import React, { Component } from 'react';
import { withRouter } from "react-router-dom";


// Packages
import 'react-select/dist/react-select.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'

// Components
import Modal from '../Modal';

// Utils
import { isValidDate } from '../../../../utils/helpers';

class DateWiseGenerateStatementModal extends Component {
    state = {
        input: {
            from: {
                value: "",
                hasError: false,
            },
            to: {
                value: "",
                hasError: false,
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { from, to } = this.state.input;
        const input = {
            from: from.value ? from.value.format('YYYY-MM-DD') : '',
            to: to.value ? to.value.format('YYYY-MM-DD') : ''
        };
        if (this.validateFormForDate()) {
            this.props.optionalProps.handlefilterInput(input)
            this.handleModalClose();
        }
    }

    handleInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            input: {
                ...state.input,
                ...newPartialInput
            }
        }))
    }

    validateFormForDate = () => {
        let errorsArePresent = false;
        let filterInputText = this.state.input;
        if (filterInputText.from.value == "" || !isValidDate(filterInputText.from.value.format('MM/DD/YYYY'))) {
            errorsArePresent = true;
            filterInputText['from'].hasError = true;
        }
        if (filterInputText.to.value == "" || !isValidDate(filterInputText.to.value.format('MM/DD/YYYY'))) {
            errorsArePresent = true;
            filterInputText['to'].hasError = true;
        }

        if (filterInputText.from.value != "" && filterInputText.to.value != "") {
            if (this.compareDate(filterInputText.from.value.format('DD/MM/YYYY')) >
                this.compareDate(filterInputText.to.value.format('DD/MM/YYYY'))) {
                errorsArePresent = true;
                filterInputText.to.hasError = true;
            }
        }

        if (errorsArePresent) {
            this.setState(state => ({
                ...state,
                input: filterInputText
            }))
        } else {
            return true;
        }
    }

    compareDate(date) {
        var parts = date.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    renderErrorMessage(input) {
        let message = '';
        if (input === 'from') {
            message = 'Please enter a valid date';
        } else if (input === 'to') {
            message = 'Please enter a valid date';
        } else {
            message = 'Please complete this field';
        }
        return (
            <div className="input-error-message">
                {message}
            </div>
        )
    }

    handleModalClose = () => {
        this.props.hideModal();
    }

    dismissFlash = () => {
        this.handleModalClose();
        this.setState(state => ({
            ...state,
            showFlash: false,
            flashMessage: ""
        }))
    }

    render() {
        let { input } = this.state;
        const { title } = this.props.optionalProps;
        return (
            <Modal
                title={title}
                closeOnClickOutside={true}
                hideModal={this.props.hideModal}
                style={{ maxWidth: `35em` }}
            >
                <form
                    style={{ margin: `0% auto`, maxWidth: `20em` }}
                    onSubmit={(event) => this.handleSubmit(event)}
                >
                        <div>
                            <div className="admin-form-row">
                                <div className={`input-container${input.from.hasError ? ' error' : ''}`} style={{ width: `45%` }}>
                                    <label htmlFor="from" style={{ display: `flex` }}>From</label>
                                    <DatePicker
                                        selected={input.from.value}
                                        onChange={date => this.handleInputChange({ from: { value: date ? date : '', hasError: false } })}
                                        placeholderText="mm/dd/yyyy"
                                        maxDate={moment()}
                                    />
                                    {input.from.hasError ? this.renderErrorMessage('from') : null}
                                </div>
                                <div className={`input-container${input.to.hasError ? ' error' : ''}`} style={{ width: `45%` }}>
                                    <label htmlFor="to" style={{ display: `flex` }}>To</label>
                                    <DatePicker
                                        selected={input.to.value}
                                        onChange={date => this.handleInputChange({ to: { value: date ? date : '', hasError: false } })}
                                        placeholderText="mm/dd/yyyy"
                                        maxDate={moment()}
                                    />
                                    {input.to.hasError ? this.renderErrorMessage('to') : null}
                                </div>
                            </div>
                        </div>
                    <input type="submit" value="Submit" className="cta mg-brand2-color" style={{ marginTop: `5%` }} />
                </form>
            </Modal>
        )
    }
}

export default withRouter(DateWiseGenerateStatementModal);
