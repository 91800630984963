import React, { Component } from 'react';

// Packages
import { Elements, StripeProvider } from 'react-stripe-elements';

// Components
import ConsumerCreditCardForm from '../ConsumerCreditCardForm';

class ConsumerAccountsPaymentPayForm extends Component {

  handleOneTimePayment = (stripeToken, amount) => {
    this.props.handleFormSubmit(stripeToken, amount);
  }

  render() {
    let { originalCollectionAmount, stripePubKey } = this.props;

    return (

      <StripeProvider apiKey={stripePubKey}>
        <Elements>
          <ConsumerCreditCardForm
            submitText={originalCollectionAmount ?
              `Charge the total: $${originalCollectionAmount}`
              :
              'Submit Offer'
            }
            handleFormSubmit={this.handleOneTimePayment}
            user={this.props.user}
            formType={this.props.formType}
            originalCollectionAmount={originalCollectionAmount}
          />
        </Elements>
      </StripeProvider>

    );
  }
}

export default ConsumerAccountsPaymentPayForm;
