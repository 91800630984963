import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";

class PreviewTaskConfigModal extends Component {
  state = {
    mergeVaribales: [],
    mergeVaribalesValues: {},
    checkArray: [],
    optionalKeys: [
      "ORG_PAYABLE_TO",
      "ORG_PAYMENT_ADDRESS_LINE1",
      "ORG_PAYMENT_ADDRESS_CITY",
      "ORG_PAYMENT_ADDRESS_STATE",
      "ORG_PAYMENT_ADDRESS_ZIP",
      "ORG_BANK_NAME_ACH",
      "ORG_BANK_ADDR_LINE1_ACH",
      "ORG_BANK_ADDR_CITY_ACH",
      "ORG_BANK_ADDR_STATE_ACH",
      "ORG_BANK_ADDR_ZIP_ACH",
      "ORG_BANK_ABA_ACH",
      "ORG_BANK_ACCT_ACH",
      "ORG_BANK_NAME_DOM_WIRE",
      "ORG_BANK_ADDR__LINE1_DOM_WIRE",
      "ORG_BANK_ADDR_CITY_DOM_WIRE",
      "ORG_BANK_ADDR_STATE_DOM_WIRE",
      "ORG_BANK_ADDR_ZIP_DOM_WIRE",
      "ORG_BANK_ABA_DOM_WIRE",
      "ORG_BANK_ACCT_DOM_WIRE",
    ],
    dropdownIsActive: false,
    mergeVaribalesValuesError: false,
    isLoading: true,
    isPreview: false,
    templateName: "",
    isEmpty: false,
    message: {
      value: "",
      hasError: false,
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    if (
      this.props &&
      this.props.optionalProps &&
      this.props.optionalProps.selectedTemp
    ) {
      let data = this.props.optionalProps.selectedTemp;
      if (data.templates && data.templates.length) {
        let flag = true;
        data.templates.map((item) => {
          if (flag) {
            if (item.method_name === "Email") {
              this.state.message.value = item.slug;
              this.setState({
                templateName: item.name,
                message: this.state.message,
              });
              this.getMergeVariables(item.slug);
              flag = false;
            }
          }
        });
      } else if (
        this.props.optionalProps.selectedTemp.hasOwnProperty(
          "isPaymentPlanTask"
        )
      ) {
        this.state.message.value =
          this.props.optionalProps.selectedTemp.email_slug;
        this.setState({
          templateName: this.props.optionalProps.selectedTemp.name,
          message: this.state.message,
        });
        this.getMergeVariables(
          this.props.optionalProps.selectedTemp.email_slug
        );
      } else {
        this.setState({ isEmpty: true });
      }
    }
  }

  getMergeVariables(name) {
    this.setState({ isLoading: true });
    API.getMergeVariables(this.props.user, name).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data && data.data) {
            this.setState({ mergeVaribales: data.data });
            this.setMergevariablesPayloadData(data.data);
          }
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "cc_email") {
      message = "Please enter a valid email";
    } else if (input === "message") {
      message = "Please select template";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleMergeVariableValue = (value, item, isReadOnly, i) => {
    let data = {};
    data[item] = value;
    let index = -1;
    let obj = {
      value: "",
      isReadOnly: false,
    };
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);
    if (index === -1) {
      if (isReadOnly) {
        obj.value = value;
        obj.isReadOnly = true;
      }
      data[item] = obj;
      this.state.checkArray.push(data);
    }
    if (
      (value === "" || value === null || value === undefined) &&
      item !== "ORG_PAYMENT_LINK"
    ) {
      this.state.checkArray.splice(index, 1);
      let check = this.state.optionalKeys.includes(item);
      if (check) {
        this.state.mergeVaribales.splice(i, 1);
        return;
      } else {
      }
    }
    if (isReadOnly) {
      if (value === null && item === "ORG_PAYMENT_LINK") {
        obj.value = null;
        obj.isReadOnly = true;
      } else {
        obj.value = value && value != null ? value : "";
        obj.isReadOnly = true;
      }
    } else {
      obj.value = value && value != null ? value : "";
      obj.isReadOnly = false;
    }
    this.state.mergeVaribalesValues[item] = obj;

    this.setState((state) => ({
      ...state,
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    }));
  };
  setMergevariablesPayloadData = (data) => {
    // for (var key in this.props.optionalProps.mergeVairablesData) {
    //   if (
    //     (this.props.optionalProps.mergeVairablesData.hasOwnProperty(key) &&
    //       data.includes(key)) ||
    //     key === "logo_url"
    //   ) {
    //     filteredObject[key] = this.props.optionalProps.mergeVairablesData[key];
    //   }
    // }
    // this.setState((state) => ({
    //   ...state,
    //   mergeVaribalesValues: filteredObject,
    // }));
    data.map((item) => {
      Object.entries(this.props.optionalProps.mergeVairablesData).forEach(
        ([key, value]) => {
          if (item === key) {
            this.handleMergeVariableValue(value, item, true);
          }
        }
      );
    });
    this.setState({ isLoading: false });
  };

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  handlePreviewClick = () => {
    let { checkArray, mergeVaribales, mergeVaribalesValues } = this.state;

    this.setState({ isPreview: true });
  };

  hidePreviewModal = () => {
    this.setState({ isPreview: false });
  };

  render() {
    let {
      isEmpty,
      templateName,
      message,
      error,
      isLoading,
      isPreview,
      mergeVaribalesValues,
    } = this.state;
    let styels = { maxWidth: `100%`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isPreview) {
      return (
        <PreviewTemplateModal
          user={this.props.user}
          hideModal={this.hidePreviewModal}
          template={message.value}
          merge_variables={mergeVaribalesValues}
        />
      );
    }

    return (
      <Modal
        optionalClasses='add-account-modal send-new-email-modal scroll'
        title='Template Preview'
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {isEmpty ? (
          <div className='admin-admins-empty-state'>
            <p
              style={{ fontSize: 16, fontWeight: 600 }}
              className='admin-admins-empty-state-heading empty-mesg-mg'
            >
              This task don't have any assigned template.
            </p>
          </div>
        ) : isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form
            className='admin-form'
            onClick={(event) => {
              this.handleClickOutside(event);
            }}
          >
            <div className='app-sidebar-layout-canvas'>
              <div className='app-sidebar app-sidebar-left' style={styels}>
                <label>Template</label>
                <div style={{ padding: "5px 0px 1em" }}>{templateName}</div>
                <div id='send-new-mail' className='modal-actions'>
                  <a
                    className={`cta mg-brand2-color`}
                    onClick={(event) => this.handlePreviewClick()}
                  >
                    Preview
                  </a>
                </div>
              </div>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(PreviewTaskConfigModal);
