import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Helmet } from "react-helmet";
import { Redirect, withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import AccountsObjectList from "../../components/ObjectList/ClientsAccounts/AccountsObjectList";
// API
import * as API from "../../utils/api";
import { showToast, fBuildVersion } from "../../utils/helpers";

class SuperAdminAccounts extends Component {
  constructor() {
    super();
    this.state = {
      Organsization: [],
      isLoading: true,
      offset: 0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      input: {
        search: {
          value: "",
        },
      },
    };
  }

  componentDidMount() {
    this.fetchAllOrganizations();


  }

  fetchAllOrganizations = (reload) => {
    let searchText = this.state.input.search.value;
    this.setState((state) => ({ ...state, showLoader: true }));
    API.fetchAllOrganizations(
      this.props.user,
      this.state.offset,
      searchText
    ).then((data) => {
      let isAccessDenied =
        data && data.status && data.status_code === 401 ? true : false;
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        !isAccessDenied
      ) {
        let Organsization = reload ? this.state.Organsization : false;
        let newData = Organsization
          ? Organsization.concat(data.data)
          : data.data;
        this.setState((state) => ({
          ...state,
          Organsization: newData,
          defaultConsumers: newData,
          isLoading: false,
          hasMoreData: data.data.length < 10 ? false : true,
          showLoader: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        }),
        () => {
          this.fetchAllOrganizations();
        }
      );
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        input: {
          ...newPartialInput,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.fetchAllOrganizations(true);
        }
      );
    }
  };

  handleRowActionClick = (event) => {
    let { history } = this.props;
    const consumerId = event.consumerId;
    if (consumerId) {
      history.push(`/super-admin/accounts/${consumerId}`);
    }
  };

  reloadData = () => {
    this.setState({offset: 0} , () => {
      this.fetchAllOrganizations();
    })
  };

  handleCreateOrg = (event) => {
    event.preventDefault();
    let props = {
      reloadData: this.reloadData,
    };
    this.props.updateModalState(true, "CREATE_ORGANIZATION", props);
  };

  getSectionHeaderUtilities() {
    const { input } = this.state;
    return (
      <div className="app-section-header-utilities admin-account-header-utility">
        <div className="admin-search-input-container">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={input.search.value}
            className="admin-search-input"
            onChange={(event) =>
              this.handleInputChange({ search: { value: event.target.value } })
            }
          />
          <span
            className="search-icon"
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
        <a
          className="cta mg-brand2-color"
          onClick={(event) => this.handleCreateOrg(event)}
        >
          Add Organization
        </a>
      </div>
    );
  }

  getAccountsObjectListData = () => {
    const { Organsization } = this.state;

    return {
      headings: [
        "Name",
        "Url",
        "Phone Number",
        "Address",
        "Payment Status",
        "expander",
      ],
      columns: Organsization.map((org) => {
        return {
          primaryColumn: {
            title: org.name,
            rowPath: `/super-admin/accounts/${org.id}`,
            secondaryInfo: org.email,
            key: "Name",
          },
          primaryRow: {
            key: "Name",
            title: org.name,
            secondaryInfo: org.email,
          },
          secondaryColumns: [
            {
              key: "Url",
              title: org.website_url ? org.website_url : "n/a",
            },
            {
              key: "Phone Number",
              title: org.contact_no ? org.contact_no : "n/a",
            },
            {
              key: "Address",
              title: org.address ? org.address : "n/a",
            },
            {
              key: "Payment Status",
              title: org.admin_approved_for_payment ? (
                <div style={{ color: "green" }}>Enable</div>
              ) : (
                <div style={{ color: "red" }}>Disable</div>
              ),
            },
            {
              rowType: "arrow-click",
              optionalClasses: "expander",
            },
          ],
          consumerId: org.id,
        };
      }),
    };
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="super-admin-accounts scrollbar">

        <Helmet>
          <title>{appName ? appName : ""} | Accounts </title>
        </Helmet>
        <AppSectionHeader
          title="Accounts"
          utilities={this.getSectionHeaderUtilities()}
        />
        {this.state.Organsization ? (
          this.state.Organsization.length ? (
            <div className="super-admin-accounts-inner">
              <BottomScrollListener
                onBottom={this.handleContainerOnBottom}
                debounce={0}
                offset={50}
              >
                {(scrollRef) => (
                  <div>
                    {isSmallDevice ? (
                      <div
                        ref={scrollRef}
                        className="big-device-grad scrollbar ac-sc"
                      >
                        <ClientsCardView
                          data={this.getAccountsObjectListData()}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                        />
                      </div>
                    ) : (
                      <AccountsObjectList
                        data={this.getAccountsObjectListData()}
                        rowActionClick={(event) =>
                          this.handleRowActionClick(event)
                        }
                        scrollRef={scrollRef}
                        isPrimaryColumn={true}
                        hideForMobile={true}
                        optionalClasses={"scrollbar ac-sc"}
                        optionalTableClasses="admin-admin-accounts-tbl"
                      />
                    )}
                    {this.state.showLoader && (
                      <div>
                        <AppCanvasLoader />
                      </div>
                    )}
                  </div>
                )}
              </BottomScrollListener>
            </div>
          ) : this.state.input.search.value.length ? (
            <div className="admin-admins-empty-state">
              <p>No results found for:</p>
              <h3 className="admin-admins-empty-state-heading">
                {this.state.input.search.value}
              </h3>
            </div>
          ) : (
            <div className="admin-admins-empty-state">
              <h3 className="admin-admins-empty-state-heading">
                No Account Activity
              </h3>
              <p>There are currently no collections accounts in progress</p>
            </div>
          )
        ) : (
          <div className="admin-admins-empty-state">
            <h3 className="admin-admins-empty-state-heading">
              No Account Activity
            </h3>
            <p>There are currently no collections accounts in progress</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SuperAdminAccounts);
