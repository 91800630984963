import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

// Packages
import Select from 'react-select';
import MaskedTextInput from 'react-text-mask';

// API
import * as API from '../../../../utils/api';

// Components
import Modal from '../Modal';

import { numberMask, numberMaskForPercent, getStrippedDollarOrPercentAmount, showToast } from '../../../../utils/helpers';


class AutomaticNegotiation extends Component {
  state = {
    creditors: [],
    input: {
      negotiationInfo: {
        type: {
          value: this.props.optionalProps.thresholdData ? this.props.optionalProps.thresholdData.threshold_type : 'Automatic',
          hasError: false,
          isRequired: true
        },
        capabilities: {
          value: "",
          hasError: false,
          isRequired: true
        },
        amount: {
          value: "",
          hasError: false,
          isRequired: true
        },
        percentage: {
          value: "",
          hasError: false,
          isRequired: true
        }
      }
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const { thresholdData } = this.props.optionalProps;

    const amount = thresholdData.amount;
    const percentage = thresholdData.percentage;

    if (thresholdData) {
      this.setState({
        input: {
          negotiationInfo: {
            type: {
              value: thresholdData.threshold_type,
              hasError: false,
              isRequired: true
            },
            capabilities: {
              value: thresholdData.capabilities,
              hasError: false,
              isRequired: true
            },
            amount: {
              value: amount,
              hasError: false,
              isRequired: true
            },
            percentage: {
              value: percentage,
              hasError: false,
              isRequired: true
            }
          }
        }
      })
    }
  }

  handleModalClose = () => {
    this.props.hideModal();
  }

  handlePaymentInfoInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        negotiationInfo: {
          ...state.input.negotiationInfo,
          ...newPartialInput,
        }
      }
    }))
  }

  handleSaveClick = (event) => {
    event.preventDefault();
    const { input } = this.state;
    if (this.validateForm()) {
      let amt = input.negotiationInfo.amount.value;
      let perct = input.negotiationInfo.percentage.value;
      let amount = amt ? getStrippedDollarOrPercentAmount(amt.toString()) : amt;
      let percentage = perct ? getStrippedDollarOrPercentAmount(perct.toString()) : perct;

      let negotiationInfo = {
        type: input.negotiationInfo.type.value,
        capability: input.negotiationInfo.capabilities.value,
        amount: amount,
        user_ids: this.props.optionalProps.consumerId,
        percentage: percentage
      }

      API.saveNegotiationThreshold(this.props.user, negotiationInfo).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.setState({ isLoading: false });
          this.props.optionalProps.releoadTableData();
        } else {
          this.setState(state => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
        this.handleModalClose();
      })
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.negotiationInfo }

    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'type') {
        if (value.value === '' || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
    });

    if (newInputState.capabilities.value == '' && newInputState.type.value == 'Automatic') {
      errorsArePresent = true;
      newInputState.capabilities.hasError = true;
    }

    if (newInputState.capabilities.value == 'Both' || newInputState.capabilities.value == 'OneTime') {
      if (newInputState.percentage.value === '' || newInputState.percentage.value === null) {
        errorsArePresent = true;
        newInputState.percentage.hasError = true;
      }
    }

    if (newInputState.capabilities.value == 'Both' || newInputState.capabilities.value == 'Monthly') {
      if (newInputState.amount.value === '' || newInputState.amount.value === null) {
        errorsArePresent = true;
        newInputState.amount.hasError = true;
      }
    }

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        negotiationInfo: newInputState
      }))
    } else {
      return true;
    }
  }

  renderErrorMessage(input) {
    let message = '';
    if (input === 'type') {
      message = "Please select negotiation type.";
    } else if (input === 'capabilities') {
      message = "Please select capabilities.";
    } else if (input === 'amount') {
      message = "Please enter amount.";
    } else if (input === 'percentage') {
      message = "Please enter percentage.";
    } else {
      message = 'Please complete this field.';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { input, slide, error } = this.state;
    let defaultChecked = input.negotiationInfo.type.value == 'Manual' ? true : false;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <Modal
        title="Negotiation & Thresholds"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
      >
        <form>
          <div>
            <div>
              <div style={style.container}>
                <div style={style.content}>Negotiation Type : </div>

                <div className="radio">
                  <label style={{ paddingLeft: `31px`, paddingRight: `15px` }}>
                    <input type="radio" name="Type" value={'Automatic'} defaultChecked={!defaultChecked} style={{ WebkitAppearance: `radio`, width: `25px`, position: `absolute`, left: `0`, MozAppearance: `radio` }}
                      onClick={event => this.handlePaymentInfoInputChange({
                        type: { value: 'Automatic', hasError: false }
                      }
                      )}
                    />
                    <div style={{ padding: `5px 0` }}>{'Automatic'}</div>
                  </label>
                </div>
                <div className="radio" style={{ marginBottom: `25px` }}>
                  <label style={{ paddingLeft: `31px` }}>
                    <input type="radio" name="Type" value={'Manual'} defaultChecked={defaultChecked} style={{ WebkitAppearance: `radio`, width: `25px`, position: `absolute`, left: `0`, MozAppearance: `radio` }}
                      onChange={event => this.handlePaymentInfoInputChange({
                        type: { value: 'Manual', hasError: false }
                      }
                      )}
                    />
                    <div style={{ padding: `5px 0` }}>{'Manual'}</div>
                  </label>
                </div>
              </div>
              {!defaultChecked ?
                <div>
                  <div style={style.container}>
                    <div style={style.content}>Negotiation Capabilities : </div>
                    <div
                      className={`input-container${input.negotiationInfo.capabilities.hasError ? ' error' : ''}`}
                    >
                      <Select
                        inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                        className="form-select"
                        name="Select"
                        value={input.negotiationInfo.capabilities.value}
                        placeholder="Select capabilities"
                        options={[
                          { "value": "OneTime", "label": "One-Time Payment" },
                          { "value": "Monthly", "label": "Monthly Payment" },
                          { "value": "Both", "label": "Both" }
                        ]}
                        resetValue=""
                        onChange={event => this.handlePaymentInfoInputChange({
                          capabilities: { value: event.value, hasError: false },
                          amount: { value: '', hasError: false },
                          percentage: { value: '', hasError: false }
                        }
                        )}
                      />
                      {input.negotiationInfo.capabilities.hasError ? this.renderErrorMessage('capabilities') : null}
                    </div>
                  </div>
                  {(input.negotiationInfo.capabilities.value == 'OneTime' || input.negotiationInfo.capabilities.value == 'Both') ?
                    <div style={style.container}>
                      <div style={style.content}>Minimum Percentage :</div>
                      <div
                        className={`input-container${input.negotiationInfo.percentage.hasError ? ' error' : ''}`}
                      >
                        <MaskedTextInput
                          guide={true}
                          mask={numberMaskForPercent}
                          name="creditorTotalDebt"
                          placeholder={'0%'}
                          placeholderChar={'\u2000'}
                          type="text"
                          value={input.negotiationInfo.percentage.value}
                          onChange={event => this.handlePaymentInfoInputChange({
                            percentage: { value: event.target.value, hasError: false }
                          }
                          )}
                        />
                        {input.negotiationInfo.percentage.hasError ? this.renderErrorMessage('percentage') : null}
                      </div>
                    </div> : null
                  }
                  {(input.negotiationInfo.capabilities.value == 'Monthly' || input.negotiationInfo.capabilities.value == 'Both') ?
                    <div style={style.container}>
                      <div style={style.content}>Minimum Amount :</div>
                      <div
                        className={`input-container${input.negotiationInfo.amount.hasError ? ' error' : ''}`}
                      >
                        <MaskedTextInput
                          guide={true}
                          mask={numberMask}
                          name="creditorTotalDebt"
                          placeholder={'$0'}
                          placeholderChar={'\u2000'}
                          type="text"
                          value={input.negotiationInfo.amount.value}
                          onChange={event => this.handlePaymentInfoInputChange({
                            amount: { value: event.target.value, hasError: false }
                          }
                          )}
                        />
                        {input.negotiationInfo.amount.hasError ? this.renderErrorMessage('amount') : null}
                      </div>
                    </div> : null
                  }
                </div>
                : null}
            </div>
            <div className="modal-actions">
              <a
                className="cta"
                onClick={(event) => this.handleSaveClick(event)}
              >
                Save Threshold
              </a>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

const style = {
  container: { display: 'flex' },
  content: { width: '80%', paddingTop: `10px` }
}

export default AutomaticNegotiation;
