import moment from 'moment';
import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import 'react-select/dist/react-select.css';
import MaskedTextInput from 'react-text-mask';
// API
import * as API from '../../../utils/api';
import { getStrippedDollarAmount, isValidDate, showToast, validateEmail } from '../../../utils/helpers';
// Utils
import AppCanvasLoader from '../../App/AppCanvasLoader';
// Components
import Panel from '../../App/Panel';

class SuperAdminUpdateOrgForm extends Component {
    state = {
        input: {
            organization: {}
        },
        config: {
            intercom: {
                appId: {
                    value: '',
                    hasError: false
                },
                token: {
                    value: '',
                    hasError: false
                }
            },
            twillio: {
                appId: {
                    value: '',
                    hasError: false
                },
                contactNo: {
                    value: '',
                    hasError: false
                },
                secretKey: {
                    value: '',
                    hasError: false
                }
            },
            stripe: {
                secretKey: {
                    value: '',
                    hasError: false
                },
                token: {
                    value: '',
                    hasError: false
                }
            },
            lob: {
                secretKey: {
                    value: '',
                    hasError: false
                }
            },
            mandrill: {
                secretKey: {
                    value: '',
                    hasError: false
                },
                subAccount: {
                    value: '',
                    hasError: false
                }
            },
            accountPref: {
                qbConnected: {
                    value: true
                },
                standAlone: {
                    value: false
                }
            }
        },
        isLoading: true,
        error: {
            hasAnyError: false,
            statusCode: 200
        },
    }

    componentDidMount() {
        let { organization } = this.props;
        this.setConfigData(organization)
    }

    setOrganizationData = (organization) => {
        this.setState(state => ({
            input: {
                organization: {
                    name: {
                        value: organization.name,
                        hasError: false
                    },
                    website_url: {
                        value: organization.website_url,
                        hasError: false
                    },
                    contact_no: {
                        value: organization.contact_no,
                        hasError: false
                    },
                    email: {
                        value: organization.email,
                        hasError: false
                    },
                    logo: {
                        value: organization.logo,
                        hasError: false
                    },
                    color: {
                        value: organization.color,
                        hasError: false
                    },
                    address: {
                        value: organization.address,
                        hasError: false
                    }
                }
            },
            isLoading: false
        }))
    }

    setConfigData = (organization) => {
        organization && organization.config_list && organization.config_list.map((item) => {
            if (item.name === 'LOB') {
                this.state.config.stripe.secretKey.value = item.secret_key;
            } else if (item.name === 'MANDRILL') {
                this.state.config.mandrill.secretKey.value = item.secret_key;
                this.state.config.mandrill.subAccount.value = item.subAccount ? item.subAccount : ''
            } else if (item.name === 'STRIPE') {
                this.state.config.stripe.secretKey.value = item.secret_key;
                this.state.config.intercom.token.value = item.token;
            } else if (item.name === 'INTERCOM') {
                this.state.config.intercom.appId.value = item.app_id;
                this.state.config.intercom.token.value = item.token;
            } else if (item.name === 'TWILIO') {
                this.state.config.twillio.appId.value = item.app_id;
                this.state.config.twillio.contactNo.value = item.contactNo ? item.contactNo : '';
                this.state.config.twillio.secretKey.value = item.secret_key
            }
        })

        if (organization.integrated_act_system && organization.integrated_act_system === 'quickbooks_online') {
            this.state.config.accountPref.qbConnected.value = true;
        } else {
            this.state.config.accountPref.qbConnected.value = false;
            this.state.config.accountPref.standAlone.value = true
        }
        this.setState({ config: this.state.config }, () => {
            this.setOrganizationData(organization);
        })
    }

    handleOrganizationInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            input: {
                ...state.input,
                organization: {
                    ...state.input.organization,
                    ...newPartialInput,
                }
            }
        }))
    }

    handleConfigInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            config: {
                ...state.config,
                ...newPartialInput,
            }
        }))
    }

    handleAccountPrefInputChange(input, newPartialInput) {
        if (input === 'qbConnected') {
            this.state.config.accountPref.qbConnected = newPartialInput
            this.state.config.accountPref.standAlone.value = false
        }
        if (input === 'standAlone') {
            this.state.config.accountPref.standAlone = newPartialInput;
            this.state.config.accountPref.qbConnected.value = false
        }
        this.setState({ config: this.state.config })
    }

    getNumberFromPhoneString = (phoneString) => {
        if (phoneString) {
            let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, '');
            return strippedNumber;
        } else {
            return null
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let { input, config } = this.state;
        let { user, organization } = this.props;

        if (this.validateForm()) {
            let payload = {
                "id": organization.id,
                "org_name": input.organization.name.value,
                "website_url": input.organization.website_url.value,
                "org_contactNo": this.getNumberFromPhoneString(input.organization.contact_no.value),
                "address": input.organization.address.value,
                "color": input.organization.color.value,
                "logo": input.organization.logo.value,
                "email": input.organization.email.value,
                "integrated_act_system": config.accountPref.qbConnected.value ? 0 : 1,
                "twillio": {
                    "contactNo": this.getNumberFromPhoneString(config.twillio.contactNo.value),
                    "appId": config.twillio.appId.value,
                    "secretKey": config.twillio.secretKey.value
                },
                "intercom": {
                    "appId": config.intercom.appId.value,
                    "token": config.intercom.token.value
                },
                "stripe": {
                    "token": config.stripe.token.value,
                    "secretKey": config.stripe.secretKey.value
                },
                "lob": {
                    "secretKey": config.lob.secretKey.value
                },
                "mandrill": {
                    "secretKey": config.mandrill.secretKey.value,
                    "subaccount": config.mandrill.subAccount.value
                }
            }

            API.updateOrganization(user, payload).then(data => {
                if (((typeof data != 'undefined') || (data != null)) && !data.error) {
                    this.props.updateModalState(true, 'SUCCESS', { message: data.message });
                } else {
                    this.setState(state => ({
                        ...state,
                        error: {
                            hasAnyError: true,
                            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
                        }
                    }))
                }
            });
        }
    }

    validateForm = () => {
        let errorsArePresent = false;
        let newOrganizationInputState = { ...this.state.input.organization };

        Object.entries(newOrganizationInputState).forEach(([key, value]) => {
            if (key === 'email') {
                if (!validateEmail(value.value)) {
                    newOrganizationInputState[key].hasError = true;
                    errorsArePresent = true;
                }
            } else if (key === 'address') {
                if (value.value === '') {
                    newOrganizationInputState[key].hasError = true;
                    errorsArePresent = true;
                }
            } else if (key === 'contact_no') {
                if (value.value === '' || (value.value && value.value.length < 10)) {
                    newOrganizationInputState[key].hasError = true;
                    errorsArePresent = true;
                }
            } else if (key === 'website_url') {
                if (value.value === '') {
                    newOrganizationInputState[key].hasError = true;
                    errorsArePresent = true;
                }
            }
        });

        if (errorsArePresent) {
            this.state.input.organization = newOrganizationInputState;
            this.setState(state => ({
                ...state,
                input: this.state.input
            }))

            return false;
        } else {
            return true;
        }
    }

    renderErrorMessage(input) {
        let message = '';

        if (input === 'contact_no') {
            message = 'Please enter a valid phone number';
        } else if (input === 'email') {
            message = 'Please enter a valid email';
        } else if (input === 'address') {
            message = 'Please enter address';
        } else if (input === 'website_url') {
            message = 'Please enter a valid url';
        } else {
            message = 'Please complete this field';
        }

        return (
            <div className="input-error-message">
                {message}
            </div>
        )
    }

    render() {
        let { isLoading, creditors, input, error, config } = this.state;
        if (error.hasAnyError) {
            if (error.statusCode == 500) {
                showToast();
                this.setState({
                    error: {
                        ...this.state.error,
                        hasAnyError: false,
                        statusCode: 200
                    }
                })
            } else if (error.statusCode == 401) {
                return <Redirect to={{ pathname: '/sign-out' }} />
            } else {
                this.props.updateModalState(true, 'OTHER_ERROR', true)
                return <Redirect to={{ pathname: '/sign-out' }} />
            }
        }

        return isLoading
            ? (
                <AppCanvasLoader />
            )
            : (
                <form
                    className="admin-form add-edit-account-form scrollbar" style={{ height: window.innerHeight * 0.65, overflow: 'auto', paddingBottom: 100 }}
                    onSubmit={(event) => this.handleSubmit(event)}
                >
                    <Panel
                        heading="Organization's Information"
                    >
                        <div className="admin-form-row">
                            <div className={`input-container one-third${input.organization.name.hasError ? ' error' : ''}`}>
                                <label htmlFor="name">Organization's Name</label>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    readOnly={true}
                                    value={input.organization.name.value}
                                    onChange={event => this.handleOrganizationInputChange({ name: { value: event.target.value, hasError: false } })}
                                />
                            </div>
                            <div
                                className={`input-container one-third${input.organization.email.hasError ? ' error' : ''}`}
                            >
                                <label htmlFor="email">Organization's Email</label>
                                <input
                                    name="email"
                                    type="email"
                                    placeholder="address@email.com"
                                    value={input.organization.email.value}
                                    onChange={event => this.handleOrganizationInputChange({ email: { value: event.target.value, hasError: false } })}
                                />
                                {input.organization.email.hasError ? this.renderErrorMessage('email') : null}
                            </div>
                            <div
                                className={`input-container one-third${input.organization.contact_no.hasError ? ' error' : ''}`}
                            >
                                <label htmlFor="phone">Organization's Phone Number</label>
                                <MaskedTextInput
                                    name="phone"
                                    mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    placeholder="(555) 555-5555"
                                    placeholderChar={'\u2000'}
                                    guide={true}
                                    type="text"
                                    value={input.organization.contact_no.value}
                                    onChange={event => this.handleOrganizationInputChange({ contact_no: { value: event.target.value, hasError: false } })}
                                />
                                {input.organization.contact_no.hasError ? this.renderErrorMessage('contact_no') : null}
                            </div>
                        </div>
                        <div className="admin-form-row">
                            <div
                                className={`input-container half${input.organization.website_url.hasError ? ' error' : ''}`}
                            >
                                <label htmlFor="url">Organization's URL</label>
                                <input
                                    name="url"
                                    placeholder=""
                                    type="text"
                                    value={input.organization.website_url.value}
                                    onChange={event => this.handleOrganizationInputChange({
                                        website_url: { value: event.target.value, hasError: false }
                                    }
                                    )}
                                />
                                {input.organization.website_url.hasError ? this.renderErrorMessage('website_url') : null}
                            </div>
                            <div
                                className={`input-container half${input.organization.address.hasError ? ' error' : ''}`}
                            >
                                <label htmlFor="streetAddress">Street Address</label>
                                <input
                                    name="streetAddress"
                                    type="text"
                                    placeholder="Street Address"
                                    value={input.organization.address.value}
                                    onChange={event => this.handleOrganizationInputChange({
                                        address: { value: event.target.value, hasError: false }
                                    }
                                    )}
                                />
                                {input.organization.address.hasError ? this.renderErrorMessage('address') : null}
                            </div>
                        </div>
                    </Panel>
                    <div className="modal-slide">
                        <Panel heading="Integrated Accounting System">
                            <div className="form-container">
                                <div className="admin-form-row">
                                    <div
                                        style={{display: 'flex', flexWrap: 'nowrap'}}
                                        className={`input-container half`}>
                                        <label htmlFor="qbConnected">QuickBook Connected Account</label>
                                        <input
                                            name="qbConnected"
                                            id="qbConnected"
                                            type="radio"
                                            value={config.accountPref.qbConnected.value}
                                            checked={config.accountPref.qbConnected.value}
                                            style={{appearance: 'radio', minHeight: '1em'}}
                                            onChange={event => this.handleAccountPrefInputChange('qbConnected', { value: event.target.value, hasError: false })}
                                            // onChange={event => this.handleConfigInputChange({
                                            //     twillio: {
                                            //         appId: { value: event.target.value, hasError: false },
                                            //         contactNo: { value: config.twillio.contactNo.value, hasError: config.twillio.contactNo.hasError },
                                            //         secretKey: { value: config.twillio.secretKey.value, hasError: config.twillio.secretKey.hasError },
                                            //     }
                                            // })}
                                        />
                                    </div>
                                    <div
                                        style={{display: 'flex', flexWrap: 'nowrap'}}
                                        className={`input-container half`}>
                                        <label htmlFor="standAlone">Stand Alone Account</label>
                                        <input
                                            name="standAlone"
                                            id="standAlone"
                                            type="radio"
                                            value={config.accountPref.standAlone.value}
                                            checked={config.accountPref.standAlone.value}
                                            style={{appearance: 'radio', minHeight: '1em'}}
                                            onChange={event => this.handleAccountPrefInputChange('standAlone', { value: event.target.value, hasError: false })}
                                            // onChange={event => this.handleConfigInputChange({
                                            //     twillio: {
                                            //         appId: { value: event.target.value, hasError: false },
                                            //         contactNo: { value: config.twillio.contactNo.value, hasError: config.twillio.contactNo.hasError },
                                            //         secretKey: { value: config.twillio.secretKey.value, hasError: config.twillio.secretKey.hasError },
                                            //     }
                                            // })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel heading="Twillio">
                            <div className="form-container">
                                <div className="admin-form-row">
                                    <div className={`input-container half${config.twillio.appId.hasError ? ' error' : ''}`}>
                                        <label htmlFor="appid">AppId</label>
                                        <input
                                            name="appid"
                                            placeholder="Enter AppId"
                                            type="text"
                                            value={config.twillio.appId.value}
                                            onChange={event => this.handleConfigInputChange({
                                                twillio: {
                                                    appId: { value: event.target.value, hasError: false },
                                                    contactNo: { value: config.twillio.contactNo.value, hasError: config.twillio.contactNo.hasError },
                                                    secretKey: { value: config.twillio.secretKey.value, hasError: config.twillio.secretKey.hasError },
                                                }
                                            })}
                                        />
                                        {config.twillio.appId.hasError ? this.renderErrorMessage('appId') : null}
                                    </div>
                                    <div className={`input-container half${config.twillio.contactNo.hasError ? ' error' : ''}`}>
                                        <label htmlFor="contactNo">Phone Number</label>
                                        <MaskedTextInput
                                            name="phone"
                                            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            placeholder="(555) 555-5555"
                                            placeholderChar={'\u2000'}
                                            guide={true}
                                            type="text"
                                            value={config.twillio.contactNo.value}
                                            onChange={event => this.handleConfigInputChange({
                                                twillio: {
                                                    appId: { value: config.twillio.appId.value, hasError: config.twillio.appId.hasError },
                                                    contactNo: { value: event.target.value, hasError: false },
                                                    secretKey: { value: config.twillio.secretKey.value, hasError: config.twillio.secretKey.hasError },
                                                }
                                            })}
                                        />
                                        {config.twillio.contactNo.hasError ? this.renderErrorMessage('contactNo') : null}
                                    </div>
                                </div>
                                <div className="admin-form-row">
                                    <div className={`input-container ${config.twillio.secretKey.hasError ? ' error' : ''}`}>
                                        <label htmlFor="secretKey">Secret Key</label>
                                        <input
                                            name="secretKey"
                                            placeholder="Enter Secret Key"
                                            type="text"
                                            value={config.twillio.secretKey.value}
                                            onChange={event => this.handleConfigInputChange({
                                                twillio: {
                                                    appId: { value: config.twillio.appId.value, hasError: config.twillio.appId.hasError },
                                                    contactNo: { value: config.twillio.contactNo.value, hasError: config.twillio.contactNo.hasError },
                                                    secretKey: { value: event.target.value, hasError: false },
                                                }
                                            })}
                                        />
                                        {config.twillio.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel heading="Intercom">
                            <div className="form-container">
                                <div className="admin-form-row">
                                    <div className={`input-container half${config.intercom.appId.hasError ? ' error' : ''}`}>
                                        <label htmlFor="appid">AppId</label>
                                        <input
                                            name="appid"
                                            placeholder="Enter AppId"
                                            type="text"
                                            value={config.intercom.appId.value}
                                            onChange={event => this.handleConfigInputChange({
                                                intercom: {
                                                    appId: { value: event.target.value, hasError: false },
                                                    token: { value: config.intercom.token.value, hasError: config.intercom.token.hasError }
                                                }
                                            })}
                                        />
                                        {config.intercom.appId.hasError ? this.renderErrorMessage('appId') : null}
                                    </div>
                                    <div className={`input-container half${config.intercom.token.hasError ? ' error' : ''}`}>
                                        <label htmlFor="token">Token</label>
                                        <input
                                            name="token"
                                            placeholder="Enter Token"
                                            type="text"
                                            value={config.intercom.token.value}
                                            onChange={event => this.handleConfigInputChange({
                                                intercom: {
                                                    token: { value: event.target.value, hasError: false },
                                                    appId: { value: config.intercom.appId.value, hasError: config.intercom.appId.hasError }
                                                }
                                            })}
                                        />

                                        {config.intercom.token.hasError ? this.renderErrorMessage('token') : null}
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel heading="Stripe">
                            <div className="form-container">
                                <div className="admin-form-row">
                                    <div className={`input-container half${config.stripe.secretKey.hasError ? ' error' : ''}`}>
                                        <label htmlFor="secretKey">Secret Key</label>
                                        <input
                                            name="secretKey"
                                            placeholder="Enter Secret Key"
                                            type="text"
                                            value={config.stripe.secretKey.value}
                                            onChange={event => this.handleConfigInputChange({
                                                stripe: {
                                                    secretKey: { value: event.target.value, hasError: false },
                                                    token: { value: config.stripe.token.value, hasError: config.stripe.token.hasError }
                                                }
                                            })}
                                        />
                                        {config.stripe.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                                    </div>
                                    <div className={`input-container half${config.stripe.token.hasError ? ' error' : ''}`}>
                                        <label htmlFor="token">Token</label>
                                        <input
                                            name="token"
                                            placeholder="Enter Token"
                                            type="text"
                                            value={config.stripe.token.value}
                                            onChange={event => this.handleConfigInputChange({
                                                stripe: {
                                                    secretKey: { value: config.stripe.secretKey.value, hasError: config.stripe.secretKey.hasError },
                                                    token: { value: event.target.value, hasError: false }
                                                }
                                            })}
                                        />
                                        {config.stripe.token.hasError ? this.renderErrorMessage('token') : null}
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel heading="Mandrill">
                            <div className="form-container">
                                <div className="admin-form-row">
                                    <div className={`input-container half${config.mandrill.secretKey.hasError ? ' error' : ''}`}>
                                        <label htmlFor="secretKey">Secret Key</label>
                                        <input
                                            name="secretKey"
                                            placeholder="Enter Secret Key"
                                            type="text"
                                            value={config.mandrill.secretKey.value}
                                            onChange={event => this.handleConfigInputChange({
                                                mandrill: {
                                                    secretKey: { value: event.target.value, hasError: false },
                                                    subAccount: { value: config.mandrill.subAccount.value, hasError: config.mandrill.subAccount.hasError }
                                                }
                                            })}
                                        />
                                        {config.mandrill.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                                    </div>
                                    <div className={`input-container half${config.mandrill.subAccount.hasError ? ' error' : ''}`}>
                                        <label htmlFor="subAccount">Sub Account</label>
                                        <input
                                            name="subAccount"
                                            placeholder="Enter Sub Account"
                                            type="text"
                                            value={config.mandrill.subAccount.value}
                                            onChange={event => this.handleConfigInputChange({
                                                mandrill: {
                                                    subAccount: { value: event.target.value, hasError: false },
                                                    secretKey: { value: config.mandrill.secretKey.value, hasError: config.mandrill.secretKey.hasError }
                                                }
                                            })}
                                        />
                                        {config.mandrill.subAccount.hasError ? this.renderErrorMessage('subAccount') : null}
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel heading="Lob">
                            <div className="form-container">
                                <div className={`input-container ${config.lob.secretKey.hasError ? ' error' : ''}`}>
                                    <label htmlFor="secretKey">Secret Key</label>
                                    <input
                                        name="secretKey"
                                        placeholder="Enter Secret Key"
                                        type="text"
                                        value={config.lob.secretKey.value}
                                        onChange={event => this.handleConfigInputChange({
                                            lob: {
                                                secretKey: { value: event.target.value, hasError: false }
                                            }
                                        })}
                                    />
                                    {config.lob.secretKey.hasError ? this.renderErrorMessage('secretKey') : null}
                                </div>
                            </div>
                        </Panel>
                    </div>

                    <input type="submit" value="Submit" className="cta mg-brand2-color" />
                </form>
            );
    }
}

export default withRouter(SuperAdminUpdateOrgForm);
