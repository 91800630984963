import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

class ObjectDebtListHeader extends Component {
  static propTypes = {
    headings: PropTypes.array.isRequired
  }

  render() {
    let { headings, adjustHeader, setObjListStyle } = this.props;

    return (
      <div className="object-debt-list-header">
        <div className="object-debt-list-header-secondary-headings" style={setObjListStyle ? {width: '100%'} : {}}>
          {headings.map((heading, index) => {
            let getstyle = setObjListStyle && setObjListStyle.length ? setObjListStyle[index] : '';
              return (
                <h4 key={shortid.generate()} className={`object-debt-list-header-heading${adjustHeader ? ' header-min-width' : ''}${' ' + getstyle}`}>
                  {heading}
                </h4>
              );
            return null;
          })}
        </div>
      </div>
    )
  }
}

export default ObjectDebtListHeader;
