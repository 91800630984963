import $ from "jquery";
import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";
// Components
import Modal from "../Modal";

class AcceptRejectNoteModal extends Component {
  state = {
    input: {
      note: {
        value: "",
        hasError: false,
        isRequired: true,
      },
    },
  };

  handleModalClose = () => {
    this.props.hideModal();
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { input } = this.state;
    const { optionalProps } = this.props;
    if (this.validateForm()) {
      let note = input.note.value;
      if (optionalProps.action == "accept") {
        optionalProps.handleAcceptOffer(optionalProps.offerId, note);
      } else if (optionalProps.action == "reject") {
        optionalProps.handleRejectAction(optionalProps.offerId, note);
      } else if (optionalProps.action === "AddTaskNote") {
        $("#add-note").replaceWith('<div class="spinner"></div>');
        optionalProps.handleAddTaskNote(note);
      } else {
        this.handleModalClose();
      }
    }
  };

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input };

    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "note") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: {
          ...state.input.note,
          ...newInputState,
        },
      }));
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "note") {
      message = "Please enter a note.";
    } else {
      message = "Please complete this field.";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let { input } = this.state;

    return (
      <Modal
        title="Add Notes"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses="ce-mo"
      >
        <form className="admin-form">
          <div className={`modal-slide`}>
            <div className="form-container" style={{ marginBottom: "2rem" }}>
              <div
                className={`input-container${
                  input.note.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="name">Please enter note</label>
                <div
                  className="input-container multi-input-field"
                  style={{ marginBottom: 0 }}
                >
                  <TextareaAutosize
                    className="multi-input-field-input"
                    name="note"
                    type="textarea"
                    maxRows={15}
                    maxLength={360}
                    placeholder="Your note here..."
                    value={input.note.value}
                    onChange={(event) =>
                      this.handleInputChange({
                        note: { value: event.target.value, hasError: false },
                      })
                    }
                  />
                </div>
                {input.note.hasError ? this.renderErrorMessage("note") : null}
              </div>
            </div>
            <div id="add-note" className="modal-actions">
              <a
                className="cta mg-brand2-color"
                style={{ width: "10rem" }}
                onClick={(event) => this.handleSubmit(event)}
              >
                Save
              </a>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

export default AcceptRejectNoteModal;
