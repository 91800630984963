import currencyFormatter from "currency-formatter";
import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import Panel from "../../../components/App/Panel";
import ACHPlaidLink from "../../../components/Consumer/ACHPlaidLink";
import ConsumerAddAccountForm from "../../../components/Consumer/ConsumerAddAccountForm";
import * as API from "../../../utils/api";
//import companyData from "../../../assets/styles/theme.json";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import CardView from "../../../components/CardView/CardView";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import zIndex from "@material-ui/core/styles/zIndex";

const styles = {
  tabPanel: {
    display: "flex",
    // marginBottom: "1.5em",
  },
  button: {
    width: "auto",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    margin: 0,
  },
  checkboxDiv: {
    display: "flex",
    margin: "0.5em 0px",
    alignItems: "center",
  },
  checkbox: {
    WebkitAppearance: `checkbox`,
    width: `16px`,
    marginRight: `10px`,
  },
  acceptAgr: {
    width: "100%",
    lineHeight: "120%",
    marginTop: 8,
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  italicStyle: {
    width: "100%",
    lineHeight: "120%",
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  secText: {
    lineHeight: "120%",
  },
  primText: {
    fontWeight: 600,
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
    opacity: 0.3,
    zIndex: 10,
    pointerEvents: "none",
  },
  error: {
    fontSize: "1em",
    color: "#fb5c82",
    fontFamily: "Open Sans",
    fontWeight: 600,
    marginTop: "0.4em",
  },
};

class ConsumerExpressPaymentForPaymentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: {
        value: "",
        hasError: false,
      },
      isPlaidVerified: null,
      plaidStatus: "",
      plaidStatusMessage: "",
      paymentMethod: "CC",
      paymentDetails: [],
      planList: {},
      errorMessage: "Please enter payment amount",
      showBankDetails: false,
      isACH: false,
      isCC: false,
      isPaymentPortal: false,
      isCheck: false,
      totalAmount: 0,
      savedCard: {
        status: false,
        cardID: "",
      },
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      companyData: {},
    };
  }

  checkActiveButtonTab = (check) => {
    if (check) {
      return this.props.primary_color;
    } else {
      return "#a1a1a1";
    }
  };
  componentDidMount() {
    let { amount } = this.state;
    amount.value =
      this.props.totalAmount > 0
        ? parseFloat(this.props.totalAmount).toFixed(2)
        : "";
    if (this.props.paymentPlanDetails.payment_plans_payments) {
      this.setState({
        totalAmount: this.props.totalAmount,
        planList: this.props.paymentPlanDetails.payment_plans_payments,
        amount,
      });
    }
    this.getConsumerPaymentDetails();
    this.getOrganizationData();
    this.reloadData();
  }

  async getOrganizationData() {
    await API.getOrganizationData().then((data) => {
      this.setState({
        companyData: data,
      });
    });
  }

  updatePaymentInfoState = () => {
    this.setState((state) => ({
      ...state,
      amount: {
        value: "",
        hasError: true,
      },
      isSubmit: false,
      isLoading: false,
    }));
  };

  reloadData = () => {
    // this.props.reloadData();
    this.getPlaidStatus();
    this.getConsumerPaymentDetails();
  };
  getPlaidStatus = () => {
    API.getPlaidStatus(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code &&
        data.status_code == 200
      ) {
        if (data.data) {
          this.setState({
            isPlaidVerified: data.data.plaid_verified,
            plaidStatusMessage: data.data.plaid_status_message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getConsumerPaymentDetails = () => {
    API.getConsumerPaymentDetails(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.getBankStatus(data.data);
        this.setState({ paymentDetails: data.data }, () => {
          let details = this.state.paymentDetails;
          if (
            details &&
            details.payment_preferences &&
            details.payment_preferences.payment_portal
          ) {
            this.setState({ isPaymentPortal: true });
            let flag = true;
            if (details.payment_preferences.credit_card) {
              this.setState({ isCC: true, paymentMethod: "CC" });
              flag = false;
            }
            if (details.payment_preferences.ach) {
              this.setState({ isACH: true, paymentMethod: "ACH" });
              flag = false;
            }
            if (
              data.data.payment_sources &&
              data.data.payment_sources.length > 0
            ) {
              const cardSource = data.data.payment_sources.find(
                (source) => source.object === "card"
              );
              if (cardSource) {
                this.setState({
                  savedCard: {
                    status: true,
                    cardID: cardSource.source_id,
                  },
                });
              }
            }
            if (flag) {
              this.setState({ paymentMethod: "", showBankDetails: true });
            }
          }
        });
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  handleFilterChange = (type) => {
    this.setState({ paymentMethod: type });
  };

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    if (!newPartialInput.amount.value) {
      newPartialInput.amount.hasError = true;
      this.setState((state) => ({
        ...state,
        ...newPartialInput,
        errorMessage: "Please enter payment amount",
      }));
    } else {
      if (this.props.totalAmount > 0) {
        if (parseFloat(newPartialInput.amount.value) >= 1.0) {
          newPartialInput.amount.hasError = false;

          this.setState((state) => ({
            ...state,
            ...newPartialInput,
          }));
        } else {
          newPartialInput.amount.hasError = true;
          this.setState((state) => ({
            ...state,
            ...newPartialInput,
            errorMessage:
              "You can not enter amount greater than total selected payment(s) amount",
          }));
        }
      } else if (parseFloat(newPartialInput.amount.value) >= 1.0) {
        this.setState((state) => ({
          ...state,
          ...newPartialInput,
        }));
      }
    }
  }

  acceptPayment = (amount, cardID, savedCard, stripeToken) => {
    let { paymentPlanDetails } = this.props;
    let { paymentDetails, totalAmount } = this.state;
    let currentDate = new Date();
    let ind = this.getCardId("card");
    let payload = {
      paymentInfo: {
        payment_plan_id: paymentPlanDetails.id,
        amount: parseFloat(amount),
        payment_type: "CreditCard",
        payment_date: moment.utc(currentDate).format("YYYY-MM-DD"),
        source_id: savedCard ? cardID : "",
        // ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
        card_token: savedCard ? "" : stripeToken,
        return_url: window.location.href,
      },
    };

    this.setState({ isSubmit: true, isLoading: true });
    API.consumerMakePaymentForPlans(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 302) {
        window.location.href = data.redirect_url;
      } else if (data && data.status_code && data.status_code == 200) {
        this.setState({ amount: { value: "" }, isSubmit: false });
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.props.reloadData();
        this.getConsumerPaymentDetails();
      } else {
        this.setState({ isSubmit: false });
        this.props.updateModalState(true, "ERROR", {
          message: data.message ? data.message : data.error,
        });
      }
      this.setState({ isLoading: false });
    });
  };

  getCardId = (type) => {
    let { paymentDetails } = this.state;
    //get card source id
    let ind =
      paymentDetails && paymentDetails.payment_sources
        ? paymentDetails.payment_sources.findIndex((i) => i["object"] == type)
        : -1;
    return ind;
  };

  handleDisconnect = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to disconnect your bank?",
      confirmClick: this.callDisconnectBank,
      closeOnClickOutside: true,
    });
  };

  callDisconnectBank = () => {
    let { paymentPlanDetails } = this.props;
    let { paymentDetails } = this.state;

    //get card source id
    let ind = this.getCardId("bank_account");
    let payload = {
      invoice_id: paymentPlanDetails.id,
      card_id: ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };

    this.setState({ isLoading: true });
    API.disconnectBank(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getConsumerPaymentDetails();
        this.reloadData();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
      this.setState({ isLoading: false });
    });
  };

  removeCard = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to remove your card?",
      confirmClick: this.callRemoveCard,
      closeOnClickOutside: true,
    });
  };

  callRemoveCard = () => {
    let { paymentDetails } = this.state;
    let ind = this.getCardId("card");
    let payload = {
      card_id: ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };
    this.setState({ isLoading: true });
    API.removeCard(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getConsumerPaymentDetails();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
      this.setState({ isLoading: false });
    });
  };

  createOrUpdateStripeAccount = (stripeToken, cardID, saveCard) => {
    if (saveCard) {
      this.addCreditCard(stripeToken, cardID, saveCard);
    } else {
      this.acceptPayment(
        this.state.amount.value,
        cardID,
        saveCard,
        stripeToken
      );
    }
  };

  addCreditCard = (stripeToken, cardID, saveCard) => {
    this.setState({ isSubmit: true, isLoading: true });
    API.createOrUpdateStripeCustomerId(this.props.user, stripeToken).then(
      (data) => {
        if (data && data.cardAdded) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          if (saveCard) {
            this.acceptPayment(this.state.amount.value, cardID, saveCard);
          } else {
            this.getConsumerPaymentDetails();
          }
        } else {
          this.setState({ isSubmit: false, isLoading: false });
          this.props.updateModalState(true, "ERROR", {
            message: data.error
              ? data.error
              : data.message || "An unknown error occurred",
          });
        }
      }
    );
  };

  getTaxAmount = (invoice) => {
    let amount = 0.0;
    if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
      invoice.tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          amount = parseFloat(item.amount);
        }
      });
    }
    return amount;
  };

  getSubTotalAmount = (invoice) => {
    let subTotal = 0.0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((item) => {
        if (
          item.detail_type === "SalesItemLineDetail" &&
          item.item_name &&
          !item.item_name.includes("Early Payer Dis") &&
          !item.item_name.includes("Slow Payer Penalty") &&
          !item.item_name.includes("Invoice Credit")
        ) {
          item.amount = parseFloat(item.amount);
          subTotal = subTotal + item.amount;
        }
      });
    }
    return subTotal && subTotal > 0 ? subTotal.toFixed(2) : "0.00";
  };

  getInvoiceTotalAmount = (invoice) => {
    let amount = 0.0;
    let tax = this.getTaxAmount(invoice);
    let slowPayer = this.getSlowPayerAmount(invoice);
    let subtotal = this.getSubTotalAmount(invoice);
    let invoiceCred = this.getInvoiceCreditAmount(invoice);

    amount =
      parseFloat(subtotal) +
      parseFloat(tax) +
      parseFloat(slowPayer) -
      parseFloat(invoiceCred);
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getPaidAmount = (value) => {
    let amount = "0.00";
    if (value && value !== null && value !== undefined) {
      if (typeof value === "string") {
        amount = parseFloat(value.replace(",", "")).toFixed(2);
      } else if (typeof value === "number") {
        amount = parseFloat(value).toFixed(2);
      }
    }

    return amount;
  };

  getPaidEarlyPayerDiscount = (invoice) => {
    let amount = 0;
    if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
      invoice.tax_and_discount_line.map((line) => {
        if (line.item_name && line.item_name.includes("Discounts given")) {
          amount = parseFloat(line.amount);
        }
      });
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getSlowPayerAmount = (invoice) => {
    let amount = 0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((lineItem) => {
        if (lineItem.item_name === "Slow Payer Penalty") {
          amount = amount + parseFloat(lineItem.amount);
        }
      });
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getInvoiceCreditAmount = (invoice) => {
    let amount = 0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((lineItem) => {
        if (lineItem.item_name === "Invoice Credit") {
          amount = parseFloat(Math.abs(lineItem.amount));
        }
      });
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  validateForm = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.hasError = false;
    } else {
      amount.hasError = true;
    }
    this.setState({ amount });
    return !amount.hasError;
  };

  createPlaidPayment = () => {
    if (this.validateForm()) {
      let { paymentDetails } = this.state;
      let ind = this.getCardId("bank_account");
      let currentDate = new Date();
      let payload = {
        paymentInfo: {
          amount: parseFloat(this.state.amount.value),
          payment_plan_id: this.props.paymentPlanDetails.id,
          payment_type: "ach_debit",
          payment_date: moment.utc(currentDate).format("YYYY-MM-DD"),
          source_id:
            ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
          return_url: window.location.href,
        },
      };
      this.setState({ isLoading: true });
      API.consumerMakePaymentForPlans(this.props.user, payload).then((data) => {
        if (data && data.status_code) {
          if (data.status_code == 302) {
            window.location.href = data.redirect_url;
          } else if (data.status_code == 200) {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
            this.props.reloadData();
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
          // this.state.amount.value = "";
          // this.state.amount.hasError = false;
          this.setState({
            // amount: this.state.amount,
            isCheck: false,
            isLoading: false,
          });
          this.props.reloadData();
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };
  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  sendDataBackToServer = (token, metadata) => {
    let payload = {
      public_token: token,
      metadata: metadata,
      payment_amount: this.state.amount.value,
      // invoice_id: this.props.paymentPlanDetails.id,
    };
    API.sendPlaidData(this.props.user, payload).then((data) => {
      if (data && data.status_code) {
        if (data.status_code == 200) {
          if (data.message === "Your account verification is pending.") {
            this.props.updateModalState(true, "SUCCESS", {
              title: "Info",
              message: data.message,
            });
          } else {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
          }
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        // this.state.amount.value = "";
        // this.state.amount.hasError = false;
        // this.setState({ amount: this.state.amount, isCheck: false });
        // this.props.getInvoiceDetails();
        this.reloadData();
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  handlePlansSelection = (list) => {
    let { amount } = this.state;
    let temp = list.filter((item) => item.isChecked);
    let total = 0;
    this.setState({ planList: list }, () => {
      temp.map((item) => {
        total = total + item.balance;
      });
      amount.value = total;
      amount.hasError = false;
      this.setState({ amount, totalAmount: total }, () => {
        this.handleInputBlur();
      });
    });
    // this.props.updateModalState(false, "PAY_INVOICES_LIST", {});
  };
  handleEditPayment = () => {
    let { planList } = this.state;
    let props = {
      plan_list: planList,
      primary_color: this.props.primary_color,
      from: "payment_plan",
      handleSubmit: this.handlePlansSelection,
    };
    this.props.updateModalState(true, "PAY_INVOICES_LIST", props);
  };

  toggleLoading = (flag) => {
    this.setState({ isLoading: flag });
  };

  getInputField = (payments, index) => {
    return (
      <div
        key={"key" + index}
        className={`input-container amount-input merge-inputs`}
        style={{ marginRight: 10 }}
      >
        <tr>
          <td
            style={{
              fontFamily: "Open Sans",
              fontWeight: 600,
              fontSize: 14,
              paddingRight: 10,
            }}
          >
            {payments.name}:
          </td>
          <td>{payments.value}</td>
        </tr>
      </div>
    );
  };

  creditCardView = () => {
    const { stripePubKey, isSubmit, primary_color } = this.props;
    return (
      <StripeProvider apiKey={stripePubKey}>
        <Elements>
          <ConsumerAddAccountForm
            primary_color={primary_color}
            updateModalState={this.props.updateModalState}
            handleFormSubmit={this.createOrUpdateStripeAccount}
            paymentAmount={this.state.amount.value}
            updatePaymentInfoState={this.updatePaymentInfoState}
            italicStyle={styles.italicStyle}
            validateAmount={true}
            isSubmit={isSubmit}
            checkboxDivStyle={styles.checkboxDiv}
            checkboxStyle={styles.checkbox}
          />
        </Elements>
      </StripeProvider>
    );
  };

  getBankStatus = (paymentDetails) => {
    let connected = false;
    if (paymentDetails && paymentDetails.payment_sources) {
      paymentDetails.payment_sources.map((item) => {
        if (item.object && item.object == "bank_account") connected = true;
      });
    }
    if (this.state.isPlaidVerified) {
      if (this.state.plaidStatusMessage === null) {
        this.setState({ plaidStatus: "Instant" });
      } else {
        this.setState({ plaidStatus: "Verified" });
      }
      connected = true;
    } else {
      if (this.state.plaidStatusMessage === "pending_manual_verification") {
        this.setState({ plaidStatus: "Pending" });
      } else {
        this.setState({ plaidStatus: "Failed" });
      }
      connected = false;
    }
    return connected;
  };

  showBankStatus = (paymentDetails) => {
    let style =
      this.state.plaidStatus === "Instant" ||
      this.state.plaidStatus === "Verified"
        ? { color: "#409D57", fontFamily: "Open Sans", fontWeight: 600 }
        : this.state.plaidStatus === "Pending"
        ? { color: "#c2c20e", fontFamily: "Open Sans", fontWeight: 600 }
        : { color: "#fb5c82", fontFamily: "Open Sans", fontWeight: 600 };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "0.5em",
        }}
      >
        <div style={{ ...styles.primText, lineHeight: "150%", marginRight: 5 }}>
          Status:
        </div>
        <div style={style}>
          {this.state.plaidStatus === "Instant" ||
          this.state.plaidStatus === "Verified"
            ? "CONNECTED"
            : this.state.plaidStatus === "Pending"
            ? "PENDING"
            : "NOT CONNECTED"}
        </div>
        <br />
      </div>
    );
  };

  renderPaymentTabs = () => {
    const { isACH, isCC, paymentMethod } = this.state;
    const creditCardTab = isCC && (
      <div style={{ position: "relative" }}>
        <button
          title='All'
          className={`option-tabs-btns all bt-sp-l${
            paymentMethod == "CC" ? " active" : ""
          }`}
          style={{
            width: "110px",
            margin: 0,
            padding: "1px",
            whiteSpace: "nowrap",
            color: this.checkActiveButtonTab(paymentMethod == "CC"),
          }}
          onClick={() => this.handleFilterChange("CC")}
        >
          Credit Card
        </button>
        {paymentMethod == "CC" && (
          <span
            style={{
              width: "108px",
              height: "4px",
              backgroundColor: "#fff",
              position: "absolute",
              bottom: "-2px",
              left: "2px",
            }}
          ></span>
        )}
      </div>
    );

    const bankTab = isACH && (
      <div style={{ position: "relative" }}>
        <button
          title='All'
          className={`option-tabs-btns all bt-sp-l${
            paymentMethod === "ACH" ? " active" : ""
          }`}
          style={{
            width: "120px",
            padding: "1px",
            margin: 0,
            whiteSpace: "nowrap",
            color: this.checkActiveButtonTab(paymentMethod == "ACH"),
          }}
          onClick={() => this.handleFilterChange("ACH")}
        >
          Bank Transfer
        </button>
        {paymentMethod == "ACH" && (
          <span
            style={{
              width: "118px",
              height: "4px",
              backgroundColor: "#fff",
              position: "absolute",
              bottom: "-2px",
              left: "1px",
            }}
          ></span>
        )}
      </div>
    );

    return (
      <div style={styles.tabPanel}>
        {creditCardTab}
        {bankTab}
      </div>
    );
  };

  MakePayment = () => {
    const { stripePubKey, paymentPlanDetails, isSubmit, totalAmount } =
      this.props;
    const {
      isCC,
      isACH,
      amount,
      paymentMethod,
      isPlaidVerified,
      plaidStatus,
      isCheck,
      isPaymentPortal,
      showBankDetails,
      paymentDetails,
    } = this.state;
    let detailsCheck = isPaymentPortal
      ? showBankDetails
        ? false
        : true
      : false;

    let paymentApplied = 0;
    if (paymentPlanDetails.payment_plans_payments) {
      paymentPlanDetails.payment_plans_payments.map((item) => {
        paymentApplied = item.paid_amount
          ? parseFloat(item.paid_amount.replace(",", "")) + paymentApplied
          : paymentApplied;
      });
    }
    const Data = [
      {
        title: "Original Balance",
        value: ` ${this.getPaidAmount(
          paymentPlanDetails.total_in_dollars_as_currency
        )}`,
      },
      {
        title: "Discount Applied",
        value: ` ${this.getPaidAmount(paymentPlanDetails.adjustment_amount)}`,
        isSubtract: true,
      },
      {
        title: "Payment(s) Applied",
        value: ` ${parseFloat(paymentApplied).toFixed(2)}`,
      },
      {
        title: "Outstanding Balance",
        value: ` ${this.getPaidAmount(paymentPlanDetails.balance_as_currency)}`,
      },
    ];
    let cardInd =
      paymentDetails &&
      paymentDetails.payment_sources &&
      paymentDetails.payment_sources.length
        ? paymentDetails.payment_sources.findIndex(
            (item) => item["object"] == "card"
          )
        : -1;

    return (
      <div className='make-payment-panel' style={{ marginTop: 0 }}>
        <Panel>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label style={{ fontSize: "18px" }}>Payment Amount</label>
            </div>
            <div>
              <button
                id='submit-consumer-paymant'
                className='cta'
                style={{
                  backgroundColor: `${this.props.primary_color}`,
                  border: "1px solid #a1a1a1",
                }}
                onClick={(e) => this.handleEditPayment(e)}
              >
                View Payment(s)
              </button>
            </div>
          </div>
          <div className='payment-container-mg'>
            <div
              className={`input-container consumer-input-payment  pay-input-value`}
            >
              {amount.value ? <span className='span-doller'>$</span> : null}
              <input
                placeholder='Enter Payment Amount'
                type='number'
                pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                value={amount.value}
                onBlur={this.handleInputBlur}
                onChange={(event) =>
                  this.handleInputChange({
                    amount: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
            </div>
            <div>
              {" "}
              {amount.hasError && (
                <div style={styles.error}>{this.state.errorMessage}</div>
              )}
            </div>
          </div>
          <div>
            <label style={{ fontSize: "18px", marginBottom: "0.5em" }}>
              Payment Plan Summary
            </label>
          </div>
          {/* {discAmount && parseFloat(discAmount) > 0 ? (
                        <div>
                            <div style={{ paddingBottom: "15px" }}>
                                Invoice must be paid in full to receive Early Payer Discount.
                            </div>
                        </div>
                    ) : null} */}
          {Data.map((item, i) => {
            return (
              <div
                key={"panel" + i}
                style={{
                  display: "flex",
                  padding: 10,
                  backgroundColor: i % 2 == 0 ? "#dbe9f2" : "#FFF",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    fontWeight:
                      item.title == "Outstanding Balance" ||
                      item.title == "Early Payer Balance Due"
                        ? 600
                        : "auto",
                  }}
                >
                  {item.title}
                  <div style={{ fontSize: "11px" }}>
                    {item.subTitle ? `(${item.subTitle})` : ""}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight:
                      item.title == "Outstanding Balance" ||
                      item.title == "Early Payer Balance Due"
                        ? 600
                        : "auto",
                  }}
                >
                  {<span>&nbsp;&nbsp;</span>}${item.value ? item.value : "0.00"}
                </div>
              </div>
            );
          })}
        </Panel>
        {detailsCheck ? (
          <Panel>
            <div
              className={`pay-amount-input amount-input`}
              style={{ marginTop: 0 }}
            >
              <label>Select Payment Method</label>
            </div>
            {/* <div style={styles.tabPanel}>
              {isACH ? (
                <button
                  title='All'
                  className={`task-filter-btns all bt-sp-l${
                    paymentMethod == "ACH" ? " active" : ""
                  }`}
                  style={{
                    ...styles.button,
                    marginRight: "2em",
                    backgroundColor: this.checkActiveButtonTab(
                      paymentMethod == "ACH"
                    ),
                    // border: "2px solid #a1a1a1",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => this.handleFilterChange("ACH")}
                >
                  Bank Transfer
                </button>
              ) : null}
              {isCC ? (
                <button
                  title='All'
                  className={`task-filter-btns all bt-sp-l${
                    paymentMethod == "CC" ? " active" : ""
                  }`}
                  style={{
                    ...styles.button,
                    backgroundColor: this.checkActiveButtonTab(
                      paymentMethod == "CC"
                    ),
                    // border: "2px solid #a1a1a1",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => this.handleFilterChange("CC")}
                >
                  Credit Card
                </button>
              ) : null}
            </div> */}
            <div>{this.renderPaymentTabs(cardInd > -1)}</div>
            <div className={`panal-content-switch`}>
              {paymentMethod == "ACH" ? (
                <div>
                  {this.showBankStatus(paymentDetails)}
                  {this.state.plaidStatus === "Instant" ||
                  this.state.plaidStatus === "Verified" ? (
                    <div>
                      <div style={styles.primText}>Your bank is connected:</div>
                      <br />
                      {paymentDetails && paymentDetails.payment_sources
                        ? paymentDetails.payment_sources.map((item) => {
                            if (item.object && item.object == "bank_account") {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        ...styles.primText,
                                        lineHeight: "120%",
                                        fontSize: 14,
                                      }}
                                    >
                                      {item.bank_name}
                                    </div>
                                    <span>xxxxxxx{item.last4}</span>
                                  </div>
                                  <button
                                    onClick={this.handleDisconnect}
                                    className='cta plaid-disconnect-btn'
                                  >
                                    Disconnect
                                  </button>
                                </div>
                              );
                            }
                          })
                        : null}
                    </div>
                  ) : (
                    <div>
                      <div style={styles.primText}>
                        Connect your bank:
                        <br />
                        <br />
                        <span style={styles.secText}>
                          Easily make payments using ACH bank transfers by
                          connecting to your bank using one of the verification
                          methods below
                        </span>
                      </div>
                      <br />
                      <div style={styles.secText}>
                        Connect immediately with Plaid (immediate) (Recommended)
                        (i) Plaid provides the quickest way to collect and
                        verify your bank information and allows you to make
                        payments immediately.
                      </div>
                    </div>
                  )}
                  <br />
                  <div style={styles.checkboxDiv}>
                    <input
                      name='ach'
                      type='checkbox'
                      checked={isCheck}
                      style={styles.checkbox}
                      onChange={(event) =>
                        this.setState({ isCheck: event.target.checked })
                      }
                    />
                    <div style={styles.acceptAgr}>
                      I authorize {this.state.companyData.clientName} to
                      electronically debit my account and, if necessary,
                      electronically credit my account to correct erroneous
                      debits.
                    </div>
                  </div>
                  <br />
                  <ACHPlaidLink
                    primary_color={this.props.primary_color}
                    auth={true}
                    user={this.props.user}
                    amount={amount.value}
                    invoice_id={paymentPlanDetails.id}
                    plaidStatus={isPlaidVerified}
                    plaidLevel={plaidStatus}
                    isCheck={isCheck && amount.value && !amount.hasError}
                    toggleLoading={this.toggleLoading}
                    updateModalState={this.props.updateModalState}
                    sendDataBackToServer={this.sendDataBackToServer}
                    createPlaidPayment={this.createPlaidPayment}
                    disconnectBank={this.callDisconnectBank}
                  />
                  {/* <br />
              <br />
              <div style={{ lineHeight: "120%" }}>
                Connect with microdeposits (1-2 Days) (i) two small deposits are
                send to your bank account and will appear on your online bank
                statement with a description that includes ATMS followed by the
                two microdeposit amounts.
              </div>
              <br />
              <button disabled className="cta mg-brand2-color">
                Connect using microdeposits
              </button> */}
                </div>
              ) : stripePubKey && paymentMethod == "CC" ? (
                <div>
                  {cardInd > -1 ? (
                    <div>
                      <h4>Credit Card Details</h4>
                      <label style={{ marginBottom: "1em" }}>
                        Card Number: xxxx-xxxx-xxxx-
                        {paymentDetails.payment_sources[cardInd].last4}
                      </label>
                      <a
                        className='text-link'
                        onClick={(e) => this.removeCard(e)}
                      >
                        Remove Card
                      </a>
                      {isSubmit ? (
                        <div style={{ marginTop: "2em" }}>
                          <div className='spinner' />
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <button
                            id='submit-consumer-paymant'
                            className='cta'
                            disabled={
                              amount.value && !amount.hasError ? false : true
                            }
                            style={{
                              backgroundColor: `${this.props.primary_color}`,
                              border: "1px solid #a1a1a1",
                              padding: "7px",
                            }}
                            onClick={(e) =>
                              this.acceptPayment(
                                this.state.amount.value,
                                this.state.savedCard.cardID,
                                this.state.savedCard.status
                              )
                            }
                          >
                            Submit Payment
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    this.creditCardView()
                  )}
                </div>
              ) : null}
            </div>
          </Panel>
        ) : (
          <Panel>
            {paymentDetails &&
              paymentDetails.payment_data &&
              Object.entries(paymentDetails.payment_data).map(
                ([key1, rowData], i) => {
                  if (rowData && rowData.length) {
                    return (
                      <div className='bank-details' key={key1}>
                        <label style={{ marginBottom: "0.3em" }}>{key1}</label>
                        {rowData && rowData.length
                          ? rowData.map((item, index) => {
                              return this.getInputField(item, index);
                            })
                          : null}
                      </div>
                    );
                  }
                }
              )}
          </Panel>
        )}
      </div>
    );
  };

  render() {
    const { error, isLoading } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div>
        {this.MakePayment()}
        {isLoading ? (
          <div style={styles.loader}>
            <AppCanvasLoader />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ConsumerExpressPaymentForPaymentPlan;
