import React from 'react';
import { Container, Paper, Typography, Grid, makeStyles, } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        display: "flex",
        marginTop: '10%'
    },

});

function BadRequest(params) {

    const classes = useStyles();

    return (

        <Container maxWidth="md" component={Paper} elevation={12} className={classes.root}>
            <Grid container spacing={2} item xl={12} lg={12} mg={12} sm={12} xs={12}>

                <Grid item xl={12} lg={12} mg={12} sm={12} xs={12} align='center' className='p-5'>
                    <Typography variant='h2'>{`400 Bad Request`}</Typography>
                </Grid>

                <Grid item xl={12} lg={12} mg={12} sm={12} xs={12} align='center' className='p-5'>
                    <Typography variant='h4'>{`Page Not Found`}</Typography>
                </Grid>

            </Grid>
        </Container>
    )

}



export default BadRequest;