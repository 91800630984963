import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import shortid from "shortid";
import ArrowLink from "../../components/ArrowLink";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import MenuItem from "../../components/MenuItem";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
import * as API from "../../utils/api";
import { showToast } from "../../utils/helpers";
import BellIcon from "../../assets/images/header/bell_grey_ic.svg";
import userIcon from "../../assets/images/header/question.svg";
import homeIcon from "../../assets/images/header/home.svg";
// Components
import Logo from "../Logo";
import Emitter from "../../utils/event-emitter";
import NotificationModal from "./Modals/Client/NotificationModal";

class AppConsumerHeader extends Component {
  static propTypes = {
    path: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    let { user } = props;
    this.state = {
      showSearchHeader: false,
      searchOptions: [],
      id: "",
      dropdownIsActive: false,
      showCount: false,
      showNtfcn: false,
      toggle: false,
      logo_url: "",
      rowAction: [],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    Emitter.on("HAS_OFFER", (hasOffer) => {
      this.setState({ showCount: hasOffer }, () => {
        this.setRowActions();
      });
    });
    this.setRowActions();
    await API.getLogoColors()
      .then((response) => {
        this.setState({
          ...this.state,
          logo_url: response.data.logo_url,
          toggle: response.data.logo_url !== null,
        });
      })
      .catch(() => {});
  }
  handleHereClick = () => {
    let { history } = this.props;
    this.setState({ showNtfcn: false });
    Emitter.emit("Notification_Clicked", true);
    // history.push(`/client/settings`, { openQB: true });
  };
  handleClickOutside = () => {
    this.setState({ showNtfcn: false });
  };

  setRowActions = () => {
    let { user, disableNav } = this.props;
    let data = [
      {
        title: this.getFullName(user),
        dataBehavior: "profile",
        icon: (
          <div
            className={`app-client-header-user-avatar app-client-header-icon app-client-header-user-avatar-consumer`}
          >
            <span>{this.renderUserInitials(user)}</span>
          </div>
        ),
      },
      {
        title: "Notifications",
        dataBehavior: "notifications",
        icon: (
          <div className='app-client-header-icon n-icon-mobile'>
            {this.state.showCount && (
              <div className='notification-count'>1</div>
            )}
            <img src={BellIcon} width='20' height='20' />
          </div>
        ),
      },
      {
        title: "Home",
        dataBehavior: "accounts",
        icon: (
          <div className='app-client-header-icon'>
            <img src={homeIcon} width='20' height='20' />
          </div>
        ),
      },
      {
        title: "Get Help",
        dataBehavior: "get-help",
        icon: (
          <div className='app-client-header-icon'>
            <img src={userIcon} width='24' height='24' />
          </div>
        ),
      },
      {
        title: "Sign Out",
        dataBehavior: "sign-out",
        icon: <div></div>,
      },
    ];

    // if (this.props.hideHeadNavOpt) {
    //   data = [
    //     {
    //       title: "Sign Out",
    //       dataBehavior: "sign-out",
    //       icon: (
    //         <div className="app-client-header-icon">
    //           <img src={SignOutIcon} width="20" height="20" />
    //         </div>
    //       ),
    //     },
    //   ];
    // }
    this.setState({ rowAction: data });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    Emitter.off("HAS_OFFER", false);
  }

  updateDimensions = () => {
    // this.setState({ showSearchHeader: false });
  };

  handleKeyPress = () => {
    this.handleSearch(this.state.id);
  };

  handleSearch = (id) => {
    if (id) {
      this.setState(
        {
          id,
        },
        () => {
          API.searchInvoiceForConsumer(this.props.user, this.state.id).then(
            (data) => {
              if ((typeof data != "undefined" || data != null) && !data.error) {
                if (data.data) {
                  this.setState({
                    searchOptions: data.data,
                    dropdownIsActive: true,
                  });
                } else {
                  this.setState({ searchOptions: [], dropdownIsActive: true });
                }
              } else {
                this.setState({ searchOptions: [] });
                this.setState((state) => ({
                  ...state,
                  error: {
                    hasAnyError: true,
                    statusCode: data
                      ? data.status ||
                        (data.message && data.message == "Access denied."
                          ? 401
                          : 500) ||
                        500
                      : 500,
                  },
                }));
              }
            }
          );
        }
      );
    } else {
      this.setState({ id, searchOptions: [], dropdownIsActive: false });
    }
  };

  getFullName = (user) => {
    let fullName = "";
    if (user.first_name) {
      fullName = user.first_name;
    }
    if (user.last_name) {
      fullName = fullName ? fullName + " " + user.last_name : user.last_name;
    }
    return fullName;
  };

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label='Back'
        arrow='left'
        path={prevLink}
        optionalClassName='onboarding-header-prev-link'
      />
    );
  }

  // User Info
  renderUserInitials = (user) => {
    let initials = "";
    if (user.first_name && user.last_name) {
      initials = user.first_name.charAt(0) + user.last_name.charAt(0);
    }
    return initials.toUpperCase();
  };

  renderUserName = (user) => {
    let userName = user.first_name + " " + user.last_name;

    return userName;
  };

  handleClickAction = (item) => {
    let { history } = this.props;
    if (item && item.id) {
      this.setState(
        { showSearchHeader: false, id: "", searchOptions: [] },
        () => {
          history.push(`/dashboard/accounts/invoices/${item.id}`);
        }
      );
    }
  };
  showNotification = () => {
    this.setState({ showNtfcn: true });
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "profile":
        history.push(`/dashboard/profile`);
        break;
      case "notifications":
        this.state.showCount && this.showNotification();
      case "accounts":
        history.push(`/dashboard/accounts/inovices`);
        break;
      case "get-help":
        history.push(`/dashboard/help`);
        break;
      case "sign-out":
        history.push(`/sign-out`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  handleSearchClick = (event, flag) => {
    this.setState({ showSearchHeader: flag, id: "", searchOptions: [] });
  };

  overflowMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <a
          onClick={(event) => this.handleSearchClick(event, true)}
          className='app-client-header-mobile-search'
        />
        <HeaderOverflowMenu showThreeDots={true} clearData={this.clearData}>
          {this.state.rowAction.map((rowAction) => {
            return (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses='drop-down-options'
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  clearData = () => {
    this.setState({ id: "", dropdownIsActive: false });
  };

  showSearchDropDown = () => {
    return (
      <HeaderOverflowMenu
        dropdownIsActive={this.state.dropdownIsActive}
        clearData={this.clearData}
        optionalClasses='search-menu'
        innerOptionalClasses='inner-menu-list scrollbar'
      >
        {this.state.searchOptions && this.state.searchOptions.length ? (
          this.state.searchOptions.map((item) => {
            return (
              <MenuItem
                key={shortid.generate()}
                handleClick={(event) => {
                  this.handleClickAction(item);
                }}
              >
                {item.invoice_number}
              </MenuItem>
            );
          })
        ) : (
          <div className='search-empty-list'>
            <p>Result not found</p>
          </div>
        )}
      </HeaderOverflowMenu>
    );
  };

  renderSearchHeader = () => {
    let { hideNav } = this.props;
    return (
      <header
        className={`app-client-header header-search-height${
          hideNav ? " consumer-mobile-header" : ""
        }`}
      >
        <a
          className='back-link'
          onClick={(event) => this.handleSearchClick(event, false)}
        />
        <input
          autoComplete='off'
          type='text'
          name='search'
          placeholder='Search'
          value={this.state.id}
          className='client-search-header'
          onChange={(event) => this.handleSearch(event.target.value)}
        />
        {this.state.id && this.showSearchDropDown()}
      </header>
    );
  };

  handleShutDownIntercom = () => {
    window.intercom("shutdown");
  };
  renderNotification = () => {
    return (
      <NotificationModal handleClickOutside={this.handleClickOutside}>
        <div class='dropdown-menu active notification-view'>
          <div className='notification-text'>
            You have a payment plan waiting for {"  "}
            <a onClick={this.handleHereClick} style={{ cursor: "pointer" }}>
              review and acceptance.
            </a>{" "}
          </div>
        </div>
      </NotificationModal>
    );
  };

  render() {
    let { user, prevLink, logoPath, appName, hideNav, accountHolder } =
      this.props;
    let { showSearchHeader, error, toggle } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showSearchHeader) {
      return this.renderSearchHeader();
    }
    if (window.location)
      if (user) {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: user.intercom_id,
          name: user.first_name, // Full name
          email: user.email, // Email address
          created_at: new Date(user.verified_at).getTime(), // Signup date as a Unix timestamp
          user_hash: user.user_hash,
        };

        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/mmxnsa15'
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = `https://widget.intercom.io/widget/${user.intercom_id}`;
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }
    return (
      <header
        className={`app-client-header${
          hideNav ? " consumer-mobile-header" : ""
        }${user.client_admin ? " client-admin-header" : ""}`}
        style={{ padding: "22px 40px 22px 14px", zIndex: "10" }}
      >
        {this.state.showNtfcn && this.renderNotification()}

        {prevLink && this.renderPrevLink(prevLink)}
        {toggle && (
          <Logo
            user={this.props.user}
            isDynamicLogo={true}
            logoUrl={this.state.logo_url}
            linkTitle={appName}
            hideNav={hideNav}
            accountHolder={accountHolder}
          />
        )}

        <div className={`app-client-header-content`}>
          <div className='overflow-menu'>{this.overflowMenu()}</div>
          <div className='app-menu'>
            {hideNav ? (
              <div className='client-search-input-container'>
                <input
                  autoComplete='off'
                  type='text'
                  name='search'
                  placeholder='Search'
                  value={this.state.id}
                  className='client-search-input consumer-search-input'
                  onChange={(event) => this.handleSearch(event.target.value)}
                />
                <span
                  className='client-search-icon consumer-search-icon'
                  onClick={() => this.handleKeyPress()}
                />
                {this.state.id && this.showSearchDropDown()}
              </div>
            ) : (
              <div></div>
            )}

            <div className='app-client-header-user'>
              <a
                title='Profile'
                className={`app-client-header-user-avatar app-client-header-user-avatar-consumer`}
                href='/dashboard/profile'
              >
                <span>{this.renderUserInitials(user)}</span>
              </a>
            </div>

            <div className='app-client-header-menu'>
              <a
                title='Notifications'
                className='social-link header-link'
                onClick={
                  this.state.showCount ? this.showNotification : () => {}
                }
                style={{ cursor: "pointer" }}
              >
                <img src={BellIcon} width='24' height='24' />
                {this.state.showCount && (
                  <div className='notification-count'>1</div>
                )}
              </a>

              <a
                title='Home'
                className='social-link header-link'
                href='/dashboard/accounts/inovices'
              >
                <img src={homeIcon} width='20' height='20' />
              </a>
              <a
                title='Get Help'
                className='social-link header-link'
                href='/dashboard/help'
              >
                <img src={userIcon} width='24' height='24' />
              </a>
              <Link
                className='app-client-header-user-nav-link cl-nav-link'
                to='/sign-out'
                onClick={() => {
                  this.handleShutDownIntercom();
                }}
              >
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(AppConsumerHeader);
