import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";

import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import Tabs from "../../components/Tabs";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";

class IndividualTermsSettings extends Component {
  state = {
    isLoading: true,
    termsList: [],
    currentTerm: this.props.currentTerm ? this.props.currentTerm : "",
  };

  componentDidMount() {
    this.getClientTermsList();
  }

  //calls when "Add term" button is click
  handleAddTermClick = (e) => {
    e.preventDefault();
    const { user } = this.props;
    let props = {
      user: user,
      activeTab: this.state.activeTab,
      reload: this.reload,
    };
    //opens Add Service popup modal
    this.props.updateModalState(true, "ADD_TERM", props);
  };

  //pagination api call when scroll reached bottom of the screen
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          // this.getClientServices(true);
        }
      );
    }
  };
  //calls on row arrow click
  rowExpanderClick = (e) => {
    let props = {
      user: this.props.user,
      activeTab: this.state.activeTab,
      reload: this.reload,
      rowData: e.rowData,
    };
    //opens Edit Service popup modal
    this.props.updateModalState(true, "ADD_TERM", props);
  };

  reload = (flag) => {
    this.getClientTermsList();
    this.props.fetchData();
  };

  getClientTermsList = (reload, tabLoad) => {
    let { currentTerm } = this.state;

    this.setState({ isLoading: true });
    if (tabLoad) this.setState({ tabLoader: true });
    API.getTermsListInSettings(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let newData = data.data;

          if (currentTerm.id) {
            let itemIndex = newData.findIndex((record) => {
              return currentTerm.id === record.id;
            });
            let newRecord = newData[itemIndex];
            let oldRecord = newData[0];
            newData.splice(itemIndex, 1, oldRecord);
            newData.splice(0, 1, newRecord);
          }

          this.setState((state) => ({
            ...state,
            termsList: newData,
            isLoading: false,
            //hasMoreData: data.data.length < 10 ? false : true,
          }));
        }
        this.setState({
          //   isLoading: false,
          showLoader: false,
          tabLoader: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tabLoader: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  UpdateClientTermAPI = (payload, index) => {
    // this.setState({isLoading: true})
    API.clientUpdateIndividualTermsInSettings(
      this.props.user,
      this.props.accountId,
      payload
    ).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });

        this.setState(
          {
            isLoading: false,
            showLoader: false,
            tabLoader: false,
            currentTerm: data.data.term_name,
          },
          () => {
            this.getClientTermsList();
            this.props.fetchData(data.data.term_name);
          }
        );
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tabLoader: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
    });
  };

  setDefaultTerm = (currentTerm, index) => {
    let payload = {
      terms_id: currentTerm.id,
    };

    //TO_DO
    this.UpdateClientTermAPI(payload, index);
  };

  getFormatedEarlyPayerDiscRate = (item) => {
    let amount = "";
    if (
      item.early_payer_discount_rate_percent_based &&
      item.early_payer_discount_rate_percent_based === true
    ) {
      if (item.early_payer_discount_rate) {
        amount = item.early_payer_discount_rate + "%";
      } else {
        amount = "0%";
      }
    } else {
      if (item.early_payer_discount_rate) {
        amount = "$" + item.early_payer_discount_rate;
      } else {
        amount = "$0";
      }
    }
    return amount;
  };

  getFormatedLatePayerPenaltyRate = (item) => {
    let amount = "";
    if (
      item.late_payer_penalty_rate_percent_based &&
      item.late_payer_penalty_rate_percent_based === true
    ) {
      if (item.late_payer_penalty_rate) {
        amount = item.late_payer_penalty_rate + "%";
      } else {
        amount = "0%";
      }
    } else {
      if (item.late_payer_penalty_rate) {
        amount = "$" + item.late_payer_penalty_rate;
      } else {
        amount = "$0";
      }
    }
    return amount;
  };

  handleIsDefault = (e, item, index) => {
    // e.stopImmediatePropagation();
    // e.stopPropagation();
    // this.setState({isDefaultValue: e.target.checked})
    if (e.target.checked) {
      this.termDefaultConfirmAlert(item, index);
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: "Atleast one term should be set as a default term.",
        title: "Alert",
      });
    }
  };

  termDefaultConfirmAlert = (item, index) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: `Are you sure you want to override organization level default term to '${item.name}' ?`,
      confirmClick: () => {
        this.setDefaultTerm(item, index);
      },
      closeOnClickOutside: true,
      cancelClick: () => {},
      isCancelCallback: true,
    });
  };

  //returns formatted table and card data
  getObjectListData = (data) => {
    let { currentTerm } = this.state;
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Name",
        style: { width: "10%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Due Days",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Early Payer Disc. Days",
        style: { width: "20%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Early Payer Disc. Rate",
        style: { width: "20%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Late Payer Penalty Rate",
        style: { width: "20%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Default",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { width: "5%", display: "flex", justifyContent: "center" },
      },
    ];
    columns = data.map((item, index) => {
      if (currentTerm.id) {
        if (index == 0) {
          item.is_default = true;
        } else {
          item.is_default = false;
        }
      }
      return {
        primaryRow: {
          key: "Name",
          title: item.name ? item.name : "",
        },
        secondaryColumns: [
          {
            title: item.name ? item.name : "",
            hideForMobile: true,
            style: {
              width: "10%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Due Days",
            title: item.due_days ? item.due_days : "0",
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Early Payer Disc. Days",
            title: item.early_payer_discount_days
              ? item.early_payer_discount_days
              : "0",
            style: { width: "20%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Early Payer Disc. Rate",
            title: this.getFormatedEarlyPayerDiscRate(item),
            style: { width: "20%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Late Payer Penalty Rate",
            title: this.getFormatedLatePayerPenaltyRate(item),
            style: { width: "20%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Default",
            title: (
              <div className='tax-value-wrapper'>
                <input
                  className='tax-checkbox'
                  type='checkbox'
                  name='isDefault'
                  id='isDefault'
                  title={
                    item.is_default
                      ? "This is default term."
                      : "Click to set is default"
                  }
                  style={{ pointerEvents: "auto", cursor: "pointer" }}
                  defaultChecked={item.is_default}
                  checked={item.is_default}
                  onChange={(e) => this.handleIsDefault(e, item, index)}
                />
                {/* <span>{row.is_taxable == true ? this.getTaxValue(row) : 'Not Taxable'}</span> */}
              </div>
            ),
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          // {
          //     rowType: "arrow-click",
          //     optionalClasses: "expander",
          // },
        ],
        rowData: item,
      };
    });

    return {
      headings,
      columns,
    };
  };

  showLoader = () => {
    return (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const { isLoading, termsList } = this.state;

    return isLoading ? (
      this.showLoader()
    ) : (
      <div className='consumer-help '>
        <Helmet>
          <title>{appName} | Terms</title>
        </Helmet>
        <div className='big-device-grad '>
          {/* <div className="goods-services-utility term-setting-utility">
                        <div>
                            <div className="he-hed">Terms</div>
                        </div>
                        <input
                            type="submit"
                            value={"+ Add Term"}
                            className="cta mg-brand2-color"
                            style={{ width: "auto" }}
                            onClick={(event) => this.handleAddTermClick(event)}
                        />
                    </div> */}
          <br />

          <BottomScrollListener
            offset={50}
            onBottom={this.handleContainerOnBottom}
            debounce={0}
          >
            {(scrollRef) => (
              <div ref={scrollRef} className='send-invoice-scroll scrollbar'>
                {/* cardview for mobile device */}
                <ClientsCardView
                  data={this.getObjectListData(termsList)}
                  rowActionClick={this.rowExpanderClick}
                />
                {/* list view for big devices */}
                <InvoiceObjectList
                  data={this.getObjectListData(termsList)}
                  hideForMobile={true}
                  isEditable={false}
                  optionalClasses='scrollbar in-sc'
                  rowActionClick={this.rowExpanderClick}
                  optionalTableClasses='terms-settings-list'
                />
                {this.state.showLoader && (
                  <div>
                    <AppCanvasLoader />
                  </div>
                )}
              </div>
            )}
          </BottomScrollListener>
        </div>
      </div>
    );
  }
}

export default IndividualTermsSettings;
