import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import shortid from "shortid";
import ArrowLink from "../ArrowLink";
import HeaderMenuItem from "../HeaderMenuItem";
import MenuItem from "../MenuItem";
import HeaderOverflowMenu from "../ObjectList/HeaderOverflowMenu";
import * as API from "../../utils/api";
import { showToast } from "../../utils/helpers";
import userIcon from "../../assets/images/header/question.svg";
import homeIcon from "../../assets/images/header/home.svg";
// Components
import Logo from "../../screens/ClientsPortal/sso/static-pages/StaticLogo";

class AppConsumerHeaderStatic extends Component {
  static propTypes = {
    path: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    let { user } = props;
    this.state = {
      showSearchHeader: false,
      searchOptions: [],
      id: "",
      dropdownIsActive: false,
      rowAction: [
        {
          title: this.getFullName(user),
          dataBehavior: "profile",
          icon: (
            <div
              className={`app-client-header-user-avatar app-client-header-icon app-client-header-user-avatar-consumer`}
            >
              <span>{"TU"}</span>
            </div>
          ),
        },
        {
          title: "Home",
          dataBehavior: "accounts",
          icon: (
            <div className="app-client-header-icon">
              <img src={homeIcon} width="20" height="20" />
            </div>
          ),
        },
        {
          title: "Get Help",
          dataBehavior: "get-help",
          icon: (
            <div className="app-client-header-icon">
              <img src={userIcon} width="24" height="24" />
            </div>
          ),
        },
        {
          title: "Sign Out",
          dataBehavior: "sign-out",
          icon: <div></div>,
        },
      ],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    // this.setState({ showSearchHeader: false });
  };

  handleKeyPress = () => {
    this.handleSearch(this.state.id);
  };

  handleSearch = (id) => {
    if (id) {
      this.setState(
        {
          id,
        },
        () => {
          // API.searchInvoiceId(this.props.user, this.state.id).then((data) => {
          //   if ((typeof data != "undefined" || data != null) && !data.error) {
          //     if (data.data) {
          //       this.setState({
          //         searchOptions: data.data,
          //         dropdownIsActive: true,
          //       });
          //     } else {
          //       this.setState({ searchOptions: [], dropdownIsActive: true });
          //     }
          //   } else {
          //     this.setState({ searchOptions: [] });
          //     this.setState((state) => ({
          //       ...state,
          //       error: {
          //         hasAnyError: true,
          //         statusCode: data
          //           ? data.status ||
          //             (data.message && data.message == "Access denied."
          //               ? 401
          //               : 500) ||
          //             500
          //           : 500,
          //       },
          //     }));
          //   }
          // });
        }
      );
    } else {
      this.setState({ id, searchOptions: [], dropdownIsActive: false });
    }
  };

  getFullName = (user) => {
    let fullName = "";
    if (user.first_name) {
      fullName = user.first_name;
    }
    if (user.last_name) {
      fullName = fullName ? fullName + " " + user.last_name : user.last_name;
    }
    return fullName;
  };

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label="Back"
        arrow="left"
        path={prevLink}
        optionalClassName="onboarding-header-prev-link"
      />
    );
  }

  renderUserName = (user) => {
    let userName = user.first_name + " " + user.last_name;

    return userName;
  };

  handleClickAction = (item) => {
    let { history } = this.props;
    if (item && item.id) {
      this.setState(
        { showSearchHeader: false, id: "", searchOptions: [] },
        () => {
          history.push(`/client/invoices/${item.id}`);
        }
      );
    }
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "profile":
        history.push(`/dashboard/profile`);
        break;
      case "accounts":
        history.push(`/dashboard/accounts/invoices`);
        break;
      case "get-help":
        history.push(`/dashboard/help`);
        break;
      case "sign-out":
        history.push(`/sign-out`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  handleSearchClick = (event, flag) => {
    this.setState({ showSearchHeader: flag, id: "", searchOptions: [] });
  };

  overflowMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <a
          onClick={(event) => this.handleSearchClick(event, true)}
          className="app-client-header-mobile-search"
        /> */}
        <HeaderOverflowMenu showThreeDots={true} clearData={this.clearData}>
          {this.state.rowAction.map((rowAction) => {
            return (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses="drop-down-options"
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  clearData = () => {
    this.setState({ id: "", dropdownIsActive: false });
  };

  showSearchDropDown = () => {
    return (
      <HeaderOverflowMenu
        dropdownIsActive={this.state.dropdownIsActive}
        clearData={this.clearData}
        optionalClasses="search-menu"
        innerOptionalClasses="inner-menu-list scrollbar"
      >
        {this.state.searchOptions && this.state.searchOptions.length ? (
          this.state.searchOptions.map((item) => {
            return (
              <MenuItem
                key={shortid.generate()}
                handleClick={(event) => {
                  this.handleClickAction(item);
                }}
              >
                {item.invoice_number}
              </MenuItem>
            );
          })
        ) : (
          <div className="search-empty-list">
            <p>Result not found</p>
          </div>
        )}
      </HeaderOverflowMenu>
    );
  };

  renderSearchHeader = () => {
    let { hideNav } = this.props;
    return (
      <header
        className={`app-client-header header-search-height${
          hideNav ? " consumer-mobile-header" : ""
        }`}
      >
        <a
          className="back-link"
          onClick={(event) => this.handleSearchClick(event, false)}
        />
        <input
          autoComplete="off"
          type="text"
          name="search"
          placeholder="Search"
          value={this.state.id}
          className="client-search-header"
          onChange={(event) => this.handleSearch(event.target.value)}
        />
        {this.state.id && this.showSearchDropDown()}
      </header>
    );
  };

  handleShutDownIntercom = () => {
    window.intercom("shutdown");
  };

  render() {
    let { user, prevLink, logoPath, appName, hideNav, accountHolder } =
      this.props;
    let { showSearchHeader, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showSearchHeader) {
      return this.renderSearchHeader();
    }
    if (window.location)
      if (user) {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: user.intercom_id,
          name: user.first_name, // Full name
          email: user.email, // Email address
          created_at: new Date(user.verified_at).getTime(), // Signup date as a Unix timestamp
          user_hash: user.user_hash,
        };

        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/mmxnsa15'
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = `https://widget.intercom.io/widget/${user.intercom_id}`;
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }

    return (
      <header
        className={`app-client-header${
          hideNav ? " consumer-mobile-header" : ""
          }${user.client_admin ? " client-admin-header" : ""}`}
          style={{ padding: "22px 40px 22px 14px"}}
      >
        {prevLink && this.renderPrevLink(prevLink)}
        <Logo
          
        />
        <div className={`app-client-header-content`}>
          <div className="overflow-menu">{this.overflowMenu()}</div>
          {localStorage.getItem("header") === "true" && (
            <div className="app-menu">
              <div className="app-client-header-user">
                <button
                  title="Profile"
                  className={`app-client-header-user-avatar app-client-header-user-avatar-consumer`}
                >
                  <span>{"TU"}</span>
                </button>
              </div>
              <div className="app-client-header-menu">
                <button title="Home" className="social-link header-link">
                  <img src={homeIcon} width="20" height="20" />
                </button>
                <button title="Get Help" className="social-link header-link">
                  <img src={userIcon} width="24" height="24" />
                </button>
                <button className="app-client-header-user-nav-link cl-nav-link">
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </header>
    );
  }
}

export default withRouter(AppConsumerHeaderStatic);
