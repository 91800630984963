import React, { Component } from "react";
import MakeGoodLogo from "../../assets/icons/MG-logo.png";

class ClientsLogo extends Component {
  render() {
    let {color} = this.props;
    let style = {color: color}
    return (
      <div className="consumer-mg">
        <div className="consumer-mg-poweredby" style={style}>Powered by</div>
        <img className="consumer-mg-logo" src={MakeGoodLogo} />
      </div>
    );
  }
}

export default ClientsLogo;
