import React from "react";
import ReactDOM from "react-dom";
import JavascriptTimeAgo from "javascript-time-ago";

import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import "./assets/styles/style.css";
import { Cookies } from "react-cookie";
import registerServiceWorker from "./registerServiceWorker";

// The desired locales.
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import { ImportProvider } from "./Context/ImportContext";
import ImportDataPolling from "./Polling/ImportDataPolling";

// Initialize the desired locales.
JavascriptTimeAgo.locale(en);
JavascriptTimeAgo.locale(ru);
const cookies = new Cookies();
const userFromCookies =
  cookies.get("authUser", { path: "/", secure: true }) || {};

ReactDOM.render(
  <BrowserRouter>
    <ImportProvider>
      <CookiesProvider>
        <ScrollToTop>
          <ImportDataPolling user={userFromCookies} />
          <App />
        </ScrollToTop>
      </CookiesProvider>
    </ImportProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

registerServiceWorker();
