import $ from "jquery";
import { toNumber } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";
import FileBase64 from "react-file-base64";
import { Redirect } from "react-router-dom";
// Packages
import Select from "react-select";
import MaskedTextInput from "react-text-mask";
import InfoIcon from "../../../../assets/images/quickbooks/ic_info_gray.png";
// API
import * as API from "../../../../utils/api";
import {
  amountMask,
  getStrippedDollarAmount,
  isValidDate,
} from "../../../../utils/helpers";
import AppCanvasLoader from "../../AppCanvasLoader";
import Panel from "../../Panel";
// Components
import Modal from "../Modal";
import Emitter from "../../../../utils/event-emitter";

const reData = {
  flag: true,
  allowToSelect: true,
  qty: 0,
};

class RefundAmountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        refundMethod: {
          value: "",
          hasError: false,
        },
        paymentAmount: {
          value: "",
          hasError: false,
        },
        refundAmount: {
          value: "",
          hasError: false,
        },
        refundAccount: {
          value: "",
          hasError: false,
        },
        refundDate: {
          value: moment(new Date()).format("MM/DD/YYYY"),
          hasError: false,
        },
        refundNote: {
          value: "",
          hasError: false,
        },
        document: {
          value: "",
          hasError: false,
        },
      },
      type: {
        value: "Full",
      },
      accountsList: [],
      invoiceLineList: [],
      invoiceLineOptions: [],
      lineDummyList: [],
      taxLines: [],
      refundValidateData: { ...reData },
      subTotal: 0,
      taxAmount: 0,
      totalAmount: 0,
      invoiceLineError: false,
      disableFull: false,
      isLoading: true,
      showLoader: false,
      slowPayerPenalty: {},
      error: {
        hasAnyError: false,
        statusCode: 200,
        message: "",
      },
    };
  }

  componentDidMount() {
    let { currentRow, invoiceDetails } = this.props;
    let { input } = this.state;

    // this.getFormattedAmount(
    //   this.props.optionalProps.debtInfo.criteria_discount_percent_based?
    //       parseFloat(this.props.optionalProps.debtInfo.balance) - (parseFloat(this.props.optionalProps.debtInfo.total_amount) / 100) * parseFloat(this.props.optionalProps.debtInfo.criteria_discount)
    //      : parseFloat(this.props.optionalProps.debtInfo.balance) - parseFloat(this.props.optionalProps.debtInfo.criteria_discount)

    let criteriaDiscountFlag = false;
    if (
      invoiceDetails &&
      invoiceDetails.criteria_discount_days &&
      invoiceDetails.criteria_discount_days > 0
    ) {
      let startdate = invoiceDetails.date;
      let nn = moment(
        moment(startdate)
          .add(invoiceDetails.criteria_discount_days, "d")
          .format("YYYY/MM/DD")
      );
      let today = new Date();
      let targetDate = moment(nn);
      var month = today.getUTCMonth() + 1; //months from 1-12
      var day = today.getUTCDate();
      var year = today.getUTCFullYear();

      let newdate = year + "/" + month + "/" + day;
      let momentToday = moment(newdate);
      let diffNumber = targetDate.diff(momentToday, "days");
      if (diffNumber > 0) {
        criteriaDiscountFlag = true;
      }
    }

    let invoiceLineAmount = 0;
    let taxAmount = 0;
    if (invoiceDetails.invoice_lines) {
      invoiceDetails.invoice_lines.map((invoiceLineObj, i) => {
        if (invoiceLineObj.item_name == "Invoice Credit") {
          invoiceLineAmount =
            invoiceLineAmount + parseFloat(invoiceLineObj.amount);
        }
      });
    }
    if (invoiceDetails.tax_and_discount_line) {
      invoiceDetails.tax_and_discount_line.map((taxLineObj, i) => {
        if (taxLineObj.item_name == "Tax") {
          taxAmount = taxAmount + parseFloat(taxLineObj.amount);
        }
      });
    }

    let earlyPayerBalance = 0;
    if (criteriaDiscountFlag) {
      if (invoiceDetails.criteria_discount_percent_based) {
        earlyPayerBalance =
          (parseFloat(invoiceDetails.total_amount) / 100) *
          parseFloat(invoiceDetails.criteria_discount);
      } else {
        earlyPayerBalance = parseFloat(invoiceDetails.criteria_discount);
      }
    }

    // check invoice amount and refund amount for full refund.
    // if invoice amount and paid amount is same then only full refund option is enabled
    if (
      this.getFormattedAmt(
        parseFloat(invoiceDetails.total_amount) + invoiceLineAmount + taxAmount
      ) !=
      this.getFormattedAmt(
        (parseFloat(currentRow.amount) + earlyPayerBalance).toFixed(2)
      )
    ) {
      this.state.type.value = "Partial";
      this.setState({ disableFull: true, type: this.state.type });
    }
    //set payment value
    input.paymentAmount.value = parseFloat(currentRow.amount).toFixed(2);
    this.getInvoiceRefundData();
    this.setState({ input });
  }

  getInvoiceRefundData = () => {
    let invoice_id = this.props.invoiceDetails.id;
    API.getInvoiceRefundData(this.props.user, invoice_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          if (data.data.account_list && data.data.account_list.length) {
            //refund account list, format data for select options
            data.data.account_list.map((account) => {
              account.label = account.name;
              account.value = account.name;
            });
            this.setState({ accountsList: data.data.account_list });
          }
          if (data.data.invoice_line && data.data.invoice_line.length) {
            //invoice lines data
            data.data.invoice_line.map((item, i) => {
              item.label = item.item_name;
              item.value = item.item_name;
            });
            this.setState({
              invoiceLineOptions: JSON.parse(
                JSON.stringify(data.data.invoice_line)
              ),
              lineDummyList: JSON.parse(JSON.stringify(data.data.invoice_line)),
            });
          }
          if (data.data.tax_line && data.data.tax_line.length) {
            this.setState({ taxLines: data.data.tax_line });
          }
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //make amount value to fix to 2 decimal points
  getFormattedAmt = (value) => {
    if (value) return parseFloat(value).toFixed(2);
    return "0.00";
  };

  handleModalClose = () => {
    if (!this.state.error.hasAnyError) {
      this.props.hideModal();
    }
  };

  //calls on form text input value change
  handleRefundInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  // get selected file check its type and save filename, extension, size in mb and other details
  getFiles(files) {
    let newInputState = { ...this.state.input.document };
    let imgExtension = files[0].type;
    let fileName = moment(new Date()).format("MMDDYYYY");
    let extensionFlag = false;
    if (!imgExtension) {
      imgExtension = files[0].name.split(".").pop();
    }

    if (
      imgExtension.toLowerCase() === "image/png" ||
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg" ||
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf"
    ) {
      extensionFlag = true;
    }
    if (files[0].file && files[0].file.size > 10000000) {
      extensionFlag = false;
    } else {
      let file_size =
        files[0].file && files[0].file.size
          ? files[0].file.size / (1024 * 1024)
          : 0;
      newInputState.fileSizeInMB = parseFloat(file_size).toFixed(2);
    }
    if (imgExtension.toLowerCase() === "image/png") {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".png"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg"
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".jpeg"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf" ||
      imgExtension.toLowerCase() === "application/jpeg"
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".pdf"
      }`;
    }

    if (extensionFlag) {
      newInputState.value = files[0].base64;
      newInputState.hasError = false;
    } else {
      newInputState.hasError = true;
    }

    this.setState({
      ...this.state,
      input: {
        ...this.state.input,
        document: newInputState,
      },
    });
  }

  //submit refund payment form
  invoiceRefundPayment = (paymentInfo) => {
    $("#form-1").replaceWith('<div class="spinner"></div>');
    API.invoiceRefundPayment(this.props.user, paymentInfo).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code === 200
      ) {
        if (data.status_code && data.status_code == 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        //reloads the invoice details screen after api success
        this.props.reloadPage();
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data.error,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  payloadLineItems = () => {
    let { invoiceLineList, lineDummyList } = this.state;

    let data = [];
    if (invoiceLineList && invoiceLineList.length) {
      invoiceLineList.map((item) => {
        data.push({
          id: item.id,
          qty: item.qty,
          item_type: item.item_type,
          service_id: item.service_id,
        });
      });
    } else {
      lineDummyList.map((item) => {
        data.push({
          id: item.id,
          qty: item.qty,
          item_type: item.item_type,
          service_id: item.service_id,
        });
      });
    }
    return data;
  };

  handleMakePaymentClick = (event) => {
    event.preventDefault();
    const { input, type } = this.state;
    let { invoiceDetails, currentRow } = this.props;
    if (this.validateForm()) {
      //data payload object
      let paymentInfo = {
        invoice_id: invoiceDetails.id,
        payment_transaction_id: currentRow.payment_transaction_id,
        refund_amount:
          type.value == "Full"
            ? input.paymentAmount.value
            : input.refundAmount.value,
        items: this.payloadLineItems(),
        full_refund: type.value == "Full",
        date: input.refundDate.value,
        note: input.refundNote.value,
        document: {
          document: input.document.value,
          document_name: input.document.fileExtension,
          file_size_in_mb: input.document.fileSizeInMB,
          description: "",
          name: "Refund_" + invoiceDetails.invoice_number,
          document_type_id: "",
        },
        payment_method: input.refundMethod.value,
        account_id: this.props.user.qb_connect
          ? input.refundAccount.account.id
          : "",
      };

      this.invoiceRefundPayment(paymentInfo);
    }
  };

  //calls when radio buttons value change
  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      type: {
        ...state.type,
        value: newPartialInput.type.value,
      },
    }));
  }

  //invoice line qty input value change
  handleChange = (e, item) => {
    let value = e.target.value >= 0 ? e.target.value : 0;
    let newData = this.validateInvoiceLinesForRefundAmt(value, item);
    if (e.target.value < item.qty) {
      if (newData.qtyFlag && newData.remainingQty) value = newData.remainingQty;
      this.setState({ invoiceLineList: this.updateInvoiceLines(value, item) });
    }
  };

  //update tax value
  updateTaxValue = (value, item, flag) => {
    let data = flag
      ? this.updateInvoiceLines(value, item)
      : this.state.invoiceLineList;
    let subTotal = this.getSubTotal(data, [], "");
    let taxAmount = this.getTax();
    let totalAmount = this.getTotal(taxAmount, subTotal);
    this.state.input.refundAmount.value = totalAmount;
    this.setState({
      taxAmount,
      subTotal,
      totalAmount,
      input: this.state.input,
    });
  };

  //update the invoice line value for current qty input and return invoice line list
  updateInvoiceLines = (value, item) => {
    let { invoiceLineList } = this.state;
    let data = invoiceLineList;
    if (data && data.length) {
      let ind = data.findIndex((e) => e.id == item.id);
      if (ind > -1) {
        data[ind].qty = value;
        if (data[ind].qty > -1 && data[ind].unit_price > 0) {
          data[ind].amount = data[ind].qty * data[ind].unit_price;
        }
      }
    }
    return data;
  };

  //calls when select invoice line. and update tax, total, subtotal values
  onInvoiceLineSelect = (event) => {
    this.state.slowPayerPenalty = { ...event };
    let { invoiceLineList } = this.state;
    let count = 0;
    let newList = JSON.parse(JSON.stringify(invoiceLineList));
    if (newList && newList.length) {
      let ind = newList.findIndex((e) => e.id == event.id);
      if (ind == -1) {
        //intially set quantity and amount to 0.
        if (event) {
          event.qty = 0;
          event.amount = 0;
        }
        newList.push(event);
      }
    } else {
      if (event) {
        event.qty = 1;
        event.amount = event.unit_price;
        count = 1;
      }
      newList.push(event);
    }

    this.setState({ invoiceLineList: newList, invoiceLineError: false }, () => {
      setTimeout(() => {
        this.validateInvoiceLinesForRefundAmt(count, event);
      }, 100);
    });

    //after select invoice line wait to calculate quantity
    this.setState({ showLoader: true });
    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 400);
  };

  //calls when click on remove invoice line item and update tax, total, subtotal values
  handleRemoveClick = (item) => {
    let { invoiceLineList } = this.state;
    if (invoiceLineList && invoiceLineList.length) {
      let ind = invoiceLineList.findIndex((e) => e.id == item.id);
      if (ind > -1) {
        invoiceLineList.splice(ind, 1);
      }
    }
    this.setState({ invoiceLineList }, () => {
      let indCheck =
        invoiceLineList && invoiceLineList.length && invoiceLineList.length == 1
          ? true
          : false;
      this.validateInvoiceLinesForRefundAmt(
        indCheck ? invoiceLineList[0].qty : 0,
        invoiceLineList && invoiceLineList.length && invoiceLineList.length == 1
          ? invoiceLineList[0]
          : []
      );
    });
  };

  //invoice line sub total calculations
  getSubTotal = (invoiceLineList, data, nextCount) => {
    let subAmt = 0;
    let newList = JSON.parse(JSON.stringify(invoiceLineList));
    let { slowPayerPenalty } = this.state;
    if (newList && newList.length) {
      newList.map((item) => {
        if (item.item_name === "Slow Payer Penalty") {
          if (item.id == data.id) {
            item.amount = slowPayerPenalty
              ? this.getFormattedAmt(slowPayerPenalty.amount)
              : item.amount;
          }
          if (item.qty > 0 || item.id == data.id) {
            if (subAmt <= slowPayerPenalty.amount) {
              subAmt = subAmt + parseFloat(slowPayerPenalty.amount);
            }
          }
        } else {
          if (item.id == data.id) {
            item.amount = item.unit_price
              ? this.getFormattedAmt(item.unit_price) * nextCount
              : item.amount;
          }

          if (item.qty > 0 || item.id == data.id)
            subAmt = subAmt + parseFloat(item.amount);
        }
      });
    }
    return subAmt;
  };

  //get next cycle data before count increase
  getNextCycleTax = (data, nextCount) => {
    let { taxLines, invoiceLineList } = this.state;
    let newList = JSON.parse(JSON.stringify(invoiceLineList));
    if (newList && newList.length) {
      newList.map((item) => {
        if (item.id == data.id) {
          item.amount = item.unit_price
            ? this.getFormattedAmt(item.unit_price) * nextCount
            : item.amount;
        }
      });
    }
    let tax = 0;
    let newSubAmt = 0;
    if (newList && newList.length) {
      newList.map((c) => {
        if (c.is_taxable) {
          newSubAmt += parseFloat(c.amount);
        }
      });
    }
    if (taxLines && taxLines.length) {
      let discPer = 0;
      taxLines.map((line) => {
        if (line.detail_type == "TaxLineDetail" && newSubAmt > 0) {
          discPer = discPer + parseFloat(line.discount_percent);
        }
      });
      tax = (parseFloat(newSubAmt) * parseFloat(discPer)) / 100;
    }
    return tax;
  };

  //invoice line tax calculations based on subtotal
  getTax = (subTotal, forSingle, isTaxable) => {
    let { taxLines, invoiceLineList } = this.state;
    let newList = JSON.parse(JSON.stringify(invoiceLineList));
    let tax = 0;
    let newSubAmt = 0;
    if (newList && newList.length) {
      newList.map((c, i) => {
        if (c.is_taxable) {
          newSubAmt += parseFloat(c.amount);
        }
      });
    }
    if (taxLines && taxLines.length) {
      newSubAmt = forSingle && isTaxable ? subTotal : newSubAmt;
      let discPer = 0;
      taxLines.map((line) => {
        if (line.detail_type == "TaxLineDetail" && newSubAmt > 0) {
          discPer = discPer + parseFloat(line.discount_percent);
        }
      });
      tax = (parseFloat(newSubAmt) * parseFloat(discPer)) / 100;
    }
    return tax;
  };

  //total of invoice lines based on invoice lines and tax
  getTotal = (taxAmount, subTotal) => {
    let total = parseFloat(subTotal) + parseFloat(taxAmount);
    return subTotal > 0 ? total.toFixed(2) : 0;
  };

  //for partial refund if unit price greater than payment amount calculate qty.
  getStepsIncCalc = (row, sub_total) => {
    let { input } = this.state;
    const increment = [
      { index: 2, value: 0.1 },
      { index: 2, value: 0.01 },
      { index: 3, value: 0.001 },
      { index: 4, value: 0.0001 },
      { index: 5, value: 0.00001 },
      { index: 6, value: 0.000001 },
      { index: 7, value: 0.0000001 },
    ];
    const finalAmount = sub_total ? sub_total : input.paymentAmount.value;
    let isContinue = true;
    let inc = increment[0].value,
      total = 0,
      tax = 0;
    let decimalValue = increment[0].index;
    //calculations to get nearest total value
    let preTax = this.getTax(finalAmount, true, row.is_taxable);
    let preQty = (finalAmount - preTax) / row.unit_price;

    increment.every((item) => {
      decimalValue = item.index;
      total = 0;
      for (
        let index = preQty;
        total <= finalAmount;
        index = index + item.value
      ) {
        inc = parseFloat(index).toFixed(decimalValue);
        total = row.unit_price * inc;
        tax = this.getTax(total, true, row.is_taxable);
        total += tax;
        if (
          total >= finalAmount &&
          parseFloat(total).toFixed(2) == parseFloat(finalAmount).toFixed(2)
        ) {
          isContinue = false;
          break;
        }
      }
      return isContinue;
    });

    total = row.unit_price * inc;
    return {
      total: total,
      qty: inc ? parseFloat(inc).toFixed(decimalValue) : inc,
      tax: this.getTax(total, true, row.is_taxable),
    };
  };

  //validate the selected lines, check final amount before incrementing quantity and then update quantity.
  validateInvoiceLinesForRefundAmt = (nextCount, item) => {
    let isContinue = true;
    let { invoiceLineList, lineDummyList, input, refundValidateData } =
      this.state;
    refundValidateData = { ...reData };
    if (invoiceLineList && invoiceLineList.length) {
      invoiceLineList.map((data) => {
        //if unit price of line item greater than payment amount
        // then calculate the closest total value by using getStepsIncCalc() func
        let chck =
          data.unit_price &&
          parseFloat(data.unit_price) > input.paymentAmount.value &&
          data.id == item.id;
        if (invoiceLineList.length > 1 && chck) {
          isContinue = true;
        } else if (data.unit_price && chck) {
          refundValidateData.allowToSelect = false;
          isContinue = false; // c0
          this.getNearestRefundValue(data);
        }
      });
    }

    let newFlag = true;
    let qtyFlag = false;
    let remainingQty = 0;
    //check line qty, not allow to enter qty more than line qty
    let id = lineDummyList.findIndex((o) => o.id == item.id);
    if (
      isContinue &&
      id > -1 &&
      lineDummyList[id].qty &&
      parseInt(lineDummyList[id].qty) < nextCount
    ) {
      // isContinue = false;  // fixes
      newFlag = false;
      if (parseInt(lineDummyList[id].qty) == nextCount - 1) {
        remainingQty = lineDummyList[id].qty;
        isContinue = true;
        qtyFlag = true;
      }
    }

    if (
      isContinue &&
      id > -1 &&
      lineDummyList[id].qty &&
      parseInt(lineDummyList[id].qty) == nextCount
    ) {
      newFlag = false;
    }

    let subTotal = this.getSubTotal(invoiceLineList, item, nextCount);
    let taxAmount = this.getNextCycleTax(item, nextCount);
    let totalAmount = this.getTotal(taxAmount, subTotal);

    if (
      isContinue &&
      nextCount <= item.qty &&
      parseFloat(totalAmount) >= parseFloat(input.paymentAmount.value)
    ) {
      isContinue = false;
      newFlag = false;
    }

    if (
      isContinue &&
      parseFloat(totalAmount) <= parseFloat(input.paymentAmount.value)
    ) {
      if (nextCount > -1 && refundValidateData.allowToSelect) {
        isContinue =
          totalAmount > 0 ? isContinue : !nextCount ? isContinue : false;
        this.updateTaxValue(nextCount, item, isContinue);
      }
    } else {
      isContinue = false;
      if (nextCount > -1 && refundValidateData.allowToSelect) {
        if (nextCount == 1 && invoiceLineList && invoiceLineList.length == 1) {
          this.getNearestRefundValue(item);
          refundValidateData.allowToSelect = false;
        } else {
          isContinue =
            parseFloat(totalAmount) == parseFloat(input.paymentAmount.value)
              ? true
              : false; //c5
          newFlag = true; //fixes
          // this.updateTaxValue(nextCount, item, isContinue);
          if (newFlag) {
            this.getNearestRefundValue(item, newFlag);
          }
        }
      }
    }
    if (parseInt(nextCount) === 0) {
      //fixes
      isContinue = true;
    }

    refundValidateData.flag = isContinue;
    refundValidateData.qtyFlag = qtyFlag;
    refundValidateData.remainingQty = remainingQty;
    this.setState({ refundValidateData, input, invoiceLineList });
    return refundValidateData;
  };

  getSubTotalForNearestValue = (data) => {
    let { invoiceLineList, taxLines, input } = this.state;
    let { invoiceDetails } = this.props;
    let total = 0;
    if (invoiceLineList.length) {
      invoiceLineList.map((item) => {
        if (item.id != data.id) {
          total = total + parseFloat(item.amount);
        }
      });
    }
    return {
      total: total,
      subTotal:
        input.paymentAmount.value -
        total +
        (invoiceDetails.adjustment ? toNumber(invoiceDetails.adjustment) : 0),
    };
  };

  getNearestRefundValue = (data, getNearestForNext) => {
    let { input } = this.state;
    let { invoiceDetails } = this.props;
    if (getNearestForNext) {
      let SubNearestData = this.getSubTotalForNearestValue(data);
      if (SubNearestData && SubNearestData.subTotal > 0) {
        let newTotal = this.getStepsIncCalc(data, SubNearestData.subTotal);
        let tax = newTotal && newTotal.tax ? newTotal.tax : 0;
        let newQty = newTotal && newTotal.qty ? newTotal.qty : 0;
        data.qty = newQty;
        if (data.qty > -1) {
          data.amount = newQty * data.unit_price;
          let tlAmt =
            data.amount +
            tax -
            (invoiceDetails.adjustment
              ? toNumber(invoiceDetails.adjustment)
              : 0);
          input.refundAmount.value = this.getFormattedAmt(
            SubNearestData.total + tlAmt
          );
          this.setState({
            taxAmount: tax,
            subTotal: SubNearestData.total + data.amount,
            totalAmount: SubNearestData.total + tlAmt,
            input: input,
          });
        }
      }
    } else {
      let newTotal = this.getStepsIncCalc(data);
      let tax = newTotal && newTotal.tax ? newTotal.tax : 0;
      let newQty = newTotal && newTotal.qty ? newTotal.qty : 0;
      data.qty = newQty;
      if (data.qty > -1) {
        data.amount = newQty * data.unit_price;
        let tlAmt = data.amount + tax;
        input.refundAmount.value = this.getFormattedAmt(tlAmt);
        this.setState({
          taxAmount: tax,
          subTotal: data.amount,
          totalAmount: tlAmt,
          input: input,
        });
      }
    }
  };

  validateRefundMethod = (value) => {
    let { currentRow } = this.props;
    if (
      value == "MakeGood Payments" &&
      currentRow &&
      (currentRow.payment_type == "CreditCard" ||
        currentRow.payment_type == "Stripe" ||
        currentRow.payment_type == "ach_debit")
    ) {
      return false;
    } else if (
      currentRow &&
      (currentRow.payment_type == "Check" || currentRow.payment_type == "Cash")
    ) {
      return false;
    } else return true;
  };

  //validate refund form values
  validateForm = () => {
    let errorsArePresent = false;
    // Determine which input group to check for errors
    let newInputState = { ...this.state.input };
    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "refundMethod") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        } else if (this.validateRefundMethod(value.value)) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "refundAmount" && this.state.type.value != "Full") {
        if (value.value === "" || getStrippedDollarAmount(value.value) < 1) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "paymentAmount") {
        if (value.value === "" || getStrippedDollarAmount(value.value) < 1) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "refundDate") {
        if (!isValidDate(value.value) || value.value === "") {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "refundAccount" && this.props.user.qb_connect) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "refundNote") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "document") {
        if (value.value && value.hasError) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    if (
      this.state.type.value != "Full" &&
      this.state.invoiceLineList &&
      this.state.invoiceLineList.length < 1
    ) {
      errorsArePresent = true;
      this.setState({ invoiceLineError: true });
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
      return false;
    } else {
      return true;
    }
  };

  //show error messages based on error
  renderErrorMessage(input) {
    let message = "";
    if (input === "refundMethod") {
      if (this.validateRefundMethod(this.state.input.refundMethod.value)) {
        message =
          "Payment not processed via MakeGood Payments, please select a different refund method.";
      } else {
        message = "Please select refund method.";
      }
    } else if (input === "refundAccount") {
      message = "Please select refund account.";
    } else if (input === "paymentAmount") {
      message = "Please enter amount.";
    } else if (input === "refundAmount") {
      message = "Please enter amount.";
    } else if (input === "refundDate") {
      message = "Please enter valid date.";
    } else if (input === "invoiceLine") {
      message = "Please select invoice line.";
    } else if (input === "refundNote") {
      message = "Please enter note.";
    } else {
      message = "Please complete this field.";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  //show selected invoice lines
  showSelectedInvoiceLines = () => {
    let { invoiceLineList, subTotal, taxAmount, totalAmount } = this.state;
    let { invoiceDetails } = this.props;
    return invoiceLineList.length ? (
      <div>
        {invoiceLineList.map((item, i) => {
          return invoiceLineList.length - 1 == i && this.state.showLoader ? (
            <div className='loader-view' key={"load" + i}>
              <div className='simple-loader'>
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
          ) : (
            <div
              key={"qty" + i}
              className='refund-edit-qty'
              style={{
                marginBottom: i != invoiceLineList.length - 1 ? 15 : 0,
              }}
            >
              <div onClick={() => this.handleRemoveClick(item)}>x</div>
              <div>{item.item_name}</div>
              <div className='input-qty'>
                <input
                  value={item.qty}
                  type='number'
                  readOnly={
                    item.item_name == "Slow Payer Penalty"
                      ? true
                      : !this.state.refundValidateData.allowToSelect
                  }
                  // readOnly={!this.state.refundValidateData.allowToSelect}
                  onChange={(e) => this.handleChange(e, item)}
                />
              </div>
              <div>
                {item.unit_price
                  ? "$" + parseFloat(item.unit_price).toFixed(2)
                  : "$0.00"}
              </div>
              <div>
                {item.amount
                  ? "$" + parseFloat(item.amount).toFixed(2)
                  : "$0.00"}
              </div>
            </div>
          );
        })}
        <div className='subTotal-panel'>
          <Panel>
            <div className='row'>
              <div className='key'>SubTotal</div>
              <div className='value'>
                {subTotal
                  ? "$" +
                    parseFloat(
                      subTotal -
                        (invoiceDetails.adjustment
                          ? toNumber(invoiceDetails.adjustment)
                          : 0)
                    ).toFixed(2)
                  : "$0.00"}
              </div>
            </div>
            <div className='row'>
              <div className='key'>Early Payer Discount</div>
              <div className='value'>
                {invoiceDetails.adjustment
                  ? "$" +
                    (invoiceDetails.adjustment
                      ? parseFloat(toNumber(invoiceDetails.adjustment))
                      : 0
                    ).toFixed(2)
                  : "$0.00"}
              </div>
            </div>
            <div className='row'>
              <div className='key'>Tax</div>
              <div className='value'>
                {taxAmount ? "$" + parseFloat(taxAmount).toFixed(2) : "$0.00"}
              </div>
            </div>
            <div className='row row-last'>
              <div className='key'>Total</div>
              <div className='value'>
                {totalAmount
                  ? "$" +
                    parseFloat(
                      totalAmount +
                        (invoiceDetails.adjustment
                          ? parseFloat(toNumber(invoiceDetails.adjustment))
                          : 0)
                    ).toFixed(2)
                  : "$0.00"}
              </div>
            </div>
          </Panel>
        </div>
      </div>
    ) : null;
  };

  render() {
    let { input, error, type, accountsList, isLoading, invoiceLineOptions } =
      this.state;

    let refundPayment =
      type.value == "Partial"
        ? input.refundAmount.value
        : input.paymentAmount.value;
    refundPayment = refundPayment ? "$" + refundPayment : "";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        //showToast(error.message);
        this.props.updateModalState(true, "ERROR", { message: error.message });
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title='Refund Payment'
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses='scroll'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form>
            <div className='refund-header-utilities'>
              <div className='radio'>
                <label>
                  <input
                    type='radio'
                    name='Type'
                    value={"Full"}
                    defaultChecked={!this.state.disableFull}
                    disabled={this.state.disableFull}
                    onClick={() =>
                      this.handleInputChange({
                        type: { value: "Full" },
                      })
                    }
                  />
                  <div>Full Refund</div>
                </label>
              </div>
              <div className='radio' style={{ marginLeft: "2em" }}>
                <label>
                  <input
                    type='radio'
                    name='Type'
                    value={"Partial"}
                    defaultChecked={this.state.disableFull}
                    onClick={() =>
                      this.handleInputChange({
                        type: { value: "Partial" },
                      })
                    }
                  />
                  <div>Partial Refund</div>
                </label>
              </div>
            </div>
            {type.value != "Full" && (
              <div style={style.container}>
                <div style={style.content}>Invoice Lines : </div>
                <div
                  className={`input-container ${
                    this.state.invoiceLineError ? " error" : ""
                  }`}
                  style={{
                    pointerEvents: this.state.refundValidateData.allowToSelect
                      ? "auto"
                      : "none",
                  }}
                >
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    className='form-select'
                    name='Select'
                    placeholder='Add invoice lines'
                    options={invoiceLineOptions}
                    onChange={(event) => this.onInvoiceLineSelect(event)}
                  />
                  {this.state.invoiceLineError
                    ? this.renderErrorMessage("invoiceLine")
                    : null}
                </div>
              </div>
            )}
            {type.value != "Full" && this.showSelectedInvoiceLines()}
            <div style={style.container}>
              <div style={style.content}>Payment Amount : </div>
              <div
                className={`input-container${
                  input.paymentAmount.hasError ? " error" : ""
                }`}
              >
                <MaskedTextInput
                  guide={true}
                  mask={amountMask}
                  readOnly={true}
                  name='paymentAmount'
                  placeholder='$0'
                  placeholderChar={"\u2000"}
                  pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                  value={"$" + input.paymentAmount.value}
                  onChange={(event) =>
                    this.handleRefundInputChange({
                      paymentAmount: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.paymentAmount.hasError
                  ? this.renderErrorMessage("paymentAmount")
                  : null}
              </div>
            </div>
            <div style={style.container}>
              <div style={style.content}>Refund Amount : </div>
              <div
                className={`input-container${
                  input.refundAmount.hasError ? " error" : ""
                }`}
              >
                <MaskedTextInput
                  guide={true}
                  mask={amountMask}
                  readOnly={true}
                  name='refundAmount'
                  placeholder='$0'
                  placeholderChar={"\u2000"}
                  pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                  value={refundPayment}
                  onChange={(event) =>
                    this.handleRefundInputChange({
                      refundAmount: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.refundAmount.hasError
                  ? this.renderErrorMessage("refundAmount")
                  : null}
              </div>
            </div>
            <div style={style.container}>
              <div style={style.content}>Refund Method : </div>
              <div
                className={`input-container${
                  input.refundMethod.hasError ? " error" : ""
                }`}
              >
                <div className='refund-method-select'>
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    className='form-select'
                    name='Select'
                    value={input.refundMethod.value}
                    placeholder='Select refund method'
                    options={[
                      {
                        value: "MakeGood Payments",
                        label: "MakeGood Payments",
                      },
                      { value: "Offline", label: "Offline" },
                      { value: "Other", label: "Other" },
                    ]}
                    onChange={(event) =>
                      this.handleRefundInputChange({
                        refundMethod: { value: event.value, hasError: false },
                      })
                    }
                  />
                  <img
                    src={InfoIcon}
                    className='info-tooltip'
                    title='If MakeGood Payments selected then MakeGood will initiate the return of funds to the customer for you.'
                  />
                </div>
                {input.refundMethod.hasError
                  ? this.renderErrorMessage("refundMethod")
                  : null}
              </div>
            </div>
            <div style={style.container}>
              <div
                style={{
                  ...style.content,
                  opacity: this.props.user.qb_connect ? "1" : "0.5",
                }}
              >
                Refund Account :{" "}
              </div>
              <div
                className={`input-container${
                  input.refundAccount.hasError ? " error" : ""
                }`}
              >
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  className='form-select'
                  name='Select'
                  value={input.refundAccount.value}
                  disabled={!this.props.user.qb_connect}
                  placeholder='Select refund method'
                  options={accountsList}
                  onChange={(event) =>
                    this.handleRefundInputChange({
                      refundAccount: {
                        value: event.value,
                        hasError: false,
                        account: event,
                      },
                    })
                  }
                />
                {input.refundAccount.hasError
                  ? this.renderErrorMessage("refundAccount")
                  : null}
              </div>
            </div>
            <div>
              <div style={style.container}>
                <div style={style.content}>Refund Date : </div>
                <div
                  className={`input-container${
                    input.refundDate.hasError ? " error" : ""
                  }`}
                >
                  <div className='refund-method-select'>
                    <MaskedTextInput
                      guide={true}
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      name='refundDate'
                      placeholder='mm/dd/yyyy'
                      placeholderChar={"\u2000"}
                      type='text'
                      value={input.refundDate.value}
                      onChange={(event) =>
                        this.handleRefundInputChange({
                          refundDate: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    <img
                      src={InfoIcon}
                      className='info-tooltip'
                      title='If MakeGood Payments Refund Method is selected then refund of funds will be initiated today and typically takes 5-7 days to process. If using Offline or Other Refund Method then add the date you issued the refund.'
                    />
                  </div>
                  {input.refundDate.hasError
                    ? this.renderErrorMessage("refundDate")
                    : null}
                </div>
              </div>
              <div style={style.container}>
                <div style={style.content}>Refund Note : </div>
                <div
                  className={`input-container${
                    input.refundNote.hasError ? " error" : ""
                  }`}
                >
                  <div className='refund-method-select'>
                    <TextareaAutosize
                      name='refundNote'
                      type='textarea'
                      placeholder='Your refund note here...'
                      value={input.refundNote.value}
                      onChange={(event) =>
                        this.handleRefundInputChange({
                          refundNote: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    <img
                      src={InfoIcon}
                      className='info-tooltip'
                      title='The refund note entered here will be added to the notes section of this invoice for record keeping. The note will not be sent to your customer.'
                    />
                  </div>
                  {input.refundNote.hasError
                    ? this.renderErrorMessage("refundNote")
                    : null}
                </div>
              </div>
              <div className='admin-form-row address-document'>
                <div className={`input-container error`}>
                  <div className={`refund-method-select file-text`}>
                    <FileBase64
                      multiple={true}
                      onDone={this.getFiles.bind(this)}
                    />
                    <img
                      src={InfoIcon}
                      className='info-tooltip'
                      title='Documents uploaded here will be added to the Documents section for this invoice. The document will not be sent to your customer'
                    />
                  </div>
                  <div
                    className=' input-error-message'
                    style={{ color: "darkgrey" }}
                  >
                    Image upload format (.png, .jpg, .jpeg, .bmp, .pdf) <br />{" "}
                    Size must be less than 1 MB <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-actions'>
              <a
                id='form-1'
                className='cta mg-brand2-color'
                onClick={(event) => this.handleMakePaymentClick(event)}
              >
                Refund Payment
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

const style = {
  container: { display: "flex" },
  content: { width: "60%", paddingTop: `10px` },
};

export default RefundAmountModal;
