import React, { Component } from 'react';

// Components
import Modal from '../Modal';

class ShowDebtNoteModal extends Component {

  render() {
    
    let { debtInfo } = this.props.optionalProps;
    return (
      <Modal
        title="Debt Notes"
        hideModal={this.props.hideModal}
        closeOnClickOutside={true}
      >
        <p style={{ textAlign: 'center' }}>
          {debtInfo.additional_details? debtInfo.additional_details : 'No Notes'}
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            onClick={this.props.hideModal}
          >
            Ok
          </a>
        </div>
      </Modal>
    )
  }
}

export default ShowDebtNoteModal;