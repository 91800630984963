import React, { Component } from "react";

class ClientsReportSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOn: true,
    };
  }

  toggleButton = () => {
    this.setState({ isOn: !this.state.isOn });
  };

  showSubscriptionScreen = () => {
    this.props.showSubscriptionScreen();
  };

  render() {
    const { title, optionalClassNames, showSubscription } = this.props;
    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
        <div
          className={`app-section-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div className="app-section-header-content">
            {showSubscription && (
              <a
                className="app-section-header-back-link bk-l"
                onClick={(event) => this.props.showSubscriptionScreen()}
                style={{color:"#ffffff"}}
              >
                « Back
              </a>
            )}
            <h1 className="app-section-header-heading side-hed-col">{title}</h1>
          </div>
        </div>
        <div className="history-btn">
          <button
            className={`${showSubscription ? " active" : ""}`}
            onClick={() => this.showSubscriptionScreen()}
            style={{marginLeft: 0}}
          >
            Subscriptions
          </button>
        </div>
      </div>
    );
  }
}

export default ClientsReportSidebar;
