import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import Tabs from "../../components/Tabs";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";
import LowStockWhite from "../../assets/images/goods/low-stock-white.png";
import noStockGray from "../../assets/images/goods/no-stock-gray.png";
import noStockWhite from "../../assets/images/goods/no-stock-white.png";

class ServiceSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList: [],
      offset: 0,
      isLoading: true,
      tabLoader: false,
      activeTab: 1,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      previousActiveTab: 0,
    };
  }

  componentDidMount() {
    this.checkQBConnect();
    this.getClientServices(false);
  }
  checkQBConnect = () => {
    API.checkAuthStatus(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code === 400) {
        this.props.user.qb_connect = false;
      } else if (
        (typeof data != "undefined" && data.data) ||
        (data != null && data.data)
      ) {
        if (data.data.status && data.data.status === "ACTIVE") {
          this.props.user.qb_connect = true;
        }
      } else {
        this.setState({
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        });
      }
    });
  };

  reload = (flag) => {
    this.setState({ offset: 0 }, () => this.getClientServices(flag));
  };

  handleTabChange = (tabIndex) => {
    this.setState(
      {
        activeTab: tabIndex,
      },
      () => {
        this.getClientServices(false, true);
      }
    );
  };

  getClientServices = (reload, tabLoad) => {
    let { offset, activeTab, previousActiveTab } = this.state;
    let type = activeTab == 1 ? '["NonInventory","Inventory"]' : "Service";
    let offsetValue = offset;
    if (previousActiveTab != 0 && previousActiveTab != activeTab) {
      this.setState({ offset: 0 });
      offsetValue = 0;
    }

    if (tabLoad) this.setState({ tabLoader: true });
    API.getClientServices(this.props.user, offsetValue, type).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let check = reload ? this.state.serviceList : false;
          let newData = check
            ? this.state.serviceList.concat(data.data.services)
            : data.data.services;
          this.setState((state) => ({
            ...state,
            serviceList: newData,
            isLoading: false,
            hasMoreData: data.data.services.length < 10 ? false : true,
            previousActiveTab: activeTab,
          }));
          this.props.goodsData({
            lowStock: data.data.low_stock_item_count,
            outStock: data.data.out_of_stock_count,
            goodsTab: activeTab,
          });
        }
        this.setState({
          isLoading: false,
          showLoader: false,
          tabLoader: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tabLoader: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //pagination api call when scroll reached bottom of the screen
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getClientServices(true);
        }
      );
    }
  };

  //calls when "Add Service" button is click
  handleAddServiceClick = (e) => {
    e.preventDefault();
    const { user } = this.props;
    let props = {
      user: user,
      activeTab: this.state.activeTab,
      reload: this.reload,
    };
    //opens Add Service popup modal
    this.props.updateModalState(true, "ADD_SERVICE", props);
  };

  //calls on row arrow click
  rowExpanderClick = (e) => {
    let props = {
      user: this.props.user,
      activeTab: this.state.activeTab,
      reload: this.reload,
      rowData: e.rowData,
      appName: this.props.appName,
    };
    //opens Edit Service popup modal
    this.props.updateModalState(true, "ADD_SERVICE", props);
  };

  showRate = (item) => {
    let rate = 0.0;
    if (item.rate) {
      rate = parseFloat(item.rate).toFixed(2);
    }
    if (item.is_rate_per_unit) {
      rate = rate + "/unit";
    } else {
      rate = rate + "/hour";
    }
    return "$" + rate;
  };

  setDefaultService = (currentTerm, index) => {
    let payload = {
      id: currentTerm.id,
      is_default: !currentTerm.is_default,
    };

    this.updateDeafultService(payload, index);
  };

  updateDeafultService = (payload) => {
    this.setState({ isLoading: true });
    API.updateDefaultService(this.props.user, payload).then((data) => {
      this.handleCommonResponse(data);
      this.setState({ isLoading: false });
    });
  };

  handleCommonResponse = (data) => {
    if (data && data.status_code) {
      if (data.status_code === 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
      } else if (data.status_code === 422) {
        Emitter.emit("NOTIFICATION_ALERT", true);
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
      this.reload();
      this.setState({ isLoading: false });
    } else {
      this.setState({
        error: {
          hasAnyError: true,
          statusCode: data
            ? data.status ||
              (data.status_code && data.status_code == 401 ? 401 : 500) ||
              500
            : 500,
        },
        isLoading: false,
      });
    }
  };

  handleIsDefault = (isChecked, item, index) => {
    if (isChecked) {
      this.defaultServiceConfirmAlert(item, index);
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: "Atleast one service should be set as a default service.",
        title: "Alert",
      });
    }
  };

  defaultServiceConfirmAlert = (item, index) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: `Are you sure you want to set service '${item.name}' as a default service?`,
      confirmClick: () => {
        this.setDefaultService(item, index);
      },
      closeOnClickOutside: true,
      cancelClick: () => {},
      isCancelCallback: true,
    });
  };

  //returns formatted table and card data
  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Name",
        style: { width: "15%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Description",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Inventory Tracking",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Taxable",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Rate",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "SKU",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Qty on Hand",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Cost of Good",
        style: { width: "14%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Reorder Point",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { width: "1%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((item, index) => {
      return {
        primaryRow: {
          key: "Name",
          title: item.name ? item.name : "",
          style: { width: "15%", display: "flex", justifyContent: "center" },
        },
        secondaryColumns: [
          {
            title: item.name ? item.name : "",
            hideForMobile: true,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Description",
            title: item.description,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Inventory Tracking",
            title: <ToggleSwitch isOn={item.is_inv_enable} />,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Taxable",
            title: <ToggleSwitch isOn={item.is_taxable} />,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Rate",
            title: this.showRate(item),
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "SKU",
            title: item.item_sku,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Qty on Hand",
            title: item.quantity_on_hand,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Cost of Good",
            title: item.cost_of_good,
            style: { width: "14%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Reorder Point",
            title: item.item_reorder_point,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        rowData: item,
      };
    });

    return {
      headings,
      columns,
    };
  };

  //returns formatted table and service card data
  getObjectServiceListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Name",
        style: { width: "20%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Description",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Taxable",
        style: { width: "20%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Rate",
        style: { width: "23%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Default",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { width: "2%", display: "flex", justifyContent: "" },
      },
    ];

    columns = data.map((item, index) => {
      return {
        primaryRow: {
          key: "Name",
          title: item.name ? item.name : "",
        },
        secondaryColumns: [
          {
            title: item.name ? item.name : "",
            hideForMobile: true,
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Description",
            title: item.description,
            style: { width: "25%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Taxable",
            title: <ToggleSwitch isOn={item.is_taxable} />,
            style: { width: "20%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Rate",
            title: this.showRate(item),
            style: {
              width: "23%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Default",
            title: (
              <div
                className='tax-value-wrapper'
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  className='tax-checkbox'
                  type='checkbox'
                  name='isDefault'
                  id='isDefault'
                  title={
                    item.is_default
                      ? "This is default service."
                      : "Click to set is default"
                  }
                  style={{ pointerEvents: "auto", cursor: "pointer" }}
                  // defaultChecked={true}
                  checked={item.is_default}
                  onChange={(e) =>
                    this.handleIsDefault(e.target.checked, item, index)
                  }
                />
              </div>
            ),
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        rowData: item,
      };
    });

    return {
      headings,
      columns,
    };
  };

  showLoader = () => {
    return (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error, serviceList, activeTab, tabLoader } = this.state;

    const tabLabels = ["Goods", "Services"];

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      this.showLoader()
    ) : (
      <div className='consumer-help'>
        <Helmet>
          <title>{appName} | Goods & Services</title>
        </Helmet>
        <div className='big-device-grad '>
          <div className='goods-services-utility'>
            <div>
              <div className='he-hed'>Goods & Services</div>
              <Tabs
                labels={tabLabels}
                activeTarget={activeTab}
                handleTabChange={this.handleTabChange}
                optionalInTabClasses='mg-tabs-tab'
                optionalClasses='consumer-pay-tab tp-sp con-fit services-tab'
              />
            </div>
            <input
              type='submit'
              value={activeTab == 1 ? "+ Add Goods" : "+ Add Services"}
              className='cta mg-brand2-color'
              style={{ width: "auto" }}
              onClick={(event) => this.handleAddServiceClick(event)}
            />
          </div>
          <br />
          {tabLoader ? (
            this.showLoader()
          ) : serviceList && serviceList.length ? (
            <BottomScrollListener
              offset={50}
              onBottom={this.handleContainerOnBottom}
              debounce={0}
            >
              {(scrollRef) => (
                <div ref={scrollRef} className='send-invoice-scroll scrollbar'>
                  {/* cardview for mobile device */}
                  <ClientsCardView
                    data={
                      activeTab == 1
                        ? this.getObjectListData(serviceList)
                        : this.getObjectServiceListData(serviceList)
                    }
                    rowActionClick={this.rowExpanderClick}
                  />
                  {/* list view for big devices */}
                  <InvoiceObjectList
                    data={
                      activeTab == 1
                        ? this.getObjectListData(serviceList)
                        : this.getObjectServiceListData(serviceList)
                    }
                    hideForMobile={true}
                    rowActionClick={this.rowExpanderClick}
                    optionalTableClasses='client-service-list'
                    scrollRef={scrollRef}
                  />
                  {this.state.showLoader && (
                    <div>
                      <AppCanvasLoader />
                    </div>
                  )}
                </div>
              )}
            </BottomScrollListener>
          ) : (
            <div className='admin-admins-empty-state'>
              <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                {`No ${activeTab == 1 ? "Goods" : "Services"} Available`}
              </h3>
              <p>{`There are currently no ${
                activeTab == 1 ? "goods" : "services"
              } available.`}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ServiceSettings;
