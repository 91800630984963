
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import account from "../../assets/images/navbar/accounts.png";
import {fBuildVersion} from "../../utils/helpers";
import * as API from "../../utils/api";

class SuperAdminNav extends Component {

    constructor() {
        super();
        this.state = {
            version:"",
            isLoading: true,
        };
    }

    static propTypes = {
        path: PropTypes.string.isRequired,
    }

    componentDidMount(){
        const { user } = this.props;
        API.getUserbackend(user).then((data) => {
            if ((typeof data != "undefined" || data != null) && !data.errors) {
                this.setState((state) => ({
                    ...state,
                    version: data.version,
                    isLoading: false,
                }));
            } else {
                this.setState((state) => ({
                    ...state,
                    error: {
                        hasAnyError: true,
                        statusCode: data
                            ? data.status ||
                            (data.message && data.message == "Access denied."
                                ? 401
                                : 500) ||
                            500
                            : 500,
                    },
                }));
            }
        });
    }

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return 'active';
    }
  }

  render() { 
    return (
      <nav className="admin-nav client-nav scrollbar">
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/super-admin/accounts"
          )}`}
          to="/super-admin/accounts"
        >
          <img src={account} width="34" height="34" className="icon" />
          ACCOUNTS
        </Link>
        <Link
          className={`admin-nav-link client-nav-link ${this.isActive(
            "/super-admin/profile"
          )}`}
          to="/super-admin/profile"
        >
          <img src={account} width="34" height="34" className="icon" />
          PROFILES
        </Link>
          <div className="footer-version">
              <p>Client Version: {fBuildVersion}</p>
              <p>API Version: {this.state.version}</p>
          </div>
      </nav>
    );
  }
}

export default SuperAdminNav;
