import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, withRouter, Redirect } from "react-router-dom";
// import ReactGA from 'react-ga';

// API
import * as API from "../../../utils/api";

import { getZohoPagesense } from "../../../utils/helpers";

// Components
import AppSectionHeader from "../../../components/App/AppSectionHeader";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ConsumerAccountsViewSidebar from "../../../components/Consumer/ConsumerAccountsViewSidebar";
import Tabs from "../../../components/Tabs";
import Panel from "../../../components/App/Panel";

class ConsumerAccountsPaymentNegotiateSelection extends Component {
  state = {
    account: {
      debtId: "",
      originalCollectionAmount: "",
      creditorName: "",
      threshold: {},
    },
    isLoading: true,
  };

  componentDidMount() {
    const debtId = parseInt(this.props.match.params.id, 10);

    this.props.updatePrevPath(`/dashboard/accounts/${debtId}`);

    API.getDebt(this.props.user, debtId).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.message) {
        let { debt, offers, threshold } = data;

        this.setState((state) => ({
          account: {
            debtId: debt.id,
            originalCollectionAmount: debt.balance_in_dollars_as_currency,
            creditorName: debt.creditor_name,
            numberOfOffers: offers.length,
            offers: offers,
            threshold:
              threshold && threshold.length > 0
                ? threshold[0]
                : { capabilities: "Both" },
          },
          debt: debt,
          isLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
          isLoading: false,
        }));
      }
    });

    getZohoPagesense();
    // ReactGA.pageview("/ConsumerAccountsPaymentNegotiateSelection");
  }

  handleTabClick = (tabIndex) => {
    const { account } = this.state;

    switch (parseInt(tabIndex, 10)) {
      case 1:
        this.props.history.push(
          `/dashboard/accounts/${account.debtId}/validate`
        );
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        // eslint-disable-next-line no-unreachable
        break;
      case 3:
        this.props.history.push(
          `/dashboard/accounts/${account.debtId}/settle-account`
        );
        // eslint-disable-next-line no-unreachable
        break;
      default:
        return null;
        // eslint-disable-next-line no-unreachable
        break;
    }
  };

  handleFormSubmit = () => {
    this.props.updateModalState(true, "USER_PAYMENT_SETTLED");
  };

  renderTabsOrHeading = () => {
    const { account } = this.state;

    if (account.numberOfOffers === 0) {
      const tabLabels = ["Validate", "Negotiate", "Pay"];

      return (
        <Tabs
          labels={tabLabels}
          activeTarget={2}
          optionalClasses='consumer-pay-tab'
          handleTabChange={this.handleTabClick}
        />
      );
    } else {
      return (
        <h2 className='consumer-accounts-payment-heading'>
          Create a Counteroffer
        </h2>
      );
    }
  };

  render() {
    const { appName } = this.props;
    const { account, isLoading, debt } = this.state;

    const title =
      appName +
      " | " +
      account.creditorName +
      ": $" +
      account.originalCollectionAmount;

    if (this.state.hasAnyError) {
      this.props.updateModalState(true, "OTHER_ERROR", this.state.hasAnyError);
      return <Redirect to={{ pathname: "/sign-out" }} />;
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='accounts-view consumer-accounts-view app-sidebar-layout'>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className='wrap user-portal-collapse-wrap'>
          <AppSectionHeader
            title={account.creditorName}
            subtitle={`$${account.originalCollectionAmount}`}
            optionalClassNames='secondary reversed-title consumer-portal-app-header'
            backLink={{
              text:
                account.numberOfOffers > 0
                  ? "Back to Account"
                  : "Back to All Accounts",
              path:
                account.numberOfOffers > 0
                  ? `/dashboard/accounts/${account.debtId}`
                  : `/dashboard/accounts/`,
            }}
          />
          <div
            className='scrollbar tab-spacing-negotiate'
            style={{ height: window.innerHeight * 0.6, overflow: "auto" }}
          >
            <div className='app-sidebar-layout-canvas'>
              <ConsumerAccountsViewSidebar account={account} debt={debt} />
              <div className='app-sidebar-layout-canvas-content'>
                {this.renderTabsOrHeading()}
                <div className='mini-wrap'>
                  <div className='consumer-accounts-payment-tab-content'>
                    <div className='collapse-wrap-padded-content'>
                      <p className='consumer-accounts-payment-negotiation-description'>
                        What type of offer would you like to negotiate to settle
                        your debt?
                      </p>
                    </div>
                    {account.threshold.capabilities == "Monthly" ||
                    account.threshold.capabilities == "Both" ? (
                      <Link
                        className='consumer-accounts-payment-plan-selector'
                        to={`/dashboard/accounts/${account.debtId}/negotiate/payment-plan`}
                      >
                        <Panel>
                          <div className='panel-content-copy'>
                            <h3>Payment Plan</h3>
                          </div>
                          <svg className='panel-content-arrow'>
                            <use xlinkHref='#arrow-right' />
                          </svg>
                        </Panel>
                      </Link>
                    ) : null}
                    {account.threshold.capabilities == "OneTime" ||
                    account.threshold.capabilities == "Both" ? (
                      <Link
                        className='consumer-accounts-payment-plan-selector'
                        to={`/dashboard/accounts/${account.debtId}/negotiate/one-time-payment`}
                      >
                        <Panel>
                          <div className='panel-content-copy'>
                            <h3>One-Time Fixed Amount</h3>
                          </div>
                          <svg className='panel-content-arrow'>
                            <use xlinkHref='#arrow-right' />
                          </svg>
                        </Panel>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsumerAccountsPaymentNegotiateSelection);
