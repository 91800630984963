import React, { Component } from 'react';

class SocialLinks extends Component {

  render() {
    return (
      <div className="social-links">
        <a className="social-link" href="https://www.facebook.com/Debhelps/" target="_blank" rel="noopener noreferrer">
          <svg><use xlinkHref="#facebook" /></svg>
        </a>
        <a className="social-link" href="https://twitter.com/debhelps" target="_blank" rel="noopener noreferrer">
          <svg><use xlinkHref="#twitter" /></svg>
        </a>
        <a className="social-link" href="https://www.instagram.com/debhelps/" target="_blank" rel="noopener noreferrer">
          <svg><use xlinkHref="#instagram" /></svg>
        </a>
      </div>
    );
  }
}

export default SocialLinks;
