import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import * as API from "../utils/api";
import companyData from "../assets/styles/theme.json";
import MakeGoodLogo from "../../src/assets/icons/MG-logo.png";

class Logo extends Component {
  static propTypes = {
    circular: PropTypes.bool,
    linkPath: PropTypes.string,
    linkTitle: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      company_name: companyData.name, //appName
      company_logo2: "",
      logoUrl: "",
    };
  }

  checkAdminPortal() {
    if (this.porps.hasOwnProperty("adminPortal")) {
      if (this.porps.adminPortal) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getLogoColorsForPayment() {
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          company_logo2: response.data.logo_url,
        });
      })
      .catch(() => {});
  }

  componentDidMount() {
    this.getLogoColorsForPayment();
    this.setState({
      ...this.state,
      logoUrl: this.props.logoUrl,
    });
  }

  componentDidUpdate() {
    if (this.state.logoUrl !== this.props.logoUrl) {
      this.setState({
        ...this.state,
        logoUrl: this.props.logoUrl,
      });
    }
  }

  checkWidthAsMediaSize() {
    let x = window.matchMedia("(max-width: 600px)");
    if (x.matches) {
      return "60px";
    } else {
      return "75px";
    }
  }

  render() {
    const { accountHolder, hideNav, isDynamicLogo, footerLogo, userAgreement } =
      this.props;
    const { logoUrl, company_logo2 } = this.state;
    if ((accountHolder && accountHolder === "consumer") || isDynamicLogo) {
      return (
        <div>
          {(logoUrl && hideNav) || isDynamicLogo ? (
            <div
              style={{
                margin: "0 0 0 0",
                position: "relative",
                marginRight: "20px",
              }}
            >
              {localStorage.getItem("imgResolution") === "moreThan500px" ? (
                <img
                  style={{
                    maxHeight: "80px",
                    height: "auto",
                    maxWidth: "80px",
                    width: "auto",
                  }}
                  src={logoUrl}
                />
              ) : (
                <img
                  style={{
                    maxHeight: "80px",
                    height: "auto",
                    maxWidth: "250px",
                    width: "auto",
                  }}
                  src={logoUrl}
                />
              )}
            </div>
          ) : logoUrl && this.checkAdminPortal() ? (
            <>
              <div
                style={
                  footerLogo
                    ? { margin: "0 0 0 0", height: 40, float: "left" }
                    : {
                        margin: "0 0 0 0",
                        height: this.checkWidthAsMediaSize(),
                        float: "left",
                      }
                }
              >
                <img
                  style={
                    footerLogo
                      ? { width: "100%", height: 36 }
                      : { height: this.checkWidthAsMediaSize() }
                  }
                  src={MakeGoodLogo}
                />
              </div>
            </>
          ) : (
            <>
              <div
                style={
                  footerLogo
                    ? { margin: "0 0 0 0", width: 40, float: "left" }
                    : { margin: "0 0 0 0", width: 200, float: "left" }
                }
              >
                <img
                  style={
                    footerLogo
                      ? { width: "100%", height: 36 }
                      : { height: "40px" }
                  }
                  src={this.state.company_logo2}
                />
              </div>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {hideNav ? (
            <div
              className="companyLogoDiv"
              style={{ margin: "0 0 0 0", float: "left" }}
            >
              <Link to="/client/dashboard">
                <img
                  // className="companyLogoDiv-consumer-view"
                  src={MakeGoodLogo}
                  style={{
                    maxHeight: "80px",
                    height: "auto",
                    maxWidth: "250px",
                    width: "auto",
                  }}
                />
              </Link>
            </div>
          ) : userAgreement ? (
            <div
              style={{
                margin: "0 0 0 0",
                position: "relative",
                marginRight: "20px",
              }}
            >
              {localStorage.getItem("imgResolution") === "moreThan500px" ? (
                <img
                  style={{
                    maxHeight: "80px",
                    height: "auto",
                    maxWidth: "80px",
                    width: "auto",
                  }}
                  src={company_logo2}
                />
              ) : (
                <img
                  style={{
                    maxHeight: "80px",
                    height: "auto",
                    maxWidth: "250px",
                    width: "auto",
                  }}
                  src={company_logo2}
                />
              )}
            </div>
          ) : (
            <div
              className="companyLogoDiv"
              style={
                footerLogo
                  ? { margin: "0 0 0 0", width: 40, float: "left" }
                  : {
                      margin: "0 0 0 0",
                      height: "35px",
                      float: "left",
                    }
              }
            >
              <img
                style={
                  footerLogo ? { width: 40, height: 36 } : { height: "35px" }
                }
                src={MakeGoodLogo}
              />
            </div>
          )}
        </div>
      );
    }
  }
}

export default Logo;
