import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router-dom";

// API
import * as API from '../../../../utils/api';

// Packages
import TextareaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import MaskedTextInput from 'react-text-mask';
import moment from 'moment';

// Components
import Panel from '../../../App/Panel';
import AppCanvasLoader from '../../../App/AppCanvasLoader';
import Modal from '../Modal';
import FlashMessage from '../../../../components/FlashMessage';

// Utils
import stateDataAbbreviations from '../../../../utils/stateDataAbbreviations.json';
import {
    isValidDate,
    numberMask,
    getStrippedDollarAmount,
    convertCreditorsToArrayForSelectComponent,
    showToast
} from '../../../../utils/helpers';

class AddNewDebt extends Component {
    state = {
        consumers: null,
        creditors: [],
        consumer_name: '',
        input: {
            additional_details: {
                value: '',
                hasError: false
            },
            balance_in_dollars_as_currency: {
                value: '',
                hasError: false
            },
            close_out_date: {
                value: '',
                hasError: false
            },
            created_at: {
                value: '',
                hasError: false
            },
            creditor_id: {
                value: '',
                hasError: false
            },
            creditor_name: {
                value: '',
                hasError: false
            },
            date_sent: {
                value: null,
                hasError: false
            },
            id: {
                value: '',
                hasError: false
            },
            last_payment_date: {
                value: '',
                hasError: false
            },
            loan_open_date: {
                value: '',
                hasError: false
            },
            status: {
                value: null,
                hasError: false
            },
            total_in_dollars_as_currency: {
                value: '',
                hasError: false,
                previousTotal: ''
            },
            user_id: {
                value: '',
                hasError: false
            },
            payment:{
                value: '',
                hasError: false
            },
            adjustment:{
                value: '',
                hasError: false
            }
        },
        isLoading: true,
        error: {
            hasAnyError: false,
            statusCode: 200
        },
        showFlash: false,
        flashMessage: "",
        disable: false
    }

    componentDidMount() {
        API.fetchAllCreditors(this.props.user).then(data => {
            if (((typeof data != 'undefined') || (data != null)) && !data.error) {
                let consumer = this.props.optionalProps.consumerId;
                let creditorsArray = convertCreditorsToArrayForSelectComponent(data.creditors);
                this.setState(state => ({
                    consumers: consumer,
                    creditors: creditorsArray,
                }))
            }
        })

        API.getClientsConsumer(this.props.user, this.props.optionalProps.consumerId).then(data => {
            if (((typeof data != 'undefined') || (data != null)) && !data.error) {
                this.setState(state => ({
                    consumer_name: data.account.consumer.first_name + " " + data.account.consumer.last_name
                }))
            }
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let { history, user } = this.props;

        const consumerId = this.state.consumers[0];

        if (this.validateForm()) {
            
            let consumerForSubmission = this.getConsumerForSubmission(consumerId);
            if(consumerForSubmission.total_in_dollars < (consumerForSubmission.initial_payment + consumerForSubmission.adjustment)){
                 let newConsumerDebtsInputState = this.state.input;
                 newConsumerDebtsInputState['total_in_dollars_as_currency'].hasError = true;
                 this.setState(state => ({
                    ...state,
                    input: newConsumerDebtsInputState
                 }))
            }else{
                 this.setState(state => ({ 
                    ...state,
                    disable: true
                 }))
                API.addConsumerDebt(user, consumerId, consumerForSubmission).then(data => {
                    //this.props.showLoader(false);
                    if (((typeof data != 'undefined') || (data != null)) && !data.error) {
                        let {optionalProps } = this.props;
                        if(optionalProps && optionalProps.context) {
                            history.push(`/${optionalProps.context}/accounts/${consumerId}`);
                            this.props.optionalProps.reloadData();
                        }
                        this.props.updateModalState(true, 'ERROR', { message: data.message, title: `Success Message` });
                    } else {
                        this.setState(state => ({
                            ...state,
                            hasAnyError: true,
                            disable: false
                        }))
                    }
                });    
            }
        }
    }


    getConsumerForSubmission = (consumerId) => {
        const { input, creditors } = this.state;
        const consumerInput = this.state.input;
        let creditor_name = consumerInput.creditor_name.value;
        let creditor_id = consumerInput.creditor_id.value;

        let debtAttributes = {
            additional_details: input.additional_details.value,
            created_at: moment(input.created_at.value, "MM/DD/YYYY"),
            creditor_id: creditor_id,
            creditor_name: creditor_name,
            total_in_dollars: getStrippedDollarAmount(input.total_in_dollars_as_currency.value),
            user_id: consumerId,
            date_sent: moment(input.created_at.value, "MM/DD/YYYY"),
            initial_payment: getStrippedDollarAmount(input.payment.value),
            adjustment: getStrippedDollarAmount(input.adjustment.value)
        };

        return debtAttributes;
    }

    handleDebtInputChange(newPartialInput) {
        
        this.setState(state => ({
            ...state,
            input: {
                ...state.input,
                ...newPartialInput
            }
        }))
    }

    validateForm = () => {
        let errorsArePresent = false;

        // Check Debts' Inputs for Errors
        let newConsumerDebtsInputState = this.state.input;
        Object.entries(newConsumerDebtsInputState).forEach(([key, value]) => {
            if (key === 'creditor_name') {
                if (value.value === '' || value.value === null) {
                    errorsArePresent = true;
                    newConsumerDebtsInputState[key].hasError = true;
                }
            } else if (key === 'created_at') {
                if (!isValidDate(value.value) || value.value === '') {
                    errorsArePresent = true;
                    newConsumerDebtsInputState[key].hasError = true;
                }
            } if (key === 'total_in_dollars_as_currency') {
                if (value.value === '' || value.value === null) {
                    errorsArePresent = true;
                    newConsumerDebtsInputState[key].hasError = true;
                }
            } else if (value.isRequired && value.value === '') {
                errorsArePresent = true;
            }
        })

        if (errorsArePresent) {
            this.setState(state => ({
                ...state,
                input: newConsumerDebtsInputState
            }))
        } else {
            return true;
        }
    }

    renderErrorMessage(input) {
        let message = '';

        if (input === 'creditor_id') {
            message = 'Please select a creditor';
        } else if (input === 'created_at') {
            message = 'Please enter a valid date';
        } else if (input === 'total_in_dollars_as_currency') {
            message = 'Please enter a valid debt amount';
        } else if(input === 'payment'){
            message = 'Please enter a valid amount';
        }else {
            message = 'Please complete this field';
        }

        return (
            <div className="input-error-message">
                {message}
            </div>
        )
    }

    handleModalClose = () => {
        this.props.hideModal();
    }


    dismissFlash = () => {
        this.handleModalClose();
        this.setState(state => ({
            ...state,
            showFlash: false,
            flashMessage: ""
        }))
    }

    render() {
        let { creditors, input, error, consumer_name } = this.state;
        const style = {
            container: { display: 'flex' },
            content: { width: '60%', paddingTop: `10px` },
            label: { width: '40%', paddingTop: `10px` }
        }

        if (error.hasAnyError) {
            if (error.statusCode == 500) {
                showToast();
                this.setState({
                    error:{
                      ...this.state.error,
                      hasAnyError: false,
                      statusCode: 200
                    }
                  });
            } else if (error.statusCode == 401) {
                return <Redirect to={{ pathname: '/sign-out' }} />
            } else {
                this.props.updateModalState(true, 'OTHER_ERROR', true)
                return <Redirect to={{ pathname: '/sign-out' }} />
            }
        }

        return (
            <Modal
                title="Add New Debt"
                closeOnClickOutside={true}
                hideModal={this.props.hideModal}
                style={{ maxWidth: `35em` }}
                optionalClasses="scroll"
            >
                <form>
                    <div>
                        <div>
                            <div style={style.container}>
                                {/* <div style={style.label}> </div>
                                    <div className={`modal-slide-header-tab`}>
                                       { consumer_name ? consumer_name : ''}
                                    </div> */}

                                <div style={{ width: `60%` }}>Debtor's Name : </div>
                                <div className={`input-container`} >
                                    {consumer_name ? consumer_name : ''}
                                </div>
                            </div>
                            <div style={style.container}>
                                <div style={style.content}>Creditor's Name : </div>
                                <div
                                    className={`input-container${input.creditor_id.hasError ? ' error' : ''}`}
                                >
                                    <Select
                                        inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                                        className="form-select"
                                        name="creditorName"
                                        value={input.creditor_id.value}
                                        placeholder="Select a Creditor"
                                        options={creditors}
                                        resetValue=""
                                        onChange={event => this.handleDebtInputChange({
                                            creditor_name: { value: event.label, hasError: false },
                                            creditor_id: { value: event.value, hasError: false }
                                        }
                                        )}
                                    />
                                    {input.creditor_id.hasError ? this.renderErrorMessage('creditor_name') : null}
                                </div>
                            </div>
                            <div>
                                <div style={style.container}>
                                    <div style={style.content}>Charges : </div>
                                    <div
                                        className={`input-container${input.total_in_dollars_as_currency.hasError ? ' error' : ''}`}
                                    >
                                        <MaskedTextInput
                                            guide={true}
                                            mask={numberMask}
                                            name="creditorTotalDebt"
                                            placeholder="$0"
                                            placeholderChar={'\u2000'}
                                            type="text"
                                            value={input.total_in_dollars_as_currency.value}
                                            onChange={event => this.handleDebtInputChange({
                                                total_in_dollars_as_currency: { value: event.target.value, hasError: false }
                                            }
                                            )}
                                        />
                                        {input.total_in_dollars_as_currency.hasError ? this.renderErrorMessage('total_in_dollars_as_currency') : null}
                                    </div>
                                </div>
                                <div style={style.container}>
                                    <div style={style.content}>Date of Origination : </div>
                                    <div
                                        className={`input-container${input.created_at.hasError ? ' error' : ''}`}
                                    >
                                        <MaskedTextInput
                                            guide={true}
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            name="created_at"
                                            placeholder="mm/dd/yyyy"
                                            placeholderChar={'\u2000'}
                                            type="text"
                                            value={input.created_at.value}
                                            onChange={event => this.handleDebtInputChange({ created_at: { value: event.target.value, hasError: false } })}
                                        />
                                        {input.created_at.hasError ? this.renderErrorMessage('created_at') : null}
                                    </div>
                                </div>
                                <div style={style.container}>
                                    <div style={style.content}>Payments : </div>
                                    <div
                                        className={`input-container${input.payment.hasError ? ' error' : ''}`}
                                    >
                                        <MaskedTextInput
                                            guide={true}
                                            mask={numberMask}
                                            name="payment"
                                            placeholder="$0"
                                            placeholderChar={'\u2000'}
                                            type="text"
                                            value={input.payment.value}
                                            onChange={event => this.handleDebtInputChange({
                                                payment: { value: event.target.value, hasError: false }
                                            }
                                            )}
                                        />
                                        {input.payment.hasError ? this.renderErrorMessage('payment') : null}
                                    </div>
                                </div>
                                <div style={style.container}>
                                    <div style={style.content}>Discount: </div>
                                    <div
                                        className={`input-container${input.adjustment.hasError ? ' error' : ''}`}
                                    >
                                        <MaskedTextInput
                                            guide={true}
                                            mask={numberMask}
                                            name="adjustment"
                                            placeholder="$0"
                                            placeholderChar={'\u2000'}
                                            type="text"
                                            value={input.adjustment.value}
                                            onChange={event => this.handleDebtInputChange({
                                                adjustment: { value: event.target.value, hasError: false }
                                            }
                                            )}
                                        />
                                        {input.adjustment.hasError ? this.renderErrorMessage('adjustment') : null}
                                    </div>
                                </div>
                                <div style={style.container}>
                                    <div style={style.content}>Additional Details : </div>
                                    <div
                                        className={`input-container${input.additional_details.hasError ? ' error' : ''}`}
                                    >
                                        <TextareaAutosize
                                            name="additional_details"
                                            type="textarea"
                                            placeholder="Your message here..."
                                            value={input.additional_details.value}
                                            onChange={event => this.handleDebtInputChange({ additional_details: { value: event.target.value, hasError: false } })}
                                        />
                                        {input.additional_details.hasError ? this.renderErrorMessage('additional_details') : null}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="modal-actions">
                            <a
                                className="cta"
                                onClick={(event) => this.state.disable ? event.preventDefault() : this.handleSubmit(event)}
                            >
                                Add Debt
                            </a>
                        </div>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default withRouter(AddNewDebt);
