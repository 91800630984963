import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import MaskedTextInput from "react-text-mask";
import EditIcon from "../../assets/icons/edit.svg";
import { validateEmail } from "../../utils/helpers";
import ConsumerSidebar from "./ConsumerSidebar";

class ConsumerProfileSidebar extends Component {
  static propTypes = {
    consumerData: PropTypes.object.isRequired,
  };
  state = {
    input: {
      username: {
        value: "",
        hasError: false,
      },
      first_name: {
        value: "",
        hasError: false,
      },
      last_name: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      phone: {
        value: "",
        hasError: false,
      },
    },
    showEdit: false,
  };

  componentDidMount() {
    const { consumerData } = this.props;
    let isemailEdit = consumerData.email ? true : false;
    this.setState((state) => ({
      input: {
        username: {
          ...state.input.username,
          value: consumerData.username,
        },
        first_name: {
          ...state.input.first_name,
          value: consumerData.first_name,
        },
        last_name: {
          ...state.input.last_name,
          value: consumerData.last_name,
        },
        phone: {
          ...state.input.phone,
          value: consumerData.phone_number,
        },
        email: {
          ...state.input.email,
          value: consumerData.email,
          isemailEditable: isemailEdit,
        },
      },
    }));
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleEditClick = (event) => {
    event.preventDefault();
    this.setState({ showEdit: !this.state.showEdit });
  };

  handleSubmit = (event) => {
    let { input } = this.state;
    event.preventDefault();
    if (this.validateForm()) {
      let userUpdate = {
        email: input.email.value,
        first_name: input.first_name.value,
        last_name: input.last_name.value,
        phone_number: input.phone.value,
      };
      this.props.handleSideBarSubmit(userUpdate);
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "first_name") {
        if (this.isEmpty(value.value)) {
          newInputState.first_name.hasError = true;
          errorsArePresent = true;
        }
      }
      if (key === "last_name") {
        if (this.isEmpty(value.value)) {
          newInputState.last_name.hasError = true;
          errorsArePresent = true;
        }
      }
      if (key === "phone") {
        let phoneNumber = value.value;
        if (this.isEmpty(value.value) || phoneNumber === null) {
          newInputState.phone.hasError = true;
          errorsArePresent = true;
        } else {
          let phoneNumber = value.value
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
          if (this.isEmpty(value.value)) {
            newInputState.phone.hasError = true;
            errorsArePresent = true;
          } else if (phoneNumber.trim().length < 10) {
            newInputState.phone.hasError = true;
            errorsArePresent = true;
          }
        }
      }
      if (key === "email") {
        if (this.isEmpty(value.value) || !validateEmail(value.value)) {
          newInputState.email.hasError = true;
          errorsArePresent = true;
        } else if (!validateEmail(value.value)) {
          newInputState.email.hasError = true;
        } else {
          newInputState.email.isemailEditable = true;
        }
      }
    });
    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  isEmpty = (value) => {
    if (value === "" || value === null || value === undefined) {
      return true;
    } else return false;
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "first_name") {
      message = "Please enter first name";
    } else if (input === "last_name") {
      message = "Please enter last name";
    } else if (input === "phone") {
      message = "Please enter a valid phone number";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  renderConsumerInfo = () => {
    const { input, showEdit } = this.state;
    return (
      <form
        className="consumer-profile-form"
        style={{ margin: "4em 0em 0em 0em" }}
      >
        {/* <div className={`input-container consumer-input`}>
          <input
            name="username"
            readOnly={true}
            placeholder="User Name"
            value={input.username.value || ""}
          />
        </div> */}
        <div className="admin-form-sidebar-row">
          <div
            className={`input-container consumer-input${
              input.first_name.hasError ? " error" : ""
            }`}
          >
            <input
              name="first_name"
              placeholder="First Name"
              type="text"
              readOnly={!showEdit}
              value={input.first_name.value || ""}
              onChange={(event) =>
                this.handleInputChange({
                  first_name: { value: event.target.value, hasError: false },
                })
              }
            />
            {input.first_name.hasError
              ? this.renderErrorMessage("first_name")
              : null}
          </div>
          <div
            className={`input-container consumer-input${
              input.last_name.hasError ? " error" : ""
            }`}
          >
            <input
              name="last_name"
              placeholder="Last Name"
              readOnly={!showEdit}
              type="text"
              value={input.last_name.value || ""}
              onChange={(event) =>
                this.handleInputChange({
                  last_name: { value: event.target.value, hasError: false },
                })
              }
            />
            {input.last_name.hasError
              ? this.renderErrorMessage("last_name")
              : null}
          </div>
        </div>
        <div className="admin-form-sidebar-row">
          <div
            className={`input-container consumer-input${
              input.phone.hasError ? " error" : ""
            }`}
          >
            <MaskedTextInput
              name="mobile"
              readOnly={!showEdit}
              mask={[
                "(",
                /\d/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholder="Phone Number"
              placeholderChar={"\u2000"}
              type="text"
              value={input.phone.value}
              onChange={(event) =>
                this.handleInputChange({
                  phone: {
                    value: event.target.value,
                    hasError: false,
                  },
                })
              }
            />
            {input.phone.hasError ? this.renderErrorMessage("phone") : null}
          </div>
          <div
            className={`input-container consumer-input${
              input.email.hasError ? " error" : ""
            }`}
          >
            <input
              name="email"
              placeholder="Email"
              readOnly={!showEdit}
              type="text"
              value={input.email.value || ""}
              onChange={(event) =>
                this.handleInputChange({
                  email: { value: event.target.value, hasError: false },
                })
              }
            />
            {input.email.hasError ? this.renderErrorMessage("email") : null}
          </div>
        </div>
      </form>
    );
  };

  sidebarContent = () => {
    return (
      <div className="app-sidebar-left">
        <div className="edit-header">
          <h4>Your Information</h4>
          <img
            src={EditIcon}
            height={15}
            width={15}
            onClick={(event) => this.handleEditClick(event)}
          />
        </div>
        {this.renderConsumerInfo()}
        <div className="history-btn-dynamic consumer-profile-side-btns">
          {this.state.showEdit ? (
            <button onClick={(event) => this.handleSubmit(event)}>
              Save Changes
            </button>
          ) : null}
          <Link to="/reset-password">Reset Password</Link>
        </div>
      </div>
    );
  };

  render() {
    let { user } = this.props;
    return (
      <ConsumerSidebar user={user} sidebarContent={this.sidebarContent()} primary_color={"#f5f7f8"}/>
    );
  }
}

export default ConsumerProfileSidebar;
