import React, { Component } from "react";
import moment from "moment";
import FileBase64 from "react-file-base64";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import $ from "jquery";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import Modal from "../Modal";
import AppCanvasLoader from "../../../App/AppCanvasLoader";

class ValidationDocumentModal extends Component {
  state = {
    input: {
      name: {
        value: null,
        hasError: false,
      },
      description: {
        value: "",
        hasError: false,
      },
      document: {
        value: "",
        hasError: false,
      },
      documentType: {
        id: "",
        value: "",
        hasError: false,
      },
      fileExtension: "",
      fileSizeInMB: "",
    },
    docTypeList: [],
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    disable: false,
  };

  componentDidMount() {
    let { optionalProps } = this.props;
    let { input } = this.state;
    if (optionalProps && optionalProps.isEditable && optionalProps.editData) {
      input.name.value = optionalProps.editData.name;
      input.description.value = optionalProps.editData.description;
      input.documentType.value = optionalProps.editData.document_type
        ? optionalProps.editData.document_type.name
        : "";
      input.documentType.id = optionalProps.editData.document_type
        ? optionalProps.editData.document_type.id
        : "";
      this.setState({ input });
    }
    this.getDocumentType();
  }

  getDocumentType = () => {
    let { user } = this.props;
    API.getDocumentType(user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.setSelectData(data.data);
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
          disable: false,
        }));
      }
    });
  };

  setSelectData = (data) => {
    if (data && data.length) {
      data.map((i) => {
        i.label = i.name;
        i.value = i.name;
      });
    }
    this.setState({ docTypeList: data });
  };

  handleUpdateSubmit = (event) => {
    event.preventDefault();
    let { user, optionalProps } = this.props;

    let { input } = this.state;
    if (this.validateForm(true)) {
      $("#upload-doc").replaceWith('<div class="spinner"></div>');
      let fromURI = "debts";
      if (
        this.props.optionalProps.from &&
        this.props.optionalProps.from === "credit_memo"
      ) {
        fromURI = "credits";
      }
      if (
        this.props.optionalProps.from &&
        this.props.optionalProps.from === "payment_plan"
      ) {
        fromURI = "payment_plan_document";
      }
      let payload = {
        description: input.description.value,
        name: input.name.value,
        document_id: optionalProps.editData.document_id,
        document_type_id: input.documentType.id,
      };
      API.updateValidationDocument(user, payload, fromURI).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            title: `Success Message`,
          });
          // if (data.data) {
          this.props.optionalProps.updateDocument(data.data);
          // }
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
            disable: false,
          }));
        }
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let { history, user, optionalProps } = this.props;
    let { input } = this.state;
    if (this.validateForm()) {
      $("#upload-doc").replaceWith('<div class="spinner"></div>');
      let payload;
      let fromURI = "debts";
      if (
        this.props.optionalProps.from &&
        this.props.optionalProps.from === "credit_memo"
      ) {
        fromURI = "credits";
        payload = {
          credit_memo_id: optionalProps.rowData.id,
          description: input.description.value,
          document: input.document.value,
          document_name: input.fileExtension,
          name: input.name.value,
          user_id: optionalProps.consumer.id,
          file_size_in_mb: input.fileSizeInMB,
          document_type_id: input.documentType.id,
        };
      } else if (
        this.props.optionalProps.from &&
        this.props.optionalProps.from === "payment_plan"
      ) {
        fromURI = "payment_plan_document";
        payload = {
          payment_plan_id: optionalProps.rowData.id,
          description: input.description.value,
          document: input.document.value,
          // document_name: input.fileExtension,
          name: input.name.value,
          document_type_id: input.documentType.id,
        };
      } else {
        payload = {
          invoice_id: optionalProps.rowData.id,
          description: input.description.value,
          document: input.document.value,
          document_name: input.fileExtension,
          name: input.name.value,
          user_id: optionalProps.consumer.id,
          file_size_in_mb: input.fileSizeInMB,
          document_type_id: input.documentType.id,
        };
      }

      API.uploadValidationDocument(user, payload, fromURI).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            title: `Success Message`,
          });
          this.props.optionalProps.reloadData();
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
            disable: false,
          }));
        }
      });
    }
  };

  getFiles(files) {
    let newInputState = { ...this.state.input };
    let imgExtension = files[0].type;
    let fileName = moment(new Date()).format("MMDDYYYY");
    let extensionFlag = false;
    if (!imgExtension) {
      imgExtension = files[0].name.split(".").pop();
    }
    let extensionFromName = files[0].name.split(".").pop();
    if (
      extensionFromName &&
      (extensionFromName.toLowerCase() === "xls" ||
        extensionFromName.toLowerCase() === "xlsx" ||
        extensionFromName.toLowerCase() === "doc" ||
        extensionFromName.toLowerCase() === "docx")
    ) {
      extensionFlag = true;
    }
    if (
      imgExtension.toLowerCase() === "xls" ||
      imgExtension.toLowerCase() === "xlsx" ||
      imgExtension.toLowerCase() === "doc" ||
      imgExtension.toLowerCase() === "docx" ||
      imgExtension.toLowerCase() === "image/png" ||
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg" ||
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf"
    ) {
      extensionFlag = true;
    }
    if (files[0].file && files[0].file.size > 10000000) {
      extensionFlag = false;
    } else {
      let file_size =
        files[0].file && files[0].file.size
          ? files[0].file.size / (1024 * 1024)
          : 0;
      this.state.input.fileSizeInMB = parseFloat(file_size).toFixed(2);
    }
    if (imgExtension.toLowerCase() === "image/png") {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".png"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg"
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".jpeg"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf" ||
      imgExtension.toLowerCase() === "application/jpeg"
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".pdf"
      }`;
    } else if (
      imgExtension.toLowerCase() === "xls" ||
      (extensionFromName && extensionFromName.toLowerCase() === "xls")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".xls"
      }`;
    } else if (
      imgExtension.toLowerCase() === "xlsx" ||
      (extensionFromName && extensionFromName.toLowerCase() === "xlsx")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".xlsx"
      }`;
    } else if (
      imgExtension.toLowerCase() === "doc" ||
      (extensionFromName && extensionFromName.toLowerCase() === "doc")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".doc"
      }`;
    } else if (
      imgExtension.toLowerCase() === "docx" ||
      (extensionFromName && extensionFromName.toLowerCase() === "docx")
    ) {
      this.state.input.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".docx"
      }`;
    }
    if (extensionFlag) {
      this.state.input.document.value = files[0].base64;
    } else {
      newInputState.document.hasError = true;
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    }
    this.setState({ input: this.state.input });
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  validateForm = (isUpdate) => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "description") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "documentType") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
      if (key === "document" && !isUpdate) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter a name";
    } else if (input === "description") {
      message = "Please enter a description";
    } else if (input === "type") {
      message = "Please select document type";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let { input, error, isLoading } = this.state;
    let { optionalProps } = this.props;
    const style = {
      container: { display: "flex" },
      content: { width: "60%", paddingTop: `10px` },
      label: { width: "40%", paddingTop: `10px` },
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title={
          this.props.optionalProps.mgModalTitle
            ? this.props.optionalProps.mgModalTitle
            : "Upload Document"
        }
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses='scroll'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form style={{ marginTop: "1rem" }}>
            <div style={style.container}>
              <div style={style.content}>Name : </div>
              <div
                className={`input-container${
                  input.name.hasError ? " error" : ""
                }`}
              >
                <input
                  name='name'
                  placeholder='Name'
                  placeholderchar={"\u2000"}
                  type='text'
                  maxLength={60}
                  value={input.name.value || ""}
                  onChange={(event) =>
                    this.handleInputChange({
                      name: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.name.hasError ? this.renderErrorMessage("name") : null}
              </div>
            </div>
            <div style={style.container}>
              <div style={style.content}>Description: </div>
              <div
                className={`input-container${
                  input.description.hasError ? " error" : ""
                }`}
              >
                <input
                  name='description'
                  placeholder='Your description here...'
                  placeholderchar={"\u2000"}
                  type='text'
                  value={input.description.value || ""}
                  onChange={(event) =>
                    this.handleInputChange({
                      description: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.description.hasError
                  ? this.renderErrorMessage("description")
                  : null}
              </div>
            </div>
            <div style={style.container}>
              <div style={style.content}>Document Type: </div>
              <div
                className={`input-container${
                  input.description.hasError ? " error" : ""
                }`}
              >
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  className='form-select'
                  name='type'
                  value={input.documentType.value}
                  placeholder='Select a type'
                  options={this.state.docTypeList}
                  onChange={(event) =>
                    this.handleInputChange({
                      documentType: {
                        id: event.id,
                        value: event.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.documentType.hasError
                  ? this.renderErrorMessage("type")
                  : null}
              </div>
            </div>

            {optionalProps && optionalProps.isEditable ? null : (
              <div
                className='admin-form-row address-document'
                style={{ marginTop: "1rem" }}
              >
                <div className={`input-container error file-text`}>
                  <FileBase64
                    multiple={true}
                    onDone={this.getFiles.bind(this)}
                  />
                  <div
                    className=' input-error-message'
                    style={{ color: "darkgrey" }}
                  >
                    Image upload format (.png, .jpg, .jpeg, .pdf, .doc, .docx,
                    .xls, .xlsx) <br /> Size must be less than 10 MB
                  </div>
                </div>
              </div>
            )}
            <div
              id='upload-doc'
              className='modal-actions'
              style={{ marginTop: "1rem" }}
            >
              <a
                className='cta mg-brand2-color'
                onClick={(event) =>
                  this.state.disable
                    ? event.preventDefault()
                    : optionalProps && optionalProps.isEditable
                    ? this.handleUpdateSubmit(event)
                    : this.handleSubmit(event)
                }
              >
                Upload Document
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(ValidationDocumentModal);
