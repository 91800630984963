import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import PropTypes from "prop-types";
import MGLogoIcon from "../assets/icons/MG-logo.png";
import { getIcon } from "../assets/icons/svg_icons_list.js";
import { usersAgreement, getOrganizationData, signout } from "../utils/api";
import onboardingBackgroundImage from "../assets/images/background_onboarding.png"

class MakeGoodEULA extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    appName: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      checkScrollEnd: false,
    };
  }
  backgroundStyle = {
    backgroundImage: `url(${onboardingBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: "100vw",
    height: "100vh",
  }
  handleAcceptTermsCheckBox = (flag) => {
    if (flag) {
      this.setState({ checkScrollEnd: true });
    } else {
      this.setState({ checkScrollEnd: false });
    }
  };

  declineUserAgreement = () => {
    let cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    getOrganizationData().then((data) => {
      let user = {
        user_auth_token: "Bearer " + authuser,
        organization_key: data.organization_key,
      };
      if (authuser && authuser != null) {
        signout(user).then(() => {
          this.props.handleSignOut();
        });
      } else {
        this.props.handleSignOut();
      }
    });
  };

  agreeUserAgreement = () => {
    let cookies = new Cookies();
    this.props.setEULAtrue();
    usersAgreement(this.props.user.user_id, this.props.user).then((data) => {
      if (data.status_code === 200) {
        if (this.props.hasOwnProperty("sub_id") && this.props.sub_id !== "") {
          this.props.setEULAtrue();
        } else {
          window.location.href = "/welcome-screen";
        }
      } else if (data.status_code === 400) {
        this.declineUserAgreement();
      }
    }).catch((data) => {
      this.declineUserAgreement();
    });
  };

  render() {
    let { checkScrollEnd } = this.state;

    return (
      <div style={this.backgroundStyle}>
        <div className="eula-dialogueBox">
          <div className="eula-dialogueBox-header-alignment">
            <div className="company-logo-center">
              <img className="companyLogoDiv-consumer-view" src={MGLogoIcon} />
            </div>
            <div>
              <h3 className="title-style-eula">
                MAKEGOOD LLC End User License Agreement
              </h3>
              <h4 className="header-style-eula">(Version: 0.1)</h4>
            </div>
          </div>
          <div
            className="eula-dialogueBox-tncPages"
            onScroll={this.handleScrollEvent}
          >
            <div className="eula-dialogueBox-content-wrap">
              <p>
                READ THIS CAREFULLY. BY CLICKING THE "ACCEPT" BUTTON, YOU <br />
                (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS
                AGREEMENT; <br />
                (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER/OF LEGAL AGE
                TO ENTER INTO A BINDING AGREEMENT; <br />
                (C) YOU HAVE THE AUTHORITY ON BEHALF OF THE BUSINESS OR COMPANY
                YOU REPRESENT TO ENTER INTO THIS AGREEMENT AND HAVE THAT COMPANY
                OR BUSINESS BE BOUND BY THESE TERMS; AND <br />
                (D) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND
                BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT
                DOWNLOAD OR USE THE APPLICATION.
              </p>
              <p>
                This End User License Agreement ("<strong>Agreement</strong>")
                is a binding agreement between the individual, corporation, LLC,
                partnership, sole proprietorship or other business entity
                executing this Agreement (collectively, "
                <strong>End User</strong>" or "<strong>you</strong>") and
                MakeGood LLC("
                <strong>Company</strong>"). This Agreement is effective as of
                the date you click “ACCEPT”. This Agreement governs your use of
                the MakeGood application, (including all related documentation,
                the "<strong>Application</strong>"). The Application is
                licensed, not sold, to you.
              </p>

              <h3 className="sub-header-style-eula">1. License Grant.</h3>
              <p>
                Subject to the terms of this Agreement, Company grants you a
                limited, non-exclusive, and nontransferable license to:
                <br />
                (a) Download, install, and use the Application for your use on a
                single device owned or otherwise controlled by you ("
                <strong>Device</strong>") strictly in accordance with the
                Application's documentation; and
                <br />
                (b) Access, stream, download, and use on such Device the Content
                and Services (as defined below) made available in or otherwise
                accessible through the Application, strictly in accordance with
                this Agreement and the Terms of Use applicable to such Content
                and Services as set forth below.
              </p>

              <h3 className="sub-header-style-eula">2. License Restrictions.</h3>
              <p>
                You shall not:
                <br />
                (a) Copy the Application, except as expressly permitted by this
                license;
                <br />
                (b) Modify, translate, adapt, or otherwise create derivative
                works or improvements, whether or not patentable, of the
                Application;
                <br />
                (c) Reverse engineer, disassemble, decompile, decode, or
                otherwise attempt to derive or gain access to the source code of
                the Application or any part thereof;
                <br />
                (d) Remove, delete, alter, or obscure any trademarks or any
                copyright, trademark, patent, or other intellectual property or
                proprietary rights notices from the Application, including any
                copy thereof;
                <br />
                (e) Rent, lease, lend, sell, sublicense, assign, distribute,
                publish, transfer, or otherwise make available the Application,
                or any features or functionality of the Application, to any
                third party for any reason, including by making the Application
                available on a network where it is capable of being accessed by
                more than one device at any time;
                <br />
                (f) Remove, disable, circumvent, or otherwise create or
                implement any workaround to any copy protection, rights
                management, or security features in or protecting the
                Application.
                <br />
                (g) Engage in any activity with the Application that interferes
                with, disrupts, damages, or accesses in an unauthorized manner
                the servers, networks, or other properties or services of any
                third party.
                <br />
                (h) Use the Application in any way which fails to comply with
                applicable laws and regulations, including without limitation
                using the Application in any way in furtherance of criminal,
                fraudulent, or other unlawful activity.
                <br />
              </p>

              <h3 className="sub-header-style-eula">3. Fees.</h3>
              <p>
                You will pay Company the fee set forth in each order (the
                “Subscription Fee”) for each Term (as that term is used in the
                order). Company will not be required to refund the Subscription
                Fee under any circumstances.
              </p>

              <h3 className="sub-header-style-eula">4. Reservation of Rights.</h3>
              <p>
                You acknowledge and agree that the Application is provided under
                license, and not sold, to you. You do not acquire any ownership
                interest in the Application under this Agreement, or any other
                rights thereto other than to use the Application in accordance
                with the license granted, and subject to all terms, conditions,
                and restrictions, under this Agreement. Company reserves and
                shall retain its entire right, title, and interest in and to the
                Application, including all copyrights, trademarks, and other
                intellectual property rights therein or relating thereto, except
                as expressly granted to you in this Agreement. This Agreement
                does not grant you any intellectual property license or rights
                in the Application or any of its components. You recognize that
                the Application and its components are protected by copyright
                and other laws. By submitting ideas, suggestions or feedback to
                the Company regarding the Application, you agree that such items
                submitted do not contain confidential or proprietary
                information; and you hereby grant the Company an irrevocable,
                unlimited, royalty-free and fully-paid perpetual license to use
                such items for any business purpose.
              </p>

              <h3 className="sub-header-style-eula">5. Collection and Use of Your Information.</h3>
              <p>
                You acknowledge that when you download, install, or use the
                Application, Company may use automatic means (including, for
                example, cookies and web beacons) to collect information about
                your Device and about your use of the Application. You also may
                be required to provide certain information about yourself as a
                condition to downloading, installing, or using the Application
                or certain of its features or functionality. All information we
                collect through or in connection with this Application is
                subject to our{" "}
                <a
                  target="_blank"
                  href="http://www.makegoodapp.com/privacypolicy"
                >
                  Privacy Policy
                </a>
                . By downloading, installing, using, and providing information
                to or through this Application, you consent to all actions taken
                by us with respect to your information in compliance with the
                Privacy Policy. You recognize and agree that hosting data online
                involves risks of unauthorized disclosure or exposure and that,
                in accessing and using the Content and Services, you assume such
                risks. Company offers no representation, warranty, or guarantee
                that Customer Data (which means data in electronic form input or
                collected through the Application by or from you). will not be
                exposed or disclosed through errors or the actions of third
                parties. Company may use, reproduce, sell, publicize, or
                otherwise exploit Aggregate Data in any way, in its sole
                discretion. “Aggregate Data” refers to Customer Data with the
                following removed: personally identifiable information and the
                names and addresses of You and/or customers.
              </p>

              <h3 className="sub-header-style-eula">6. Content and Services.</h3>
              <p>
                The Application may provide you with access to Company's website
                located at{" "}
                <a target="_blank" href="http://www.makegoodapp.com">
                  www.makegoodapp.com
                </a>{" "}
                including all subdomains and any other related domain
                names(collectively, the "<strong>Website</strong>") and products
                and services accessible thereon, and certain features,
                functionality, and content accessible on or through the
                Application may be hosted on the Website (collectively, "
                <strong>Content and Services</strong>
                "). Your access to and use of such Content and Services are
                governed by Website's Terms of Use and Privacy Policy located at{" "}
                <a target="_blank" href="http://www.makegoodapp.com/termsofuse">
                  www.makegoodapp.com/termsofuse
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="http://www.makegoodapp.com/privacypolicy"
                >
                  www.makegoodapp.com/privacypolicy
                </a>
                , which are incorporated herein by this reference, as they may
                be amended from time to time. In the event of a conflict between
                this Agreement, the Terms of Use and/or the Privacy Policy, the
                provision most favorable to the Company shall control. Your
                access to and use of such Content and Services may require you
                to acknowledge your acceptance of such Terms of Use and Privacy
                Policy and/or to register with the Website, and your failure to
                do so may restrict you from accessing or using certain of the
                Application's features and functionality. Any violation of such
                Terms of Use will also be deemed a violation of this Agreement.
                Company may permanently erase Customer Data if your account is
                delinquent, suspended, or terminated for 30 days or more.
                Without limiting the generality of Sections 11 and 12 of this
                Agreement, the Content and Services are designed to provide
                tools to assist you in the collection of your own accounts
                receivable. These tools are for your use in your reasonable
                discretion. The Company does not engage directly with your
                customers, and is not a “debt collector” as defined under the
                Federal Fair Debt Collection Practices Act as it may be amended
                from time to time, or any similar term used under any similar
                state statutes. In providing these tools, the Company may make
                recommendations. These recommendations are not intended to be
                legal advice, nor does the Company represent that all such
                recommendations are in compliance with all applicable laws, and
                you may accept or reject such recommendations at your own risk.
              </p>

              <h3 className="sub-header-style-eula">7. Geographic Restrictions.</h3>
              <p>
                The Content and Services are based in the United States and
                provided for access and use only by persons located in the
                United States. You acknowledge that you may not be able to
                access all or some of the Content and Services outside of the
                United States and that access thereto may not be legal by
                certain persons or in certain countries. If you access the
                Content and Services from outside the United States, you are
                responsible for compliance with local laws.
              </p>

              <h3 className="sub-header-style-eula">8. Updates.</h3>
              <p>
                Company may from time to time in its sole discretion develop and
                provide Application updates, which may include upgrades, bug
                fixes, patches, other error corrections, and/or new features
                (collectively, including related documentation, "
                <strong>Updates</strong>"). Updates may also modify or delete in
                their entirety certain features and functionality. You agree
                that Company has no obligation to provide any Updates or to
                continue to provide or enable any particular features or
                functionality. Based on your Device settings, when your Device
                is connected to the internet either:
                <br />
                (a) The Application will automatically download and install all
                available Updates; or
                <br />
                (b) You may receive notice of or be prompted to download and
                install available Updates.
              </p>
              <p>
                You shall promptly download and install all Updates and
                acknowledge and agree that the Application or portions thereof
                may not properly operate should you fail to do so. You further
                agree that all Updates will be deemed part of the Application
                and be subject to all terms and conditions of this Agreement.
              </p>

              <h3 className="sub-header-style-eula">9. Third-Party Materials.</h3>
              <p>
                The Application may display, include, or make available
                third-party content (including data, information, applications,
                and other products, services, and/or materials) or provide links
                to third-party websites or services, including through
                third-party advertising ("
                <strong>Third-Party Materials</strong>
                "). You acknowledge and agree that Company is not responsible
                for Third-Party Materials, including their accuracy,
                completeness, timeliness, validity, copyright compliance,
                legality, decency, quality, or any other aspect thereof. Company
                does not assume and will not have any liability or
                responsibility to you or any other person or entity for any
                Third-Party Materials. Third-Party Materials and links thereto
                are provided solely as a convenience to you, and you access and
                use them entirely at your own risk and subject to such third
                parties' terms and conditions. You acknowledge that third party
                vendors may make information regarding your use of the
                Application, including information which may be deemed to be
                personally identifiable information of your customers, available
                to the Company.
              </p>

              <h3 className="sub-header-style-eula">10. Term and Termination.</h3>
              <p>
                (a) The term of Agreement commences when you download the
                Application and will continue in effect until terminated by you
                or Company as set forth in this Section.
                <br />
                (b) You may terminate this Agreement by deleting the Application
                and all copies thereof from your Device.
                <br />
                (c) Company may terminate this Agreement at any time without
                notice if it ceases to support the Application, which Company
                may do in its sole discretion. In addition, this Agreement will
                terminate immediately and automatically without any notice if
                you violate any of the terms and conditions of this Agreement.
                <br />
                (d) Upon termination:
                <br />
                <ol>
                  <li>
                    All rights granted to you under this Agreement will also
                    terminate; and
                  </li>
                  <li>
                    You must cease all use of the Application and delete all
                    copies of the Application from your Device and account.
                  </li>
                </ol>
                (e) Termination will not limit any of Company's rights or
                remedies at law or in equity.
              </p>

              <h3 className="sub-header-style-eula"> 11. Disclaimer of Warranties.</h3>
              <p>
                THE APPLICATION IS PROVIDED TO END USER "AS IS" AND WITH ALL
                FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM
                EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN
                BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR
                RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS
                ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY
                ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR
                TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY
                PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION
                OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS,
                ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY
                OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE
                WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY
                STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN
                OR WILL BE CORRECTED.
              </p>
              <p>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS
                ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE
                STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE
                EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
              </p>

              <h3 className="sub-header-style-eula">12. Limitation of Liability.</h3>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
                RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY
                ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE
                APPLICATION OR THE CONTENT AND SERVICES FOR:
                <br />
                (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL,
                BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY
                OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                OR PUNITIVE DAMAGES.
                <br />
                (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED FIFTY
                DOLLARS ($50.00).
                <br />
                THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE
                OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
                OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE
                FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF
                LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY
                MAY NOT APPLY TO YOU.
              </p>

              <h3 className="sub-header-style-eula">13. Indemnification.</h3>
              <p>
                You agree to indemnify, defend, and hold harmless Company and
                its members, managers, officers, directors, employees, agents,
                affiliates, successors, and assigns from and against any and all
                losses, damages, liabilities, deficiencies, claims, actions,
                judgments, settlements, interest, awards, penalties, fines,
                costs, or expenses of whatever kind, including reasonable
                attorneys' fees, arising from or relating to your use or misuse
                of the Application or your breach of this Agreement, including
                but not limited to the content you submit or make available
                through this Application.
              </p>

              <h3>14. Export Regulation.</h3>
              <p>
                The Application may be subject to US export control laws,
                including the Export Control Reform Act and its associated
                regulations. You shall not, directly or indirectly, export,
                re-export, or release the Application to, or make the
                Application accessible from, any jurisdiction or country to
                which export, re-export, or release is prohibited by law, rule,
                or regulation. You shall comply with all applicable federal
                laws, regulations, and rules, and complete all required
                undertakings (including obtaining any necessary export license
                or other governmental approval), prior to exporting,
                re-exporting, releasing, or otherwise making the Application
                available outside the US.
              </p>

              <h3 className="sub-header-style-eula">15. Severability.</h3>
              <p>
                If any provision of this Agreement is illegal or unenforceable
                under applicable law, the remainder of the provision will be
                amended to achieve as closely as possible the effect of the
                original term and all other provisions of this Agreement will
                continue in full force and effect.
              </p>

              <h3 className="sub-header-style-eula">16. Governing Law: Venue.</h3>
              <p>
                This Agreement is governed by and construed in accordance with
                the internal laws of the State of Rhode Island without giving
                effect to any choice or conflict of law provision or rule. Any
                legal suit, action, or proceeding arising out of or related to
                this Agreement or the Application shall be instituted
                exclusively in the federal courts of the United States or the
                courts of the State of Rhode Island in each case located in
                Providence County. You waive any and all objections to the
                exercise of jurisdiction over you by such courts and to venue in
                such courts. Your breach of this Agreement may cause irreparable
                injury or harm to the Company, in which case the Company may, in
                addition to any other rights it may have, seek an injunction or
                any other court order to stop any breach or avoid any future
                breach without posting a bond or surety of any kind. You shall
                reimburse the Company for its fees and costs incurred in
                enforcing its rights under this Agreement, including all
                attorneys’ fees.
              </p>

              <h3 className="sub-header-style-eula">17. Limitation of Time to File Claims.</h3>
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE
                SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </p>

              <h3 className="sub-header-style-eula">18. Entire Agreement; Amendments.</h3>
              <p>
                This Agreement, our website Terms of Use and our Privacy Policy
                constitute the entire agreement between you and Company with
                respect to the Application and supersede all prior or
                contemporaneous understandings and agreements, whether written
                or oral, with respect to the Application. The Company rejects
                additional or conflicting terms of any form purchasing document
                you submit. This Agreement may not be amended other than by a
                written agreement executed by the parties; provided, however
                that the Company may amend this Agreement as it relates to all
                similarly situated End Users by posting an amendment on the
                Website. Such amendment shall not be effective until at least 15
                days after such posting. If such amendment is unacceptable to
                you, you may terminate this Agreement upon notice to the Company
                as provided below. Your continued use of the Application shall
                constitute acceptance of the amendment.
              </p>

              <h3 className="sub-header-style-eula">19. Waiver.</h3>
              <p>
                No failure to exercise, and no delay in exercising, on the part
                of either party, any right or any power hereunder shall operate
                as a waiver thereof, nor shall any single or partial exercise of
                any right or power hereunder preclude further exercise of that
                or any other right hereunder.
              </p>

              <h3 className="sub-header-style-eula">20. MISCELLANEOUS.</h3>
              <p>
                (a) Independent Contractors. The parties are independent
                contractors and will so represent themselves in all regards.
                Neither party is the agent of the other, and neither may make
                commitments on the other’s behalf.
                <br />
                (b) Notices. Company may send notices pursuant to this Agreement
                to your email contact points provided by you, and such notices
                will be deemed received 24 hours after they are sent. You may
                send notices pursuant to this Agreement to info@makegoodapp.com,
                and such notices will be deemed received 72 hours after they are
                sent. Without limiting the foregoing, you agree that a posting
                on the Company’s website shall be deemed proper notice.
                <br />
                (c) Force Majeure. No delay, failure, or default, other than a
                failure to pay fees when due, will constitute a breach of this
                Agreement to the extent caused by acts of war, terrorism,
                hurricanes, earthquakes, other acts of God or of nature, strikes
                or other labor disputes, riots or other acts of civil disorder,
                embargoes, or other causes beyond the performing party’s
                reasonable control.
                <br />
                (d) Assignment & Successors. You may not assign this Agreement
                or any of its rights or obligations hereunder without Company’s
                express written consent. Except to the extent forbidden in this
                Section, this Agreement will be binding upon and inure to the
                benefit of the parties’ respective successors and assigns. You
                acknowledge that Intuit Inc. is a third party beneficiary under
                this Agreement with the rights to enforce the terms of this
                Agreement.
              </p>
            </div>
          </div>
          <div className="eula-dialogueBox-footer-align">
            <div>
              {checkScrollEnd ?
                <span className="check-box" onClick={() => this.handleAcceptTermsCheckBox(false)}>{getIcon("check-box-closed")}</span>
                :
                <span className="check-box" onClick={() => this.handleAcceptTermsCheckBox(true)}>{getIcon("check-box-open")}</span>
              }
              <span>I have read & accepted all terms & Conditions</span>
            </div>
            <div>
              <div className="eula-decline" onClick={this.declineUserAgreement}>
                <button>Decline</button>
              </div>
              {this.state.checkScrollEnd ? (
                <div className="eula-active-next">
                  <button onClick={this.agreeUserAgreement}>
                    Accept & Continue
                  </button>
                </div>
              ) : (
                <div className="eula-inactive-next">
                  <button>Accept & Continue</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MakeGoodEULA;