import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import BottomScrollListener from "react-bottom-scroll-listener";
// import ReactGA from 'react-ga';

// API
import * as API from "../../../utils/api";
import { getZohoPagesense } from "../../../utils/helpers";

// Components
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ObjectHistoryList from "../../../components/ObjectList/ObjectHistoryList";

class EmployeeEmailHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedData: [],
      fromChild: "",
      isLoading: true,
      hasMoreData: false,
      showFlash: false,
      search: {
        value: props.searchText || "",
      },
      offset: 0,
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getHistoryData();
    getZohoPagesense();
    // ReactGA.pageview("/EmailHistory");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchText !== this.props.searchText) {
      this.setState(
        (state) => ({
          ...state,
          search: {
            value: nextProps.searchText,
          },
          offset: 0,
          savedData: [],
          isLoading: true,
        }),
        () => {
          this.getHistoryData();
        }
      );
    }
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getHistoryData();
        }
      );
    }
  };

  getHistoryData() {
    const { user } = this.props;
    const { offset, search } = this.state;

    this.setState((state) => ({ ...state, showLoader: true }));

    API.fetchMailchimpCampaignHistory(user, offset, search.value).then(
      (data) => {
        let isAccessDenied =
          data && data.message && data.message == "Access denied."
            ? true
            : false;

        if (
          (typeof data != "undefined" || data != null) &&
          !data.errors &&
          !isAccessDenied
        ) {
          if (data.data && data.data.length) {
            let savedData = this.state.savedData;
            let newData = savedData.concat(data.data.automations);
            this.setState((state) => ({
              savedData: newData,
              isLoading: false,
              hasMoreData: data.data.automations.length < 20 ? false : true,
              showLoader: false,
            }));

            this.setState((state) => ({
              ...state,
              filterBy: {
                ...state.filterBy,
                offset: data.offset,
              },
            }));
          } else {
            this.setState((state) => ({
              isLoading: false,
              showLoader: false,
            }));
          }
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Template Name",
      "Campaign Name",
      "Status",
      "Date & Time",
      "Mail Sent",
    ];

    columns = data.map((row) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: row.recipients.list_name ? row.recipients.list_name : "n/a",
          },
          {
            title: row.settings ? row.settings.title : "n/a",
          },
          {
            title: row.status,
          },
          {
            title: row.create_time
              ? moment(row.create_time).format("MM/DD/YYYY HH:mm:ss")
              : "n/a",
          },
          {
            title: row.emails_sent,
          },
        ],
        rowLinkPath: `/employee/messageHistory/mail/${row.id}`,
        fullRowIsClickable: true,
      };
    });
    return {
      headings,
      columns,
    };
  };

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <div style={{ marginTop: `10rem` }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div>
        {this.state.savedData.length > 0 ? (
          <BottomScrollListener
            offset={50}
            onBottom={this.handleContainerOnBottom}
            debounce={0}
          >
            {(scrollRef) => (
              <div>
                <ObjectHistoryList
                  optionalClasses={"object-debt-list object-list-color"}
                  data={this.getObjectListData(this.state.savedData)}
                  scrollRef={scrollRef}
                />
                {this.state.showLoader && (
                  <div>
                    <AppCanvasLoader />
                  </div>
                )}
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className='admin-admins-empty-state'>
            <h2>No email history available.</h2>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(EmployeeEmailHistory);
