import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ObjectChatList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    consumer: PropTypes.object
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = '(' + hours + ':' + minutes + ' ' + ampm + ')';
    return strTime;
  }

  render() {
    let { data, consumer } = this.props;
    let date1 = new Date(data.columns[0].chatData.createdAt);
    return (
      <div>
        <div className="chat-date">{date1.toDateString()}</div>
        {
          data.columns.map((row, index) => {
            let date = new Date(row.chatData.createdAt);
            let hoursMinuts = this.formatAMPM(date);
            let user = row.chatData.userRole ? row.chatData.userRole : '';
            return (
              row.chatData.userText ?
                <div key={index}>
                  <div className="object-row-header-name chat-text-color"
                    dangerouslySetInnerHTML={{
                      __html: '<p>' + hoursMinuts + ' ' + user + ' </p>: ' + row.chatData.userText
                    }}></div>
                </div>
                : ''
            )
          })
        }
      </div>
    );
  }
}

export default ObjectChatList;
