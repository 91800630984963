import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import shortid from "shortid";
// Components
import HeaderMenuItem from "../../../../components/HeaderMenuItem";
import Logo from "../../../../components/Logo";
import HeaderOverflowMenu from "../../../../components/ObjectList/HeaderOverflowMenu";
import * as API from "../../../../utils/api";

class ExpressHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowAction: [
        {
          title: "Get Help",
          dataBehavior: "get-help",
        },
        {
          title: "Sign In",
          dataBehavior: "sign-in",
          icon: <div></div>,
        },
      ],
      appThemeData: {},
    };
  }
  getLogoColorsForPayment() {
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
        });
      })
      .catch(() => {});
  }
  componentDidMount() {
    this.getLogoColorsForPayment();
  }

  handleClickAction = (item) => {
    let { history } = this.props;
    if (item && item.id) {
      this.setState(
        { showSearchHeader: false, id: "", searchOptions: [] },
        () => {
          history.push(`/client/invoices/${item.id}`);
        }
      );
    }
  };

  handleClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";

    switch (actionBehavior) {
      case "get-help":
        history.push(`/help`);
        break;
      case "sign-in":
        history.push(`/`);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  clearData = () => {
    //
  };

  overflowMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <HeaderOverflowMenu showThreeDots={true} clearData={this.clearData}>
          {this.state.rowAction.map((rowAction) => {
            return (
              <HeaderMenuItem
                key={shortid.generate()}
                rowAction={rowAction}
                dataBehavior={rowAction.dataBehavior}
                handleClick={(event, rowAction) =>
                  this.handleClick(event, rowAction)
                }
                optionalClasses='drop-down-options'
              >
                {rowAction}
              </HeaderMenuItem>
            );
          })}
        </HeaderOverflowMenu>
      </div>
    );
  };

  render() {
    let { logoPath, appName, hideMenu } = this.props;

    return (
      <header
        className={`app-client-header consumer-mobile-header`}
        style={{ zIndex: 10 }}
      >
        <Logo
          logoUrl={this.state.appThemeData.logo_url}
          linkPath={logoPath}
          linkTitle={appName}
          hideNav={true}
          accountHolder='consumer'
        />
        {!hideMenu && (
          <div className={`app-client-header-content`}>
            <div className='overflow-menu'>{this.overflowMenu()}</div>
            <div className='app-menu'>
              <div className='app-client-header-menu'></div>
            </div>
          </div>
        )}
      </header>
    );
  }
}

export default withRouter(ExpressHeader);
