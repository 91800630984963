import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

class ObjectHistoryHeader extends Component {
  static propTypes = {
    headings: PropTypes.array.isRequired
  }

  render() {
    let { headings } = this.props;

    return (
      <div className="object-debt-list-header">
        <div className="object-debt-list-header-secondary-headings">
          {headings.map((heading, index) => {
              return (
                <h4 key={shortid.generate()} className="object-debt-list-header-heading" style = {{fontSize: `0.905em`}}>
                  {heading}
                </h4>
              );
            return null;
          })}
        </div>
      </div>
    )
  }
}

export default ObjectHistoryHeader;
