import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
// Utils
import { validateEmail } from "../../utils/helpers";
import * as API from "../../utils/api";
// import companyData from '../../assets/styles/theme.json';

// helpers
import { errorMessage, networkMessage, showToast } from "../../utils/helpers";
class LoginForm extends Component {
  state = {
    input: {
      email: {
        value: "",
        hasError: false,
      },
      password: {
        value: "",
        hasError: false,
      },
    },
    companyData: {},
    accessError: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    errorMsg: "",
  };
  componentDidMount() {
    this.getOrganizationData();
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);

    if (searchParams.size !== 0 && searchParams.get("user")) {
      this.handleInputChange({
        email: { value: searchParams.get("user"), hasError: false },
      });
    }
  }

  async getOrganizationData() {
    let me = this;
    await API.getOrganizationData().then((data) => {
      this.setState({
        appNmae: data.name,
        clientName: data.clientName,
        companyData: data,
      });
    });
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const userCandidate = {
      email: this.state.input.email.value,
      password: this.state.input.password.value,
      organization_key: this.state.companyData.organization_key,
    };
    if (this.validateForm()) {
      //$('#login-btn-submit').replaceWith('<div class="spinner"></div>');
      API.setOauthObj(userCandidate);
      API.login(userCandidate).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (!data.errors) {
            const user = {
              qb_connect:
                data.is_qb_connected === null ? false : data.is_qb_connected,
              first_name: data.first_name,
              email: data.email,
              last_name: data.last_name,
              user_id: data.id,
              user_auth_token: data.auth_token,
              is_onboarding_completed: data.is_onboarding_completed,
              admin: data.admin,
              is_employee: data.is_employee,
              super_admin: data.super_admin,
              onboarding_complete: data.accepted_on ? true : false,
              client_admin: data.client_admin,
              verified: data.verified,
              verified_at: data.verified_at,
              user_hash: data.user_hash,
              intercom_id: data.intercom_id,
              accounting_system: data.accounting_system,
            };
            this.props.updateUserLogin(user);
          } else {
            this.setState((state) => ({
              ...state,
              showFlash: true,
              accessError: errorMessage,
              errorMsg: data.errors,
            }));
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;
    if (!validateEmail(newInputState.email.value)) {
      newInputState.email.hasError = true;
      errorsArePresent = true;
    }

    if (newInputState.password.value === "") {
      newInputState.password.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage = (input) => {
    let message = "";
    let { errorMsg } = this.state;
    if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "password") {
      message = "Please enter a password";
    } else {
      if (errorMsg) {
        let errorMessage = "";
        let splitErrorMsg = errorMsg ? errorMsg.split(".") : "";
        splitErrorMsg.forEach(function (msg, i) {
          if (i != 0) {
            errorMessage = errorMessage + msg;
            if (i > 0 && i < splitErrorMsg.length - 1) {
              errorMessage = errorMessage + ".";
            }
          }
        });
        message = errorMessage;
      } else {
        message = "Invalid email or password";
      }
    }
    return <div className='input-error-message'>{message}</div>;
  };

  render() {
    const { input, accessError, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div>
        {accessError && (
          <div className='input-container error'>
            {this.renderErrorMessage()}
          </div>
        )}
        <form
          className='sessions-form'
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <div
            className={`input-container${input.email.hasError ? " error" : ""}`}
          >
            <label>Email Address</label>
            <input
              name='email'
              type='email'
              placeholder='you@youremail.com'
              value={input.email.value}
              onChange={(event) =>
                this.handleInputChange({
                  email: { value: event.target.value, hasError: false },
                })
              }
            />
            {input.email.hasError ? this.renderErrorMessage("email") : null}
          </div>
          <div
            className={`input-container${
              input.password.hasError ? " error" : ""
            }`}
          >
            <label>Password</label>
            <div className='password-input-container'>
              <input
                className={`${input.password.hasError ? "error" : ""}`}
                name='password'
                type='password'
                placeholder='password'
                value={input.password.value}
                onChange={(event) =>
                  this.handleInputChange({
                    password: { value: event.target.value, hasError: false },
                  })
                }
              />
              <Link className='forgot-password-link' to='/reset-password'>
                Forgot?
              </Link>
            </div>
            {input.password.hasError
              ? this.renderErrorMessage("password")
              : null}
          </div>
          <input
            id='login-btn-submit'
            className='cta mg-button-common'
            type='submit'
            value='Login'
            style={{
              backgroundColor: this.props.primary_color,
              borderColor: this.props.primary_color,
            }}
          />
        </form>
      </div>
    );
  }
}

export default LoginForm;
