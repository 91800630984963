import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";
import moment from "moment";

class InventoryItemFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowToggle: false,
      selectedCheckBox: [],
      agingSummaryData: [],
      selected_items: [],
      isLoading: true,
      offset: 0,
      showLoader: false,
      buttonVisibility: false,
      hasError: false,
    };
  }

  componentDidMount() {
    let { selected_items, user } = this.props;

    this.setState({
      selected_items: selected_items,
    });
    if (user) {
      this.getReportData();
    }
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.selectedCheckBox.length > 0) {
      this.state.selected_items = this.state.selectedCheckBox;
    }
    let data = this.removeDuplicate(this.state.selected_items);
    this.props.hideModal(data);
  };

  getFormattedDate = (date) => {
    if (date) return moment(new Date(date)).format("YYYY-MM-DD");
    return date;
  };

  getReportData = () => {
    const reportId = this.props.match.params.id;
    let fromDate = "";
    let toDate = "";
    if (this.props.item.fromDate.value && this.props.item.toDate.value) {
      fromDate = this.getFormattedDate(this.props.item.fromDate.value);
      toDate = this.getFormattedDate(this.props.item.toDate.value);
    }

    API.arAgingSummaryList(
      this.props.user,
      reportId,
      fromDate,
      toDate,
      []
    ).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data.length > 0) {
          this.setState(
            {
              agingSummaryData: data.data,
            },
            () => {
              this.setLocalId();
            }
          );
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  setLocalId = () => {
    let { agingSummaryData, selectedCheckBox } = this.state;
    let { selected_items } = this.props;
    if (agingSummaryData && agingSummaryData.length) {
      agingSummaryData.map((agingSummaryDataObj, index) => {
        agingSummaryDataObj.localId = index;
        if (selected_items && selected_items.length) {
          let index = selected_items.findIndex(
            (o) => o === agingSummaryDataObj.item_name
          );
          if (index > -1) {
            agingSummaryDataObj.is_checked = true;
            selectedCheckBox.push(agingSummaryDataObj.item_name);
          } else {
            agingSummaryDataObj.is_checked = false;
            let index = selectedCheckBox.indexOf(agingSummaryDataObj.item_name);
            if (index > -1) {
              selectedCheckBox.splice(index, 1);
            }
          }
        }
      });
    }
    this.setState({
      agingSummaryData: agingSummaryData,
      selectedCheckBox: selectedCheckBox,
    });
  };

  removeDuplicate = (data) => {
    if (data) {
      return data.filter((value, index) => data.indexOf(value) === index);
    }
  };

  multiSelect = () => {
    if (this.state.selectedCheckBox.length > 1) {
      let first =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 2];
      let last =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 1];
      if (first > last) {
        let temp = first;
        first = last;
        last = temp;
      }
      this.state.agingSummaryData.map((agingSummaryDataObj) => {
        if (
          agingSummaryDataObj.localId >= first &&
          agingSummaryDataObj.localId <= last
        ) {
          agingSummaryDataObj.is_checked = true;
        }
      });
    }
    this.setState({ agingSummaryData: this.state.agingSummaryData });
  };

  onCheckboxClick = (event, item) => {
    const item_name = event.target.name;
    let isShiftKey = event.shiftKey;
    this.state.agingSummaryData.map((agingSummaryDataObj) => {
      if (agingSummaryDataObj.item_name == item_name) {
        if (event.target.checked) {
          agingSummaryDataObj.is_checked = true;
          this.state.selectedCheckBox.push(item_name);
        } else {
          agingSummaryDataObj.is_checked = false;
          let index = this.state.selectedCheckBox.indexOf(item_name);
          if (index > -1) {
            this.state.selectedCheckBox.splice(index, 1);
          }
        }
      }
    });

    this.setState({ selectedCheckBox: this.state.selectedCheckBox }, () => {
      isShiftKey && this.multiSelect();
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getSelectedContacts(true);
        }
      );
    }
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [];

    columns = data.map((item, index) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: (
              <input
                name={item.item_name}
                value={item.item_name}
                key={item.item_name}
                type="checkbox"
                checked={item.is_checked}
                style={{
                  WebkitAppearance: `checkbox`,
                  width: `20px`,
                }}
                onChange={() => {}}
                onClick={(event) => this.onCheckboxClick(event, item)}
              />
            ),
            opClassesPar: "task-checkbox",
          },
          {
            title: item.item_name ? item.item_name : "",
          },
        ],
        fullRowIsClickable: false,
        rowType: "",
        optionalRowClasses: "object-row-inner-padding",
      };
    });

    return {
      headings,
      columns,
    };
  };

  render() {
    let { isLoading, hasError, agingSummaryData } = this.state;
    return (
      <Modal
        title="Select Inventory for item filter"
        closeOnClickOutside={true}
        hideModal={this.handleSubmit}
        style={{ maxWidth: `35em`, paddingBottom: "1em" }}
        optionalClasses="scroll task-popup-sp"
        titleClasses="modal-header"
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            {/* {this.getTopComponent()}*/}
            {agingSummaryData && agingSummaryData.length > 0 && (
              <BottomScrollListener
                offset={50}
                onBottom={this.handleContainerOnBottom}
                debounce={0}
              >
                {(scrollRef) => (
                  <div>
                    <ObjectDebtList
                      data={this.getObjectListData(agingSummaryData)}
                      scrollRef={scrollRef}
                      removePadding={true}
                      showLoader={this.state.showLoader}
                    />
                  </div>
                )}
              </BottomScrollListener>
            )}
            {hasError ? (
              <div
                className="input-container error"
                style={{
                  marginBottom: "1.5em",
                  marginTop: "-1em",
                  display: "flex",
                }}
              >
                <div className="input-error-message" style={{ marginTop: 0 }}>
                  Please Select Item
                </div>
              </div>
            ) : null}
            <div className="modal-actions" style={{ marginBottom: "2rem" }}>
              <a
                className="cta mg-brand2-color"
                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                onClick={(event) => this.handleSubmit(event)}
              >
                Done
              </a>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(InventoryItemFilterModal);
