import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import SessionsModal from "../../components/Sessions/SessionsModal";
import Logo from "../../components/Logo";
import * as API from '../../utils/api';
class PasswordSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appThemeData: {},
      check: false,
    }
  }
  componentDidMount(){
    this.getLogoColorsForPayment();
  }

  getLogoColorsForPayment() {
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
          check: !(response.data.logo_url === undefined)
        });
      })
      .catch(() => {});
  }
  render() {
    return (
      <div className="sessions fill-screen mg-session" style={{backgroundColor: 'rgb(245,247,248'}}>
        <SessionsModal>
          <div className="sessions-modal-logo-container mg-logo-container">
           <Logo isDynamicLogo={true} logoUrl={this.state.appThemeData.logo_url}  /> 
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: this.state.appThemeData.primary_color }}>
              <span>Thank you!</span>
            </h1>
            <div>
              <p>Your password has been updated successfully!</p>
              <Link to="/login" className="cta " style={{ backgroundColor: this.state.appThemeData.primary_color }}>
                Continue to Login
              </Link>
            </div>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default PasswordSuccess;
