import React, { Component } from "react";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsSettingSidebar from "../../components/Clients/ClientsSettingSidebar";
import Tabs from "../../components/Tabs";
import * as API from "../../utils/api";
import GeneralSetting from "./GeneralSettings";
import QuickbookSetting from "./QuickbookSetting";
import MiscellaneousSettings from "./MiscellaneousSettings";
import TermsSettings from "./TermsSettings";
import ClientsSettingPayment from "./ClientsSettingPayment";
import ServiceSettings from "./ServiceSettings";
import Emitter from "../../utils/event-emitter";
import CustomizeWorkFlowStep9 from "./sso/CustomizeWorkFlowStep9";

class ClientsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //defalut active tab is Tasks
      //if we get "openQB" flag from location history then active tab is QuickBooks,
      //if we get "openPayment" flag from location history then active tab is Payments,
      activeTab:
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.openQB
          ? 2
          : this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.openPayment
          ? 3
          : 1,
      isQBConnected: false,
      lastSyncDate: "",
      isEditPref: false,
      disconnected: false,
      connectStripeData: [],
      goodsStockProps: {},
      consumerInformation: {
        name: {
          value: "",
        },
      },
      termsList: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.openQB
    ) {
      this.setState({ isEditPref: true });
    }
    this.getClientTermsList();
  }

  getClientTermsList = (reload, tabLoad) => {
    this.setState({ isLoading: true });
    if (tabLoad) this.setState({ tabLoader: true });

    API.getTermsListInSettings(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let newData = data.data;
          this.setState(
            (state) => ({
              ...state,
              termsList: newData,
              isLoading: false,
            }),
            () => this.setTermsSatat(this.state.termsList)
          );
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tabLoader: false,
          error: {
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  setTermsSatat = (termList) => {
    let { consumerInformation } = this.state;
    termList &&
      termList.map((item) => {
        if (item.is_default === true) {
          consumerInformation.name.value = item.name;
          this.setState({
            consumerInformation,
          });
        }
      });
  };

  //calls when tab click event triggers
  handleTabChange = (tabIndex) => {
    this.setState({
      activeTab: tabIndex,
      disconnected: false,
      isEditPref: false,
    });
  };

  //update stripe data
  updateStripeStatus = (data) => {
    this.setState({
      connectStripeData: data,
    });
  };

  //QB preferences screen
  viewpreferences = (flag) => {
    this.setState({ isEditPref: flag });
  };

  //update quickbooks screen sidebar data based on connection status
  updateQBInsideBar = (data) => {
    if (data.isConnectedToQB) {
      this.setState({ isQBConnected: data.isConnectedToQB });
    } else if (data.lastSyncDate) {
      this.setState({ lastSyncDate: data.lastSyncDate });
    }
  };

  //disconnect from quickbook
  disconnectQB = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message:
        "Disconnecting your QuickBooks Online account will stop synchronizations between your MakeGood account and QuickBooks Online account. Are you sure you want to disconnect your QuickBooks Online account now?",
      confirmClick: this.callDisconnectQB,
      closeOnClickOutside: true,
    });
  };

  callDisconnectQB = (event) => {
    API.disconnectQB(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        data.status_code == 200
      ) {
        this.props.user.qb_connect = false;
        this.props.updateModalState(true, "SUCCESS", {
          message:
            "You have successfully disconnected your QuickBooks Online account.",
        });
        this.setState({ isQBConnected: false, disconnected: true });
        Emitter.emit("IS_QUICKBOOK_CONNECTED", false);
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  //In Payments Tab this will call when user click on "Payments Dashboard" button to get redirection link
  paymentDashboardClick = () => {
    API.getStripeDashboardLink(this.props.user).then((data) => {
      if (data && data.data && data.status_code == 200) {
        //open redirection link in new tab
        window.open(data.data, "_blank");
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  //render UI for Tabbar
  getSectionHeaderUtilities() {
    const { activeTab } = this.state;
    const tabLabels = [
      "Tasks",
      "QuickBooks",
      "Payments",
      "Goods & Services",
      " Miscellaneous",
      "Credit Terms",
    ];
    return (
      <Tabs
        labels={tabLabels}
        activeTarget={activeTab}
        handleTabChange={this.handleTabChange}
        optionalInTabClasses='mg-tabs-tab'
        optionalClasses='consumer-pay-tab tp-sp con-fit large-tabs'
      />
    );
  }

  goodsData = (data) => {
    this.setState({ goodsStockProps: data });
  };

  render() {
    const { appName } = this.props;
    const {
      activeTab,
      isQBConnected,
      lastSyncDate,
      isEditPref,
      connectStripeData,
      consumerInformation,
    } = this.state;
    return (
      <div className='client-scroll-dashboard scrollbar'>
        <div className='app-sidebar-layout-canvas'>
          <ClientsSettingSidebar
            updateModalState={this.props.updateModalState}
            termsList={this.state.termsList}
            user={this.props.user}
            title={"Settings"}
            activeTab={activeTab}
            isQBConnected={isQBConnected}
            lastSyncDate={lastSyncDate}
            connectStripeData={connectStripeData}
            handleTabChange={this.handleTabChange}
            viewpreferences={this.viewpreferences}
            disconnectQB={this.disconnectQB}
            defaultValue={consumerInformation.name.value}
            paymentDashboardClick={this.paymentDashboardClick}
            isEditPref={isEditPref}
            utilities={this.getSectionHeaderUtilities()}
            optionalClassNames='client-setting-header'
            goodsStockProps={this.state.goodsStockProps}
          />
          <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll scrollbar'>
            <div className='hide-collapse-gradient'>
              <AppSectionHeader
                utilities={this.getSectionHeaderUtilities()}
                optionalClassNames='client-setting-header'
              />
            </div>
            {activeTab === 1 ? (
              <CustomizeWorkFlowStep9
                {...this.props}
                user={this.props.user}
                appName={appName}
                updateModalState={this.props.updateModalState}
              />
            ) : // <GeneralSetting
            //   {...this.props}
            //   user={this.props.user}
            //   appName={appName}
            //   updateModalState={this.props.updateModalState}
            // />
            activeTab === 2 ? (
              <QuickbookSetting
                {...this.props}
                user={this.props.user}
                appName={appName}
                updateQBInsideBar={this.updateQBInsideBar}
                updateModalState={this.props.updateModalState}
                isEditPref={isEditPref}
                viewpreferences={this.viewpreferences}
                disconnected={this.state.disconnected}
                updateUserLogin={this.props.updateUserLogin}
              />
            ) : activeTab === 3 ? (
              <ClientsSettingPayment
                {...this.props}
                user={this.props.user}
                appName={appName}
                connectStripeData={connectStripeData}
                updateStripeStatus={this.updateStripeStatus}
                stripePubKey={this.props.stripePubKey}
              />
            ) : activeTab === 5 ? (
              <MiscellaneousSettings
                {...this.props}
                user={this.props.user}
                appName={appName}
              />
            ) : activeTab === 6 ? (
              <TermsSettings
                {...this.props}
                user={this.props.user}
                appName={appName}
                fetchData={this.getClientTermsList}
              />
            ) : (
              <ServiceSettings
                {...this.props}
                user={this.props.user}
                appName={appName}
                goodsData={this.goodsData}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsSettings;
