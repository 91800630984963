import React, { Component } from "react";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerHelpForm from "../../components/Consumer/Forms/ConsumerHelpForm";
import FlashMessage from "../../components/FlashMessage";
// Utils
import * as API from "../../utils/api";
import { getZohoPagesense, showToast } from "../../utils/helpers";

class ConsumerHelp extends Component {
  static contextTypes = {
    router: () => true, // replace with PropTypes.object if you use them
  };
  state = {
    user: {},
    showFlash: false,
    flashMessage: "",
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    const { user } = this.props;

    API.getUser(user).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.setState((state) => ({
          user: data.user,
          isLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
    getZohoPagesense();
    // ReactGA.pageview("/ConsumerHelp");
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleHelpFormSubmit = (inputs) => {
    const { user } = this.state;
    let messageObjectForSubmit = {
      contact_form: {
        name: user.first_name + " " + user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        topic: inputs.inquiry,
        message: inputs.message,
        page: "consumer",
      },
    };
    API.consumerRequestHelp(user, messageObjectForSubmit).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Your message was sent successfully",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='consumer-help'>
        <Helmet>
          <title>{appName} | Help</title>
        </Helmet>
        <div
          className='app-sidebar-layout-canvas'
          style={{ backgroundColor: "#f5f7f8" }}
        >
          <div
            className='app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad'
            style={{ boxShadow: "none" }}
          >
            <ClientsLogo color={"#000"} />
            <div
              // to={`/dashboard/accounts`}
              onClick={this.props.history.goBack}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "#000000",
                textDecoration: "none",
              }}
            >
              « Back
            </div>
            <h1
              className='app-section-consumer-heading'
              style={{ marginBottom: "1em", color: "#000000" }}
            >
              Get Help
            </h1>
            {this.state.showFlash ? (
              <FlashMessage
                message={this.state.flashMessage}
                handleFlashDismiss={this.dismissFlash}
              />
            ) : null}
            <div className='scrollbar consumer-portal-app-header'>
              <ConsumerHelpForm handleSubmit={this.handleHelpFormSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsumerHelp);
