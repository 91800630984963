import React, { Component } from "react";
import { Link } from "react-router-dom";
import Panel from '../../components/App/Panel';

class ClientsEditSettingSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTitle = (user) => {
    let fullname = "";
    if (user.company_name) {
      fullname = user.company_name;
    } else if (user.display_name) {
      fullname = user.display_name;
    }
    return fullname;
  };

  renderContentForCreditTerms = () => {
    let {defaultValue} = this.props;
    return (
      <>
      <div className="side-wd">
        <Panel>
          <p className="qb-p">
          Use this page to change the default terms applied to an individual account. Just click the box under the "Default" column to select the term you want to apply as the default term.
          </p>
        </Panel>
      </div>
      <div className="mobile-view-default-term">
            <label htmlFor="accountNo" className="mobile-view-default-term">Default Term</label>
            <div className="invoice-amt-owed" style={{ fontSize: "18px",marginBottom: '10px' }}>
              {defaultValue}
            </div>
          </div>
      </>
    );
  };

  renderBillingDetails = () =>{
    const {  account } = this.props;

    return (
      <>
       {account && account.registration_token ? (
            <div style={{ display: "flex" }}>
              <label style={{ width: "auto" }}>Registration Token: </label>
              <h4 style={{ width: "auto", marginLeft: 6 }}>
                {account.registration_token}
              </h4>
            </div>
          ) : null}
          {account && account.email && account.email !== ''
          ? <div style={{ marginTop: "1.5em" }}>
            <div>{`${account.email}`}</div>
            <div style={{fontWeight: 'bold'}}>{`${account.verified ? '(Registered)' : '(Not Registered)'}`}</div>
          </div> : null}
          {account && account.registration_token ? (
            <div className="history-btn">
              <button
                style={{ marginLeft: 0, maxWidth: "14em" }}
                onClick={() => this.props.handleSendLink()}
              >
                Send Registration Link
              </button>
            </div>
          ) : null}
      </>
    )
  }


  render() {
    const { optionalClassNames, account,activeTab, backPath, backText } = this.props;
    const id = this.props.match.params.id;
    const title = this.getTitle(account);
    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
        <div
          className={`app-section-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div className="app-section-header-content">
            <Link to={backPath} className="app-section-header-back-link">
              « {backText}
            </Link>
            <h1
              className="app-section-header-heading side-hed-col"
              style={{ fontSize: "2em" }}
            >
              {title}
            </h1>
          </div>
        </div>
        <div className="invoice-sidebar" style={{ marginTop: "1.5em" }}>
         {
          activeTab == 1 ? this.renderBillingDetails()
          : this.renderContentForCreditTerms()
         }
           {/* {activeTab === 6 && (
          <div className="mobile-view-default-term">
            <label htmlFor="accountNo" className="mobile-view-default-term">Default Term</label>
            <div className="invoice-amt-owed" style={{ fontSize: "18px" }}>
              {defaultValue}
            </div>
          </div>
        )} */}
        </div>
      </div>
    );
  }
}

export default ClientsEditSettingSidebar;
