import React, { Component } from "react";
import Modal from "./Modal";
import * as API from "../../../utils/api";

class LearnMoremodal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      showLoader: false,
    };
  }

  componentDidMount() {}

  reverseCreditMemo = (item) => {};

  handleModalClose = () => {
    this.props.hideModal();
  };

  styles = {
    headings: {
      color: "#000000",
      fontFamily: "Open sans",
      fontSize: "18px",
      fontWeight: "700",
      marginBottom: "0.5rem",
      lineHeight: "initial",
    },
    subHeadings: {
      margin: "0px",
      color: "#0ab894",
      fontFamily: "Open sans",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "initial",
    },
    data: {
      margin: "0px",
      color: "#000000",
      fontFamily: "Open sans",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "initial",
    },
  };
  render() {
    return (
      <Modal
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses="scroll"
      >
        <div
          style={{
            marginTop: "1.5em",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <div>
              <h3 style={this.styles.headings}>
                Payment Processing Fees & Processing Times
              </h3>
            </div>
            <p style={this.styles.subHeadings}>
              {"Credit Card (Visa, Mastercard, American Express, Discover):"}
            </p>
            <ul style={{ paddingLeft: "20px",marginBottom: "20px" }}>
              <li style={this.styles.data}>
                FEES: 2.9% + .30 per successful charge; +1% for international
                cards; +1% if currency conversion required.
              </li>
              <li style={this.styles.data}>TIME: Immediate</li>
            </ul>
            <p style={this.styles.subHeadings}>
              {"ACH Direct Debit (US bank account):"}
            </p>
            <ul style={{ paddingLeft: "20px" }}>
              <li style={this.styles.data}>
                FEES: 0.8% - capped at $5.00; $4 for failed ACH direct debits;
                $15 for disputed ACH direct debits.
              </li>
              <li style={this.styles.data}>TIME: 4-5 days</li>
            </ul>
          </div>

          <div
          // style={}
          >
            <div>
              <h3 style={this.styles.headings}>Payouts</h3>
            </div>
            <p style={this.styles.subHeadings}>{"Standard Payouts:"}</p>
            <ul style={{ paddingLeft: "20px",marginBottom: "20px" }}>
              <li style={this.styles.data}>
                FEES: .25 cents +; .25% of payout volume
              </li>
              <li style={this.styles.data}>TIME: 2 business days</li>
            </ul>
            <p style={this.styles.subHeadings}>{"Instant Payouts:"}</p>
            <ul style={{ paddingLeft: "20px" }}>
              <li style={this.styles.data}>FEES: 1% of payout volume</li>
              <li style={this.styles.data}>TIME: Less than 30 minutes</li>
            </ul>
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <input
              type="submit"
              value={"Done"}
              className="cta mg-brand2-color"
              style={{
                float: "right",
                width: "fit-content",
                borderRadius: "0.5em",
                border: "2px solid",
                padding: "7px 10px",
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: "600",
              }}
              onClick={() => this.handleModalClose()}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default LearnMoremodal;
