import React, { Component } from "react";
// Packages
import onClickOutside from "react-onclickoutside";

class NotificationModal extends Component {
  handleClickOutside = () => {
    if (this.props.closeOnClickOutside) {
      this.props.closeOnClickOutside();
    }
  };

  render() {
    let { children } = this.props;
    return <div>{children}</div>;
  }
}

export default onClickOutside(NotificationModal);
