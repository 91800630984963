import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import { Helmet } from "react-helmet";
import BottomScrollListener from "react-bottom-scroll-listener";
// import ReactGA from 'react-ga';

// API
import * as API from "../../utils/api";
import filter_icon from "../../assets/icons/filter.svg";

// Components
import MenuItem from "../../components/MenuItem";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ObjectHistoryList from "../../components/ObjectList/ObjectHistoryList";

import { getZohoPagesense } from "../../utils/helpers";
import DropdownButton from "../../components/DropdownButton";
import FlashMessage from "../../components/FlashMessage";

class SkipTrace extends Component {
  constructor(props) {
    super();
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      savedData: [],
      hasMoreData: false,
      dropdownIsActive: false,
      isLoading: true,
      searchText: "",
      showFlash: false,
      offset: 0,
      input: {
        search: {
          value: "",
        },
      },
      filterText: "",
      sendFrom: "",
      sendTo: "",
      showLoader: false,
      isUploading: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { searchText, offset } = this.state;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    this.getSkipTraceData(true);
    getZohoPagesense();
    // ReactGA.pageview("/SkipTrace");
  }

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  getSkipTraceData = (reload) => {
    this.setState((state) => ({ ...state, showLoader: true }));
    const { user } = this.props;
    const { searchText, offset, filterText } = this.state;

    API.fetchSkipTraceData(user, offset).then((data) => {
      let savedData = reload ? this.state.savedData : false;

      if (
        (typeof data != "undefined" || data != null) &&
        !data.errors &&
        data.data
      ) {
        let newData = savedData ? savedData.concat(data.data) : data.data;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          showLoader: false,
          hasMoreData: data.data.length < 7 ? false : true,
          savedData: newData,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          isLoading: false,
          showLoader: false,
        }));
      }
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getSkipTraceData(true);
        }
      );
    }
  };

  onCheckboxClick(event) {
    let props = {
      rowDetail: event,
    };
    this.props.updateModalState(true, "SHOW_SKIP_TRACE_DETAIL", props);
  }

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "First Name",
      "Last Name",
      "SSN",
      "Email",
      "City",
      "State",
      "Zip",
    ];

    columns = data.map((row) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: `${row.first_name ? row.first_name : ""}`,
          },
          {
            title: `${row.last_name ? row.last_name : ""}`,
          },
          {
            title: `${row.ssn ? row.ssn : ""}`,
          },
          {
            title: `${row.email ? row.email : ""}`,
          },
          {
            title: `${row.city ? row.city : ""}`,
          },
          {
            title: `${row.state ? row.state : ""}`,
          },
          {
            title: `${row.zip ? row.zip : ""}`,
          },
        ],
        rowType: "detailView",
        rowDetail: `${row ? row : ""}`,
      };
    });
    return {
      headings,
      columns,
    };
  };

  uploadFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);

    this.setState(
      (state) => ({
        ...state,
        isUploading: true,
      }),
      () => {
        API.uploadSkipTraceCSV(this.props.user, formData).then((data) => {
          if ((typeof data != "undefined" || data != null) && !data.error) {
            if (data.message) {
              this.setState((state) => ({
                ...state,
                showFlash: true,
                flashMessage: data.message,
              }));
            }
            this.getSkipTraceData(false);
          } else {
            this.setState((state) => ({
              ...state,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.message && data.message == "Access denied."
                      ? 401
                      : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
      }
    );
  };

  handleUploadClick = () => {
    this.refs.fileUploader.click();
  };

  handleSearch = () => {
    let queryString = this.state.input.search.value;
    if (queryString) {
      API.clientSkipTraceUserSearch(
        this.props.user,
        queryString,
        this.state.offset
      ).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          let newData = data.data;
          this.setState((state) => ({
            ...state,
            isLoading: false,
            showLoader: false,
            savedData: newData,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      this.getSkipTraceData(false);
    }
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        }),
        () => {
          this.handleSearch();
        }
      );
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        input: {
          ...newPartialInput,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  getSectionHeaderUtilities() {
    const { input, dropdownIsActive } = this.state;
    let app_api_url = process.env.REACT_APP_API_URL;

    return (
      <div className='app-section-header-utilities'>
        <div className='admin-search-input-container'>
          <input
            type='text'
            name='search'
            placeholder='Search'
            value={input.search.value}
            className='admin-search-input'
            onKeyDown={this.handleKeyPress}
            onChange={(event) =>
              this.handleInputChange({ search: { value: event.target.value } })
            }
          />
          <span
            className='search-icon'
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
        <DropdownButton title='Import Options' primaryLink='/client/SkipTrace'>
          <MenuItem handleClick={this.handleUploadClick}>
            Upload Multiple Accounts (CSV)
          </MenuItem>
          <li>
            <a
              href={app_api_url + "/import-user-email-search-template.csv"}
              rel='noopener noreferrer'
              target='_blank'
            >
              Download CSV Template
            </a>
          </li>
        </DropdownButton>
        <input
          type='file'
          id='file'
          accept='.csv'
          ref='fileUploader'
          style={{ display: "none" }}
          onChange={(event) => this.uploadFile(event)}
        />
      </div>
    );
  }

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive,
    }));
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  render() {
    const { appName } = this.props;
    const { isLoading } = this.state;
    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div
        className='consumer-debt-detail-form'
        style={{ paddingTop: 0 }}
        onClick={(event) => {
          this.handleClickOutside(event);
        }}
      >
        <Helmet>
          <title>{appName} | Mails</title>
        </Helmet>
        {this.state.showFlash ? (
          <FlashMessage
            message={this.state.flashMessage}
            handleFlashDismiss={this.dismissFlash}
          />
        ) : null}
        <AppSectionHeader
          title='SkipTrace'
          utilities={this.getSectionHeaderUtilities()}
        />
        {this.state.savedData.length > 0 ? (
          <BottomScrollListener
            onBottom={this.handleContainerOnBottom}
            debounce={0}
          >
            {(scrollRef) => (
              <div>
                <ObjectHistoryList
                  optionalClasses={"object-debt-list"}
                  data={this.getObjectListData(this.state.savedData)}
                  rowCheckListHandler={this.onCheckboxClick}
                  scrollRef={scrollRef}
                />
                {this.state.showLoader && (
                  <div>
                    <AppCanvasLoader />
                  </div>
                )}
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className='admin-admins-empty-state'>
            {this.state.showLoader ? (
              <AppCanvasLoader />
            ) : (
              <h2> No user details available. </h2>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SkipTrace);
