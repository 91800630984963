import React, { Component } from "react";
import moment from "moment";
import TextareaAutosize from "react-autosize-textarea";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import FileBase64 from "react-file-base64";
import Panel from "../../App/Panel";

class NotesAndAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        document: {
          value: "",
          hasError: false,
        },
        fileExtension: "",
        fileSizeInMB: "",
      },
    };
  }

  //get files and its all related data
  getFiles(index, files) {
    let newInputState = { ...this.props.attachedDocuments[index].base64Doc };
    let imgExtension = files[0].type;
    let fileName = moment(new Date()).format("MMDDYYYY");
    let extensionFlag = false;
    if (!imgExtension) {
      imgExtension = files[0].name.split(".").pop();
    }
    let extensionFromName = files[0].name.split(".").pop();
    if (
      extensionFromName &&
      (extensionFromName.toLowerCase() === "xls" ||
        extensionFromName.toLowerCase() === "xlsx" ||
        extensionFromName.toLowerCase() === "doc" ||
        extensionFromName.toLowerCase() === "docx")
    ) {
      extensionFlag = true;
    }
    if (
      imgExtension.toLowerCase() === "xls" ||
      imgExtension.toLowerCase() === "xlsx" ||
      imgExtension.toLowerCase() === "doc" ||
      imgExtension.toLowerCase() === "docx" ||
      imgExtension.toLowerCase() === "image/png" ||
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg" ||
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf"
    ) {
      extensionFlag = true;
    }
    if (files[0].file && files[0].file.size > 10000000) {
      extensionFlag = false;
    } else {
      let file_size =
        files[0].file && files[0].file.size
          ? files[0].file.size / (1024 * 1024)
          : 0;
      newInputState.fileSizeInMB = parseFloat(file_size).toFixed(2);
    }
    if (imgExtension.toLowerCase() === "image/png") {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".png"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg"
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".jpeg"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf" ||
      imgExtension.toLowerCase() === "application/jpeg"
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".pdf"
      }`;
    } else if (
      imgExtension.toLowerCase() === "xls" ||
      (extensionFromName && extensionFromName.toLowerCase() === "xls")
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".xls"
      }`;
    } else if (
      imgExtension.toLowerCase() === "xlsx" ||
      (extensionFromName && extensionFromName.toLowerCase() === "xlsx")
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".xlsx"
      }`;
    } else if (
      imgExtension.toLowerCase() === "doc" ||
      (extensionFromName && extensionFromName.toLowerCase() === "doc")
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".doc"
      }`;
    } else if (
      imgExtension.toLowerCase() === "docx" ||
      (extensionFromName && extensionFromName.toLowerCase() === "docx")
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".docx"
      }`;
    }
    if (extensionFlag) {
      newInputState.document.value = files[0].base64;
    } else {
      newInputState.document.hasError = true;
      this.props.setBase64Doc(newInputState, index);
    }
    this.props.setBase64Doc(newInputState, index);
  }

  renderErrorMessage(input) {
    let message = "";
    if (input === "user") {
      message = "Please select user";
    } else if (input === "name") {
      message = "Please enter name";
    } else if (input === "description") {
      message = "Please enter description";
    } else if (input === "docType") {
      message = "Please select document type";
    } else if (input === "note") {
      message = "Please enter note";
    } else if (input === "date") {
      message = "Please enter valid date";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  renderDocuments = (document, index) => {
    let { docTypeList, attachedDocuments } = this.props;
    return (
      <Panel>
        {attachedDocuments.length > 1 && (
          <div
            className='close'
            onClick={() => this.props.handleCrossClick("attachments", index)}
          />
        )}
        <div className='main-container'>
          <div className='half'>
            <div
              className={`input-container invoice-fields ${
                document.docName.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='attachment'>Name</label>
              <input
                name='docName'
                type='text'
                value={document.docName.value}
                placeholder='Enter Document Name'
                onChange={(e) => this.props.handleDocumentChange(e, "", index)}
              />
              {document.docName.hasError && this.renderErrorMessage("name")}
            </div>
            <div
              className={`input-container invoice-fields ${
                document.description.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='description'>Description</label>
              <input
                name='description'
                type='text'
                value={document.description.value}
                placeholder='Describe Document here'
                onChange={(e) => this.props.handleDocumentChange(e, "", index)}
              />
              {document.description.hasError &&
                this.renderErrorMessage("description")}
            </div>
          </div>
          <div className='half'>
            <div
              className={`input-container term-select ${
                document.docType.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='doctype'>Document Type</label>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className='form-select'
                name='docType'
                placeholder='Select Document Type'
                options={docTypeList}
                value={document.docType.value}
                onChange={(e) => {
                  if (e) {
                    this.props.handleDocumentChange(
                      { target: { value: e.name, name: "docType" } },
                      e,
                      index
                    );
                  }
                }}
              />
              {document.docType.hasError && this.renderErrorMessage("docType")}
            </div>
            <div
              className='admin-form-row address-document'
              style={{ marginTop: "1em" }}
            >
              <div
                className={`input-container error file-text`}
                style={{ marginBottom: "0.5em" }}
              >
                <FileBase64
                  multiple={true}
                  onDone={this.getFiles.bind(this, index)}
                />
                <div
                  className=' input-error-message'
                  style={{ color: "darkgrey" }}
                >
                  Image upload format (.png, .jpg, .jpeg, .pdf, .doc, .docx,
                  .xls, .xlsx) <br /> Size must be less than 10 MB
                </div>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    );
  };

  renderNotes = (note, index) => {
    let { userList, notesAndAttchments } = this.props;

    return (
      <Panel>
        {notesAndAttchments.length > 1 && (
          <div
            className='close'
            onClick={() => this.props.handleCrossClick("notes", index)}
          />
        )}
        <div className='main-container'>
          <div
            className={`input-container invoice-fields ${
              note.note.hasError ? " error" : ""
            }`}
          >
            <label htmlFor='hours'>Note</label>
            <TextareaAutosize
              name='note'
              rows={2}
              maxRows={4}
              type='textarea'
              placeholder='Enter note here...'
              value={note.note.value}
              onChange={(e) => this.props.handleNotesChange(e, index)}
            />
            {note.note.hasError && this.renderErrorMessage("note")}
          </div>
        </div>
      </Panel>
    );
  };

  render() {
    let { notesAndAttchments, attachedDocuments } = this.props;

    return (
      <div className='add-invoice-inner-panel'>
        {this.props.headerContent("Notes & Attachments")}
        {notesAndAttchments && notesAndAttchments.length
          ? notesAndAttchments.map((note, index) => {
              return this.renderNotes(note, index);
            })
          : null}
        {this.props.innerButtons(
          <button
            onClick={this.props.handleAddNotesClick}
            className='inner-btn mg-brand2-color'
          >
            Add More Notes
          </button>
        )}
        {attachedDocuments && attachedDocuments.length
          ? attachedDocuments.map((document, index) => {
              return this.renderDocuments(document, index);
            })
          : null}
        {this.props.innerButtons(
          <button
            onClick={this.props.handleAddDocumentClick}
            className='inner-btn mg-brand2-color'
          >
            Add More Documents
          </button>
        )}
      </div>
    );
  }
}

export default NotesAndAttachments;
