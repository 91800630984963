import React, { useState, useRef, useCallback } from "react";
import styles from "../../assets/styles/screens/client/importData.module.scss";
import { ReactComponent as UploadIcon } from "../../assets/icons/UploadIcon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/ExcelIcon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/CrossIcon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/ErrorIcon.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/WarningIcon.svg";
import * as API from "../../utils/api";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { useImport } from "../../Context/ImportContext";

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    fontSize: "12px",
    fontWeight: "semibold",
  },
})(Tooltip);

const docsLink = {
  accountCSV: process.env.REACT_APP_AWS_ACCOUNT_SAMPLE_CSV,
  accountGuide: process.env.REACT_APP_AWS_ACCOUNTS_GUIDE_URL,
  invoiceCSV: process.env.REACT_APP_AWS_INVOICE_SAMPLE_CSV,
  invoiceGuide: process.env.REACT_APP_AWS_INVOICE_GUIDE_URL,
};

const infoTitle =
  "Selecting the checkbox will create new customers if they do not already exist in the system.";

const ImportInvoiceData = (props) => {
  const { type } = props;
  const [isFileUploaded, setIsFileUploaded] = useState({
    uploaded: false,
    hasError: false,
    hasWarning: false,
  });
  const [fileInfo, setFileInfo] = useState({});
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [warnings, setWarnings] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [isAddNewCustomers, setIsAddNewCustomers] = useState(false);
  const inputRef = useRef(null);
  const { setImportStarted, setIsPolling, setImportType } = useImport();

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };
  const onButtonClick = () => {
    inputRef.current.click();
  };

  function handleFile(files) {
    const file = files[0];
    const fileName = file.name;
    const validExtensions = ["csv", "xls", "xlsx"];
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (validExtensions.includes(fileExtension)) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("is_accounts", type === "account");
      setFileInfo(file);
      setIsFileUploaded({ ...isFileUploaded, uploaded: true });
      setIsloading(true);
      API.validateFileData(props.user, formData)
        .then((res) => {
          if (res && res.status_code === 400) {
            if (res.errors[0].records_limit_exceeded > 1000) {
              props.updateModalState(true, "ERROR", {
                message:
                  "Spreadsheet data validation failed due to exceeded records limit.",
              });
              setIsFileUploaded({
                uploaded: true,
                hasError: true,
                hasWarning: false,
              });
            } else {
              setError(res.errors);
              if (res.warnings) {
                setWarnings(res.warnings);
                setIsFileUploaded({
                  uploaded: true,
                  hasError: true,
                  hasWarning: true,
                });
              } else {
                setIsFileUploaded({
                  uploaded: true,
                  hasError: true,
                  hasWarning: false,
                });
              }
              props.updateModalState(true, "ERROR", {
                message: res.message ? res.message : res.error,
              });
            }
          } else {
            if (res.warnings) {
              setWarnings(res.warnings);
              setIsFileUploaded({
                uploaded: true,
                hasError: false,
                hasWarning: true,
              });
            } else {
              setIsFileUploaded({
                uploaded: true,
                hasError: false,
                hasWarning: false,
              });
            }
            props.updateModalState(true, "SUCCESS", {
              message: res.message ? res.message : res.error,
            });
          }
          setIsloading(false);
        })
        .catch(() => {
          setIsloading(false);
        });
    } else {
      props.updateModalState(true, "ERROR", {
        message:
          "Invalid file type. Only CSV, XLS, and XLSX files are allowed.",
      });
    }
  }

  function importAccountsData(file) {
    const formData = new FormData();
    formData.append("file", file);
    setIsloading(true);
    setImportType("account");
    setWarnings(null);

    API.importAccountData(props.user, formData)
      .then((res) => {
        if (res && res.status_code === 200) {
          setImportStarted(true);
          setIsPolling(true);
          props.history.goBack();
          props.updateModalState(true, "SUCCESS", {
            message: res.message ? res.message : res.error,
          });
        } else {
          if (res.errors) {
            setError(res.errors);
            setIsFileUploaded({
              uploaded: true,
              hasError: true,
              hasWarning: false,
            });
          }
          props.updateModalState(true, "ERROR", {
            message: res.message ? res.message : res.error,
          });
        }
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  }

  function importInvoicesData(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("create_customer", isAddNewCustomers);
    setIsloading(true);
    setWarnings(null);
    setImportType("invoice");

    API.importInvoiceData(props.user, formData)
      .then((res) => {
        if (res && res.status_code === 200) {
          props.history.goBack();
          setIsPolling(true);
          props.updateModalState(true, "SUCCESS", {
            message: res.message ? res.message : res.error,
          });
        } else {
          if (res.errors) {
            setError(res.errors);
            setIsFileUploaded({
              uploaded: true,
              hasError: true,
              hasWarning: false,
            });
          }
          props.updateModalState(true, "ERROR", {
            message: res.message ? res.message : res.error,
          });
        }
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  }

  return (
    <div className={styles["import-invoice-data"]}>
      <div
        className={styles["back-to-accounts"]}
        onClick={() => props.history.goBack()}
      >
        {type === "account" ? "<< Back to Accounts" : "<< Back to Invoices"}
      </div>
      <div className={styles["content"]}>
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div className={styles["upload-container"]}>
            <div className={styles["title"]}>
              <div className={styles["title-text"]}>
                {type === "account"
                  ? "Importing accounts"
                  : "Importing invoices"}
              </div>
              {isFileUploaded.uploaded && (
                <div
                  className={
                    !isFileUploaded.hasError
                      ? styles["button"]
                      : isAddNewCustomers
                      ? styles["button"]
                      : styles["disabled-button"]
                  }
                  onClick={() =>
                    type === "account"
                      ? importAccountsData(fileInfo)
                      : importInvoicesData(fileInfo)
                  }
                >
                  {"Confirm Import"}
                </div>
              )}
            </div>
            {!isFileUploaded.uploaded && (
              <UploadSection
                fileInfo={fileInfo}
                handleDrag={handleDrag}
                inputRef={inputRef}
                handleChange={handleChange}
                onButtonClick={onButtonClick}
                dragActive={dragActive}
                handleDrop={handleDrop}
                type={type}
              />
            )}
            {isFileUploaded.uploaded && (
              <div style={{ height: "55vh" }}>
                <FileSection
                  isFileUploaded={isFileUploaded}
                  fileInfo={fileInfo}
                  setFileInfo={setFileInfo}
                  setError={setError}
                  error={error}
                  warnings={warnings}
                  setWarnings={setWarnings}
                  setIsFileUploaded={setIsFileUploaded}
                  type={type}
                />
                {type !== "account" && (
                  <div className={styles["checkbox-container"]}>
                    <div>
                      <input
                        className={styles["checkbox"]}
                        style={{ WebkitAppearance: "checkbox" }}
                        type='checkbox'
                        checked={isAddNewCustomers}
                        onChange={() =>
                          setIsAddNewCustomers(!isAddNewCustomers)
                        }
                      />
                    </div>
                    <div className={styles["normal-text"]}>
                      {"Add New Customers to MakeGood."}
                    </div>
                    <div>
                      <BlueOnGreenTooltip title={infoTitle}>
                        <InfoIcon className={styles["info-icon"]} />
                      </BlueOnGreenTooltip>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isFileUploaded.uploaded && <ImportInstructions type={type} />}
          </div>
        )}
      </div>
    </div>
  );
};

const ImportInstructions = (props) => {
  const { type } = props;
  const uploadInstructions = [
    <div className={styles["instructions-link"]}>
      {"Start with a template"}
      <div
        className={styles["import-guide"]}
        onClick={() => {
          window.open(
            type === "account" ? docsLink.accountCSV : docsLink.invoiceCSV,
            "_blank"
          );
        }}
      >
        {"Download sample file"}
      </div>
    </div>,
    "Before you upload, add new products and services to MakeGood",
    "Sequence of columns should be maintained as per sample file template.",
    "Your file can't have more than 100 rows, but you can upload multiple files.",
    `File supported for importing ${
      type === "account" ? "account data" : "invoice data"
    } is CSV, XLSX, or XLS.`,
  ];
  return (
    <div className={styles["instructions"]}>
      <div className={styles["title-bold"]}>
        {type === "account"
          ? `First Time Importing Accounts?`
          : `First Time Importing Invoices?`}
      </div>
      <div className={styles["instructions-link"]}>
        {"Learn how to set up your import file"}
        <p
          className={styles["import-guide"]}
          onClick={() => {
            window.open(
              type === "account"
                ? docsLink.accountGuide
                : docsLink.invoiceGuide,
              "_blank"
            );
          }}
        >
          {"Download Import Guide"}
        </p>
      </div>
      <div>
        <ul>
          {uploadInstructions.map((item, index) => (
            <li key={index}>
              <span>•</span>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const UploadSection = ({
  handleDrag,
  inputRef,
  handleChange,
  onButtonClick,
  handleDrop,
  dragActive,
}) => {
  const { isPolling } = useImport();
  return (
    <div
      className={styles["upload-box"]}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type='file'
        style={{ display: "none" }}
        // multiple={true}
        onChange={handleChange}
      />
      <UploadIcon className={styles["upload-icon"]} />
      <div className={styles["upload-text"]}>{"Drag & Drop files or"}</div>
      <div
        className={isPolling ? styles["disabled-button"] : styles["button"]}
        onClick={onButtonClick}
      >
        {"Browse"}
      </div>
      {dragActive && (
        <div
          id='drag-file-element'
          className={styles["drag"]}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
};

const FileSection = (props) => {
  const {
    isFileUploaded,
    fileInfo,
    setFileInfo,
    setIsFileUploaded,
    error,
    setError,
    type,
    warnings,
    setWarnings,
  } = props;
  const accountErrorDescription = [
    {
      code: "duplicate_email",
      description:
        "The email address provided is already in use. Please choose a unique email address.",
    },
    {
      code: "duplicate_display_name",
      description:
        "The display name specified is already assigned to another entry. Please choose a unique display name.",
    },
    {
      code: "display_name_already_exists",
      description:
        "The display name you have chosen already exists. Please select a different display name.",
    },
    {
      code: "email_already_exists",
      description:
        "The email address you have entered is already associated with an existing account. Please use a different email address.",
    },
    {
      code: "missing_name_columns",
      description:
        "One or more required name columns are missing. Please ensure all required fields are filled in given rows.",
    },
    {
      code: "invalid_state",
      description:
        "The state provided is invalid. Please provide a valid state.",
    },
    {
      code: "records_limit_exceeded",
      description: `The number of records exceeds the allowed limit. The spreadsheet contains 1000 records, which is more than the permissible limit.`,
    },
    {
      code: "missing_headers",
      description:
        "The spreadsheet is missing one or more required headers. Please ensure all required headers are included.",
    },
    {
      code: "extra_headers",
      description:
        "The spreadsheet contains extra headers that are not required. Please remove the unnecessary headers.",
    },
    {
      code: "invalid_header_sequence",
      description:
        "The headers in the spreadsheet are not in the correct sequence. Please refer to the sample file for proper order.",
    },
    {
      code: "invalid_zip",
      description:
        "The zip format is not valid. Please refer to the sample file for proper order.",
    },
    {
      code: "invalid_phone",
      description:
        "The phone number is not valid. Please refer to the sample file for proper order.",
    },
    {
      code: "invalid_email",
      description:
        "The email is not valid. Please refer to the sample file for proper order.",
    },
  ];

  const invoiceErrorDescription = [
    {
      code: "default_service_errors",
      description:
        "The default service for this organization is not available. Please create one to proceed.",
    },
    {
      code: "invalid_item_date_errors",
      description:
        "Item date is invalid or missing. Proper date format should be MM-DD-YYYY or MM/DD/YYYY.",
    },
    {
      code: "duplicate_invoice_numbers",
      description: "There are duplicate invoice numbers.",
    },
    {
      code: "missing_items",
      description: "Some items are missing in the system, please check.",
    },
    {
      code: "missing_customer_warning",
      description:
        "Some customers are missing.Please select the checkbox given below to add them.",
    },
    {
      code: "mandatory_columns_error",
      description:
        "Mandatory columns are missing. Please refer to the sample CSV for proper format.",
    },
    {
      code: "invalid_invoice_date_errors",
      description:
        "Invoice date is invalid or missing. Proper date format should be MM-DD-YYYY or MM/DD/YYYY.",
    },
    {
      code: "invalid_due_date_errors",
      description:
        "Due date is invalid or missing. Proper date format should be MM-DD-YYYY or MM/DD/YYYY.",
    },
    {
      code: "invalid_tax_rate_errors",
      description: "Tax rate is invalid. It should lie between 0-100",
    },
    {
      code: "invalid_taxable_errors",
      description: "Taxable value is invalid. It should be either Y or N.",
    },
    {
      code: "invalid_quantity_errors",
      description: "Quantity is invalid. It should be a positive number.",
    },
    {
      code: "invalid_rate_errors",
      description: "Rate is invalid. It should be a positive number.",
    },
    {
      code: "invalid_amount_errors",
      description: "Amount is invalid. It should be a positive number.",
    },
    {
      code: "invalid_communication_errors",
      description:
        "Communication value is invalid. It should be either Y or N.",
    },
    {
      code: "invalid_header_sequence",
      description:
        "The headers in the spreadsheet are not in the correct sequence. Please refer to the sample file for proper order.",
    },
  ];

  const uploadFileErrors =
    error &&
    error.flatMap((obj1) => {
      const key = Object.keys(obj1)[0];
      const match =
        type === "account"
          ? accountErrorDescription.find((obj2) => obj2.code === key)
          : invoiceErrorDescription.find((obj2) => obj2.code === key);
      if (match) {
        return {
          error: key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          errorDescription: match.description,
          errorData: key === "invalid_header_sequence" ? [] : obj1[key],
        };
      }
      return [];
    });

  const uploadFileWarnings =
    warnings &&
    warnings.flatMap((obj1) => {
      const key = Object.keys(obj1)[0];
      const match =
        type === "account"
          ? accountErrorDescription.find((obj2) => obj2.code === key)
          : invoiceErrorDescription.find((obj2) => obj2.code === key);
      if (match) {
        return {
          error: key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          errorDescription: match.description,
          errorData: obj1[key],
        };
      }
      return [];
    });

  return (
    <div className={styles["instructions"]}>
      <div className={styles["upload-text"]}>
        <div>
          <div className={styles["title-bold"]}>
            {isFileUploaded.hasError
              ? "Invalid File Uploaded"
              : "Uploaded Files"}
          </div>
          {isFileUploaded.hasError && (
            <div className={styles["normal-text"]} style={{ width: "85%" }}>
              {
                "We can't import some of your invoices. Fix the errors and give it another go."
              }
            </div>
          )}
        </div>
        {isFileUploaded.hasError && (
          <div
            className={styles["uploadbtn"]}
            onClick={() => {
              setFileInfo({});
              setError([]);
              setWarnings([]);
              setIsFileUploaded({
                uploaded: false,
                hasError: false,
                hasWarning: false,
              });
            }}
          >
            Upload New File
          </div>
        )}
      </div>
      <div
        style={{
          border: isFileUploaded.hasError && "1px solid red",
        }}
        className={styles["fileSection"]}
      >
        <div className={styles["file-info-container"]}>
          <div>
            <ExcelIcon />
          </div>
          <div className={styles["file-name"]}>
            <div className={styles["title-bold"]}>
              {fileInfo ? fileInfo.name : ""}
            </div>
            <div className={styles["normal-text"]}>{`${
              fileInfo ? (fileInfo.size / 1024).toFixed(2) : 0
            } MB`}</div>
          </div>
        </div>
        <div>
          <CrossIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsFileUploaded({
                uploaded: false,
                hasError: false,
                hasWarning: false,
              });
              setFileInfo({});
            }}
          />
        </div>
      </div>
      {isFileUploaded.hasWarning &&
        uploadFileWarnings &&
        uploadFileWarnings.map((error) => (
          <ErrorSection data={error} type={type} warnings={warnings} />
        ))}
      {isFileUploaded.hasError &&
        uploadFileErrors &&
        uploadFileErrors.map((error) => (
          <ErrorSection data={error} type={type} />
        ))}
    </div>
  );
};

const ErrorSection = ({ data, type, warnings }) => {
  return (
    <div className={styles["errorSection"]}>
      <div className={styles["header"]}>
        <div className={styles["icon"]}>
          {warnings ? <WarningIcon /> : <ErrorIcon />}
        </div>
        <div className={warnings ? styles["warning"] : styles["error"]}>
          {data.error}
        </div>
      </div>
      <div className={styles["description"]}>{data.errorDescription}</div>
      <ul className={styles["errorList"]}>
        {data.errorData.map((item, index) => {
          if (typeof item === "string" || typeof item === "number") {
            return <li key={index}>{item}</li>;
          } else {
            return (
              <li key={index}>{`${item.value ? item.value : ""} at row ${
                item.index
              }`}</li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default ImportInvoiceData;
