import React, { Component } from "react";
import Modal from "../Modal";
class DefaultTermModal
    extends Component {
    hideToast = () => {
        this.props.hideModal();
    };
    render() {
        let { message, title } = this.props.optionalProps;

        return (
            <>
                (
                <Modal
                    style={{ height: "50%", width: "50%", zIndex:5 }}
                    // title={"Failed!"}
                    hideModal={this.props.hideModal}
                    closeOnClickOutside={true}
                >
                    <p style={{ textAlign: "center", marginTop: "70px", color: "black", fontSize: "18px" }}>{message}</p>
                    <div className="modal-actions">
                        <a className="cta mg-brand2-color"
                            style={{
                                borderRadius: "0.5em",
                                width: "140px"
                            }}
                            onClick={this.props.optionalProps.handleSubmit ? this.props.optionalProps.handleSubmit : this.hideToast}>
                            Okay
                        </a>
                    </div>
                </Modal>
                )
            </>
        );
    }
}

export default DefaultTermModal
    ;