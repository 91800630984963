import React, { Component } from 'react';

// Components
import Modal from '../Modal';


class OfferReviewModal extends Component {

  handleActionSubmit = (event) => {
    let clickedBehavior = event.target.getAttribute('data-behavior');

    if (clickedBehavior === 'offer-accept') {
      this.props.updateModalState(true, 'OFFER_ACCEPTED');
    } else if (clickedBehavior === 'offer-counter') {
      this.props.updateModalState(true, 'COUNTEROFFER');
    } else if (clickedBehavior === 'offer-reject') {
      this.props.updateModalState(true, 'OFFER_REJECTED');
    }
  }

  render() {

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="offer-review"
        title="Michael submitted an offer:"
      >
        <div className="modal-body">
          <div className="offer-review-row">
            <div className="offer-review-row-column label">
              Total Amount:
            </div>
            <div className="offer-review-row-column data">
              $500
            </div>
          </div>
          <div className="offer-review-row">
            <div className="offer-review-row-column label">
              Payment Type:
            </div>
            <div className="offer-review-row-column data">
              Payment Plan
            </div>
          </div>
          <div className="offer-review-row">
            <div className="offer-review-row-column label">
              Number of Payments
            </div>
            <div className="offer-review-row-column data">
              5
            </div>
          </div>
          <div className="offer-review-row">
            <div className="offer-review-row-column label">
              Start Date
            </div>
            <div className="offer-review-row-column data">
              08/27/2017
            </div>
          </div>
        </div>
        <div className="modal-actions">
          <a
            className="cta-tertiary"
            data-behavior="offer-accept"
            onClick={(event) => this.handleActionSubmit(event)}
          >
            Accept
          </a>
          <a
            className="cta-secondary"
            data-behavior="offer-reject"
            onClick={(event) => this.handleActionSubmit(event)}
          >
            Reject
          </a>
          <a
            className="cta"
            data-behavior="offer-counter"
            onClick={(event) => this.handleActionSubmit(event)}
          >
            Counter
          </a>
        </div>
      </Modal>
    )
  }
}

export default OfferReviewModal;