import React, { Component } from 'react';

// Components
import Modal from '../Modal';
import AppCanvasLoader from '../../AppCanvasLoader';

class ShowSkipTraceDetailModal extends Component {
  state = {
    image: '',
    showLoader: true
  }

  componentDidMount() {
    let image = this.props.optionalProps.image;
    this.setState(state => ({
      ...state,
      image: image
    }))

    setTimeout(() => {
      this.setState({
        showLoader: false
      })
    }, 1000);
  }

  handleModalClose = () => {
    this.props.hideModal();
  }


  render() {
    let { showLoader, image } = this.state;

    return (
      <Modal
        title="User Detail"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses="add-account-modal send-new-mail-title mail-view-detail"
      >
        <form>
          <div className="scrollbar" style={{ height: window.innerHeight * 0.7, overflow: 'auto', paddingBottom: 50 }}>
            <div className="object-list-empty-state img-align">
                <span></span>
                <span></span>       
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default ShowSkipTraceDetailModal;
