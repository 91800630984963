import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

// Components
import ObjectRow from './ObjectRow';
import ObjectListHeader from './ObjectListHeader';

class ObjectList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    appName: PropTypes.string
  }

  renderOptionalColumnHeadings(headings, fromConsumerAccount, hideHeader) {
    if (headings && headings.length > 0) {
      return (
        <ObjectListHeader
          headings={headings}
          fromConsumerAccount={fromConsumerAccount}
          hideHeader={hideHeader}
        />
      )
    }
  }

  render() {
    let { data, rowActionClick, optionalClasses, hideHeader, removeExtraSpace, fromOfferHistory, hideForMobile, fromConsumerAccount } = this.props;
    return (
      <div className={`table-wrap-cls${hideForMobile ? ' mobile-hide-list' : ''}`}>
        <div className={`object-list${optionalClasses ? ' ' + optionalClasses : ''}`}>
          {this.renderOptionalColumnHeadings(data.headings, fromConsumerAccount, hideHeader)}
          {
            <div className={`${removeExtraSpace ? '' : 'object-list-scroll-for-big-device'}`} style={removeExtraSpace ? {} : { height: window.innerHeight * 0.6, paddingBottom: 100}}>{
              data.columns.map((row) => {
                return (
                  <ObjectRow
                    key={shortid.generate()}
                    rowData={row}
                    appName={this.props.appName}
                    handleRowActionClick={(event) => rowActionClick(event)}
                    fromOfferHistory={fromOfferHistory}
                    fromConsumerAccount={fromConsumerAccount}
                  />
                )
              })
            }
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ObjectList;
