import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import AppCanvasLoader from "../../AppCanvasLoader";
import Modal from "../Modal";

class PreviewTaskTemplateModal extends Component {
  state = {
    consumerData: {},
    mergeVaribales: [],
    mergeVaribalesValues: {},
    checkArray: [],
    mergeVaribalesValuesError: false,
    isLoading: true,
    isPreview: false,
    message: {
      value: "",
      hasError: false,
    },
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    let { optionalProps } = this.props;
    let { orgId, slugName, user } = optionalProps;
    this.setState({ consumerData: user }, () => {
      this.getMergeVariables(orgId, slugName);
    });
  }

  getMergeVariables(orgId, slugName) {
    this.setState({ isLoading: true });
    API.getTemplateMergeVar(this.props.user, orgId, slugName).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data && data.data) {
            this.setState({ mergeVaribales: data.data }, () => {
              this.autoFillMergeVariablies();
            });
          }
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  autoFillMergeVariablies() {
    if (this.state.mergeVaribales && this.state.mergeVaribales.length) {
      this.state.mergeVaribales.map((item) => {
        if (item === "FNAME") {
          this.handleMergeVariableValue(
            this.state.consumerData.first_name,
            item
          );
        } else if (item === "CUSTOM_URL") {
          let url =
            window && window.location && window.location.origin
              ? window.location.origin
              : "";
          this.handleMergeVariableValue(url, item);
        }
      });
    }
  }

  handlePreviewClick = () => {
    let { optionalProps } = this.props;
    let { orgId, slugName } = optionalProps;
    if (this.validateForm()) {
      this.setState({ isLoading: true, isPreview: true });
      let payload = {
        merge_variables: this.state.mergeVaribalesValues,
        organization_id: orgId,
        template: slugName,
      };
      API.previewTaskTemplate(this.props.user, payload).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.data) {
            let getView = document.getElementById("template");
            if (getView) {
              getView.innerHTML = data.data;
            }
          }
          this.setState({ isLoading: false });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  validateForm = () => {
    let errorsArePresent = false;
    let check =
      this.state.checkArray.length === this.state.mergeVaribales.length;

    if (!this.state.mergeVaribalesValues || !check) {
      this.setState({ mergeVaribalesValuesError: true });
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage() {
    return (
      <div className="input-error-message">Please complete this fields</div>
    );
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  handleMergeVariableValue(value, item) {
    let data = {};
    data[item] = value;
    let index = -1;
    index = this.state.checkArray.findIndex((o) => Object.keys(o)[0] === item);

    if (index === -1) {
      this.state.checkArray.push(data);
    }
    if (value === "" || value === null || value === undefined) {
      this.state.checkArray.splice(index, 1);
    }

    this.state.mergeVaribalesValues[item] = value;

    if (!this.state.mergeVaribalesValues[item]) {
      this.setState({ mergeVaribalesValuesError: true });
    } else {
      this.setState({
        mergeVaribalesValuesError: false,
      });
    }

    this.setState({
      mergeVaribalesValues: this.state.mergeVaribalesValues,
    });
  }

  renderMergeVaribles(item, index) {
    let { mergeVaribalesValues, mergeVaribalesValuesError } = this.state;
    return (
      <div className="admin-form-row" key={item}>
        <div className={`input-container`}>
          <label htmlFor="name">{item}</label>
          <input
            name={item}
            placeholder={"Enter " + item}
            type="text"
            value={mergeVaribalesValues[item]}
            onChange={(event) =>
              this.handleMergeVariableValue(event.target.value, item)
            }
          />
        </div>
      </div>
    );
  }

  hidePreviewModal = () => {
    this.setState({ isPreview: false });
  };

  render() {
    let {
      error,
      isLoading,
      isPreview,
      mergeVaribales,
      mergeVaribalesValuesError,
    } = this.state;
    let styels = { marginRight: `10px`, maxWidth: `30rem`, marginBottom: 0 };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isPreview) {
      return (
        <Modal
          optionalClasses="add-account-modal preview-modal scroll"
          title="Preview Template"
          closeOnClickOutside={true}
          hideModal={this.hidePreviewModal}
        >
          {isLoading && <AppCanvasLoader />}
          <div id="template"></div>
        </Modal>
      );
    }

    return (
      <Modal
        optionalClasses="add-account-modal send-new-email-modal scroll"
        title="Preview Task Template"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form className="admin-form">
            <div className="app-sidebar-layout-canvas">
              <div className="app-sidebar app-sidebar-left" style={styels}>
                <div>
                  <div>
                    <div>
                      <label>Merge Variables:</label>
                      {mergeVaribalesValuesError ? (
                        <div
                          className={`input-container half error`}
                          style={{ marginBottom: 10 }}
                        >
                          {this.renderErrorMessage("mergeVaribalesValuesError")}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {mergeVaribales && mergeVaribales.length ? (
                      mergeVaribales.map((item, index) => {
                        return this.renderMergeVaribles(item, index);
                      })
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div id="send-new-mail" className="modal-actions double-btns">
                  <button
                    disabled={mergeVaribalesValuesError}
                    className={`cta mg-brand2-color`}
                    onClick={(event) => this.handlePreviewClick()}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(PreviewTaskTemplateModal);
