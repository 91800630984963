import React, { Component } from 'react';

// Components
import Modal from "../Modal";

class TermErrorModal extends Component {

    render() {
        let { message, title, termsProps } = this.props.optionalProps;
        return (
            <Modal
                title={title ? title : "Failed!"}
                hideModal={this.props.hideModal}
                closeOnClickOutside={true}
            >
                <p style={{ textAlign: 'center' }}>
                    {message}
                </p>
                <div className="modal-actions">
                    <a
                        className="cta mg-brand2-color"
                        onClick={() => {
                            this.props.hideModal();
                            this.props.updateModalState(true, "ADD_TERM", termsProps);
                        }}
                    >
                        {title === 'Success Message' ? "Ok" : "Got it."}
                    </a>
                </div>
            </Modal>
        )
    }
}

export default TermErrorModal;