import React,{Component} from "react";
import Modal from './Modal';
class NotCreditMemo extends Component {

    render() {
      let { message, title, isOKCallback } = this.props.optionalProps;
  
      return (
        <Modal
          title={title ? title : "Info!"}
          hideModal={this.props.hideModal}
          closeOnClickOutside={true}
        >
          <p style={{ textAlign: 'center' }}>
            <p>No Credit Memo Applied</p>
          </p>
          <div className="modal-actions">
            <a
              className="cta mg-brand2-color"
              onClick={() => {
                this.props.hideModal();
                if(this.props && this.props.optionalProps && this.props.optionalProps.isOKCallback) {
                  this.props.optionalProps.onOKClick()
                };
              }
            }
            >
              Ok
            </a>
          </div>
        </Modal>
      )
    }
  }
  export default NotCreditMemo;