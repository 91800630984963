import React, { PureComponent } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsProfileSidebar from "../../components/Clients/ClientsProfileSidebar";
import ClientsProfileForm from "../../components/Clients/Forms/ClientsProfileForm";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";

class ClientsProfile extends PureComponent {
  state = {
    user: {},
    addressDocument: {},
    communicationPreferences: {},
    isLoading: true,
    showFlash: false,
    hasAnyError: false,
  };

  componentDidMount() {
    const { user } = this.props;
    API.getCleintAdminUser(user).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        !data.errors &&
        !isAccessDenied
      ) {
        this.setState((state) => ({
          user: data.user,
          communicationPreferences: data.communication_preferences,
          isLoading: false,
        }));
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data ? data.message : "Some thing went wrong!",
        });
      }
    });
    getZohoPagesense();
    // ReactGA.pageview("/ClientsProfile");
  }

  handleSubmit = (clientUpdate) => {
    API.updateClient(this.props.user, clientUpdate).then((data) => {
      if ((typeof data !== "undefined" || data !== null) && !data.errors) {
        this.props.updateModalState(true, "SUCCESS", {
          message: "Profile saved successfully.",
        });
        API.getCleintAdminUser(this.props.user).then((data) => {
          if (!data.errors) {
            this.setState(
              (state) => ({
                user: data.user,
                communicationPreferences: data.communication_preferences,
                isLoading: false,
              }),
              () => {
                this.props.getUserProfile(
                  this.state.user.first_name,
                  this.state.user.last_name
                );
              }
            );
          }
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  dismissLoader = () => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
    }));
  };

  handleSubmitCardAction = (stripeToken) => {
    API.createOrUpdateStripeCustomerId(this.props.user, stripeToken).then(
      (data) => {
        if ((typeof data !== "undefined" || data !== null) && data.cardAdded) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });

          API.getUser(this.props.user).then((data) => {
            if (!data.errors) {
              this.setState((state) => ({
                user: data.user,
                communicationPreferences: data.communication_preferences,
                isLoading: false,
              }));
            }
          });
        } else {
          this.dismissLoader();
          this.props.updateModalState(true, "ERROR", {
            message: data.error
              ? data.error
              : data.message || "An unknown error occurred",
          });
        }
      }
    );
  };

  createOrUpdateStripeAccount = (stripeToken) => {
    if (this.state.user.last_4 != null) {
      confirmAlert({
        title: "Warning!",
        message:
          "You have already added card. do you want to replace the card?.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.handleSubmitCardAction(stripeToken),
          },
          {
            label: "No",
            onClick: () => this.dismissLoader,
          },
        ],
      });
    } else {
      this.handleSubmitCardAction(stripeToken);
    }
  };

  removeCardActionClick = () => {
    API.removeCard(this.props.user).then((data) => {
      if (typeof data !== "undefined" || data !== null) {
        if (data.cardRemoved) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          API.getUser(this.props.user).then((data) => {
            if (!data.errors) {
              this.setState((state) => ({
                user: data.user,
                communicationPreferences: data.communication_preferences,
                isLoading: false,
              }));
            }
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  render() {
    const { appName, stripePubKey } = this.props;

    const { user, addressDocument, isLoading, communicationPreferences } =
      this.state;

    if (this.state.hasAnyError || user === undefined) {
      this.props.updateModalState(true, "OTHER_ERROR", this.state.hasAnyError);
      return <Redirect to={{ pathname: "/sign-out" }} />;
    }
    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='consumer-profile'>
        <Helmet>
          <title>{appName} | Profile</title>
        </Helmet>
        <div className='user-portal-collapse-wrap app-sidebar-layout scrollbar client-scroll-profile'>
          <AppSectionHeader title='Profile' hideAll={true} />
          <div>
            <div className='app-sidebar-layout-canvas'>
              <ClientsProfileSidebar
                user={user}
                addressDocument={addressDocument}
                removeCardActionClick={this.removeCardActionClick}
              />
              <div className='admin-profile-app-sidebar-layout-canvas-content'>
                <ClientsProfileForm
                  user={user}
                  communicationPreferences={communicationPreferences}
                  handleSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsProfile;
