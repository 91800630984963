import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";

class MiscellaneousSettings extends Component {
  state = {
    prefData: [],
    isLoading: true,
  };

  componentDidMount() {
    //TO_DO
    this.getMiscellaneousSettingStatus();
  }

  //get payment preferences data
  getMiscellaneousSettingStatus = () => {
    API.getMiscellaneousInSettings(this.props.user).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          this.setState({ prefData: data.data });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          isLoading: false,
        }));
      }
    });
  };

  //update payment pref on toggler click
  updatePaymentPreferences = (query) => {
    API.clientUpdateMiscellaneousInSettings(this.props.user, query).then(
      (data) => {
        if (typeof data != "undefined" || data != null) {
          if (data) {
            this.getMiscellaneousSettingStatus();
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  toggleButton = (type, prefIndex, prefItemIndex, prefItem, e) => {
    let { prefData } = this.state;
    let is_enable;
    let query = {};
    if (type == "LETTER_PREF") {
      let pref = prefData[prefIndex];
      let prefItem = pref.data[prefItemIndex];
      prefData[prefIndex].data[prefItemIndex].is_enable =
        !prefData[prefIndex].data[prefItemIndex].is_enable;

      query = {
        id: prefItem.id,
        is_enable: this.getPrefItemEnableValue(
          prefItemIndex,
          prefIndex,
          prefItem
        ),
      };
    }
    this.setState({ prefData }, () => {});
    if (query) {
      this.updatePaymentPreferences(query);
    }
  };
  getPrefItemEnableValue = (prefItemIndex, prefIndex, prefItem) => {
    let { prefData } = this.state;
    let pref = prefData[prefIndex];
    if (prefItem.name === "Hide Account Number from Invoices") {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    } else if (
      prefItem.name ===
      "Include Internal Contacts to Automated email communications"
    ) {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    } else if (
      prefItem.name === "Use Perforated Edges and Return Envelopes for Invoices"
    ) {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    } else if (prefItem.name === "Send Monthly Statements To Customers") {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    } else if (prefItem.name === "Include Supporting Documents with Invoices") {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    } else if (
      prefItem.name === "Include Paid Invoice in Payment Receipt Communication"
    ) {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    } else if (prefItem.name === "Auto Populate Balance Due") {
      if (prefData[prefIndex].data[prefItemIndex].is_enable) {
        return true;
      } else {
        return false;
      }
    }
  };
  render() {
    const { appName } = this.props;
    const { isLoading, prefData } = this.state;

    return isLoading ? (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div className='consumer-help'>
        <Helmet>
          <title>{appName} | Miscellaneous</title>
        </Helmet>
        <div className='big-device-grad '>
          <div className='he-hed'>Miscellaneous Preferences</div>
          {prefData && prefData.length
            ? prefData.map((preference, prefindex) => {
                return (
                  <div className='pref-selection' key={`pre007${prefindex}`}>
                    <div className='pref-selection-header'>
                      {preference.title}
                    </div>
                    <div className='pref-selection-sep' />
                    {preference && preference.data && preference.data.length
                      ? preference.data.map((prefItem, prefItemIndex) => {
                          return (
                            <div
                              className='pref-selection-inner'
                              key={`007${prefindex}${prefItemIndex}`}
                            >
                              <div className='pref-selection-inner-left'>
                                <p className='pref-selection-rdtx'>
                                  {prefItem.name ? prefItem.name : null}
                                </p>
                                <ToggleSwitch
                                  isOn={prefItem.is_enable}
                                  toggleButton={() =>
                                    this.toggleButton(
                                      "LETTER_PREF",
                                      prefindex,
                                      prefItemIndex,
                                      prefItem
                                    )
                                  }
                                />
                              </div>
                              {prefItem.help_text ? (
                                <div className='pref-selection-inner-right'>
                                  <img
                                    src={InfoIcon}
                                    className='pref-selection-info-ic'
                                  />
                                  <p className='pref-selection-sb-txt'>
                                    {prefItem.help_text
                                      ? prefItem.help_text
                                      : null}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                    {/* <div className="pref-selection-inner md-sp">
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            Here is the link to your payment portal{" "}
                                            <a href={url} target="_blank" style={{ cursor: "pointer" }}>
                                                {url}
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

export default MiscellaneousSettings;
