import React, { Component } from 'react';
import { Redirect } from "react-router-dom";


// API
import * as API from '../../../../utils/api';

// Components
import Modal from '../Modal';

import { convertCreditorsToArrayForSelectComponent, showToast } from '../../../../utils/helpers';




class AddNewCreditorModal extends Component {
  state = {
    creditors: [],
    input: {
      creditorInformation: {
        name: {
          value: "",
          hasError: false,
          isRequired: true
        },
        exist: {
          value: "",
          hasError: false,
        }
      }
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    },
  }

  componentDidMount() {
    API.fetchAllCreditors(this.props.user).then(data => {
      let creditorsArray = convertCreditorsToArrayForSelectComponent(data.creditors);
      this.setState(state => ({
        creditors: creditorsArray
      }))
    })
  }

  handleModalClose = () => {
    this.props.hideModal();
  }

  handleCreditorInfoInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        creditorInformation: {
          ...state.input.creditorInformation,
          ...newPartialInput,
        }
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { input } = this.state;
    if (this.validateForm()) {
      let creditorForSubmission = {
        name: input.creditorInformation.name.value
      }
      API.createCreditor(this.props.user, creditorForSubmission).then(data => {
        this.scrollToTop();
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          if (data.message) {
            // this.props.updateModalState(true, 'ERROR', {message: data.message});
            let newInputState = { ...this.state.input.creditorInformation }
            newInputState['exist'].hasError = true;
            this.setState(state => ({
              ...state,
              creditorInformation: newInputState
            }))
            this.renderErrorMessage('exist')
          } else {
            if (this.props.optionalProps.consumerId) {
              this.props.optionalProps.history.push(`/client/accounts/${this.props.optionalProps.consumerId}`);
              this.props.optionalProps.history.push(`/client/accounts/${this.props.optionalProps.consumerId}/edit`);
            }
            this.handleModalClose()
          }
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      });
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.creditorInformation }

    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'name') {
        if (value.value === '' || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        creditorInformation: newInputState
      }))
    } else {
      return true;
    }
  }



  renderErrorMessage(input) {
    let message = '';
    if (input === 'name') {
      message = "Please enter a name.";
    } else if (input === 'exist') {
      message = "Creditor already exist.";
    } else {
      message = 'Please complete this field.';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { input, slide, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }
    return (
      <Modal
        title="Add Account"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
      >
        <form
          className="admin-form"
        >
          <header className="modal-slide-header" >
            <div className={`modal-slide-header-tab${slide === 1 ? ' active' : ''}`}>
              Creditor's Information
            </div>
          </header>
          <div className={`modal-slide`}>
            <div className="form-container">
              <div
                className={`input-container${input.creditorInformation.name.hasError || input.creditorInformation.exist.hasError ? ' error' : ''}`}
              >
                <label htmlFor="name">Creditor's Name</label>
                <div
                  className="input-container multi-input-field"
                >
                  <input
                    className="multi-input-field-input"
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={input.creditorInformation.name.value}
                    onChange={event => this.handleCreditorInfoInputChange({ name: { value: event.target.value, hasError: false } })}
                  />

                </div>
                {input.creditorInformation.name.hasError ? this.renderErrorMessage('name') : null}
                {input.creditorInformation.exist.hasError ? this.renderErrorMessage('exist') : null}
              </div>

            </div>
            <div className="modal-actions">
              <a
                className="cta"
                onClick={(event) => this.handleSubmit(event)}
              >
                Create New Creditor
              </a>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default AddNewCreditorModal;
