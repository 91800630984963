import React, { Component } from 'react';

class SVGSprite extends Component {
  render() {
    return (
      <svg className="hide">

        <symbol id="bell" viewBox="0 0 25 28">
          <path d="M20.4,19.2l-2.5-4.4c-0.2-0.3-0.3-0.7-0.3-1.1V8.2c0-3-2.5-5.4-5.6-5.4c-3.1,0-5.6,2.4-5.6,5.4v5.7
            c0,0.4-0.1,0.7-0.3,1.1l-2.5,4.4H20.4z M17.1,22c0.1,0.2,0.2,0.5,0.2,0.8c0,2.9-2.4,5.2-5.3,5.2c-2.9,0-5.3-2.3-5.3-5.2
            c0-0.3,0.1-0.6,0.2-0.8H2.6c-2,0-3.2-1.9-2.2-3.8l3-5.2V8.5C3.4,3.8,7.3,0,12,0s8.7,3.8,8.7,8.5V13l3.1,5.3c0.9,1.8-0.3,3.7-2.3,3.7
            H17.1z M13.7,22h-3.4c0.1,0.2,0.2,0.5,0.2,0.8c0,0.8,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5C13.6,22.5,13.6,22.3,13.7,22z"/>
        </symbol>

        <symbol id="arrow-right" viewBox="0 0 18 30">
          <path d="M1.8,0.3C2,0.1,2.2,0,2.5,0C2.8,0,3,0.1,3.2,0.3l14,14c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
            l-14,14C3,29.9,2.8,30,2.5,30c-0.3,0-0.5-0.1-0.7-0.3l-1.5-1.5C0.1,28,0,27.8,0,27.5s0.1-0.5,0.3-0.7L12.1,15L0.3,3.2
            C0.1,3,0,2.8,0,2.5C0,2.2,0.1,2,0.3,1.8L1.8,0.3z"/>
        </symbol>

        <symbol id="arrow-left" viewBox="0 0 18 30">
          <path d="M1.8,0.3 C2,0.1 2.2,0 2.5,0 C2.8,0 3,0.1 3.2,0.3 L17.2,14.3 C17.4,14.5 17.5,14.7 17.5,15 C17.5,15.3 17.4,15.5 17.2,15.7 L3.2,29.7 C3,29.9 2.8,30 2.5,30 C2.2,30 2,29.9 1.8,29.7 L0.3,28.2 C0.1,28 0,27.8 0,27.5 C0,27.2 0.1,27 0.3,26.8 L12.1,15 L0.3,3.2 C0.1,3 0,2.8 0,2.5 C0,2.2 0.1,2 0.3,1.8 L1.8,0.3 Z" id="arrow-left" fillRule="nonzero" transform="translate(8.750000, 15.000000) scale(-1, 1) translate(-8.750000, -15.000000) "></path>
        </symbol>

        <symbol id="lock" viewBox="0 0 26 31">
          <path d="M7.7,14.5h10.9v-4.1c0-1.5-0.5-2.8-1.6-3.9s-2.4-1.6-3.9-1.6s-2.8,0.5-3.9,1.6s-1.6,2.4-1.6,3.9V14.5
            z M25.5,16.5v12.3c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6H3c-0.6,0-1.1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4V16.5
            c0-0.6,0.2-1.1,0.6-1.4s0.9-0.6,1.4-0.6h0.7v-4.1c0-2.6,0.9-4.9,2.8-6.7s4.1-2.8,6.7-2.8s4.9,0.9,6.7,2.8s2.8,4.1,2.8,6.7v4.1h0.7
            c0.6,0,1.1,0.2,1.4,0.6C25.3,15.5,25.5,16,25.5,16.5z"/>
        </symbol>

        <symbol id="close" viewBox="0 0 20 20">
          <path d="M13.7704055,10 L19.2259084,4.5444971 C20.2580305,3.51237493 20.2580305,1.82727751 19.2259084,0.774091627 C18.1937862,-0.258030542 16.5086888,-0.258030542 15.4555029,0.774091627 L10,6.22959452 L4.5444971,0.774091627 C3.51237493,-0.258030542 1.82727751,-0.258030542 0.774091627,0.774091627 C-0.258030542,1.8062138 -0.258030542,3.49131122 0.774091627,4.5444971 L6.22959452,10 L0.774091627,15.4555029 C-0.258030542,16.4876251 -0.258030542,18.1727225 0.774091627,19.2259084 C1.30068457,19.7525013 1.97472354,20.0052659 2.64876251,20.0052659 C3.32280147,20.0052659 4.01790416,19.7525013 4.52343339,19.2259084 L9.97893628,13.7704055 L15.4344392,19.2259084 C15.9610321,19.7525013 16.6350711,20.0052659 17.3091101,20.0052659 C17.983149,20.0052659 18.6782517,19.7525013 19.1837809,19.2259084 C20.2159031,18.1937862 20.2159031,16.5086888 19.1837809,15.4555029 L13.7704055,10 Z" fillRule="nonzero"></path>
        </symbol>

        <symbol id="overflow-menu" viewBox="0 0 7.5 30">
          <path d="M3.8,18.9C1.7,18.9,0,17.2,0,15s1.7-3.9,3.8-3.9s3.8,1.8,3.8,3.9S5.8,18.9,3.8,18.9z M3.8,30
            C1.7,30,0,28.2,0,26.1s1.7-3.9,3.8-3.9s3.8,1.8,3.8,3.9S5.8,30,3.8,30z M3.8,7.9C1.7,7.9,0,6.1,0,3.9S1.7,0,3.8,0s3.8,1.8,3.8,3.9
            S5.8,7.9,3.8,7.9z"/>
        </symbol>

        <symbol id="currency" viewBox="0 0 34 34">
          <path d="M26.6,31.9c2.9,0,5.3-2.3,5.3-5.2V7.2c0-2.8-2.4-5.2-5.3-5.2H7.3C4.4,2,2,4.3,2,7.2v19.6
            c0,2.8,2.4,5.2,5.3,5.2H26.6z M26.6,33.9H7.3c-4,0-7.3-3.2-7.3-7.2V7.2C0,3.2,3.3,0,7.3,0h19.3c4,0,7.3,3.2,7.3,7.2v19.6
            C33.9,30.7,30.6,33.9,26.6,33.9z M15.2,24.3h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h5.6c1.4,0,2.5-1.1,2.5-2.4c0-1.3-1.1-2.4-2.5-2.4l-2.3,0
            c-2.5,0-4.5-2-4.5-4.4c0-2.3,1.8-4.2,4.2-4.4V7c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v1.7h3.6c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-5.3
            c-1.4,0-2.5,1.1-2.5,2.4c0,1.3,1.1,2.4,2.5,2.4l2.3,0c2.5,0,4.5,2,4.5,4.4c0,2.4-2,4.4-4.5,4.4h-0.6v1.6c0,0.6-0.4,1-1,1
            c-0.6,0-1-0.4-1-1V24.3z"/>
        </symbol>

        <symbol id="setting" viewBox="0 0 34 34">
          <path d="M26.6,31.9c2.9,0,5.3-2.3,5.3-5.2V7.2c0-2.8-2.4-5.2-5.3-5.2H7.3C4.4,2,2,4.3,2,7.2v19.6c0,2.8,2.4,5.2,5.3,5.2
            H26.6z M26.6,33.9H7.3c-4,0-7.3-3.2-7.3-7.2V7.2C0,3.2,3.3,0,7.3,0h19.3c4,0,7.3,3.2,7.3,7.2v19.6C33.9,30.7,30.6,33.9,26.6,33.9z"/>
          <path d="M27.9,14.661a0.781,0.781,0,0,0-.771-0.591,2.651,2.651,0,0,1-1.813-4.614,0.667,0.667,0,0,0,.073-0.905,11.873,11.873,
            0,0,0-1.9-1.921,0.667,0.667,0,0,0-.912.074,2.762,2.762,0,0,1-2.991.673,2.667,2.667,0,0,1-1.62-2.607,0.666,0.666,0,0,0-.589-0.7,
            11.961,11.961,0,0,0-2.7-.007,0.668,0.668,0,0,0-.594.687,2.671,2.671,0,0,1-1.642,2.562,2.77,2.77,0,0,1-2.969-.677,0.668,0.668,0,0,
            0-.905-0.076,11.919,11.919,0,0,0-1.94,1.92,0.668,0.668,0,0,0,.072.913,2.662,2.662,0,0,1,.671,3,2.773,2.773,0,0,1-2.61,1.623,0.652,
            0.652,0,0,0-.693.589,12.041,12.041,0,0,0-.005,2.731,0.794,0.794,0,0,0,.786.59,2.63,2.63,0,0,1,2.468,1.645,2.672,2.672,0,0,1-.671,
            2.969,0.668,0.668,0,0,0-.073.9,11.917,11.917,0,0,0,1.9,1.922,0.666,0.666,0,0,0,.913-0.072,2.758,2.758,0,0,1,2.99-.673A2.663,2.663,
            0,0,1,14,27.218a0.667,0.667,0,0,0,.589.7,11.918,11.918,0,0,0,2.7.007,0.668,0.668,0,0,0,.594-0.688,2.669,2.669,0,0,1,1.641-2.56,2.766,
            2.766,0,0,1,2.97.677,0.67,0.67,0,0,0,.905.076,11.948,11.948,0,0,0,1.94-1.92,0.667,0.667,0,0,0-.072-0.912,2.652,2.652,0,0,1,1.782-4.623l0.148,
            0a0.668,0.668,0,0,0,.7-0.588A12.032,12.032,0,0,0,27.9,14.661ZM16,20.024a4,4,0,1,1,4-4A4.007,4.007,0,0,1,16,20.024Z" />
        </symbol>

        <symbol id="admin" viewBox="0 0 34 34">
          <path d="M26.6,31.9c2.9,0,5.3-2.3,5.3-5.2V7.2c0-2.8-2.4-5.2-5.3-5.2H7.3C4.4,2,2,4.3,2,7.2v19.6c0,2.8,2.4,5.2,5.3,5.2
            H26.6z M26.6,33.9H7.3c-4,0-7.3-3.2-7.3-7.2V7.2C0,3.2,3.3,0,7.3,0h19.3c4,0,7.3,3.2,7.3,7.2v19.6C33.9,30.7,30.6,33.9,26.6,33.9z
             M21.1,22.9c4.3,1.2,8,4.2,10,8.2c0.2,0.5,0,1.1-0.5,1.3c-0.5,0.2-1.1,0-1.3-0.5c-2.3-4.7-7.1-7.7-12.5-7.7c-5.3,0-10.1,3-12.4,7.6
            c-0.3,0.5-0.9,0.7-1.3,0.4s-0.7-0.9-0.4-1.3c2-4,5.6-6.9,9.9-8c-2.3-1.4-3.9-3.9-3.9-6.8c0-4.4,3.7-8,8.2-8c4.5,0,8.2,3.6,8.2,8
            C25,18.9,23.4,21.4,21.1,22.9z M23,16c0-3.3-2.8-6-6.2-6c-3.4,0-6.2,2.7-6.2,6c0,3.3,2.8,6,6.2,6C20.2,22.1,23,19.4,23,16z"/>
        </symbol>

        <symbol id="lob" viewBox="0 0 34 34">
        <path d="M26.6,31.9c2.9,0,5.3-2.3,5.3-5.2V7.2c0-2.8-2.4-5.2-5.3-5.2H7.3C4.4,2,2,4.3,2,7.2v19.6c0,2.8,2.4,5.2,5.3,5.2
            H26.6z M26.6,33.9H7.3c-4,0-7.3-3.2-7.3-7.2V7.2C0,3.2,3.3,0,7.3,0h19.3c4,0,7.3,3.2,7.3,7.2v19.6C33.9,30.7,30.6,33.9,26.6,33.9z"/>
          <path d="M8.779,13.62q0.363,0.256,2.186,1.52t2.794,1.946q0.107,0.075.453,0.325t0.576,0.406q0.229,0.155.555,0.346a3.227,3.227,0,0,0,
          .613.288,1.693,1.693,0,0,0,.533.1h0.021a1.693,1.693,0,0,0,.533-0.1,3.218,3.218,0,0,0,.613-0.288q0.325-.192.555-0.346t0.576-.405q0.346-.251.453-0.325,
          0.981-.683,4.991-3.466a5.066,5.066,0,0,0,1.3-1.312,2.815,2.815,0,0,0,.523-1.61,1.721,1.721,0,0,0-1.706-1.706H8.651a1.523,1.523,0,0,0-1.264.554,2.154,
          2.154,0,0,0-.443,1.387,2.49,2.49,0,0,0,.587,1.456A5.292,5.292,0,0,0,8.779,13.62Zm16.21,1.141q-3.5,2.367-5.311,3.679-0.608.448-.986,0.7a6.076,6.076,0,0,
          1-1.008.512,3.072,3.072,0,0,1-1.173.261H16.489a3.072,3.072,0,0,1-1.173-.261,6.08,6.08,0,0,1-1.008-.512q-0.379-.25-0.986-0.7-1.44-1.056-5.3-3.679a5.65,5.65,
          0,0,1-1.077-.928V22.3a1.711,1.711,0,0,0,1.706,1.706h15.7A1.711,1.711,0,0,0,26.056,22.3V13.834A5.885,5.885,0,0,1,24.989,14.762Z"/>
        </symbol>

        <symbol id="view" viewBox="0 0 18 30">
          <path d="M22.532,6a17.049,17.049,0,0,0-3.051-2.836A13.593,13.593,0,0,0,11.5.325,13.593,13.593,0,0,0,3.519,3.168,17.049,17.049,0,0,0,.467,6l-0.392.5L0.467,
          7A17.062,17.062,0,0,0,3.519,9.832,13.593,13.593,0,0,0,11.5,12.675a13.593,13.593,0,0,0,7.981-2.843A17.053,17.053,0,0,0,22.533,7l0.392-.5ZM11.5,10.826A4.327,
          4.327,0,0,1,7.3,5.417L6.314,5.251A5.313,5.313,0,0,0,7.876,10.4,14.055,14.055,0,0,1,4.5,8.571,16.812,16.812,0,0,1,2.16,6.5,16.81,16.81,0,0,1,4.5,4.429,14.055,
          14.055,0,0,1,7.876,2.6L8.54,3.343A4.329,4.329,0,1,1,11.5,10.826Zm7-2.255A14.057,14.057,0,0,1,15.123,10.4a5.309,5.309,0,0,0,0-7.808A14.056,14.056,0,0,1,18.5,
          4.429,16.811,16.811,0,0,1,20.84,6.5,16.806,16.806,0,0,1,18.5,8.571ZM8.742,5.659A2.887,2.887,0,1,0,9.5,4.426l1.422,1.6Z"/>
        </symbol>

        <symbol id="help" viewBox="0 0 34 34">
          <path d="M26.7,0H7.3C3.3,0,0,3.2,0,7.2v19.6c0,4,3.3,7.2,7.3,7.2h19.4c4,0,7.3-3.2,7.3-7.2V7.2C34,3.2,30.7,0,26.7,0z
             M32,26.8c0,2.9-2.4,5.2-5.3,5.2H7.3C4.4,32,2,29.7,2,26.8V7.2C2,4.3,4.4,2,7.3,2h19.4C29.6,2,32,4.3,32,7.2V26.8z"/>
          <circle cx="16.5" cy="25.5" r="1.5"/>
          <path d="M16.7,6.9c-1.5,0-2.9,0.5-4,1.6C11.6,9.6,11,11,11,12.6c0,0.6,0.4,1,0.9,1s0.9-0.4,1-1c0-1,0.4-2,1.1-2.7
            c0.7-0.7,1.7-1,2.7-1C18.5,9,20,10.6,20,12.4c0.1,1.4-0.5,2.6-1.5,3.4c-1.9,1.4-3,3.7-3,6V22c0,0.6,0.4,1,0.9,1s0.9-0.5,0.9-1v-0.3
            c0-1.8,0.8-3.4,2.3-4.5c1.6-1.1,2.4-3,2.3-5C21.8,9.4,19.5,7,16.7,6.9z"/>
        </symbol>

        <symbol id="square-texture" viewBox="0 0 171 60">
          <path d="M0,60v-4.8h4.8V60H0z M0,41.6v-4.8h4.8v4.8H0z M0,23.2v-4.8h4.8v4.8H0z M0,4.8V0h4.8v4.8H0z M18.4,60
          	v-4.8h4.8V60H18.4z M18.4,41.6v-4.8h4.8v4.8H18.4z M18.4,23.2v-4.8h4.8v4.8H18.4z M18.4,4.8V0h4.8v4.8H18.4z M36.8,60v-4.8h4.8V60
          	H36.8z M36.8,41.6v-4.8h4.8v4.8H36.8z M36.8,23.2v-4.8h4.8v4.8H36.8z M36.8,4.8V0h4.8v4.8H36.8z M55.2,60v-4.8H60V60H55.2z
          	 M55.2,41.6v-4.8H60v4.8H55.2z M55.2,23.2v-4.8H60v4.8H55.2z M55.2,4.8V0H60v4.8H55.2z M73.5,60v-4.8h4.8V60H73.5z M73.5,41.6v-4.8
          	h4.8v4.8H73.5z M73.5,23.2v-4.8h4.8v4.8H73.5z M73.5,4.8V0h4.8v4.8H73.5z M91.9,60v-4.8h4.8V60H91.9z M91.9,41.6v-4.8h4.8v4.8H91.9z
          	 M91.9,23.2v-4.8h4.8v4.8H91.9z M91.9,4.8V0h4.8v4.8H91.9z M110.3,60v-4.8h4.8V60H110.3z M110.3,41.6v-4.8h4.8v4.8H110.3z
          	 M110.3,23.2v-4.8h4.8v4.8H110.3z M110.3,4.8V0h4.8v4.8H110.3z M128.7,60v-4.8h4.8V60H128.7z M128.7,41.6v-4.8h4.8v4.8H128.7z
          	 M128.7,23.2v-4.8h4.8v4.8H128.7z M128.7,4.8V0h4.8v4.8H128.7z M147.1,60v-4.8h4.8V60H147.1z M147.1,41.6v-4.8h4.8v4.8H147.1z
          	 M147.1,23.2v-4.8h4.8v4.8H147.1z M147.1,4.8V0h4.8v4.8H147.1z M165.5,60v-4.8h4.8V60H165.5z M165.5,41.6v-4.8h4.8v4.8H165.5z
          	 M165.5,23.2v-4.8h4.8v4.8H165.5z M165.5,4.8V0h4.8v4.8H165.5z"/>
        </symbol>

        <symbol id="logo" viewBox="0 0 72.8 59.7">
          <path d="M23.4,0.2h-5c-0.4,0-0.8,0.3-0.8,0.7c0,0,0,0,0,0v11.6c-0.5-0.5-1.1-0.9-1.8-1.2c-1.3-0.6-2.7-1-4.1-1c-1.6,0-3.3,0.3-4.8,1
          C5.6,12,4.3,12.9,3.3,14c-1,1.1-1.8,2.4-2.4,3.8c-1.1,2.9-1.1,6.2,0,9.2c0.6,1.4,1.4,2.7,2.4,3.8c1,1.1,2.3,2.1,3.7,2.7
          c1.5,0.7,3.1,1,4.8,1c1.4,0,2.8-0.4,4.1-1c0.6-0.3,1.2-0.7,1.8-1.2v0.9c0,0.4,0.3,0.8,0.8,0.8h5c0.4,0,0.8-0.3,0.8-0.8V1
          C24.1,0.6,23.8,0.3,23.4,0.2z M12,28.5c-0.8,0-1.5-0.2-2.2-0.5c-1.3-0.6-2.4-1.8-2.9-3.2c-0.5-1.5-0.5-3.2,0-4.7
          c0.5-1.4,1.5-2.6,2.8-3.2c0.7-0.3,1.5-0.5,2.2-0.4c0.8,0,1.6,0.2,2.3,0.5c0.7,0.3,1.2,0.8,1.7,1.3c0.5,0.6,0.9,1.2,1.1,1.9
          c0.6,1.5,0.6,3.2,0,4.7c-0.3,0.7-0.6,1.4-1.1,1.9c-0.5,0.5-1.1,1-1.7,1.3C13.6,28.3,12.8,28.5,12,28.5z M44.3,13.4
          c-1-0.9-2.1-1.7-3.4-2.2c-1.3-0.5-2.7-0.8-4.2-0.8c-1.6,0-3.3,0.3-4.8,0.9c-1.4,0.6-2.6,1.4-3.6,2.6c-1,1.1-1.7,2.4-2.2,3.8
          c-0.5,1.5-0.8,3.1-0.8,4.7c0,1.6,0.2,3.1,0.7,4.6c0.4,1.4,1.2,2.8,2.2,3.9c1,1.1,2.2,2,3.6,2.6c1.6,0.7,3.3,1,5,0.9
          c1.4,0,2.7-0.2,4-0.6C42,33.5,43,33,44,32.2c0.9-0.7,1.6-1.5,2.2-2.4c0.5-0.9,0.9-1.9,1.1-2.9c0.1-0.4-0.2-0.8-0.6-0.9
          c0,0-0.1,0-0.1,0h-5.1c-0.3,0-0.6,0.2-0.7,0.5c-0.3,0.7-0.7,1.2-1.2,1.7c-0.8,0.5-1.7,0.7-2.6,0.6c-0.8,0-1.6-0.1-2.3-0.4
          c-0.6-0.2-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-0.8-1.5c-0.1-0.4-0.2-0.8-0.2-1.2h14.4c0.4,0,0.7-0.3,0.7-0.6c0.1-0.6,0.2-1.3,0.2-1.9
          c0-0.5,0-1,0-1.3C47.4,18.1,46.3,15.4,44.3,13.4L44.3,13.4z M31.9,19.5c0.1-1,0.5-2,1.3-2.6c1-0.8,2.2-1.2,3.4-1.1
          c1.2-0.1,2.4,0.4,3.2,1.2c0.7,0.7,1.1,1.6,1.2,2.6H31.9z M72,17.6c-0.6-1.4-1.4-2.7-2.4-3.8c-1-1.1-2.3-2-3.7-2.6
          c-1.5-0.7-3.1-1-4.8-1c-1.4,0-2.8,0.4-4.1,1c-0.6,0.3-1.2,0.7-1.8,1.2V0.8c0-0.4-0.3-0.8-0.7-0.8c0,0,0,0,0,0h-5
          c-0.4,0-0.8,0.3-0.8,0.8v32.2c0,0.4,0.3,0.8,0.8,0.8h5c0.4,0,0.8-0.3,0.8-0.8V32c0.5,0.5,1.1,0.9,1.8,1.2c1.3,0.6,2.7,1,4.1,1
          c1.6,0,3.3-0.3,4.8-1c1.4-0.6,2.7-1.5,3.7-2.6c1-1.1,1.9-2.4,2.4-3.8C73.1,23.9,73.1,20.6,72,17.6z M60.8,16.2
          c0.8,0,1.5,0.2,2.2,0.5c1.3,0.6,2.4,1.8,2.9,3.2c0.5,1.5,0.5,3.2,0,4.7c-0.5,1.4-1.5,2.6-2.8,3.2c-0.7,0.3-1.5,0.5-2.2,0.5
          c-0.8,0-1.6-0.2-2.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3c-0.5-0.6-0.9-1.2-1.1-1.9c-0.5-1.5-0.5-3.2,0-4.7c0.2-0.7,0.6-1.4,1.1-1.9
          c0.5-0.5,1.1-1,1.8-1.3C59.2,16.3,60,16.2,60.8,16.2z M64,40.2c-1.4-0.9-2.7-1.6-3.6-0.2c-8,13.3-25.2,17.5-38.4,9.6
          c-3.9-2.4-7.2-5.6-9.6-9.6c-0.9-1.4-2.1-0.7-3.6,0.2s-2.5,1.5-1.6,3C17,59.3,37.9,64.5,54,54.8c4.7-2.9,8.7-6.8,11.5-11.5
          C66.4,41.8,65.4,41.1,64,40.2z"/>
        </symbol>

        <symbol id="logo-base" viewBox="0 0 151 71">
          <path d="M48.4,0C49.3,0,50,0.7,50,1.6v66.7c0,0.9-0.7,1.6-1.6,1.6H38.1c-0.9,0-1.6-0.7-1.6-1.6v-1.8
            c-1.1,0.9-2.3,1.8-3.7,2.4C30,70.3,27.2,71,24.3,71c-3.6,0-6.9-0.7-9.9-2c-3-1.3-5.6-3.2-7.7-5.5c-2.1-2.3-3.8-5-5-7.9
            c-1.2-3-1.8-6.2-1.8-9.5c0-3.3,0.6-6.5,1.8-9.5c1.2-3,2.9-5.7,5-8c2.1-2.3,4.7-4.1,7.7-5.5c3-1.3,6.3-2,9.9-2c2.8,0,5.7,0.7,8.5,2.1
            c1.4,0.7,2.6,1.5,3.7,2.4V1.6c0-0.9,0.7-1.6,1.6-1.6H48.4z M92.5,28.1c2,1.9,3.6,4.2,4.8,6.7c1.1,2.6,1.7,5.4,1.7,8.4
            c0,0.8,0,1.7-0.1,2.7c0,1.1-0.2,2.4-0.5,3.9c-0.1,0.7-0.8,1.3-1.6,1.3H66.7c0.1,0.8,0.3,1.6,0.6,2.4c0.4,1.2,1,2.2,1.7,3.1
            c0.8,0.8,1.8,1.5,3,2c1.3,0.5,2.9,0.8,4.9,0.8c2.6,0,4.5-0.4,5.5-1.3c1.2-1,2-2.1,2.5-3.4c0.2-0.6,0.8-1,1.5-1H97
            c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.4,0.8,0.4,1.2c-0.4,2.1-1.1,4.1-2.3,5.9c-1.1,1.9-2.7,3.5-4.6,5c-1.9,1.4-4,2.5-6.5,3.4
            C82.8,70.6,80,71,77,71c-4,0-7.5-0.6-10.5-1.9c-3-1.3-5.5-3-7.5-5.3c-2-2.2-3.5-4.9-4.5-7.9c-1-2.9-1.5-6.1-1.5-9.5
            c0-3.4,0.5-6.6,1.6-9.6c1.1-3,2.6-5.6,4.6-7.8c2-2.2,4.5-4,7.5-5.2c2.9-1.2,6.3-1.9,10-1.9c3.1,0,6,0.5,8.7,1.6
            C88.1,24.7,90.5,26.2,92.5,28.1z M149.2,36.6c1.2,3,1.8,6.2,1.8,9.5c0,3.4-0.6,6.6-1.8,9.5c-1.2,3-2.9,5.6-5,7.9
            c-2.1,2.3-4.7,4.1-7.7,5.5c-3,1.3-6.3,2-9.9,2c-2.8,0-5.7-0.7-8.5-2.1c-1.4-0.7-2.6-1.5-3.7-2.4v1.8c0,0.9-0.7,1.6-1.6,1.6h-10.4
            c-0.9,0-1.6-0.7-1.6-1.6V1.6c0-0.9,0.7-1.6,1.6-1.6h10.4c0.9,0,1.6,0.7,1.6,1.6v24.1c1.1-0.9,2.3-1.8,3.7-2.4
            c2.8-1.4,5.6-2.1,8.5-2.1c3.6,0,6.9,0.7,9.9,2c3,1.3,5.6,3.2,7.7,5.5C146.4,30.9,148,33.6,149.2,36.6z M24.9,58.6
            c1.7,0,3.3-0.3,4.7-1c1.4-0.7,2.7-1.6,3.6-2.7c1-1.1,1.8-2.5,2.4-4c0.6-1.5,0.9-3.2,0.9-4.9c0-1.7-0.3-3.4-0.9-4.9
            c-0.6-1.5-1.4-2.9-2.4-4c-1-1.1-2.2-2-3.6-2.7c-1.4-0.7-3-1-4.7-1c-1.7,0-3.3,0.3-4.7,1c-1.4,0.7-2.6,1.6-3.6,2.7
            c-1,1.1-1.8,2.4-2.3,4c-0.5,1.5-0.8,3.2-0.8,4.9c0,1.7,0.3,3.4,0.8,4.9c0.5,1.5,1.3,2.8,2.3,4c1,1.1,2.2,2,3.6,2.7
            C21.6,58.3,23.2,58.6,24.9,58.6z M66.6,40.5h19c-0.2-2.2-1.1-4-2.5-5.3c-1.7-1.6-3.9-2.4-6.7-2.4c-2.9,0-5.2,0.8-7.1,2.4
            C67.7,36.4,66.9,38.1,66.6,40.5z M126.1,33.5c-1.8,0-3.4,0.3-4.8,1c-1.4,0.7-2.6,1.6-3.6,2.7c-1,1.1-1.8,2.4-2.3,4
            c-0.5,1.5-0.8,3.2-0.8,4.9c0,1.7,0.3,3.4,0.8,4.9c0.5,1.5,1.3,2.8,2.3,4c1,1.1,2.2,2,3.6,2.7c1.4,0.7,3,1,4.8,1c1.7,0,3.3-0.3,4.7-1
            c1.4-0.7,2.6-1.6,3.6-2.7c1-1.1,1.8-2.4,2.3-4c0.5-1.5,0.8-3.2,0.8-4.9c0-1.7-0.3-3.4-0.8-4.9c-0.5-1.5-1.3-2.8-2.3-4
            c-1-1.1-2.2-2-3.6-2.7C129.4,33.9,127.8,33.5,126.1,33.5z"/>
        </symbol>

        <symbol id="quote" viewBox="0 0 49 40">
          <path transform="translate(-695.000000, -585.000000)" d="
      		M714.4,608.1c1.8,1.8,2.7,4,2.7,6.8c0,2.7-1,5-3,6.9c-2,1.9-4.5,2.8-7.7,2.9c-3.2,0-5.9-1.1-8-3.6c-2.2-2.4-3.2-5.6-3.2-9.6
      		c0-8.2,2.1-14.4,6.4-18.8c4.3-4.4,9-6.8,14.3-7.2v7.9c-3,0.5-5.6,2-7.8,4.5s-3.4,5.3-3.6,8.3c0.9-0.5,1.9-0.7,3-0.7
      		C710.3,605.4,712.6,606.3,714.4,608.1z M741.2,608.1c1.8,1.8,2.7,4,2.7,6.8c0,2.7-1,5-3,6.9c-2,1.9-4.6,2.8-7.8,2.9
      		c-3.2,0-5.9-1.1-8-3.6c-2.2-2.4-3.2-5.6-3.2-9.6c0-8.2,2.2-14.4,6.5-18.7c4.3-4.3,9.1-6.8,14.3-7.3v7.9c-2.9,0.5-5.5,2-7.7,4.5
      		s-3.4,5.3-3.5,8.3c0.9-0.5,1.9-0.7,2.9-0.7C737.1,605.4,739.4,606.3,741.2,608.1z"/>
        </symbol>

        <symbol id="magnifying-glass" viewBox="0 0 30 30">
          <path id="path-1_1_" d="M22,19.4l7.4,7.4c0.7,0.7,0.7,1.9,0,2.7s-1.9,0.7-2.7,0L19.4,22c-2,1.5-4.5,2.3-7.2,2.3
            C5.5,24.4,0,18.9,0,12.2S5.5,0,12.2,0s12.2,5.5,12.2,12.2C24.4,14.9,23.5,17.4,22,19.4z M20.6,12.2c0-4.7-3.8-8.4-8.4-8.4
            s-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4S20.6,16.8,20.6,12.2z"/>
        </symbol>

        <symbol id="instagram" viewBox="0 0 16 16">
          <path d="M10.5,8c0-0.7-0.2-1.3-0.7-1.8C9.3,5.7,8.7,5.5,8,5.5S6.7,5.7,6.2,6.2C5.7,6.7,5.5,7.3,5.5,8s0.2,1.3,0.7,1.8
            c0.5,0.5,1.1,0.7,1.8,0.7s1.3-0.2,1.8-0.7C10.3,9.3,10.5,8.7,10.5,8z M11.9,8c0,1.1-0.4,2-1.1,2.7C10,11.5,9.1,11.9,8,11.9
            s-2-0.4-2.7-1.1C4.5,10,4.1,9.1,4.1,8s0.4-2,1.1-2.7C6,4.5,6.9,4.1,8,4.1s2,0.4,2.7,1.1C11.5,6,11.9,6.9,11.9,8z M12.9,4
            c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.3S11.1,4.2,11.1,4s0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3
            c0.2,0,0.5,0.1,0.6,0.3C12.8,3.5,12.9,3.7,12.9,4z M8,1.8c0,0-0.3,0-0.7,0c-0.5,0-0.8,0-1,0s-0.5,0-0.9,0s-0.7,0-1,0.1
            C4,2,3.8,2.1,3.6,2.1C3.2,2.3,3,2.5,2.7,2.7C2.5,3,2.3,3.2,2.1,3.6C2.1,3.8,2,4,2,4.3c0,0.3-0.1,0.6-0.1,1s0,0.7,0,0.9s0,0.6,0,1
            c0,0.5,0,0.7,0,0.7s0,0.3,0,0.7c0,0.5,0,0.8,0,1s0,0.5,0,0.9s0,0.7,0.1,1c0,0.3,0.1,0.5,0.2,0.7c0.1,0.3,0.3,0.6,0.6,0.9
            c0.2,0.2,0.5,0.4,0.9,0.6C3.8,13.9,4,14,4.3,14c0.3,0,0.6,0.1,1,0.1c0.4,0,0.7,0,0.9,0s0.6,0,1,0c0.5,0,0.7,0,0.7,0s0.3,0,0.7,0
            c0.5,0,0.8,0,1,0s0.5,0,0.9,0c0.4,0,0.7,0,1-0.1c0.3,0,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.6-0.9
            c0.1-0.2,0.1-0.4,0.2-0.7c0-0.3,0.1-0.6,0.1-1c0-0.4,0-0.7,0-0.9s0-0.6,0-1c0-0.5,0-0.7,0-0.7s0-0.3,0-0.7c0-0.5,0-0.8,0-1
            s0-0.5,0-0.9c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.3-0.3-0.6-0.6-0.9c-0.2-0.2-0.5-0.4-0.9-0.6C12.2,2.1,12,2,11.7,2
            c-0.3,0-0.6-0.1-1-0.1s-0.7,0-0.9,0s-0.6,0-1,0C8.3,1.8,8,1.8,8,1.8z M15.5,8c0,1.5,0,2.5,0,3.1c-0.1,1.4-0.5,2.4-1.2,3.1
            c-0.7,0.7-1.8,1.1-3.1,1.2c-0.6,0-1.6,0-3.1,0s-2.5,0-3.1,0c-1.4-0.1-2.4-0.5-3.1-1.2c-0.7-0.7-1.1-1.8-1.2-3.1c0-0.6,0-1.6,0-3.1
            s0-2.5,0-3.1C0.6,3.5,1,2.5,1.8,1.8C2.5,1,3.5,0.6,4.9,0.5c0.6,0,1.6,0,3.1,0s2.5,0,3.1,0c1.4,0.1,2.4,0.5,3.1,1.2
            c0.7,0.7,1.1,1.8,1.2,3.1C15.5,5.5,15.5,6.5,15.5,8z"/>
        </symbol>

        <symbol id="twitter" viewBox="0 0 16 13">
          <path d="M15.5,2c-0.4,0.6-1,1.2-1.6,1.6c0,0.1,0,0.2,0,0.4c0,0.8-0.1,1.7-0.4,2.5S12.9,8.2,12.4,9s-1.1,1.5-1.8,2.1
          	s-1.5,1.1-2.5,1.4c-1,0.4-2,0.5-3.2,0.5c-1.8,0-3.4-0.5-4.8-1.4c0.2,0,0.5,0,0.8,0c1.5,0,2.8-0.4,3.9-1.3c-0.7,0-1.3-0.2-1.8-0.6
          	S2,8.7,1.8,8.1c0.2,0,0.4,0,0.6,0c0.3,0,0.6,0,0.8-0.1C2.5,7.9,1.9,7.5,1.4,7s-0.7-1.2-0.7-2v0c0.4,0.2,0.9,0.4,1.4,0.4
          	C1.7,5,1.4,4.6,1.1,4.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.6,0.1-1.1,0.4-1.6c0.8,1,1.7,1.7,2.9,2.3c1.1,0.6,2.3,0.9,3.6,1
          	C7.6,4.1,7.6,3.9,7.6,3.7c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.4-0.9,2.2-0.9c0.9,0,1.7,0.3,2.3,1c0.7-0.1,1.4-0.4,2-0.8
          	c-0.2,0.7-0.7,1.3-1.4,1.7C14.3,2.4,14.9,2.3,15.5,2z"/>
        </symbol>

        <symbol id="facebook" viewBox="0 0 10 17">
          <path d="M9.1,0.1v2.6H7.6C7,2.7,6.7,2.8,6.5,3.1C6.3,3.3,6.2,3.6,6.2,4.1V6H9L8.7,8.9H6.2v7.4h-3V8.9H0.7V6h2.5V3.8
          c0-1.2,0.3-2.2,1-2.8c0.7-0.7,1.6-1,2.7-1C7.9,0,8.6,0,9.1,0.1z"/>
        </symbol>

        <symbol id="setting-icon" viewBox="0 0 20 20">
          <path d="M19.642,12.637L17.6,10.9a6.967,6.967,0,0,0,0-2.073l2.044-1.738a0.415,0.415,0,0,0,.091-0.521L17.872,3.295a0.4,0.4,0,0,0-.489-0.181l-2.5.928a7.225,7.225,0,0,0-1.77-1.034L12.653,0.34a0.407,0.407,0,0,0-.4-0.34H8.533a0.407,0.407,0,0,0-.4.34L7.674,3.008A7.226,7.226,0,0,0,5.9,4.042L3.4,3.114a0.4,0.4,0,0,0-.489.181L1.055,6.569a0.415,0.415,0,0,0,.091.521L3.189,8.827a6.973,6.973,0,0,0,0,2.073L1.145,12.637a0.415,0.415,0,0,0-.091.521l1.861,3.274a0.4,0.4,0,0,0,.489.181l2.5-.928a7.227,7.227,0,0,0,1.77,1.034l0.46,2.668a0.407,0.407,0,0,0,.4.34h3.722a0.406,0.406,0,0,0,.4-0.34l0.46-2.668a7.227,7.227,0,0,0,1.77-1.034l2.5,0.928a0.4,0.4,0,0,0,.489-0.181l1.861-3.274A0.415,0.415,0,0,0,19.642,12.637Zm-9.248,1.336a4.11,4.11,0,1,1,4.046-4.11A4.083,4.083,0,0,1,10.394,13.973Z"/>
        </symbol>

        <symbol id="manage-user" viewBox="0 0 20 20">
          <path d="M9.719,0a6.487,6.487,0,0,1,2.542.312,4.941,4.941,0,0,1,2.7,6.31,5.21,5.21,0,0,1-3.812,2.96,5.262,5.262,0,0,1-2.3-.156C6.858,8.78,4.488,6.013,5.748,3.04A5.3,5.3,0,0,1,8.29.392,10.99,10.99,0,0,0,9.719,0ZM5.748,9.662C7.654,9.651,8.2,10.773,9.8,11.142c2.543,0.586,3.634-1.931,5.48-1.48,2.688,0.656,2.963,2.518,3.574,5.219a5.278,5.278,0,0,1-.4,3.661c-1.3,1.89-5.145,1.4-8.34,1.4-4.7,0-8.392.674-8.1-4.362L2.174,14.1a9.84,9.84,0,0,1,.635-2.025,4.7,4.7,0,0,1,1.906-2.1C5.039,9.811,5.453,9.85,5.748,9.662Z"/>
        </symbol>
      </svg>
    );
  }
}

export default SVGSprite;
