import React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    marginTop: "10%",
    padding: "30px",
  },
  middle: {
    margin: "auto",
  },
});

function EmailVerifyError(params) {
  const classes = useStyles();

  return (
    <Container
      maxWidth="md"
      component={Paper}
      elevation={12}
      className={classes.root}
    >
      <Grid container spacing={2} item xl={12} lg={12} mg={12} sm={12} xs={12}>
        <Grid
          item
          xl={12}
          lg={12}
          mg={12}
          sm={12}
          xs={12}
          align="center"
          className="p-5"
        >
          <Typography
            variant="h4"
            style={{ color: "#cd5c5c" }}
          >{`Email Verification Error`}</Typography>
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          mg={12}
          sm={12}
          xs={12}
          align="left"
          className="p-5"
        >
          <Typography variant="h6">
            MakeGood supports using your Intuit account to sign up for a
            MakeGood account. However, Intuit requires your email address to be
            validated before using MakeGood’s integration with QuickBooks
            Online. Please use the link here to validate your Intuit account
            email address:
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          mg={12}
          sm={12}
          xs={12}
          align="center"
          className="p-5"
        >
          <Typography variant="h6">
            <a href="https://accounts.intuit.com/app/account-manager/security">
              https://accounts.intuit.com/app/account-manager/security{" "}
            </a>{" "}
            <br /> Contact{" "}
            <a href="mailto:support@makegoodapp.com">support@makegoodapp.com</a>{" "}
            for further assistance.
          </Typography>
        </Grid>
        <Grid className={classes.middle}>
          <button
            style={{
              padding: " 15px 30px",
              borderRadius: "10px",
              backgroundColor: "#0ab894",
              color: "#ffffff",
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Open Sans",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Okay
          </button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EmailVerifyError;
