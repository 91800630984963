import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// Components
import Modal from '../Modal';


class UserAccountPaymentSettledModal extends Component {

  handleModalClose = () => {
    this.props.hideModal();
    this.props.history.push("/dashboard/accounts");
  }

  render() {
    const { paymentComplete, is_settled } = this.props.optionalProps;

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="user-account-payment-settled"
        closeOnClickOutside={false}
        modalExit={false}
      >
        <div className="modal-spotlight-illustration"></div>
        {(paymentComplete && !is_settled) ?
          <div>
            <h1 className="modal-heading">
              Thank you!
              </h1>
            <p style={{ textAlign: 'center' }}>
              We have received your payment!
              </p>
          </div> :
          <div>
            <h1 className="modal-heading">
              Thank you for settling your account!
                </h1>
            <p style={{ textAlign: 'center' }}>
              We have received your payment, and your account is now settled!
                </p>
          </div>
        }
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.handleModalClose}
          >
            {(paymentComplete && !is_settled) ?
              'OK' : 'Done'
            }
          </a>
        </div>
      </Modal>
    )
  }
}

export default withRouter(UserAccountPaymentSettledModal);