import React, { Component } from "react";
import Panel from "../../components/App/Panel";
import moment from "moment";

class ClientsDashboardSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: 0,
    };
  }

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY") + " - ";
    }
    return "";
  };

  emptyView = (activities) => {
    return (
      <div className="empty-activity">
        <p>There are currently no {activities} to review.</p>
      </div>
    );
  };

  openInvoiceListModal = (item) => {
    const { user } = this.props;
        let props = {
            user: user,
            invoice_list: item.invoice_list && item.invoice_list.length ? item.invoice_list : []
        };
        if(item.invoice_list && item.invoice_list.length )
        this.props.updateModalState(true, "SHOW_INVOICE_LIST", props);
  }

  render() {
    const {
      title,
      optionalClassNames,
      upcoming_activities,
      recent_activities,
    } = this.props;

    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar">
        <div
          className={`app-section-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <div className="app-section-header-content">
            <h1 className="app-section-header-heading side-hed-col">{title}</h1>
          </div>
        </div>
        <div>
          <div
            className="panel-heading-secondary"
            style={{ marginBottom: "1em" }}
          >
            Activity Feed
          </div>
          <Panel
            heading="Upcoming Activities"
            optionalClasses="activity-panel scrollbar"
          >
            {upcoming_activities && upcoming_activities.length ? (
              <div className="content activity-text ">
                <ul>
                  {upcoming_activities.map((item, index) => {
                    return (
                      <div
                        key={"up" + index.toString()}
                        className={`${index % 2 == 0 ? "alt-row" : ""}`}
                      >
                        <li>{this.getDate(item.due_date) + item.text}</li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            ) : (
              this.emptyView("Upcoming Activities")
            )}
          </Panel>
          <Panel
            heading="Recent Activities"
            optionalClasses="activity-panel scrollbar"
          >
            {recent_activities && recent_activities.length ? (
              <div className="content activity-text recent-activity-text">
                <ul>
                  {recent_activities.map((item, index) => {
                    return (
                      <div
                        key={"rec" + index.toString()}
                        className={`${index % 2 == 0 ? "alt-row" : ""} ${item && item.invoice_list && item.invoice_list.length ? 'list-li' : ''}`}
                        onClick={() => this.openInvoiceListModal(item)}
                      >
                        <li>{this.getDate(item.due_date) + item.text}</li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            ) : (
              this.emptyView("Recent Activities")
            )}
          </Panel>
        </div>
      </div>
    );
  }
}

export default ClientsDashboardSidebar;
