import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import Panel from "../../components/App/Panel";
import CardView from "../../components/CardView/CardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";
import { showToast } from "../../utils/helpers";

class SuperAdminAssignOrg extends Component {
  state = {
    orgList: [],
    otherTaskList: [],
    orgTemplates: [],
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    this.getTaskList();
    this.orgTemplatesAssigned();
    this.getOrgTemplateData();
  }

  getTaskList = () => {
    const { accountId, user } = this.props;
    API.getOrganizationTaskList(user, accountId).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.setState({ orgList: data.data });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  orgTemplatesAssigned = () => {
    const { accountId, user } = this.props;
    API.orgTemplatesAssigned(user, accountId).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.setState({ otherTaskList: data.data });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getOrgTemplateData = () => {
    let { accountId, user } = this.props;
    API.getOrganizationTaskProfileTemp(user, accountId).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          this.mapDataForOptions(data.data);
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  callUpdateApi(data) {
    let { accountId, user } = this.props;
    let payload = {
      template_id: data.template_id,
      organization_id: accountId,
      tmp_type: data.tmp_type,
      tmp_name: data.tmp_name,
      tmp_slug: data.tmp_slug,
    };
    API.updateAssignTemplates(user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  mapDataForOptions = (data) => {
    let { orgTemplates } = this.state;
    if (data && data.length) {
      data.map((item) => {
        if (item.method_name == "Email") {
          if (item.templates && item.templates.length) {
            item.templates.map((i) => {
              i.value = i.slug;
              i.label = i.name;
              orgTemplates.push(i);
            });
          }
        }
      });
      this.setState({ orgTemplates });
    }
  };

  rowExpanderClick = (event) => {
    const { accountId } = this.props;
    let props = {
      taskData: event.rowData,
      accountId: accountId,
      reload: this.getTaskList,
    };
    this.props.updateModalState(true, "ADMIN_ASSIGN_TASK", props);
  };

  cancelAssignedTemp = (item) => {
    let { user } = this.props;
    let payload = {
      task_template_id: item.task_template_id,
    };
    API.unAssignTemplates(user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.getTaskList();
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getTemplateMergeVar = (rowData) => {
    const { accountId, user } = this.props;
    let props = {
      user: user,
      orgId: accountId,
      slugName:
        rowData && rowData.templates && rowData.templates.length
          ? rowData.templates[0].slug
          : "",
    };
    this.props.updateModalState(true, "TASK_TEMPLATE_PREVIEW", props);
  };

  getTemplates = (row) => {
    if (row.templates && row.templates.length) {
      return (
        <div>
          {row.templates.map((item, index) => {
            return (
              <div
                className='template-col-name'
                style={{ marginBottom: "0.5em", whiteSpace: "break-spaces" }}
                key={"__" + index}
              >
                <div
                  style={{
                    marginTop: 1,
                    wordBreak: "break-all",
                    paddingRight: 4,
                  }}
                >
                  {item.method_name + " - " + item.name}
                </div>
                <span
                  style={{ flex: "none", margin: "auto" }}
                  className='assigned-tem-card-cross'
                  onClick={() => this.cancelAssignedTemp(item)}
                >
                  x
                </span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  showPreview = (rowData) => {
    if (rowData.templates && rowData.templates.length) {
      return (
        <a
          style={{
            display: "flex",
            height: "100%",
            width: "30px",
            paddingTop: 15,
          }}
          className={`object-row-expander`}
          onClick={(event) => this.getTemplateMergeVar(rowData)}
        >
          <svg className='icon object-row-view'>
            <use xlinkHref='#view' />
          </svg>
        </a>
      );
    } else {
      return <div style={{ display: "flex", width: "30px" }}></div>;
    }
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Name",
        style: { width: "20%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Status",
        style: { width: "20%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Assigned Templates",
        style: { width: "20%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "View",
        style: { width: "20%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { width: 0, display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((row, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Name",
            title: row.name,
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Status",
            title: <ToggleSwitch isOn={row.status} />,
            style: { width: "20%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Assigned Templates",
            title: this.getTemplates(row),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "View",
            title: this.showPreview(row),
            style: { width: "20%", display: "flex", justifyContent: "center" },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        rowData: row,
      };
    });

    return {
      headings,
      columns,
    };
  };

  render() {
    const { orgTemplates, orgList, otherTaskList, isLoading, error } =
      this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='app-sidebar-layout-canvas'>
        <div className='app-sidebar-layout-canvas-content'>
          <div className='he-hed admin-task-header accounts-view-heading'>
            Assign Templates to Task
          </div>
          {orgList && orgList.length ? (
            <Panel>
              <CardView
                data={this.getObjectListData(orgList)}
                rowActionClick={(event) => this.rowExpanderClick(event)}
              />
              <InvoiceObjectList
                data={this.getObjectListData(orgList)}
                hideForMobile={true}
                optionalTableClasses='task-template-list'
                rowActionClick={(event) => this.rowExpanderClick(event)}
              />
            </Panel>
          ) : (
            <div className='object-list-empty-state'>
              <span className='object-list-empty-state-message'>
                There are currently no tasks to assign.
              </span>
            </div>
          )}
          <div className='he-hed admin-task-header accounts-view-heading'>
            Assign Templates to Organization
          </div>
          <Panel>
            <div>
              {otherTaskList && otherTaskList.length ? (
                otherTaskList.map((item, index) => {
                  return (
                    <div
                      className={`input-container`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label
                        htmlFor='state'
                        style={{ width: "50%", marginBottom: "-1em" }}
                      >
                        {item.name}
                      </label>
                      <Select
                        inputProps={{
                          autoComplete: "none",
                          autoCorrect: "off",
                          spellCheck: "off",
                        }}
                        name='state'
                        resetValue=''
                        className='form-select form-select-inline'
                        value={item.tmp_slug}
                        placeholder='Select a template'
                        options={orgTemplates}
                        onChange={(event) => {
                          item.tmp_name = event.label;
                          item.tmp_slug = event.value;
                          this.setState({ otherTaskList }, () => {
                            this.callUpdateApi(item);
                          });
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className='object-list-empty-state'>
                  <span className='object-list-empty-state-message'>
                    There are currently no tasks to assign.
                  </span>
                </div>
              )}
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

export default SuperAdminAssignOrg;
