import React, { Component } from 'react';
let item1 = ""
class ExpanderSet extends Component {
  constructor(props){
	  super(props);
  }
  
  state = {
    expanderItems: [
      {
        id: "0",
        isActive: true,
        heading: "Can I really decide how much I pay?",
        body: "Absolutely, "+this.props.appName+" gives you complete control over what you pay, and when. So you decide what works for you based on your circumstances. Our one-off fixed payment option gives you the chance to make us an offer and clear your delinquent account in one go. While our monthly payment plan is great for those looking to make regular payments, and pay off their balance a bit at a time. Join "+this.props.appName+" and make us an offer to start negotiating today!"
      },
      {
        id: "1",
        isActive: false,
        heading: "Can "+this.props.appName+" reject my offer?",
        body: "We pride ourselves on being the most flexible payment option for your account. While we’ll accept any monthly agreement over a dollar, for quick settlement accounts we use a load of mathematical equations, algorithms, and business(y) factors to make sure that we can still keep the lights on and help other consumers like you. We do love the negotiation process and want to make sure you feel like you’re getting a fair deal -- so go ahead and throw an offer at us!"
      },
      {
        id: "2",
        isActive: false,
        heading: "What’s the catch? Are there hidden costs or late payment fees?",
        body: "Zilch. Zero. Nada. "+this.props.appName+" was built to provide consumers like you an experience never before seen in the industry. We believe in full transparency--we don’t believe in tacking on fees, or hidden costs to fatten up our wallets. When you settle an account with us you will have full documentation of the exact amount you owed and what you settled for. We’ve been and in some cases are still in your shoes, so we understand that it’s stressful enough paying off accounts that got away from you without having to deal with added fees mounted on top."
      }
    ]
  }

  handleExpanderItemClick = (event) => {

    let clickedIndex = event.target.getAttribute('data-item-number');

    let newExpanderItems = this.state.expanderItems;

    for (let item of newExpanderItems) {
      if (item.id === clickedIndex) {
        item.isActive = !item.isActive;
      } else {
        item.isActive = false;
      }
    }

    this.setState(state => ({
      ...state,
      expanderItems: newExpanderItems
    }))
  }

  render() {

    let expanderList = this.state.expanderItems.map((item) =>
      <div
        className={`expander-set-item${item.isActive ? ' active': '' }`}
        key={item.id}
      >
        <div
          className="expander-set-item-header"
          onClick={(event) => this.handleExpanderItemClick(event)}
          data-item-number={item.id}
        >
          <div className="button" data-item-number={item.id}></div>
          <h3 data-item-number={item.id}>
            {item.heading}
          </h3>
        </div>
        <div className="expander-set-item-body">
          <p>
            {item.body}
          </p>
        </div>
      </div>
    )

    return (
      <div className="expander-set">
        {expanderList}
      </div>
    );
  }
}

export default ExpanderSet;
