import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import styles from "../../CustomTable/DynamicTable.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";

class HistoryObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.sortHeaderStyle = (index, header) => {
      return {
        display: "flex",
        flexWrap: "nowrap",
        minWidth: "max-content",
        justifyContent:
          index == 0 ||
          header.includes("Send Date") ||
          header.includes("Ex.Delivery Date") ||
          header.includes("From") ||
          header.includes("To") ||
          header === "Scheduled" ||
          header === "Template Name" ||
          header === "Receiver Email" ||
          header.includes("Date/Time")
            ? "flex-start"
            : (header.includes("Rate") ||
                header.includes("Amt") ||
                header.includes("Amount") ||
                header.includes("Total") ||
                header.includes("Discount") ||
                header.includes("Balance") ||
                header.includes("Number")) &&
              index !== 0
            ? "flex-end"
            : "center",
        alignItems: "center",
      };
    };
    // this.headerStyle = (index, header, data) => {
    //   return {
    //     marginLeft: header === "Item Total" && "2%",
    //     width:
    //       header === "" || header === "expander"
    //         ? "20px"
    //         : `${100 / data.headings.length}%`,
    //     justifyContent:
    //       index == 0 ||
    //       header.includes("Send Date") ||
    //       header.includes("Ex.Delivery Date") ||
    //       header.includes("From") ||
    //       header.includes("To") ||
    //       header === "Scheduled" ||
    //       header === "Template Name" ||
    //       header === "Receiver Email"
    //         ? "flex-start"
    //         : (header.includes("Rate") ||
    //             header.includes("Amt") ||
    //             header.includes("Amount") ||
    //             header.includes("Total") ||
    //             header.includes("Discount") ||
    //             header.includes("Balance") ||
    //             header.includes("Number")) &&
    //           index !== 0
    //         ? "flex-end"
    //         : "center",
    //     alignItems:
    //       index == 0
    //         ? "left"
    //         : (header.includes("Rate") ||
    //             header.includes("Amt") ||
    //             header.includes("Amount") ||
    //             header.includes("Total") ||
    //             header.includes("Discount") ||
    //             header.includes("Balance") ||
    //             header.includes("Number")) &&
    //           index !== 0
    //         ? "right"
    //         : "center",
    //   };
    // };
    // this.contentStyle = (index, data, col) => {
    //   return {
    //     whiteSpace: col.key === "Tax" && "nowrap",
    //     marginLeft: col.key === "Item Total" && "2%",
    //     width:
    //       col.key === "" || col.key.includes("expander")
    //         ? "20px"
    //         : `${100 / data.headings.length}%`,

    //     justifyContent:
    //       index == 0 ||
    //       col.key.includes("Send Date") ||
    //       col.key.includes("Ex.Delivery Date") ||
    //       col.key.includes("From") ||
    //       col.key.includes("To") ||
    //       col.key === "Scheduled" ||
    //       col.key === "Template Name" ||
    //       col.key === "Receiver Email"
    //         ? "flex-start"
    //         : (col.key.includes("Rate") ||
    //             col.key.includes("Amt") ||
    //             col.key.includes("Amount") ||
    //             col.key.includes("Total") ||
    //             col.key.includes("Discount") ||
    //             col.key.includes("Balance") ||
    //             col.key.includes("Number")) &&
    //           index !== 0
    //         ? "flex-end"
    //         : "center",
    //     textAlign: col.key.includes("Pymt Method") ? "center" : "",
    //     alignItems:
    //       index == 0
    //         ? "left"
    //         : (col.key.includes("Rate") ||
    //             col.key.includes("Amt") ||
    //             col.key.includes("Amount") ||
    //             col.key.includes("Total") ||
    //             col.key.includes("Discount") ||
    //             col.key.includes("Balance") ||
    //             col.key.includes("Number")) &&
    //           index !== 0
    //         ? "right"
    //         : "center",
    //   };
    // };
  }

  checkPrimaryColor = (props) => {
    return props.hasOwnProperty("appThemeData")
      ? props.appThemeData.hasOwnProperty("primary_color")
        ? props.appThemeData.primary_color
        : "#0ab894"
      : "#0ab894";
  };

  handleExpanderClick = (event, rowData) => {
    event.preventDefault();
    this.props.rowActionClick(rowData);
  };

  showOnlyHeader = (header, index) => {
    return (
      header === "Paid Amount" ||
      header === "Discount" ||
      header === "Auto Scheduled" ||
      header === "Admin Name" ||
      header === "Customer ID" ||
      header === ""
    );
  };

  renderRowExpander = (rowData, col) => {
    let { fromConsumer } = this.props;
    let type = col.icon;
    if (type && type === "up-down") {
      return (
        <a
          key={shortid.generate()}
          className={`client-caret expander-caret ${
            col.isExpanded ? " down-caret" : ""
          }${fromConsumer ? " consumer-brand" : ""}`}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        ></a>
      );
    } else if (type && type === "eye") {
      return (
        <a
          key={shortid.generate()}
          className={`object-row-expander`}
          style={{
            margin: "1.2rem 0.2rem 0 0",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        >
          <svg className='icon object-row-view'>
            <use xlinkHref='#view' />
          </svg>
        </a>
      );
    } else {
      if (rowData.rowData === "static") {
        return (
          <a
            key={shortid.generate()}
            className='client-caret expander-caret-big-arrow'
            onClick={(event) => this.handleExpanderClick(event, rowData)}
          ></a>
        );
      } else {
        return (
          <td>
            <a
              key={shortid.generate()}
              className='client-caret expander-caret'
              onClick={(event) => this.handleExpanderClick(event, rowData)}
              style={{
                margin: "0.5rem 0",
                display: "flex",
                borderTop: `0.8em solid ${this.checkPrimaryColor(this.props)}`,
              }}
            ></a>
          </td>
        );
      }
    }
  };

  renderPrimaryColumn = (primaryColumn) => {
    let primaryContent = (
      <div className='object-row-header-name primary-column-title'>
        {primaryColumn.title}
      </div>
    );

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div className='object-row-header-secondary-info primary-column-info'>
        {primaryColumn.secondaryInfo}
      </div>
    ) : null;

    return (
      <div className={styles.headingStyle}>
        {primaryContent}
        {secondaryContent}
      </div>
    );
  };

  showSortContent = (header, index) => {
    const { sortHeaders } = this.props;
    if (header === sortHeaders[0]) {
      return (
        <div
          className={"caret-header-title"}
          onClick={() => this.props.sortInvoiceData(header, !sortHeaders[1])}
        >
          <div style={this.sortHeaderStyle(index, header)}>
            <div className={styles.headingStyle}>{header}</div>
            {!this.props.hasOwnProperty("hideSort") && (
              <div
                id='table-header-caret'
                style={{ marginLeft: "4px" }}
                className={`client-caret ${
                  sortHeaders[1] ? " client-caret-up" : ""
                }`}
                // style={{ marginLeft: "0.4em" }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className='caret-header-title'
          onClick={() => this.props.sortInvoiceData(header, true)}
        >
          <div style={this.sortHeaderStyle(index, header)}>
            <div className={styles.headingStyle}> {header}</div>
            {header !== "Action" && !this.props.hasOwnProperty("hideSort") && (
              <div
                id='table-header-caret'
                className={`client-caret`}
                // style={{ marginLeft: "0.4em" }}
              />
            )}
          </div>
        </div>
      );
    }
  };

  render() {
    let {
      data,
      isStatus,
      isBeforeIcon,
      isClose,
      hideForMobile,
      optionalClasses,
      optionalTableClasses,
      scrollRef,
      sortHeaders,
      isPrimaryColumn,
      isPaymentPlansData,
    } = this.props;
    let status_style = isStatus ? " header-status-space" : "";
    let primary_col_style = isPrimaryColumn ? " primary-column" : "";
    return (
      <div ref={scrollRef} className={"scrollbar in-sc"}>
        <Table
          hover
          striped
          className={
            // "history-object-list"}
            " mobile-hide-list"
          }
        >
          <thead className={styles.headerFix}>
            <tr className={styles.headingStyle}>
              {isBeforeIcon && <th style={{ flex: 0, marginRight: "20px" }} />}
              {data.headings &&
                data.headings.map((header, index) => {
                  return (
                    <th
                      key={shortid.generate()}
                      className={
                        // index == 0
                        //   ? status_style
                        //   :
                        header === "expander"
                          ? " row-expander"
                          : styles.headingStyle
                      }
                      style={{ backgroundColor: "#DADADA", borderTop: "none" }}
                      // style={this.headerStyle(index, header, data)}
                    >
                      {header === "expander"
                        ? ""
                        : sortHeaders
                        ? header === "expander"
                          ? null
                          : this.showOnlyHeader(header, index)
                          ? header
                          : this.showSortContent(header, index)
                        : header}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody
            ref={scrollRef}
            className='scrollbar in-sc'
            // className={`${optionalClasses ? optionalClasses : ""}`}
          >
            {data.columns &&
              data.columns.map((item, index) => {
                return (
                  <tr key={shortid.generate()}>
                    {/* {isStatus ? (
                    <td
                      className={`status-point ${
                        isClose || item.taskStatus
                          ? "col3"
                          : item.status
                          ? "col1"
                          : "col2"
                      }`}
                    />
                  ) : isBeforeIcon ? (
                    item.icon
                  ) : (
                    ""
                  )} */}
                    {item.primaryColumn && (
                      <td key={shortid.generate()} className={styles.rowData}>
                        {this.renderPrimaryColumn(item.primaryColumn)}
                      </td>
                    )}
                    {item.secondaryColumns &&
                      item.secondaryColumns.map((col, index) => {
                        if (col.rowType && col.rowType === "arrow-click") {
                          return this.renderRowExpander(item, col);
                        } else if (col.rowType && col.rowType === "hide-task") {
                          return <div style={{ width: 12 }} />;
                        }
                        return (
                          <td
                            key={shortid.generate()}
                            className={`${styles.rowData} ${
                              col.key === "Status" && col.optionalClasses
                            }`}
                            // className={col.optionalClasses}
                            // style={
                            //   col.hasOwnProperty("key")
                            //     ? this.contentStyle(index, data, col)
                            //     : {}
                            // }
                          >
                            {col.title}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default HistoryObjectList;
