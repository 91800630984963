import $ from "jquery";
import React, { Component } from "react";
import moment from "moment";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
// Packages
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast, validateEmail } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import RefundAmountModal from "./RefundAmountModal";

class RefundPaymentModal extends Component {
  state = {
    refundList: [],
    currentRow: {},
    showRefundModal: false,
    isLoading: true,
    responseMessage: "",
    showMessage: false,
    isSuccess: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    //get invoice id from invoice details page
    let invoice_id = this.props.optionalProps.invoiceDetails.id;
    //get list of payments to refund
    this.getRefundList(invoice_id);
  }

  getRefundList = (invoice_id) => {
    API.getRefundList(this.props.user, invoice_id).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.error) {
          showToast(data.error);
        } else {
          if (data.data) {
            this.setState({ refundList: data.data });
          }
          this.setState({ isLoading: false });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          isLoading: false,
        }));
      }
    });
  };

  //on refund action open modal form to refund payment
  refundPayment = (event, row) => {
    event.preventDefault();
    this.setState({ currentRow: row, showRefundModal: true });
  };

  //close popup modal
  handleModalClose = () => {
    this.props.hideModal();
  };

  //hide refund payment modal
  hideRefundModal = () => {
    this.setState({ showRefundModal: false, currentRow: {} });
  };

   //render table headers
  getHeaders = () => {
    return (
      <div style={StyleSheet.headerView}>
        <h4 style={StyleSheet.headerLabel}>Date</h4>
        <h4 style={StyleSheet.headerLabel1}>Amount</h4>
        <h4 style={StyleSheet.headerLabel1}>Method</h4>
        <h4 style={StyleSheet.headerLabel1}>Action</h4>
      </div>
    );
  };

  render() {
    let {
      error,
      isLoading,
      refundList,
      isSuccess,
      currentRow,
      showMessage,
      showRefundModal,
      responseMessage,
    } = this.state;
    let { optionalProps } = this.props;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (showRefundModal) {
      return (
        <RefundAmountModal
          user={this.props.user}
          currentRow={currentRow}
          reloadPage={optionalProps.reloadData}
          hideModal={this.hideRefundModal}
          invoiceDetails={this.props.optionalProps.invoiceDetails}
          updateModalState={this.props.updateModalState}
        />
      );
    }

    return (
      <Modal
        optionalClasses="add-account-modal send-new-email-modal scroll ce-mo"
        title="Refund Payment"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {showMessage ? (
          <div style={{ marginTop: "7em" }}>
            <div
              className="flash"
              style={
                isSuccess ? { justifyContent: "center" } : StyleSheet.success
              }
            >
              {responseMessage}
            </div>
          </div>
        ) : isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div style={{ marginTop: "0.5em" }}>
            {refundList && refundList.length ? this.getHeaders() : null}
            {refundList && refundList.length ? (
              refundList.map((row, i) => {
                return (
                  <div key={"_" + i} style={StyleSheet.tableBody}>
                    <div style={StyleSheet.dateValue}>
                      {row.date ? moment(row.date).format("MM/DD/YYYY") : "n/a"}
                    </div>
                    <div style={StyleSheet.fullWidth}>
                      ${row.amount ? parseFloat(row.amount).toFixed(2) : "0.00"}
                    </div>
                    <div style={StyleSheet.fullWidth}>{row.payment_type}</div>
                    <div className="in-new-btn" style={StyleSheet.fullWidth}>
                      <button
                        id={`refund${row.id}`}
                        style={StyleSheet.refundBtn}
                        onClick={(event) => this.refundPayment(event, row)}
                      >
                        Refund
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="admin-admins-empty-state"
                style={StyleSheet.empty}
              >
                <p>No payments available to refund.</p>
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

let StyleSheet = {
  headerView: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
  },
  tableBody: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e1e1e1",
    borderRadius: 4,
    marginBottom: "1em",
    padding: "0.5em",
  },
  headerLabel: {
    margin: "0px 10px 0px 0px",
    width: "100%",
    fontSize: "1em",
    color: "#9b9b9b",
  },
  headerLabel1: {
    margin: 0,
    width: "100%",
    fontSize: "1em",
    color: "#9b9b9b",
  },
  success: {
    backgroundColor: "#fb5c82",
    borderColor: "#fb5c82",
    justifyContent: "center",
  },
  empty: {
    padding: "2rem 2rem 1rem 2rem",
    backgroundColor: "#FFF",
  },
  refundBtn: { padding: "1em 2em 0.8em", margin: 0, fontWeight: 600 },
  fullWidth: { margin: 0, width: "100%" },
  dateValue: { margin: "0px 10px 0px 0px", width: "100%" },
};

export default withRouter(RefundPaymentModal);
