import React, { Component } from "react";
import Modal from "../Modal";
class WarningMessageModal extends Component {
  hideToast = () => {
    this.props.hideModal();
  };

  handleSubmitOnclick = () => {
    this.props.optionalProps.handleSubmit();
    this.hideToast();
  };

  render() {
    let { message, title } = this.props.optionalProps;

    return (
      <>
        (
        <Modal
          style={{ height: "50%", width: "50%", zIndex: 5 }}
          // title={"Failed!"}
          hideModal={this.props.hideModal}
          closeOnClickOutside={true}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "70px",
              color: "black",
              fontSize: "18px",
            }}
          >
            {message}
          </p>
          <div className="modal-actions-default">
            <a
              className="cta cancel"
              style={{
                borderRadius: "0.5em",
                width: "140px",
                height: "50px",
                float: "left",
                backgroundColor: "#ffffff",
                border: "2px solid #000000",
                color: "#000000",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 600,
              }}
              onClick={this.hideToast}
            >
              Cancel
            </a>
            <a
              className="cta mg-brand2-color"
              style={{
                borderRadius: "0.5em",
                width: "140px",
                height: "50px",
                float: "right",
              }}
              onClick={
                this.props.optionalProps.handleSubmit
                  ? () => {this.props.optionalProps.handleSubmit(); this.hideToast()}
                  : this.hideToast
              }
            >
              Continue
            </a>
          </div>
        </Modal>
        )
      </>
    );
  }
}

export default WarningMessageModal;
