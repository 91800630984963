import React, { Component } from "react";
import { SketchPicker } from "react-color";
import Popup from "reactjs-popup";
// Components
import Panel from "../App/Panel";

class SuperAdminTemplateSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showColorPicker: false,
      selectedSet: "",
      logoFile: "",
    };
    this.imageUploader = React.createRef();
  }

  onfocusOut = (payload) => {
    this.props.updateMergeVariables(payload);
  };

  getFiles = () => {
    let files = this.state.logoFile;
    let imgExtension = files.name.split(".")[1];
    let extensionFlag = false;
    if (imgExtension.toLowerCase() === "png") {
      extensionFlag = true;
    } else if (files.size > 1000000) {
      extensionFlag = false;
    }
    if (extensionFlag) {
      var data = new FormData();
      var imagedata = files;
      data.append("data", imagedata);
      this.props.updateLogo(data);
      this.setState({ logoFile: "" });
    }
  };

  handleFileChange = (e) => {
    let files = e.target.files;
    if (files && files.length) {
      this.setState({ logoFile: files[0] });
    }
  };

  handleBrowseClick = () => {
    if (this.imageUploader && this.imageUploader.current) {
      this.imageUploader.current.click();
    }
  };

  getBoxHeader = (header) => {
    return (
      <div className="admin-config-header" style={{ marginBottom: "-1.5em" }}>
        {header}
      </div>
    );
  };

  toggleColorPicker = (colorData) => {
    let id = this.state.showColorPicker ? "" : colorData.merge_variable_id;
    this.setState(
      {
        showColorPicker: !this.state.showColorPicker,
        selectedSet: id,
      },
      () => {
        if (!this.state.showColorPicker) {
          this.onfocusOut({
            merge_variable_id: colorData.merge_variable_id,
            value: colorData.value,
          });
        }
      }
    );
  };

  closeOnClickOutside = () => {
    this.setState({ showColorPicker: false, selectedSet: "" });
  };

  getColorPicker = (colorData) => {
    let { showColorPicker, selectedSet } = this.state;
    let colorValue = colorData.value
      ? colorData.value
      : colorData.default_value;
    return (
      <div>
        <label>{colorData.name}</label>
        <div
          style={{ flexDirection: "row", display: "flex", marginBottom: 12 }}
        >
          <a
            title="Click here"
            className="color-picker-btn"
            style={{
              backgroundColor: "#008bb2",
              width: "auto",
              textDecoration: "none",
            }}
            onClick={() => this.toggleColorPicker(colorData)}
          >
            <div
              className="btn-label"
              style={{ fontFamily: "sofia_pro_regular", fontSize: 16 }}
            >
              {showColorPicker && selectedSet == colorData.merge_variable_id
                ? "Set"
                : "Select Theme"}
            </div>
          </a>
          <div className="color-selected-value" style={{ width: "auto" }}>
            <div
              className="color-box"
              style={{ backgroundColor: colorValue }}
            />
            {colorValue}
          </div>
        </div>
        {showColorPicker && selectedSet == colorData.merge_variable_id ? (
          <SketchPicker
            color={colorValue}
            onChangeComplete={(color) =>
              this.props.hanldeInputChange(color, colorData, true)
            }
            closeOnClickOutside={this.closeOnClickOutside}
          />
        ) : null}
      </div>
    );
  };

  getInputField = (payments, stylechange) => {
    return (
      <div
        className={`input-container amount-input merge-inputs`}
        style={{ marginRight: 10 }}
      >
        <label
          style={{
            marginBottom: "0.3em",
            fontFamily: "Open Sans",
            fontWeight: stylechange ? 500 : 600
          }}
        >
          {payments.name}
        </label>
        <input
      type={payments.name === "Zip"  ? "number":"text" } 
          placeholder={payments.name}
          value={payments.value}
        onBlur={(e) =>
            this.onfocusOut({
              merge_variable_id: payments.merge_variable_id,
              value: payments.value || e.target.value
            })
          }
          onChange={(e) =>{
            if(payments.name === "Zip" && e.target.value.length>5 )
            return false;
            this.props.hanldeInputChange(e.target.value, payments)
          }}
        />
      </div>
    );
  };
  
  uploadLogo = (data) => {
    let { logoFile } = this.state;
    let filename = logoFile && logoFile.name ? logoFile.name : "";
    return (
      <div>
        <label>
          Upload Logo<span> ( .png )</span>
          <span>
            <button
              style={{
                cursor: "pointer",
                padding: "8px 20px",
                marginLeft: "1em",
                backgroundColor: "#0ab894",
                borderRadius: 3,
                color: "#FFF",
                fontSize: 14,
              }}
              onClick={this.getFiles}
              disabled={!filename}
            >
              Upload
            </button>
          </span>
        </label>
        <div style={{ marginBottom: ".1em", fontSize: 14 }}>
          <div
            style={{
              display: "inline-flex",
              margin: "2px 0px 7px",
              alignItems: "center",
            }}
          >
            <input
              ref={this.imageUploader}
              type="file"
              style={{ display: "none" }}
              onChange={this.handleFileChange}
              multiple={false}
            />
            <div
              style={{
                padding: "5px 10px",
                cursor: "pointer",
                border: "1px solid",
                backgroundColor: "#dbe9f2",
                borderRadius: 3,
              }}
              onClick={this.handleBrowseClick}
            >
              Browse...
            </div>
            <div style={{ marginLeft: 8 }}>{filename}</div>
          </div>
        </div>
        {data && data.logo_url && (
          <div
            className="client-sidebar-logo"
            style={{ margin: "5px 0px 0px 0px" }}
          >
            <Popup
              style={{ transform: `translate(50%,-50%)`, minWidth: `auto` }}
              trigger={
                <img
                  alt="document"
                  src={data.logo_url}
                  width="40"
                  height="40"
                />
              }
              position="top center"
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { account, name, mergeVarData } = this.props;
    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar ">
        <Panel>
          {this.uploadLogo(mergeVarData)}
          {Object.entries(mergeVarData).map(([key, subject], index) => {
            return key && key != "logo_url" ? (
              <div>
                {this.getBoxHeader(key)}
                {Object.entries(subject).map(([key1, rowData], i) => {
                  return (
                    <div
                      className={`${
                        key1 ? "bank-details" : "portal-subheading"
                      }`}
                    >
                      <label style={{ marginBottom: "0.3em" }}>{key1}</label>
                      {rowData && rowData.length
                        ? rowData.map((item) => {
                            return key == "Colors"
                              ? this.getColorPicker(item)
                              : this.getInputField(item, key1);
                          })
                       : null}
                    </div>
                  );
                })}
              </div>
            ) : null;
          })}
        </Panel>
      </div>
    );
  }
}

export default SuperAdminTemplateSidebar;
