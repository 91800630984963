import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import { getDollarAmount } from "../../../utils/helpers";
import styles from "../../CustomTable/DynamicTable.module.scss";

class ClientsInventoryStatusReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        headings: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
  }

  getReportData = (data) => {
    const headings = [
      "Item Name",
      "Item SKU",
      "Quantity On Hand",
      "Reorder Point",
      "Sales Price",
      "Cost Of Good",
    ];

    const columns = data.map((item) => ({
      item_name: item.item_name,
      item_sku: item.item_sku,
      quantity_on_hand: item.quantity_on_hand,
      reorder_point: item.reorder_point,
      sales_price: getDollarAmount(Number(item.sales_price)),
      cost_of_good: getDollarAmount(Number(item.cost_of_good)),
    }));

    return {
      headings,
      columns,
    };
  };

  renderTableBody = (columns) => {
    return (
      <tbody>
        {columns.length > 0 ? (
          columns.map((col) => (
            <tr key={shortid.generate()}>
              <td className={styles.rowData}>{col.item_name}</td>
              <td className={styles.rowData}>{col.item_sku}</td>
              <td className={styles.rowData}>{col.quantity_on_hand}</td>
              <td className={styles.rowData}>{col.reorder_point}</td>
              <td className={styles.rowData}>{col.sales_price}</td>
              <td className={styles.rowData}>{col.cost_of_good}</td>
            </tr>
          ))
        ) : (
          <tr className='empty-row'>
            <td colSpan={6} className={styles.emptyRow}>
              No data available
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  render() {
    const { data } = this.state;
    return (
      <Table hover striped>
        <thead className={styles.headerFix}>
          <tr>
            {data.headings.map((header) => (
              <th
                key={shortid.generate()}
                className={styles.headingStyle}
                style={{ backgroundColor: "#DADADA" }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.columns.length > 0 ? (
            data.columns.map((col) => (
              <tr key={shortid.generate()}>
                <td className={styles.rowData}>{col.item_name}</td>
                <td className={styles.rowData}>{col.item_sku}</td>
                <td className={styles.rowData}>{col.quantity_on_hand}</td>
                <td className={styles.rowData}>{col.reorder_point}</td>
                <td className={styles.rowData}>{col.sales_price}</td>
                <td className={styles.rowData}>{col.cost_of_good}</td>
              </tr>
            ))
          ) : (
            <tr className='empty-row'>
              <td colSpan={6} className={styles.emptyRow}>
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default ClientsInventoryStatusReport;
