import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Panel extends Component {
  static propTypes = {
    heading: PropTypes.string,
    optionalClasses: PropTypes.string
  }

  render() {
    const { heading, children, optionalClasses } = this.props;

    return (
      <div className={`panel${optionalClasses ? ' ' + optionalClasses : ''}`}>
        {heading &&
          <h3 className="panel-heading">
            {heading}
          </h3>
        }
        <div className="panel-content">
          {children}
        </div>
      </div>
    );
  }
}

export default Panel;
