import React, { Component } from "react";
import shortid from "shortid";

class CardView extends Component {
  renderPrimaryRow = (data, status, rowData) => {
    let { isStatus } = this.props;
    return (
      <div className="card-view">
        <div className="card-view-content">
          <h4 className="card-view-content-header">{data.key}</h4>
        </div>
        <div className="card-view-content">
          <div className="card-view-content-primary-data point-space">
            {data.value ? data.value : data.title}
          </div>
          {isStatus && (
            <div
              className={`card-view-content-status-point ${
                rowData.taskStatus ? "col3" : status ? "col1" : "col2"
              }`}
            />
          )}
          {data.secondaryInfo ? (
            <div className="card-view-content-secondary-info">
              {data.secondaryInfo}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderSecondaryRows = (data, emptyPrimary, rowData) => {
    let { isStatus, expanderLabel } = this.props;
    return data.map((row, index) => {
      if (row.hideForMobile) return;

      if (row.rowType && row.rowType === "arrow-click") {
        return (
          <div
            key={shortid.generate()}
            className={`card-view${
              emptyPrimary && index === 0 ? "" : " content-space"
            }`}
          >
            <div className="card-view-content">
              <h4 className="card-view-content-header">{row.key}</h4>
            </div>
            <div className="card-view-content">
              <a
                onClick={() => this.props.rowActionClick(rowData)}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  className={`card-view-content expander-link${
                    row.optionalClasses ? " " + row.optionalClasses : ""
                  }`}
                >
                  {expanderLabel ? expanderLabel : "View Details"}
                </div>
                <div className="object-row-arrow-consumer" style={{ marginTop: 3 }}>
                  <div
                    className={`client-caret expander-caret${
                      row.isExpanded ? " down-caret" : ""
                    }`}
                  />
                </div>
              </a>
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={index + row.key}
            className={`card-view${
              emptyPrimary && index === 0 ? "" : " content-space"
            }`}
          >
            <div className="card-view-content">
              <h4 className="card-view-content-header">{row.key}</h4>
            </div>
            <div className="card-view-content">
              <div
                className={`card-view-content-secondary-data point-space${
                  row.optionalClasses ? " " + row.optionalClasses : ""
                }`}
              >
                {row.title}
              </div>
              {isStatus && (
                <div>
                  <div
                    className={`card-view-content-status-point ${
                      rowData.status ? "col1" : "col2"
                    }`}
                  />
                </div>
              )}
              {row.secondaryInfo ? (
                <div className="card-view-content-secondary-info">
                  {row.secondaryInfo}
                </div>
              ) : null}
            </div>
          </div>
        );
      }
    });
  };

  render() {
    let { data, showEditPopup } = this.props;
    return data && data.columns && data.columns.length ? (
      data.columns.map((rowData, index) => {
        return (
          <div className="client-side-card" key={"_" + index.toString()}>
            {rowData.clickable && rowData.rowData ? (
              <a
                className="card"
                key={index.toString()}
                onClick={(event) => {
                  showEditPopup(event, rowData.rowData);
                }}
              >
                {rowData &&
                  rowData.primaryRow &&
                  this.renderPrimaryRow(rowData.primaryRow, rowData.status, rowData)}
                {this.renderSecondaryRows(
                  rowData.secondaryColumns,
                  rowData.emptyPrimary
                )}
              </a>
            ) : (
              <div className="card" key={index.toString()}>
                {rowData &&
                  rowData.primaryRow &&
                  this.renderPrimaryRow(rowData.primaryRow, rowData.status, rowData)}
                {this.renderSecondaryRows(
                  rowData.secondaryColumns,
                  rowData.emptyPrimary,
                  rowData
                )}
              </div>
            )}
          </div>
        );
      })
    ) : (
      <div />
    );
  }
}

export default CardView;
