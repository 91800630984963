import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import Modal from "../Modal";
import moment from "moment";

class ClientReportFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowToggle: false,
      selectedCheckBox: [],
      agingSummaryData: [],
      selected_items: [],
      isLoading: false,
      offset: 0,
      showLoader: false,
      buttonVisibility: false,
      hasError: false,
    };
  }

  componentDidMount() {
    let { selected_items, user, reportSlug } = this.props;

    this.setState({
      selected_items: selected_items,
    });
    if (user) {
      if (reportSlug != "aging_detail") {
        this.getReportDataForAgingSummary();
      } else {
        this.getReportDataForAgingDetails();
      }
    }
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();

      if (this.state.selectedCheckBox.length > 0) {
        this.state.selected_items = this.state.selectedCheckBox;
      } else {
        this.state.selected_items = [];
      }

      let data = this.removeDuplicate(this.state.selected_items);
      this.props.hideModal(data);
    } else this.props.hideModal(this.state.selected_items);
  };

  // getFormattedDate = (date) => {
  //   if (date) return moment(new Date(date)).format("YYYY-MM-DD");
  //   return date;
  // };

  getReportDataForAgingSummary = () => {
    const reportId = this.props.match.params.id;
    let fromDate = "";
    let toDate = "";
    // if (this.props.item.fromDate.value && this.props.item.toDate.value) {
    //   fromDate = this.getFormattedDate(this.props.item.fromDate.value);
    //   toDate = this.getFormattedDate(this.props.item.toDate.value);
    // }
    this.setState({
      isLoading: true,
    });

    API.arAgingSummaryList(
      this.props.user,
      reportId,
      fromDate,
      toDate,
      []
    ).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data && data.data.length > 0) {
          this.setState(
            {
              agingSummaryData: data.data,
            },
            () => {
              this.setLocalId();
            }
          );
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getReportDataForAgingDetails = () => {
    let { totalItems, is_all_items } = this.state;

    if (is_all_items) {
      this.setState({ totalItems: 0 });
    }
    this.setState({
      isLoading: true,
    });
    API.fetchAllClientsForTask().then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.consumers) {
          totalItems = data.consumers.length;

          this.setState(
            {
              agingSummaryData: [...data.consumers],
              reportName: "A/R AGING DETAIL REPORT",
              reportSlug: "aging_detail",
              totalItems: totalItems,
              isLoading: false,
            },
            () => {
              this.setLocalId();
            }
          );
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //add / remove selected clients
  selectedClientOperations = (
    agingSummaryData,
    selectedCheckBox,
    selected_items,
    agingSummaryDataObj,
    index
  ) => {
    if (this.props.reportSlug == "aging_detail") {
      //checkbox operations for aging detail report
      let index = selected_items.findIndex((o) => o == agingSummaryDataObj.id);
      if (index > -1) {
        agingSummaryDataObj.is_checked = true;
        selectedCheckBox.push(agingSummaryDataObj.id);
      } else {
        //checkbox operations for aging summary report
        agingSummaryDataObj.is_checked = false;
        let index = selectedCheckBox.indexOf(agingSummaryDataObj.id);
        if (index > -1) {
          selectedCheckBox.splice(index, 1);
        }
      }
    } else {
      let index = selected_items.findIndex(
        (o) => o == agingSummaryDataObj.user_id
      );
      if (index > -1) {
        agingSummaryDataObj.is_checked = true;
        selectedCheckBox.push(agingSummaryDataObj.user_id);
      } else {
        agingSummaryDataObj.is_checked = false;
        let index = selectedCheckBox.indexOf(agingSummaryDataObj.user_id);
        if (index > -1) {
          selectedCheckBox.splice(index, 1);
        }
      }
    }
  };

  setLocalId = () => {
    let { agingSummaryData, selectedCheckBox } = this.state;
    let { selected_items } = this.props;
    if (agingSummaryData && agingSummaryData.length) {
      agingSummaryData.map((agingSummaryDataObj, index) => {
        agingSummaryDataObj.localId = index;
        if (selected_items && selected_items.length) {
          this.selectedClientOperations(
            agingSummaryData,
            selectedCheckBox,
            selected_items,
            agingSummaryDataObj,
            index
          );
        }
      });
    }
    this.setState({
      agingSummaryData: agingSummaryData,
      selectedCheckBox: selectedCheckBox,
    });
  };

  removeDuplicate = (data) => {
    if (data) {
      return data.filter((value, index) => data.indexOf(value) === index);
    }
  };

  multiSelect = () => {
    if (this.state.selectedCheckBox.length > 1) {
      let first =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 2];
      let last =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 1];
      if (first > last) {
        let temp = first;
        first = last;
        last = temp;
      }
      this.state.agingSummaryData.map((agingSummaryDataObj) => {
        if (
          agingSummaryDataObj.localId >= first &&
          agingSummaryDataObj.localId <= last
        ) {
          agingSummaryDataObj.is_checked = true;
        }
      });
    }
    this.setState({ agingSummaryData: this.state.agingSummaryData });
  };

  onCheckboxClick = (event, item) => {
    const item_ids = event.target.name;
    let isShiftKey = event.shiftKey;

    this.state.agingSummaryData.map((agingSummaryDataObj) => {
      if (
        (this.props.reportSlug == "aging_detail" &&
          agingSummaryDataObj.id == item_ids) ||
        (this.props.reportSlug == "aging_summary" &&
          agingSummaryDataObj.user_id == item_ids)
      ) {
        if (event.target.checked) {
          agingSummaryDataObj.is_checked = true;
          this.state.selectedCheckBox.push(item_ids * 1);
        } else {
          agingSummaryDataObj.is_checked = false;
          let ids = parseInt(item_ids);
          let index = this.state.selectedCheckBox.indexOf(ids);
          if (index > -1) {
            this.state.selectedCheckBox.splice(index, 1);
          }
        }
      }
    });

    this.setState({ selectedCheckBox: this.state.selectedCheckBox }, () => {
      isShiftKey && this.multiSelect();
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getSelectedContacts(true);
        }
      );
    }
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [];

    columns = data.map((item, index) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: (
              <input
                name={
                  this.props.reportSlug == "aging_detail"
                    ? item.id
                    : this.props.reportSlug == "aging_summary"
                    ? item.user_id
                    : ""
                }
                value={
                  this.props.reportSlug == "aging_detail"
                    ? item.id
                    : this.props.reportSlug == "aging_summary"
                    ? item.user_id
                    : ""
                }
                key={item.localId}
                type='checkbox'
                checked={item.is_checked}
                style={{
                  WebkitAppearance: `checkbox`,
                  width: `20px`,
                }}
                onChange={() => {}}
                onClick={(event) => this.onCheckboxClick(event, item)}
              />
            ),
            opClassesPar: "task-checkbox",
          },
          {
            title:
              this.props.reportSlug == "aging_detail"
                ? item.display_name
                : this.props.reportSlug == "aging_summary"
                ? item.user_name
                : "",
          },
        ],
        fullRowIsClickable: false,
        rowType: "",
        optionalRowClasses: "object-row-inner-padding",
      };
    });

    return {
      headings,
      columns,
    };
  };

  render() {
    let { isLoading, hasError, agingSummaryData } = this.state;
    let style = !isLoading
      ? { height: window.innerHeight / 2, overflowY: "scroll" }
      : { height: "auto" };
    return (
      <Modal
        title='Select Clients for filter'
        closeOnClickOutside={true}
        hideModal={this.handleSubmit}
        style={{
          maxWidth: `35em`,
          paddingBottom: "1em",
        }}
        optionalClasses='scroll task-popup-sp mxht'
        titleClasses='modal-header'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            {agingSummaryData && agingSummaryData.length > 0 && (
              <BottomScrollListener
                offset={50}
                onBottom={this.handleContainerOnBottom}
                debounce={0}
              >
                {(scrollRef) => (
                  <div style={style}>
                    <ObjectDebtList
                      data={this.getObjectListData(agingSummaryData)}
                      optionalClasses='mg-mb-0'
                      scrollRef={scrollRef}
                      removePadding={true}
                      showLoader={this.state.showLoader}
                    />
                  </div>
                )}
              </BottomScrollListener>
            )}
            {hasError && (
              <div
                className='input-container error'
                style={{
                  marginBottom: "1.5em",
                  marginTop: "-1em",
                  display: "flex",
                }}
              >
                <div className='input-error-message' style={{ marginTop: 0 }}>
                  Please Select Item
                </div>
              </div>
            )}

            {/* Submit Button Block */}
            <div
              className='submit-container'
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              <a
                className='cta mg-brand2-color'
                onClick={(event) => this.handleSubmit(event)}
              >
                Done
              </a>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(ClientReportFilterModal);
