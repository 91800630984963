import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import shortid from "shortid";
import editIcon from "../../../assets/icons/edit_blue.svg";
import styles from "../../CustomTable/DynamicTable.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";

class AccountsObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleExpanderClick = (event, rowData) => {
    event.preventDefault();
    this.props.rowActionClick(rowData);
  };

  goToEditSetting = (e, path) => {
    e.preventDefault();
    let finalPath = path + "/edit-setting";
    this.props.history.push(finalPath);
  };

  renderRowExpander = (rowData) => {
    return (
      <a
        key={shortid.generate()}
        className={`client-caret expander-caret`}
        style={{
          margin: "0.8rem 0 0 0.8rem",
          display: "flex",
          // borderTop: `0.8em solid ${this.checkPrimaryColor(this.props)}`,
        }}
        onClick={(event) => this.handleExpanderClick(event, rowData)}
      ></a>
    );
  };

  renderPrimaryColumn = (primaryColumn, isStatus, status) => {
    let primaryContent = (
      // <div className='object-row-header-name primary-column-title'>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isStatus && (
          <span className={`status-point ${status ? "col1" : "col2"}`} />
        )}
        <div style={{ color: "black", fontWeight: 700 }}>
          {primaryColumn.title}
        </div>
        {primaryColumn.editIcon ? (
          <img
            className='edit-icon'
            src={editIcon}
            onClick={(e) => this.goToEditSetting(e, primaryColumn.rowPath)}
          />
        ) : null}
      </div>
    );

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div>{primaryColumn.secondaryInfo}</div>
    ) : null;

    return (
      <Link
        to={primaryColumn.rowPath}
        style={{ textDecoration: "none" }}
        className={styles.rowData}
      >
        {primaryContent}
        {secondaryContent}
      </Link>
    );
  };

  showSortContent = (header, index) => {
    const { sortHeaders } = this.props;
    if (header === sortHeaders[0]) {
      return (
        <div
          className='caret-header-title'
          onClick={() => this.props.sortAccountData(header, !sortHeaders[1])}
        >
          <div style={{ display: "flex" }}>
            <div className={styles.headingStyle}>{header}</div>
            <div
              className={`client-caret${
                sortHeaders[1] ? " client-caret-up" : ""
              }`}
              style={{ marginLeft: "0.4em" }}
            />
          </div>
        </div>
      );
    } else {
      if (header == "Status") {
        return (
          <div className='caret-header-title'>
            <div className={styles.headingStyle}>{header}</div>
            <div style={{ marginLeft: "1em" }} />
          </div>
        );
      } else {
        return (
          <div
            className='caret-header-title'
            onClick={() => this.props.sortAccountData(header, true)}
          >
            <div className={styles.headingStyle}>{header}</div>
            <div className={`client-caret`} style={{ marginLeft: "0.4em" }} />
          </div>
        );
      }
    }
  };

  render() {
    let {
      data,
      isPrimaryColumn,
      isStatus,
      hideForMobile,
      optionalClasses,
      optionalTableClasses,
      sortHeaders,
      scrollRef,
    } = this.props;
    let primary_col_style = isPrimaryColumn ? " primary-column" : "";
    let status_style = isStatus
      ? primary_col_style + " header-status-space"
      : primary_col_style;
    return (
      <div ref={scrollRef} className='scrollbar in-sc'>
        <Table hover striped className='mobile-hide-list'>
          <thead className={styles.headerFix}>
            <tr className='list-header'>
              {data.headings &&
                data.headings.map((header, index) => {
                  return (
                    <th
                      key={shortid.generate()}
                      className={styles.headingStyle}
                      style={{ backgroundColor: "#DADADA", borderTop: "none" }}
                    >
                      {header === "expander"
                        ? ""
                        : sortHeaders
                        ? header === "Last Pymt" || header === "Next Pymt"
                          ? header
                          : this.showSortContent(header, index)
                        : header}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody
            ref={this.props.scrollRef}
            // className={`${optionalClasses ? optionalClasses : ""}`}
          >
            {data.columns &&
              data.columns.map((item, indexCol) => {
                return (
                  <tr key={shortid.generate()} className={styles.rowData}>
                    {/* {isStatus && (
                    <td
                      className={`status-point ${
                        item.status ? "col1" : "col2"
                      }`}
                      // style={{ width: `${100 / data.headings.length}%` }}
                    />
                  )} */}
                    {item.primaryColumn && (
                      <td
                        key={shortid.generate()}
                        className={styles.rowData}
                        style={{ width: "20%" }}
                      >
                        {this.renderPrimaryColumn(
                          item.primaryColumn,
                          isStatus,
                          item.status
                        )}
                      </td>
                    )}
                    {item.secondaryColumns &&
                      item.secondaryColumns.map((col, index) => {
                        if (col.rowType && col.rowType === "arrow-click") {
                          return this.renderRowExpander(item);
                        }
                        if (col.key == "Status") {
                          return (
                            <td
                              key={shortid.generate()}
                              // style={{
                              //   display: "flex",
                              //   flexDirection: "column",
                              // }}
                            >
                              {col.title}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={shortid.generate()}
                              className={styles.rowData}
                            >
                              {col.title}
                            </td>
                          );
                        }
                      })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default withRouter(AccountsObjectList);
