import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import MenuItem from "../../components/MenuItem";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import DropdownButton from "../../components/DropdownButton";
import * as API from "../../utils/api";

import dollorIcon from "../../assets/icons/dollar-teal.png";
import downloadImg from "../../assets/icons/download.png";
import deleteIcon from "../../assets/icons/delete.png";
import editImg from "../../assets/icons/edit_blue.svg";
import PlacementEligibleIcon from "../../assets/icons/delinquent.png";
import DollerIcon from "../../assets/images/dollerIcon.png";
import LockIcon from "../../assets/icons/lock.png";
import {
  getDollarAmount,
  getZohoPagesense,
  showToast,
  isSameStartDateAndDueDate,
} from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";

class ClientsInvoices extends Component {
  constructor(props) {
    super(props);
    this.dropDownRef = React.createRef();
  }

  state = {
    input: {
      search: {
        value: "",
      },
    },
    isTableLoading: true,
    showLoader: false,
    sortHeader: "invoice_number",
    sortType: false,
    invoices: [],
    consumer_type: "open",
    sortHeader: "Inv. #",
    sortType: false,
    isLoading: true,
    showLoader: false,
    hasMoreData: false,
    dropdownIsActive: false,
    offset: 0,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    this.handleInvoiceListRefresh = this.handleInvoiceListRefresh.bind(this);
    Emitter.on("INVOICE_LIST_REFRESH", this.handleInvoiceListRefresh);
    getZohoPagesense();
    // ReactGA.pageview("/clients-invoices");
    this.getInvoiceList();
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  componentWillUnmount() {
    Emitter.off("INVOICE_LIST_REFRESH", this.handleInvoiceListRefresh);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  handleInvoiceListRefresh() {
    this.getInvoiceList(false);
  }

  handleOutsideClick(event) {
    if (
      this.dropDownRef &&
      this.dropDownRef.current &&
      !this.dropDownRef.current.contains(event.target)
    ) {
      this.setState({ dropdownIsActive: false });
    }
  }
  //calls when page scroll reaches the bottom, used for pagination
  handleContainerOnBottom = (event) => {
    if (this.state.hasMoreData && this.state.consumer_type === "ALL") {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "PAST_DUE" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "CLOSED" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "OPEN" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    }
  };
  handleSearch = (reload, isSort) => {
    if (!isSort) {
      if (reload) {
        this.setState({ showLoader: true });
      } else {
        this.setState({ listLoader: true });
      }
    }
  };

  setConsumersData = (dataSet = []) => {
    this.setState((state) => ({
      ...state,
      consumers: dataSet,
      listLoader: false,
    }));
  };

  getInvoiceList = (reload) => {
    this.setState({ isTableLoading: true });
    let sort_key = this.getSortKey();
    let sort_by = this.state.sortType ? "asc" : "desc";
    let filterBy = this.state.consumer_type;
    API.getInvoiceList(
      this.props.user,
      sort_key,
      sort_by,
      this.state.offset,
      filterBy
    ).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        typeof data != "undefined" ||
        (data != null && data && data.status_code == 200)
      ) {
        if (data.data) {
          let invoice = reload ? this.state.invoices : false;
          let newData = invoice ? invoice.concat(data.data) : data.data;
          this.setState((state) => ({
            ...state,
            invoices: newData,
            hasMoreData: data.data.length < 15 ? false : true,
          }));
        }
        this.setState({
          isLoading: false,
          showLoader: false,
          isTableLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isTableLoading: false,
          showLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  goToEditInvoice = (id) => {
    this.getInvoiceDetails(id);
  };

  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(user, doc_id, "debts").then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  //getInvoiceDetails
  getInvoiceDetails = (invoiceId) => {
    let { user } = this.props;
    API.getInvoiceDetails(user, invoiceId).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data.message === "success" &&
        !data.error
      ) {
        if (data.data) {
          this.handleEditClick(data.data);
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getInvoiceList(true);
        }
      );
    }
  };
  handleFilter = (consumer_type) => {
    this.setState(
      { consumer_type, offset: 0, invoices: [], showLoader: true },
      () => {
        this.getInvoiceList(true);
      }
    );
  };

  //go to edit invoice
  handleEditClick = (data) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        invoiceDetails: data,
        backPath: `/client/invoices`,
      },
    });
  };

  handleRowActionClick = (event) => {
    const qbId = event.rowData.id;
    let { history } = this.props;
    if (qbId) {
      history.push(`/client/invoices/${qbId}`);
    }
  };

  sortInvoiceData = (header, type) => {
    this.setState(
      {
        sortHeader: header,
        sortType: type,
        offset: 0,
      },
      () => {
        this.getInvoiceList(false);
      }
    );
  };

  getSortKey = () => {
    let { sortHeader } = this.state;
    if (sortHeader === "Inv. #") {
      return "invoice_number";
    } else if (sortHeader === "Account Name") {
      return "display_name";
    } else if (sortHeader === "Invoice Date") {
      return "txn_date";
    } else if (sortHeader === "Due Date") {
      return "due_date";
    } else if (sortHeader === "Invoice Amount") {
      return "total_amt";
    } else if (sortHeader === "Balance") {
      return "balance";
    } else if (sortHeader === "Paid Amt") {
      return "paid_amt";
    } else if (sortHeader === "Inv. Amt") {
      return "total_amt";
    } else if (sortHeader === "Inv. Date") {
      return "txn_date";
    } else if (sortHeader === "Acc. Name") {
      return "display_name";
    } else if (sortHeader === "Status") {
      return "status";
    }
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      invoice.total_amt = invoice.total_amt ? invoice.total_amt : 0;
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) -
            parseFloat(invoice.balance) +
            parseFloat(invoice.tax_amount) +
            parseFloat(invoice.slow_payer_amount)
          : parseFloat(invoice.total_amt)
        : parseFloat(invoice.total_amt);
    }
    return amount.toFixed(2);
  };

  getInvoiceDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY");
    } else {
      return "--/--/----";
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    if (flag) {
      if (isSameStartDateAndDueDate(rowData.due_date)) {
        flag = false;
      }
    }
    return !flag;
  };

  toggleDropdown = () => {
    this.setState({ dropdownIsActive: !this.state.dropdownIsActive });
  };

  handleAddInvoiceClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/add/invoices`,
      state: { backPath: "/client/invoices" },
    });
  };

  handleImportInvoiceClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/invoices/import`,
      state: { backPath: "/client/invoices" },
    });
  };

  getInvoiceListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Inv. #",
        style: {
          width: "10%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Status",
        style: { width: `8%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Acc. Name",
        style: { width: `12%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Inv. Date",
        style: { width: `10%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Due Date",
        style: { width: `8%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Inv. Amt",
        style: { width: `8%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Paid Amt",
        style: { width: `8%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Discount",
        style: { width: `8%`, display: "flex", justifyContent: "center" },
      },
      {
        name: "Balance",
        style: {
          width: `12%`,
          display: "flex",
          justifyContent: "center",
          paddingRight: "2%",
        },
      },
      {
        name: "Action",
        style: {
          width: `13%`,
          display: "flex",
          justifyContent: "center",
          paddingRight: "4%",
        },
      },
      {
        name: "expander",
        style: { width: `5%`, display: "flex", justifyContent: "flex-start" },
      },
    ];

    columns = data.map((row, index) => {
      // const total_amount =
      //   parseFloat(row.total_amt) + parseFloat(row.tax_amount);
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: row.invoice_number ? row.invoice_number : "n/a",
            style: {
              width: `10%`,
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Status",
            title: (
              <span>
                {row.has_active_payment_plan && (
                  <img
                    src={DollerIcon}
                    width={"20px"}
                    height={"20px"}
                    title='In Payment Plan'
                    style={{ margin: "4px 6px" }}
                  />
                )}
                {row.is_eligible_for_placement &&
                  !row.is_placed_collections && (
                    <img
                      src={PlacementEligibleIcon}
                      width={"30px"}
                      height={"30px"}
                      title='Eligible For Placement'
                    />
                  )}
                {row.is_eligible_for_placement && row.is_placed_collections && (
                  <img
                    src={LockIcon}
                    width={"30px"}
                    height={"30px"}
                    title='In Collection'
                  />
                )}
              </span>
            ),
            style: { width: `8%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Acc. Name",
            title: row.display_name ? row.display_name : "",
            style: { width: `12%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Inv. Date",
            title: this.getInvoiceDate(row.txn_date),
            style: { width: `10%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Due Date",
            title: this.getInvoiceDate(row.due_date),
            style: { width: `8%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Inv. Amt",
            title: getDollarAmount(row.invoice_total_amount),
            style: { width: `8%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Paid Amt",
            title: getDollarAmount(row.paid_amount),
            style: { width: `8%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Discount",
            title: getDollarAmount(row.invoice_credit),
            style: { width: `8%`, display: "flex", justifyContent: "center" },
          },
          {
            key: "Balance",
            title: getDollarAmount(row.balance),
            style: {
              width: `10%`,
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Action",
            title: (
              <div
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {row.balance != "0.0" ? (
                  <button
                    title='Accept Payment'
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      marginRight: 10,
                      marginLeft: "-0.4rem",
                    }}
                    onClick={() => this.openAcceptPaymentModal(row)}
                  >
                    <img src={dollorIcon} width='18' height='18' />
                  </button>
                ) : (
                  <button
                    title=''
                    style={{
                      background: "transparent",
                      marginRight: 10,
                    }}
                  >
                    <div style={StyleSheet.btnDiv}></div>
                  </button>
                )}
                <button
                  title='Edit'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => this.goToEditInvoice(row.id)}
                >
                  <img src={editImg} width='16' height='16' />
                </button>
                <button
                  title='Download'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() =>
                    this.downloadInvoice(row.document_id, row.document_name)
                  }
                >
                  <img src={downloadImg} width='16' height='16' />
                </button>{" "}
                <div style={{ cursor: "pointer", width: "min-content" }}>
                  <img
                    title='Delete'
                    src={deleteIcon}
                    height={28}
                    width={28}
                    onClick={() => this.handleDeleteInvoice(row.id)}
                  />
                </div>
              </div>
            ),
            style: {
              width: `13%`,
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            style: {
              width: "5%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
        ],
        rowData: row,
        status: this.getAccountStatus(row),
        taskStatus: row.balance && row.balance <= 0 ? true : false,
      };
    });

    return {
      headings,
      columns,
    };
  };

  handleDeleteInvoice = (invoiceID) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to delete invoice?",
      confirmClick: () => this.deleteInvoice(invoiceID),
      closeOnClickOutside: true,
    });
  };

  deleteInvoice = (invoiceId) => {
    API.deleteInvoice(this.props.user, invoiceId).then((data) => {
      if (data) {
        if (data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          this.getInvoiceList();
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  renderInvoice = (tableData) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    // tableData.length > 0 ?
    return (
      <BottomScrollListener
        offset={50}
        onBottom={(event) => this.handleContainerOnBottom(event)}
        debounce={0}
      >
        {(scrollRef) => (
          <div>
            {isSmallDevice ? (
              <div className='big-device-grad invoice-line'>
                <div ref={scrollRef} className='scrollbar in-sc'>
                  <ClientsCardView
                    data={this.getInvoiceListData(tableData)}
                    rowActionClick={(event) => this.handleRowActionClick(event)}
                    isStatus={true}
                  />
                </div>
              </div>
            ) : (
              <div style={{ marginBottom: "1em" }}>
                <InvoiceObjectList
                  data={this.getInvoiceListData(tableData)}
                  rowActionClick={(event) => this.handleRowActionClick(event)}
                  isStatus={true}
                  hideForMobile={true}
                  isInvoiceListData={true}
                  optionalClasses='scrollbar in-sc'
                  scrollRef={scrollRef}
                  sortHeaders={[this.state.sortHeader, this.state.sortType]}
                  sortInvoiceData={this.sortInvoiceData}
                />
              </div>
            )}
          </div>
        )}
      </BottomScrollListener>
    );
  };

  getSectionHeaderUtilities = () => {
    let { dropdownIsActive } = this.state;

    this.filterIndicators = (indicator) => {
      if (indicator == "closed") {
        return "Closed";
      } else if (indicator == "past_due") {
        return " Past Due";
      } else if (indicator == "voided") {
        return "Voided";
      } else if (indicator == "open") {
        return "Open";
      } else if (indicator == "all") {
        return "All";
      }
    };

    return (
      <>
        <div className='mobDiv'>
          <div
            className='utility-btn-col mg-ml-0'
            style={{ marginLeft: "20px" }}
          >
            <DropdownButton
              title={`Filter By: ${this.filterIndicators(
                this.state.consumer_type
              )}`}
              primaryLink='/client/accounts'
              overrideStyle={true}
              forFilter={true}
              clientMobile={true}
              pointerNone={true}
            >
              <MenuItem
                handleClick={(event) => this.handleFilter("all")}
                optionalClasses={
                  this.state.consumer_type === "ALL" ? " filter-select" : " "
                }
              >
                All
              </MenuItem>
              <MenuItem
                handleClick={(event) => this.handleFilter("closed")}
                optionalClasses={
                  this.state.consumer_type === "CLOSED" ? " filter-select" : " "
                }
              >
                Closed
              </MenuItem>
              <MenuItem
                handleClick={(event) => this.handleFilter("past_due")}
                optionalClasses={
                  this.state.consumer_type === "PAST_DUE"
                    ? " filter-select"
                    : " "
                }
              >
                Past Due
              </MenuItem>

              <MenuItem
                handleClick={(event) => this.handleFilter("open")}
                optionalClasses={
                  this.state.consumer_type === "OPEN" ? " filter-select" : " "
                }
              >
                Open
              </MenuItem>
              <MenuItem
                handleClick={(event) => this.handleFilter("voided")}
                optionalClasses={
                  this.state.consumer_type === "VOIDED" ? " filter-select" : " "
                }
              >
                Voided
              </MenuItem>
            </DropdownButton>
          </div>
          <div
            className='dropdown-button addinvoicebtn'
            style={{ marginLeft: "auto", marginTop: "auto" }}
            ref={this.dropDownRef}
          >
            <a
              onClick={this.toggleDropdown}
              className='mg-brand2-color cta dropdown-button-submit override-dropdown-button'
            >
              + Add Invoice
            </a>
            <a
              className={`cta dropdown-button-toggle mg-brand2-color toggle-sep override-toggle`}
              onClick={this.toggleDropdown}
            >
              <div className='caret'></div>
            </a>
            <ul
              className={`dropdown-menu${
                dropdownIsActive ? " active" : ""
              } mlleft0`}
              onClick={this.toggleDropdown}
            >
              <MenuItem handleClick={this.handleAddInvoiceClick}>
                Add Invoice
              </MenuItem>
              <MenuItem handleClick={this.handleImportInvoiceClick}>
                Import Invoice
              </MenuItem>
            </ul>
          </div>
        </div>
      </>
    );
  };
  reloadData = (e) => {
    this.getInvoiceList();
  };

  openAcceptPaymentModal = (debt) => {
    let invoiceDetails = debt;
    let targetDate;
    let criteriaDiscountFlag = false;
    if (
      invoiceDetails &&
      invoiceDetails.criteria_discount_days &&
      invoiceDetails.criteria_discount_days > 0
    ) {
      let startdate = invoiceDetails.date;
      // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
      let nn = moment(
        moment(startdate)
          .add(invoiceDetails.criteria_discount_days, "d")
          .format("YYYY/MM/DD")
      );
      let today = new Date();
      targetDate = moment(nn);
      var month = today.getUTCMonth() + 1; //months from 1-12
      var day = today.getUTCDate();
      var year = today.getUTCFullYear();

      let newdate = year + "/" + month + "/" + day;
      let momentToday = moment(newdate);
      let diffNumber = targetDate.diff(momentToday, "days");
      if (diffNumber > 0) {
        criteriaDiscountFlag = true;
      } else {
        targetDate = "";
      }
    }
    debt.total_amount = debt.total_amt;

    let props = {
      debtInfo: debt,
      reloadData: this.reloadData,
      targetDate: targetDate,
      criteriaDiscountFlag: criteriaDiscountFlag,
      amountPaid: parseFloat(this.getPaidAmount(debt)),
    };
    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  render() {
    const { invoices, isLoading, error } = this.state;
    const { appName } = this.props;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>
          <title>{appName} | Invoices </title>
        </Helmet>
        <AppSectionHeader
          title='Invoices'
          optionalClassNames='client-invoice-utility'
          utilities={this.getSectionHeaderUtilities()}
        />
        <div className='app-sidebar-layout-canvas-content'>
          {this.state.showLoader && invoices.length == 0 ? null : invoices &&
            invoices.length ? (
            this.renderInvoice(invoices)
          ) : (
            <div className='admin-admins-empty-state'>
              <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                No invoices Available
              </h3>
              <p>There are currently no invoices available</p>
            </div>
          )}
          {this.state.showLoader && (
            <div style={{ marginTop: "-3em" }}>
              <AppCanvasLoader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsInvoices;

let StyleSheet = {
  btnDiv: {
    height: "16px",
    width: "16px",
  },
};
