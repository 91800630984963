import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Elements, StripeProvider } from "react-stripe-elements";
// Packages
import MaskedTextInput from "react-text-mask";
import EditIcon from "../../../assets/icons/edit.svg";
import Panel from "../../../components/App/Panel";
import ConsumerAddAccountForm from "../../../components/Consumer/ConsumerAddAccountForm";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
import { validateEmail } from "../../../utils/helpers";

class ConsumerProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditCompany: false,
      showEditCard: false,
      consumerData: { id: "" },
      input: {
        companyName: {
          value: "",
          hasError: false,
        },
        displayName: {
          value: "",
          hasError: false,
        },
        first_name: {
          value: "",
          hasError: false,
        },
        last_name: {
          value: "",
          hasError: false,
        },
        email: {
          value: "",
          hasError: false,
        },
        secondaryEmail: {
          value: "",
          hasError: false,
        },
        phone_number: {
          value: "",
          hasError: false,
        },
        address_line1: {
          value: "",
          hasError: false,
        },
        address_line2: {
          value: "",
          hasError: false,
        },
        city: {
          value: "",
          hasError: false,
        },
        state: {
          value: "",
          hasError: false,
        },
        zipcode: {
          value: "",
          hasError: false,
        },
      },
      setData: this.setData.bind(this),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.consumerData &&
      prevState.consumerData['id'] != nextProps.consumerData['id']
    ) {
      return {
        consumerData: nextProps.consumerData,
        model: prevState.setData(nextProps.consumerData),
      };
    }
    return null;
  }

  setData = (consumerData) => {
    this.setState((state) => ({
      showEditCompany: false,
      showEditCard: false,
      consumerData: consumerData,
      input: {
        companyName: {
          ...state.input.companyName,
          value: consumerData.company_name,
        },
        displayName: {
          ...state.input.displayName,
          value: consumerData.display_name,
        },
        first_name: {
          ...state.input.first_name,
          value: consumerData.billing_first_name,
        },
        last_name: {
          ...state.input.last_name,
          value: consumerData.billing_last_name,
        },
        email: {
          ...state.input.email,
          value: consumerData.billing_primary_email,
        },
        secondaryEmail: {
          ...state.input.email,
          value: consumerData.billing_secondary_email,
        },
        phone_number: {
          ...state.input.phone_number,
          value: consumerData.billing_phone_no,
        },
        address_line1: {
          ...state.input.address_line1,
          value: consumerData.billing_address_line1,
        },
        address_line2: {
          ...state.input.address_line2,
          value: consumerData.billing_address_line2,
        },
        city: {
          ...state.input.city,
          value: consumerData.billing_city,
        },
        state: {
          ...state.input.state,
          value: consumerData.billing_state,
        },
        zipcode: {
          ...state.input.zipcode,
          value: consumerData.billing_zip,
        },
      },
    }));
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const { input } = this.state;
      const userUpdate = {
        company_name: input.companyName.value,
        display_name: input.displayName.value,
        billing_first_name: input.first_name.value,
        billing_last_name: input.last_name.value,
        billing_phone_no: input.phone_number.value
          ? input.phone_number.value
              .replace("(", "")
              .replace(")", "")
              .replace(" ", "")
              .replace("-", "")
          : "",
        billing_primary_email: input.email.value,
        billing_secondary_email: input.secondaryEmail.value,
        billing_address_line1: input.address_line1.value,
        billing_address_line2: input.address_line2.value,
        billing_city: input.city.value,
        billing_state: input.state.value,
        billing_zip: input.zipcode.value,
      };
      this.props.handleSubmit(userUpdate);
    }
  };

  validateForm = () => {
    let errorsArePresent = false;
    // Determine which input group to check for errors
    let inputs = { ...this.state.input };
    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "companyName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "displayName") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "email") {
        if (
          !value.value ||
          (!validateEmail(value.value) && value.value !== "")
        ) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "address_line1") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "city") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "state") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          inputs[key].hasError = true;
        }
      }
    });

    this.setState({ input: inputs });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  isEmpty = (value) => {
    if (value === "" || value === null || value === undefined) {
      return true;
    } else return false;
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "companyName") {
      message = "Please enter company name";
    } else if (input === "displayName") {
      message = "Please enter a display name";
    } else if (input === "email") {
      message = "Please enter a valid email address";
    } else if (input === "street_address") {
      message = "Please enter a street address";
    } else if (input === "city") {
      message = "Please enter a city";
    } else if (input === "state") {
      message = "Please select a state";
    } else if (input === "zipcode") {
      message = "Please enter a zip code";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleEditClick = (event, type) => {
    event.preventDefault();
    if (type === "payment") {
      this.setState({ showEditCard: !this.state.showEditCard });
    } else if (type === "company") {
      this.setState({ showEditCompany: !this.state.showEditCompany });
    }
  };

  render() {
    let { input, showEditCompany } = this.state;
    let { stripePubKey } = this.props;

    return (
      <div>
        <div className="consumer-profile-form">
          <div className="edit-header">
            <h4>Company Information</h4>
            <img
              src={EditIcon}
              height={15}
              width={15}
              onClick={(event) => this.handleEditClick(event, "company")}
            />
          </div>
          <div style={{ marginBottom: "1.5em" }} />
          <Panel>
            <div className="edit-setting-screen">
              <form className="admin-form">
                <div className={`modal-slide`}>
                  <div className="form-container">
                    <div className="form-column">
                      <header
                        className="modal-slide-header"
                        style={{ textAlign: "left", marginBottom: "0.5em" }}
                      >
                        <div
                          className={`modal-slide-header-tab`}
                          style={{ margin: 0 }}
                        >
                          Billing Contact Information
                        </div>
                      </header>
                      <div
                        className={`input-container${
                          input.companyName.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="name">Company Name</label>
                        <input
                          name="name"
                          type="text"
                          readOnly={!showEditCompany}
                          placeholder="Company Name"
                          value={input.companyName.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              companyName: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.companyName.hasError
                          ? this.renderErrorMessage("companyName")
                          : null}
                      </div>
                      <div
                        className={`input-container${
                          input.displayName.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="name">Display Name</label>
                        <input
                          name="display_name"
                          type="text"
                          readOnly={!showEditCompany}
                          placeholder="Display Name"
                          value={input.displayName.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              displayName: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.displayName.hasError
                          ? this.renderErrorMessage("displayName")
                          : null}
                      </div>
                      <div className={`input-container`}>
                        <label htmlFor="name">Name</label>
                        <div className="input-container multi-input-field">
                          <input
                            className="multi-input-field-input"
                            name="name"
                            type="text"
                            readOnly={!showEditCompany}
                            placeholder="First Name"
                            value={input.first_name.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                first_name: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          <input
                            className="multi-input-field-input"
                            name="name"
                            type="text"
                            placeholder="Last Name"
                            readOnly={!showEditCompany}
                            value={input.last_name.value || ""}
                            onChange={(event) =>
                              this.handleInputChange({
                                last_name: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={`input-container${
                          input.email.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="email">Email Address</label>
                        <input
                          name="email"
                          type="email"
                          placeholder="address@email.com"
                          value={input.email.value || ""}
                          readOnly={!showEditCompany}
                          onChange={(event) =>
                            this.handleInputChange({
                              email: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.email.hasError
                          ? this.renderErrorMessage("email")
                          : null}
                      </div>
                      <div
                        className={`input-container${
                          input.secondaryEmail.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="email">Secondary Email Address</label>
                        <input
                          name="email"
                          type="email"
                          readOnly={!showEditCompany}
                          placeholder="address@email.com"
                          value={input.secondaryEmail.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              secondaryEmail: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.secondaryEmail.hasError
                          ? this.renderErrorMessage("email")
                          : null}
                      </div>
                      <div
                        className={`input-container${
                          input.phone_number.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="phone">Phone Number</label>
                        <MaskedTextInput
                          name="phone"
                          mask={[
                            "(",
                            /\d/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          readOnly={!showEditCompany}
                          placeholder="(555) 555-5555"
                          placeholderChar={"\u2000"}
                          guide={true}
                          type="text"
                          value={input.phone_number.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              phone_number: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-column">
                      <header
                        className="modal-slide-header"
                        style={{ textAlign: "left", marginBottom: "0.5em" }}
                      >
                        <div
                          className={`modal-slide-header-tab`}
                          style={{ margin: 0 }}
                        >
                          Billing Address Information
                        </div>
                      </header>
                      <div
                        className={`input-container${
                          input.address_line1.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="streetAddress">Street Address</label>
                        <input
                          name="streetAddress"
                          type="text"
                          readOnly={!showEditCompany}
                          placeholder="Enter an Address"
                          value={input.address_line1.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              address_line1: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.address_line1.hasError
                          ? this.renderErrorMessage("street_address")
                          : null}
                      </div>
                      <div className={`input-container`}>
                        <label htmlFor="unit">Suite # / Unit #</label>
                        <input
                          name="unit"
                          type="text"
                          placeholder="Apt. #"
                          readOnly={!showEditCompany}
                          value={input.address_line2.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              address_line2: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                      </div>
                      <div
                        className={`input-container${
                          input.city.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="city">City</label>
                        <input
                          name="city"
                          type="text"
                          readOnly={!showEditCompany}
                          placeholder="Enter a City"
                          value={input.city.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              city: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.city.hasError
                          ? this.renderErrorMessage("city")
                          : null}
                      </div>
                      <div
                        className={`input-container${
                          input.state.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="state">State</label>
                        {showEditCompany ? null : (
                          <div className="hide-select" />
                        )}
                        <Select
                          inputProps={{
                            autoComplete: "none",
                            autoCorrect: "off",
                            spellCheck: "off",
                          }}
                          className="form-select"
                          name="state"
                          value={input.state.value || ""}
                          placeholder="Select"
                          options={stateDataShortNames}
                          resetValue=""
                          onChange={(event) =>
                            this.handleInputChange({
                              state: {
                                value: event ? event.value : "",
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.state.hasError
                          ? this.renderErrorMessage("state")
                          : null}
                      </div>
                      <div
                        className={`input-container${
                          input.zipcode.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="zipcode">Zip Code</label>
                        <MaskedTextInput
                          guide={true}
                          mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                          name="zipcode"
                          placeholder="90210"
                          placeholderChar={"\u2000"}
                          type="text"
                          readOnly={!showEditCompany}
                          value={input.zipcode.value || ""}
                          onChange={(event) =>
                            this.handleInputChange({
                              zipcode: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.zipcode.hasError
                          ? this.renderErrorMessage("zipcode")
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="modal-actions">
                    {showEditCompany ? (
                      <a
                        className="cta mg-brand2-color"
                        onClick={this.handleSubmit}
                      >
                        Save Changes
                      </a>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
          </Panel>
        </div>
        {/* <div className="consumer-update-profile-form">
          <div className="edit-header">
            <h4>Payment Information</h4>
            <img
              src={EditIcon}
              height={15}
              width={15}
              onClick={(event) => this.handleEditClick(event, "payment")}
            />
          </div>
          <div style={{ marginTop: "1.5em" }}>
            {stripePubKey ? (
              <StripeProvider apiKey={stripePubKey}>
                <Elements>
                  <ConsumerAddAccountForm
                    handleFormSubmit={this.props.handleFormSubmit}
                    showEditCard={this.state.showEditCard}
                  />
                </Elements>
              </StripeProvider>
            ) : null}
          </div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(ConsumerProfileForm);
