import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Components
import Logo from '../Logo';

class MarketingHeader extends Component {

  static propTypes = {
    path: PropTypes.string.isRequired
  }

  state = {
    menuIsOpen: false
  };

  isActive(linkUrl) {
    if (this.props.path === linkUrl) {
      return 'active';
    }
  }

  componentDidMount() {
    var nav = $('[data-structure="nav"]');

    // MENU RESET
    $(window).resize(() => {
      if ($(window).width() > 680) {
        this.setState(state => ({
          ...state,
          menuIsOpen: false
        }))
        nav.show();
      } else {
          nav.hide();
          setTimeout(function(){
            nav.show();
          }, 500);
      }
    }); // END WINDOW RESIZE
  }

  handleMenuButtonClick(event) {
    event.preventDefault();
    this.toggleMenu();
  }

  toggleMenu = () => {
    this.setState(state => ({
      ...state,
      menuIsOpen: !state.menuIsOpen
    }))
  }

  render() {
    const { menuIsOpen } = this.state;

    return (
      <header style={{padding: '0.6rem'}}>
        <div className="wrap companyLogoMargin">
          <Logo
            linkPath="/"
            linkTitle={this.props.appName}
          />
          <a
            className={ `menu-button${ menuIsOpen ? ' open' : '' }` }
            onClick={event => this.handleMenuButtonClick(event)}
          >
            <span className="lines"></span>
          </a>
          <nav style={menuIsOpen ? {marginLeft: '-40px'} : {}} data-structure="nav" className={`menu-drawer${menuIsOpen ? '' : ' hidden'}`}>
            <Link
              className={this.isActive('/login')}
              title="Login"
              to="/login"
            >
              Login
            </Link>
            <Link
              className={[this.isActive('/join'),'cta-secondary cta']}
              title={"Join " + this.props.appName}
              to="/register"
            >
              Register
            </Link>
          </nav>
        </div>
      </header>
    );
  }
}

export default MarketingHeader;
