import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Heap from "react-heap";
// import ReactGA from 'react-ga';

// Components
import SessionsModal from "../components/Sessions/SessionsModal";
import AppThirdPartyConfigForm from "../components/AppThirdPartyConfigForm";
import Logo from "../components/Logo";

import * as API from "../utils/api";

import { getZohoPagesense } from "../utils/helpers";

class AppThirdPartyConfig extends Component {
  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/AppThirdPartyConfig");
  }

  render() {
    const { isLoggedIn, user, location, isAppConfigure } = this.props;

    let { appName } = this.props;

    return (
      <div className='sessions fill-screen third-party-config '>
        <Helmet>
          <title>{appName} | Configure</title>
        </Helmet>
        <SessionsModal title='Configure the app'>
          <div className='sessions-modal-logo-container'>
            <Logo circular={true} />
          </div>
          <h1 className='sessions-modal-heading'>
            Third Party Services Configuration
          </h1>
          <div className='sessions-modal-content'>
            <AppThirdPartyConfigForm
              location={this.props.location}
              updateModalState={this.props.updateModalState}
              isAppConfigure={this.props.isAppConfigure}
            />
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default AppThirdPartyConfig;
