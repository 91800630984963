import moment from "moment";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import { getZohoPagesense } from "../../utils/helpers";
import PaymentHistoryAll from "./PaymentHistoryAll";
import PaymentHistoryOffline from "./PaymentHistoryOffline";
import PaymentHistoryOnline from "./PaymentHistoryOnline";

class PaymentHistory extends Component {
  constructor(props) {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      savedData: [],
      flag: false,
      isLoading: true,
      searchData: "",
      showFlash: false,
      closeDropDown: false,
      type: {
        value: "All",
        hasError: false,
        isRequired: true,
        offset: 0,
      },
      search: {
        value: "",
      },
    };
  }

  componentDidMount() {
    this.setState((state) => ({
      isLoading: false,
    }));
    getZohoPagesense();
    // ReactGA.pageview("/PaymentHistory");
  }

  handleClickAction = (action, title) => {
    this.state.type.value = action;
    this.state.search.value = "";
    this.setState({
      typr: this.state.type,
      search: this.state.search,
      searchData: "",
      closeDropDown: true,
    });
    setTimeout(() => {
      this.setState({ closeDropDown: false });
    }, 200);
  };

  setClose = () => {
    this.setState({ closeDropDown: false });
  };

  handleKeyPress(event) {
    let searchText = this.state.search.value;
    if (event.key === "Enter") {
      this.setState((state) => ({
        ...state,
        searchData: searchText,
      }));
    }
  }

  handleSearchInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        search: {
          value: newPartialInput.search.value,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  getSectionHeaderUtilities() {
    const { search, closeDropDown, type } = this.state;

    return (
      <div className='app-section-header-utilities header-utility-history'>
        <DropdownButton
          historyFilter={true}
          closeDropDown={closeDropDown}
          setClose={this.setClose}
          style={{ minWidth: 180, marginTop: 0 }}
        >
          <MenuItem
            handleClick={(event) => this.handleClickAction("All")}
            optionalClasses={type.value === "ALL" ? " filter-select" : " "}
          >
            All
          </MenuItem>
          <MenuItem
            handleClick={(event) => this.handleClickAction("Online")}
            optionalClasses={type.value === "Online" ? " filter-select" : " "}
          >
            Online
          </MenuItem>
          <MenuItem
            handleClick={(event) => this.handleClickAction("Offline")}
            optionalClasses={type.value === "Offline" ? " filter-select" : " "}
          >
            Offline
          </MenuItem>
        </DropdownButton>
      </div>
    );
  }

  render() {
    const { appName, user } = this.props;
    const { isLoading, type, searchData, flag } = this.state;

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        {this.getSectionHeaderUtilities()}
        <div style={{ marginBottom: "1em" }} />
        {type.value === "All" ? (
          <PaymentHistoryAll user={this.props.user} searchText={searchData} />
        ) : type.value === "Offline" ? (
          <PaymentHistoryOffline
            user={this.props.user}
            searchText={searchData}
          />
        ) : type.value === "Online" ? (
          <PaymentHistoryOnline
            user={this.props.user}
            searchText={searchData}
          />
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='empty-mesg-mg'>No payment history available.</h3>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PaymentHistory);
