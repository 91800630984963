import React, { Component } from 'react';


// Components
import Modal from './Modal';



class OtherErrorModal extends Component {


  hideErrorModal = () => {
      this.props.hideModal();
  }



  render() {
    let errorCls = 'error-card-cls'
    return (
      <Modal
        title=""
        hideModal={this.props.hideModal}
        closeOnClickOutside={true}
        optionalClasses = {errorCls}
      >
          <h2 className="error-color"> Oops...  </h2>
          <h2 className="error-color">Something went wrong.</h2>

        <div className="modal-actions">
          <a
            className="cta mg-brand2-color"
            onClick={this.hideErrorModal}
          >
            Ok
          </a>
        </div>
      </Modal>
    )
  }
}

export default OtherErrorModal;