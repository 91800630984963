import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
// Packages
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import MaskedTextInput from 'react-text-mask';

// Utils
import {
  validateEmail,
  getStrippedNumberFromString,
} from '../../utils/helpers';

class MarketingContactForm extends Component {

  state = {
    input: {
      name: {
        value: "",
        hasError: "",
      },
      phone_number: {
        value: "",
        hasError: "",
      },
      email: {
        value: "",
        hasError: "",
      },
      inquiry: {
        value: "",
        hasError: "",
      },
      message: {
        value: "",
        hasError: "",
      },
    },
    errorsArePresent: false,
    userTriedToSubmit: false
  }

  handleInputChange(newPartialInput) {
    const { userTriedToSubmit } = this.state;

    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }), () => {
      if (userTriedToSubmit) {
        this.checkForAnyErrorsPresent();
      }
    });
  }

  resetInputFields = () => {
    this.setState(state => ({
      ...state,
      input: {
        name: {
          value: "",
          hasError: "",
        },
        phone_number: {
          value: "",
          hasError: "",
        },
        email: {
          value: "",
          hasError: "",
        },
        inquiry: {
          value: "",
          hasError: "",
        },
        message: {
          value: "",
          hasError: "",
        },
      },
    }))
  }

  checkForAnyErrorsPresent = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;

      Object.entries(newInputState).forEach(([key, value]) => {
        if (value.hasError === true) {
          errorsArePresent = true;
        }
      });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        errorsArePresent: true
      }))
    } else {
      this.setState(state => ({
        ...state,
        errorsArePresent: false
      }))
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateFormAndSetErrorsIfNecessary()) {
      let { input } = this.state;

      let inputValues = {
        name: input.name.value,
        email: input.email.value,
        phone_number: getStrippedNumberFromString(input.phone_number.value),
        inquiry: input.inquiry.value,
        message: input.message.value
      }

      this.props.handleSubmit(inputValues);
      this.resetInputFields();
    }
  }

  validateFormAndSetErrorsIfNecessary = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'email') {
        if (!validateEmail(value.value)) {
          newInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === 'phone_number') {
        if (getStrippedNumberFromString(value.value) === '') {
          newInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (value.value === '') {
        newInputState[key].hasError = true;
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState,
        errorsArePresent: true,
        userTriedToSubmit: true
      }))
    } else {
      return true;
    }
  }

  renderFormErrorMessage() {
    return (
      <div className="marketing-contact-form-error-message">
        Please fix any errors before submitting.
      </div>
    )
  }

  render() {
    const { input, errorsArePresent } = this.state;

    var selectOptions = [
      { value: 'Issue', label: ' I have an issue (account or payment)' },
      { value: 'Something Else', label: 'Question about something else' }
    ];

    return (
      <form
        className="marketing-contact-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <div className="primary-input-container">
          <input
            className={`marketing-contact-form-primary-input${input.name.hasError ? ' error' : ''}`}
            name="name"
            type="text"
            placeholder="Your Name"
            value={input.name.value}
            onChange={event => this.handleInputChange({name: {value: event.target.value, hasError: false}})}
          />
          <MaskedTextInput
            className={`marketing-contact-form-primary-input${input.phone_number.hasError ? ' error' : ''}`}
            name="phone"
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder="Phone Number"
            placeholderChar={'\u2000'}
            guide={true}
            type="text"
            value={input.phone_number.value}
            onChange={event => this.handleInputChange({phone_number: {value: event.target.value, hasError: false}})}
          />
          <input
            className={`marketing-contact-form-primary-input${input.email.hasError ? ' error' : ''}`}
            name="email"
            type="text"
            placeholder="Your Email"
            value={input.email.value}
            onChange={event => this.handleInputChange({email: {value: event.target.value, hasError: false}})}
          />
          <Select
            className={`form-select  marketing-contact-form-primary-input${input.inquiry.hasError ? ' error' : ''}`}
            name="form-field-name"
            value={input.inquiry.value}
            placeholder="Inquiring about..."
            options={selectOptions}
            resetValue=""
            onChange={event => this.handleInputChange({inquiry: {value: event ? event.value : '', hasError: false}})}
          />
        </div>
        <textarea
          name="message"
          className={input.message.hasError ? ' error' : ''}
          type="textarea"
          placeholder="Your Message"
          value={input.message.value}
          onChange={event => this.handleInputChange({message: {value: event.target.value, hasError: false}})}
        >
        </textarea>
        {errorsArePresent ? this.renderFormErrorMessage() : null}
        <input type="submit" value="Submit" className="cta submit-btn-align" />
        <Link to="/" className="text-link submit-btn-align" style={{textAlign: 'center'}}>
          {"Back to Home"}
        </Link>
      </form>
    );
  }
}

export default MarketingContactForm;
