import React, { useState } from "react";
import styles from "./button.module.scss";
import { ReactComponent as BackIcon } from "../../assets/icons/BackIcon.svg";
import { ReactComponent as BackIconLight } from "../../assets/icons/BackIconLight.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/AddIcon.svg";
import { ReactComponent as AddIconLight } from "../../assets/icons/AddIconLight.svg";

function SecondaryButton({
  icon,
  text,
  onClick,
  title,
  isBackButton,
  style,
  isAddButton,
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      title={title}
      style={style}
      className={`${styles.secondaryButton} ${styles.flex}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isBackButton ? (
        isHovered ? (
          <BackIconLight width={20} height={20} />
        ) : (
          <BackIcon width={15} height={15} />
        )
      ) : (
        isAddButton &&
        (isHovered ? (
          <AddIconLight width={20} height={20} />
        ) : (
          <AddIcon width={15} height={15} />
        ))
      )}
      <div>{text}</div>
    </div>
  );
}

export default SecondaryButton;
