import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom"

// Utils
import * as API from '../utils/api';
import { validateCompanyEmail, validateEmail, networkMessage, showToast } from '../utils/helpers';
import deb_logo from '../assets/icons/deb_logo.png';

class ConfigureForm extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    input: {
      companyName: {
        value: "",
        hasError: false
      },
      companyEmail: {
        value: "",
        hasError: false
      },
      adminEmail: {
        value: "",
        hasError: false
      },
      senderEmail: {
        value: "",
        hasError: false
      },
      companyAddress: {
        value: "",
        hasError: false
      }
    },
    accessError: false,
    error: {
      hasAnyError: false,
      statusCode: 200
    },
    isAppConfigure: false,
    nextFlag: false
  }

  componentWillMount() {
    var isConf = localStorage.getItem("isAppConfigured") || false;
    if (isConf == false || isConf == "false") {
      isConf = this.getConfiguredStatus();
      this.setState(state => ({
        ...state,
        loading: false,
        isAppConfigure: isConf
      }))
    } else {
      this.setState(state => ({
        ...state,
        loading: false,
        isAppConfigure: true
      }))
    }
    var appsName = this.getAppData();

  }

  componentWillReceiveProps() {
    let me = this;
    if (this.props.appData) {
      me.state.input.companyName.value = this.props.appData.company_name;
      me.state.input.companyEmail.value = this.props.appData.company_email;
      me.state.input.adminEmail.value = this.props.appData.admin_email;
      me.state.input.senderEmail.value = this.props.appData.sender_email;
      me.state.input.companyAddress.value = this.props.appData.company_address;
      me.setState(state => ({
        ...state,
        input: {
          ...state.input
        }
      }))
    }
  }

  async getAppData() {
    await API.getAppInfo().then(data => {
      if ((typeof data != 'undefined' || data != null) && (!data.errors)) {
        if (data.appData) {
          this.state.input.companyName.value = this.props.appData.company_name;
          this.state.input.companyEmail.value = this.props.appData.company_email;
          this.state.input.adminEmail.value = this.props.appData.admin_email;
          this.state.input.senderEmail.value = this.props.appData.sender_email;
          this.state.input.companyAddress.value = this.props.appData.company_address;
          this.setState(state => ({
            ...state,
            input: {
              ...state.input
            }
          }))
        }
      }
    });
  }

  async getConfiguredStatus() {
    var me = this;
    await API.isAppConfigure().then(data => {
      if ((typeof data != 'undefined' || data != null) && (!data.errors)) {
        if (data.isAppConfigured) {
          me.setState(state => ({
            ...state,
            loading: false,
            isAppConfigure: true
          }))
          return true;
        } else {
          me.setState(state => ({
            ...state,
            loading: false,
            isAppConfigure: false
          }))
          return false;
        }
      } else {
        me.setState(state => ({
          ...state,
          loading: false,
          isAppConfigure: false
        }))
        return false;
      }
    });
  }



  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }))
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.validateForm()) {
      const company_data = {
        'company_name': this.state.input.companyName.value,
        'company_email': this.state.input.companyEmail.value,
        'admin_email': this.state.input.adminEmail.value,
        'sender_email': this.state.input.senderEmail.value,
        'company_address': this.state.input.companyAddress.value,
        'is_configured': true
      }

      API.configureApp(company_data).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          if (data.isAppConfigured) {
            this.setState(state => ({
              ...state,
              input: {
                ...state.input
              },
              isAppConfigure: true,
              nextFlag: true
            }))
          } else {
            this.setState(state => ({
              ...state,
              input: {
                ...state.input
              },
              isAppConfigure: false
            }))
          }
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      })
    } else {
      console.log("in handleSubmit ::: false");
    }
  }

  validateForm = () => {
    let newInputState = { ...this.state.input }
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'companyEmail') {
        if (!validateCompanyEmail(value.value)) {
          newInputState[key].hasError = true
        }
      } else if (key === 'adminEmail' || key === 'senderEmail') {
        if (!validateEmail(value.value)) {
          newInputState[key].hasError = true
        }
      } else if (value.value == '') {
        newInputState[key].hasError = true
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true
    }
  }

  renderErrorMessage(input) {
    let message = ''
    if (input === 'companyEmail' || input === 'adminEmail' || input === 'senderEmail') {
      message = 'Please enter a valid email'
    } else {
      message = 'Please make sure to complete this field'
    }
    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    const { appName } = this.props;
    const { input, accessError, location, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    if (this.state.nextFlag == true) {
      return <Redirect to={{ pathname: '/appThirdPartyConfig' }} />
    }


    return (
      <div>
        {accessError &&
          <div className='input-container error'>
            {this.renderErrorMessage('account')}
          </div>
        }
        <form className="sessions-form configure-form" onSubmit={(event) => this.handleSubmit(event)}>

          <div
            className={`input-container${input.companyName.hasError ? ' error' : ''}`}
          >
            <label>Organization Name</label>
            <input
              name="companyName"
              type="text"
              value={input.companyName.value}
              onChange={event => this.handleInputChange(
                { companyName: { value: event.target.value, hasError: false } }
              )}
            />
            {input.companyName.hasError ? this.renderErrorMessage('companyName') : null}
          </div>
          <div className={`input-container${input.companyEmail.hasError ? ' error' : ''}`} >
            <label >Website Url</label>
            <input
              name="companyEmail"
              type="url"
              value={input.companyEmail.value}
              onChange={event => this.handleInputChange(
                { companyEmail: { value: event.target.value, hasError: false } }
              )}
            />
            {input.companyEmail.hasError ? this.renderErrorMessage('companyEmail') : null}
          </div>

          <div className={`input-container${input.adminEmail.hasError ? ' error' : ''}`} >
            <label >Admin Email Address</label>
            <input
              name="adminEmail"
              type="email"
              value={input.adminEmail.value}
              onChange={event => this.handleInputChange(
                { adminEmail: { value: event.target.value, hasError: false } }
              )}
            />
            {input.adminEmail.hasError ? this.renderErrorMessage('adminEmail') : null}
          </div>

          <div className={`input-container${input.senderEmail.hasError ? ' error' : ''}`} >
            <label >Sender Email Address</label>
            <input
              name="senderEmail"
              type="email"
              value={input.senderEmail.value}
              onChange={event => this.handleInputChange(
                { senderEmail: { value: event.target.value, hasError: false } }
              )}
            />
            {input.senderEmail.hasError ? this.renderErrorMessage('senderEmail') : null}
          </div>

          <div className={`input-container${input.companyAddress.hasError ? ' error' : ''}`} >
            <label >Organization Address</label>
            <textarea
              name="companyAddress"
              type="textarea"
              placeholder="Organization Address"
              rows={3}
              value={input.companyAddress.value}
              onChange={event => this.handleInputChange(
                { companyAddress: { value: event.target.value, hasError: false } }
              )}
              required />
            {input.companyAddress.hasError ? this.renderErrorMessage('companyAddress') : null}
          </div>

          <input className="cta" type="submit" value="Next" />
        </form>
        <div className="copyrightText">
          <span className="copyrightSpanText">
            Copyright © 2018 {appName} - All Rights Reserved.
            	</span>
        </div>
      </div>
    )
  }
}

export default withRouter(ConfigureForm)
