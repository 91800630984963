import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// import ReactGA from 'react-ga';

// Components
import MarketingHeader from "../components/Marketing/MarketingHeader";
import MarketingFooter from "../components/Marketing/MarketingFooter";
import MarketingContactForm from "../components/Marketing/MarketingContactForm";
import FlashMessage from "../components/FlashMessage";

// Utils
import * as API from "../utils/api";
import { showToast, getZohoPagesense } from "../utils/helpers";

class MarketingContact extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
  };

  state = {
    showFlash: false,
    flashMessage: "",
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/MarketingContact");
  }

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  handleContactFormSubmit = (inputs) => {
    let messageObjectForSubmit = {
      contact_form: {
        name: inputs.name,
        email: inputs.email,
        phone_number: inputs.phone_number,
        topic: inputs.inquiry,
        message: inputs.message,
      },
    };

    API.marketingContact(messageObjectForSubmit).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.setState((state) => ({
          ...state,
          showFlash: true,
          flashMessage:
            "Thanks! Your message was sent successfully, and we'll be in touch with you soon.",
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  render() {
    const { appName } = this.props;
    const { error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div className='landing'>
        <Helmet>
          <title>{appName} | Contact Us</title>
        </Helmet>
        <MarketingHeader
          path={this.props.location}
          appName={this.props.appName}
        />
        <section className='hero'>
          <div className='mini-wrap'>
            <h1>Contact</h1>
            <p>
              Questions or concerns? We’re happy to chat. Fill out the form
              below and we will respond to your messages as soon as we can.
            </p>
          </div>
        </section>
        <section>
          <div className='mini-wrap'>
            {this.state.showFlash ? (
              <FlashMessage
                message={this.state.flashMessage}
                handleFlashDismiss={this.dismissFlash}
              />
            ) : null}
            <MarketingContactForm handleSubmit={this.handleContactFormSubmit} />
          </div>
        </section>
        <MarketingFooter
          appName={this.props.appName}
          companyAddress={this.props.companyAddress}
          adminEmail={this.props.adminEmail}
        />
      </div>
    );
  }
}

export default MarketingContact;
