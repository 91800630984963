import React, { Component } from 'react';
import MaskedTextInput from 'react-text-mask';
import { withRouter, Redirect } from "react-router-dom";

// Utils
import * as API from '../../utils/api';
import { isValidDate, showToast } from '../../utils/helpers';

class OnboardingVerifyIdentityForm extends Component {

  state = {
    input: {
      birthday: {
        value: "",
        hasError: false
      },
      ssn: {
        value: null,
        hasError: false
      },
    },
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const userUpdate = {
        "date_of_birth": this.state.input.birthday.value,
        "ssn": this.state.input.ssn.value,
      }
      API.updateUser(this.props.user, userUpdate).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && (!data.errors)) {
          this.props.history.push("/onboarding/user-agreement");
        }else{
          this.setState(state => ({
             ...state,
             error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      });
    }
  }

  hasOnlyDigits = (string) => {
    return /^\d+$/.test(string);
  }

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'birthday') {
        if (!isValidDate(value.value, 2017)) {
          newInputState[key].hasError = true;
          errorsArePresent = true
        }
      } else if (key === 'ssn'){
        if (value.value === null || !this.hasOnlyDigits(value.value) || value.value.length !== 4) {
          newInputState[key].hasError = true;
        }
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true;
    }
  }

  renderErrorMessage(input) {
    let message = '';

    if (input === 'birthday') {
      message = 'Please make sure date is valid';
    } else {
      message = 'Please make sure to complete this field';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    const { input, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <form className="sessions-form" onSubmit={(event) => this.handleSubmit(event)}>
        <div
          className={`input-container${input.birthday.hasError ? ' error' : ''}`}
        >
          <label>Date of Birth</label>
          <MaskedTextInput
            guide={true}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            name="birthday"
            placeholder="mm/dd/yyyy"
            placeholderChar={'\u2000'}
            type="text"
            value={input.birthday.value}
            onChange={event => this.handleInputChange(
              { birthday: { value: event.target.value, hasError: false } }
            )}
          />
          {input.birthday.hasError ? this.renderErrorMessage('birthday') : null}
        </div>
        <div
          className={`input-container${input.ssn.hasError ? ' error' : ''}`}
        >
          <label>Last 4 digits of SSN</label>
          <MaskedTextInput
            name="ssn"
            mask={[/\d/, /\d/, /\d/, /\d/]}
            placeholder="####"
            placeholderChar={'\u2000'}
            guide={true}
            type="text"
            value={input.ssn.value}
            onChange={event => this.handleInputChange(
              { ssn: { value: event.target.value, hasError: false } }
            )}
          />
          {input.ssn.hasError ? this.renderErrorMessage('ssn') : null}
        </div>
        <div className="sessions-form-note">
          <svg className="icon"><use xlinkHref="#lock" /></svg>
          <p>
            We understand the need for your privacy and the security with your information. We use a 256-bit encryption to ensure none of your information gets stolen.
          </p>
        </div>
        <input className="cta" type="submit" value="Next »" />
      </form>
    );
  }
}

export default withRouter(OnboardingVerifyIdentityForm);
