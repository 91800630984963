import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";

class InvoiceListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        }
    }

    componentDidMount() {
        let { optionalProps } = this.props;
        let { invoice_list } = optionalProps;
        this.setState({list: invoice_list});
    }

    goToInvoice = (item) => {
        let { history } = this.props;
        history.push(`/client/invoices/${item.id}`);
        this.handleModalClose();
    }

    handleModalClose = () => {
        this.props.hideModal();
    };

    //render table headers
    getHeaders = () => {
        return (
        <div style={StyleSheet.headerView}>
            <h4 style={StyleSheet.headerLabel}>Invoice Number</h4>
            <h4 style={StyleSheet.headerLabel1}>Action</h4>
        </div>
        );
    };

    render() {
        let { list } = this.state;
        let { optionalProps } = this.props;
        let { invoice_list } = optionalProps;
        return(
            <Modal
                title={"Invoice List"}
                closeOnClickOutside={true}
                hideModal={this.props.hideModal}
                optionalClasses="scroll"
            >
                <div style={{ marginTop: "0.5em" }}>
                    {this.getHeaders()}
                    {list && list.length
                    ?   list.map((item, i) => {
                        return(
                            <div key={"_" + i} style={StyleSheet.tableBody}>
                                <div style={StyleSheet.invoiceID}>
                                    {`#${item.invoice_number}`}
                                </div>

                                <div className="in-new-btn" style={StyleSheet.buttonWrapper}>
                                    <button
                                        id={`xyz` + i}
                                        style={StyleSheet.refundBtn}
                                        onClick={(event) => this.goToInvoice(item)}
                                    >
                                        Go to details
                                    </button>
                                </div>
                            </div>
                        )
                    })
                :   null}
                    
                </div>
            </Modal>
        )
    }
}

let StyleSheet = {
    headerView: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        padding: "0.5em",
    },
    tableBody: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        border: "1px solid #e1e1e1",
        borderRadius: 4,
        marginBottom: "1em",
        padding: "0.5em",
        
    },
    headerLabel: {
        margin: "0px 10px 0px 0px",
        width: "100%",
        fontSize: "1em",
        color: "#9b9b9b",
        flex: 5
    },
      headerLabel1: {
        margin: 0,
        width: "100%",
        fontSize: "1em",
        color: "#9b9b9b",
        flex: 3
    },
    refundBtn: { padding: "1em 2em 0.8em", margin: 0, fontWeight: 600 },
    fullWidth: { margin: 0, width: "100%" },
    dateValue: { margin: "0px 10px 0px 0px", width: "100%" },
    invoiceID: {
        margin: 0,
        width: "100%",
        flex: 5
    },
    buttonWrapper: {
        margin: 0,
        width: "100%",
        flex: 3
    }
}

export default withRouter(InvoiceListModal);