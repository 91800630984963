import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import AppClientHeader from "../../../components/App/AppClientHeader";
import ClientsNav from "../../../components/Clients/ClientsNav";
import MGLogoIcon from "../../../assets/icons/MG-logo_icon.png";
import subDomainLoader from "../../../assets/icons/sub_domain_loader.gif";
import * as API from "../../../utils/api";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import $ from "jquery";

class CreateSubDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
        subDomain: "",
        isSubDomainError: false,
        subDomainErrorText: "",
      },
      widthOfDomainInputField: "30px",
    };
    this.spanRef = React.createRef();
  }
  componentDidMount() {
    //get initial dashboard details
    if (this.props == undefined || this.props.clientName == "") {
      if (this.props.user) {
        this.props.history.push("/sign-out");
      } else {
        if (this.props == undefined) {
          return <Redirect to={{ pathname: "/login" }} />;
        } else {
          this.props.history.push("/login");
        }
      }
    }
  }

  handleSubDomainInputChange = (e) => {
    this.setState({ subDomain: e.target.value });
  };

  spinnerHandler = (loadingState) => {
    if (loadingState) {
      $("#send-new-mail").replaceWith(
        '<div id = "spinner" class="spinner"></div>'
      );
    } else {
      $("#spinner").replaceWith(
        '<div id="send-new-mail" className="modal-actions double-btns"> </div>'
      );
    }
  };

  nextClick = () => {
    const cookies = new Cookies();
    if (
      this.validate(
        this.props.user.email,
        this.state.subDomain,
        this.props.history.location.state.organization_key
      )
    ) {
      let payload = {
        sso_user_email: this.props.user.email,
        org_url:
          process.env.REACT_APP_STAGE === "production"
            ? this.state.subDomain + ".makegoodapp.com"
            : this.state.subDomain + ".accountpayonline.com",
        organization_key: this.props.history.location.state.organization_key,
      };
      this.state.isLoading = true;
      this.spinnerHandler(this.state.isLoading);
      API.saveSubdomain(this.props.user, payload).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.status_code == 200
        ) {
          cookies.remove("authUser", { path: "/", secure: true });
          cookies.remove("isLoggedIn", { path: "/", secure: true });
          cookies.remove("accessToken", { path: "/", secure: true });
          cookies.remove("refreshToken", { path: "/", secure: true });
          this.setState({ isLoading: false });
          this.spinnerHandler(this.state.isLoading);
          if (data.data) {
            window.location.href =
              data.data.sudomain_name +
              "?id_token=" +
              data.data.id_token +
              "&email=" +
              data.data.email +
              "&sub_id=" +
              data.data.sub_id;
          } else {
            if (this.props.user) {
              this.props.history.push("/sign-out");
            } else {
              if (this.props == undefined) {
                return <Redirect to={{ pathname: "/login" }} />;
              } else {
                this.props.history.push("/login");
              }
            }
          }
          window.location.replace(
            data.data.sudomain_name +
              "/welcome-screen?id_token=" +
              data.data.id_token +
              "&email=" +
              data.data.email +
              "&sub_id=" +
              data.data.sub_id
          );
        } else {
          this.setState({ isLoading: false });
          this.spinnerHandler(this.state.isLoading);
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      });
    } else {
      if (this.state.subDomainErrorText) {
        this.props.updateModalState(true, "ERROR", {
          title: "Error",
          message: this.state.subDomainErrorText,
        });
        // this.setState({subDomainErrorText:""})
      } else {
        this.props.updateModalState(true, "ERROR", {
          title: "Error",
          message: "somthing went wrong please try again.",
        });
        if (this.props == undefined || this.props.clientName == "") {
          if (this.props.user) {
            this.props.history.push("/sign-out");
          } else {
            if (this.props == undefined) {
              return <Redirect to={{ pathname: "/login" }} />;
            } else {
              this.props.history.push("/login");
            }
          }
        }
      }
    }
  };

  validate = (email, subDomain, organization_key) => {
    if (
      email === null ||
      email === "" ||
      email === undefined ||
      organization_key === null ||
      organization_key === "" ||
      organization_key == undefined
    ) {
      return <Redirect to={{ pathname: "/login" }} />;
    } else {
      let subDomainRegex = /^[A-Za-z0-9\-]+$/g;
      if (subDomain == null || subDomain == "" || subDomain == undefined) {
        let errorMsg = "Please input subdomain text.";
        // this.setState({isSubDomainError: true, subDomainErrorText: errorMsg});
        this.state.subDomainErrorText = errorMsg;
        return false;
      } else if (subDomain && !subDomainRegex.test(subDomain)) {
        let errorMsg = "Please enter valid subdomain text.";
        // this.setState({isSubDomainError: true, subDomainErrorText: errorMsg});
        this.state.subDomainErrorText = errorMsg;
        return false;
      } else if (subDomain && subDomain.trim().length > 30) {
        let errorMsg = "Maximum length of Sub-domain should be 30 characters.";
        this.state.subDomainErrorText = errorMsg;
        return false;
      } else {
        this.setState({ isSubDomainError: false, subDomainErrorText: "" });
        return true;
      }
    }
  };

  render() {
    const { appName,user } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div className="app-portal">
        <AppClientHeader
          user={this.props.user}
          appName={this.props.appName}
          logoPath="/client/accounts/"
          hideNav={true}
          headerTab={this.props.headerTab}
          disableNav={true}
          hideHeadNavOpt={true}
        />
        <div className={`app-portal-canvas consumer-portal canvas-back`}>
          <ClientsNav path={this.props.location} user={user} disableNav={true} />
          <div className="wrap" class="sso-dashboard-cls he-hed">
            <div>
              <div class="company-logo-center">
                <img class="companyLogoDiv-consumer-view" src={MGLogoIcon} />
              </div>
            </div>
            <div
              style={{
                margin: "40px",
              }}
            >

              <p class="domain-label-cls">
                Enter the web domain name of your choice here
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1rem",
                  justifyContent: "center",
                }}
              >
                <input
                  className="multi-input-field-input sub-domain-input mbwt"
                  name="subDomain"
                  type="text"
                  style={{ width: "50%" }}
                  value={this.state.subDomain}
                  onChange={(event) => this.handleSubDomainInputChange(event)}
                />
                <p className="mbdomain">
                  {process.env.REACT_APP_STAGE === "production"
                    ? ".makegoodapp.com"
                    : ".accountpayonline.com"}
                </p>
              </div>
              <p class="domain-hint-cls">
              This will complete the custom web domain that your organization uses to log in. Customers will also see this domain when they access your company-branded Billing and Payment Portal.{" "}
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <a
                className="cta mg-brand2-color"
                onClick={(event) => this.nextClick(event)}
              >
                Next
              </a>
              <div id="send-new-mail" className="modal-actions double-btns">
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateSubDomain;
