import $ from "jquery";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import {
  amountMask,
  getStrippedDollarAmount,
  quantityMask,
  isAlphaNumericWithHyphenUnderscore,
  isValidDate,
} from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import InfoIcon from "../../../../assets/images/quickbooks/ic_info_gray.png";
import moment from "moment";
import Emitter from "../../../../utils/event-emitter";

class AddServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        name: {
          value: "",
          hasError: false,
        },
        rate: {
          value: "",
          hasError: false,
        },
        description: {
          value: "",
          hasError: false,
        },
        mapToAccount: {
          value: "",
          hasError: false,
          account: "",
        },
        item_sku: {
          value: "",
          hasError: false,
        },
        inv_start_date: {
          value: "",
          hasError: false,
        },
        quantity_on_hand: {
          value: "",
          hasError: false,
        },
        item_reorder_point: {
          value: "",
          hasError: false,
        },
        invAssetAccount: {
          value: "",
          hasError: false,
          assetAccount: "",
        },
        cost_of_good: {
          value: "",
          hasError: false,
        },
        expenseAccount: {
          value: "",
          hasError: false,
          expenseAccount: "",
        },
      },
      incomeAccountsList: [],
      assetAccountsList: [],
      expenseAccountsList: [],
      isTaxable: false,
      isInventoryTracking: false,
      isDefaultService: false,
      isLoading: true,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    let { input, disableInventoryTrack } = this.state;
    let { optionalProps } = this.props;
    let { rowData } = optionalProps;

    if (rowData && rowData.id) {
      input.name.value = rowData.name;
      input.rate.value = rowData.rate
        ? parseFloat(rowData.rate).toFixed(2)
        : rowData.rate;
      input.description.value = rowData.description;
      if (rowData.is_inv_enable) {
        input.item_sku.value = rowData.item_sku;
        input.inv_start_date.value = rowData.inv_start_date;
        input.quantity_on_hand.value = rowData.quantity_on_hand;
        input.item_reorder_point.value = rowData.item_reorder_point;
        input.cost_of_good.value = rowData.cost_of_good;
      }

      this.setState(
        {
          isTaxable: rowData.is_taxable,
          isInventoryTracking: rowData.is_inv_enable,
          input: input,
          isDefaultService: rowData.is_default,
          rowData: rowData,
        },
        () => {
          this.getServiceAccountList(
            rowData.income_account_id,
            rowData.expense_account_id,
            rowData.inv_asset_account_id
          );
        }
      );
    } else {
      this.getServiceAccountList();
    }
  }

  getServiceAccountList = (
    accountId,
    expense_account_id,
    inv_asset_account_id
  ) => {
    API.getServiceAccountList(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          data.data.income_accounts.map((account) => {
            account.label = account.name;
            account.value = account.name;
          });
          data.data.expense_accounts.map((expenseAccount) => {
            expenseAccount.label = expenseAccount.name;
            expenseAccount.value = expenseAccount.name;
          });
          data.data.asset_accounts.map((assetAccount) => {
            assetAccount.label = assetAccount.name;
            assetAccount.value = assetAccount.name;
          });
          this.setState(
            {
              incomeAccountsList: data.data.income_accounts,
              expenseAccountsList: data.data.expense_accounts,
              assetAccountsList: data.data.asset_accounts,
            },
            () => {
              this.setAccountForEditService(
                accountId,
                inv_asset_account_id,
                expense_account_id
              );
            }
          );
        }
        this.setState({ isLoading: false });
      } else {
        this.handleError(data);
      }
    });
  };

  setAccountForEditService = (
    accountId,
    invAssetAccountId,
    expenseAccountId
  ) => {
    var me = this;
    let { incomeAccountsList, assetAccountsList, expenseAccountsList } =
      this.state;
    if (this.state.isInventoryTracking) {
      let incomeAccountsListData = [];
      this.state.incomeAccountsList.map((accountObj) => {
        if (accountObj.sub_account_type == "SalesOfProductIncome") {
          incomeAccountsListData.push(accountObj);
        }
      });
      me.state.incomeAccountsList = incomeAccountsListData;
    }

    let account = this.state.incomeAccountsList.filter(
      (item) => item.id == accountId
    );
    if (account && account.length) {
      setTimeout(() => {
        this.setState(
          {
            ...this.state,
            input: {
              ...this.state.input,
              mapToAccount: {
                value: account[0].name,
                account: account[0],
              },
            },
          },
          () => {}
        );
      }, 100);
    }

    let assetAccount = assetAccountsList.filter(
      (item) => item.id == invAssetAccountId
    );
    if (assetAccount && assetAccount.length) {
      setTimeout(() => {
        this.setState(
          {
            ...this.state,
            input: {
              ...this.state.input,
              invAssetAccount: {
                value: assetAccount[0].name,
                assetAccount: assetAccount[0],
              },
            },
          },
          () => {}
        );
      }, 100);
    }

    let expenseAccount = expenseAccountsList.filter(
      (item) => item.id == expenseAccountId
    );
    if (expenseAccount && expenseAccount.length) {
      this.setState(
        {
          ...this.state,
          input: {
            ...this.state.input,
            expenseAccount: {
              value: expenseAccount[0].name,
              expenseAccount: expenseAccount[0],
            },
          },
        },
        () => {}
      );
    }
  };

  getFormattedDate = (date) => {
    if (date) return moment(new Date(date)).format("YYYY-MM-DD");
    return date;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let { input, isTaxable, rowData, isInventoryTracking, isDefaultService } =
      this.state;
    let { optionalProps } = this.props;
    let activeTab = optionalProps.activeTab;
    let type =
      activeTab == 1
        ? isInventoryTracking
          ? "Inventory"
          : "NonInventory"
        : "Service";
    if (this.validateForm()) {
      let payload = {
        type: type,
        name: input.name.value,
        description: input.description.value,
        rate: getStrippedDollarAmount(input.rate.value).toFixed(2),
        is_taxable: isTaxable,
        item_sku: isInventoryTracking ? input.item_sku.value : "",
        inv_start_date: isInventoryTracking
          ? this.getFormattedDate(input.inv_start_date.value)
          : "",
        quantity_on_hand: isInventoryTracking
          ? input.quantity_on_hand.value
          : 0,
        item_reorder_point: isInventoryTracking
          ? input.item_reorder_point.value
          : "",
        cost_of_good: isInventoryTracking
          ? getStrippedDollarAmount(input.cost_of_good.value).toFixed(2)
          : 0,
        is_inv_enable: isInventoryTracking,
        is_rate_per_unit: type == "Service" ? false : true,
        income_account_id:
          input.mapToAccount &&
          input.mapToAccount.account &&
          input.mapToAccount.account.id
            ? input.mapToAccount.account.id
            : "",
        inv_asset_account_id:
          input.invAssetAccount &&
          input.invAssetAccount.assetAccount &&
          input.invAssetAccount.assetAccount.id &&
          isInventoryTracking
            ? input.invAssetAccount.assetAccount.id
            : "",
        expense_account_id:
          input.expenseAccount &&
          input.expenseAccount.expenseAccount &&
          input.expenseAccount.expenseAccount.id &&
          isInventoryTracking
            ? input.expenseAccount.expenseAccount.id
            : "",
        is_default: isDefaultService,
      };
      if (rowData && rowData.id) {
        payload.id = rowData.id;
        this.handleUpdate(payload);
      } else {
        this.handleAddService(payload);
      }
    }
  };

  handleAddService = (payload) => {
    API.clientAddServices(this.props.user, payload).then((data) => {
      this.handleCommonResponse(data);
    });
  };

  handleUpdate = (payload) => {
    this.setState({ isLoading: true });
    API.clientUpdateServices(this.props.user, payload).then((data) => {
      this.handleCommonResponse(data);
      this.setState({ isLoading: false });
    });
  };

  handleCommonResponse = (data) => {
    if (data && data.status_code) {
      if (data.status_code === 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
      } else if (data.status_code === 422) {
        Emitter.emit("NOTIFICATION_ALERT", true);
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
      this.props.optionalProps.reload(false);
      this.setState({ isLoading: false });
    } else {
      this.handleError(data);
    }
  };

  handleError = (data) => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
      error: {
        hasAnyError: true,
        message: data && data.error ? data.error : "",
        statusCode: data
          ? data.status ||
            (data.message && data.message == "Access denied." ? 401 : 500) ||
            500
          : 500,
      },
    }));
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleInputBlur = () => {
    let { input } = this.state;
    if (input.rate.value) {
      if (!input.rate.value.includes(".")) {
        input.rate.value = input.rate.value + ".00";
      }
    }
    this.setState({ input });
  };

  handleToggle = (e, type) => {
    e.preventDefault();
    if (type === "isDefaultService" && this.state.isDefaultService) {
      this.props.updateModalState(true, "ERROR", {
        message: "Atleast one service should be set as a default service.",
        title: "Alert",
      });
    } else {
      this.setState({ [type]: !this.state[type] });
    }
  };

  handleInventoryToggle = (e, type) => {
    e.preventDefault();
    let incomeAccountList = [];
    let { optionalProps } = this.props;
    let { rowData } = optionalProps;
    if (!this.state[type]) {
      this.state.incomeAccountsList.map((account) => {
        if (account.sub_account_type == "SalesOfProductIncome") {
          incomeAccountList.push(account);
        }
      });
      this.setState({ incomeAccountsList: incomeAccountList });
    } else {
      if (rowData && rowData.is_inv_enable && !rowData.is_inv_enable) {
        this.getServiceAccountList(
          rowData.account_id,
          rowData.expense_account_id,
          rowData.inv_asset_account_id
        );
      } else {
        this.getServiceAccountList();
      }
    }
    this.setState({ [type]: !this.state[type] });
  };
  validateForm = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    let { isInventoryTracking } = this.state;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "rate") {
        if (
          getStrippedDollarAmount(value.value) < 0 ||
          getStrippedDollarAmount(value.value) === ""
        ) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "mapToAccount") {
        if (this.props.user.qb_connect) {
          if (value.value === "" || value.value === null) {
            errorsArePresent = true;
            newInputState[key].hasError = true;
          }
        }
      } else if (key === "description") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (isInventoryTracking && key === "inv_start_date") {
        if (
          value.value === "" ||
          value.value === null ||
          !isValidDate(moment(value.value).format("MM/DD/YYYY"))
        ) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (isInventoryTracking && key === "quantity_on_hand") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      } else if (isInventoryTracking && key === "item_sku") {
        if (value.value) {
          if (!isAlphaNumericWithHyphenUnderscore(value.value)) {
            errorsArePresent = true;
            newInputState[key].hasError = true;
          }
        }
      } else if (isInventoryTracking && key === "invAssetAccount") {
        if (this.props.user.qb_connect) {
          if (value.value === "" || value.value === null) {
            errorsArePresent = true;
            newInputState[key].hasError = true;
          }
        }
      } else if (isInventoryTracking && key === "expenseAccount") {
        if (this.props.user.qb_connect) {
          if (value.value === "" || value.value === null) {
            errorsArePresent = true;
            newInputState[key].hasError = true;
          }
        }
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let { isInventoryTracking } = this.state;
    let message = "";
    if (input === "name") {
      message = "Please enter a name";
    } else if (input === "description") {
      message = "Please enter description";
    } else if (input === "rate") {
      message = "Please enter rate";
    } else if (input === "maptoaccount") {
      message = "Please select account";
    } else if (isInventoryTracking && input === "inv_start_date") {
      message = "Please enter valid date";
    } else if (isInventoryTracking && input === "quantity_on_hand") {
      message = "Please enter number";
    } else if (isInventoryTracking && input === "item_sku") {
      message = "Character should be letters, numbers, underscores and hyphens";
    } else if (isInventoryTracking && input === "inv_asset_account_id") {
      message = "Please select asset account";
    } else if (isInventoryTracking && input === "expenseAccount") {
      message = "Please select expense account";
    } else {
      message = "Please complete this field";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let {
      input,
      error,
      isLoading,
      isTaxable,
      isDefaultService,
      isInventoryTracking,
      incomeAccountsList,
      assetAccountsList,
      expenseAccountsList,
      rowData,
    } = this.state;
    let { optionalProps } = this.props;

    let activeTab = optionalProps.activeTab;
    let isEdit = rowData && rowData.id ? true : false;

    let title =
      activeTab == 1
        ? isEdit
          ? "Edit Goods"
          : "Add Goods"
        : isEdit
        ? "Edit Services"
        : "Add Services";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title={title}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses='scroll'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form>
            <div
              className={`input-container ${
                input.name.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='name'>Name</label>
              <div>
                <input
                  name='name'
                  type='text'
                  maxLength={60}
                  placeholder={
                    activeTab == 1
                      ? "Enter Name of Good or Product"
                      : " Enter Name of Service"
                  }
                  value={input.name.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      name: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.name.hasError ? this.renderErrorMessage("name") : null}
              </div>
            </div>

            <div
              className={`input-container ${
                input.description.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='description'>Description</label>
              <div>
                <input
                  name='description'
                  type='text'
                  placeholder={
                    activeTab == 1
                      ? "Enter Description of Good or Product"
                      : "Enter Description of Service"
                  }
                  value={input.description.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      description: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.description.hasError
                  ? this.renderErrorMessage("description")
                  : null}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className={`input-container`} style={{ display: "flex" }}>
                <label style={{ width: "auto", marginRight: 10 }}>
                  Taxable
                </label>
                <ToggleSwitch
                  isOn={isTaxable}
                  toggleButton={(e) => this.handleToggle(e, "isTaxable")}
                />
              </div>
              {activeTab == 1 ? (
                <div className={`input-container`} style={{ display: "flex" }}>
                  <label style={{ width: "auto", marginRight: 10 }}>
                    Inventory Tracking
                  </label>
                  <ToggleSwitch
                    isOn={isInventoryTracking}
                    isDisable={isEdit && rowData.is_inv_enable ? true : false}
                    toggleButton={(e) =>
                      this.handleInventoryToggle(e, "isInventoryTracking")
                    }
                  />
                </div>
              ) : (
                <div className={`input-container`} style={{ display: "flex" }}>
                  <label style={{ width: "auto", marginRight: 10 }}>
                    Set As Default
                  </label>
                  <ToggleSwitch
                    isOn={isDefaultService}
                    toggleButton={(e) =>
                      this.handleToggle(e, "isDefaultService")
                    }
                  />
                </div>
              )}
            </div>
            <div>
              <label className='inventoryToggleTextCls'>
                {isInventoryTracking
                  ? isEdit
                    ? ""
                    : "You are converting this item to inventory tracked."
                  : ""}
              </label>
            </div>
            <div
              className={`input-container ${
                input.rate.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='rate'>
                {activeTab == 1 ? "Sales Price / Rate" : "Rate Per Hour"}
              </label>
              <div>
                <MaskedTextInput
                  mask={amountMask}
                  name='rate'
                  placeholder='$0'
                  placeholderChar={"\u2000"}
                  type='text'
                  value={input.rate.value}
                  onBlur={() => this.handleInputBlur()}
                  onChange={(event) =>
                    this.handleInputChange({
                      rate: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.rate.hasError ? this.renderErrorMessage("rate") : null}
              </div>
            </div>

            <div
              className={`input-container ${
                input.mapToAccount.hasError ? " error" : ""
              }`}
            >
              <label
                style={{ opacity: !this.props.user.qb_connect ? "0.5" : "1" }}
                htmlFor='item'
              >
                Map To Income Account
              </label>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className='form-select'
                name='item'
                disabled={!this.props.user.qb_connect}
                value={input.mapToAccount.value}
                options={incomeAccountsList}
                onChange={(event) =>
                  this.handleInputChange({
                    mapToAccount: {
                      value: event.value,
                      hasError: false,
                      account: event,
                    },
                  })
                }
              />
              {input.mapToAccount.hasError
                ? this.renderErrorMessage("maptoaccount")
                : null}
            </div>
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.item_sku.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='name'>Item SKU</label>
                <div>
                  <input
                    name='item_sku'
                    type='text'
                    maxLength={60}
                    placeholder={" Enter SKU"}
                    value={input.item_sku.value}
                    onChange={(event) =>
                      this.handleInputChange({
                        item_sku: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.item_sku.hasError
                    ? this.renderErrorMessage("item_sku")
                    : null}
                </div>
              </div>
            ) : null}
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.inv_start_date.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='name'>
                  Inventory Start Date
                  <span style={{ color: "#fb5c82" }}>*</span>
                </label>
                <div>
                  <MaskedTextInput
                    guide={true}
                    mask={[
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    name='inv_start_date'
                    placeholder='mm/dd/yyyy'
                    placeholderChar={"\u2000"}
                    type='text'
                    disabled={isEdit && rowData.is_inv_enable ? true : false}
                    value={
                      isEdit && rowData.is_inv_enable
                        ? moment(rowData.inv_start_date).format("MM/DD/YYYY")
                        : input.inv_start_date.value
                    }
                    style={{ width: "93%" }}
                    onChange={(event) =>
                      this.handleInputChange({
                        inv_start_date: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  <img
                    src={InfoIcon}
                    className='info-tooltip'
                    title='The Inventory Tracking Start Date is the date you are starting or started tracking the quantity on hand of an inventory item. '
                  />
                  {input.inv_start_date.hasError
                    ? this.renderErrorMessage("inv_start_date")
                    : null}
                </div>
              </div>
            ) : null}
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.quantity_on_hand.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='name'>
                  Initial Quantity on Hand
                  <span style={{ color: "#fb5c82" }}>*</span>
                </label>
                <div>
                  <MaskedTextInput
                    mask={quantityMask}
                    name='quantity_on_hand'
                    placeholder=' Enter Number'
                    placeholderChar={"\u2000"}
                    type='text'
                    value={input.quantity_on_hand.value}
                    onChange={(event) =>
                      this.handleInputChange({
                        quantity_on_hand: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.quantity_on_hand.hasError
                    ? this.renderErrorMessage("quantity_on_hand")
                    : null}
                </div>
              </div>
            ) : null}
            {isEdit && rowData.is_inv_enable ? (
              <div>
                <label className='inventoryToggleTextCls'>
                  {rowData.quantity_on_hand != input.quantity_on_hand.value
                    ? isEdit
                      ? "Editing the Quantity On Hand could adversely impact " +
                        this.props.appName +
                        "'s accounting records. Would you like to proceed?"
                      : ""
                    : ""}
                </label>
              </div>
            ) : (
              ""
            )}
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.item_reorder_point.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='name'>Item Reorder Point</label>
                <div>
                  <MaskedTextInput
                    mask={quantityMask}
                    name='item_reorder_point'
                    placeholder=' Enter Number'
                    placeholderChar={"\u2000"}
                    type='text'
                    value={input.item_reorder_point.value}
                    onChange={(event) =>
                      this.handleInputChange({
                        item_reorder_point: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.item_reorder_point.hasError
                    ? this.renderErrorMessage("item_reorder_point")
                    : null}
                </div>
              </div>
            ) : null}
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.invAssetAccount.hasError ? " error" : ""
                }`}
              >
                <label
                  style={{ opacity: !this.props.user.qb_connect ? "0.5" : "1" }}
                  htmlFor='item'
                >
                  Inventory Asset Account
                </label>
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  disabled={!this.props.user.qb_connect}
                  className='form-select'
                  name='inv_asset_account_id'
                  value={input.invAssetAccount.value}
                  options={assetAccountsList}
                  onChange={(event) =>
                    this.handleInputChange({
                      invAssetAccount: {
                        value: event.value,
                        hasError: false,
                        assetAccount: event,
                      },
                    })
                  }
                />
                {input.invAssetAccount.hasError
                  ? this.renderErrorMessage("inv_asset_account_id")
                  : null}
              </div>
            ) : null}
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.cost_of_good.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='rate'>{"Cost of Good"}</label>
                <div>
                  <MaskedTextInput
                    mask={amountMask}
                    name='cost_of_good'
                    placeholder='$0'
                    placeholderChar={"\u2000"}
                    type='text'
                    value={input.cost_of_good.value}
                    onBlur={() => this.handleInputBlur()}
                    onChange={(event) =>
                      this.handleInputChange({
                        cost_of_good: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.cost_of_good.hasError
                    ? this.renderErrorMessage("cost_of_good")
                    : null}
                </div>
              </div>
            ) : null}
            {activeTab == 1 && isInventoryTracking ? (
              <div
                className={`input-container ${
                  input.expenseAccount.hasError ? " error" : ""
                }`}
              >
                <label
                  style={{ opacity: !this.props.user.qb_connect ? "0.5" : "1" }}
                  htmlFor='item'
                >
                  Expense Account
                </label>
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  disabled={!this.props.user.qb_connect}
                  className='form-select'
                  name='expense_account_id'
                  value={input.expenseAccount.value}
                  options={expenseAccountsList}
                  onChange={(event) =>
                    this.handleInputChange({
                      expenseAccount: {
                        value: event.value,
                        hasError: false,
                        expenseAccount: event,
                      },
                    })
                  }
                />
                {input.expenseAccount.hasError
                  ? this.renderErrorMessage("expenseAccount")
                  : null}
              </div>
            ) : null}
            <div className='modal-actions' id='submit-service'>
              <a
                className='cta mg-brand2-color'
                onClick={(event) => this.handleSubmit(event)}
                style={{ paddingLeft: "3em", paddingRight: "3em" }}
                disabled={this.state.isLoading}
              >
                Submit
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

export default withRouter(AddServiceModal);
