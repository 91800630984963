import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { showToast } from "../../../../utils/helpers";
import { Multiselect } from "multiselect-react-dropdown";
import ToggleSwitch from "../../../../components/ToggleSwitch";
// Components
import Modal from "../Modal";

class AddCommunicationProfileModal extends Component {
  state = {
    profileName: {
      value: "",
      hasError: false,
    },
    pref: [
      {
        flag: false,
        name: "Email",
      },
      {
        flag: false,
        name: "Letter",
      },
    ],
    options: [
      {
        id: 1,
        name: "item.name;",
      },
      {
        id: 2,
        name: "item.name12",
      },
      {
        id: 3,
        name: "item.name122344342",
      },
    ],
    setDefault: false,
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    let { isEdit, profileData } = this.props.optionalProps;
    if (isEdit) {
      this.state.profileName.value = profileData.name;
      this.setState({ profileName: this.state.profileName });
    }
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  handleNameChange = (value) => {
    this.state.profileName.value = value;
    this.state.profileName.hasError = false;
    this.setState({ profileName: this.state.profileName });
  };

  callUpdateAPI = () => {};

  callCreateAPI = () => {};

  validateForm = () => {
    let { profileName } = this.state;
    let errorsArePresent = false;
    if (profileName.value === "") {
      errorsArePresent = true;
      profileName.hasError = true;
      this.setState({ profileName });
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = () => {
    let { isEdit } = this.props.optionalProps;
    if (isEdit && this.validateForm()) {
      this.callUpdateAPI();
    } else if (this.validateForm()) {
      this.callCreateAPI();
    }
  };

  toggleButton = () => {
    this.setState({ setDefault: !this.state.setDefault });
  };

  onSelect = (selectedList) => {
    let { document_ids } = this.state;
    document_ids = [];
    if (selectedList && selectedList.length) {
      selectedList.map((item) => {
        document_ids.push(item.document_id);
      });
      this.setState({ document_ids });
    }
  };

  onCheckboxClick = (e, selectedItem) => {
    let { pref } = this.state;
    if (pref && pref.length) {
      pref.map((item) => {
        if (item.name == selectedItem.name) {
          item.flag = !item.flag;
        }
      });
    }
    this.setState({ pref });
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "name") {
      message = "Please enter profile name";
    }
    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let { profileName, setDefault, error, pref } = this.state;
    let { isEdit } = this.props.optionalProps;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    return (
      <Modal
        title="Add Communication Profile"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em`, paddingBottom: "1em" }}
        optionalClasses="scroll task-popup-sp"
        titleClasses="modal-header"
      >
        <div
          className={`input-container ${profileName.hasError ? " error" : ""}`}
          style={{ paddingLeft: 0 }}
        >
          <label htmlFor="name">Profile Name</label>
          <div>
            <input
              name="name"
              type="text"
              maxLength={60}
              placeholder="Enter Profile Name"
              value={profileName.value}
              onChange={(event) => this.handleNameChange(event.target.value)}
            />
            {profileName.hasError ? this.renderErrorMessage("name") : null}
          </div>
        </div>

        <div className="pref-container">
          <div>
            <label>Preferences</label>
            <div className="prof-comms-pref">
              {pref && pref.length
                ? pref.map((item) => {
                    return (
                      <div className="pref-checkbox-view">
                        <input
                          name={item.name}
                          value={item.name}
                          key={item.name}
                          type="checkbox"
                          checked={item.flag}
                          style={{
                            WebkitAppearance: `checkbox`,
                            width: `20px`,
                            marginRight: 10,
                          }}
                          onChange={() => {}}
                          onClick={(event) => this.onCheckboxClick(event, item)}
                        />
                        <div>{item.name}</div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div>
            <label>Set Default</label>
            <div style={{ marginBottom: "2em", marginTop: 8 }}>
              <ToggleSwitch
                isOn={setDefault}
                toggleButton={() => this.toggleButton()}
              />
            </div>
          </div>
        </div>

        <label>Assign Organizations</label>
        <div className={`input-container half`} style={{ marginBottom: 20 }}>
          <Multiselect
            options={this.state.options}
            displayValue="name"
            placeholder="Select attach documents"
            onSelect={this.onSelect}
          />
        </div>

        <div className="modal-actions">
          <a
            className="cta mg-brand2-color"
            onClick={(event) => this.handleSubmit(event)}
            style={{ paddingLeft: "3em", paddingRight: "3em" }}
          >
            Submit
          </a>
        </div>
      </Modal>
    );
  }
}

export default AddCommunicationProfileModal;
