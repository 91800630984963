import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Packages
import currencyFormatter from 'currency-formatter';

// Components
import Panel from '../App/Panel';

class AdminSidebar extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired
  }

  renderAddress(account) {
    if (
      account.street_address_primary ||
      account.street_address_secondary ||
      account.city ||
      account.state ||
      account.zipcode
    ) {
      return (
        <p>
          {account.street_address_primary ? account.street_address_primary : ''}
          {account.street_address_primary && <br/>}
          {account.street_address_secondary ? account.street_address_secondary : ''}
          {account.street_address_secondary && <br/>}
          {account.city ? account.city : ''}
          {account.city && account.state ? ',': ''}
          {account.state ? account.state : ''}
          {account.state && account.zipcode ? ' ': ''}
          {account.zipcode ? account.zipcode : ''}
        </p>
      )
    } else {
      return (
        <p>
          No address available
        </p>
      )
    }
  }

  render() {
    const { account, name} = this.props;

    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar " 
      >
        <Panel
          heading="Outstanding Debt"
        >
          <div className="accounts-view-sidebar-debt">
            {currencyFormatter.format(account.total_debt_owed, { code: 'USD' })}
          </div>
        </Panel>
        <Panel
          heading="Consumer Information"
        >
          <h4 className="panel-heading-secondary">
            Basic Information
          </h4>
          <p>
            {account.first_name + ' ' + account.last_name}
            <br/>
            {account.email &&
              <span>
                {account.email}
              </span>
            }
            {account.email && <br/>}
            {account.phone_number}
          </p>
          <h4 className="panel-heading-secondary">
            Address
          </h4>
          {this.renderAddress(account)}
          <h4 className="panel-heading-secondary">
             Date of Birth
          </h4>
          <p>
            {account.date_of_birth ? account.date_of_birth : 'Not available'}
          </p>
          <h4 className="panel-heading-secondary">
            Last 4 of SSN
          </h4>
          <p>
            {account.ssn}
          </p>
        </Panel>
        <div className="app-sidebar-actions">
          <Link
            className="app-sidebar-actions-link edit"
            to={`/admin/accounts/${name}/edit`}>
            Edit Account
          </Link>
          <Link
            className="app-sidebar-actions-link delete"
            to={`/admin/accounts/${name}`}>
            Delete Account
          </Link>
        </div>
      </div>
    );
  }
}

export default AdminSidebar;
