import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class ArrowLink extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    arrow: PropTypes.string.isRequired,
    optionalClassName: PropTypes.string
  }

  renderLabelAndArrow(label, arrow) {
    return arrow === 'left'
    ? (
        <span className="arrow-link-content">
          <svg className="icon"><use xlinkHref="#arrow-left"/></svg> <span className="arrow-link-content-label">
            {label}
          </span>
        </span>
      )
    : (
        <span className="arrow-link-content">
          <span className="arrow-link-content-label">
            {label}
          </span> <svg className="icon"><use xlinkHref="#arrow-right"/></svg>
        </span>
      )
  }

  render() {
    let { optionalClassName, path, label, arrow } = this.props;

    return (
      <Link
        to={path}
        className={`arrow-link${optionalClassName ? ' ' + optionalClassName : ''}`}
      >
        {this.renderLabelAndArrow(label, arrow)}
      </Link>
    )
  }
}

export default ArrowLink;
