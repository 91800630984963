import React, { Component } from 'react';
import { Redirect } from "react-router-dom"

// API
import * as API from '../../utils/api';

import { networkMessage } from '../../utils/helpers'


// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import AppSectionHeader from '../../components/App/AppSectionHeader';
import ObjectChatList from '../../components/ObjectList/ObjectChatList';

class ViewConsumerConversation extends Component {

  state = {
    consumerAccountId: null,
    consumer: {},
    isLoading: true,
    hasAnyError: false
  }

  componentDidMount() {
    const consumerAccountId = this.props.match.params.id;
    API.getViewConsumerConversation(this.props.user, consumerAccountId).then(data => {
     if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        let { consumer, conversation } = data.account;
        this.setState(state => ({
          ...state,
          consumer: consumer,
          conversation: conversation,
          isLoading: false
        }));
      } else {
        this.setState(state => ({
          ...state,
          hasAnyError: true
        }))
      }
    });
  }


  getObjectListData = (data) => {
    let headings = [],
      columns = []
    for (var i in data) {
      columns = data.map((conversation_data) => {
        return {
          chatData: {
            userRole: conversation_data.author.type,
            userText: conversation_data.body,
            chatId: conversation_data.author.id,
            createdAt: conversation_data.created_at
          }
        }
      })
    }

    return {
      headings,
      columns
    }
  }



  render() {
    const { consumer, conversation, isLoading } = this.state;
    let emptyStateMessage = 'No Chat Conversation.'
    if (conversation !== undefined && conversation.length > 0) {
      var conversationList = [];
      for (var i = 0; i < conversation.length; i++) {
        let tableData = conversation[i]
        conversationList.push(
          <div className="object-list-empty-state chat-margin" key={i}>
            <ObjectChatList
              data={this.getObjectListData(tableData)}
              consumer={consumer}
            />
          </div>
        );
      }
    }

    if (this.state.hasAnyError) {
      this.props.updateModalState(true, 'OTHER_ERROR', this.state.hasAnyError);
      return <Redirect to={{ pathname: '/sign-out' }} />
    }

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <AppSectionHeader
            title="View Conversations"
          />
          <div className="object-list-empty-state">
            {conversationList ? conversationList : (<div className="object-list-empty-state chat-margin" > {emptyStateMessage} </div>)}
          </div>
        </div>
      );
  }
}

export default ViewConsumerConversation;
