import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Packages
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import shortid from 'shortid';

// Components
import DropdownOverflowMenu from './DropdownOverflowMenu';
import MenuItem from '../MenuItem';
import ExpanderTable from './ExpanderTable';

class ObjectHistoryRow extends Component {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    appName: PropTypes.string
  }

  state = {
    rowType: 'default',
    expanded: false,
    imageURL: '',
    rowDetail: {}
  }

  componentDidMount() {
    let { rowData } = this.props;
    this.setState(state => ({
      ...state,
      imageURL: rowData.imageURL,
      rowDetail: rowData.rowDetail
    }))
    this.setRowType(rowData.rowType);
  }

  setRowType(rowType) {
    this.setState(state => ({
      ...state,
      rowType: rowType
    }))
  }

  renderPrimaryColumn = (rowData) => {
    let { primaryColumn, rowLinkPath, fullRowIsClickable } = rowData;

    let rowDescriptor =
      primaryColumn.rowDescriptor ? (
        <div className="row-descriptor">
          {primaryColumn.rowDescriptor}
        </div>
      ) : null


    let primaryContent = (
      <div className="object-row-header-name">
        {primaryColumn.title}
      </div>
    )

    let secondaryContent =
      primaryColumn.secondaryInfo ? (
        <div className="object-row-header-secondary-info">
          {primaryColumn.secondaryInfo}
        </div>
      ) : null

    if (rowLinkPath && !fullRowIsClickable) {
      return (
        <Link
          className="object-debt-row-header"
          to={rowLinkPath}
        >
          {rowDescriptor}
          {primaryContent}
          {secondaryContent}
        </Link>
      )
    } else {
      return (
        <div className={`object-debt-row-header ${primaryColumn.optionalClasses ? ' ' + primaryColumn.optionalClasses : ''}`}>
          {rowDescriptor}
          {primaryContent}
          {secondaryContent}
        </div>
      )
    }
  }

  renderLatestPaymentDate = (payments) => {
    const latestPayment = payments.reduce(function (previousPayment, currentPayment) {
      return (previousPayment.timestamp > currentPayment.timestamp) ? previousPayment : currentPayment
    })

    let dateString = moment.unix(latestPayment.timestamp / 1000).format("MM/DD/YYYY");

    return dateString
  }

  renderOutstandingDebt = (debts) => {
    let totalDebt = debts.reduce((sum, debt) => {
      return sum + debt.balance;
    }, 0);

    return currencyFormatter.format(totalDebt, { code: 'USD' });
  }

  renderSecondaryColumns = (columns) => {
    const status = { color: `turquoise`, fontFamily: `sofia_pro_bold`, fontSize: `1.0em` }
    if (columns.length > 0) {
      return (
        <div className="object-debt-row-data">
          {columns.map((column) => {
            return (
              <div
                key={shortid.generate()}
                className={`object-row-data-column${column.parentClasses ? ' ' + column.parentClasses : ''}`}
              >
                <div
                  className={`object-row-data-column-inner${column.optionalClasses ? ' ' + column.optionalClasses : ''}`}
                  style={{height: `110%`}}
                >
                  {column.title}
                </div>
              </div>
            );
          })}
        </div>
      )
    }
  }

  renderRowActionButton = (rowType, rowActions, rowData) => {

    let { expanded } = this.state;

    const style = { height: `30px`, width: `30px` }

    if (rowType === 'overflowMenu') {
      return (
        <DropdownOverflowMenu>
          {
            rowActions.map((rowAction) => {
              return (
                <MenuItem
                  key={shortid.generate()}
                  dataBehavior={rowAction.dataBehavior}
                  dataTarget={rowAction.dataTarget}
                  optionalClasses={rowAction.optionalClasses}
                  handleClick={(event) => this.props.handleRowActionClick(event)}
                >
                  {rowAction.title}
                </MenuItem>
              )
            })
          }
        </DropdownOverflowMenu>
      )
    } else if (rowType === 'expander') {
      return (
        <a
          className={`object-row-expander${expanded ? ' active' : ''}`}
          onClick={(event) => this.handleEyeIconClick(event)}
        >
          <svg className="icon"><use xlinkHref="#filter" /></svg>
        </a>
      )
    } if (rowType === 'view') {
      let data = rowData && rowData.showDetails ? rowData.row : this.state.imageURL
      return (
        <a
          className={`object-row-expander${expanded ? ' active' : ''}${rowData.showDetails ? ' last-icon-view' : ''}`} style={style}
          onClick={(event) => this.props.action(data)}
        >
          <svg className="icon"><use xlinkHref="#view" /></svg>
        </a>
      )
    } else if (rowType === 'clickThrough') {
      return (
        <svg className="object-row-arrow"><use xlinkHref="#arrow-right" /></svg>
      )
    } else if (rowType === 'detailView'){
      return (
        <a
          className={`object-row-expander${expanded ? ' active' : ''}`} style={style}
          onClick={(event) => this.props.action(this.state.rowDetail)}
        >
          <svg className="icon"><use xlinkHref="#view" /></svg>
        </a>
      )
    }
  }

  renderExpanderContent = (expanderOptions) => {
    let { expanded } = this.state;

    if (expanderOptions) {
      return (
        <ExpanderTable
          options={expanderOptions}
          isExpanded={expanded}
        />
      )
    }
  }

  getOptionalRowClasses = (rowData) => {
    let classes = '';

    if (rowData.rowType === 'expander') {
      classes += ' expander';
    }

    if (rowData.optionalRowClasses) {
      classes += ' ' + rowData.optionalRowClasses;
    }

    return classes;
  }

  render() {
    let { rowData } = this.props;
    let optionalClasses = this.getOptionalRowClasses(rowData);

    let rowInner = (
      <div className="object-row-inner">
        <div className="object-row-content">
          {this.renderPrimaryColumn(rowData)}
          {this.renderSecondaryColumns(rowData.secondaryColumns)}
          {this.renderRowActionButton(rowData.rowType, rowData.rowActions, rowData)}
        </div>
        {this.renderExpanderContent(rowData.expanderOptions)}
      </div>
    );

    return rowData.fullRowIsClickable
      ? (
        <Link
          to={rowData.rowLinkPath}
          className={`object-row object-row-link${optionalClasses}`}
        >
          {rowInner}
        </Link>
      )
      : (
        <div
          className={`object-row${optionalClasses}`}
        >
          {rowInner}
        </div>
      )
  }
}

export default ObjectHistoryRow;
