import { instanceOf } from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import companyData from "./assets/styles/theme.json";
import ModalConductor from "./components/App/Modals/ModalConductor";
import SignOut from "./components/Sessions/SignOut";
import ConsumerPaymentPlanDetails from "./screens/ConsumerPortal/Payment/ConsumerPaymentPlanDetails";
import ConsumerAccountsPaymentplansReview from "./screens/ConsumerPortal/ConsumerAccountsPaymentplansReview";
// Import Components
import SVGSprite from "./components/SVGSprite";
import AdminAccountAddEditView from "./screens/AdminPortal/AdminAccountAddEditView";
import AdminAccounts from "./screens/AdminPortal/AdminAccounts";
import AdminAccountsView from "./screens/AdminPortal/AdminAccountsView";
import AdminAdmins from "./screens/AdminPortal/AdminAdmins";
// import AdminPayments from './screens/AdminPortal/AdminPayments';
import AdminOffers from "./screens/AdminPortal/AdminOffers";
import AdminPendingActions from "./screens/AdminPortal/AdminPendingActions";
// Admin Portal
import AdminPortal from "./screens/AdminPortal/AdminPortal";
import AdminViewAddressDoc from "./screens/AdminPortal/AdminViewAddressDoc";
import CompanyAccounts from "./screens/AdminPortal/CompanyAccounts";
import ViewConsumerConversation from "./screens/AdminPortal/ViewConsumerConversation";
import ViewPendingAddressUser from "./screens/AdminPortal/ViewPendingAddressUser";
// Configure App
import AppConfiguration from "./screens/AppConfiguration";
import AppThirdPartyConfig from "./screens/AppThirdPartyConfig";
import ClientsAccountAddEditView from "./screens/ClientsPortal/ClientsAccountAddEditView";
import ClientsAccounts from "./screens/ClientsPortal/ClientsAccounts";
import ClientsAccountsView from "./screens/ClientsPortal/ClientsAccountsView";
import ClientsEditSettings from "./screens/ClientsPortal/ClientsEditSettings";
import ClientsAdmins from "./screens/ClientsPortal/ClientsAdmins";
import ClientsDashboard from "./screens/ClientsPortal/ClientsDashboard";
import ClientsEmployeeAccountsView from "./screens/ClientsPortal/ClientsEmployeeAccountsView";
import ClientsEmployees from "./screens/ClientsPortal/ClientsEmployees";
import ClientsHistory from "./screens/ClientsPortal/ClientsHistory";
import ClientsInvoices from "./screens/ClientsPortal/ClientsInvoices";
import ClientsInvoicesView from "./screens/ClientsPortal/ClientsInvoicesView";
import ClientsPaymentPlanView from "./screens/ClientsPortal/ClientsPaymentPlansView";
import ClientsAddInvoices from "./screens/ClientsPortal/ClientsAddInvoices";
import ClientsHelp from "./screens/ClientsPortal/ClientsHelp";
import ClientsCreditMemoView from "./screens/ClientsPortal/CreditMemo/ClientsCreditMemoView";
import ClientsAddCreditMemo from "./screens/ClientsPortal/CreditMemo/ClientsAddCreditMemo";
// import AdminPayments from './screens/AdminPortal/AdminPayments';
import ClientsOffers from "./screens/ClientsPortal/ClientsOffers";
import ClientsPendingActions from "./screens/ClientsPortal/ClientsPendingActions";
//Clients Portal
import ClientsPortal from "./screens/ClientsPortal/ClientsPortal";
import ClientsProfile from "./screens/ClientsPortal/ClientsProfile";
import ClientsReports from "./screens/ClientsPortal/ClientsReports";
import ClientsReportView from "./screens/ClientsPortal/ClientsReportView";
import ClientsSettings from "./screens/ClientsPortal/ClientsSettings";
import ClientsTasks from "./screens/ClientsPortal/ClientsTasks";
import AccountSwitch from "./screens/ClientsPortal/AccountSwitch";
import ClientViewAddressDoc from "./screens/ClientsPortal/ClientViewAddressDoc";
import MailchimpHistory from "./screens/ClientsPortal/history/MailchimpHistory";
import MessageHistory from "./screens/ClientsPortal/history/MessageHistory";
import ViewConsumerChat from "./screens/ClientsPortal/history/ViewConsumerChat";
import LobHistory from "./screens/ClientsPortal/LobHistory";
import SkipPayment from "./screens/ClientsPortal/SkipPayment";
import SkipTrace from "./screens/ClientsPortal/SkipTrace";
import ConsumerAccounts from "./screens/ConsumerPortal/ConsumerAccounts";
import ConsumerAccountsView from "./screens/ConsumerPortal/ConsumerAccountsView";
import ConsumerDocumentValidate from "./screens/ConsumerPortal/ConsumerDocumentValidate";
import ConsumerHelp from "./screens/ConsumerPortal/ConsumerHelp";
import ImportInvoiceData from "./screens/ClientsPortal/importInvoiceData";
// Consumer Portal
import ConsumerPortal from "./screens/ConsumerPortal/ConsumerPortal";
import ConsumerProfile from "./screens/ConsumerPortal/ConsumerProfile";
import DebtDetail from "./screens/ConsumerPortal/DebtDetail";
import ConsumerAccountsNegotiateOneTimePayment from "./screens/ConsumerPortal/Payment/ConsumerAccountsNegotiateOneTimePayment";
import ConsumerAccountsNegotiatePaymentPlan from "./screens/ConsumerPortal/Payment/ConsumerAccountsNegotiatePaymentPlan";
import ConsumerAccountsPaymentNegotiateSelection from "./screens/ConsumerPortal/Payment/ConsumerAccountsPaymentNegotiateSelection";
import ConsumerAccountsPaymentSettleAccount from "./screens/ConsumerPortal/Payment/ConsumerAccountsPaymentSettleAccount";
import ConsumerAccountsPaymentValidate from "./screens/ConsumerPortal/Payment/ConsumerAccountsPaymentValidate";
import EmployeeAccounts from "./screens/EmployeePortal/EmployeeAccounts";
import EmployeeAccountAddEditView from "./screens/EmployeePortal/EmployeeAccountsAddEditView";
import EmployeeAccountsView from "./screens/EmployeePortal/EmployeeAccountsView";
import EmployeeLobHistory from "./screens/EmployeePortal/EmployeeLobHistory";
import EmployeeOffers from "./screens/EmployeePortal/EmployeeOffers";
import EmployeePaymentHistory from "./screens/EmployeePortal/EmployeePaymentHistory";
// Employee Portal
import EmployeePortal from "./screens/EmployeePortal/EmployeePortal";
import EmployeeProfile from "./screens/EmployeePortal/EmployeeProfile";
import EmployeeSkipTrace from "./screens/EmployeePortal/EmployeeSkipTrace";
import EmployeeViewAddressDoc from "./screens/EmployeePortal/EmployeeViewAddressDoc";
import EmployeeMailchimpHistory from "./screens/EmployeePortal/history/EmployeeMailchimpHistory";
import EmployeeMessageHistory from "./screens/EmployeePortal/history/EmployeeMessageHistory";
import EmployeeViewConsumerChat from "./screens/EmployeePortal/history/EmployeeViewConsumerChat";
// Screens
// -----------------------------------------------------------
// Landing
import Landing from "./screens/Landing";
// MakeGood
import MakeGoodPolicy from "./screens/MakeGoodPolicy";
import MarketingContact from "./screens/MarketingContact";
import MarketingContent from "./screens/MarketingContent";
// Marketing
import MarketingHomepage from "./screens/MarketingHomepage";
import MarketingPrivacy from "./screens/MarketingPrivacy";
import MarketingTerms from "./screens/MarketingTerms";
import ConfirmPassword from "./screens/NewLanding/ConfirmPassword";
//New Registration
import LandingHeader from "./screens/NewLanding/LandingHeader";
import NewRegistration from "./screens/NewLanding/NewRegistration";
import PasswordSuccess from "./screens/NewLanding/PasswordSuccess";
import OnboardingCompletion from "./screens/Onboarding/OnboardingCompletion";
import OnboardingConfirmAccountCode from "./screens/Onboarding/OnboardingConfirmAccountCode";
import OnboardingConfirmAccountPhone from "./screens/Onboarding/OnboardingConfirmAccountPhone";
import OnboardingCreateAccount from "./screens/Onboarding/OnboardingCreateAccount";
import OnboardingResidence from "./screens/Onboarding/OnboardingResidence";
import OnboardingUserAgreement from "./screens/Onboarding/OnboardingUserAgreement";
import OnboardingVerifyIdentity from "./screens/Onboarding/OnboardingVerifyIdentity";
import InvoiceAmountForm from "./screens/QuickBook/InvoiceAmountForm";
import QbFailure from "./screens/QuickBook/QbFailure";
import QbSuccess from "./screens/QuickBook/QbSuccess";
import QbDisconnect from "./screens/QuickBook/QbDisconnect";
import SSOSuccess from "./screens/QuickBook/SSOSuccess";
import SSOFailure from "./screens/QuickBook/SSOFailure";
import QbLaunch from "./screens/QuickBook/QbLaunch";
import StripePaymentFailed from "./screens/QuickBook/StripePaymentFailed";
import StripePaymentSuccess from "./screens/QuickBook/StripePaymentSuccess";
import ChoosePassword from "./screens/Sessions/ChoosePassword";
import ExpressPaymentSuccess from "./screens/QuickBook/ExpressPaymentSuccess";
import ExpressPaymentFailed from "./screens/QuickBook/ExpressPaymentFailed";
import ConsumerGetHelp from "./screens/QuickBook/ConsumerGetHelp";
// Sessions
import Login from "./screens/Sessions/Login";
import ResetPassword from "./screens/Sessions/ResetPassword";
import SuperAdminAccountAddEditView from "./screens/SuperAdminPortal/SuperAdminAccountAddEditView";
import SuperAdminAccounts from "./screens/SuperAdminPortal/SuperAdminAccounts";
import SuperAdminAccountsView from "./screens/SuperAdminPortal/SuperAdminAccountsView";
import SuperAdminProfile from "./screens/SuperAdminPortal/SuperAdminProfile";
//Super Admin
import SuperAdminPortal from "./screens/SuperAdminPortal/SuperAdminPortal";
import UserLicenseAgreement from "./screens/UserLicenseAgreement";
import MakeGoodEULA from "./screens/EndUserLicenseAgreement";
import accountViewData from "./testData/accountViewData.json";
import userDashboardObjectLists from "./testData/userDashboardObjectLists.json";
// Data
import userData from "./testData/userData.json";
import Favicon from "./assets/icons/favicon.ico";
// Utils
import * as API from "./utils/api";
import { googleAnalyticId } from "./utils/helpers";
import Emitter from "./utils/event-emitter";
import ClientsSSODashboard from "./screens/ClientsPortal/sso/ClientsSSODashboard";
import CreateSubDomain from "./screens/ClientsPortal/sso/CreateSubDomain";
import WelcomeScreenView from "./screens/ClientsPortal/sso/WelcomeScreenView";
import QuickBookAddOrganizations from "./screens/ClientsPortal/sso/QuickBookAddOrganizations";
import UserData from "./screens/UserData";
import CustomizeWorkFlow from "./screens/ClientsPortal/sso/CustomizeWorkFlow";
import PaymentPortalLogin from "./screens/ClientsPortal/sso/static-pages/PaymentPortalLogin";
import ConsumerAccountsInvoices from "./screens/ClientsPortal/sso/static-pages/ConsumerAccounts";
import ConsumerAccountsViewStatic from "./screens/ClientsPortal/sso/static-pages/ConsumerAccountsView";
import InvoiceAmountFormStatic from "./screens/ClientsPortal/sso/static-pages/InvoiceAmountForm";
import ServerError from "./components/error-pages/internal-server-error";
import BadRequest from "./components/error-pages/bad-request";
import EmailVerifyError from "./components/error-pages/emailVerifyError";
import PayPaymentPlans from "./screens/ConsumerPortal/PayPaymentPlans";
import PayInvoices from "./screens/ConsumerPortal/PayInvoices";
import ConsumerPortalStatic from "./screens/ConsumerPortal/ConsumerPortalStatic";
class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  // App will manage the state of global elements such as the user auth status

  constructor(props) {
    super(props);
    this.state = {
      progressPercent: 0,
      back_Path: "",
      user: {},
      isLoggedIn: false,
      showModal: false,
      currentModal: null,
      optionalModalProps: null,
      prevPath: null,
      confirmOnboardingPhoneNumber: false,
      hasAnyError: false,
      appName: "", //company_name
      clientName: "",
      appEmail: "https://www.debnow.com",
      heapEmail: null,
      heapAppId: null,
      intercomAppId: null,
      stripePubKey: null,
      appData: {},
      userData: {},
      companyData: {},
      isEULAtrue: false,
      appThemeData: {},
    };
    this.updateOnboardingComplete = this.updateOnboardingComplete.bind(this);
  }

  updateOnboardingComplete() {
    this.setState((state) => ({
      ...state,
      user: {
        ...state.user,
        is_onboarding_completed: true,
      },
    }));
  }
  getLogoColorsForPayment() {
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
        });
      })
      .catch(() => {});
  }

  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
      organization_key: this.state.companyData.organization_key,
    };
    if (user && user != null && authuser != null && authuser != undefined) {
      API.getOrgDetails(user).then((data) => {
        if (data && data.status_code == 200) {
          this.setState((state) => ({
            ...state,
            progressPercent: parseFloat(
              data.data.organization.onboarding_percentage
            ),
          }));
        }
      });
    }
  };

  componentDidMount() {
    const { cookies } = this.props;
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    this.getOrganizationData();
    this.getOrganizationalDetails();

    if (
      searchParams.get("email") == null ||
      searchParams.get("sub_id") == null ||
      searchParams.get("id_token") == null
    ) {
      if (window.location.pathname != "/login") {
        if (
          window.location.pathname != "/team_registration" &&
          window.location.pathname != "/reset-password" &&
          window.location.pathname != "/disconnect" &&
          window.location.pathname != "/EmailVerifyError" &&
          window.location.pathname != "/payment" &&
          window.location.pathname != "/register" &&
          window.location.pathname != "/sso-success" &&
          window.location.pathname != "/qb-success" &&
          window.location.pathname != "/welcome-screen/login" &&
          window.location.pathname != "/welcome-screen/accounts" &&
          window.location.pathname != "/welcome-screen/accounts/000000" &&
          window.location.pathname != "/welcome-screen/payment" &&
          window.location.pathname != "/sso_dashboard" &&
          window.location.pathname != "/users/:id/password/edit" &&
          window.location.pathname != "/users/:id/set_password" &&
          window.location.pathname != "/choose-password" &&
          (cookies.get("authUser", { path: "/", secure: true }) == null ||
            cookies.get("isLoggedIn", { path: "/", secure: true }) == null ||
            cookies.get("accessToken", { path: "/", secure: true }) == null ||
            cookies.get("refreshToken", { path: "/", secure: true }) == null)
        ) {
          window.location.href = "/login";
          cookies.remove("authUser", { path: "/", secure: true });
          cookies.remove("isLoggedIn", { path: "/", secure: true });
          cookies.remove("accessToken", { path: "/", secure: true });
          cookies.remove("refreshToken", { path: "/", secure: true });
        }
      }
    } else {
      this.getOrganizationalDetails();
    }
  }

  componentWillMount() {
    this.getLogoColorsForPayment();
    const { cookies } = this.props;
    const userFromCookies =
      cookies.get("authUser", { path: "/", secure: true }) || {};

    const isLoggedIn =
      cookies.get("isLoggedIn", { path: "/", secure: true }) || false;
    const url = window.location.origin;
    // this.getOrganizationData();

    this.intializeGA();
    this.setState(
      (state) => ({
        ...state,
        user: userFromCookies,
        appEmail: url,
        isLoggedIn: isLoggedIn,
      }),
      () => this.getFullUserProfile(userFromCookies)
    );

    //handle oauth error
    Emitter.on("OAUTH_ERROR", (isError) => {
      cookies.remove("authUser", { path: "/", secure: true });
      cookies.remove("isLoggedIn", { path: "/", secure: true });
      cookies.remove("accessToken", { path: "/", secure: true });
      cookies.remove("refreshToken", { path: "/", secure: true });
      return (
        <Redirect
          exact
          to={{
            pathname: "/login",
          }}
          push
        />
      );
    });
    Emitter.on("ORGINIZATION_DETAILS_CALL", () => {
      this.getOrganizationalDetails();
    });
  }

  componentWillUnmount() {
    Emitter.off("OAUTH_ERROR");
    Emitter.off("ORGINIZATION_DETAILS_CALL");
  }

  // async getOrganizationData() {
  //   let me = this;
  //   await API.getOrganizationData().then((data) => {
  //     localStorage.setItem("themeData", JSON.stringify(data));
  //     this.setState(
  //       {
  //         appName: data.name,
  //         clientName: data.clientName,
  //         companyData: data,
  //       },
  //       () => {
  //         me.getAppConfigInfo();
  //       }
  //     );
  //   });
  // }

  getOrganizationData() {
    API.getOrganizationData()
      .then((data) => {
        if (data) {
          localStorage.setItem("themeData", JSON.stringify(data));
          this.setState({
            appName: data.name,
            clientName: data.clientName,
            companyData: data,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching organization data: ", error);
      })
      .finally(() => {
        this.getAppConfigInfo(); // Always gets called, no matter if the API call was successful or not
      });
  }

  getFullUserProfile = (user) => {
    if (
      user.hasOwnProperty("user_id") &&
      user.user_id &&
      !user.admin &&
      !user.super_admin &&
      !user.client_admin
    ) {
      API.getUser(user).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.errors) {
          const consolidatedUser = Object.assign(user, data.user);
          this.setState((state) => ({
            ...state,
            user: consolidatedUser,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
          }));
        }
      });
    }
  };

  getAppConfigInfo = () => {
    const newOrgData = JSON.parse(localStorage.getItem("themeData"));
    let orgKey = newOrgData
      ? newOrgData.organization_key
      : this.state.companyData.organization_key
      ? this.state.companyData.organization_key
      : companyData.organization_key;
    API.getAppConfigInfo(orgKey).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.appConfigData) {
          data.appConfigData.forEach((appConfigData, index) => {
            if (appConfigData.name === "HEAP") {
              this.setState((state) => ({
                ...state,
                loading: false,
                heapEmail: appConfigData.email,
                heapAppId: appConfigData.app_id,
              }));
            } else if (appConfigData.name === "INTERCOM") {
              this.setState((state) => ({
                ...state,
                intercomAppId: appConfigData.app_id,
              }));
            } else if (appConfigData.name === "STRIPE") {
              this.setState((state) => ({
                ...state,
                stripePubKey: appConfigData.token,
              }));
            }
          });

          return true;
        } else {
          this.setState((state) => ({
            ...state,
            loading: false,
          }));
          return false;
        }
      } else {
        this.setState((state) => ({
          ...state,
          loading: false,
        }));
        return false;
      }
    });
  };

  handleUserLogin = (user) => {
    return new Promise((resolve, reject) => {
      const { cookies } = this.props;
      const plusOneYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      const cookieOpts = { path: "/", expires: plusOneYear, secure: true };
      cookies.set("authUser", user, cookieOpts);
      cookies.set("isLoggedIn", true, cookieOpts);

      //getGoogleAnalyticKey().pageview('login page');

      this.setState(
        (state) => ({
          ...state,
          user: {
            ...user,
          },
          isLoggedIn: true,
        }),
        () => {
          resolve(true);
        }
      );
    });
  };

  handleUserAgreementAccept = (user) => {
    return new Promise((resolve, reject) => {
      const { cookies } = this.props;
      const plusOneYear = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      );
      const cookieOpts = { path: "/", expires: plusOneYear, secure: true };
      user.onboarding_complete = true;
      cookies.set("authUser", user, cookieOpts);

      this.setState(
        (state) => ({
          ...state,
          user: {
            ...user,
          },
          onboarding_complete: true,
        }),
        () => {
          resolve(true);
        }
      );
    });
  };

  handleUserSignOut = () => {
    const { cookies } = this.props;
    cookies.remove("authUser", { path: "/", secure: true });
    cookies.remove("isLoggedIn", { path: "/", secure: true });
    cookies.remove("accessToken", { path: "/", secure: true });
    cookies.remove("refreshToken", { path: "/", secure: true });
    this.setState((state) => ({
      ...state,
      user: {},
      isLoggedIn: false,
    }));
    window.location.href = "/login";
  };
  handleStateChange = (backpath) => {
    this.setState((state) => ({
      ...state,
      back_Path: backpath,
    }));
  };

  handleModalChange = (showModalState, modalType, optionalModalProps) => {
    this.setState((state) => ({
      ...state,
      showModal: showModalState,
      currentModal: modalType,
      optionalModalProps,
    }));
  };

  hideAndResetModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: false,
      currentModal: null,
      optionalModalProps: null,
    }));
  };

  handlePrevPathUpdate = (path) => {
    this.setState((state) => ({
      ...state,
      prevPath: path,
    }));
  };

  setEULAtrue = () => {
    this.setState({ isEULAtrue: true });
  };

  updateOnboardingConfirmationCodeStatus = (status) => {
    this.setState((state) => ({
      ...state,
      confirmOnboardingPhoneNumber: status,
    }));
  };

  async getAppData() {
    await API.getAppInfo().then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        if (data.appData) {
          this.setState((state) => ({
            ...state,
            //appName: this.state.appName, //data.appData.company_name,
            appEmail: data.appData.company_email,
            appData: data.appData,
          }));
        }
      }
    });
  }

  intializeGA = () => {
    //ReactGA.initialize(googleAnalyticId);
  };

  setUserData = (data) => {
    this.setState({ userData: data });
  };
  updateUserName = (first_name, last_name) => {
    let { user } = this.state;
    user.first_name = first_name;
    user.last_name = last_name;
    this.setState((state) => ({
      ...state,
      ...user,
    }));
  };

  render() {
    let {
      user,
      showModal,
      currentModal,
      prevPath,
      confirmOnboardingPhoneNumber,
      appName,
      appEmail,
      stripePubKey,
      appData,
      isAppConfigured,
      isAppconfig,
    } = this.state;
    //const appName = appNameText;
    const modal = showModal ? (
      <ModalConductor
        user={this.state.user}
        stripePubKey={stripePubKey}
        appName={this.state.appName}
        appEmail={this.state.appEmail}
        currentModal={this.state.currentModal}
        hideModal={this.hideAndResetModal}
        updateModalState={this.handleModalChange}
        optionalProps={this.state.optionalModalProps}
      />
    ) : null;

    return (
      <div id='app'>
        <script>{this.intializeGA()}</script>
        <Helmet>
          <link rel='shortcut icon' href={Favicon} />
        </Helmet>
        <SVGSprite />
        <Switch>
          {/* Marketing Pages */}
          <Route
            path='/privacypolicy'
            render={(props) => (
              <MakeGoodPolicy
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                location={props.location.pathname}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/register'
            render={(props) => (
              <LandingHeader
                {...props}
                token={queryString.parse(props.location.search).token}
                orgName={queryString.parse(props.location.search).org_name}
                setUserData={this.setUserData}
                updateModalState={this.handleModalChange}
                intercomAppId={this.state.intercomAppId}
              />
            )}
          />
          <Route
            exact
            path='/registration-form'
            render={(props) => (
              <NewRegistration
                {...props}
                userData={this.state.userData}
                updateModalState={this.handleModalChange}
                intercomAppId={this.state.intercomAppId}
              />
            )}
          />
          <Route
            exact
            path='/confirm-password'
            render={(props) => (
              <ConfirmPassword
                {...props}
                userData={this.state.userData}
                updateModalState={this.handleModalChange}
                intercomAppId={this.state.intercomAppId}
              />
            )}
          />
          <Route
            exact
            path='/password-success'
            render={(props) => <PasswordSuccess {...props} />}
          />
          <Route
            exact
            path='/'
            render={(props) => (
              <MarketingHomepage
                appName={this.state.appName}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                location={props.location.pathname}
                user={user}
                intercomAppId={this.state.intercomAppId}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/configureApp'
            render={(props) => (
              <AppConfiguration
                appName={appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                appData={appData}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />
          <Route
            exact
            path='/appThirdPartyConfig'
            render={(props) => (
              <AppThirdPartyConfig
                appName={this.state.appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path='/contact'
            render={(props) => (
              <MarketingContact
                appName={this.state.appName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path='/privacy'
            render={(props) => (
              <MarketingPrivacy
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                location={props.location.pathname}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path='/terms'
            render={(props) => (
              <MarketingTerms
                appName={this.state.appName}
                appEmail={this.state.appEmail}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path='/content'
            render={() => (
              <MarketingContent
                appName={this.state.appName}
                companyAddress={this.state.companyAddress}
                adminEmail={this.state.adminEmail}
              />
            )}
          />
          <Route
            path='/landing'
            render={() => (
              <Landing
                appName={this.state.appName}
                appEmail={this.state.appEmail}
              />
            )}
          />
          {/* Sessions Screens */}
          <Route
            path='/login'
            render={(props) => (
              <Login
                appName={this.state.appName}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
                heapEmail={this.state.heapEmail}
                heapAppId={this.state.heapAppId}
              />
            )}
          />
          <Route
            path='/sign-out'
            render={(props) => (
              <SignOut
                handleSignOut={this.handleUserSignOut}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route path={`/bad-request`} component={BadRequest} />
          <Route path={`/server-error`} component={ServerError} />
          <Route path={`/EmailVerifyError`} component={EmailVerifyError} />
          <Route
            path='/reset-password'
            render={(props) => <ResetPassword appName={this.state.appName} />}
          />
          {/* Sessions Screens -- /choose-password -- Catch Rails user reset url --*/}
          <Route
            path='/users/:id/password/edit'
            render={(props) => (
              <ChoosePassword
                {...props}
                userID={props.match.params.id}
                user={user}
                token={queryString.parse(props.location.search).token}
                appName={this.state.appName}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path='/users/:id/set_password'
            render={(props) => (
              <ChoosePassword
                {...props}
                user={user}
                userID={props.match.params.id}
                token={queryString.parse(props.location.search).token}
                appName={this.state.appName}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          {/* Onboarding Screens */}
          <Route
            exact
            path='/create-account'
            render={(props) => (
              <OnboardingCreateAccount
                appName={this.state.appName}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/confirm-account/phone'
            render={(props) => (
              <OnboardingConfirmAccountPhone
                appName={this.state.appName}
                user={user}
                reconfirmPhoneNumber={confirmOnboardingPhoneNumber}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/confirm-account/enter-code'
            render={(props) => (
              <OnboardingConfirmAccountCode
                appName={this.state.appName}
                user={user}
                handleResendConfirmationCode={
                  this.updateOnboardingConfirmationCodeStatus
                }
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/onboarding/address'
            render={(props) => (
              <OnboardingResidence
                appName={this.state.appName}
                user={user}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/onboarding/verify-identity'
            render={(props) => (
              <OnboardingVerifyIdentity
                appName={this.state.appName}
                user={user}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/onboarding/user-agreement'
            render={(props) => (
              <OnboardingUserAgreement
                appName={this.state.appName}
                user={user}
                handleSignOut={this.handleUserSignOut}
                handleAcceptUserAgreement={this.handleUserAgreementAccept}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/onboarding/success'
            render={(props) => (
              <OnboardingCompletion
                appName={this.state.appName}
                user={user}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/accounts'
            render={(props) => (
              <ConsumerPortalStatic
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                intercomAppId={this.state.intercomAppId}
                removePadding={true}
                appThemeData={this.state.appThemeData}
                handleSignOut={this.handleUserSignOut}
                screen={
                  <ConsumerAccountsInvoices
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    user={user}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                    handleSignOut={this.handleUserSignOut}
                  />
                }
              />
            )}
          />
          {/* User Dashboard Screens */}
          <Redirect
            exact
            from='/dashboard'
            to='/dashboard/accounts/invoices'
            push
          />
          <Redirect
            exact
            from='/dashboard/accounts'
            to='/dashboard/accounts/invoices'
            push
          />
          <Route
            exact
            path='/dashboard/accounts/:tab'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                intercomAppId={this.state.intercomAppId}
                removePadding={true}
                appThemeData={this.state.appThemeData}
                screen={
                  <ConsumerAccounts
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/invoices/pay-invoices'
            render={(props) => (
              <ConsumerPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/dashboard/accounts/invoices"}
                screen={
                  <PayInvoices
                    {...props}
                    primary_color={this.state.appThemeData.primary_color}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/invoices/:id'
            render={(props) => (
              <ConsumerPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/dashboard/accounts/invoices"}
                screen={
                  <ConsumerAccountsView
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/payment-plans/:id'
            render={(props) => (
              <ConsumerPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/dashboard/accounts/payment-plans"}
                screen={
                  <ConsumerPaymentPlanDetails
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/pay-payment-plans/:id'
            render={(props) => (
              <ConsumerPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/dashboard/accounts/payment-plans"}
                screen={
                  <PayPaymentPlans
                    {...props}
                    primary_color={this.state.appThemeData.primary_color}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/payment-plans/:id/review'
            render={(props) => (
              <ConsumerPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/dashboard/accounts/payment-plans"}
                screen={
                  <ConsumerAccountsPaymentplansReview
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/accounts/000000'
            render={(props) => (
              <ConsumerPortalStatic
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                prevPath={"/welcome-screen/accounts"}
                screen={
                  <ConsumerAccountsViewStatic
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    stripePubKey={stripePubKey}
                    updatePrevPath={this.handlePrevPathUpdate}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/:id/document-validate'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts/:id"}
                screen={
                  <ConsumerDocumentValidate
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    updatePrevPath={this.handlePrevPathUpdate}
                    objectListData={userDashboardObjectLists}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/:id/negotiate'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts"}
                screen={
                  <ConsumerAccountsPaymentNegotiateSelection
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    updatePrevPath={this.handlePrevPathUpdate}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/:id/validate'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts"}
                screen={
                  <ConsumerAccountsPaymentValidate
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    updatePrevPath={this.handlePrevPathUpdate}
                    objectListData={userDashboardObjectLists}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/:id/negotiate/payment-plan'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={prevPath}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerAccountsNegotiatePaymentPlan
                      {...props}
                      appName={this.state.appName}
                      user={user}
                      updatePrevPath={this.handlePrevPathUpdate}
                      objectListData={userDashboardObjectLists}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/:id/negotiate/one-time-payment'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={prevPath}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerAccountsNegotiateOneTimePayment
                      {...props}
                      appName={this.state.appName}
                      user={user}
                      updatePrevPath={this.handlePrevPathUpdate}
                      objectListData={userDashboardObjectLists}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/accounts/:id/settle-account'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Accounts"}
                isLoggedIn={this.state.isLoggedIn}
                prevPath={"/dashboard/accounts"}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerAccountsPaymentSettleAccount
                      {...props}
                      appName={this.state.appName}
                      user={user}
                      updatePrevPath={this.handlePrevPathUpdate}
                      objectListData={userDashboardObjectLists}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/profile'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                appThemeData={this.state.appThemeData}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ConsumerProfile
                      {...props}
                      appName={this.state.appName}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      user={user}
                      stripePubKey={stripePubKey}
                      appThemeData={this.state.appThemeData}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/help'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ConsumerHelp
                    {...props}
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          {/* Admin Screens */}
          <Redirect exact from='/admin' to='/admin/accounts' push />
          <Route
            exact
            path='/admin/accounts'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/accounts/add'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/accounts/:id'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                appThemeData={this.state.appThemeData}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountsView
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/accounts/:id/edit'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/offers'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminOffers
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/admins'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAdmins
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/PendingActions'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminPendingActions
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/companyDashboard'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <CompanyAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/companyDashboard/add'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/companyDashboard/:id'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                appThemeData={this.state.appThemeData}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/companyDashboard/:id/edit'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/view/conversation/:id'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewConsumerConversation
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/dashboard/profile/debtdetail'
            render={(props) => (
              <ConsumerPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                headerTab={"Debt Detail"}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <DebtDetail
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/accounts/:id/addressDocument'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <AdminViewAddressDoc
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/admin/pendingAddressUser'
            render={(props) => (
              <AdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewPendingAddressUser
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          {/* Clients Screens */}
          <Redirect exact from='/client' to='/client/dashboard' push />
          <Route
            exact
            path='/client/dashboard'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={this.state.user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsDashboard
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appConfigMethod={this.getAppConfigInfo}
                    percentageAPI={this.getOrganizationalDetails}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Redirect exact from='/client' to='/client/accounts' push />
          <Route
            exact
            path='/client/accounts'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsAccounts
                    appName={this.state.appName}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/import'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ImportInvoiceData
                    {...props}
                    type='account'
                    appName={this.state.appName}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Redirect exact from='/client' to='/client/team' push />
          <Route
            exact
            path='/client/team'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsEmployees
                    appName={this.state.appName}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/employees/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsEmployeeAccountsView
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/accounts/add'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsAccountAddEditView
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/accounts/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsAccountsView
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/accounts/:id/edit-setting'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsEditSettings
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/accounts/:id/edit'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                progressPercent={this.state.progressPercent}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsAccountAddEditView
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/profile'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                progressPercent={this.state.progressPercent}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  this.state.stripePubKey !== null ? (
                    <ClientsProfile
                      {...props}
                      appThemeData={this.state.appThemeData}
                      appName={this.state.appName}
                      hideModal={this.hideAndResetModal}
                      getUserProfile={this.updateUserName}
                      updateModalState={this.handleModalChange}
                      user={user}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path='/client/offers'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                progressPercent={this.state.progressPercent}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ClientsOffers
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/tasks'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                removePadding={true}
                screen={
                  <ClientsTasks
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/reports'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                removePadding={true}
                screen={
                  <ClientsReports
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/reports/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                // removePadding={true}
                screen={
                  <ClientsReportView
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/invoices'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsInvoices
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/invoices/import'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ImportInvoiceData
                    {...props}
                    type='invoice'
                    appName={this.state.appName}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/invoices/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsInvoicesView
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/payment-plans/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsPaymentPlanView
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/add/invoices'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsAddInvoices
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/creditMemo/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                removePadding={true}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsCreditMemoView
                    {...props}
                    appThemeData={this.state.appThemeData}
                    back_Path={this.state.back_Path}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/add/creditMemo'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsAddCreditMemo
                    {...props}
                    appThemeData={this.state.appThemeData}
                    handleStateChange={this.handleStateChange}
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/admins'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsAdmins
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/PendingActions'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsPendingActions
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/view/conversation/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ViewConsumerConversation
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/accounts/:id/addressDocument'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientViewAddressDoc
                    {...props}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/pendingAddressUser'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ViewPendingAddressUser
                    appName={appName}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/settings'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsSettings
                    {...props}
                    appName={appName}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateUserLogin={this.handleUserLogin}
                    updateModalState={this.handleModalChange}
                    stripePubKey={stripePubKey}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/change-account'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                removePadding={true}
                progressPercent={this.state.progressPercent}
                screen={
                  <AccountSwitch
                    {...props}
                    appName={appName}
                    cookies={this.props.cookies}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateUserLogin={this.handleUserLogin}
                    updateModalState={this.handleModalChange}
                    stripePubKey={stripePubKey}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/history'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsHistory
                    {...props}
                    appName={appName}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/lobHistory'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <LobHistory
                    {...props}
                    appName={appName}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/messageHistory/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <MessageHistory
                    {...props}
                    appName={appName}
                    appThemeData={this.state.appThemeData}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/view/chat/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ViewConsumerChat
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/messageHistory/mail/:id'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <MailchimpHistory
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/skipTrace'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <SkipTrace
                    {...props}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/client/help'
            render={(props) => (
              <ClientsPortal
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                progressPercent={this.state.progressPercent}
                screen={
                  <ClientsHelp
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          {/* Super Admin Screens */}
          <Redirect exact from='/super-admin' to='/super-admin/accounts' push />
          <Route
            exact
            path='/super-admin/accounts'
            render={(props) => (
              <SuperAdminPortal
                location={props.location.pathname}
                user={user}
                appThemeData={this.state.appThemeData}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminAccounts
                    appName={this.state.appName}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/super-admin/profile'
            render={(props) => (
              <SuperAdminPortal
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminProfile
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/super-admin/accounts/:id'
            render={(props) => (
              <SuperAdminPortal
                location={props.location.pathname}
                user={user}
                appThemeData={this.state.appThemeData}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/super-admin/accounts/:id/edit'
            render={(props) => (
              <SuperAdminPortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                appThemeData={this.state.appThemeData}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SuperAdminAccountAddEditView
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          {/* Employee Screens */}
          <Redirect exact from='/employee' to='/employee/accounts' push />
          <Route
            exact
            path='/employee/accounts'
            render={(props) => (
              <EmployeePortal
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeAccounts
                    appName={this.state.appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/accounts/:id'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeAccountsView
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/accounts/:id/edit'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeAccountAddEditView
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/view/conversation/:id'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <ViewConsumerConversation
                    {...props}
                    user={user}
                    appThemeData={this.state.appThemeData}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/accounts/:id/addressDocument'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeViewAddressDoc
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/profile'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  this.state.stripePubKey !== null ? (
                    <EmployeeProfile
                      {...props}
                      appName={this.state.appName}
                      hideModal={this.hideAndResetModal}
                      updateModalState={this.handleModalChange}
                      user={user}
                      stripePubKey={stripePubKey}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/offers'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeOffers
                    user={user}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/skipPayment'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <SkipPayment
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/paymentHistory'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeePaymentHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/lobHistory'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeLobHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/messageHistory/:id'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeMessageHistory
                    {...props}
                    appName={appName}
                    user={user}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/view/chat/:id'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeViewConsumerChat
                    {...props}
                    user={user}
                    appName={this.state.appName}
                    userData={userData}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/messageHistory/mail/:id'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeMailchimpHistory
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          <Route
            exact
            path='/employee/skipTrace'
            render={(props) => (
              <EmployeePortal
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                screen={
                  <EmployeeSkipTrace
                    {...props}
                    appName={this.state.appName}
                    appEmail={this.state.appEmail}
                    user={user}
                    userData={accountViewData}
                    hideModal={this.hideAndResetModal}
                    updateModalState={this.handleModalChange}
                  />
                }
              />
            )}
          />
          {/* QuickBook login flow pages */}
          <Route
            exact
            path='/qb-success'
            render={(props) => (
              <QbSuccess
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
              />
            )}
          />
          <Route
            exact
            path='/qb-disconnect'
            render={(props) => (
              <QbDisconnect
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
              />
            )}
          />
          <Route
            exact
            path='/qb-failure'
            render={(props) => (
              <QbFailure
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
                updateUserLogin={this.handleUserLogin}
              />
            )}
          />
          <Route
            exact
            path='/payment'
            render={(props) => (
              <InvoiceAmountForm
                {...props}
                appThemeData={this.state.appThemeData}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            path='/welcome-screen/payment'
            render={(props) => (
              <InvoiceAmountFormStatic
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                handleSignOut={this.handleUserSignOut}
              />
            )}
          />
          <Route
            exact
            path='/payment-success'
            render={(props) => (
              <ExpressPaymentSuccess
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                invoiceNumber={
                  queryString.parse(props.location.search).invoice_number
                }
                amount={queryString.parse(props.location.search).amount}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/payment-failed'
            render={(props) => (
              <ExpressPaymentFailed
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/enable-payment/success'
            render={(props) => (
              <StripePaymentSuccess
                {...props}
                appName={this.state.appName}
                location={props.location.pathname}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />
          <Route
            exact
            path='/enable-payment/failed'
            render={(props) => (
              <StripePaymentFailed
                {...props}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                appName={this.state.appName}
                location={props.location.pathname}
              />
            )}
          />
          <Route
            exact
            path='/help'
            render={(props) => (
              <ConsumerGetHelp
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/sso-success'
            render={(props) => (
              <SSOSuccess
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
              />
            )}
          />
          <Route
            exact
            path='/sso_failure'
            render={(props) => (
              <SSOFailure
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/sso_dashboard'
            render={(props) => (
              <ClientsSSODashboard
                {...props}
                user={this.state.user}
                isEULAtrue={this.state.isEULAtrue}
                setEULAtrue={this.setEULAtrue}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                handleSignOut={this.handleUserSignOut}
                updateUserLogin={this.handleUserLogin}
              />
            )}
          />
          <Route
            exact
            path='/eula'
            render={(props) => (
              <MakeGoodEULA
                isEULAtrue={this.state.isEULAtrue}
                setEULAtrue={this.setEULAtrue}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                handleSignOut={this.handleUserSignOut}
                user={{
                  user_auth_token: this.state.user.auth_token,
                  user_id: this.state.user.user_id,
                  eula_accepted_date: this.state.user.accepted_on,
                }}
              />
            )}
          />
          <Route
            exact
            path='/sub-domain'
            render={(props) => (
              <CreateSubDomain
                {...props}
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type=''
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/add-organization-details'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='ADD_ORG'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/account-preferences-sync-quickbook-data'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='SYNC_ACCOUNT'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />{" "}
          <Route
            exact
            path='/welcome-screen/connect-account-system'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='CONNECT_ACCOUNT_SYSTEM'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/invite-your-team-members'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='INVITE_TEAM_MEMBERS'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                appThemeData={this.state.appThemeData}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/add-billing-details'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='CREDIT_TERMS'
                step=''
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/add-banking-info'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='CREDIT_TERMS'
                step='step2'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/select-payment-portal'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='CREDIT_TERMS'
                step='step3'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/upload-W9-form'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='CREDIT_TERMS'
                step='step4'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/default-credit-terms'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='CREDIT_TERMS'
                step='step5'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/account-placement-eligibility'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='CREDIT_TERMS'
                step='step6'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/invoice-branding-and-comms'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='INVOICE_PREFERENCES'
                step='step7'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/communication-preferences'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='INVOICE_PREFERENCES'
                step='step8'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/schedule-communications'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='SCHEDULE_COMMUNICATIONS'
                step='step9'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/schedule-go-live-date'
            render={(props) => (
              <CustomizeWorkFlow
                {...props}
                type='GO_LIVE_DATE'
                step='step10'
                stripePubKey={stripePubKey}
                appThemeData={this.state.appThemeData}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                props={this.props}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/enable-payment'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='SetUp_Payment_Account'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                isEnabledpayment={"SetUp_Payment_Account"}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/payment-preferences'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='PAYMENT_PREFERENCES'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/welcome-screen/payment-portal-preview'
            render={(props) => (
              <WelcomeScreenView
                {...props}
                type='PAYMENT_PORTAL_PREVIEW'
                stripePubKey={stripePubKey}
                appName={this.state.appName}
                user={this.state.user}
                clientName={this.state.clientName}
                location={props.location.pathname}
                updateModalState={this.handleModalChange}
                updateUserLogin={this.handleUserLogin}
                handleSignOut={this.handleUserSignOut}
                updateOnboardingComplete={this.updateOnboardingComplete}
              />
            )}
          />
          <Route
            exact
            path='/team_registration'
            render={(props) => (
              <UserData {...props} updateModalState={this.handleModalChange} />
            )}
          />
          <Route
            path='/welcome-screen/login'
            render={(props) => (
              <PaymentPortalLogin
                appName={this.state.appName}
                user={user}
                isLoggedIn={this.state.isLoggedIn}
                location={props.location}
                updateUserLogin={this.handleUserLogin}
                updateModalState={this.handleModalChange}
                heapEmail={this.state.heapEmail}
                heapAppId={this.state.heapAppId}
                handleSignOut={this.handleUserSignOut}
              />
            )}
          />
        </Switch>

        {showModal ? modal : ""}

        {showModal &&
        (!currentModal === "SUCCESS" || !currentModal === "ERROR") ? (
          <div className='curtain'></div>
        ) : (
          ""
        )}
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />
      </div>
    );
  }
}

export default withCookies(App);
