import React, { Component } from 'react';
import MaskedTextInput from 'react-text-mask';
import { withRouter, Link } from "react-router-dom";

// Utils
import * as API from '../../utils/api'

import { networkMessage } from '../../utils/helpers'

class OnboardingCreateAccountForm extends Component {

  state = {
    input: {
      phone: {
        value: "",
        hasError: false
      }
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm('phone')) {
      let phoneNumber = this.state.input.phone.value;
      phoneNumber = phoneNumber.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
      const userUpdate = {
        'phone_number': phoneNumber,
        'confirm_account': true
      }

      API.updateUser(this.props.user, userUpdate).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && (!data.errors)) {
          this.props.history.push("/confirm-account/enter-code");
        }else{
          let newInputState = { ...this.state.input }
          newInputState.phone.hasError = true
          this.setState(state => ({
            ...state,
            input: newInputState
          }))
        }
      });
    }
  }

  validateForm = (formName) => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    if (newInputState.phone.value === '') {
      newInputState.phone.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true;
    }
  }

  renderErrorMessage() {
    return (
      <div className="input-error-message">
        Please enter a valid phone number
      </div>
    )
  }

  render() {
    const { input } = this.state;

    return (
      <form className="sessions-form" onSubmit={(event) => this.handleSubmit(event)}>
        <div
          className={`input-container${input.phone.hasError ? ' error' : ''}`}
        >
          <MaskedTextInput
            name="phone"
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholder="(123) 456-7890"
            placeholderChar={'\u2000'}
            guide={true}
            type="text"
            value={input.phone.value}
            onChange={event => this.handleInputChange(
              { phone: { value: event.target.value, hasError: false } }
            )}
          />
          {input.phone.hasError ? this.renderErrorMessage('phone') : null}
        </div>
        <input className="cta text-wrap mg-button" type="submit" value="Send Text Message Now »" />
        <div className="sessions-form-note">
          <Link
            to="/sign-out"
            className="text-link mg-text-link">
            « Back to login
          </Link>
        </div>
      </form>
    )
  }
}

export default withRouter(OnboardingCreateAccountForm);
