import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
// Packages
import MaskedTextInput from "react-text-mask";
import { validateEmail } from "../../../utils/helpers";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
// Components
import Panel from "../../App/Panel";

class ClientsProfileForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    input: {
      first_name: {
        value: "",
        hasError: false,
      },
      last_name: {
        value: "",
        hasError: false,
      },
      phone: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      company_fax_number: {
        value: "",
        hasError: false,
      },
      hours_of_operation: {
        value: "",
        hasError: false,
      },
      street_address_primary: {
        value: "",
        hasError: false,
      },
      street_address_secondary: {
        value: "",
        hasError: false,
      },
      city: {
        value: "",
        hasError: false,
      },
      state: {
        value: "",
        hasError: false,
      },
      zipcode: {
        value: "",
        hasError: false,
      },
      preferenceEmail: {
        value: false,
        hasError: false,
      },
      preferenceText: {
        value: false,
        hasError: false,
      },
      preferenceInApp: {
        value: false,
        hasError: false,
      },
      preferencePhysicalEmail: {
        value: false,
        hasError: false,
      },
      preferencePhone: {
        value: false,
        hasError: false,
      },
    },
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState((state) => ({
      input: {
        first_name: {
          ...state.input.first_name,
          value: user.first_name,
        },
        last_name: {
          ...state.input.last_name,
          value: user.last_name,
        },
        phone: {
          ...state.input.phone,
          value: `+1 ${user.phone_number}`,
        },
        email: {
          ...state.input.email,
          value: user.email,
        },
        company_fax_number: {
          ...state.input.company_fax_number,
          value: user.company_fax_number,
        },
        hours_of_operation: {
          ...state.input.hours_of_operation,
          value: user.hours_of_operation,
        },
        street_address_primary: {
          ...state.input.street_address_primary,
          value: user.street_address_primary,
        },
        street_address_secondary: {
          ...state.input.street_address_secondary,
          value: user.street_address_secondary,
        },
        city: {
          ...state.input.city,
          value: user.city,
        },
        state: {
          ...state.input.state,
          value: user.state,
        },
        zipcode: {
          ...state.input.zipcode,
          value: user.zipcode,
        },
        preferenceEmail: {
          ...state.input.preferenceEmail,
          value: user.preference_email,
        },
        preferenceText: {
          ...state.input.preferenceText,
          value: user.preference_text,
        },
        preferenceInApp: {
          ...state.input.preferenceInApp,
          value: user.preference_in_app,
        },
        preferencePhysicalEmail: {
          ...state.input.preferencePhysicalEmail,
          value: user.preference_physical_email,
        },
        preferencePhone: {
          ...state.input.preferencePhone,
          value: user.preference_phone,
        },
      },
    }));
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm("phone")) {
      let first_name = this.state.input.first_name.value;
      let last_name = this.state.input.last_name.value;
      let phoneNumber = this.state.input.phone.value;
      let email = this.state.input.email.value;
      let company_fax_number = this.state.input.company_fax_number.value;
      let hours_of_operation = this.state.input.hours_of_operation.value;
      let street_address_primary =
        this.state.input.street_address_primary.value;
      let street_address_secondary =
        this.state.input.street_address_secondary.value;
      let city = this.state.input.city.value;
      let state = this.state.input.state.value;
      let zipCode = this.state.input.zipcode.value;
      let preferenceEmail = this.state.input.preferenceEmail.value;
      let preferenceText = this.state.input.preferenceText.value;
      let preferenceInApp = this.state.input.preferenceInApp.value;
      let preferencePhysicalEmail =
        this.state.input.preferencePhysicalEmail.value;
      let preferencePhone = this.state.input.preferencePhone.value;

      phoneNumber = phoneNumber
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");
      const userUpdate = {
        first_name: first_name,
        last_name: last_name,
        phone_number: phoneNumber.replace("+1", ""),
        email: email,
        company_fax_number: company_fax_number,
        hours_of_operation: hours_of_operation,
        street_address_primary: street_address_primary,
        street_address_secondary: street_address_secondary,
        city: city,
        state: state,
        zipcode: zipCode,
        preference_email: preferenceEmail,
        preference_text: preferenceText,
        preference_in_app: preferenceInApp,
        preference_physical_email: preferencePhysicalEmail,
        preference_phone: preferencePhone,
      };
      this.props.handleSubmit(userUpdate);
    }
  };

  validateForm = (formName) => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "first_name") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined
        ) {
          newInputState.first_name.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "last_name") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined
        ) {
          newInputState.last_name.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "phone") {
        let phoneNumber = value.value;
        if (
          phoneNumber === "" ||
          phoneNumber === null ||
          phoneNumber === undefined
        ) {
          newInputState.phone.hasError = true;
          errorsArePresent = true;
        } else {
          let phoneNumber = value.value
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
          if (phoneNumber === "") {
            newInputState.phone.hasError = true;
            errorsArePresent = true;
          } else if (phoneNumber.trim().length < 9) {
            newInputState.phone.hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (key === "email") {
        if (value.value === "" || !validateEmail(value.value)) {
          newInputState.email.hasError = true;
          errorsArePresent = true;
        } else if (!validateEmail(value.value)) {
          newInputState.email.hasError = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "") {
          newInputState.street_address_primary.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "city") {
        if (value.value === "") {
          newInputState.city.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "state") {
        if (value.value === "") {
          newInputState.state.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "") {
          newInputState.zipcode.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "company_fax_number") {
        if (value.value === "") {
          newInputState.company_fax_number.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "hours_of_operation") {
        if (value.value === "") {
          newInputState.hours_of_operation.hasError = true;
          errorsArePresent = true;
        }
      }
    });
    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "first_name") {
      message = "Please enter first name";
    } else if (input === "last_name") {
      message = "Please enter last name";
    } else if (input === "phone") {
      message = "Please enter a valid phone number";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "hours_of_operation") {
      message = "Hours cannot be blank";
    } else if (input === "company_fax_number") {
      message = "Please enter a valid company fax number";
    } else if (input === "street_address_primary") {
      message = "Street Address cannot be blank";
    } else if (input === "city") {
      message = "City cannot be blank";
    } else if (input === "state") {
      message = "State cannot be blank";
    } else if (input === "zipcode") {
      message = "Zip Code cannot be blank";
    } else if (input === "document") {
      message = "";
    } else {
      message = "Please complete this field";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  render() {
    let { input } = this.state;
    let { addressDocument } = this.props;
    let iconImage = addressDocument ? addressDocument.document : "";

    return (
      <form
        className='consumer-profile-form'
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <Panel heading='Update Your Profile'>
          <div className='admin-form-row'>
            <div
              className={`input-container half${
                input.first_name.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='first_name'>First Name</label>
              <input
                name='first_name'
                guide='true'
                placeholder='Enter First Name'
                type='text'
                value={input.first_name.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    first_name: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.first_name.hasError
                ? this.renderErrorMessage("first_name")
                : null}
            </div>
            <div
              className={`input-container half ${
                input.last_name.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='last_name'>Last Name</label>
              <input
                name='last_name'
                guide='true'
                placeholder='Enter Last Name'
                type='text'
                value={input.last_name.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    last_name: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.last_name.hasError
                ? this.renderErrorMessage("last_name")
                : null}
            </div>
          </div>

          <div className='admin-form-row'>
            <div
              className={`input-container half ${
                input.email.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>E-mail:</label>
              <input
                name='email'
                placeholder='address@email.com'
                type='text'
                value={input.email.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    email: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.email.hasError ? this.renderErrorMessage("email") : null}
            </div>
            <div
              className={`input-container half ${
                input.phone.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>Company Phone Number</label>
              <MaskedTextInput
                name='phone'
                mask={[
                  "+",
                  "1",
                  "(",
                  /\d/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder='(###) ###-####'
                placeholderChar={"\u2000"}
                guide='true'
                type='text'
                value={input.phone.value}
                onChange={(event) =>
                  this.handleInputChange({
                    phone: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.phone.hasError ? this.renderErrorMessage("phone") : null}
            </div>
          </div>
          <div className='admin-form-row'>
            <div
              className={`input-container half${
                input.company_fax_number.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='company_fax_number'>Company Fax Number</label>
              <input
                name='company_fax_number'
                guide='true'
                placeholder='fax number'
                type='text'
                value={input.company_fax_number.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    company_fax_number: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.company_fax_number.hasError
                ? this.renderErrorMessage("company_fax_number")
                : null}
            </div>
            <div
              className={`input-container half ${
                input.hours_of_operation.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='hours_of_operation'>Hours of Operation</label>
              <input
                name='hours_of_operation'
                guide='true'
                placeholder='hours'
                type='text'
                value={input.hours_of_operation.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    hours_of_operation: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.hours_of_operation.hasError
                ? this.renderErrorMessage("hours_of_operation")
                : null}
            </div>
          </div>
          <div className='admin-form-row'>
            <div
              className={`input-container${
                input.street_address_primary.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>Street Address </label>
              <input
                name='street_address_primary'
                placeholder=''
                type='text'
                value={input.street_address_primary.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    street_address_primary: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.street_address_primary.hasError
                ? this.renderErrorMessage("street_address_primary")
                : null}
            </div>
          </div>
          <div className='admin-form-row'>
            <div
              className={`input-container two-thirds${
                input.street_address_secondary.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>Street Address Line 2</label>
              <input
                name='street_address_secondary'
                placeholder=''
                type='text'
                value={input.street_address_secondary.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    street_address_secondary: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.street_address_secondary.hasError
                ? this.renderErrorMessage("street_address_secondary")
                : null}
            </div>
            <div
              className={`input-container one-third ${
                input.zipcode.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>Zip Code</label>
              <MaskedTextInput
                guide='true'
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                name='zipcode'
                placeholder='90210'
                placeholderChar={"\u2000"}
                type='text'
                value={input.zipcode.value}
                onChange={(event) =>
                  this.handleInputChange({
                    zipcode: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.zipcode.hasError
                ? this.renderErrorMessage("zipcode")
                : null}
            </div>
          </div>
          <div className='admin-form-row'>
            <div
              className={`input-container half${
                input.city.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>City</label>
              <input
                name='city'
                placeholder=''
                type='text'
                value={input.city.value || ""}
                onChange={(event) =>
                  this.handleInputChange({
                    city: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.city.hasError ? this.renderErrorMessage("city") : null}
            </div>
            <div
              className={`input-container half${
                input.state.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='phone'>State </label>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className='form-select'
                name='state'
                value={input.state.value}
                placeholder='Select a State'
                options={stateDataShortNames}
                resetValue=''
                onChange={(event) =>
                  this.handleInputChange({
                    state: { value: event ? event.value : "", hasError: false },
                  })
                }
              />
              {input.state.hasError ? this.renderErrorMessage("state") : null}
            </div>
          </div>
        </Panel>
        <input
          type='submit'
          value='Save Changes'
          className='cta mg-brand2-color'
          id='formSubmitId'
        />
      </form>
    );
  }
}

export default withRouter(ClientsProfileForm);
