import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import * as API from "../../../../utils/api";
import { amountMask, getStrippedDollarAmount, quantityMask, percentMask } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import Emitter from "../../../../utils/event-emitter";

class AddTermModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                name: {
                    value: "",
                    hasError: false,
                },
                dueDays: {
                    value: "",
                    hasError: false,
                },
                earlyPayerDiscDay: {
                    value: "",
                    hasError: false,
                },
                earlyPayerDiscRate: {
                    value: "",
                    hasError: false,
                },
                earlyPayerDiscRatePerc: {
                    value: "",
                    hasError: false,
                },
                latePayerPenaltyRate: {
                    value: "",
                    hasError: false,
                },
                latePayerPenaltyRatePerc: {
                    value: "",
                    hasError: false,
                }
            },
            isLoading: false,
            earlyPayerDiscRateType: "PERCENT",
            latePayerPenaltyRateType: "PERCENT",
            isUpdateData: false,
            isDefaultValue: false,
            error: {
                hasAnyError: false,
                statusCode: 200,
            },
            termsProps: {}
        }
    }

    componentDidMount() {
        let { input } = this.state;
        let { optionalProps } = this.props;
        let { rowData } = optionalProps;

        if (rowData && rowData.id) {
            this.setEditData(rowData)
        }
    }

    setEditData = (rowData) => {
        this.setState({isLoading: true, isDefaultValue: rowData.is_default});
        this.handleInputChange({name: { value: rowData.name, hasError: false }});
        this.handleInputChange({dueDays: { value: rowData.due_days.toString(), hasError: false }});
        if (rowData.early_payer_discount_days) {
            this.handleInputChange({earlyPayerDiscDay: { value: rowData.early_payer_discount_days.toString(), hasError: false }});
        } else {
            this.handleInputChange({earlyPayerDiscDay: { value: '0', hasError: false }});
        }
        

        if(rowData.early_payer_discount_rate_percent_based && rowData.early_payer_discount_rate_percent_based === true){
            let amt;
            if (rowData.early_payer_discount_rate) {
                amt = rowData.early_payer_discount_rate + "%";
            } else {
                amt = "0%";
            }
            this.handleInputChange({earlyPayerDiscRatePerc: { value: amt, hasError: false }});
            this.setState({earlyPayerDiscRateType: 'PERCENT' });
        }else {
            let amt
            if (rowData.early_payer_discount_rate) {
                amt = "$" + rowData.early_payer_discount_rate;
            } else {
                amt = "$0";
            }
            this.handleInputChange({earlyPayerDiscRate: { value: amt, hasError: false }});
            this.setState({earlyPayerDiscRateType: 'DOLLAR' });
        }

        if(rowData.late_payer_penalty_rate_percent_based  && rowData.late_payer_penalty_rate_percent_based  === true){
            let amt;
            if (rowData.late_payer_penalty_rate) {
                amt = rowData.late_payer_penalty_rate + "%";
            } else {
                amt = "0%";
            }
            this.handleInputChange({latePayerPenaltyRatePerc: { value: amt, hasError: false }});
            this.setState({latePayerPenaltyRateType: 'PERCENT' });
        }else {
            let amt;
            if (rowData.late_payer_penalty_rate) {
                amt = "$" + rowData.late_payer_penalty_rate;
            } else {
                amt = "$0";
            }
            this.handleInputChange({latePayerPenaltyRate: { value: amt, hasError: false }});
            this.setState({latePayerPenaltyRateType: 'DOLLAR' });
        }
        this.setState({isUpdateData: true})
        setTimeout(() => {
            this.setState({isLoading: false});
        }, 800);

    }

    handleInputChange(newPartialInput) {
        this.setState((state) => ({
            ...state,
            input: {
                ...state.input,
                ...newPartialInput,
            },
        }));
    }

    handleInputBlur = () => {
        let { input } = this.state;
        if (input.dueDays.value) {
          if (!input.dueDays.value.includes(".")) {
            input.dueDays.value = input.dueDays.value + ".00";
          }
        }
        this.setState({ input });
    };

    renderErrorMessage(input) {
        let message = "";

        if (input === "name") {
            message = "Please enter a name.";
        } else if (input === "dueDays") {
            message = "Please enter the due day(s).";
        } else if (input === "earlyPayerDiscDay") {
            message = "Please enter the early payer disc. day(s).";
        } else if (input === "earlyPayerDiscRate") {
            message = "Please enter the early payer disc. amount.";
        } else if (input === "earlyPayerDiscRatePerc") {
            message = "Please enter the early payer disc. percentage.";
        } else if (input === "latePayerPenaltyRate") {
            message = "Please enter the late payer penalty amount";
        } else if (input === "latePayerPenaltyRatePerc") {
            message = "Please enter the late payer penalty percentage";
        }

        return <div className="input-error-message">{message}</div>;
    }

    handleModalClose = () => {
        this.props.hideModal();
    };

    onRadioChange = (type, value) => {
        this.setState({isLoading: true});
        if(type && type === 'earlyPayerDiscRateType'){
            this.setState({earlyPayerDiscRateType: value });
            this.handleInputChange({earlyPayerDiscRate: { value: '', hasError: false }})
            this.handleInputChange({earlyPayerDiscRatePerc: { value: '', hasError: false }})

        }else if (type && type === 'latePayerPenaltyRateType'){
            this.setState({latePayerPenaltyRateType: value, latePayerPenaltyRatePerc: '', latePayerPenaltyRate: ''});
            this.handleInputChange({latePayerPenaltyRate: { value: '', hasError: false }})
            this.handleInputChange({latePayerPenaltyRatePerc: { value: '', hasError: false }})
        };
        setTimeout(() => {
            this.setState({isLoading: false});
        }, 1500);
    }

    handleSubmit = (event) => {
        let { optionalProps } = this.props;
        let { rowData } = optionalProps;
        event.preventDefault();
        if(this.validateForm()){
            if(this.state.isUpdateData){
                if (!rowData.is_default && rowData.is_default !== this.state.isDefaultValue) {
                    this.handleUpdate();
                }else if(rowData.is_default === this.state.isDefaultValue){
                    this.handleUpdate();
                } else {
                    this.props.updateModalState(true, "ERROR", {
                        message: "Atleast one term should be set as a default term.",
                    });
                }
            }else{
                this.handleAdd();
            }
        }else{
        }
    }

    handleAdd = () => {
        let payload = {
            name: '',
            due_days: 0,
            early_payer_discount_days: 0,
            early_payer_discount_rate: 0,
            late_payer_penalty_rate: 0,
            early_payer_discount_rate_percent_based: true,
            late_payer_penalty_rate_percent_based: true,
            is_default: this.state.isDefaultValue
        }

        payload.name = this.state.input.name.value;
        payload.due_days = this.state.input.dueDays.value;
        payload.early_payer_discount_days = this.state.input.earlyPayerDiscDay.value;

        if(this.state.earlyPayerDiscRateType === 'PERCENT'){
            payload.early_payer_discount_rate_percent_based = true;
            payload.early_payer_discount_rate = this.state.input.earlyPayerDiscRatePerc.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        } else{
            payload.early_payer_discount_rate_percent_based = false;
            payload.early_payer_discount_rate = this.state.input.earlyPayerDiscRate.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        }
        
        if(this.state.latePayerPenaltyRateType === 'PERCENT'){
            payload.late_payer_penalty_rate_percent_based = true;
            payload.late_payer_penalty_rate = this.state.input.latePayerPenaltyRatePerc.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        } else{
            payload.late_payer_penalty_rate_percent_based = false;
            payload.late_payer_penalty_rate = this.state.input.latePayerPenaltyRate.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        }
        let termsProps = {
            user: this.props.user,
            reload: this.props.optionalProps.reload,
            rowData: payload,
        };
        this.setState({termsProps: termsProps});
        API.clientCreateTermsInSettings(this.props.user, payload).then((data) => {
            this.handleCommonResponse(data);
        });
    };

    handleUpdate = () => {
        let { optionalProps } = this.props;
        let { rowData } = optionalProps;
        let payload = {
            name: '',
            due_days: 0,
            early_payer_discount_days: 0,
            early_payer_discount_rate: 0,
            late_payer_penalty_rate: 0,
            early_payer_discount_rate_percent_based: true,
            late_payer_penalty_rate_percent_based: true,
            id: rowData.id,
            is_default: this.state.isDefaultValue
        }

        payload.name = this.state.input.name.value;
        payload.due_days = this.state.input.dueDays.value;
        payload.early_payer_discount_days = this.state.input.earlyPayerDiscDay.value;

        if(this.state.earlyPayerDiscRateType === 'PERCENT'){
            payload.early_payer_discount_rate_percent_based = true;
            payload.early_payer_discount_rate = this.state.input.earlyPayerDiscRatePerc.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        } else{
            payload.early_payer_discount_rate_percent_based = false;
            payload.early_payer_discount_rate = this.state.input.earlyPayerDiscRate.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        }
        
        if(this.state.latePayerPenaltyRateType === 'PERCENT'){
            payload.late_payer_penalty_rate_percent_based = true;
            payload.late_payer_penalty_rate = this.state.input.latePayerPenaltyRatePerc.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        } else{
            payload.late_payer_penalty_rate_percent_based = false;
            payload.late_payer_penalty_rate = this.state.input.latePayerPenaltyRate.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        }

        let termsProps = {
            user: this.props.user,
            reload: this.props.optionalProps.reload,
            rowData: payload,
        };
        this.setState({termsProps: termsProps});
        API.clientUpdateTermsInSettings(this.props.user, payload).then((data) => {
            this.handleCommonResponse(data);
        });
    };

    handleCommonResponse = (data) => {
        if (data && data.status_code) {
          if (data.status_code == 200) {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
          } else if (data.status_code == 422) {
            Emitter.emit("NOTIFICATION_ALERT", true);
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
           else {
              this.props.updateModalState(true, "TERM_ERROR", {
              message: data.message,
              termsProps: this.state.termsProps
            });
          }
          this.props.optionalProps.reload(false);
          this.setState({ isLoading: false });
        } else {
          this.handleError(data);
        }
    };

    handleIsDefault = (e) => {
        // e.stopImmediatePropagation();
        // e.stopPropagation();
        this.setState({isDefaultValue: e.target.checked})
    }

    validateForm = () => {
        let errorsArePresent = false;
        let newInputState = this.state.input;
    
        Object.entries(newInputState).forEach(([key, value]) => {
          if (key === "name") {
            if (value.value === "" || value.value === null) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          } else if (key === "dueDays") {
            if (value.value === null || value.value === "" || parseFloat(value.value) < 0) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          } else if (key === "earlyPayerDiscDay") {
            if (value.value === null || value.value === "" || parseFloat(value.value) < 0) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          } else if (key === "earlyPayerDiscRate"  && this.state.earlyPayerDiscRateType === 'DOLLAR') {
            if (value.value === null || value.value === "" || parseFloat(value.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')) < 0) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          }else if (key === "earlyPayerDiscRatePerc" && this.state.earlyPayerDiscRateType === 'PERCENT') {
            if (value.value === null || value.value === "" || parseFloat(value.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')) < 0) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          }else if (key === "latePayerPenaltyRate" && this.state.latePayerPenaltyRateType === 'DOLLAR') {
            if (value.value === null || value.value === "" || parseFloat(value.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')) < 0) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          }else if (key === "latePayerPenaltyRatePerc" && this.state.latePayerPenaltyRateType === 'PERCENT') {
            if (value.value === null || value.value === "" || parseFloat(value.value.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')) < 0) {
              errorsArePresent = true;
              newInputState[key].hasError = true;
            }
          }
        });
    
        if (errorsArePresent) {
          this.setState((state) => ({
            ...state,
            input: newInputState,
          }));
        } else {
          return true;
        }
      };

    renderCheckBoxes = (type, handleAction) => {
        return (
          <div className="check-box-view">
            <div className="inner-row">
              <input
                type="radio"
                checked={type == "PERCENT"}
                style={{
                  WebkitAppearance: `radio`,
                  width: `18px`,
                  MozAppearance: `radio`,
                }}
                onClick={() => handleAction("PERCENT")}
              />
              <label>Percent ( % )</label>
            </div>
            <div className="inner-row">
              <input
                type="radio"
                checked={type == "DOLLAR"}
                style={{
                  WebkitAppearance: `radio`,
                  width: `18px`,
                  MozAppearance: `radio`,
                }}
                onClick={() => handleAction("DOLLAR")}
              />
              <label>Amount ( $ )</label>
            </div>
          </div>
        );
      };

    render() {
        let {
            input,
            isLoading,
            error,
            earlyPayerDiscRateType,
            latePayerPenaltyRateType,
            isUpdateData,
            isDefaultValue
        } = this.state;
        let { optionalProps } = this.props;

        if (error.hasAnyError) {
            if (error.statusCode == 500) {
                this.setState({
                    error: {
                        ...this.state.error,
                        hasAnyError: false,
                        statusCode: 200,
                    },
                });
            } else if (error.statusCode == 401) {
                return <Redirect to={{ pathname: "/sign-out" }} />;
            } else {
                this.props.updateModalState(true, "OTHER_ERROR", true);
                return <Redirect to={{ pathname: "/sign-out" }} />;
            }
        }

        return(
            <Modal
                title={isUpdateData && isUpdateData === true ? "Edit Term" : "Add Term"}
                closeOnClickOutside={true}
                hideModal={this.props.hideModal}
                optionalClasses="scroll"
            >
                {isLoading ? (
                    <AppCanvasLoader />
                ) : (
                    <form>
                            <div
                                className={`input-container ${input.name.hasError ? " error" : ""
                                    }`}
                            >
                                <label htmlFor="name">Name</label>
                                <div>
                                    <input
                                        name="name"
                                        type="text"
                                        maxLength={60}
                                        placeholder={ "Enter Name"}
                                        value={input.name.value}
                                        onChange={(event) =>
                                            this.handleInputChange({
                                                name: { value: event.target.value, hasError: false },
                                            })
                                        }
                                    />
                                    {input.name.hasError ? this.renderErrorMessage("name") : null}
                                </div>
                            </div>

                            <div
                                className={`input-container ${input.dueDays.hasError ? " error" : ""
                                    }`}
                            >
                                <label htmlFor="dueDays">
                                    {"Due Days"}
                                </label>
                                <div>
                                    <MaskedTextInput
                                        mask={quantityMask}
                                        name="dueDays"
                                        placeholder="0"
                                        placeholderChar={"\u2000"}
                                        type="text"
                                        value={input.dueDays.value}
                                        // onBlur={() => this.handleInputBlur()}
                                        onChange={(event) =>
                                            this.handleInputChange({
                                                dueDays: { value: event.target.value, hasError: false },
                                            })
                                        }
                                    />
                                    {input.dueDays.hasError ? this.renderErrorMessage("dueDays") : null}
                                </div>
                            </div>

                            <div
                                className={`input-container ${input.earlyPayerDiscDay.hasError ? " error" : ""
                                    }`}
                            >
                                <label htmlFor="dueDays">
                                    {"Early Payer Discount Days"}
                                </label>
                                <div>
                                    <MaskedTextInput
                                        mask={quantityMask}
                                        name="earlyPayerDiscDay"
                                        placeholder="0"
                                        placeholderChar={"\u2000"}
                                        type="text"
                                        value={input.earlyPayerDiscDay.value}
                                        // onBlur={() => this.handleInputBlur()}
                                        onChange={(event) =>
                                            this.handleInputChange({
                                                earlyPayerDiscDay: { value: event.target.value, hasError: false },
                                            })
                                        }
                                    />
                                    {input.earlyPayerDiscDay.hasError ? this.renderErrorMessage("earlyPayerDiscDay") : null}
                                </div>
                            </div>

                            <div
                                className={`input-container ${earlyPayerDiscRateType === "DOLLAR" && input.earlyPayerDiscRate.hasError ? " error" : earlyPayerDiscRateType === "PERCENT" && input.earlyPayerDiscRatePerc.hasError ? " error" : ""
                                    }`}
                            >
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <label htmlFor="dueDays" style={{width: '40%'}}>
                                        {"Early Payer Discount Rate"}
                                    </label>
                                    {this.renderCheckBoxes(earlyPayerDiscRateType, (e) => this.onRadioChange('earlyPayerDiscRateType', e))}
                                </div>
                                
                                {earlyPayerDiscRateType === "DOLLAR"
                                    ?   <div>
                                            <MaskedTextInput
                                                mask={amountMask}
                                                name="earlyPayerDiscRate"
                                                placeholder="$0"
                                                placeholderChar={"\u2000"}
                                                type="text"
                                                value={input.earlyPayerDiscRate.value}
                                                // onBlur={() => this.handleInputBlur()}
                                                onChange={(event) => {
                                                    this.handleInputChange({
                                                        earlyPayerDiscRate: { value: event.target.value, hasError: false },
                                                    })
                                                    }
                                                }
                                            />
                                            {input.earlyPayerDiscRate.hasError ? this.renderErrorMessage("earlyPayerDiscRate") : null}
                                        </div>
                                    :   <div>
                                            <MaskedTextInput
                                                mask={percentMask}
                                                name="earlyPayerDiscRatePerc"
                                                placeholder="0%"
                                                placeholderChar={"\u2000"}
                                                type="text"
                                                value={input.earlyPayerDiscRatePerc.value}
                                                // onBlur={() => this.handleInputBlur()}
                                                onChange={(event) =>{
                                                    this.handleInputChange({
                                                        earlyPayerDiscRatePerc: { value: event.target.value, hasError: false },
                                                    })
                                                    }   
                                                }
                                            />
                                            {input.earlyPayerDiscRatePerc.hasError ? this.renderErrorMessage("earlyPayerDiscRatePerc") : null}
                                        </div>
                                    }
                            </div>

                            <div
                                className={`input-container ${latePayerPenaltyRateType === "DOLLAR" && input.latePayerPenaltyRate.hasError ? " error" : latePayerPenaltyRateType === "PERCENT" && input.latePayerPenaltyRatePerc.hasError ? " error" : ""
                                    }`}
                            >
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <label htmlFor="dueDays" style={{width: '40%'}}>
                                        {"Late Payer Penalty Rate"}
                                    </label>
                                    {this.renderCheckBoxes(latePayerPenaltyRateType, (e) => this.onRadioChange('latePayerPenaltyRateType', e))}
                                </div>
                                
                                {latePayerPenaltyRateType === "DOLLAR"
                                ?   <div>
                                        <MaskedTextInput
                                            mask={amountMask}
                                            name="latePayerPenaltyRate"
                                            placeholder="$0"
                                            placeholderChar={"\u2000"}
                                            type="text"
                                            value={input.latePayerPenaltyRate.value}
                                            // onBlur={() => this.handleInputBlur()}
                                            onChange={(event) =>
                                                this.handleInputChange({
                                                    latePayerPenaltyRate: { value: event.target.value, hasError: false },
                                                })
                                            }
                                        />
                                        {input.latePayerPenaltyRate.hasError ? this.renderErrorMessage("latePayerPenaltyRate") : null}
                                    </div>
                                :   <div>
                                        <MaskedTextInput
                                            mask={percentMask}
                                            name="latePayerPenaltyRatePerc"
                                            placeholder="0%"
                                            placeholderChar={"\u2000"}
                                            type="text"
                                            value={input.latePayerPenaltyRatePerc.value}
                                            // onBlur={() => this.handleInputBlur()}
                                            onChange={(event) =>
                                                this.handleInputChange({
                                                    latePayerPenaltyRatePerc: { value: event.target.value, hasError: false },
                                                })
                                            }
                                        />
                                        {input.latePayerPenaltyRatePerc.hasError ? this.renderErrorMessage("latePayerPenaltyRatePerc") : null}
                                    </div>
                                }
                            </div>

                            <div className="confirm-default-div">
                                <input 
                                    type="checkbox"
                                    name="isDefault"
                                    style={styles.checkInput}
                                    defaultChecked={isDefaultValue}
                                    checked={isDefaultValue}
                                    onChange={(e)=>this.handleIsDefault(e)} />
                                    <div>Set it as a default term?</div>
                            </div>
                            
                            <div className="modal-actions" id="submit-service">
                                <a
                                    className="cta mg-brand2-color"
                                    onClick={(event) => this.handleSubmit(event)}
                                    style={{ paddingLeft: "3em", paddingRight: "3em" }}
                                >
                                    Submit
                                </a>
                            </div>
                    </form>
                )}
            </Modal>
        )
    }
}

const styles = {
    checkDiv: {},
    checkInput: {
        width: '20px',
        height: '20px',
        border: '1px solid #000000',
        // appearance: 'unset'
        // -webkit-appearance: 'unset'
    }
}

export default withRouter(AddTermModal);