import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import { Redirect } from "react-router-dom";

class StripePaymentFailed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { appName } = this.props;

    if (!this.props.isLoggedIn || !this.props.user.client_admin) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Failed</title>
        </Helmet>
        <div className={`sessions-modal modal-space`}>
          <div className="sessions-modal-logo-container centerd-content">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading qb-heading-error">
              Payment account creation Failed
            </h1>
            <p className="qb-msg-text">
              {/* The authentication process with QuickBooks Online has failed,
              Please try again. */}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default StripePaymentFailed;
