import React, { Component } from 'react';
import PropTypes from 'prop-types';


// API
import * as API  from '../../utils/api'

class OfferCount extends Component {
   static propTypes = {
      path: PropTypes.string,
      user: PropTypes.object
   }

  state = {
    offersCount: 0
  }

  componentDidMount(){
     // this.interval = setInterval(() =>
          API.getPendingOffersCount(this.props.user).then(data => {
            this.setState(state => ({
              ...state,
              offersCount: data.offers_count,
            }))
          })
    // , 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render() {
    let { offersCount } = this.state;
    return (
       <div className ="offer-count app-section-header-heading" > {offersCount > 0 ? offersCount+" offers" : ''}</div>
    );
  }
}

export default OfferCount;
