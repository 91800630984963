import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import filter_icon from "../../assets/icons/filter.svg";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import DropdownButton from "../../components/DropdownButton";
// Components
import MenuItem from "../../components/MenuItem";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
// API
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";

class LobHistory extends Component {
  constructor(props) {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleRowActionClick = this.handleRowActionClick.bind(this);
    this.state = {
      savedData: [],
      filter_icon: filter_icon,
      hasMoreData: false,
      dropdownIsActive: false,
      isLoading: true,
      searchText: "",
      showFlash: false,
      offset: 0,
      search: {
        value: "",
      },
      action: "",
      filterText: "",
      sendFrom: "",
      sendTo: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { searchText, offset } = this.state;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    this.getLobData();
    getZohoPagesense();
    // ReactGA.pageview("/LobHistory");
  }

  getLobData() {
    this.setState((state) => ({ ...state, showLoader: true }));
    const { user } = this.props;
    const { searchText, offset, action, filterText, sendFrom, sendTo } =
      this.state;
    let queryString = "";
    {
      action
        ? action === "date"
          ? (queryString =
              "send_date_from=" +
              sendFrom +
              "&send_date_to=" +
              sendTo +
              "&all=" +
              false)
          : (queryString = action + "=" + filterText + "&all=" + false)
        : searchText
        ? (queryString = "all=" + false)
        : (queryString = "all=" + true);
    }

    API.fetchLobHistory(user, offset, searchText, queryString).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        !data.errors &&
        !isAccessDenied
      ) {
        let savedData = this.state.savedData;
        let newData = savedData ? savedData.concat(data.data) : data.data;

        this.setState((state) => ({
          ...state,
          savedData: newData,
          isLoading: false,
          hasMoreData: data.data.length < 20 ? false : true,
          showLoader: false,
          // offset:data.offset,
        }));
      } else if (data.data == null) {
        this.setState((state) => ({
          showLoader: false,
          isLoading: false,
        }));
        this.handleModalClose();
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
          title: "Info!",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  handleModalClose = () => {
    this.props.hideModal();
  };
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getLobData();
        }
      );
    }
  };

  handleKeyPress(event) {
    let searchText = this.state.search.value;
    if (event.key === "Enter") {
      this.setState(
        (state) => ({
          ...state,
          searchText: searchText,
          savedData: [],
          offset: 0,
          action: "",
          filterText: "",
          sendFrom: "",
          sendTo: "",
          showLoader: true,
        }),
        () => {
          this.getLobData();
        }
      );
    }
  }

  handleSearchInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        search: {
          value: newPartialInput.search.value,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  rendeSkipFilterModal(action, title) {
    let props = {
      action: action,
      handlefilterInput: this.handlefilterInput,
      title: title,
    };
    this.props.updateModalState(true, "LOB_FILTER", props);
  }

  handlefilterInput = (input) => {
    let { user } = this.props;
    const { from, to, filterText, action } = input;

    this.setState(
      (state) => ({
        ...state,
        savedData: [],
        searchText: "",
        action: action,
        filterText: filterText,
        sendFrom: from,
        sendTo: to,
        offset: 0,
        showLoader: true,
      }),
      () => {
        this.getLobData();
      }
    );
  };

  handleClickAction = (action, title) => {
    switch (action) {
      case action:
        this.rendeSkipFilterModal(action, title);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive,
    }));
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  handleRowActionClick(event) {
    let props = {
      image: event.imageURL,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  }

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Letter ID",
      "Send Date",
      "Ex.Delivery Date",
      "From",
      "To",
      "Carrier",
      "Mail Type",
      "Tracking No.",
      "expander",
    ];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Letter ID",
            title: `${row.letter_id ? row.letter_id : "n/a"}`,
          },
          {
            key: "Send Date",
            title: row.send_date
              ? this.renderLatestPaymentDate(row.send_date)
              : "n/a",
          },
          {
            key: "Ex.Delivery Date",
            title: row.expected_delivery_date
              ? this.renderLatestPaymentDate(row.expected_delivery_date)
              : "n/a",
          },
          {
            key: "From",
            title: `${row.from ? row.from : ""}`,
          },
          {
            key: "To",
            title: `${row.to ? row.to : ""}`,
          },
          {
            key: "Carrier",
            title: `${row.carrier ? row.carrier : ""}`,
          },
          {
            key: "Mail Type",
            title: `${row.mail_type ? row.mail_type : ""}`,
          },
          {
            key: "Tracking No.",
            title: `${row.tracking_number ? row.tracking_number : "n/a"}`,
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            icon: "eye",
          },
        ],
        imageURL: `${row.image ? row.image : ""}`,
      };
    });
    return {
      headings,
      columns,
    };
  };

  getSectionHeaderUtilities() {
    const { search, dropdownIsActive } = this.state;

    return (
      <div className='app-section-header-utilities header-utility-history'>
        <DropdownButton
          historyFilter={true}
          style={{ minWidth: 180, marginTop: 0 }}
        >
          {/* <MenuItem
            handleClick={(event) => this.handleClickAction("from", "Mail From")}
          >
            From
          </MenuItem> */}
          <MenuItem
            handleClick={(event) => this.handleClickAction("to", "Mail To")}
          >
            To
          </MenuItem>
          {/* <MenuItem
            handleClick={(event) =>
              this.handleClickAction("mailType", "Mail Type")
            }
          >
            Mail Type
          </MenuItem> */}
          <MenuItem
            handleClick={(event) => this.handleClickAction("date", "Date")}
          >
            Date
          </MenuItem>
        </DropdownButton>
      </div>
    );
  }

  render() {
    const { appName } = this.props;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;
    return (
      <div>
        {this.getSectionHeaderUtilities()}
        {this.state.savedData.length > 0 ? (
          <BottomScrollListener
            onBottom={this.handleContainerOnBottom}
            debounce={0}
            offset={200}
          >
            {(scrollRef) => (
              <div>
                {isSmallDevice ? (
                  <div className='big-device-grad invoice-line'>
                    <div ref={scrollRef} className='scrollbar in-sc'>
                      <ClientsCardView
                        data={this.getObjectListData(this.state.savedData)}
                        rowActionClick={(event) =>
                          this.handleRowActionClick(event)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: "1em" }}>
                    <HistoryObjectList
                      data={this.getObjectListData(this.state.savedData)}
                      rowActionClick={(event) =>
                        this.handleRowActionClick(event)
                      }
                      hideForMobile={true}
                      optionalClasses='scrollbar in-sc'
                      scrollRef={scrollRef}
                    />
                  </div>
                )}
                {this.state.showLoader && <AppCanvasLoader />}
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className='admin-admins-empty-state'>
            {this.state.showLoader ? (
              <AppCanvasLoader />
            ) : (
              <h3 className='empty-mesg-mg'>No letter history available.</h3>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(LobHistory);
