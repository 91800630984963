import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import AppSectionHeader from '../../components/App/AppSectionHeader';
// API
import * as API from '../../utils/api';
import { showToast } from '../../utils/helpers';

class AdminViewAddressDoc extends Component {

  state = {
    consumerAccountId: null,
    consumer: {},
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const consumerAccountId = this.props.match.params.id;
    API.getViewConsumerAddressDocument(this.props.user, 'admin', consumerAccountId).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        let { consumer, address_document } = data.account;
        this.setState(state => ({
          ...state,
          consumer: consumer,
          addressDocument: address_document,
          isLoading: false
        }));
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          isLoading: false
        }))
      }
    });
  }


  approveDocument() {
    const consumerAccountId = this.state.consumer.id;
    API.approveAddressDocument(this.props.user, 'admin', consumerAccountId).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        if (data.message) {
          this.props.updateModalState(true, 'ERROR', {message: data.message});
        } else {
          let { consumer, address_document } = data.account;
          this.setState(state => ({
            ...state,
            consumer: consumer,
            addressDocument: address_document,
            isLoading: false
          }));
          this.props.updateModalState(true, 'SUCCESS', {message: 'You have verified the user address document.'});
        }
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          isLoading: false
        }))
      }

    });
  }

  rejectDocument() {
    const consumerAccountId = this.state.consumer.id;
    API.rejectAddressDocument(this.props.user, 'admin', consumerAccountId).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        if (data.message) {
          this.props.updateModalState(true, 'ERROR', {message: data.message});
        } else {
          let { consumer, address_document } = data.account;
          this.setState(state => ({
            ...state,
            consumer: consumer,
            addressDocument: address_document,
            isLoading: false
          }));
          this.props.updateModalState(true, 'SUCCESS', {message: 'You have rejected the user address document.'});
        }
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          isLoading: false
        }))
      }

    });
  }

  render() {
    const { consumer, addressDocument, isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <AppSectionHeader
            title="View Address Document"
          />
          <div className="scrollbar" style={{ height: window.innerHeight * 0.7, overflow: 'auto', paddingBottom: 50 }}>
            <div className="address-document-status"> Document Current Status : {addressDocument ? addressDocument.status ? addressDocument.status : '' : ''} </div>
            <div className="object-list-empty-state chat-margin img-align">
              {addressDocument ? addressDocument.document ? addressDocument.document.indexOf('pdf') !== -1 ?
                <div>
                  <object data={addressDocument.document} type="application/pdf" style={{ width: `800px`, height: `500px` }}>
                    <embed src={addressDocument.document} type="application/pdf" />
                  </object>
                </div>
                :
                <div style={{ lineHeight: 'initial' }}>
                  {addressDocument ? <img alt='document' src={addressDocument.document} width="800" /> : 'No Address Document Available.'}
                </div>
                : <div className="consumer-accounts-empty-state">
                    <h3 className="consumer-accounts-empty-state-heading" style={{height: 'auto'}}>
                      No Document Available
                    </h3>
                  </div>
                : <div className="consumer-accounts-empty-state" style={{height: 'auto'}}>
                    <h3 className="consumer-accounts-empty-state-heading">
                      No Document Available
                    </h3>
                  </div>
              }
            </div>

            {addressDocument ? addressDocument.status ? addressDocument.status === 'PENDING' ?
              <div><div className="btn-div-width"><input type="button" value="Verify" className="btn-approve-reject" onClick={() => { this.approveDocument(this) }} /></div><div className="btn-div-width" ><input type="button" value="Reject" className="btn-approve-reject" onClick={() => { this.rejectDocument(this) }} /></div></div>
              : '' : '' : ''}

          </div>
        </div>
      );
  }
}

export default AdminViewAddressDoc;
