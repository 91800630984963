import React, { Component } from "react";
import Heap from "react-heap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Logo from "../../../../screens/ClientsPortal/sso/static-pages/StaticLogo";
import LoginForm from "./LoginForm";
import signInWithIntuit from "../../../../assets/images/landing/Sign_in_transparent_btn_tall_default.png";
// Components
import SessionsModal from "../../../../components/Sessions/SessionsModal";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAppConfig: false,
      signInWithIntuitImg: "",
    };
  }

  componentDidMount() {localStorage.setItem("static", true);
  localStorage.setItem("type", "PAYMENT_PORTAL_PREVIEW");}

  render() {
    const { heapEmail, heapAppId } = this.props;

    let { appName, location } = this.props;
    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Login</title>
        </Helmet>
        {heapAppId && heapEmail ? (
          <Heap appId={heapAppId} userId={heapEmail} />
        ) : null}

        <SessionsModal title="Sign into your account">
          <div className="sessions-modal-logo-container mg-logo-container">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading" style={{ color: "#008bb2" }}>
              Sign into your account
            </h1>
            <LoginForm
              updateUserLogin={this.props.updateUserLogin}
              history={this.props.history}
              location={this.props.location}
              updateModalState={this.props.updateModalState}
            />
            <div className="sessions-modal-alternative-actions">
              <span>Don't have an account?</span>
              <button disabled={true} className="mg-text-link-static">
                Sign Up Now
              </button>
            </div>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <button disabled={true}>
              <img src={signInWithIntuit} />
            </button>
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default Login;
