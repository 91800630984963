import React from "react";
import "react-select/dist/react-select.css";
export default class FileBase64Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.inputRef = React.createRef();
  }

  handleChange(e) {
    // get the files
    let files = e.target.files;

    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000),
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length == files.length) {
          // Apply Callback function
          if (this.props.multiple) {
            this.props.onDone(allFiles);
          } else {
            this.props.onDone(allFiles[0]);
          }
        }
      }; // reader.onload
    } // for
  }

  render() {
    let {
      isCustomBtn,
      customFlow6,
      buttonText,
      optionalClass,
      input,
      id,
      disableInput,
    } = this.props;

    return (
      <>
        <div className={`file-text ${isCustomBtn ? "hide-input" : ""}`}>
          <input
            ref={isCustomBtn ? this.inputRef : null}
            id={id}
            className={isCustomBtn ? "hide-input" : ""}
            style={{
              display: isCustomBtn ? "none !important" : "block",
              color: "darkgrey",
            }}
            type='file'
            accept={this.props.acceptDoc ? this.props.acceptDoc : ""}
            onChange={this.handleChange.bind(this)}
            multiple={this.props.multiple ? this.props.multiple : false}
            disabled={
              this.props.hasOwnProperty("disableInput") && disableInput
                ? true
                : false
            }
          />
        </div>

        {isCustomBtn ? (
          <button
            className={`cta mg-brand2-color W9form  ${
              optionalClass ? optionalClass : ""
            }`}
            style={{
              width: "255px",
              height: "50px",
              paddingTop: buttonText ? "10px" : "12px",
              borderRadius: "0.5em",
              marginTop: "20px",
              left: customFlow6 ? "0" : buttonText ? "0px" : "160px",
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "Open Sans",
            }}
            onClick={() => {
              this.inputRef.current.click();
            }}
          >
            {buttonText ? buttonText : " Choose file"}{" "}
          </button>
        ) : null}
      </>
    );
  }
}
