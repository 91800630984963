// ImportDataPolling.js
import React, { useEffect } from "react";
import { useImport } from "../Context/ImportContext";
import * as API from "../utils/api";
import { toast } from "react-toastify";
import Emitter from "../utils/event-emitter";

const ImportDataPolling = (props) => {
  const { isPolling, setStatus, setIsPolling, importType } = useImport();

  const refreshAccountList = () => {
    Emitter.emit("ACCOUNT_LIST_REFRESH", { reload: false, isSort: false });
  };

  const refreshInvoiceList = () => {
    Emitter.emit("INVOICE_LIST_REFRESH", { reload: false });
  };

  const checkImportStatus = () => {
    API.importStatus(props.user, importType).then((res) => {
      if (res.status_code === 200) {
        const newStatus = res.import_status.status;
        setStatus(newStatus);
        if (newStatus === "completed") {
          setIsPolling(false);
          if (res.import_status.entity_type === "Account") {
            toast.success("Accounts imported successfully");
            refreshAccountList();
          } else {
            toast.success("Invoices imported successfully");
            refreshInvoiceList();
          }
        }
      } else {
        setIsPolling(false);
        if (importType === "account") {
          toast.error("Accounts imported failed");
        } else {
          toast.error("Invoices imported failed");
        }
      }
    });
  };

  useEffect(() => {
    let interval;
    if (isPolling) {
      // Start polling every 3 seconds
      interval = setInterval(checkImportStatus, 3000);
    }

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [isPolling]);

  return null;
};

export default ImportDataPolling;
