import React, { Component } from "react";
import PropTypes from "prop-types";

class HeaderMenuItem extends Component {
  static propTypes = {
    handleClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
  };

  render() {
    const {
      handleClick,
      children,
      dataBehavior,
      dataTarget,
      optionalClasses,
      rowAction
    } = this.props;
    return (
      <li
        className={`menu-item${optionalClasses ? " " + optionalClasses : ""}`}
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        onClick={(event) => {
          handleClick(event, rowAction);
        }}
        data-behavior={dataBehavior}
        data-target={dataTarget}
      >
        {children.icon && <div className="icon-view">{children.icon}</div>}
        <div>{children.title}</div>
      </li>
    );
  }
}

export default HeaderMenuItem;
