import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// Utils
import * as API from "../../utils/api";
import AppCanvasLoader from "../App/AppCanvasLoader";
import Cookies from "universal-cookie";

class SignOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgKey: "",
    };
  }
  componentWillMount() {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    API.getOrganizationData().then((data) => {
      let user = {
        user_auth_token: "Bearer " + authuser,
        organization_key: data.organization_key,
      };
      if (authuser && authuser != null) {
        API.signout(user).then(() => {
          this.props.handleSignOut();
        });
      } else {
        this.props.handleSignOut();
      }
    });
  }

  render() {
    if (this.props.isLoggedIn) {
      return (
        <div style={{ marginTop: `40%` }}>
          <AppCanvasLoader />
        </div>
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }
  }
}

export default SignOut;
