import moment from "moment";
import React, { Component } from "react";
// import ReactGA from "react-ga";
import shortid from "shortid";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";
import Tabs from "../../components/Tabs";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ClientsSidebar from "../../components/Clients/ClientsSidebar";
import HeaderOverflowMenu from "../../components/ObjectList/HeaderOverflowMenu";
import HeaderMenuItem from "../../components/HeaderMenuItem";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";
import PlacementEligibleIcon from "../../assets/icons/delinquent.png";
import deleteIcon from "../../assets/icons/delete.png";
import DollerIcon from "../../assets/images/dollerIcon.png";
import LockIcon from "../../assets/icons/lock.png";
import {
  getDollarAmount,
  getZohoPagesense,
  showToast,
  isSameStartDateAndDueDate,
  displayPlanStatus,
  getCalculatedPlanAmount,
  formatDate,
} from "../../utils/helpers";
import ClientsUploadValidationDoc from "./ClientsUploadValidationDoc";

import dollorIcon from "../../assets/icons/dollar-teal.png";
import deleteImg from "../../assets/icons/delete.png";
import downloadImg from "../../assets/icons/download.png";
import editImg from "../../assets/icons/edit_blue.svg";
import viewIcon from "../../assets/icons/view.png";
import Statement from "../../assets/icons/Statement.png";
// import Emailpng from "../../assets/icons/Emailpng.png";
import Emailsvg from "../../assets/icons/Emailsvg.svg";
class ClientsAccountsView extends Component {
  constructor(props) {
    super(props);
    this.emailOptionRef = React.createRef();
  }
  state = {
    selectedTaskType: "Invoices",
    closeDropDown: false,
    offset: 0,
    showLoader: false,
    isStatementSelected: false,
    user: {},
    consumer_type: "open",
    consumer: {},
    stats: {},
    debts: [],
    selectedDebt: [],
    is_placed_invoices: false,
    rowAction: [],
    invoiceLength: 0,
    invoiceIdArray: 0,
    creditMemoList: [],
    statementList: [],
    paymentPlansList: [],
    receivePaymentList: [],
    paymentPlansDetails: [],
    paymentData: [],
    hasMorePaymentData: false,
    selectedOPT: "Invoices",
    isLoading: true,
    isTableLoading: true,
    activeTab: 1,
    totalAccountCredit: 0,
    selectedOfferForCounter: {},
    upcoming_activities: [],
    recent_activities: [],
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    open_additional_details: false,
    showValidationScreen: false,
    isOpenInvoiceView: false,
    isPlaceInCollection: false,
    isPaymentPlansSelected: false,
    isReceivePaymentSelected: false,
    isReceivePaymentForInvoicesSelected: false,
    paymentPlanId: "",
    creditMemodata: [],
    communicationData: {
      Letters: [],
      Notes: [],
      Emails: [],
    },
    emailDropdownActive: false,
  };

  componentDidMount() {
    this.getGenerateStatementList(this.props.match.params.id);
    this.handleSearchFilter();
    this.getAccountActivities();
    getZohoPagesense();
    this.setRowActions();
    this.getEmailTemplates();
    this.getCreditMemoData();
    // ReactGA.pageview("/ClientsAccountsView");
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  // componentWillUnmount() {
  //   document.removeEventListener("click", this.handleClickOutside);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.emailDropdownActive && !prevState.emailDropdownActive) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else if (
      !this.state.emailDropdownActive &&
      prevState.emailDropdownActive
    ) {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.emailOptionRef.current &&
      !this.emailOptionRef.current.contains(event.target)
    ) {
      this.setState({ emailDropdownActive: false });
    }
  }

  //get updated account details based on updated accountId
  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      const id = props.match.params.id;
      this.handleSearchFilter(id);
      this.getAccountActivities(id);
      // this.getCreditMemoList(id);
    }
  }
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData && this.state.consumer_type === "ALL") {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "PAST_DUE" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "CLOSED" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "OPEN" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    }
  };
  handleFilter = (consumer_type) => {
    this.setState({ consumer_type, offset: 0 }, () => {
      // this.getInvoiceList(false);
      this.handleSearchFilter(false);
    });
    this.setState({
      closeDropDown: true,
    });
    setTimeout(() => {
      this.setState({ closeDropDown: false });
    }, 200);
  };

  handleSearch = (reload, isSort) => {
    if (!isSort) {
      if (reload) {
        this.setState({ showLoader: true });
      } else {
        this.setState({ listLoader: true });
      }
    }
  };

  handleSearchFilter = (reload, isSort) => {
    reload = true;
    this.setState({
      isTableLoading: true,
      isPaymentPlansSelected: false,
      isReceivePaymentSelected: false,
      isReceivePaymentForInvoicesSelected: false,
      listLoader: true,
      showLoader: true,
    });
    let sort_by = this.state.sortType ? "asc" : "desc";
    let filterBy = this.state.consumer_type;
    const accountId = this.props.match.params.id;
    API.getClientsConsumer(
      this.props.user,
      accountId,
      sort_by,
      this.state.offset,
      filterBy
    ).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          totalAccountCredit: data.account.credits,
          consumer: data.account.consumer,
          stats: data.account.stats,
          debts: data.account.debts,
          isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showLoader: false,
          isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          listLoader: false,
          showLoader: false,
          isLoading: false,
        }));
      }
    });
  };

  //get clients Credit memo list
  getCreditMemoList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getClientCreditMemoList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          creditMemoList: data.data,
          // isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  //get clients Credit memo list
  getGenerateStatementList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getGenerateStatementList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          statementList: data.data,
          // isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  generateStatement(from, to) {
    let fromDate = "";
    let toDate = "";
    if (from && to && from != undefined && to != undefined) {
      fromDate = from;
      toDate = to;
    }
    API.generateStatement(
      this.props.user,
      this.props.match.params.id,
      fromDate,
      toDate
    ).then((data) => {
      let isAccessDenied =
        data &&
        data.status_code &&
        (data.status_code == 400 ||
          data.status_code == 401 ||
          data.status_code == 500)
          ? true
          : false;
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.getGenerateStatementList();
      } else {
        if (data.message) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            title: "Info",
          });
        } else {
          this.setState((state) => ({
            ...state,
            // isLoading: false,
            isTableLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    });
  }

  //get clients Open invoice list
  getOpenInvoiceList(id, isPlaceInCollection = false) {
    const accountId = id ? id : this.props.match.params.id;
    API.getOpenInvoicesList(
      this.props.user,
      accountId,
      isPlaceInCollection
    ).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        let tempData = [...data.data];
        if (tempData && tempData.length) {
          tempData.forEach((ele) => {
            ele.selectionCheck = false;
          });
        }
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          debts: tempData,
          // isLoading: false,
          is_placed_invoices: data.is_placed_invoices,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getPaymentPlanInvoiceList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getPaymentPlanInvoiceList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        let tempData = [...data.data];
        if (tempData && tempData.length) {
          tempData.forEach((ele) => {
            ele.selectionCheck = false;
          });
        }
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          paymentPlansList: tempData,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getUnappliedPayments(reload) {
    this.setState({ showLoader: true, isTableLoading: true });

    API.getUnappliedPayments(
      this.props.user,
      this.props.match.params.id,
      this.state.offset
    )
      .then((data) => {
        let isAccessDenied =
          data && data.status_code && data.status_code == 401 ? true : false;
        if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
          let paymentData = this.state.paymentData;
          let payments = reload ? this.state.paymentData : false;
          let newData = payments ? paymentData.concat(data.data) : data.data;
          this.setState((state) => ({
            paymentData: newData,
            hasMorePaymentData: data.data.length < 20 ? false : true,
          }));

          this.setState((state) => ({
            ...state,
            offset: this.state.offset,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isTableLoading: false,
          showLoader: false,
        }));
      });
  }

  getPaymentPlanList(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getPaymentPlanList(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // let checkData = data.payment_plans.length > 0;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        let tempData = [...data.payment_plans];
        if (tempData && tempData.length) {
          tempData.forEach((ele) => {
            ele.selectionCheck = false;
          });
        }
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          paymentPlansList: tempData,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }
  //delete clients Credit memo
  deleteCreditMemoAPI(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.deleteClientsCreditMemo(this.props.user, id).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      // Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        !isAccessDenied &&
        data.status_code == 200
      ) {
        this.setState((state) => ({
          ...state,
          isTableLoading: false,
        }));
        this.getCreditMemoList();
        this.handleSearchFilter();
      } else {
        if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState((state) => ({
          ...state,
          // isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  //getInvoiceDetails
  getInvoiceDetails = (invoiceId) => {
    let { user } = this.props;
    API.getInvoiceDetails(user, invoiceId).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data.message === "success" &&
        !data.error
      ) {
        if (data.data) {
          this.handleEditClick(data.data);
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //go to edit invoice
  handleEditClick = (data) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        invoiceDetails: data,
        backPath: `/client/accounts/${id}`,
      },
    });
  };

  //opens edit setting screen
  handleEditSetting = () => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    if (id) {
      // history.push(`/client/accounts/${id}/edit-setting`);
      history.push({
        pathname: `/client/accounts/${id}/edit-setting`,
        state: { term_name: this.state.consumer.terms_name, user_id: id },
      });
    }
  };

  //opens open-invoice view with checkboxes
  openInvoiceView = () => {
    this.setState(
      {
        isPlaceInCollection: false,
        isOpenInvoiceView: true,
        isReceivePaymentForInvoicesSelected: false,
        isTableLoading: true,
        isStatementSelected: false,
        selectedTaskType: "Invoices",
        emailDropdownActive: false,
        debts: [],
      },
      () => {
        this.getOpenInvoiceList();
      }
    );
  };

  receiveInvoicePayment = () => {
    this.setState(
      {
        isPlaceInCollection: false,
        isStatementSelected: false,
        isOpenInvoiceView: false,
        isReceivePaymentForInvoicesSelected: true,
        selectedTaskType: "Invoices",
        isTableLoading: true,
        debts: [],
      },
      () => {
        this.getOpenInvoiceList();
      }
    );
  };

  openInvoiceListForCollection = () => {
    this.setState(
      {
        isOpenInvoiceView: false,
        isPlaceInCollection: true,
        isTableLoading: true,
        isStatementSelected: false,
        debts: [],
      },
      () => {
        this.getOpenInvoiceList(this.props.match.params.id, true);
      }
    );
  };

  //get accounts recent activity and upcoming activity data
  getAccountActivities(id) {
    const accountId = id ? id : this.props.match.params.id;
    API.getAccountActivities(this.props.user, accountId).then((data) => {
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          let { recent_activities, upcoming_activities } = data.data;

          //sort recent activities
          if (recent_activities && recent_activities.length) {
            recent_activities.sort(function (a, b) {
              return new Date(b.due_date) - new Date(a.due_date);
            });
          }

          //sort upcoming activities
          if (upcoming_activities && upcoming_activities.length) {
            upcoming_activities.sort(function (a, b) {
              return new Date(a.due_date) - new Date(b.due_date);
            });
          }

          this.setState({ recent_activities, upcoming_activities });
        }
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  deleteCreditMemo = (id) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to delete this credit memo?",
      confirmClick: () => {
        this.deleteCreditMemoAPI(id);
      },
      closeOnClickOutside: true,
    });
  };

  openAcceptPaymentModal = (debt) => {
    let invoiceDetails = debt.debt_invoice;
    let targetDate;
    let criteriaDiscountFlag = false;
    if (
      invoiceDetails &&
      invoiceDetails.criteria_discount_days &&
      invoiceDetails.criteria_discount_days > 0
    ) {
      let startdate = invoiceDetails.date;
      // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
      let nn = moment(
        moment(startdate)
          .add(invoiceDetails.criteria_discount_days, "d")
          .format("YYYY/MM/DD")
      );
      let today = new Date();
      targetDate = moment(nn);
      var month = today.getUTCMonth() + 1; //months from 1-12
      var day = today.getUTCDate();
      var year = today.getUTCFullYear();

      let newdate = year + "/" + month + "/" + day;
      let momentToday = moment(newdate);
      let diffNumber = targetDate.diff(momentToday, "days");
      if (diffNumber > 0) {
        criteriaDiscountFlag = true;
      } else {
        targetDate = "";
      }
    }

    debt.debt_invoice.total_amount = debt.debt_invoice.total_amt;

    let props = {
      debtInfo: debt.debt_invoice,
      reloadData: this.reloadData,
      targetDate: targetDate,
      criteriaDiscountFlag: criteriaDiscountFlag,
      amountPaid:
        debt.payment_details &&
        Array.isArray(debt.payment_details) &&
        debt.payment_details.length > 0
          ? debt.payment_details.reduce(
              (sum, transaction) => sum + parseFloat(transaction.amount),
              0
            )
          : 0, // Default value if there are no payment details
    };
    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  goToEditInvoice = (id) => {
    this.getInvoiceDetails(id);
  };

  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(user, doc_id, "debts").then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  getAccountFromData = (id, users) => {
    let currentId = parseInt(id, 10);
    let user = users.filter(function (user) {
      return user.id === currentId;
    });

    return user[0];
  };

  //on invoice click opens invoice details screen
  rowExpanderClick = (event) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    const invoiceId = event.rowData.debt_invoice.id;
    if (invoiceId) {
      history.push({
        pathname: `/client/invoices/${invoiceId}`,
        state: { backPath: `/client/accounts/${id}` },
      });
    }
  };

  rowExpanderForPaymentPlan = (event) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    let planId = event.rowData.id;
    // const invoiceId = event.rowData.debt_invoice.id;
    if (true) {
      history.push({
        pathname: `/client/payment-plans/${planId}`,
        state: {
          backPath: `/client/accounts/${id}`,
          accountData: this.state.consumer,
        },
      });
    }
  };

  //on credit memo click opens invoice details screen
  rowExpanderClickForMemo = (event) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    const creditMemoId = event.rowData.id;
    // const creditMemoId = 1;
    if (creditMemoId) {
      history.push({
        pathname: `/client/creditMemo/${creditMemoId}`,
        state: {
          backPath: `/client/accounts/${id}`,
          accountPath: `/client/accounts/${id}`,
        },
      });
    }
  };

  reloadData = () => {
    this.handleSearchFilter();
  };

  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  getCreditMemoData() {
    const accountId = this.props.match.params.id;

    API.getAppliedCredit(accountId, "Account").then((data) => {
      if (typeof data != "undefined" || data != null) {
        this.setState({
          creditMemodata: data.data,
        });
      }
    });
    // /credit_memo/apply_credit_memo?user_id=50213
  }

  renderNewLetterModal() {
    const { consumer, user, stats, statementList, debts, creditMemodata } =
      this.state;
    const options = statementList.map((item) => ({
      Id: item.id,
      name: `${item.month} ${item.statement_date}`,
    }));
    let props = {
      consumerId: consumer.id,
      consumer: consumer,
      options: options,
      creditMemo: creditMemodata,
      debts: debts,
      stats: stats,
      type: "Account",
    };
    this.props.updateModalState(true, "SEND_NEW_LETTER", props);
  }

  renderSendNewMailModal(consumerId, newMail) {
    const { consumer, user, stats, statementList } = this.state;
    let multipleInvoiceData = this.getCheckedInvoiceList();
    let tempConsumer = { ...consumer };
    tempConsumer.user_id = consumer.id;
    const options = statementList.map((item) => ({
      Id: item.id,
      name: `${item.month} ${item.statement_date}`,
    }));
    let props;
    if (newMail) {
      props = {
        user: user,
        consumer: consumer,
        consumerId: consumerId,
        isMultipleInvoices: false,
        multipleInvoiceData: null,
        type: "Account",
        stats: stats,
        consumerId: consumer.id,
        options: options,
      };
    } else {
      props = {
        user: user,
        consumerId: consumerId,
        invoiceDetails: { user: tempConsumer },
        isMultipleInvoices: true,
        multipleInvoiceData: multipleInvoiceData,
        closeOpenInvoiceView: this.closeOpenInvoiceView,
        type: "Account",
        consumerId: consumer.id,
      };
    }

    this.props.updateModalState(true, "SEND_NEW_MAIL", props);
  }

  renderSendCustomEmailModal() {
    const { consumer, user, statementList } = this.state;
    let consumerId = user.user_id ? user.user_id : "";
    let options = [];
    statementList.forEach((item, key) => {
      options.push({
        Id: item.id,
        name: `${item.month} ${item.statement_date}`,
      });
    });
    let props = {
      user: user,
      consumerId: consumerId,
      type: "Account",
      consumer: consumer,
      options: options,
    };
    this.props.updateModalState(true, "FREE_FORM_EMAIL", props);
  }

  renderPaymentPlanCommModal(consumerId, data) {
    let temp = data.payment_plans_payments.filter(
      (item) => item.status === "due"
    );
    let user = data.user;
    if (user.last_name == null) {
      user.last_name = "";
    }

    let numbers_of_payments = "0";
    if (temp.length) {
      temp.forEach((item, i) => {
        numbers_of_payments += i + 1 + ",";
      });
      numbers_of_payments = numbers_of_payments.slice(0, -1);
    }
    let date = temp.length ? temp[0].due_date.split("T")[0] : "-";
    let temp_val =
      parseFloat(data.total_in_dollars_as_currency.trim().replace(",", "")) -
      parseFloat(data.adjustment_amount.trim().replace(",", ""));
    let plan_amt = temp_val.toFixed(3);
    let props = {
      payment_plan_id: data.id,
      payment_amount: data.monthly_payment_amount_as_currency
        .trim()
        .replace(",", ""),
      number_of_payments: data.number_of_payments,
      plan_amount: plan_amt,
      is_months_plan: data.is_months_plan,
      consumer: data.user,
      doc_data: data.documents,
      payment_amount_due: data.payment_amount_due_in_dollars,
      payment_plan_number: data.payment_plan_number,
      payment_due_date: date,
      payment_plan_status: displayPlanStatus(data.status),
      first_name: data.user.first_name,
      start_date: data.start_date.split("T")[0],
      payment_plan_payment_number: numbers_of_payments,
      // invoiceDetails: { user: tempConsumer },
      isPaymentPlanCommModel: true,
      type: "Account",
      // multipleInvoiceData: multipleInvoiceData,
      // closeOpenInvoiceView: this.closeOpenInvoiceView,
    };
    this.props.updateModalState(true, "SEND_NEW_MAIL", props);
  }

  sendMultipleInvoice = () => {
    const { invoiceDetails, user } = this.state;
    let consumerId = user.user_id ? user.user_id : "";
    let isValid = this.checkSendEmailValid();
    if (isValid) {
      this.renderSendNewMailModal(consumerId, false);
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: `Please select atleast one invoice to send an email.`,
        title: "Alert",
      });
    }
  };

  receivePaymentsInvoice = () => {
    let isValid = this.checkSendEmailValid();
    if (isValid) {
      this.renderReceivePaymentsInvoiceModal();
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: `Please select atleast one invoice to receive payments.`,
        title: "Alert",
      });
    }
  };

  SelectInvoiceToAddInPaymentPlans = () => {
    const { user } = this.state;
    let consumerId = user.user_id ? user.user_id : "";
    let isValid = this.checkInvoiceForPaymentPlanValid();
    if (isValid) {
      this.createPaymentPlan(consumerId);
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: `Please select atleast one invoice to create payment plan.`,
        title: "Alert",
      });
    }
  };

  SelectPlansToReceivePayment = () => {
    const { user, receivePaymentList } = this.state;
    let tempDebts = [...receivePaymentList];
    let consumerId = user.user_id ? user.user_id : "";
    let isValid = this.checkPlanForRecievePayment(tempDebts);
    if (true) {
      this.receivePaymentModal(consumerId);
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: `Please select atleast one plan to receive payment.`,
        title: "Alert",
      });
    }
  };

  placeInCollectionConfirmModel = () => {
    const { consumer, user, is_placed_invoices } = this.state;
    let multipleInvoiceData = this.getCheckedInvoiceListForCollection();
    let tempConsumer = { ...consumer };
    tempConsumer.user_id = consumer.id;
    let props = {
      user: user,
      invoiceDetails: { user: tempConsumer },
      isFirstTimeInCollection: !is_placed_invoices,
      multipleInvoiceData: multipleInvoiceData,
      closeInvoiceListForCollection: this.closeInvoiceListForCollection,
    };
    this.props.updateModalState(true, "PLACR_IN_COLLECTION_CONFIRM", props);
  };

  closeOpenInvoiceView = () => {
    this.setState(
      {
        isOpenInvoiceView: false,
        isPlaceInCollection: false,
        isReceivePaymentForInvoicesSelected: false,
        isTableLoading: true,
        invoiceLength: 0,
        debts: [],
      },
      () => {
        this.reloadData();
      }
    );
  };

  closeReceiveInvoicePaymentView = () => {
    this.setState(
      {
        isOpenInvoiceView: false,
        isPlaceInCollection: false,
        isReceivePaymentForInvoicesSelected: false,
        isTableLoading: true,
        invoiceLength: 0,
        debts: [],
      },
      () => {
        this.reloadData();
      }
    );
  };

  closeInvoiceListForCollection = () => {
    this.setState(
      {
        isPlaceInCollection: false,
        isOpenInvoiceView: false,
        isTableLoading: true,
        invoiceLength: 0,
        debts: [],
      },
      () => {
        this.reloadData();
      }
    );
  };

  getCreditMemoStatus = (data) => {
    let status = "";
    if (parseFloat(data.total_amount) === parseFloat(data.balance)) {
      status = "Unapplied";
    } else if (parseFloat(data.balance) === 0.0) {
      status = "Applied";
    } else if (parseFloat(data.total_amount) - parseFloat(data.balance) > 0) {
      status = "Partially Applied";
    }
    return status;
  };

  //get invoice paid amount based on total amount and balance
  getPaidAmount = (invoice, taxAmount, slowPayerAmount) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) -
            parseFloat(invoice.balance) +
            parseFloat(taxAmount) +
            parseFloat(slowPayerAmount)
          : invoice.total_amt
        : invoice.total_amt;
    }
    return amount.toFixed(2);
  };

  //check for invoice is past due or not
  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    if (flag) {
      if (isSameStartDateAndDueDate(rowData.due_date)) {
        flag = false;
      }
    }
    // if(isSameStartDateAndDueDate(rowData.txn_date, rowData.due_date)){
    //   flag = false
    // }
    return !flag;
  };

  checkSendEmailValid = () => {
    const { debts } = this.state;
    let ct = 0;
    let isSelcted = false;
    if (debts && debts.length) {
      debts.map((ele, index) => {
        if (ele.selectionCheck) {
          ct++;
        }
      });
      if (ct > 0) {
        //TO_DO display/enable send email button...
        isSelcted = true;
      } else {
        //TO_DO hide/disable send email button...
        isSelcted = false;
      }
    }
    return isSelcted;
  };

  checkInvoiceForPaymentPlanValid = () => {
    const { paymentPlansList } = this.state;
    let ct = 0;
    let isSelcted = false;
    if (paymentPlansList && paymentPlansList.length) {
      paymentPlansList.map((ele, index) => {
        if (ele.selectionCheck) {
          ct++;
        }
      });
      if (ct > 0) {
        isSelcted = true;
      } else {
        isSelcted = false;
      }
    }
    return isSelcted;
  };

  checkPlanForRecievePayment = (tempDebts) => {
    let ct = 0;
    let isSelcted = false;
    if (tempDebts && tempDebts.length) {
      tempDebts.map((ele, index) => {
        if (ele.planCheck) {
          ct++;
        }
      });
      if (ct > 0) {
        isSelcted = true;
      } else {
        isSelcted = false;
      }
    }
    return isSelcted;
  };

  checkPlaceInCollectionData = (invoiceLength) => {
    if (invoiceLength > 0) {
      this.placeInCollectionConfirmModel();
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: `Please select atleast one invoice to place in collections.`,
        title: "Alert",
      });
    }
  };

  getCheckedInvoiceList = () => {
    const { debts } = this.state;
    let invoiceIdsOBJ = {
      invoiceListArray: [],
      invoiceListSTR: "",
    };
    if (debts && debts.length) {
      debts.map((ele, index) => {
        if (ele.selectionCheck) {
          invoiceIdsOBJ.invoiceListArray.push(
            parseInt(ele.debt_invoice ? ele.debt_invoice.id : ele.id)
          );
          invoiceIdsOBJ.invoiceListSTR =
            invoiceIdsOBJ.invoiceListSTR !== ""
              ? invoiceIdsOBJ.invoiceListSTR +
                ", " +
                ele.debt_invoice.invoice_number
              : ele.debt_invoice.invoice_number;
        }
      });
    }
    return invoiceIdsOBJ;
  };

  getListForCreatingPaymentPlan = () => {
    const { paymentPlansList } = this.state;
    const { user } = this.props;
    const accountId = this.props.match.params.id;
    let invoiceIdsOBJ = {
      user: user,
      accountId: accountId,
      invoiceIds: [],
      invoiceNumbers: [],
      invoiceBalanceAmt: 0,
    };
    if (paymentPlansList && paymentPlansList.length) {
      paymentPlansList.map((ele, index) => {
        if (ele.selectionCheck) {
          invoiceIdsOBJ.invoiceIds.push(parseInt(ele.id));
          invoiceIdsOBJ.invoiceNumbers.push(ele.invoice_number);
          invoiceIdsOBJ.invoiceBalanceAmt =
            parseFloat(invoiceIdsOBJ.invoiceBalanceAmt) +
            parseFloat(ele.balance);
        }
      });
    }
    return invoiceIdsOBJ;
  };

  getCheckedInvoiceListForCollection = () => {
    const { debts } = this.state;
    let invoiceIdsOBJ = {
      invoiceListArray: [],
      invoiceListSTR: "",
    };
    if (debts && debts.length) {
      debts.map((ele, index) => {
        if (ele.selectionCheck) {
          invoiceIdsOBJ.invoiceListArray.push(
            parseInt(ele.debt_invoice ? ele.debt_invoice.id : ele.id)
          );
          invoiceIdsOBJ.invoiceListSTR =
            invoiceIdsOBJ.invoiceListSTR !== ""
              ? invoiceIdsOBJ.invoiceListSTR + ", " + ele.invoice_number
              : ele.invoice_number;
        }
      });
    }
    return invoiceIdsOBJ;
  };

  onCheckboxChange = (e, item, index) => {
    const { debts } = this.state;
    let tempDebts = [...debts];
    let { invoiceLength } = this.state;

    if (e.target.checked) {
      invoiceLength = invoiceLength + 1;
    } else {
      invoiceLength = invoiceLength - 1;
    }
    tempDebts[index].selectionCheck = e.target.checked;
    this.setState({ debts: tempDebts, invoiceLength }, () => {
      this.checkSendEmailValid();
    });
  };

  onCheckboxChangePaymentsPlan = (e, item, index) => {
    const { paymentPlansList } = this.state;
    let tempDebts = [...paymentPlansList];
    let { invoiceLength, invoiceIdArray } = this.state;

    if (e.target.checked) {
      invoiceLength = invoiceLength + 1;
    } else {
      invoiceLength = invoiceLength - 1;
    }
    tempDebts[index].selectionCheck = e.target.checked;
    this.setState({ paymentPlansList: tempDebts, invoiceLength }, () => {
      this.checkInvoiceForPaymentPlanValid();
    });
  };
  onCheckboxChangeReceivePaymentList = (e, item, index) => {
    const { receivePaymentList } = this.state;
    let tempDebts = [...receivePaymentList];
    let { invoiceLength, invoiceIdArray } = this.state;

    if (e.target.checked) {
      invoiceLength = invoiceLength + 1;
    } else {
      invoiceLength = invoiceLength - 1;
    }
    tempDebts[index].planCheck = e.target.checked;
    this.setState({ receivePaymentList: tempDebts, invoiceLength }, () => {
      this.checkPlanForRecievePayment(tempDebts);
    });
  };
  //returns formatted table data
  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Inv. #",
        style: {
          width: "12%",
          margin: 0,
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Status",
        style: { width: "12%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Inv. Date",
        style: { width: "12%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Due Date",
        style: { width: "12%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Inv. Total",
        style: { width: "12%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Amt Paid",
        style: { width: "12%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Balance",
        style: { width: "12%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Action",
        style: {
          width: "12%",
          marginLeft: "1%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "expander",
        style: { width: "4%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((debt, index) => {
      const total_amount =
        parseFloat(debt.debt_invoice.total_amt) + parseFloat(debt.tax_amount);
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: <>{debt.debt_invoice.invoice_number}</>,
            style: {
              width: "12%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Status",
            title: (
              <div style={{ display: "flex", alignItems: "center" }}>
                {debt.has_active_payment_plan && (
                  <img
                    src={DollerIcon}
                    width={"20px"}
                    height={"20px"}
                    title='In Payment Plan'
                  />
                )}
                {debt.debt_invoice.is_eligible_for_placement &&
                  !debt.debt_invoice.is_placed_collections && (
                    <img
                      src={PlacementEligibleIcon}
                      width={"30px"}
                      height={"30px"}
                      title='Eligible For Placement'
                    />
                  )}
                {debt.debt_invoice.is_eligible_for_placement &&
                  debt.debt_invoice.is_placed_collections && (
                    <img
                      src={LockIcon}
                      width={"30px"}
                      height={"30px"}
                      title='In Collection'
                    />
                  )}
              </div>
            ),
            style: { width: "12%", display: "flex", alignItems: "center" },
          },
          {
            key: "Inv. Date",
            title: this.renderLatestPaymentDate(debt.debt_invoice.txn_date),
            style: { width: "12%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Due Date",
            title: this.renderLatestPaymentDate(debt.debt_invoice.due_date),
            style: { width: "12%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Inv. Total",
            title: getDollarAmount(debt.invoice_total_amount),
            style: { width: "12%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Amt Paid",
            title: getDollarAmount(debt.invoice_amount_paid),
            style: { width: "12%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Balance",
            title: getDollarAmount(debt.debt_invoice.balance),
            style: { width: "12%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Action",
            title: (
              <>
                {debt.debt_invoice.balance != "0.0" ? (
                  <button
                    title='Accept Payment'
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      marginRight: 10,
                      marginLeft: "-0.4rem",
                    }}
                    onClick={() => this.openAcceptPaymentModal(debt)}
                  >
                    <img src={dollorIcon} width='18' height='18' />
                  </button>
                ) : (
                  <button
                    title=''
                    style={{
                      background: "transparent",
                      marginRight: 10,
                    }}
                  >
                    <div style={StyleSheet.btnDiv}></div>
                  </button>
                )}
                <button
                  title='Edit'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => this.goToEditInvoice(debt.debt_invoice.id)}
                >
                  <img src={editImg} width='16' height='16' />
                </button>
                {debt.mg_document ? (
                  <button
                    title='Download'
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() =>
                      this.downloadInvoice(
                        debt.mg_document.document_id,
                        debt.mg_document.name
                      )
                    }
                  >
                    <img src={downloadImg} width='16' height='16' />
                  </button>
                ) : null}
                <button
                  title='Delete'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => this.handleDeleteInvoice(debt.debt_invoice.id)}
                >
                  <img src={deleteIcon} width='28' height='28' />
                </button>
              </>
            ),
            style: { width: "12%", display: "flex", justifyContent: "center" },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            style: { width: "4%", display: "flex", justifyContent: "center" },
          },
        ],
        status: this.getAccountStatus(debt.debt_invoice),
        taskStatus:
          debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
            ? true
            : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

  handleDeleteInvoice = (invoiceID) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to delete invoice?",
      confirmClick: () => this.deleteInvoice(invoiceID),
      closeOnClickOutside: true,
    });
  };

  deleteInvoice = (invoiceId) => {
    let { history } = this.props;
    API.deleteInvoice(this.props.user, invoiceId).then((data) => {
      if (data) {
        if (data.status_code === 200) {
          setTimeout(() => {
            this.handleSearchFilter();
          }, 2000);
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  //returns formatted table data with checkboxes
  getObjectListDataWithCheckbox = (data) => {
    let headings = [],
      columns = [];

    headings = [
      // {
      //   name: "",
      //   style: { width: "5%", display: "flex", justifyContent: "flex-start" },
      // },
      {
        name: "Inv. #",
        style: { width: "20%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Inv. Date",
        style: { width: "16%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Due Date",
        style: { width: "16%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Total",
        style: { width: "16%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Amt Paid",
        style: { width: "16%", display: "flex", justifyContent: "center" },
      },
      // "Discount",
      {
        name: "Balance",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      { name: "expander", style: { width: 0 } },
    ];

    columns = data.map((debt, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          // {
          //   key: "",
          //   title: (
          //     <div className='email-value-wrapper'>
          //       <input
          //         className='email-checkbox'
          //         type='checkbox'
          //         id={`tax00${index}`}
          //         name={"tax"}
          //         value={"tax"}
          //         checked={debt.selectionCheck}
          //         onChange={(e) => this.onCheckboxChange(e, debt, index)}
          //       />
          //     </div>
          //   ),
          //   style: {
          //     width: "4%",
          //     display: "flex",
          //     justifyContent: "flex-start",
          //   },
          // },
          {
            key: "Inv. #",
            title: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className='email-value-wrapper'>
                  <input
                    className='email-checkbox'
                    type='checkbox'
                    id={`tax00${index}`}
                    name={"tax"}
                    value={"tax"}
                    checked={debt.selectionCheck}
                    onChange={(e) => this.onCheckboxChange(e, debt, index)}
                  />
                  <div style={{ whiteSpace: "nowrap" }}>
                    {debt.debt_invoice
                      ? debt.debt_invoice.invoice_number
                      : debt.invoice_number}
                  </div>
                </div>
              </div>
            ),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Inv. Date",
            title: this.renderLatestPaymentDate(
              debt.debt_invoice ? debt.debt_invoice.txn_date : debt.txn_date
            ),
            style: { width: "16%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Due Date",
            title: this.renderLatestPaymentDate(
              debt.debt_invoice ? debt.debt_invoice.due_date : debt.due_date
            ),
            style: { width: "16%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Inv. Total",
            title: getDollarAmount(
              debt.debt_invoice ? debt.debt_invoice.total_amt : debt.total_amt
            ),
            style: { width: "16%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Amt Paid",
            title: getDollarAmount(
              this.getPaidAmount(
                debt.debt_invoice ? debt.debt_invoice : debt,
                debt.tax_amount !== null &&
                  debt.tax_amount !== "" &&
                  debt.tax_amount !== undefined
                  ? debt.tax_amount
                  : 0,
                debt.slow_payer_amount !== null &&
                  debt.slow_payer_amount !== "" &&
                  debt.slow_payer_amount !== undefined
                  ? debt.slow_payer_amount
                  : 0
              )
            ),
            style: { width: "16%", display: "flex", justifyContent: "center" },
          },
          // {
          //   key: "Discount",
          //   title: getDollarAmount(
          //     debt.debt_invoice ? debt.debt_invoice.adjustment : debt.adjustment
          //   ),
          // },
          {
            key: "Balance",
            title: getDollarAmount(
              debt.debt_invoice ? debt.debt_invoice.balance : debt.balance
            ),
            style: { width: "16%", display: "flex", justifyContent: "center" },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: this.getAccountStatus(
          debt.debt_invoice ? debt.debt_invoice : debt
        ),
        // status: this.getAccountStatus(debt.debt_invoice),
        taskStatus: parseFloat(debt.balance) <= 0 ? true : false,
        rowData: debt,
      };
    });
    return {
      headings,
      columns,
    };
  };

  receivePayment = (planId) => {
    this.setState({
      isPaymentPlansSelected: false,
      isReceivePaymentSelected: true,
    });
    this.setState((state) => ({
      ...state,
      paymentPlanId: planId,
    }));
    this.getPlanDetails(planId);
  };

  getPlanDetails = (planId) => {
    let { user } = this.props;
    this.setState({ isLoading: true });
    API.getPaymentPlanLById(user, planId).then((data) => {
      if ((data != undefined || data != null) && data.message === "success") {
        if (data.data) {
          this.setState({
            receivePaymentList: data.data.payment_plans_payments,
            paymentPlansDetails: data.data,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  deletePaymentPlan = (id) => {
    API.clientDeletePaymentPlan(this.props.user, id).then((data) => {
      if ((data != undefined || data != null) && data.status_code === 200) {
        this.getPaymentPlanList(this.props.match.params.id);
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
      }
    });
  };

  handleDeletePaymentPlan = (id, number) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: `Are you sure you want to delete payment plan ${number}?`,
      confirmClick: () => this.deletePaymentPlan(id),
      closeOnClickOutside: true,
    });
  };

  //returns formatted table data
  getPaymentPlanListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Payment Plan #",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Status",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Plan Date",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "# of Invoices",
        style: {
          minWidth: "7%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Plan Amount",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "# of Months/ Weeks",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Monthly Payment Amt",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Current Balance",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Mark as Accepted",
        style: {
          minWidth: "10%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Action",
        style: {
          minWidth: "8%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "expander",
        style: {
          minWidth: "5%",
          display: "flex",
          justifyContent: "center",
        },
      },
    ];

    columns = data.map((debt, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Payment Plan #",
            title: <>{debt.payment_plan_number}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Status",
            title: <>{displayPlanStatus(debt.status)}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Plan Date",
            title: this.renderLatestPaymentDate(debt.created_at),
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "# of Invoices",
            title: <>{debt.get_payment_plan_invoices.length}</>,
            style: {
              minWidth: "7%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Plan Amount",
            title:
              "$ " +
              getCalculatedPlanAmount(
                debt.total_in_dollars_as_currency,
                debt.adjustment_amount,
                debt.is_credit_memo_applied,
                debt.status,
                "Plan_amt"
              ),
            // style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "# of Months/ Weeks",
            title: <>{debt.number_of_payments}</>,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Monthly Payment Amt",
            title: "$ " + debt.monthly_payment_amount_as_currency,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Current Balance",
            title: "$ " + debt.balance_as_currency,
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Mark as Accepted",
            title: (debt.status === "offer" ||
              debt.status === "offer_sent") && (
              <button
                style={{
                  padding: "5px",
                  borderRadius: "7px",
                  backgroundColor: "#0ab894",
                  color: "#ffffff",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  fontWeight: 400,
                  width: "75px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.markPaymentPlanAccepted(debt.id);
                }}
              >
                Mark as Accepted
              </button>
            ),
            style: {
              minWidth: "10%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Action",
            title: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button
                  title='Accept Payment'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                  onClick={() => {
                    if (
                      debt.status === "offer" ||
                      debt.status === "offer_sent"
                    ) {
                      this.props.updateModalState(true, "ERROR", {
                        message: "This Payment plan is not accepted yet.",
                        title: "Error!",
                      });
                    } else if (debt.status === "closed") {
                      this.props.updateModalState(true, "SUCCESS", {
                        message: "This Payment plan is closed.",
                        title: "Info!",
                      });
                    } else {
                      this.receivePayment(debt.id);
                    }
                  }}
                >
                  <img src={dollorIcon} width='16' height='16' />
                </button>
                <button
                  title='Edit'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                  // onClick={() => this.goToEditInvoice(debt.debt_invoice.id)}
                >
                  <img src={editImg} width='16' height='16' />
                </button>
                <button
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    marginRight: 5,
                  }}
                  title='Communication'
                  onClick={() =>
                    this.renderPaymentPlanCommModal(
                      this.state.user.user_id,
                      debt
                    )
                  }
                >
                  <img src={Emailsvg} width='16' height='16' />
                </button>
                {parseFloat(debt.balance_as_currency) > 0 && (
                  <button
                    title='Delete'
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      marginRight: 5,
                    }}
                    onClick={() =>
                      this.handleDeletePaymentPlan(
                        debt.id,
                        debt.payment_plan_number
                      )
                    }
                  >
                    <img src={deleteImg} width='28' height='28' />
                  </button>
                )}
              </div>
            ),
            style: {
              minWidth: "8%",
              display: "flex",
              justifyContent: "flex-start",
              marginRight: "0.5rem",
            },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            style: {
              minWidth: "5%",
              display: "flex",
              justifyContent: "flex-end",
            },
          },
        ],
        status: null,
        // taskStatus:
        //   debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
        //     ? true
        //     : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

  markPaymentPlanAccepted = (id) => {
    this.setState((state) => ({
      ...state,
      isTableLoading: true,
    }));
    API.markPaymentPlanAccepted(this.props.user, id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
          title: "SUCCESS",
        });
      } else if (
        (data && data.status_code == 400) ||
        data.status_code == 500 ||
        data.status_code == 404
      ) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
          title: "Error!",
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: "Access denied.",
          title: "Error!",
        });
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
      this.handleBacktoPaymentPlansList();
    });
  };
  //returns formatted table data with checkboxes
  getObjectPaymentPlanInvoicesListDataWithCheckbox = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Inv. #",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Inv. Date",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Due Date",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Inv. Total",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Amt Paid",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Balance",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "expander",
        style: {
          width: 0,
        },
      },
    ];

    columns = data.map((debt, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div className='email-value-wrapper'>
                  <input
                    className='email-checkbox'
                    type='checkbox'
                    id={`tax00${index}`}
                    name={"tax"}
                    value={"tax"}
                    checked={debt.selectionCheck}
                    onChange={(e) =>
                      this.onCheckboxChangePaymentsPlan(e, debt, index)
                    }
                  />
                </div>
                <div>
                  {debt.debt_invoice
                    ? debt.debt_invoice.invoice_number
                    : debt.invoice_number}
                </div>
              </div>
            ),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Inv. Date",
            title: this.renderLatestPaymentDate(
              debt.debt_invoice ? debt.debt_invoice.txn_date : debt.txn_date
            ),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Due Date",
            title: this.renderLatestPaymentDate(
              debt.debt_invoice ? debt.debt_invoice.due_date : debt.due_date
            ),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Inv. Total",
            title: getDollarAmount(debt.total_amount && debt.total_amount),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Amt Paid",
            title: getDollarAmount(
              // this.getPaidAmount(
              debt.invoice_paid_amount
              // debt.debt_invoice ? debt.debt_invoice : debt,
              // debt.tax_amount !== null &&
              //   debt.tax_amount !== "" &&
              //   debt.tax_amount !== undefined
              //   ? debt.tax_amount
              //   : 0,
              // debt.slow_payer_amount !== null &&
              //   debt.slow_payer_amount !== "" &&
              //   debt.slow_payer_amount !== undefined
              //   ? debt.slow_payer_amount
              //   : 0
              // )
            ),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Balance",
            title: getDollarAmount(
              debt.debt_invoice ? debt.debt_invoice.balance : debt.balance
            ),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
        ],
        status: this.getAccountStatus(
          debt.debt_invoice ? debt.debt_invoice : debt
        ),
        // status: this.getAccountStatus(debt.debt_invoice),
        taskStatus: parseFloat(debt.balance) <= 0 ? true : false,
        rowData: debt,
      };
    });
    return {
      headings,
      columns,
    };
  };
  getObjectReceivePaymentListDataWithCheckbox = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Payment #",
        style: { width: "10%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Due Date",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Paid Date",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Payment Amt.",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Rem. Balance",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Status",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((data, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          // {
          //   key: "",
          //   title: (
          //     <div className="email-value-wrapper">
          //       {parseFloat(data.balance.replace(",", "")) <= 0 ? (
          //         <input
          //           className="email-checkbox"
          //           type="checkbox"
          //           checked={true}
          //           disabled={true}
          //         />
          //       ) : (
          //         <input
          //           className="email-checkbox"
          //           type="checkbox"
          //           id={`tax00${index}`}
          //           name={"tax"}
          //           value={"tax"}
          //           checked={data.status === "paid" ? true : data.planCheck}
          //           disabled={data.status === "paid" ? true : false}
          //           onChange={(e) =>
          //             this.onCheckboxChangeReceivePaymentList(e, data, index)
          //           }
          //         />
          //       )}
          //     </div>
          //   ),
          // },
          {
            key: "Payment #",
            title: index + 1,
            style: {
              width: "10%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Due Date",
            title:
              data.due_date === null
                ? "--/--/----"
                : this.renderLatestPaymentDate(data.due_date),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Paid Date",
            title:
              data.paid_date === null
                ? "--/--/---"
                : this.renderLatestPaymentDate(data.paid_date),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Payment Amt.",
            title: getDollarAmount(data.payment_amount),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Rem. Balance",
            title: getDollarAmount(data.balance),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Status",
            title: displayPlanStatus(data.status),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
        ],
        rowData: data,
      };
    });
    return {
      headings,
      columns,
    };
  };
  //returns formatted credit memo table data
  getMemoObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = ["Credit No.", "Date", "Amount", "Status", "Action", "expander"];

    columns = data.map((debt) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Credit No.",
            title: debt.credit_memo_number,
          },
          {
            key: "Date",
            title: this.renderLatestPaymentDate(debt.date),
          },
          {
            key: "Amount",
            title: getDollarAmount(debt.total_amount),
          },
          {
            key: "status",
            title: this.getCreditMemoStatus(debt),
          },
          {
            key: "Action",
            title:
              this.getCreditMemoStatus(debt) === "Unapplied" ? (
                <button
                  title='Delete'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                  onClick={() => this.deleteCreditMemo(debt.id)}
                >
                  <img src={deleteImg} width='26' height='26' />
                </button>
              ) : (
                "-"
              ),
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        // status: this.getAccountStatus(debt.debt_invoice),
        // taskStatus:
        //   debt.debt_invoice.balance && debt.debt_invoice.balance <= 0
        //     ? true
        //     : false,
        rowData: debt,
      };
    });

    return {
      headings,
      columns,
    };
  };

  //returns formatted credit memo table data
  getStatementObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = ["Month", "Statement Date", "From", "To", "Action"];

    columns = data.map((statement) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Month",
            title: statement.month,
          },
          {
            key: "Statement Date",
            title: statement.statement_date
              ? this.renderLatestPaymentDate(statement.statement_date)
              : "",
          },
          {
            key: "From",
            title: statement.start_date
              ? this.renderLatestPaymentDate(statement.start_date)
              : "",
          },
          {
            key: "To",
            title: statement.end_date
              ? this.renderLatestPaymentDate(statement.end_date)
              : "",
          },
          {
            key: "Action",
            title: (
              <div className='Action-buttons'>
                <button
                  title='Letter'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                  onClick={() => this.sendStatement(statement)}
                >
                  <img src={Statement} width='16' height='16' />
                </button>
                <button
                  title='View'
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    marginRight: 15,
                  }}
                  onClick={() => this.viewStatement(statement.id)}
                >
                  <img src={viewIcon} width='16' height='16' />
                </button>
                <a
                  className='make-payment-button'
                  style={{ cursor: "pointer", marginRight: 15 }}
                  title='Download'
                  onClick={() =>
                    this.downloadStatement(
                      statement.id,
                      statement.month,
                      statement.statement_date
                    )
                  }
                >
                  <img src={downloadImg} width='16' height='16' />
                </a>

                <button
                  style={{ cursor: "pointer", marginRight: 15 }}
                  title='Email'
                  onClick={() => {
                    this.viewEmail(statement);
                  }}
                >
                  <img src={Emailsvg} width='16' height='16' />
                </button>
              </div>
            ),
          },
        ],
        rowData: statement,
      };
    });

    return {
      headings,
      columns,
    };
  };

  viewStatement = (statement_id) => {
    const { user } = this.props;
    var me = this;
    API.downloadStatement(user, statement_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let props = {
            image: data.data,
            user: me.props.user,
          };
          me.props.updateModalState(true, "SHOW_IMAGE", props);
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };
  viewEmail = (users) => {
    const { user } = this.props;
    var me = this;
    let payload = {
      is_email: true,
      user_id: users.user_id,
      statement_id: users.id,
    };
    API.emailLetterStatement(users, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
            title: "Info",
          });
        }
      } else if (
        (data && data.status_code == 400) ||
        data.status_code == 500 ||
        data.status_code == 404
      ) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
          title: "Error!",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  sendStatement = (users) => {
    const { user } = this.props;
    var me = this;
    let payload = {
      is_email: false,
      user_id: users.user_id,
      statement_id: users.id,
    };
    API.emailLetterStatement(users, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
          title: "Info",
        });
      } else if (
        (data && data.status_code == 400) ||
        data.status_code == 500 ||
        data.status_code == 404
      ) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
          title: "Error!",
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  downloadStatement = (statement_id, statement_month, statement_date) => {
    const { user } = this.props;
    let temp_date = statement_date.split("-")[2];
    API.downloadStatement(user, statement_id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = "Statement_" + temp_date + "_" + statement_month;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  emptyView = (activities) => {
    return (
      <div className='empty-activity' style={{ marginBottom: "1em" }}>
        <p>There are currently no {activities} to review.</p>
      </div>
    );
  };

  getPaymentObjectList = (data) => {
    let headings = [],
      columns = [];
    headings = ["Inv #", "Pymt Method", "Date/Time", "Amount", "Status"];

    columns = data.map((row) => {
      let statusPillClass = null;
      if (
        row.transaction_status === "REFUNDED" ||
        row.transaction_status === "VOIDED" ||
        row.transaction_status === "UNAPPLIED"
      ) {
        statusPillClass = "refund-message rm-scrll-w";
      } else if (
        row.transaction_status === "FAILED" ||
        row.transaction_status === "CANCELLED"
      ) {
        statusPillClass = "fail-message rm-scrll-w";
      } else if (
        row.transaction_status === "Complete" ||
        row.transaction_status === "SUCCESS"
      ) {
        statusPillClass = "success-message rm-scrll-w";
      }

      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv #",
            title: row.invoice_number ? row.invoice_number : "--",
          },
          {
            key: "Pymt Method",
            title:
              row.payment_type && row.payment_type === "CreditCard"
                ? "Credit Card"
                : row.payment_type === "Cheque"
                ? "Check"
                : row.payment_type,
          },
          {
            key: "Date/Time",
            title: row.date ? (
              <div>
                {formatDate(row.date)[0]}
                <br />
                {`${formatDate(row.date)[1]} 
              ${formatDate(row.date)[2]}`}
              </div>
            ) : row.created_at ? (
              <div>
                {formatDate(row.created_at)[0]}
                <br />
                {`${formatDate(row.created_at)[1]} 
              ${formatDate(row.created_at)[2]}`}
              </div>
            ) : (
              "n/a"
            ),
          },
          {
            key: "Amount",
            title: getDollarAmount(
              row.is_cancelled ? row.cancel_amount : row.amount
            ),
          },
          {
            key: "Status",
            title: `${
              row.transaction_status
                ? String(row.transaction_status).toLocaleUpperCase()
                : "--"
            }`,
            optionalClasses: statusPillClass,
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  getEmailTemplates = () => {
    API.getAccountLevelEmailTemplates(this.props.user).then((res) => {
      // console.log(res.data);
    });
  };

  handleCommunicationFilterChange = (type) => {
    this.setState({
      selectedTaskType: type,
    });
    if (type !== "Invoices") {
      this.setState({
        isTableLoading: true,
      });
      API.getAccountCommunication(
        this.props.user,
        this.props.match.params.id,
        type
      )
        .then((res) => {
          this.setState((prevState) => ({
            communicationData: {
              ...prevState.communicationData,
              [type]: res.data,
              isTableLoading: false,
            },
          }));
        })
        .catch(() => {})
        .finally(() => {
          this.setState({
            isTableLoading: false,
          });
        });
    }
  };

  handleNewLetterClick = () => {
    const { debts, consumer } = this.state;
    this.getCreditMemoData(consumer.id);
    if (debts.length > 0) {
      this.renderNewLetterModal();
    } else {
      this.props.updateModalState(true, "ERROR", {
        message:
          "There are no open invoices available for the respective account to send a statement at this time.",
        title: "Error!",
      });
    }
  };

  renderNewCommunicationButton = (communicationType) => {
    switch (communicationType) {
      case "Invoices":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.handleAddInvoiceClick()}
          >
            New Invoice
          </button>
        );
      case "Emails":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.toggleEmailDropdown()}
          >
            New Email
          </button>
        );
      case "Letters":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.handleNewLetterClick()}
          >
            New Letter
          </button>
        );
      case "Notes":
        return (
          <button
            style={{ padding: "1em 1em" }}
            onClick={() => this.addNewNote()}
          >
            New Note
          </button>
        );
      default:
        return this.renderEmpty("No Communications available");
    }
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  handleEmailRowClick = (mailInfo) => {
    let props = {
      mailInfo: mailInfo.row.mandrill_json,
    };
    this.props.updateModalState(true, "SHOW_MAIL_DESC", props);
  };

  renderEmailCommunication = (emailData) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return emailData && emailData.length ? (
      <div>
        {isSmallDevice ? (
          <div className='big-device-grad invoice-line'>
            <div className='scrollbar in-sc'>
              <ClientsCardView
                data={this.setEmailData(emailData)}
                rowActionClick={(event) => this.handleEmailRowClick(event)}
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "1em" }}>
            <HistoryObjectList
              data={this.setEmailData(emailData)}
              rowActionClick={(event) => this.handleEmailRowClick(event)}
              hideForMobile={true}
              optionalClasses='scrollbar'
            />
          </div>
        )}
      </div>
    ) : (
      this.renderEmpty(`There are currently no emails to review.`)
    );
  };

  renderNotesCommunication = (notes) => {
    return notes && notes.length ? (
      <div>
        <ClientsCardView data={this.setNotesData(notes)} />
        <InvoiceObjectList
          data={this.setNotesData(notes)}
          hideForMobile={true}
          optionalTableClasses='in-task'
        />
      </div>
    ) : (
      this.renderEmpty(`There are currently no notes to review.`)
    );
  };

  renderLetterCommunication = (letters) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return letters && letters.length ? (
      <div>
        {isSmallDevice ? (
          <div className='big-device-grad invoice-line'>
            <div className='scrollbar in-sc'>
              <ClientsCardView
                data={this.setLetterData(letters)}
                rowActionClick={(event) => this.handleLetterRowClick(event)}
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "1em" }}>
            <HistoryObjectList
              data={this.setLetterData(letters)}
              rowActionClick={(event) => this.handleLetterRowClick(event)}
              hideForMobile={true}
              optionalClasses='scrollbar'
            />
          </div>
        )}
      </div>
    ) : (
      this.renderEmpty(`There are currently no letters to review.`)
    );
  };

  setEmailData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Date & Time",
      "Receiver Email",
      "Template Name",
      "Status",
      "Open Count",
      "Click Count",
      "expander",
    ];

    columns = data.map((row) => {
      let statusPillClass = null;
      if (row.status == "sent") {
        statusPillClass = "success-message";
      } else {
        statusPillClass = "fail-message";
      }

      return {
        primaryColumn: {
          key: "Date & Time",
          title: row.created_at
            ? moment.utc(row.created_at).format("MM/DD/YYYY")
            : "n/a",
          secondaryInfo: row.created_at
            ? moment.utc(row.created_at).format("HH:mm:ss")
            : "n/a",
        },
        primaryRow: {
          key: "Date & Time",
          title: row.date
            ? moment(row.date * 1000).format("MM/DD/YYYY")
            : "n/a",
          secondaryInfo: row.date
            ? moment(row.date * 1000).format("HH:mm:ss")
            : "n/a",
        },
        secondaryColumns: [
          {
            key: "Receiver Email",
            title: row.receiver_email ? row.receiver_email : "n/a",
          },
          {
            key: "Template Name",
            title: row.template_name ? row.template_name : "n/a",
          },
          {
            key: "Status",
            title: row.status ? row.status.toUpperCase() : row.status,
            optionalClasses: `${statusPillClass ? statusPillClass : ""}`,
          },
          {
            key: "Open Count",
            title: row.open_count ? row.open_count : "0",
          },
          {
            key: "Click Count",
            title: row.click_count ? row.click_count : "0",
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            icon: "eye",
          },
        ],
        rowType: "view",
        showDetails: true,
        row: row,
      };
    });
    return {
      headings,
      columns,
    };
  };

  handleLetterRowClick(event) {
    let props = {
      image: event.imageURL,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  }
  setLetterData = (data) => {
    let headings = [],
      columns = [];
    headings = ["Send Date", "Ex.Delivery Date", "expander"];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Send Date",
            title: row.send_date
              ? this.renderLatestPaymentDate(row.send_date)
              : "n/a",
          },
          {
            key: "Ex.Delivery Date",
            title: row.expected_delivery_date
              ? this.renderLatestPaymentDate(row.expected_delivery_date)
              : "n/a",
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            icon: "eye",
          },
        ],
        imageURL: `${row.thumbnail ? row.thumbnail : ""}`,
      };
    });
    return {
      headings,
      columns,
    };
  };
  setNotesData = (data) => {
    const headings = [{ name: "Name" }, { name: "Date" }, { name: "Note" }];

    const columns = data.map((row) => ({
      emptyPrimary: true,
      secondaryColumns: [
        {
          key: "Name",
          title: row.created_by_name ? row.created_by_name : "",
        },
        {
          key: "Date",
          title: this.getDate(row.created_at),
        },
        {
          key: "Note",
          title: row.note,
        },
      ],
    }));

    return { headings, columns };
  };

  handleAddAccountLevelNote = (noteData) => {
    let payload = { note: noteData };
    API.addAccountNote(
      this.props.user,
      payload,
      this.props.match.params.id
    ).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.handleCommunicationFilterChange(this.state.selectedTaskType);
      } else {
        //close modal
        this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  addNewNote = () => {
    let optionalProps = {
      action: "AddTaskNote",
      handleAddTaskNote: this.handleAddAccountLevelNote,
    };
    this.props.updateModalState(true, "ACCEPT_REJECT_NOTES", optionalProps);
  };

  renderCommunicationData = (communicationType) => {
    const { communicationData } = this.state;
    const { Emails, Letters, Notes } = communicationData;

    switch (communicationType) {
      case "Emails":
        return this.renderEmailCommunication(Emails);
      case "Letters":
        return this.renderLetterCommunication(Letters);
      case "Notes":
        return this.renderNotesCommunication(Notes);
      default:
        return this.renderEmpty("No Communications available");
    }
  };
  renderEmailOptions = (active) => {
    const { user } = this.state;
    let consumerId = user.user_id ? user.user_id : "";
    return (
      active && (
        <div style={{ position: "relative", top: "23%", right: "12%" }}>
          <ul
            className={`dropdown-menu${active ? " active" : ""} mlleft0`}
            // onClick={this.toggleDropdown}
            onClick={() => {}}
            ref={this.emailOptionRef}
          >
            <MenuItem handleClick={() => this.renderSendCustomEmailModal()}>
              Custom Email
            </MenuItem>
            <MenuItem
              handleClick={() => this.renderSendNewMailModal(consumerId, true)}
            >
              Email Only
            </MenuItem>
            <MenuItem handleClick={this.openInvoiceView}>
              Send Multiple <br /> Invoices
            </MenuItem>
          </ul>
        </div>
      )
    );
  };

  toggleEmailDropdown = () => {
    this.setState((prevState) => ({
      emailDropdownActive: !prevState.emailDropdownActive,
    }));
  };

  //show table and card data
  renderTable = (tableData) => {
    const {
      isOpenInvoiceView,
      isPlaceInCollection,
      isReceivePaymentForInvoicesSelected,
      selectedTaskType,
      activeTab,
    } = this.state;
    let emptyStateMessage = "No Invoices Available";
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className='comm-notes-btn-container' style={{ margin: 0 }}>
            <button
              title={"Invoices"}
              className={`task-filter-btns invoices comm-btns ${
                selectedTaskType === "Invoices" ? "active" : ""
              }`}
              style={{ margin: 0 }}
              onClick={() => this.handleCommunicationFilterChange("Invoices")}
            />
            <button
              title={"Notes"}
              className={`task-filter-btns note comm-btns ${
                selectedTaskType === "Notes" ? "active" : ""
              }`}
              onClick={() => this.handleCommunicationFilterChange("Notes")}
            />
            <button
              title={"Emails"}
              className={`task-filter-btns email comm-btns ${
                selectedTaskType === "Emails" ? " active" : ""
              }`}
              onClick={() => this.handleCommunicationFilterChange("Emails")}
            />
            <button
              title={"Letters"}
              className={`task-filter-btns comm-btns letter ${
                selectedTaskType === "Letters" ? " active" : ""
              }`}
              onClick={() => this.handleCommunicationFilterChange("Letters")}
            />
          </div>
          <div className='in-new-btn'>
            <div style={{ marginTop: "2rem", marginRight: "3rem" }}>
              {this.renderEmailOptions(this.state.emailDropdownActive)}
            </div>
            {this.renderNewCommunicationButton(selectedTaskType)}
          </div>
        </div>
        {selectedTaskType === "Invoices" ? (
          tableData.length > 0 ? (
            !this.state.isTableLoading ? (
              <div>
                <div>
                  <ClientsCardView
                    data={
                      isOpenInvoiceView
                        ? this.getObjectListDataWithCheckbox(tableData)
                        : isPlaceInCollection
                        ? this.getObjectListDataWithCheckbox(tableData)
                        : isReceivePaymentForInvoicesSelected
                        ? this.getObjectListDataWithCheckbox(tableData)
                        : this.getObjectListData(tableData)
                    }
                    isStatus={true}
                    rowActionClick={(event) => this.rowExpanderClick(event)}
                  />
                </div>
                <div>
                  <InvoiceObjectList
                    data={
                      isOpenInvoiceView
                        ? this.getObjectListDataWithCheckbox(tableData)
                        : isPlaceInCollection
                        ? this.getObjectListDataWithCheckbox(tableData)
                        : isReceivePaymentForInvoicesSelected
                        ? this.getObjectListDataWithCheckbox(tableData)
                        : this.getObjectListData(tableData)
                    }
                    rowActionClick={(event) => this.rowExpanderClick(event)}
                    isStatus={true}
                    hideForMobile={true}
                    isInvoiceListData={true}
                    optionalClasses='scrollbar in-sc'
                  />
                </div>
              </div>
            ) : (
              <div
                className='admin-admins-empty-state'
                style={{
                  padding: "2rem 2rem 1rem 2rem",
                  backgroundColor: "#FFF",
                }}
              >
                {this.state.isTableLoading && <AppCanvasLoader />}
              </div>
            )
          ) : (
            <div
              // className='admin-admins-empty-state'
              style={{
                // padding: "2rem 2rem 1rem 2rem",
                backgroundColor: "#FFF",
              }}
            >
              {this.state.isTableLoading ? (
                <AppCanvasLoader />
              ) : (
                this.renderEmpty(emptyStateMessage)
              )}
            </div>
          )
        ) : (
          activeTab === 1 && this.renderCommunicationData(selectedTaskType)
        )}
      </div>
    );
  };

  renderCreditMemoTable = (tableData) => {
    let emptyStateMessage = "No Credit Memos Available";

    return tableData.length > 0 ? (
      <div>
        <div>
          <ClientsCardView
            data={this.getMemoObjectListData(tableData)}
            isStatus={false}
            rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
          />
        </div>
        <HistoryObjectList
          data={this.getMemoObjectListData(tableData)}
          rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
          isStatus={false}
          hideForMobile={true}
          optionalClasses='scrollbar in-sc'
        />
      </div>
    ) : (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        {this.state.isTableLoading ? (
          <AppCanvasLoader />
        ) : (
          <p>{emptyStateMessage}</p>
        )}
      </div>
    );
  };

  renderPaymentPlansTables = (tableData) => {
    const {
      isPaymentPlansSelected,
      isReceivePaymentSelected,
      receivePaymentList,
    } = this.state;

    let emptyStateMessage = "No Payment Plans Available";
    return tableData.length > 0 ? (
      !this.state.isTableLoading ? (
        <div>
          <div>
            <ClientsCardView
              data={
                isPaymentPlansSelected
                  ? this.getObjectPaymentPlanInvoicesListDataWithCheckbox(
                      tableData
                    )
                  : isReceivePaymentSelected
                  ? this.getObjectReceivePaymentListDataWithCheckbox(
                      receivePaymentList
                    )
                  : this.getPaymentPlanListData(tableData)
              }
              isStatus={false}
              rowActionClick={(event) => this.rowExpanderForPaymentPlan(event)}
            />
          </div>
          <div>
            <InvoiceObjectList
              data={
                isPaymentPlansSelected
                  ? this.getObjectPaymentPlanInvoicesListDataWithCheckbox(
                      tableData
                    )
                  : isReceivePaymentSelected
                  ? this.getObjectReceivePaymentListDataWithCheckbox(
                      receivePaymentList
                    )
                  : this.getPaymentPlanListData(tableData)
              }
              rowActionClick={(event) => this.rowExpanderForPaymentPlan(event)}
              isPaymentPlansData={true}
              isStatus={false}
              hideForMobile={true}
              optionalClasses='scrollbar in-sc'
            />
          </div>
        </div>
      ) : (
        <div
          className='admin-admins-empty-state'
          style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
        >
          {this.state.isTableLoading ? (
            <AppCanvasLoader />
          ) : (
            <p>{emptyStateMessage}</p>
          )}
        </div>
      )
    ) : (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        {this.state.isTableLoading ? (
          <AppCanvasLoader />
        ) : (
          <p>{emptyStateMessage}</p>
        )}
      </div>
    );
  };

  renderStatementTable = (tableData) => {
    let emptyStateMessage = "No Statement Available";
    return tableData.length > 0 ? (
      <div>
        <div>
          <ClientsCardView
            data={this.getStatementObjectListData(tableData)}
            isStatus={false}
            rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
          />
        </div>
        <HistoryObjectList
          data={this.getStatementObjectListData(tableData)}
          rowActionClick={(event) => this.rowExpanderClickForMemo(event)}
          isStatus={false}
          hideForMobile={true}
          optionalClasses='scrollbar in-sc'
        />
      </div>
    ) : (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        {this.state.isTableLoading ? (
          <AppCanvasLoader />
        ) : (
          <p>{emptyStateMessage}</p>
        )}
      </div>
    );
  };

  getFullName = (firstName, lastName) => {
    let fullName = "n/a";
    if (firstName || lastName) {
      fullName = firstName + " " + lastName;
    }
    return fullName;
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMorePaymentData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        {
          offset: offsetCount,
        },
        () => {
          this.getUnappliedPayments(true);
        }
      );
    }
  };

  renderPaymentsTable = () => {
    const { isLoading, paymentData } = this.state;
    let emptyStateMessage = "No Payments Available";
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return (
      <div>
        {paymentData.length > 0 ? (
          <div>
            <BottomScrollListener
              offset={50}
              onBottom={this.handleContainerOnBottom}
              debounce={0}
            >
              {(scrollRef) => (
                <div>
                  {isSmallDevice ? (
                    <div className='big-device-grad invoice-line'>
                      <div ref={scrollRef} className='scrollbar in-sc'>
                        <ClientsCardView
                          data={this.getPaymentObjectList(paymentData)}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <HistoryObjectList
                      data={this.getPaymentObjectList(paymentData)}
                      rowActionClick={(event) =>
                        this.handleRowActionClick(event)
                      }
                      hideForMobile={true}
                      optionalClasses='scrollbar in-sc'
                      optionalTableClasses='payment-cols-all'
                      scrollRef={scrollRef}
                    />
                  )}
                  {this.state.showLoader && <AppCanvasLoader />}
                </div>
              )}
            </BottomScrollListener>
          </div>
        ) : (
          <div
            className='admin-admins-empty-state'
            style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
          >
            {this.state.isTableLoading ? (
              <AppCanvasLoader />
            ) : (
              <p>{emptyStateMessage}</p>
            )}
          </div>
        )}
      </div>
    );
  };

  //calls when add invoice button click to open add invoice screen
  handleAddInvoiceClick = (e) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/invoices`,
      state: {
        consumer: this.state.consumer,
        backPath: `/client/accounts/${id}`,
      },
    });
  };

  //calls when add invoice button click to open add credit memo screen
  handleAddCreditMemoClick = (e) => {
    let { history } = this.props;
    const id = this.props.match.params.id;
    history.push({
      pathname: `/client/add/creditMemo`,
      state: {
        consumer: this.state.consumer,
        backPath: `/client/accounts/${id}`,
        accountPath: `/client/accounts/${id}`,
      },
    });
  };

  //calls when add invoice button click to open add credit memo screen
  handleGenerateStatementClick = (e) => {
    this.generateStatement();
  };

  handlefilterInput = (input) => {
    const { from, to } = input;

    this.setState(
      (state) => ({
        ...state,
        showLoader: true,
      }),
      () => {
        this.generateStatement(from, to);
      }
    );
  };

  handleDateWiseGenerateStatementClick = (e) => {
    let props = {
      //action: action,
      handlefilterInput: this.handlefilterInput,
      title: "DateWise Generate Statement",
    };
    this.props.updateModalState(true, "GENERATE_STATEMENT", props);
  };

  handleBacktoInvoiceList = () => {
    this.setState(
      {
        isPaymentPlansSelected: false,
        isReceivePaymentSelected: false,
        isOpenInvoiceView: false,
        isPlaceInCollection: false,
        isReceivePaymentForInvoicesSelected: false,
        isTableLoading: true,
        invoiceLength: 0,
        debts: [],
        activeTab: 1,
      },
      () => {
        this.reloadData();
      }
    );
  };
  handleBacktoPaymentPlansList = () => {
    this.setState(
      {
        isPaymentPlansSelected: false,
        isReceivePaymentSelected: false,
        isOpenInvoiceView: false,
        isPlaceInCollection: false,
        isReceivePaymentForInvoicesSelected: false,
        isTableLoading: true,
        invoiceLength: 0,
        debts: [],
        activeTab: 4,
      },
      () => {
        this.getPaymentPlanList(this.props.match.params.id);
      }
    );
  };

  handleAddPaymentPlan = () => {
    this.setState(
      {
        isTableLoading: true,
        isPaymentPlansSelected: true,
        isReceivePaymentSelected: false,
      },
      () => {
        this.getPaymentPlanInvoiceList();
      }
    );
  };
  createPaymentPlan = (consumerId, flag) => {
    let { invoiceLength, checkTotalBalanceAmt, paymentPlansList } = this.state;
    let invoiceBalanceAmt = 0;
    if (paymentPlansList && paymentPlansList.length) {
      paymentPlansList.map((ele, index) => {
        if (ele.selectionCheck) {
          invoiceBalanceAmt =
            parseFloat(invoiceBalanceAmt) + parseFloat(ele.balance);
        }
      });
    }
    let props = {
      invoiceLength: invoiceLength,
      consumerId: consumerId,
      multipleInvoiceData: this.getListForCreatingPaymentPlan(),
      handleBacktoPaymentPlansList: this.handleBacktoPaymentPlansList,
      closeInvoiceListForCollection: this.closeInvoiceListForCollection,
    };
    if (invoiceBalanceAmt < 1) {
      this.props.updateModalState(true, "ERROR", {
        message: `Minimum payment plan amount should be 1$. Please add more invoices to list.`,
        title: "Alert",
      });
    } else {
      this.props.updateModalState(true, "CREATE_PAYMENT_PLAN", props);
    }
  };

  receivePaymentModal = (consumerId, flag) => {
    let { receivePaymentList, paymentPlansDetails, paymentPlanId } = this.state;
    let props = {
      receivePayment: true,
      consumerId: consumerId,
      paymentPlanId: paymentPlanId,
      modal: "paymentPlan",
      reloadData: this.handleBacktoPaymentPlansList,
      receivePaymentList: receivePaymentList,
      paymentPlansDetails: paymentPlansDetails,
    };
    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  renderReceivePaymentsInvoiceModal = (consumerId, flag) => {
    let { debts } = this.state;
    const accountId = window.location.pathname.match(/\d+$/)[0];
    let props = {
      receivePaymentForInvoices: true,
      consumerId: accountId,
      modal: "multipleInvoices",
      debts: debts,
      reloadData: this.closeReceiveInvoicePaymentView,
    };
    this.props.updateModalState(true, "MAKE_PAYMENT", props);
  };

  //get header with add invoice button
  setClose = () => {
    let { closeDropDown } = this.state;
    this.setState({ closeDropDown: false });
  };

  getSectionHeaderUtilitiess = () => {
    const {
      activeTab,
      isOpenInvoiceView,
      isPlaceInCollection,
      closeDropDown,
      selectedTaskType,
    } = this.state;

    return activeTab === 1 && selectedTaskType === "Invoices" ? (
      !isPlaceInCollection && !isOpenInvoiceView && (
        <div
          className='app-section-header-utilities'
          style={{
            margin: "18px 10px 0 10px",
          }}
        >
          <DropdownButton
            title={`Filter By: ${this.filterIndicators(
              this.state.consumer_type
            )}`}
            historyFilter={true}
            style={{ maxWidth: 180, marginTop: 0 }}
            primaryLink='/client/accounts'
            clientMobile={true}
            closeDropDown={closeDropDown}
            setClose={this.setClose}
          >
            <MenuItem
              handleClick={(event) => this.handleFilter("all")}
              optionalClasses={
                this.state.consumer_type === "ALL" ? " filter-select" : " "
              }
            >
              All
            </MenuItem>

            <MenuItem
              handleClick={(event) => this.handleFilter("closed")}
              optionalClasses={
                this.state.consumer_type === "CLOSE" ? " filter-select" : " "
              }
            >
              Closed
            </MenuItem>

            <MenuItem
              handleClick={(event) => this.handleFilter("past_due")}
              optionalClasses={
                this.state.consumer_type === "PAST_DUE" ? " filter-select" : " "
              }
            >
              Past Due
            </MenuItem>

            <MenuItem
              handleClick={(event) => this.handleFilter("open")}
              optionalClasses={
                this.state.consumer_type === "OPEN" ? " filter-select" : " "
              }
            >
              Open
            </MenuItem>
            <MenuItem
              handleClick={(event) => this.handleFilter("voided")}
              optionalClasses={
                this.state.consumer_type === "VOIDED" ? " filter-select" : " "
              }
            >
              Voided
            </MenuItem>
          </DropdownButton>
        </div>
      )
    ) : (
      <div>{""}</div>
    );
  };

  getSectionHeaderUtilities = () => {
    const {
      activeTab,
      isOpenInvoiceView,
      isPlaceInCollection,
      invoiceLength,
      isPaymentPlansSelected,
      isReceivePaymentSelected,
      isReceivePaymentForInvoicesSelected,
      isStatementSelected,
      selectedTaskType,
    } = this.state;
    if (activeTab === 1 && selectedTaskType === "Invoices") {
      return (
        <div className='right-section mg-mt-10'>
          {isOpenInvoiceView && (
            <button
              className='mg-brand2-color send-invoice-email-btn mg-send-email-btn'
              onClick={() => this.sendMultipleInvoice()}
              style={{
                marginRight: "15px",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Send Email
            </button>
          )}
          {isPlaceInCollection && (
            <button
              className='mg-brand2-color send-invoice-email-btn mg-send-email-btn'
              onClick={() => this.checkPlaceInCollectionData(invoiceLength)}
              style={{
                marginRight: "15px",
                fontWeight: 500,
                cursor: "pointer",
                cursor: "pointer",
              }}
            >
              Place In Collection
            </button>
          )}
          {isReceivePaymentForInvoicesSelected && (
            <button
              className='mg-brand2-color send-invoice-email-btn mg-send-email-btn'
              onClick={() => this.receivePaymentsInvoice()}
              style={{
                marginRight: "15px",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Receive Payment
            </button>
          )}
          {isStatementSelected && (
            <button
              className='mg-brand2-color send-invoice-email-btn mg-send-email-btn'
              style={{
                marginRight: "15px",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Statements
            </button>
          )}
          <DropdownButton
            title='+ More Options'
            primaryLink='/client/accounts'
            overrideStyle={true}
            parentOptClasses={"account-re-down-mob"}
            buttonInnerClasses={"down-inner"}
            forFilter={true}
            clientMobile={true}
            totalFilter={true}
            onPrimaryClick={(event) => {}}
          >
            {!isStatementSelected && (
              <MenuItem handleClick={this.handleAddInvoiceClick}>
                Add Invoice
              </MenuItem>
            )}
            {!isReceivePaymentForInvoicesSelected ? (
              <MenuItem handleClick={this.receiveInvoicePayment}>
                Receive Payment
              </MenuItem>
            ) : (
              <MenuItem handleClick={this.closeReceiveInvoicePaymentView}>
                Back to all invoices list
              </MenuItem>
            )}
            <MenuItem
              handleClick={() =>
                this.setState({
                  isStatementSelected: true,
                  isReceivePaymentForInvoicesSelected: false,
                  isPlaceInCollection: false,
                  isOpenInvoiceView: false,
                })
              }
            >
              Statements
            </MenuItem>
            {isStatementSelected && (
              <MenuItem handleClick={this.handleGenerateStatementClick}>
                Generate Statement
              </MenuItem>
            )}
            {isStatementSelected && (
              <MenuItem handleClick={this.handleDateWiseGenerateStatementClick}>
                DateWise Generate Statement
              </MenuItem>
            )}
            {!isOpenInvoiceView ? (
              <MenuItem handleClick={this.openInvoiceView}>
                Send Multiple Invoices
              </MenuItem>
            ) : (
              <MenuItem handleClick={this.closeOpenInvoiceView}>
                Back to all invoices list
              </MenuItem>
            )}
            {!isPlaceInCollection ? (
              <MenuItem handleClick={this.openInvoiceListForCollection}>
                Place In Collections
              </MenuItem>
            ) : (
              <MenuItem handleClick={this.closeInvoiceListForCollection}>
                Back to all invoices list
              </MenuItem>
            )}
            {!isStatementSelected && (
              <MenuItem
                handleClick={() => {
                  this.handleTabChange(4);
                }}
              >
                Manage Payment Plan
              </MenuItem>
            )}
          </DropdownButton>
        </div>
      );
    }
    if (activeTab === 3) {
      return (
        <div className='right-section mg-mt-10'>
          <DropdownButton
            title='+ Add Credit Memo'
            primaryLink='/client/accounts'
            overrideStyle={true}
            parentOptClasses={"account-re-down-mob mg-btnsize"}
            buttonInnerClasses={"down-inner"}
            forFilter={true}
            clientMobile={true}
            totalFilter={true}
            onPrimaryClick={(event) => {}}
          >
            <MenuItem handleClick={this.handleAddCreditMemoClick}>
              Add Credit Memo
            </MenuItem>
          </DropdownButton>
        </div>
      );
    }
    if (activeTab === 4) {
      return (
        <div className='right-section mg-mt-10'>
          {isPaymentPlansSelected && (
            <button
              className='mg-brand2-color send-invoice-email-btn mg-send-email-btn'
              onClick={() => this.SelectInvoiceToAddInPaymentPlans()}
              style={{
                marginRight: "15px",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Create Payment Plan
            </button>
          )}
          {isReceivePaymentSelected && (
            <button
              className='mg-brand2-color send-invoice-email-btn mg-send-email-btn'
              onClick={() => this.SelectPlansToReceivePayment()}
              style={{
                marginRight: "15px",
                fontSize: "16px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Receive Payment
            </button>
          )}
          <DropdownButton
            title='+ More Options'
            primaryLink='/client/accounts'
            overrideStyle={true}
            parentOptClasses={"account-re-down-mob mg-btnsize"}
            buttonInnerClasses={"down-inner"}
            forFilter={true}
            clientMobile={true}
            totalFilter={true}
            onPrimaryClick={(event) => {}}
          >
            {isPaymentPlansSelected ? (
              <>
                <MenuItem handleClick={this.handleBacktoInvoiceList}>
                  Back to invoices list
                </MenuItem>
                <MenuItem
                  handleClick={() => {
                    this.handleBacktoPaymentPlansList();
                  }}
                >
                  Back to Payment Plans
                </MenuItem>
              </>
            ) : isReceivePaymentSelected ? (
              <>
                <MenuItem handleClick={this.handleBacktoInvoiceList}>
                  Back to invoices list
                </MenuItem>
                <MenuItem
                  handleClick={() => {
                    this.handleBacktoPaymentPlansList();
                  }}
                >
                  Back to Payment Plans
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem handleClick={this.handleAddPaymentPlan}>
                  Add Payment Plan
                </MenuItem>
                <MenuItem handleClick={this.handleBacktoInvoiceList}>
                  Back to invoices list
                </MenuItem>
                <MenuItem handleClick={() => {}}>Receive Payment</MenuItem>
              </>
            )}
          </DropdownButton>
        </div>
      );
    }
  };

  //get three dot popover
  renderThreeDotOptions = () => {
    return (
      <HeaderOverflowMenu
        showThreeDots={true}
        optionalClasses={"account-dropdown-optional"}
        innerOptionalClasses={"account-dropdown"}
      >
        {this.state.rowAction.map((rowAction) => {
          return (
            <HeaderMenuItem
              key={shortid.generate()}
              rowAction={rowAction}
              dataBehavior={rowAction.dataBehavior}
              handleClick={(event, rowAction) =>
                this.handleOptionClick(event, rowAction)
              }
              optionalClasses={`drop-down-options account-drop-down-options ${
                rowAction &&
                rowAction.title &&
                rowAction.title === this.state.selectedOPT
                  ? "selected-account-opt"
                  : ""
              }`}
            >
              {rowAction}
            </HeaderMenuItem>
          );
        })}
      </HeaderOverflowMenu>
    );
  };

  setRowActions = () => {
    let { user } = this.props;
    let data = [
      {
        title: "Invoices",
        dataBehavior: "invoices",
        icon: (
          <div
            className={`app-client-header-user-avatar app-client-header-icon`}
          >
            {/* <span>{this.renderUserInitials(user)}</span> */}
          </div>
        ),
      },
      {
        title: "Credit Memo",
        dataBehavior: "credit-memo",
        icon: (
          <div className='app-client-header-icon'>
            {/* <img src={settings} width="20" height="20" /> */}
          </div>
        ),
      },
    ];
    this.setState({ rowAction: data });
  };

  //calls when select any option.
  handleOptionClick = (event, rowAction) => {
    let { history } = this.props;
    const actionBehavior =
      rowAction && rowAction.dataBehavior ? rowAction.dataBehavior : "";
    const actionTitle = rowAction && rowAction.title ? rowAction.title : "";

    switch (actionBehavior) {
      case "invoices":
        this.onOptionChange(actionTitle);
        break;
      case "credit-memo":
        this.onOptionChange(actionTitle);
        break;
      default:
        this.setState({ selectedOPT: "Invoices" });
    }
  };

  getDate = (date) => {
    if (date) {
      let newDate = moment.utc(date).format("MM/DD/YYYY HH:mm:ss").split(" ");
      return (
        <div>
          {newDate[0]}
          <br />
          <div style={{}}>{`${newDate[1]}`}</div>
        </div>
      );
    }
    return "";
  };

  onOptionChange = (index) => {
    const { activeTab } = this.state;
    const id = this.props.match.params.id;
    this.setState(
      {
        isTableLoading: true,
        debts: [],
        isPaymentPlansSelected: false,
        isReceivePaymentSelected: false,
        paymentPlansList: [],
      },
      () => {
        if (activeTab === 1) {
          this.setState({ isStatementSelected: false });
          this.handleSearchFilter(id);
        } else if (activeTab === 2) {
          this.getUnappliedPayments();
          this.getGenerateStatementList(id);
        } else if (activeTab === 3) {
          this.getCreditMemoList(id);
        } else if (activeTab === 4) {
          this.getPaymentPlanList(id);
        }
      }
    );
  };

  //calls when tab click event triggers
  handleTabChange = (tabIndex) => {
    if (tabIndex !== this.state.activeTab || tabIndex === 4) {
      this.setState(
        {
          activeTab: tabIndex,
          isOpenInvoiceView: false,
          isPlaceInCollection: false,
          isReceivePaymentSelected: false,
          isPaymentPlansSelected: false,
        },
        () => {
          this.onOptionChange(tabIndex);
        }
      );
    }
  };
  filterIndicators = (indicator) => {
    if (indicator == "closed") {
      return "Closed";
    } else if (indicator == "past_due") {
      return " Past Due";
    } else if (indicator == "voided") {
      return "Voided";
    } else if (indicator == "open") {
      return "Open";
    } else if (indicator == "all") {
      return "All";
    }
  };

  //render UI for Tabbar
  getSectionHeaderUtilitiesNew() {
    const { activeTab } = this.state;
    // const tabLabels = ["Tasks", "QuickBooks", "Payments", "Goods & Services", "Terms"];
    const tabLabels = ["Invoices", "Payments", "Credit Memos", "Payment Plans"];

    return (
      <Tabs
        labels={tabLabels}
        activeTarget={activeTab}
        handleTabChange={this.handleTabChange}
        optionalInTabClasses='mg-tabs-tab'
        optionalClasses='account-tp-sp con-fit large-tabs account-tabs'
      />
    );
  }

  render() {
    const { appName, user, history } = this.props;
    const {
      consumer,
      debts,
      isLoading,
      showValidationScreen,
      selectedDebt,
      upcoming_activities,
      recent_activities,
      error,
      selectedOPT,
      creditMemoList,
      activeTab,
      statementList,
      paymentPlansList,
      isStatementSelected,
    } = this.state;

    let backPath = showValidationScreen
      ? `/client/accounts/${consumer.id}`
      : "/client/accounts";
    let backText = showValidationScreen
      ? "Back to Account"
      : "Back to All Accounts";

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    let first_name = consumer.first_name ? consumer.first_name : "";
    let last_name = consumer.last_name ? consumer.last_name : "";

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>
            {appName} | {first_name + " " + last_name}
          </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsSidebar
            {...this.props}
            backPath={backPath}
            backText={backText}
            account={consumer}
            user={user}
            totalAccountCredit={this.state.totalAccountCredit}
            history={history}
            upcoming_activities={upcoming_activities}
            recent_activities={recent_activities}
            handleEditSetting={this.handleEditSetting}
          />
          <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
            {showValidationScreen ? (
              <ClientsUploadValidationDoc
                {...this.props}
                debtData={selectedDebt}
                consumer={consumer}
              />
            ) : (
              <div>
                <div
                  className='account-view-subheader-wrapper btnAlignHolder'
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexFlow: "wrap",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className='btnAlign'
                    style={{
                      marginBottom: "0em",
                      marginTop: "0em",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div>{this.getSectionHeaderUtilitiesNew()}</div>
                    <div className='filter-btn-web'>
                      {this.getSectionHeaderUtilitiess()}
                    </div>
                    {this.state.activeTab == 1 &&
                    this.state.isOpenInvoiceView == false ? (
                      <div
                        className='utility-btn-col'
                        style={{ marginLeft: "4px" }}
                      ></div>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {this.getSectionHeaderUtilities()}
                    <div className='filter-btn-small'>
                      {this.getSectionHeaderUtilitiess()}
                    </div>
                  </div>
                </div>
                {activeTab === 1 && isStatementSelected
                  ? this.renderStatementTable(statementList)
                  : activeTab === 1
                  ? this.renderTable(debts)
                  : null}
                {/* {activeTab === 2 && this.renderStatementTable(statementList)} */}
                {activeTab === 2 && this.renderPaymentsTable()}
                {activeTab === 3 && this.renderCreditMemoTable(creditMemoList)}
                {activeTab === 4 &&
                  this.renderPaymentPlansTables(paymentPlansList)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsAccountsView;

let StyleSheet = {
  btnDiv: {
    height: "16px",
    width: "16px",
  },
};
