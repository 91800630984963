import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
// Packages
import currencyFormatter from 'currency-formatter';

// Components
import Panel from '../App/Panel';

class SuperAdminSidebar extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired
  }

  render() {
    const { account, name } = this.props;

    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar ">
        <Panel
          heading="Organization Information"
        >
          {this.props.renderEnablePayment()}
          <h4 className="panel-heading-secondary">
            Basic Information
          </h4>
          <p>
            {account.name}
            <br />
            {account.email &&
              <span style={{ wordBreak: 'break-all' }}>
                {account.email}
              </span>
            }
            {account.email && <br />}
            {account.phone_number}
          </p>
          <h4 className="panel-heading-secondary">
            Website Url
          </h4>
          <p style={{ wordBreak: 'break-all' }}>
            {account.website_url}
          </p>
          <h4 className="panel-heading-secondary">
            Logo
          </h4>
          <div className="client-sidebar-logo">
            <Popup
              style={{ transform: `translate(50%,-50%)`, minWidth: `auto` }}
              trigger={<img alt='document'
                src={account.logo} width="40" height="40" />
              }
              position="top center"
            />
          </div>
          <h4 className="panel-heading-secondary">
            Theme Color
          </h4>
          <p className="client-sidebar-theme">
            <div className="client-sidebar-theme-value" style={{ backgroundColor: account.color }} />
            {account.color}
          </p>
          <h4 className="panel-heading-secondary">
            Address
          </h4>
          <p>
            {account.address}
          </p>
          <h4 className="panel-heading-secondary">
            Contact No
          </h4>
          <p>
            {account.contact_no ? account.contact_no : 'Not available'}
          </p>
        </Panel>
        <div className="app-sidebar-actions">
          <Link
            className="app-sidebar-actions-link edit"
            to={`/super-admin/accounts/${name}/edit`}>
            Edit Account
          </Link>
          {/* <Link
            className="app-sidebar-actions-link delete"
            to={`/admin/accounts/${name}`}>
            Delete Account
          </Link> */}
        </div>
      </div>
    );
  }
}

export default SuperAdminSidebar;
