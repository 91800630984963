import React, { Component } from 'react';

// Packages
import Select from 'react-select';

// Components
import Panel from '../../App/Panel';

// Utils
import { validateEmail } from '../../../utils/helpers';

class ClientsAddAdminForm extends Component {
  state = {
    input: {
      name: {
        value: "",
        hasError: false
      },
      email: {
        value: "",
        hasError: false
      },
      role: {
        value: "",
        hasError: false
      }
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const { input } = this.state;

      const name = input.name.value;
      let firstName = name.substr(0,name.indexOf(' '));
      let lastName = name.substr(name.indexOf(' ')+1);
      let clientAdmin = false;

      if (input.role.value === "Client") {
        clientAdmin = true
      }

      let newClientUser = {
        first_name: firstName,
        last_name: lastName,
        email: input.email.value,
        client_admin: clientAdmin
      }

      this.props.handleFormSubmission(newClientUser);
      this.resetAndCloseForm();
    }
  }

  validateForm = () => {
    let newInputState = {...this.state.input};
    let errorsArePresent = false;
    if (!validateEmail(newInputState.email.value)) {
      newInputState.email.hasError = true;
      errorsArePresent = true;
    }
    if (newInputState.name.value === '') {
      newInputState.name.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true;
    }
  }

  resetAndCloseForm = () => {
    this.resetForm();
    this.props.closeForm();
  }

  resetForm = () => {
    this.setState(state => ({
      ...state,
      input: {
        name: {
          value: "",
          hasError: false
        },
        email: {
          value: "",
          hasError: false
        },
        role: {
          value: "",
          hasError: false
        }
      }
    }))
  }

  renderErrorMessage(type) {
    return type === 'name'
    ? (
      <div className="input-error-message">
        Please enter a name
      </div>
    )
    : (
      <div className="input-error-message">
        Please enter a valid email address
      </div>
    )
  }

  render() {
    let { input } = this.state;

    return (
      <form
        className="admin-add-admin-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <Panel>
          <div
            className={`input-container${input.name.hasError ? ' error' : ''}`}
          >
            <label htmlFor="name">
              Client's Name
            </label>
            <input
              name="name"
              type="text"
              placeholder="Name"
              value={input.name.value}
              onChange={event => this.handleInputChange(
                  {name: {value: event.target.value, hasError: false}}
              )}
            />
            {input.name.hasError ? this.renderErrorMessage('name') : null}
          </div>
          <div
            className={`input-container${input.email.hasError ? ' error' : ''}`}
          >
            <label htmlFor="email">
              Client's Email
            </label>
            <input
              name="email"
              type="email"
              placeholder="Email"
              value={input.email.value}
              onChange={event => this.handleInputChange(
                  {email: {value: event.target.value, hasError: false}}
              )}
            />
            {input.email.hasError ? this.renderErrorMessage('email') : null}
          </div>
          <div className="select-container">
            <label htmlFor="email">
              Role
            </label>
            <Select
              inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
              className="form-select"
              name="role"
              value={input.role.value}
              placeholder="Select Role"
              options={ [
                {"value":"Admin","label":"Admin"},
                {"value":"Client","label":"Client Admin"}
              ] }
              resetValue=""
              onChange={event => this.handleInputChange({role: {value: event ? event.value : '', hasError: false}})}
            />
          </div>
          <div className="admin-add-admin-form-actions">
            <div className="admin-add-admin-form-actions-content">
              <input type="submit" value="+ Add Client" className="cta" />
              <a
                className="text-link"
                onClick={() => this.resetAndCloseForm()}
              >
                Cancel
              </a>
            </div>
          </div>
        </Panel>
      </form>
    );
  }
}

export default ClientsAddAdminForm;
