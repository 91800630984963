import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import filter_icon from "../assets/icons/filter.svg";

// Packages
import onClickOutside from "react-onclickoutside";

class DropdownButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  };

  state = {
    dropdownIsActive: false,
  };

  componentDidUpdate(props) {
    if (
      props.closeDropDown &&
      props.historyFilter &&
      this.state.dropdownIsActive
    ) {
      this.toggleDropdown();
      this.props.setClose();
    }
  }

  toggleDropdown = () => {
    this.setState((state) => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive,
    }));
  };

  handleClickOutside = () => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown();
    }
  };

  renderPrimaryButton(
    title,
    onPrimaryClick,
    primaryLink,
    overrideStyle,
    buttonInnerClasses,
    totalFilter,
    appThemeData
  ) {
    if (onPrimaryClick && totalFilter) {
      return (
        <a
          className={`${appThemeData ? "" : "mg-brand2-color"}  ${
            buttonInnerClasses ? buttonInnerClasses + " " : ""
          }${
            overrideStyle
              ? "cta dropdown-button-submit override-dropdown-button"
              : "cta dropdown-button-submit"
          }${!!this.props.pointerNone ? " mg-pointer-none " : " "}`}
          onClick={() => this.toggleDropdown()}
          style={{
            backgroundColor: appThemeData ? appThemeData.primary_color : "",
            borderColor: "#E1E1E1",
            fontFamily: 'Open Sans'
          }}
        >
          {title}
        </a>
      );
    } else if (onPrimaryClick) {
      return (
        <a
          className={`${appThemeData ? "" : "mg-brand2-color"} ${
            buttonInnerClasses ? buttonInnerClasses + " " : ""
          }${
            overrideStyle
              ? "cta dropdown-button-submit override-dropdown-button"
              : "cta dropdown-button-submit"
          }${!!this.props.pointerNone ? " mg-pointer-none " : " "}`}
          onClick={onPrimaryClick}
          style={{
            backgroundColor: appThemeData ? appThemeData.primary_color : "",
            borderColor: "#E1E1E1",
          }}
        >
          {title}
        </a>
      );
    } else {
      return (
        <Link
          className={`${appThemeData ? "" : "mg-brand2-color"} ${
            buttonInnerClasses ? buttonInnerClasses + " " : ""
          }${
            overrideStyle
              ? "cta dropdown-button-submit override-dropdown-button"
              : "cta dropdown-button-submit"
          }${!!this.props.pointerNone ? " mg-pointer-none " : " "}`}
          to={primaryLink}
          style={{
            backgroundColor: appThemeData ? appThemeData.primary_color : "",
            borderColor: "#E1E1E1",
          }}
        >
          {title}
        </Link>
      );
    }
  }

  render() {
    const {
      title,
      children,
      onPrimaryClick,
      primaryLink,
      overrideStyle,
      forFilter,
      parentOptClasses,
      buttonInnerClasses,
      clientMobile,
      historyFilter,
      totalFilter,
      appThemeData,
    } = this.props;

    let { dropdownIsActive } = this.state;

    if (historyFilter) {
      return (
        <a style={{ cursor: "pointer" }} onClick={() => this.toggleDropdown()}>
          {/* <img className="history-filter-icon" src={filter_icon} /> */}
          <svg
            className="history-filter-icon"
            id="filter"
            xmlns="http://www.w3.org/2000/svg"
            width="32.001"
            height="32.001"
            viewBox="0 0 53 53"
          >
            {" "}
            <circle
              style={{ fill: appThemeData ? appThemeData.primary_color : "#0ab894" }}
              cx="26.5"
              cy="26.5"
              r="26.5"
            />{" "}
            <path
              id="Forma_1"
              data-name="Forma 1"
              style={{ fill: "#fff", fillRule: "evenodd" }}
              d="M37.211,15.921a0.681,0.681,0,0,0-.617-0.4H15.458a0.682,0.682,0,0,0-.617.4,0.7,0.7,0,0,0,.091.734l8.125,9.981v9.2a0.693,0.693,0,0,0,.322.587,0.674,0.674,0,0,0,.662.032l4.565-2.291a0.691,0.691,0,0,0,.379-0.618l0.009-6.912,8.125-9.981A0.7,0.7,0,0,0,37.211,15.921ZM27.787,25.947a0.7,0.7,0,0,0-.156.439l-0.009,6.732-3.2,1.607V26.387a0.7,0.7,0,0,0-.156-0.439L16.9,16.906H35.148Z"
            />{" "}
          </svg>
          <ul
            className={`dropdown-menu${dropdownIsActive ? " active" : ""}`}
            onClick={() => this.toggleDropdown()}
            style={this.props.style}
          >
            {children}
          </ul>
        </a>
      );
    }

    return (
      <div
        className={`dropdown-button${
          parentOptClasses ? " " + parentOptClasses : ""
        }`}
      >
        {this.renderPrimaryButton(
          title,
          onPrimaryClick,
          primaryLink,
          overrideStyle,
          buttonInnerClasses,
          totalFilter,
          appThemeData
        )}
        <a
          className={`cta dropdown-button-toggle ${
            appThemeData ? "" : "toggle-sep"
          } ${dropdownIsActive ? " active" : ""}
          ${appThemeData ? `` : `mg-brand2-color`}
          ${overrideStyle ? " override-toggle" : ""}`}
          onClick={() => this.toggleDropdown()}
          style={{
            backgroundColor: appThemeData ? appThemeData.primary_color : "",
            borderColor: "#E1E1E1",
          }}
        >
          <div className="caret"></div>
        </a>
        <ul
          className={`dropdown-menu${dropdownIsActive ? " active" : ""}${
            clientMobile ? " mobile-left" : ""
          }${!!this.props.extraPropsForIndex ? " mg-zIndex" : " "}`}
          onClick={() => (forFilter ? this.toggleDropdown() : "")}
        >
          {children}
        </ul>
      </div>
    );
  }
}

export default onClickOutside(DropdownButton);
