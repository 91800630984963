import React, { Component } from 'react';

// Components
import Modal from '../Modal';


class OfferRejectedModal extends Component {

  handleModalClose = () => {
    const { debtId } = this.props.optionalProps;
    this.props.hideModal();
    // this.props.optionalProps.history.push(`/dashboard/accounts/${debtId}`);
  }

  render() {
    let { user } = this.props.optionalProps;

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="offer-rejected"
        closeOnClickOutside={true}
      >
        <div className="modal-spotlight-illustration"></div>
        <h1 className="modal-heading">
          Offer has been rejected!
        </h1>
        <p>
          {user.first_name} will receive a message that the offer has been rejected. {user.first_name} will need to submit a new offer or pay for the debt.
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.handleModalClose}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default OfferRejectedModal;