import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Components
import Panel from '../App/Panel'

class ClientsEmployeeSidebar extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  }

  renderAddress(user) {
    if (
      user.street_address_primary ||
      user.street_address_secondary ||
      user.city ||
      user.state ||
      user.zipcode
    ) {
      return (
        <p>
          {user.street_address_primary ? user.street_address_primary : ''}
          {user.street_address_primary && <br />}
          {user.street_address_secondary ? user.street_address_secondary : ''}
          {user.street_address_secondary && <br />}
          {user.city ? user.city : ''}
          {user.city && user.state ? ', ' : ''}
          {user.state ? user.state : ''}
          {user.state && user.zipcode ? ' ' : ''}
          {user.zipcode ? user.zipcode : ''}
        </p>
      )
    } else {
      return (
        <p>
          No address available
        </p>
      )
    }
  }

  render() {
    let { user, addressDocument } = this.props
    return (
      <div className="app-sidebar app-sidebar-left">
        <div className="mobile-actions app-sidebar-actions">
          <h4>Hello, {user.first_name}!</h4>
          <Link
            className="app-sidebar-actions-link"
            to="/sign-out">
            Logout
          </Link>
        </div>
        <Panel
          heading="Employee Information"
        >
          <h4 className="panel-heading-secondary">
            Employee Full Name
              </h4>
          <p>
            {user.first_name} {user.last_name}
          </p>
          <h4 className="panel-heading-secondary">
            Email
              </h4>
          <p>
            {user.email ? user.email : 'Not Available'}
          </p>
          <h4 className="panel-heading-secondary">
            Phone Number
              </h4>
          <p>
            {user.phone_number ? user.phone_number : 'Not Available'}
          </p>
          <h4 className="panel-heading-secondary">
            Mailing Address:
              </h4>
          {this.renderAddress(user)}
        </Panel>
        <div className="app-sidebar-actions">
          <Link
            className="app-sidebar-actions-link"
            target="_blank"
            to="/terms">
            Terms of Service
          </Link>
          <Link
            className="app-sidebar-actions-link"
            target="_blank"
            to="/privacy">
            Privacy Information
          </Link>
        </div>
      </div>
    )
  }
}
export default ClientsEmployeeSidebar
