import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import AppClientHeader from "../../../components/App/AppClientHeader";
import ClientsNav from "../../../components/Clients/ClientsNav";
import MGLogoIcon from "../../../assets/icons/MG-logo_icon.png";
import * as API from "../../../utils/api";
import { errorMessage } from "../../../utils/helpers";
import MakeGoodEULA from "../../../screens/EndUserLicenseAgreement";

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#ffffff",
    opacity: 0.95,
  },
};

class ClientsSSODashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organization_key: "",
      sub_id: "",
      email: "",
      id_token: "",
      isSavedSubdomain: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      user_auth_token: null,
      user_id: null,
      eula_accepted_date: false,
    };
  }

  componentDidMount() {
    //get initial dashboard details
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    let qbUserInfo = JSON.parse(searchParams.get("qb_user_info"));
    let email = qbUserInfo.email;
    let organization_key = searchParams.get("org_key");
    let sub_id = qbUserInfo.sub;
    let id_token = searchParams.get("id_token");
    let is_save_subdomain = searchParams.get("is_save_subdomain");
    var me = this;
    this.setState(
      {
        organization_key: organization_key,
        sub_id: sub_id,
        id_token: id_token,
        email: email,
        isSavedSubdomain: is_save_subdomain,
      },
      () => {
        me.ssoLoginMethod();
      }
    );
  }

  dateIsValid = (date) => {
    if (date && date != "") {
      return new Date(date) instanceof Date && !isNaN(new Date(date));
    } else {
      return false;
    }
  };

  ssoLoginMethod = () => {
    let { organization_key, sub_id, email, id_token } = this.state;
    let { history } = this.props;
    const userCandidate = {
      email: email,
      organization_key: organization_key,
      openid_id: sub_id,
      id_token: id_token,
      sub_id: sub_id,
    };
    this.setState({ isLoading: true });
    let newData = JSON.parse(localStorage.getItem("newOrgData"));
    let userOrgData = userCandidate ? userCandidate : newData;
    try {
      API.setOauthSSOObj(userOrgData);
    } catch (error) {
      console.log("error catched clientssodashboard==>", error);
    }
    localStorage.setItem("switchOrgDetails", JSON.stringify(userCandidate));
    API.ssoLogin(userOrgData).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (!data.errors) {
          const user = {
            qb_connect:
              data.is_qb_connected === null ? false : data.is_qb_connected,
            first_name: data.first_name,
            last_name: data.last_name,
            user_id: data.id,
            user_auth_token: data.auth_token,
            admin: data.admin,
            is_employee: data.is_employee,
            super_admin: data.super_admin,
            onboarding_complete: data.accepted_on ? true : false,
            is_onboarding_completed: data.accepted_on ? true : false,
            client_admin: data.client_admin,
            verified: data.verified,
            verified_at: data.verified_at,
            email: data.email,
            user_hash: data.user_hash,
            intercom_id: data.intercom_id,
            organization_key: organization_key,
          };
          this.setState({
            user_auth_token: data.auth_token,
            user_id: data.id,
            eula_accepted_date: this.dateIsValid(data.accepted_on),
          });
          if (this.dateIsValid(data.accepted_on)) {
            this.props.setEULAtrue();
          }
          this.props.updateUserLogin(user);
          if (this.state.isSavedSubdomain) {
            history.push(`/welcome-screen`, {
              organization_key: organization_key,
              idToken: id_token,
              email: email,
              sub_id: sub_id,
            });
          }
        } else {
          this.setState((state) => ({
            ...state,
            showFlash: true,
            accessError: errorMessage,
            errorMsg: data.errors,
          }));
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
      this.setState({ isLoading: false });
    });
  };

  nextClick = () => {
    let { organization_key } = this.state;
    let { history } = this.props;
    history.push(`/sub-domain`, { organization_key: organization_key });
  };

  render() {
    const { appName, user } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <>
        {this.props.isEULAtrue || this.state.eula_accepted_date ? (
          <div className='app-portal'>
            <AppClientHeader
              user={this.props.user}
              appName={this.props.appName}
              logoPath='/client/accounts/'
              hideNav={true}
              headerTab={this.props.headerTab}
              disableNav={true}
              hideHeadNavOpt={true}
            />
            <div className={`app-portal-canvas consumer-portal canvas-back`}>
              <ClientsNav
                path={this.props.location}
                user={user}
                disableNav={true}
              />
              <div className='wrap' class='sso-dashboard-cls he-hed'>
                <div>
                  <div class='company-logo-center'>
                    <img
                      class='companyLogoDiv-consumer-view'
                      src={MGLogoIcon}
                    />
                  </div>
                </div>
                <div style={{ margin: "40px" }}>
                  <p>Welcome to MakeGood!</p>
                  <p style={{ textAlign: "center" }}>
                    Setting up your new account is easy.
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <a className='cta mg-brand2-color' onClick={this.nextClick}>
                    Let’s get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <MakeGoodEULA
            isEULAtrue={this.props.isEULAtrue}
            setEULAtrue={this.props.setEULAtrue}
            updateModalState={this.props.updateModalState}
            handleSignOut={this.props.handleSignOut}
            sub_id={this.state.sub_id}
            user={{
              user_auth_token: this.state.user_auth_token,
              user_id: this.state.user_id,
              eula_accepted_date: this.state.eula_accepted_date,
            }}
          />
        )}
        {isLoading ? (
          <div style={styles.loader}>
            <AppCanvasLoader />
          </div>
        ) : null}
      </>
    );
  }
}
export default ClientsSSODashboard;
