import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
// Packages
import MaskedTextInput from "react-text-mask";
import { validateEmail } from "../../../utils/helpers";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
import Panel from "../../App/Panel";

class ClientsEmployeeProfileForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    input: {
      first_name: {
        value: "",
        hasError: false,
      },
      last_name: {
        value: "",
        hasError: false,
      },
      phone: {
        value: "",
        hasError: false,
      },
      email: {
        value: "",
        hasError: false,
      },
      street_address_primary: {
        value: "",
        hasError: false,
      },
      street_address_secondary: {
        value: "",
        hasError: false,
      },
      city: {
        value: "",
        hasError: false,
      },
      state: {
        value: "",
        hasError: false,
      },
      zipcode: {
        value: "",
        hasError: false,
      },
      allowToMakeOffers: {
        value: false,
        hasError: false,
      },
      viewAllAcounts: {
        value: false,
        hasError: false,
      },
    },
  };

  componentDidMount() {
    const { employee } = this.props;
    this.setState((state) => ({
      input: {
        first_name: {
          ...state.input.first_name,
          value: employee.first_name,
        },
        last_name: {
          ...state.input.last_name,
          value: employee.last_name,
        },
        phone: {
          ...state.input.phone,
          value: employee.phone_number,
        },
        email: {
          ...state.input.email,
          value: employee.email,
        },
        street_address_primary: {
          ...state.input.street_address_primary,
          value: employee.street_address_primary,
        },
        street_address_secondary: {
          ...state.input.street_address_secondary,
          value: employee.street_address_secondary,
        },
        city: {
          ...state.input.city,
          value: employee.city,
        },
        state: {
          ...state.input.state,
          value: employee.state,
        },
        zipcode: {
          ...state.input.zipcode,
          value: employee.zipcode,
        },
        allowToMakeOffers: {
          ...state.input.allowToMakeOffers,
          value: employee.allow_make_offer,
        },
        viewAllAcounts: {
          ...state.input.viewAllAcounts,
          value: employee.view_all_accounts,
        },
      },
    }));
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    let { input } = this.state;
    event.preventDefault();
    if (this.validateForm()) {
      let phoneNumber = this.state.input.phone.value;
      phoneNumber = phoneNumber
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");
      const updateEmployee = {
        first_name: input.first_name.value,
        last_name: input.last_name.value,
        phone_number: phoneNumber,
        email: input.email.value,
        street_address_primary: input.street_address_primary.value,
        street_address_secondary: input.street_address_secondary.value,
        city: input.city.value,
        state: input.state.value,
        zipcode: input.zipcode.value,
        allow_make_offer: input.allowToMakeOffers.value,
        view_all_accounts: input.viewAllAcounts.value,
      };
      this.props.handleSubmit(updateEmployee);
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "first_name") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined
        ) {
          newInputState.first_name.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "last_name") {
        if (
          value.value === "" ||
          value.value === null ||
          value.value === undefined
        ) {
          newInputState.last_name.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "phone") {
        let phoneNumber = value.value;
        if (
          phoneNumber === "" ||
          phoneNumber === null ||
          phoneNumber === undefined
        ) {
          newInputState.phone.hasError = true;
          errorsArePresent = true;
        } else {
          let phoneNumber = value.value
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
          if (phoneNumber === "") {
            newInputState.phone.hasError = true;
            errorsArePresent = true;
          } else if (phoneNumber.trim().length < 9) {
            newInputState.phone.hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (key === "email") {
        if (value.value === "" || !validateEmail(value.value)) {
          newInputState.email.hasError = true;
          errorsArePresent = true;
        } else if (!validateEmail(value.value)) {
          newInputState.email.hasError = true;
        }
      } else if (key === "street_address_primary") {
        if (value.value === "") {
          newInputState.street_address_primary.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "city") {
        if (value.value === "") {
          newInputState.city.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "state") {
        if (value.value === "") {
          newInputState.state.hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "zipcode") {
        if (value.value === "") {
          newInputState.zipcode.hasError = true;
          errorsArePresent = true;
        }
      }
    });
    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "first_name") {
      message = "Please enter first name";
    } else if (input === "last_name") {
      message = "Please enter last name";
    } else if (input === "phone") {
      message = "Please enter a valid phone number";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "street_address_primary") {
      message = "Street Address cannot be blank";
    } else if (input === "city") {
      message = "City cannot be blank";
    } else if (input === "state") {
      message = "State cannot be blank";
    } else if (input === "zipcode") {
      message = "Zip Code cannot be blank";
    } else if (input === "document") {
      message = "";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let { input } = this.state;

    return (
      <form
        className="consumer-profile-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <Panel heading="Update Employee Profile">
          <div className="admin-form-row">
            <div
              className={`input-container half${
                input.first_name.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="first_name">First Name</label>
              <input
                name="first_name"
                guide={true}
                placeholder="Enter First Name"
                type="text"
                value={input.first_name.value}
                onChange={(event) =>
                  this.handleInputChange({
                    first_name: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.first_name.hasError
                ? this.renderErrorMessage("first_name")
                : null}
            </div>
            <div
              className={`input-container half ${
                input.last_name.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="last_name">Last Name</label>
              <input
                name="last_name"
                guide={true}
                placeholder="Enter Last Name"
                type="text"
                value={input.last_name.value}
                onChange={(event) =>
                  this.handleInputChange({
                    last_name: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.last_name.hasError
                ? this.renderErrorMessage("last_name")
                : null}
            </div>
          </div>

          <div className="admin-form-row">
            <div
              className={`input-container half ${
                input.email.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">E-mail:</label>
              <input
                name="email"
                placeholder="address@email.com"
                type="text"
                value={input.email.value}
                onChange={(event) =>
                  this.handleInputChange({
                    email: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.email.hasError ? this.renderErrorMessage("email") : null}
            </div>
            <div
              className={`input-container half ${
                input.phone.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">Phone Number</label>
              <MaskedTextInput
                name="phone"
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="(###) ###-####"
                placeholderChar={"\u2000"}
                guide={true}
                type="text"
                value={input.phone.value}
                onChange={(event) =>
                  this.handleInputChange({
                    phone: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.phone.hasError ? this.renderErrorMessage("phone") : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container${
                input.street_address_primary.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">Street Address </label>
              <input
                name="street_address_primary"
                placeholder=""
                type="text"
                value={input.street_address_primary.value}
                onChange={(event) =>
                  this.handleInputChange({
                    street_address_primary: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.street_address_primary.hasError
                ? this.renderErrorMessage("street_address_primary")
                : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container two-thirds${
                input.street_address_secondary.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">Street Address Line 2</label>
              <input
                name="street_address_secondary"
                placeholder=""
                type="text"
                value={input.street_address_secondary.value}
                onChange={(event) =>
                  this.handleInputChange({
                    street_address_secondary: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
              {input.street_address_secondary.hasError
                ? this.renderErrorMessage("street_address_secondary")
                : null}
            </div>
            <div
              className={`input-container one-third ${
                input.zipcode.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">Zip Code</label>
              <MaskedTextInput
                guide={true}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                name="zipcode"
                placeholder="90210"
                placeholderChar={"\u2000"}
                type="text"
                value={input.zipcode.value}
                onChange={(event) =>
                  this.handleInputChange({
                    zipcode: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.zipcode.hasError
                ? this.renderErrorMessage("zipcode")
                : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container half${
                input.city.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">City</label>
              <input
                name="city"
                placeholder=""
                type="text"
                value={input.city.value}
                onChange={(event) =>
                  this.handleInputChange({
                    city: { value: event.target.value, hasError: false },
                  })
                }
              />
              {input.city.hasError ? this.renderErrorMessage("city") : null}
            </div>
            <div
              className={`input-container half${
                input.state.hasError ? " error" : ""
              }`}
            >
              <label htmlFor="phone">State </label>
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className="form-select"
                name="state"
                value={input.state.value}
                placeholder="Select a State"
                options={stateDataShortNames}
                resetValue=""
                onChange={(event) =>
                  this.handleInputChange({
                    state: { value: event ? event.value : "", hasError: false },
                  })
                }
              />
              {input.state.hasError ? this.renderErrorMessage("state") : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div className={`input-container`}>
              <label htmlFor="make-offer" style={{ display: `flex` }}>
                <input
                  name="make-offer"
                  type="checkbox"
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `-5px`,
                  }}
                  checked={input.allowToMakeOffers.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      allowToMakeOffers: {
                        value: !input.allowToMakeOffers.value,
                        hasError: false,
                      },
                    })
                  }
                />
                Allow To Make Offer
              </label>
            </div>
            <div className={`input-container`}>
              <label htmlFor="view-account" style={{ display: `flex` }}>
                <input
                  name="view-account"
                  type="checkbox"
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `-5px`,
                  }}
                  checked={input.viewAllAcounts.value}
                  onChange={(event) =>
                    this.handleInputChange({
                      viewAllAcounts: {
                        value: !input.viewAllAcounts.value,
                        hasError: false,
                      },
                    })
                  }
                />
                View All Accounts
              </label>
            </div>
          </div>
        </Panel>
        <input type="submit" value="Save Changes" className="cta" />
      </form>
    );
  }
}

export default withRouter(ClientsEmployeeProfileForm);
