import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import {Link} from "react-router-dom";
import * as API from "../../utils/api";
import {errorMessage, validateEmail} from "../../utils/helpers";

class SSOFailure extends Component {
    constructor(props) {
        super(props);
    }


    signInWithIntuit = () => {
        API.signInWithIntuit().then((data) => {
            if ((typeof data != "undefined" || data != null) && !data.errors) {
                window.open(data.data, "_self");
            }
        });
    };

    render() {
        const { appName } = this.props;
        return (
            <div className="sessions fill-screen mg-session">
                <Helmet>
                    <title>{appName} | Success</title>
                </Helmet>
                <div className={`sessions-modal modal-space`}>
                    <div className="sessions-modal-logo-container centerd-content">
                        <Logo circular={true} />
                    </div>
                    <div style={{marginTop: "1rem"}}>
                        Unfortunately, Its gone for failure sso sign in.
                        Want to reconnect again. <p style={{marginTop: "1rem"}}>Click here
                        <a
                            onClick={(event) => {
                                this.signInWithIntuit()
                            }}
                        >
                            Sign In With Intuit
                        </a>
                        or <a href="/login" >Cancel</a></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SSOFailure;
