import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import ReactGA from "react-ga";
import Logo from "../../components/Logo";

// Components
import OnboardingHeader from "../../components/Onboarding/OnboardingHeader";
import SessionsModal from "../../components/Sessions/SessionsModal";

import { getZohoPagesense } from "../../utils/helpers";

class OnboardingCompletion extends Component {
  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/OnboardingCompletion");
  }

  render() {
    let { appName } = this.props;
    console.log(this.props);
    return (
      <div className='sessions fill-screen mg-session'>
        <Helmet>
          <title>{appName} | Account Created!</title>
        </Helmet>
        <SessionsModal optionalClassName='onboarding-completion'>
          <div className='sessions-modal-logo-container mg-logo-container'>
            <Logo userAgreement={true} />
          </div>
          <div className='sessions-modal-content'>
            {/* <div className="onboarding-completion-illustration" /> */}
            <h1 className='sessions-modal-heading' style={{ color: "#008bb2" }}>
              Done!
            </h1>
            <p>Your account has been successfully created!</p>
            {!this.props.user.is_onboarding_completed && (
              <Link
                className='cta mg-button'
                to='/welcome-screen'
                style={{ margin: "0em" }}
              >
                Complete your onboarding
              </Link>
            )}
            {this.props.user.client_admin &&
              this.props.user.is_onboarding_completed && (
                <Link
                  className='cta mg-button'
                  to='/client/dashboard'
                  style={{ margin: "0em" }}
                >
                  View My Account »
                </Link>
              )}
            {!this.props.user.client_admin &&
              this.props.user.is_onboarding_completed && (
                <Link
                  className='cta mg-button'
                  to='/admin/accounts'
                  style={{ margin: "0em" }}
                >
                  View My Account »
                </Link>
              )}
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default OnboardingCompletion;
