import React from "react";

const TruncatedName = ({ name }) => {
  return (
    <div className='truncated-name' title={name}>
      {name}
    </div>
  );
};

export default TruncatedName;
