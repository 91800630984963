import React, { Component } from "react";
import moment from "moment";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import Panel from "../../../components/App/Panel";
import {
  amountMask,
  percentMask,
  quantityMask,
  quantityMaskMax,
  getDollarAmount,
} from "../../../utils/helpers";
import InfoIcon from "../../../assets/images/quickbooks/ic_info_gray.png";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.isShow = true;
    this.state = {
      amountpaid: "",
      slowpayerpenalty: "",
      epd: "",
      balancedue: "",
    };
  }

  componentDidMount() {
    if (!!this.props.invoiceDetailsForEdit)
      this.showTaskDiscountDetails(
        this.props.invoiceDetailsForEdit.invoice_lines
      );
    // this.calculateBalance();
    this.calculateEarlyandSlowPay();
  }

  //get dollar amount in thousand format
  getThousandAmount = (value) => {
    if (value) return getDollarAmount(value);
    return "";
  };
  handleDiscountFocus = (event) => {
    event.stopPropagation();
    let { qbSetting } = this.props;
    let props = {
      title: "Warning!",
      message:
        "You can't apply Discount without enabling Discounts in your Company Preferences within QuickBooks.",
    };
    if (this.isShow && this.props.user.qb_connect && !qbSetting.allow_discount)
      this.props.updateModalState(true, "SUCCESS", props);
    this.isShow = false;
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "qty") {
      message = "Please enter Quantity";
    } else if (input === "total") {
      message =
        "For 'Inventory Items' Item Amount Total should only be in Multiples of 'Unit Cost/Hourly Rate'";
    } else if (input === "date") {
      message = "Please enter valid date";
    } else if (input === "rate") {
      message = "Please enter Unit Cost/Hourly Rate";
    } else if (input === "tax") {
      message = "Please enter Tax Rate";
    } else if (input === "discountAmount") {
      message = "Invoice Credit cannot be greater than the Invoice Total.";
    } else {
      message = "Please complete this field";
    }
    return (
      <div className='input-error-message mg-input-err-msg'>{message}</div>
    );
  }

  renderCreditItem = (creditItem, index) => {
    let { creditMemoDetails, creditMemoList } = this.props;
    let me = this;
    let creditMemoOptionList = [];
    creditMemoOptionList = [...creditMemoList];
    if (creditMemoDetails && creditMemoDetails.length > 0) {
      creditMemoDetails.forEach((creditMObj) => {
        creditMemoOptionList.forEach((creditMemoObj, index) => {
          if (creditMObj.amount.value == creditMemoObj.balance) {
            creditMemoOptionList.splice(index, 1);
          }
        });
      });
    }
    if (creditMemoOptionList && creditMemoOptionList.length > 0) {
      creditMemoOptionList.map((e, i) => {
        e.label = `#${e.credit_memo_number}${"    "}${"  |  "}${"    "}${
          e.value
        }`;
      });
    }

    return (
      <Panel>
        {creditMemoDetails.length > 0 && (
          <div
            className='close'
            onClick={() =>
              this.props.handleCrossClick("credit-memo-item", index)
            }
          />
        )}
        <div className='main-container'>
          <div className='half'>
            <label htmlFor='service'>
              Apply Invoice Credit (by Credit Memo)
            </label>
            <div
              className={`input-container term-select credit-memo-select ${
                creditItem.hasError ? " error" : ""
              }`}
            >
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                options={creditMemoOptionList}
                value={creditItem.amount.value}
                className='form-select'
                placeholder='Apply Invoice Credit'
                onChange={(e) => me.props.saveRemainingCredit(e, index)}
              />
            </div>
            {creditItem.hasError && (
              <div className='input-error-message service-error'>
                Please select Credit Amount
              </div>
            )}
          </div>

          <div
            className='half'
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className={`input-container invoice-fields ${
                creditItem.creditMemoNumber.hasError ? " error" : ""
              }`}
              style={{ margin: "0 10px" }}
            >
              <label htmlFor='creditMemoNumber'>Credit Memo # </label>
              <MaskedTextInput
                name='creditMemoNumber'
                mask={quantityMask}
                readOnly={true}
                class='disabled'
                value={creditItem.creditMemoNumber.value}
                onChange={(e) =>
                  this.props.handleCreditMemoDetailsChange(e, index)
                }
              />
              {creditItem.creditMemoNumber.hasError && (
                <div className='input-error-messages service-error'>
                  Credit Memo # will be partially applied to the invoice.
                </div>
              )}
            </div>
          </div>

          <div
            className='half'
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className={`input-container invoice-fields ${
                creditItem.amount.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='hours'>Amount </label>
              <MaskedTextInput
                name='amount'
                mask={quantityMask}
                readOnly={true}
                class='disabled'
                value={creditItem.amount.value}
                onChange={(e) =>
                  this.props.handleCreditMemoDetailsChange(e, index)
                }
              />
              {creditItem.amount.hasError && (
                <div className='input-error-messages service-error'>
                  Credit Memo will be partially applied to the invoice.
                </div>
              )}
            </div>
          </div>
        </div>
      </Panel>
    );
  };

  renderUnappliedPaymentItem = (unappliedPaymentItem, index) => {
    let { unappliedPaymentsDetails, unappliedPaymentsList } = this.props;
    let me = this;

    // Create a new array excluding the items already in unappliedPaymentsDetails
    let unappliedPaymentsOptionList = unappliedPaymentsList.filter(
      (paymentOption) => {
        return !unappliedPaymentsDetails.some((paymentDetail) => {
          return paymentDetail.id.value === paymentOption.id;
        });
      }
    );

    // Add labels to the filtered options
    unappliedPaymentsOptionList = unappliedPaymentsOptionList.map((e) => {
      return { ...e, label: `$${parseFloat(e.amount).toFixed(2)}` };
    });

    return (
      <Panel>
        {unappliedPaymentsDetails.length > 0 && (
          <div
            className='close'
            onClick={() => this.props.removeUnappliedPaymentField(index)}
          />
        )}
        <div className='main-container'>
          <div className='half'>
            <label htmlFor='service'>Apply Unapplied Payment</label>
            <div
              className={`input-container term-select unapplied-payment-select ${
                unappliedPaymentItem.hasError ? " error" : ""
              }`}
            >
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                options={unappliedPaymentsOptionList}
                value={unappliedPaymentItem.amount.value}
                className='form-select'
                placeholder='Apply Unapplied Payment'
                onChange={(e) => me.props.saveRemainingPayment(e, index)}
              />
            </div>
            {unappliedPaymentItem.hasError && (
              <div className='input-error-message service-error'>
                Please select Payment Amount
              </div>
            )}
          </div>

          <div
            className='half'
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className={`input-container invoice-fields ${
                unappliedPaymentItem.amount.hasError ? " error" : ""
              }`}
            >
              <label htmlFor='hours'>Amount </label>
              <MaskedTextInput
                name='amount'
                mask={amountMask}
                readOnly={true}
                placeholder='$0.00'
                class='disabled'
                value={unappliedPaymentItem.amount.value}
                onChange={(e) =>
                  this.props.handleUnappliedPaymentsDetailsChange(e, index)
                }
              />
              {unappliedPaymentItem.amount.hasError && (
                <div className='input-error-messages service-error'>
                  Payment will be partially applied to the invoice.
                </div>
              )}
            </div>
          </div>
        </div>
      </Panel>
    );
  };

  renderInvoiveItem = (invoiceItem, index) => {
    let { serviceList, invoiceDetails } = this.props;
    let isReadOnly = invoiceItem.service.value ? false : true;
    let isBlock =
      invoiceItem.data && invoiceItem.data["item_type"] == "Inventory"
        ? true
        : false;
    return (
      <Panel>
        {invoiceDetails.length > 1 && (
          <div
            className='close'
            onClick={() => this.props.handleCrossClick("invoice-item", index)}
          />
        )}
        <div className='main-container'>
          <div className='half'>
            <label htmlFor='service'>Goods/Services</label>
            <div
              className={`input-container client-select ${
                invoiceItem.service.hasError ? " error" : ""
              }`}
            >
              <Select
                inputProps={{
                  autoComplete: "none",
                  autoCorrect: "off",
                  spellCheck: "off",
                }}
                className='form-select'
                name='service'
                placeholder='Select Goods/Services'
                options={serviceList}
                value={invoiceItem.service.value}
                onChange={(e) => this.props.saveserviceDetails(e, index)}
                onInputChange={(e) =>
                  e ? this.props.clientServicesSearch(e, () => {}) : ""
                }
              />
              <div className='invoice-tax'>
                <input
                  name='taxable'
                  type='checkbox'
                  checked={invoiceItem.taxable.value}
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `1px`,
                  }}
                  onChange={() =>
                    this.props.handleInvoiceDetailsChange(
                      {
                        target: {
                          name: "taxable",
                          value: !invoiceItem.taxable.value,
                        },
                      },
                      index
                    )
                  }
                />
                <div htmlFor='tax'>Taxable</div>
              </div>
            </div>
            {invoiceItem.service.hasError && (
              <div className='input-error-message service-error'>
                Please select Goods/Services
              </div>
            )}

            <label htmlFor='itemDate'>Item Date</label>
            <div
              className={`input-container invoice-fields ${
                invoiceItem.itemDate.hasError ? " error" : ""
              }`}
            >
              <MaskedTextInput
                guide={true}
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                name='itemDate'
                placeholder='mm/dd/yyyy'
                type='text'
                readOnly={isReadOnly}
                value={invoiceItem.itemDate.value}
                onChange={(e) =>
                  this.props.handleInvoiceDetailsChange(e, index)
                }
              />
              {invoiceItem.itemDate.hasError && this.renderErrorMessage("date")}
            </div>
            <div className={`input-container invoice-fields`}>
              <label htmlFor='sedcription'>Description</label>
              <input
                name='description'
                placeholder='Enter description'
                type='text'
                readOnly={isReadOnly}
                value={invoiceItem.description.value}
                onChange={(e) =>
                  this.props.handleInvoiceDetailsChange(e, index)
                }
              />
            </div>
          </div>
          <div
            className='half'
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className='half'>
              <div
                className={`input-container invoice-fields ${
                  invoiceItem.hours.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='hours'>Quantity (Units/Hours)</label>
                {invoiceItem.service.value === "Inventory" ? (
                  <MaskedTextInput
                    name='hours'
                    placeholder='Enter Quantity'
                    mask={quantityMaskMax}
                    readOnly={isReadOnly}
                    value={invoiceItem.hours.value}
                    onChange={(e) =>
                      this.props.handleInvoiceDetailsChange(e, index)
                    }
                  />
                ) : (
                  <input
                    name='hours'
                    type='number'
                    placeholder='Enter Quantity'
                    readOnly={isReadOnly}
                    value={invoiceItem.hours.value}
                    onChange={(e) =>
                      this.props.handleInvoiceDetailsChange(e, index)
                    }
                  />
                )}
                {invoiceItem.hours.hasError && this.renderErrorMessage("qty")}
              </div>
              <div
                className={`input-container invoice-fields ${
                  invoiceItem.rate.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='rateprice'>Unit Cost/Hourly Rate</label>
                {invoiceItem.rate.value ? (
                  <MaskedTextInput
                    name='rate'
                    readOnly={isReadOnly}
                    mask={amountMask}
                    value={invoiceItem.rate.value}
                    autoFocus={
                      invoiceItem.rate.value.length <= 1 ? true : false
                    }
                    onChange={(e) =>
                      this.props.handleInvoiceDetailsChange(e, index)
                    }
                  />
                ) : (
                  <input
                    name='rate'
                    placeholder='Enter Unit Cost/Hourly Rate'
                    readOnly={isReadOnly}
                    value={invoiceItem.rate.value}
                    onChange={(e) =>
                      this.props.handleInvoiceDetailsChange(e, index)
                    }
                  />
                )}
                {invoiceItem.hours.hasError && this.renderErrorMessage("rate")}
              </div>
              <div className={`input-container invoice-fields`}>
                <label htmlFor='total'>Item Amount Total</label>
                <MaskedTextInput
                  name='total'
                  placeholder='$0.00'
                  mask={amountMask}
                  readOnly={isReadOnly}
                  value={invoiceItem.total.value}
                  onChange={(e) =>
                    this.props.handleInvoiceDetailsChange(e, index)
                  }
                />
                {invoiceItem.total.hasError && this.renderErrorMessage("total")}
              </div>
            </div>
            {isBlock ? (
              <div className='half'>
                <div className={`input-container invoice-fields`}>
                  <label htmlFor='hours'>Quantity on Hand</label>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <input
                      name='quantity_on_hand'
                      readOnly={true}
                      value={invoiceItem.data["quantity_on_hand"]}
                    />
                    {parseInt(invoiceItem.data["quantity_on_hand"]) <= 0 ? (
                      <input
                        name='item_reorder_point'
                        readOnly={true}
                        style={{ background: "red" }}
                        value={"Out of Stock"}
                      />
                    ) : parseInt(invoiceItem.data["quantity_on_hand"]) <=
                      parseInt(invoiceItem.data["item_reorder_point"]) ? (
                      <input
                        name='item_reorder_point'
                        readOnly={true}
                        style={{ background: "yellow" }}
                        value={"Low Stock"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Panel>
    );
  };

  getLinesJson = (item, type, flag, subTotal) => {
    let per =
      type == "Tax"
        ? item.discount_percent
        : (per = this.getDicountAndLateFeePercent(type, subTotal));

    return {
      key: `${type}${per ? "(" + per + "%)" : ""}`,
      percent: parseFloat(per ? per : 0),
      value: parseFloat(item.amount ? item.amount : 0),
      flag: flag,
    };
  };

  addMultipleTaxDiscount = (data, row, type) => {
    if (data && data.key) {
      let per = type == "Tax" ? data.percent + row.percent : row.percent;
      return {
        key: `${type}${per ? "(" + per + "%)" : ""}`,
        percent: per,
        value: data.value + row.value,
        flag: data.flag,
      };
    } else {
      return row;
    }
  };

  getDicountAndLateFeePercent = (type, subtotal) => {
    let { invoiceDetailsForEdit } = this.props;
    let tax_and_discount_line = invoiceDetailsForEdit.tax_and_discount_line;
    let value = 0;
    if (tax_and_discount_line.length) {
      tax_and_discount_line.map((item) => {
        if (type == "Discount" && item.detail_type === "DiscountLineDetail") {
          value += parseFloat(item.amount).toFixed(2);
        } else if (
          type == "Late Fee" &&
          item.detail_type === "SalesItemLineDetail"
        ) {
          value += parseFloat(item.amount).toFixed(2);
        }
      });
    }

    if (subtotal > 0 && value > 0)
      return parseFloat((value * 100) / subtotal).toFixed(2);
    return 0;
  };

  showTaskDiscountDetails = (data) => {
    let { invoiceDetailsForEdit } = this.props;
    let tax_and_discount_line = invoiceDetailsForEdit.tax_and_discount_line;
    let filterdData = [];
    let subTotal = 0;
    let taxData, discountData, lateFeeData;
    let total = 0;

    //calculate subtotal
    if (data && data.length) {
      data.map((item) => {
        if (
          item.detail_type === "SalesItemLineDetail" &&
          item.item_name &&
          !item.item_name.includes("Early Payer Dis") &&
          !item.item_name.includes("Slow Payer Penalty") &&
          !item.item_name.includes("Invoice Credit")
        ) {
          item.amount = parseFloat(item.amount);
          subTotal = subTotal + item.amount;
        }
      });
    }

    if (tax_and_discount_line && tax_and_discount_line.length) {
      tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          //Tax percent/amount
          taxData = this.addMultipleTaxDiscount(
            taxData,
            this.getLinesJson(item, "Tax", ""),
            "Tax"
          );
        } else if (item.detail_type === "DiscountLineDetail") {
          //Discount percent/amount
          discountData = this.addMultipleTaxDiscount(
            discountData,
            this.getLinesJson(item, "Discount", "-", subTotal),
            "Discount"
          );
        } else if (item.detail_type === "SalesItemLineDetail") {
          //late fee
          lateFeeData = this.addMultipleTaxDiscount(
            lateFeeData,
            this.getLinesJson(item, "Late Fee", "", subTotal),
            "Late Fee"
          );
        }
      });
    }

    //Add subtotal to final data
    filterdData.push({
      key: "SUBTOTAL",
      flag: "",
      value: subTotal.toFixed(2),
    });
    //add taxData to final data
    taxData
      ? filterdData.push(taxData)
      : filterdData.push(this.getLinesJson([], "Tax", ""));

    //add discountData to final data
    // discountData
    //   ? filterdData.push(discountData)
    //   : filterdData.push(this.getLinesJson([], "Invoice Credit", ""));
    let isInvoiceCredit = false;
    let invoiceCreditValue = 0;
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.invoice_lines &&
      invoiceDetailsForEdit.invoice_lines.length
    ) {
      invoiceDetailsForEdit.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes("Invoice Credit")) {
          invoiceCreditValue = parseFloat(Math.abs(line.amount));
          isInvoiceCredit = true;
        }
      });
    }

    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.payment_history &&
      invoiceDetailsForEdit.payment_history.length
    ) {
      invoiceDetailsForEdit.payment_history.map((txn) => {
        if (
          txn.payment_type &&
          txn.payment_type === "Credit Memo" &&
          txn.is_cancelled === false
        ) {
          invoiceCreditValue =
            invoiceCreditValue + parseFloat(Math.abs(txn.amount));
          isInvoiceCredit = true;
        }
      });
    }

    if (invoiceCreditValue) {
      filterdData.push({
        key: "Invoice Credit",
        value: parseFloat(invoiceCreditValue).toFixed(2), // parseFloat(discountData.value)
        flag: "-",
      });
    }
    // if(discountData && discountData.value && parseFloat(discountData.value) > 0){
    //   filterdData.push({
    //     key: "Invoice Credit",
    //     value: parseFloat(discountData.value).toFixed(2), // parseFloat(discountData.value)
    //     flag: "-"
    //   })
    // }

    //logic for Early payer discount
    let EarlyPayerDiscVal = 0.0;
    let epdsCT = 0;
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.invoice_lines &&
      invoiceDetailsForEdit.invoice_lines.length
    ) {
      invoiceDetailsForEdit.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes("Early Payer Dis")) {
          EarlyPayerDiscVal = parseFloat(line.amount);
          epdsCT++;
        }
      });
      let epdGCt = 0;
      if (
        invoiceDetailsForEdit.tax_and_discount_line &&
        invoiceDetailsForEdit.tax_and_discount_line.length
      ) {
        invoiceDetailsForEdit.tax_and_discount_line.map((line) => {
          if (line.item_name && line.item_name.includes("Discounts given")) {
            EarlyPayerDiscVal = parseFloat(line.amount);
            epdGCt++;
          }
        });
      }
      // if(EarlyPayerDiscVal !== 0 && epdsCT > 0){
      //   filterdData.push({
      //     key: "Early Payer Discount",
      //     value: parseFloat(finalEarlyPayerDisc).toFixed(2),
      //     flag: "",
      //   });
      // } else if (EarlyPayerDiscVal !== 0 && epdGCt > 0) {
      //   filterdData.push({
      //     key: "Early Payer Discount",
      //     value: parseFloat(EarlyPayerDiscVal).toFixed(2),
      //     flag: "-",
      //   });
      // }
    }

    //Slow Payer Panalty
    let SPPAmountV = 0.0;
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.invoice_lines &&
      invoiceDetailsForEdit.invoice_lines.length
    ) {
      invoiceDetailsForEdit.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes("Slow Payer Penalty")) {
          SPPAmountV = parseFloat(line.amount);
        }
      });
    }

    // logic to calculate Invoice total
    let taxA = taxData && taxData.value ? taxData.value : 0;
    let discountA = discountData && discountData.value ? discountData.value : 0;
    // let totolA = parseFloat(subTotal) + parseFloat(taxA) - parseFloat(discountA);
    let totolA = parseFloat(subTotal) + parseFloat(taxA);
    if (isInvoiceCredit) {
      totolA = totolA - parseFloat(invoiceCreditValue);
    }

    //add INVOICE TOTAL to final data
    filterdData.push({
      key: "INVOICE TOTAL",
      value: totolA.toFixed(2),
    });

    //add Amount Paid to final data
    let invoiceTotal =
      invoiceDetailsForEdit && invoiceDetailsForEdit.total_amount
        ? parseFloat(invoiceDetailsForEdit.total_amount)
        : 0;
    let balValue =
      invoiceDetailsForEdit && invoiceDetailsForEdit.balance
        ? parseFloat(invoiceDetailsForEdit.balance)
        : 0;
    let PaidValue =
      totolA.toFixed(2) - balValue + SPPAmountV - Math.abs(EarlyPayerDiscVal);
    if (PaidValue > 0) {
      filterdData.push({
        key: "Amount Paid",
        value: PaidValue.toFixed(2),
        flag: "-",
      });
    }

    //logic for Early payer discount
    let finalEarlyPayerDisc = 0.0;
    let sCT = 0;
    let targetDate;
    let earlyPayerInfoFlag = false;
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.invoice_lines &&
      invoiceDetailsForEdit.invoice_lines.length
    ) {
      invoiceDetailsForEdit.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes("Early Payer Dis")) {
          finalEarlyPayerDisc = parseFloat(line.amount);
          sCT++;
        }
      });
      let dGCt = 0;
      if (
        invoiceDetailsForEdit.tax_and_discount_line &&
        invoiceDetailsForEdit.tax_and_discount_line.length
      ) {
        invoiceDetailsForEdit.tax_and_discount_line.map((line) => {
          if (line.item_name && line.item_name.includes("Discounts given")) {
            finalEarlyPayerDisc = parseFloat(line.amount);
            dGCt++;
          }
        });
      }
      if (finalEarlyPayerDisc !== 0 && sCT > 0) {
        filterdData.push({
          key: "Early Payer Discount",
          value: parseFloat(finalEarlyPayerDisc).toFixed(2),
          flag: "",
        });
      } else if (finalEarlyPayerDisc !== 0 && dGCt > 0) {
        filterdData.push({
          key: "Early Payer Discount",
          value: parseFloat(finalEarlyPayerDisc).toFixed(2),
          flag: "-",
        });
      }
      if (sCT === 0) {
        if (
          invoiceDetailsForEdit &&
          invoiceDetailsForEdit.criteria_discount_days &&
          invoiceDetailsForEdit.criteria_discount_days > 0
        ) {
          let startdate = invoiceDetailsForEdit.date;
          // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
          let nn = moment(
            moment(startdate)
              .add(invoiceDetailsForEdit.criteria_discount_days, "d")
              .format("YYYY/MM/DD")
          );
          let today = new Date();
          targetDate = moment(nn);
          var month = today.getUTCMonth() + 1; //months from 1-12
          var day = today.getUTCDate();
          var year = today.getUTCFullYear();

          let newdate = year + "/" + month + "/" + day;
          let momentToday = moment(newdate);
          let diffNumber = targetDate.diff(momentToday, "days");
          if (diffNumber >= 0) {
            let value = 0.0;
            if (
              invoiceDetailsForEdit &&
              invoiceDetailsForEdit.criteria_discount_percent_based === true
            ) {
              value = parseFloat(invoiceDetailsForEdit.total_amount) / 100;
              value =
                value * parseFloat(invoiceDetailsForEdit.criteria_discount);
            } else if (
              invoiceDetailsForEdit &&
              invoiceDetailsForEdit.criteria_discount_percent_based === false
            ) {
              value = invoiceDetailsForEdit.criteria_discount;
            }
            earlyPayerInfoFlag = true;
            // filterdData.push({
            //   key: "Early Payer Discount",
            //   value: parseFloat(value).toFixed(2),
            //   flag: "",
            // });
          }
        }
      }
    }

    //add discountData to final data
    // discountData
    //   ? filterdData.push(discountData)
    //   : filterdData.push(this.getLinesJson([], "Early Payer Discount", ""));

    //add late fee to final data
    // lateFeeData
    //   ? filterdData.push(lateFeeData)
    //   : filterdData.push(this.getLinesJson([], "Late Fee", ""));

    //calculations for total based on subtotal, tax and discount
    total = subTotal;
    if (taxData && taxData.value) {
      total = total + taxData.value;
    }
    if (discountData && discountData.value) {
      total = total - discountData.value;
    }
    if (lateFeeData && lateFeeData.value) {
      total = total + lateFeeData.value;
    }

    //Slow Payer Panalty
    let SPPValue = 0.0;
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.invoice_lines &&
      invoiceDetailsForEdit.invoice_lines.length
    ) {
      invoiceDetailsForEdit.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes("Slow Payer Penalty")) {
          SPPValue = SPPValue + parseFloat(line.amount);
        }
      });
      if (SPPValue !== 0) {
        filterdData.push({
          key: "Slow Payer Penalty",
          value: parseFloat(SPPValue).toFixed(2),
          flag: "",
        });
      }
    }

    filterdData.push({
      key: "BALANCE DUE",
      value: invoiceDetailsForEdit.balance,
      flag: "",
    });

    if (filterdData && filterdData.length > 0) {
      filterdData.map((e, i) => {
        if (e.key === "Amount Paid") {
          this.setState({
            // amountpaid: `$${e.flag}${e.value}`,
            amountpaid: getDollarAmount(e.value),
          });
        }
        if (e.key === "Slow Payer Penalty") {
          // this.setState({
          //   // slowpayerpenalty: `$${e.flag}${e.value}`,
          //   slowpayerpenalty: getDollarAmount(e.value),
          // });
        }
        if (e.key === "BALANCE DUE") {
          this.setState({
            // balancedue: `$${e.flag}${e.value}`,
            balancedue: getDollarAmount(e.value),
          });
        }
      });
    }

    if (earlyPayerInfoFlag && !(PaidValue.toFixed(2) > 0)) {
      if (invoiceDetailsForEdit.criteria_discount_percent_based) {
        let epd = getDollarAmount(
          (parseFloat(invoiceDetailsForEdit.total_amount) *
            parseFloat(invoiceDetailsForEdit.criteria_discount)) /
            100
        );
        // this.setState({
        //   epd: epd,
        // });
      } else {
        let epd = getDollarAmount(invoiceDetailsForEdit.criteria_discount);
        // this.setState({
        //   epd: epd,
        // });
      }
    } else {
      if (filterdData && filterdData.length > 0) {
        filterdData.map((e, i) => {
          if (e.key === "Early Payer Discount") {
            // this.setState({
            //   // epd: `$${e.flag}${e.value}`,
            //   epd: getDollarAmount(e.value),
            // });
          }
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    // Check if any relevant prop has changed
    if (
      prevProps.invoiceSubTotal !== this.props.invoiceSubTotal ||
      prevProps.taxableSubTotal !== this.props.taxableSubTotal ||
      prevProps.unappliedPaymentsDetails !==
        this.props.unappliedPaymentsDetails ||
      prevProps.initialCreditMemoChange !==
        this.props.initialCreditMemoChange ||
      prevProps.creditMemoDetails !== this.props.creditMemoDetails ||
      prevProps.isEdit !== this.props.isEdit
    ) {
      this.calculateEarlyandSlowPay();
    }
  }

  calculateEarlyandSlowPay() {
    const {
      invoiceDetailsForEdit,
      isEdit,
      invoiceDetails,
      invoiceSubTotal,
      taxableSubTotal,
      creditMemoDetails,
      unappliedPaymentsDetails,
    } = this.props;
    let value;
    // Calculate tax in dollar
    const taxInDollar =
      (taxableSubTotal * invoiceSubTotal.taxRate.value) / 100 || 0;

    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.date &&
      invoiceDetailsForEdit.criteria_discount_days
    ) {
      const startDate = moment(invoiceDetailsForEdit.date, "YYYY-MM-DD");
      const targetDate = startDate.add(
        invoiceDetailsForEdit.criteria_discount_days,
        "days"
      );

      const today = moment().startOf("day"); // Current date without time
      const diffNumber = targetDate.diff(today, "days");

      if (diffNumber >= 0) {
        if (
          invoiceDetailsForEdit &&
          invoiceDetailsForEdit.criteria_discount_percent_based
        ) {
          let effectiveValue = parseFloat(invoiceSubTotal.total.value); //992.50
          if (parseFloat(invoiceSubTotal.appliedCreditMemoAmount.value)) {
            effectiveValue =
              effectiveValue +
              parseFloat(invoiceSubTotal.appliedCreditMemoAmount.value); // 50
          }
          if (taxInDollar) {
            value = parseFloat((effectiveValue - taxInDollar) / 100); //92.50
          } else {
            value = parseFloat(effectiveValue / 100);
          }
          value =
            parseFloat(value) *
            parseFloat(invoiceDetailsForEdit.criteria_discount);
        } else if (
          invoiceDetailsForEdit &&
          invoiceDetailsForEdit.criteria_discount_percent_based === false
        ) {
          value = invoiceDetailsForEdit.criteria_discount;
        }
      }
    }
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.early_payer_discount_amount &&
      invoiceDetailsForEdit.early_payer_discount_amount > 0
    ) {
      let amountPaid =
        invoiceDetailsForEdit && parseFloat(invoiceDetailsForEdit.amount_paid);
      this.setState({
        epd: amountPaid > 0 ? "" : getDollarAmount(parseFloat(value)),
      });
    }

    let slowPayerAmount;
    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.criteria_late_fee_percent_based &&
      parseFloat(invoiceDetailsForEdit.criteria_late_fee)
    ) {
      let tempVal = parseFloat(invoiceSubTotal.total.value);
      // if (
      //   parseFloat(invoiceSubTotal.discountAmount.value) ||
      //   parseFloat(invoiceSubTotal.appliedCreditMemoAmount.value)
      // ) {
      //   if (parseFloat(invoiceSubTotal.discountAmount.value)) {
      //     tempVal = tempVal - parseFloat(invoiceSubTotal.discountAmount.value);
      //   } else {
      //     tempVal =
      //       tempVal + parseFloat(invoiceSubTotal.appliedCreditMemoAmount.value);
      //   }
      // }

      if (parseFloat(invoiceSubTotal.appliedCreditMemoAmount.value)) {
        tempVal =
          tempVal + parseFloat(invoiceSubTotal.appliedCreditMemoAmount.value);
      }
      if (taxInDollar) {
        tempVal = tempVal - taxInDollar;
      } else {
        tempVal = tempVal;
      }

      slowPayerAmount = tempVal / 100 || 0;
      slowPayerAmount =
        slowPayerAmount * invoiceDetailsForEdit.criteria_late_fee;
    } else if (
      invoiceDetailsForEdit &&
      !invoiceDetailsForEdit.criteria_late_fee_percent_based &&
      parseFloat(invoiceDetailsForEdit.criteria_late_fee)
    ) {
      slowPayerAmount =
        parseFloat(invoiceDetailsForEdit.total_amount) +
        parseFloat(invoiceDetailsForEdit.criteria_late_fee);
    }

    if (
      invoiceDetailsForEdit &&
      invoiceDetailsForEdit.slow_payer_amount &&
      invoiceDetailsForEdit.slow_payer_amount > 0
    ) {
      // if (!this.isApplySlowPayer()) {
      //   this.setState({
      //     slowpayerpenalty: getDollarAmount(
      //       invoiceDetailsForEdit.slow_payer_amount
      //     ),
      //   });
      // } else {
      this.setState({
        slowpayerpenalty: getDollarAmount(slowPayerAmount),
      });
      // }
    }

    this.calculateBalance();
  }

  isApplySlowPayer() {
    const { invoiceDetailsForEdit } = this.props;
    let isApply = true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let dueDateObj;
    if (invoiceDetailsForEdit && invoiceDetailsForEdit.due_date) {
      dueDateObj = new Date(invoiceDetailsForEdit.due_date);
      if (
        dueDateObj < today &&
        parseFloat(invoiceDetailsForEdit.slow_payer_amount) > 0
      ) {
        isApply = false;
      }
    }
    return isApply;
  }

  calculateBalance() {
    const {
      isEdit,
      invoiceDetails,
      invoiceSubTotal,
      taxableSubTotal,
      creditMemoDetails,
      unappliedPaymentsDetails,
      invoiceDetailsForEdit,
    } = this.props;
    const { slowpayerpenalty } = this.state;
    // Helper function to parse dollar values and handle empty or invalid values
    const parseDollarValue = (value) => {
      if (typeof value !== "string") return 0;

      let number;
      if (value.includes("$")) {
        // Remove dollar sign and commas before parsing
        number = parseFloat(value.replace(/[$,]/g, ""));
      } else {
        // Remove only commas if no dollar sign is present
        number = parseFloat(value.replace(/,/g, ""));
      }

      return isNaN(number) ? 0 : number;
    };

    // Calculate unapplied amount
    const unappliedAmount = unappliedPaymentsDetails
      ? unappliedPaymentsDetails
          .map((item) => parseDollarValue(item.amount.value))
          .reduce((acc, curr) => acc + curr, 0)
      : 0;

    // Calculate credit memo amount
    const creditMemoAmount = creditMemoDetails
      ? creditMemoDetails
          .map((item) => parseDollarValue(item.amount.value))
          .reduce((acc, curr) => acc + curr, 0)
      : 0;

    // Calculate tax in dollar
    const taxInDollar =
      (taxableSubTotal * invoiceSubTotal.taxRate.value) / 100 || 0;

    // Calculate balance due based on whether we are in edit mode
    let balanceDue;
    if (!isEdit) {
      balanceDue =
        parseDollarValue(invoiceSubTotal.subTotal.value) +
        taxInDollar -
        creditMemoAmount -
        unappliedAmount;
    } else {
      //early payer , slow payer
      let amountPaid = parseDollarValue(this.state.amountpaid);
      let earlyPayerDiscount = 0;
      let slowPayerPenalty = 0;
      const appliedCreditMemoAmount =
        invoiceSubTotal.appliedCreditMemoAmount.value;
      let subTotalValue = parseDollarValue(invoiceSubTotal.subTotal.value);
      earlyPayerDiscount = parseDollarValue(this.state.epd);
      slowPayerPenalty = parseDollarValue(slowpayerpenalty);

      balanceDue =
        subTotalValue +
        taxInDollar -
        (creditMemoAmount + appliedCreditMemoAmount) -
        unappliedAmount -
        amountPaid -
        earlyPayerDiscount +
        slowPayerPenalty;
    }

    // Apply discount if it exists
    if (parseFloat(invoiceSubTotal.discountAmount.value) > 0) {
      const discountAmount = parseDollarValue(
        invoiceSubTotal.discountAmount.value
      );
      balanceDue -= discountAmount;
    }
    balanceDue = isNaN(balanceDue) ? 0 : balanceDue;
    if (balanceDue) {
      // this.setState({
      //   balancedue:
      //     balanceDue < 0 ? "0.00" : getDollarAmount(String(balanceDue)),
      // });
    }
    return balanceDue < 0 ? "0.00" : getDollarAmount(String(balanceDue));
  }

  render() {
    let {
      isEdit,
      qbSetting,
      invoiceDiscountType,
      invoiceLateFeesType,
      invoiceDetails,
      invoiceSubTotal,
      taxableSubTotal,
      from,
      creditMemoDetails,
      creditMemoList,
      unappliedPaymentsDetails,
      unappliedPaymentsList,
    } = this.props;

    let checkForTax =
      !isEdit &&
      qbSetting &&
      qbSetting.using_sales_tax &&
      invoiceSubTotal.taxRate.value > 0
        ? true
        : false;

    let taxInDollar = (taxableSubTotal * invoiceSubTotal.taxRate.value) / 100;
    // this.calculateBalance();

    return (
      <div className='add-invoice-inner-panel'>
        {this.props.headerContent(
          this.props && this.props.headerText
            ? this.props.headerText
            : "Invoice Details"
        )}
        {invoiceDetails && invoiceDetails.length
          ? invoiceDetails.map((invoiceItem, index) => {
              return this.renderInvoiveItem(invoiceItem, index);
            })
          : null}
        {this.props.innerButtons(
          <button
            onClick={this.props.handleAddItemClick}
            className='inner-btn mg-brand2-color'
          >
            Add More Items
          </button>
        )}
        <div className='main-container'>
          <div className='half right-algn total-section'>
            <div className={`input-container invoice-fields`}>
              <label htmlFor='subtotal'>Invoice Subtotal</label>
              <input
                name='subtotal'
                readOnly
                className='disabled'
                placeholder='$0.00'
                value={
                  invoiceSubTotal.subTotal.value
                    ? this.getThousandAmount(invoiceSubTotal.subTotal.value)
                    : ""
                }
              />
            </div>
            <div className='inline-input'>
              <div
                className={`input-container invoice-fields inner-left ${
                  invoiceSubTotal.taxRate.hasError ? " error" : ""
                }`}
              >
                <label htmlFor='taxrate'>Tax Rate ( % )</label>
                <MaskedTextInput
                  type='text'
                  name='taxRate'
                  placeholder='Enter Tax Rate'
                  mask={percentMask}
                  readOnly={checkForTax}
                  value={invoiceSubTotal.taxRate.value}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value.replace("%", "")) > 0 &&
                      parseFloat(e.target.value.replace("%", "")) <= 100
                    ) {
                      this.props.handleInvoiceSubTotalChange(e);
                    } else {
                      e.target.value = "";
                    }
                  }}
                />
                {invoiceSubTotal.taxRate.hasError &&
                  this.renderErrorMessage("tax")}
              </div>
              <div className={`input-container invoice-fields inner-right`}>
                <label htmlFor='taxrate'>Tax Amount</label>
                <input
                  placeholder='$0.00'
                  readOnly
                  className='disabled'
                  value={taxInDollar ? this.getThousandAmount(taxInDollar) : ""}
                />
              </div>
            </div>
            {!from || from !== "credit_memo" ? (
              <div
                className='inline-input'
                style={{ alignItems: "flex-start" }}
              >
                <div className={`input-container invoice-fields inner-left `}>
                  {
                    // <div style={{display:invoiceSubTotal.appliedCreditMemoAmount.value!=""?"block":"none"}}>
                    <div>
                      <label
                        className='label-with-checkbox lwc-phone'
                        htmlFor='discount'
                      >
                        <span>
                          {/* Apply Discount to Invoice */}
                          Apply Invoice Credit
                          <img
                            src={InfoIcon}
                            className='info-tooltip'
                            title='Entering a discount here applies an immediate discount to the invoice that is not based on the Discount Terms.'
                          />
                        </span>
                        {this.props.renderCheckBoxes(
                          invoiceDiscountType,
                          this.props.handleInvoiceDiscountTypeChange
                        )}
                      </label>
                      <MaskedTextInput
                        type='text'
                        name='discount'
                        // placeholder="Enter Discount"
                        placeholder='Enter Invoice Credit Percentage'
                        readOnly={
                          this.props.user.qb_connect &&
                          !qbSetting.allow_discount
                            ? true
                            : invoiceDiscountType == "DOLLAR"
                        }
                        className={`${
                          invoiceDiscountType === "DOLLAR" ? " disabled" : ""
                        }`}
                        mask={percentMask}
                        onFocus={this.handleDiscountFocus}
                        value={invoiceSubTotal.discount.value}
                        onChange={(e) => {
                          if (
                            parseFloat(e.target.value.replace("%", "")) > 0 &&
                            parseFloat(e.target.value.replace("%", "")) <= 100
                          ) {
                            this.props.handleInvoiceSubTotalChange(e);
                          } else {
                            e.target.value = "";
                          }
                        }}
                      />
                    </div>
                  }
                </div>
                <div
                  className={`input-container invoice-fields inner-right ${
                    invoiceSubTotal.discountAmount.hasError ? " error" : ""
                  }`}
                >
                  {
                    //  <div style={{display:invoiceSubTotal.appliedCreditMemoAmount.value!=""?"block":"none"}}>
                    <div>
                      <label htmlFor='discount'>Invoice Credit Amount</label>
                      {invoiceDiscountType == "DOLLAR" ? (
                        <MaskedTextInput
                          name='discountAmount'
                          placeholder='$0.00'
                          readOnly={
                            this.props.user.qb_connect &&
                            !qbSetting.allow_discount
                              ? true
                              : invoiceDiscountType != "DOLLAR"
                          }
                          mask={amountMask}
                          onFocus={this.handleDiscountFocus}
                          value={invoiceSubTotal.discountAmount.value}
                          onBlur={() =>
                            this.props.calculateDiscountLateFeeAmount(
                              "discountAmount"
                            )
                          }
                          onChange={this.props.handleInvoiceSubTotalChange}
                        />
                      ) : (
                        <input
                          readOnly={true}
                          placeholder='$0.00'
                          className={`${
                            invoiceDiscountType != "DOLLAR" ? " disabled" : ""
                          }`}
                          value={this.getThousandAmount(
                            invoiceSubTotal.discountAmount.value
                          )}
                        />
                      )}
                      {invoiceSubTotal.discountAmount.hasError &&
                        this.renderErrorMessage("discountAmount")}
                    </div>
                  }
                </div>
              </div>
            ) : null}
            <div className='add-invoice-inner-panel'>
              {creditMemoList && creditMemoList.length > 0 ? (
                <div className=''>
                  {creditMemoDetails && creditMemoDetails.length
                    ? creditMemoDetails.map((creditItem, index) => {
                        return this.renderCreditItem(creditItem, index);
                      })
                    : null}
                  {this.props.innerButtons(
                    <button
                      onClick={this.props.handleAddCreditMemoClick}
                      className='inner-btn mg-brand2-color'
                    >
                      Apply Another Credit Memo
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
              {isEdit && (!from || from !== "credit_memo") ? (
                <div
                  className={`input-container invoice-fields inner-left `}
                  style={{ alignItems: "flex-start" }}
                >
                  {
                    // <div style={{display:invoiceSubTotal.appliedCreditMemoAmount.value!=""?"block":"none"}}>

                    <div>
                      <label
                        className='label-with-checkbox lwc-phone'
                        htmlFor='discount'
                      >
                        <div>Applied Invoice Credit (by Credit Memo)</div>
                      </label>
                      <div className='input-container invoice-fields inner-left'>
                        <MaskedTextInput
                          type='text'
                          name='appliedCreditAmount'
                          // placeholder="Enter Discount"
                          placeholder='Applied Invoice Credit'
                          readOnly={true}
                          className={`disabled`}
                          mask={amountMask}
                          // onFocus={this.handleDiscountFocus}
                          value={invoiceSubTotal.appliedCreditMemoAmount.value}
                          style={{
                            width: "calc(100% - 8px)",
                            marginTop: "10px",
                          }}
                        />
                      </div>
                    </div>
                  }
                </div>
              ) : null}
            </div>
            <div className='add-invoice-inner-panel'>
              {unappliedPaymentsList && unappliedPaymentsList.length > 0 && (
                <div className=''>
                  {unappliedPaymentsDetails &&
                    unappliedPaymentsDetails.map(
                      (unappliedPaymentItem, index) =>
                        this.renderUnappliedPaymentItem(
                          unappliedPaymentItem,
                          index
                        )
                    )}
                  {this.props.innerButtons(
                    <button
                      onClick={() => this.props.addEmptyUnappliedPaymentField()}
                      className='inner-btn mg-brand2-color'
                    >
                      Apply Another Unapplied Payment
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='inner-bottom-border' />
        <div className='half total-section'>
          <div className={`input-container invoice-fields right-algn`}>
            <label htmlFor='total'>Total</label>
            <input
              name='total'
              placeholder='$0.00'
              className='disabled'
              readOnly
              value={
                invoiceSubTotal.total.value
                  ? this.getThousandAmount(invoiceSubTotal.total.value)
                  : ""
              }
            />
          </div>
        </div>

        <div className='half total-section'>
          <div className='inline-input'>
            <div className={`input-container invoice-fields inner-left `}>
              <label htmlFor='amountpaid'>Amount Paid</label>
              <input
                name='amountpaid'
                placeholder='$0.00'
                className='disabled'
                readOnly
                value={this.state.amountpaid}
              />
            </div>
            <div className={`input-container invoice-fields inner-right`}>
              <label htmlFor='slowpayerpenalty'>Slow Payer Penalty</label>
              <input
                name='slowpayerpenalty'
                placeholder='$0.00'
                className='disabled'
                readOnly
                value={this.state.slowpayerpenalty}
              />
            </div>
          </div>

          <div className='inline-input'>
            <div className={`input-container invoice-fields inner-left`}>
              <label htmlFor='epd'>Early Payer Discount</label>
              <input
                name='epd'
                placeholder='$0.00'
                className='disabled'
                readOnly
                value={this.state.epd}
              />
            </div>
            <div className={`input-container invoice-fields inner-right`}>
              <label htmlFor='balancedue'>Balance Due</label>
              <input
                name='balancedue'
                placeholder='$0.00'
                className='disabled'
                readOnly
                value={this.calculateBalance()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceDetails;
