import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

// Components
import ObjectHistoryRow from './ObjectHistoryRow';
import ObjectHistoryHeader from './ObjectHistoryHeader';


class ObjectHistoryList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    appName: PropTypes.string
  }

  renderOptionalColumnHeadings(headings) {
    if (headings && headings.length > 0) {
      return (
        <ObjectHistoryHeader
          headings={headings}
        />
      )
    }
  }

  render() {
    let { data, rowActionClick, optionalClasses, hideScroll } = this.props;
    return (
      <div className={`object-debt-list${optionalClasses ? ' ' + optionalClasses : ''}`}>
        {this.renderOptionalColumnHeadings(data.headings)}
        {
          <div ref={this.props.scrollRef} className={`${hideScroll ? '' : "scrollbar"}`} style={hideScroll ? {height: 'auto'} : { height: window.innerHeight * 0.65 , paddingBottom: 50}}> {
            data.columns.map((row) => {
              return (
                <ObjectHistoryRow
                  key={shortid.generate()}
                  rowData={row}
                  appName={this.props.appName}
                  handleRowActionClick={(event) => rowActionClick(event)}
                  action={(event) => this.props.rowCheckListHandler(event)}
                />
              )
            })
          }
          </div>
        }
      </div>
    );
  }
}

export default ObjectHistoryList;
