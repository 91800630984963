import $ from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import React, { Component } from "react";
import moment from "moment";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import * as API from "../../../../utils/api";
import {
  amountMask,
  getStrippedDollarAmount,
  quantityMask,
  percentMask,
  isValidDate,
  getFormattedDateYYYYMMDD,
} from "../../../../utils/helpers";
import AppCanvasLoader from "../../AppCanvasLoader";
import Modal from "../Modal";
import MaskedTextInput from "react-text-mask";
import FlashMessage from "../../../FlashMessage";
import Emitter from "../../../../utils/event-emitter";

class CreatePaymentPlanModal extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isLoading: false,
    dropdownIsActive: false,
    markAsAccepted: false,
    radioApplyDiscount: false,
    radioDiscountPercentage: true,
    radioDiscountAmount: false,
    radioMonthSelected: true,
    radioWeekSelected: false,
    paymentAmountError: false,
    paymentPeriodError: false,
    paymentDueDateError: false,
    paymentPeriodMathError: false,
    isCreditMemoApplied: false,
    isCreditMemoWindow: false,
    saveAndSend: false,
    paymentPlanInformation: {
      numberOfInvoices: {
        value: "",
        error: false,
      },
      paymentPlanAmount: {
        value: "",
        error: false,
      },
      discountPercent: {
        value: "",
        error: false,
      },
      discountAmount: {
        value: "",
        error: false,
      },
      installmentPeriod: {
        value: "",
        error: false,
      },
      paymentAmount: {
        value: "",
        error: false,
      },
      paymentDueDate: {
        value: "",
        error: false,
      },
    },
    consumer: {},
    debts: [],
    isAmtValueUpdated: false,
    isDiscountAmtUpdated: false,
  };

  componentWillMount() {
    let { consumerId, invoiceLength, multipleInvoiceData } =
      this.props.optionalProps;
    this.setState((state) => ({
      ...state,
      paymentPlanInformation: {
        ...state.paymentPlanInformation,
        numberOfInvoices: {
          value: invoiceLength,
          error: false,
        },
        paymentPlanAmount: {
          value: parseFloat(multipleInvoiceData.invoiceBalanceAmt).toFixed(2),
          error: false,
        },
      },
    }));
    this.getConsumerData();
  }
  componentDidMount() {}

  handleModalClose = () => {
    this.props.hideModal();
  };
  handleModalCloseBackToPaymentPlans = () => {
    this.props.hideModal();
    this.props.optionalProps.handleBacktoPaymentPlansList();
  };
  toggleDropdown = (event) => {
    event.preventDefault();
    this.setState({
      dropdownIsActive: !this.state.dropdownIsActive,
    });
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  checkradioMarkAsAccepted = (e) => {
    let { markAsAccepted } = this.state;
    this.setState({
      markAsAccepted: !markAsAccepted,
    });
  };
  checkradioApplyDiscount = (e) => {
    let { radioApplyDiscount } = this.state;
    let { multipleInvoiceData } = this.props.optionalProps;
    this.setState((state) => ({
      ...state,
      radioApplyDiscount: !radioApplyDiscount,
      paymentPlanInformation: {
        ...state.paymentPlanInformation,
        discountPercent: {
          value: "",
          error: false,
        },
        discountAmount: {
          value: "",
          error: false,
        },
        paymentPlanAmount: {
          value: parseFloat(multipleInvoiceData.invoiceBalanceAmt).toFixed(2),
          error: false,
        },
      },
    }));
  };
  checkradioDiscountPercentage = (e) => {
    let { radioDiscountPercentage } = this.state;
    this.setState({
      radioDiscountPercentage: !radioDiscountPercentage,
    });
  };
  checkradioradioDiscountAmount = (e) => {
    let { radioDiscountAmount } = this.state;
    this.setState({
      radioDiscountAmount: !radioDiscountAmount,
    });
  };
  checkRadioMonthSelected = (e) => {
    let { radioMonthSelected } = this.state;
    this.setState({
      radioMonthSelected: !radioMonthSelected,
    });
  };
  checkradioWeekSelected = (e) => {
    let { radioWeekSelected } = this.state;
    this.setState({
      radioWeekSelected: !radioWeekSelected,
    });
  };

  checkIspercent = (type) => {
    let { paymentPlanInformation } = this.state;
    let { multipleInvoiceData } = this.props.optionalProps;

    if (type === "is_percent") {
      this.setState((state) => ({
        ...state,
        radioDiscountPercentage: true,
        radioDiscountAmount: false,
      }));
      this.handleInputChange({
        paymentPlanAmount: {
          value: parseFloat(
            multipleInvoiceData.invoiceBalanceAmt.toFixed(2)
          ).toFixed(2),
          error: false,
        },
        discountAmount: {
          value: "",
          error: false,
        },
        discountPercent: {
          value: "",
          error: false,
        },
      });
    } else if (type === "is_amount") {
      this.setState((state) => ({
        ...state,
        radioDiscountPercentage: false,
        radioDiscountAmount: true,
      }));
      this.handleInputChange({
        paymentPlanAmount: {
          value: parseFloat(
            multipleInvoiceData.invoiceBalanceAmt.toFixed(2)
          ).toFixed(2),
          error: false,
        },
        discountAmount: {
          value: "",
          error: false,
        },
        discountPercent: {
          value: "",
          error: false,
        },
      });
    } else if (type === "is_month") {
      this.setState((state) => ({
        ...state,
        radioMonthSelected: true,
        radioWeekSelected: false,
      }));
      this.handleInputChange({
        installmentPeriod: {
          value: "",
          error: false,
        },
      });
    } else if (type === "is_week") {
      this.setState((state) => ({
        ...state,
        radioMonthSelected: false,
        radioWeekSelected: true,
      }));
      this.handleInputChange({
        installmentPeriod: {
          value: "",
          error: false,
        },
      });
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      paymentPlanInformation: {
        ...state.paymentPlanInformation,
        ...newPartialInput,
      },
    }));
  }

  getDateFormat = (date) => {
    // let tempdate = new Date(date).toISOString().slice(0, 10);
    let tempdate = new Date(date).getTime();
    return tempdate;
  };
  validateData = () => {
    let {
      numberOfInvoices,
      paymentPlanAmount,
      discountAmount,
      discountPercent,
      installmentPeriod,
      paymentAmount,
      paymentDueDate,
    } = this.state.paymentPlanInformation;
    let { radioApplyDiscount, radioDiscountPercentage, radioDiscountAmount } =
      this.state;
    let flag = true;
    if (
      numberOfInvoices.value === "" ||
      numberOfInvoices.value === null ||
      numberOfInvoices.value === undefined
    ) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          numberOfInvoices: {
            value: numberOfInvoices.value,
            error: true,
          },
        },
      }));
      flag = false;
    }
    if (
      paymentPlanAmount.value === "" ||
      paymentPlanAmount.value === null ||
      paymentPlanAmount.value === undefined
    ) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          paymentPlanAmount: {
            value: paymentPlanAmount.value,
            error: true,
          },
        },
      }));
      flag = false;
    }
    if (
      radioApplyDiscount &&
      radioDiscountPercentage &&
      (discountPercent.value === "" ||
        discountPercent.value === null ||
        discountPercent.value === undefined)
    ) {
      this.handleInputChange({
        discountPercent: {
          value: "",
          error: true,
        },
      });
      flag = false;
    }
    if (
      radioApplyDiscount &&
      radioDiscountAmount &&
      (discountAmount.value === "" ||
        discountAmount.value === null ||
        discountAmount.value === undefined)
    ) {
      this.handleInputChange({
        discountAmount: {
          value: "",
          error: true,
        },
      });
      flag = false;
    }
    if (
      installmentPeriod.value === "" ||
      installmentPeriod.value === null ||
      installmentPeriod.value === undefined
    ) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          installmentPeriod: {
            value: installmentPeriod.value,
            error: true,
          },
        },
      }));
      flag = false;
    }
    if (
      parseFloat(installmentPeriod.value) >
      parseFloat(
        paymentPlanAmount.value.trim().replace("$", "").replace(",", "")
      ).toFixed(2)
    ) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          installmentPeriod: {
            value: installmentPeriod.value,
            error: true,
          },
        },
      }));
      flag = false;
    }
    if (
      paymentAmount.value === "" ||
      paymentAmount.value === null ||
      paymentAmount.value === undefined
    ) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          paymentAmount: {
            value: "",
            error: true,
          },
        },
      }));
      flag = false;
    }
    if (
      paymentDueDate.value === "" ||
      paymentDueDate.value === null ||
      paymentDueDate.value === undefined
    ) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          paymentDueDate: {
            value: "",
            error: true,
          },
        },
      }));
      flag = false;
    } else if (!isValidDate(paymentDueDate.value)) {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          paymentDueDate: {
            value: "",
            error: true,
          },
        },
      }));
      this.setState((state) => ({
        ...state,
        paymentDueDateError: true,
      }));
      flag = false;
    } else {
      let now = new Date();
      if (
        this.getDateFormat(paymentDueDate.value) <
        this.getDateFormat(moment(now).format("MM/DD/YYYY"))
      ) {
        this.setState((state) => ({
          ...state,
          paymentDueDateError: true,
        }));
        this.setState((state) => ({
          ...state,
          paymentPlanInformation: {
            ...state.paymentPlanInformation,
            paymentDueDate: {
              value: "",
              error: false,
            },
          },
        }));
        flag = false;
      } else {
        this.setState((state) => ({
          ...state,
          paymentDueDateError: false,
        }));
      }
    }

    return flag;
  };

  formulatePaymentPlan(value, type) {
    let { multipleInvoiceData } = this.props.optionalProps;
    let { paymentPlanInformation } = this.state;
    let discount = 0.0;
    if (type === "discountPercent" && value !== "" && value !== null) {
      discount = parseFloat(
        value.trim().replace("%", "").replace(",", "")
      ).toFixed(2);
      this.handleInputChange({
        paymentPlanAmount: {
          value: (
            parseFloat(multipleInvoiceData.invoiceBalanceAmt.toFixed(2)) -
            (parseFloat(multipleInvoiceData.invoiceBalanceAmt.toFixed(2)) *
              discount) /
              100
          ).toFixed(2),
          error: false,
        },
      });
    } else if (type === "discountPercent") {
      this.handleInputChange({
        paymentPlanAmount: {
          value: parseFloat(
            multipleInvoiceData.invoiceBalanceAmt.toFixed(2)
          ).toFixed(2),
          error: false,
        },
        installmentPeriod: {
          value: "",
          error: false,
        },
        paymentAmount: {
          value: "",
          error: false,
        },
      });
    }
    if (type === "discountAmount" && value !== "" && value !== null) {
      discount = parseFloat(
        value.trim().replace("$", "").replace(",", "")
      ).toFixed(2);
      this.handleInputChange({
        paymentPlanAmount: {
          value: (
            parseFloat(multipleInvoiceData.invoiceBalanceAmt.toFixed(2)) -
            discount
          ).toFixed(2),
          error: false,
        },
      });
      this.setState((state) => ({
        ...state,
        paymentAmountError: false,
        paymentPeriodError: false,
        paymentPeriodMathError: false,
      }));
    } else if (type === "discountAmount") {
      this.handleInputChange({
        paymentPlanAmount: {
          value: parseFloat(
            multipleInvoiceData.invoiceBalanceAmt.toFixed(2)
          ).toFixed(2),
          error: false,
        },
        installmentPeriod: {
          value: "",
          error: false,
        },
        paymentAmount: {
          value: "",
          error: false,
        },
      });
      this.setState((state) => ({
        ...state,
        paymentAmountError: false,
        paymentPeriodError: false,
        paymentPeriodMathError: false,
      }));
    }
    if (
      type === "installmentPeriod" &&
      value !== "" &&
      value !== null &&
      parseInt(value) <=
        parseFloat(
          paymentPlanInformation.paymentPlanAmount.value
            .trim()
            .replace("$", "")
            .replace(",", "")
        ).toFixed(2) &&
      Math.ceil(
        parseFloat(
          parseFloat(
            paymentPlanInformation.paymentPlanAmount.value
              .trim()
              .replace("$", "")
              .replace(",", "")
          ).toFixed(2) / parseInt(value)
        )
      ) >= 1
    ) {
      if (parseInt(value) === 1) {
        this.handleInputChange({
          paymentAmount: {
            value:
              "$" +
              `${parseFloat(
                paymentPlanInformation.paymentPlanAmount.value
                  .trim()
                  .replace("$", "")
                  .replace(",", "")
              ).toFixed(2)}`,
            error: false,
          },
        });
      } else {
        if (
          parseInt(value) *
            Math.ceil(
              parseFloat(
                parseFloat(
                  paymentPlanInformation.paymentPlanAmount.value
                    .trim()
                    .replace("$", "")
                    .replace(",", "")
                ).toFixed(2) / parseInt(value)
              )
            ) >
          parseFloat(
            paymentPlanInformation.paymentPlanAmount.value
              .trim()
              .replace("$", "")
              .replace(",", "")
          ).toFixed(2)
        ) {
          this.handleInputChange({
            installmentPeriod: {
              value: Math.ceil(
                parseFloat(
                  paymentPlanInformation.paymentPlanAmount.value
                    .trim()
                    .replace("$", "")
                    .replace(",", "")
                ).toFixed(2) /
                  Math.ceil(
                    parseFloat(
                      parseFloat(
                        paymentPlanInformation.paymentPlanAmount.value
                          .trim()
                          .replace("$", "")
                          .replace(",", "")
                      ).toFixed(2) / parseInt(value)
                    )
                  )
              ),
              error: false,
            },
            paymentAmount: {
              value:
                "$" +
                `${parseFloat(
                  Math.ceil(
                    parseFloat(
                      parseFloat(
                        paymentPlanInformation.paymentPlanAmount.value
                          .trim()
                          .replace("$", "")
                          .replace(",", "")
                      ).toFixed(2) / parseInt(value)
                    )
                  )
                ).toFixed(2)}`,
              error: false,
            },
          });
        } else {
          this.handleInputChange({
            paymentAmount: {
              value:
                "$" +
                parseFloat(
                  Math.ceil(
                    parseFloat(
                      parseFloat(
                        paymentPlanInformation.paymentPlanAmount.value
                          .trim()
                          .replace("$", "")
                          .replace(",", "")
                      ).toFixed(2) / parseInt(value)
                    )
                  )
                ).toFixed(2),
              error: false,
            },
          });
        }
      }
      this.setState((state) => ({
        ...state,
        paymentAmountError: false,
        paymentPeriodError: false,
        paymentPeriodMathError: false,
      }));
    } else if (
      type === "installmentPeriod" &&
      (value === "" || value === null)
    ) {
      if (
        !(
          parseInt(value) <=
          parseFloat(
            paymentPlanInformation.paymentPlanAmount.value
              .trim()
              .replace("$", "")
              .replace(",", "")
          ).toFixed(2)
        )
      ) {
        this.setState((state) => ({
          ...state,
          paymentPeriodError: true,
          paymentAmountError: false,
          paymentPeriodMathError: false,
        }));
      } else {
        this.handleInputChange({
          paymentAmount: {
            value: "",
            error: false,
          },
          installmentPeriod: {
            value: "",
            error: true,
          },
        });
        this.setState((state) => ({
          ...state,
          paymentPeriodError: false,
          paymentAmountError: false,
          paymentPeriodMathError: false,
        }));
      }

      if (
        Math.ceil(
          !parseFloat(
            parseFloat(
              paymentPlanInformation.paymentPlanAmount.value
                .trim()
                .replace("$", "")
                .replace(",", "")
            ).toFixed(2) / parseInt(value)
          )
        ) >= 1
      ) {
        this.handleInputChange({
          paymentAmount: {
            value: "",
            error: false,
          },
          installmentPeriod: {
            value: "",
            error: false,
          },
        });
        this.setState((state) => ({
          ...state,
          paymentPeriodError: false,
          paymentAmountError: false,
          paymentPeriodMathError: true,
        }));
      } else {
        this.handleInputChange({
          paymentAmount: {
            value: "",
            error: false,
          },
          installmentPeriod: {
            value: "",
            error: false,
          },
        });
        this.setState((state) => ({
          ...state,
          paymentPeriodError: false,
          paymentAmountError: false,
          paymentPeriodMathError: false,
        }));
      }
    } else {
      this.setState((state) => ({
        ...state,
        paymentPlanInformation: {
          ...state.paymentPlanInformation,
          installmentPeriod: {
            value: "",
            error: true,
          },
        },
      }));
    }
    if (
      type === "paymentAmount" &&
      value !== "" &&
      value !== null &&
      parseFloat(value) <=
        parseFloat(
          paymentPlanInformation.paymentPlanAmount.value
            .trim()
            .replace("$", "")
            .replace(",", "")
        ).toFixed(2)
    ) {
      let installmentPeriodChange = parseFloat(
        parseFloat(
          paymentPlanInformation.paymentPlanAmount.value
            .trim()
            .replace("$", "")
            .replace(",", "")
        ).toFixed(2) / parseFloat(value)
      ).toFixed(2);
      this.handleInputChange({
        installmentPeriod: {
          value: Math.ceil(installmentPeriodChange),
          error: false,
        },
      });
      this.setState((state) => ({
        ...state,
        paymentPeriodError: false,
        paymentAmountError: false,
      }));
    } else if (
      type === "paymentAmount" &&
      value !== "" &&
      value !== null &&
      parseFloat(value) >
        parseFloat(paymentPlanInformation.paymentPlanAmount.value).toFixed(2)
    ) {
      this.handleInputChange({
        paymentAmount: {
          value: "",
          error: true,
        },
        installmentPeriod: {
          value: "",
          error: false,
        },
      });
      this.setState((state) => ({
        ...state,
        paymentPeriodError: false,
        paymentAmountError: true,
      }));
    }
  }

  renderErrorMessage(input) {
    let message = "";
    if (input === "numberOfInvoices") {
      message = "Please check count of selected invoices.";
    } else if (input === "paymentPlanAmount") {
      message = "Please enter a valid payment plan amount.";
    } else if (input === "discountAmount") {
      message = "Please enter a valid discount amount.";
    } else if (input === "discountPercent") {
      message = "Please enter a valid discount percentage.";
    } else if (input === "installmentPeriod") {
      message = "Please enter a valid # of months/ weeks.";
    } else if (input === "paymentAmount") {
      message = "Please enter a valid payment amount.";
    } else if (input === "paymentAmountError") {
      message =
        "Payment amount should be less than or equal to payment plan amount.";
    } else if (input === "paymentPeriodError") {
      message =
        "# of months/ Weeks should be less than or equal to payment plan amount.";
    } else if (input === "paymentPeriodMathError") {
      message = "The payment amount should be greater than or equal to $1.";
    } else if (input === "paymentDueDate") {
      message = "Please enter a valid payment due date.";
    } else if (input === "paymentDueDateError") {
      message =
        "Backdating is not allowed, Please enter a valid payment due date.";
    } else {
      message = "Please complete this field.";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  handleSubmit = (flag) => {
    const { consumer } = this.state;
    const { optionalProps, user } = this.props;
    let { consumerId, invoiceLength } = this.props.optionalProps;
    let {
      markAsAccepted,
      radioApplyDiscount,
      radioDiscountPercentage,
      radioDiscountAmount,
      radioMonthSelected,
      saveAndSend,
      paymentPlanInformation,
    } = this.state;
    let payload = {
      user_id: optionalProps.multipleInvoiceData.accountId,
      number_of_payments: paymentPlanInformation.installmentPeriod.value,
      start_date: getFormattedDateYYYYMMDD(
        paymentPlanInformation.paymentDueDate.value
      ),
      description: "",
      is_accepted: markAsAccepted,
      is_months_plan: radioMonthSelected,
      payment_plan_amount: parseFloat(
        paymentPlanInformation.paymentPlanAmount.value
          .trim()
          .replace("$", "")
          .replace(",", "")
      ).toFixed(2),
      monthly_payment_amount: parseFloat(
        `${paymentPlanInformation.paymentAmount.value}`
          .trim()
          .replace("$", "")
          .replace(",", "")
      ).toFixed(2),
      discount_amount:
        radioApplyDiscount && radioDiscountPercentage
          ? (
              parseFloat(
                optionalProps.multipleInvoiceData.invoiceBalanceAmt
              ).toFixed(2) -
              parseFloat(
                paymentPlanInformation.paymentPlanAmount.value
                  .trim()
                  .replace("$", "")
                  .replace(",", "")
              ).toFixed(2)
            ).toFixed(2)
          : radioApplyDiscount && radioDiscountAmount
          ? parseFloat(
              paymentPlanInformation.discountAmount.value
                .trim()
                .replace("$", "")
                .replace(",", "")
            )
          : 0,

      invoice_ids: optionalProps.multipleInvoiceData.invoiceIds,
      save_and_send: flag,
    };
    let tempConsumer = { ...consumer };
    tempConsumer.user_id = consumer.id;
    let props = {
      user: user,
      consumerId: tempConsumer.id,
      invoiceDetails: { user: tempConsumer },
      isMultipleInvoices: false,
      isPaymentPlanModel: true,
      multipleInvoiceData: this.getCheckedInvoiceList,
      closeOpenInvoiceView: this.closeOpenInvoiceView,
      payload: payload,
      handleModalClose: this.handleModalClose,
      handleModalCloseBackToPaymentPlans:
        this.handleModalCloseBackToPaymentPlans,
    };

    if (this.validateData()) {
      if (flag === true) {
        if (radioApplyDiscount) {
          this.setState((state) => ({
            ...state,
            isCreditMemoWindow: true,
            saveAndSend: true,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isCreditMemoWindow: false,
            saveAndSend: true,
          }));
          this.props.updateModalState(true, "SEND_NEW_MAIL", props);
        }
      } else {
        if (radioApplyDiscount) {
          this.setState((state) => ({
            ...state,
            isCreditMemoWindow: true,
            saveAndSend: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isCreditMemoWindow: false,
            saveAndSend: false,
          }));
          this.createPaymentPlanCall(false);
        }
      }
    }
  };

  handleCreditMemoWindow = (flag, saveSend) => {
    const { consumer } = this.state;
    const { optionalProps, user } = this.props;
    let { consumerId, invoiceLength } = this.props.optionalProps;
    let {
      markAsAccepted,
      radioApplyDiscount,
      radioDiscountPercentage,
      radioDiscountAmount,
      radioMonthSelected,
      saveAndSend,
      paymentPlanInformation,
    } = this.state;
    let payload = {
      user_id: optionalProps.multipleInvoiceData.accountId,
      number_of_payments: paymentPlanInformation.installmentPeriod.value,
      start_date: getFormattedDateYYYYMMDD(
        paymentPlanInformation.paymentDueDate.value
      ),
      description: "",
      is_accepted: markAsAccepted,
      is_months_plan: radioMonthSelected,
      payment_plan_amount: parseFloat(
        paymentPlanInformation.paymentPlanAmount.value
          .trim()
          .replace("$", "")
          .replace(",", "")
      ).toFixed(2),
      monthly_payment_amount: parseFloat(
        `${paymentPlanInformation.paymentAmount.value}`
          .trim()
          .replace("$", "")
          .replace(",", "")
      ).toFixed(2),
      discount_amount:
        radioApplyDiscount && radioDiscountPercentage
          ? (
              parseFloat(
                optionalProps.multipleInvoiceData.invoiceBalanceAmt
              ).toFixed(2) -
              parseFloat(
                paymentPlanInformation.paymentPlanAmount.value
                  .trim()
                  .replace("$", "")
                  .replace(",", "")
              ).toFixed(2)
            ).toFixed(2)
          : radioApplyDiscount && radioDiscountAmount
          ? parseFloat(
              paymentPlanInformation.discountAmount.value
                .trim()
                .replace("$", "")
                .replace(",", "")
            )
          : 0,

      is_credit_memo_applied: flag,
      invoice_ids: optionalProps.multipleInvoiceData.invoiceIds,
      save_and_send: saveAndSend,
    };
    let multipleInvoiceData = this.getCheckedInvoiceList();
    let tempConsumer = { ...consumer };
    tempConsumer.user_id = consumer.id;
    let props = {
      user: user,
      consumerId: optionalProps.consumerId,
      invoiceDetails: { user: tempConsumer },
      isMultipleInvoices: false,
      isPaymentPlanModel: true,
      multipleInvoiceData: multipleInvoiceData,
      closeOpenInvoiceView: this.closeOpenInvoiceView,
      payload: payload,
      handleModalClose: this.handleModalClose,
      handleModalCloseBackToPaymentPlans:
        this.handleModalCloseBackToPaymentPlans,
    };
    if (this.state.saveAndSend) {
      this.props.updateModalState(true, "SEND_NEW_MAIL", props);
    } else {
      this.createPaymentPlanCall(flag);
      this.handleModalCloseBackToPaymentPlans();
    }
  };

  getCheckedInvoiceList = () => {
    const { debts } = this.state;
    let invoiceIdsOBJ = {
      invoiceListArray: [],
      invoiceListSTR: "",
    };
    if (debts && debts.length) {
      debts.map((ele, index) => {
        if (ele.selectionCheck) {
          invoiceIdsOBJ.invoiceListArray.push(
            parseInt(ele.debt_invoice ? ele.debt_invoice.id : ele.id)
          );
          invoiceIdsOBJ.invoiceListSTR =
            invoiceIdsOBJ.invoiceListSTR !== ""
              ? invoiceIdsOBJ.invoiceListSTR +
                ", " +
                ele.debt_invoice.invoice_number
              : ele.debt_invoice.invoice_number;
        }
      });
    }
    return invoiceIdsOBJ;
  };
  getConsumerData = (reload, isSort) => {
    let { multipleInvoiceData } = this.props.optionalProps;
    reload = true;
    this.setState({
      isTableLoading: true,
      isPaymentPlansSelected: false,
      listLoader: true,
      showLoader: true,
    });
    let sort_by = "asc";
    let filterBy = "open";
    let page = 0;
    const accountId = this.props.match.params.id;
    API.getClientsConsumer(
      this.props.user,
      multipleInvoiceData.accountId,
      sort_by,
      page,
      filterBy
    ).then((data) => {
      let isAccessDenied =
        data && data.status_code && data.status_code == 401 ? true : false;
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if ((typeof data != "undefined" || data != null) && !isAccessDenied) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          consumer: data.account.consumer,
          debts: data.account.debts,
          isLoading: false,
          isTableLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          showLoader: false,
          isLoading: false,
          isTableLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          listLoader: false,
          showLoader: false,
          isLoading: false,
        }));
      }
    });
  };

  createPaymentPlanCall(flag) {
    let { consumerId, invoiceLength, multipleInvoiceData } =
      this.props.optionalProps;
    let {
      markAsAccepted,
      radioApplyDiscount,
      radioDiscountPercentage,
      radioDiscountAmount,
      radioMonthSelected,
      saveAndSend,
      paymentPlanInformation,
    } = this.state;
    let payload = {
      user_id: multipleInvoiceData.accountId,
      number_of_payments: paymentPlanInformation.installmentPeriod.value,
      start_date: getFormattedDateYYYYMMDD(
        paymentPlanInformation.paymentDueDate.value
      ),
      description: "",
      is_accepted: markAsAccepted,
      is_months_plan: radioMonthSelected,
      payment_plan_amount: parseFloat(
        paymentPlanInformation.paymentPlanAmount.value
          .trim()
          .replace("$", "")
          .replace(",", "")
      ).toFixed(2),
      monthly_payment_amount: parseFloat(
        `${paymentPlanInformation.paymentAmount.value}`
          .trim()
          .replace("$", "")
          .replace(",", "")
      ).toFixed(2),
      discount_amount:
        radioApplyDiscount && radioDiscountPercentage
          ? (
              parseFloat(multipleInvoiceData.invoiceBalanceAmt).toFixed(2) -
              parseFloat(
                paymentPlanInformation.paymentPlanAmount.value
              ).toFixed(2)
            ).toFixed(2)
          : radioApplyDiscount && radioDiscountAmount
          ? parseFloat(
              paymentPlanInformation.discountAmount.value
                .trim()
                .replace("$", "")
                .replace(",", "")
            )
          : 0,

      is_credit_memo_applied: flag,
      invoice_ids: multipleInvoiceData.invoiceIds,
      save_and_send: saveAndSend,
    };
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    API.createPaymentPlan(multipleInvoiceData.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.handleModalClose();
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
          title: "SUCCESS",
        });
      } else if (
        (data && data.status_code == 400) ||
        data.status_code == 500 ||
        data.status_code == 404
      ) {
        this.handleModalClose();
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
          title: "Error!",
        });
      } else {
        this.handleModalClose();
        this.props.updateModalState(true, "ERROR", {
          message: "Access denied.",
          title: "Error!",
        });
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          isLoading: false,
        }));
      }
      this.props.optionalProps.handleBacktoPaymentPlansList();
    });
  }

  render() {
    let {
      input,
      error,
      isLoading,
      paymentPlanInformation,
      paymentAmountError,
      paymentPeriodError,
      paymentDueDateError,
      paymentPeriodMathError,
    } = this.state;
    let { multipleInvoiceData } = this.props.optionalProps;
    let styels = { maxWidth: "100%", width: "100%", marginBottom: 0 };
    return (
      <>
        {this.state.isCreditMemoWindow ? (
          <div
            className='confirm-modal'
            style={{ minWidth: "30em", minHeight: "15em" }}
          >
            <div className='modal-msg'>
              This payment plan has a discount applied. When a discount is
              applied to a payment plan MakeGood can automatically create a
              Credit Memo to ensure the discount applied to the invoices in this
              payment plan is properly reconciled to your accounting system. The
              Credit Memo is automatically applied to the oldest invoice first.
              Would you like to have the Credit Memo created?
            </div>
            <div className='confirm-btns'>
              <button
                className='yes-btn'
                onClick={(event) => {
                  this.handleCreditMemoWindow(true);
                }}
              >
                YES
              </button>
              <button
                className='cancel-btn'
                onClick={() => {
                  this.handleCreditMemoWindow(false);
                }}
              >
                NO
              </button>
            </div>
          </div>
        ) : (
          <Modal
            optionalClasses='add-account-modal send-new-email-modal scroll'
            title='Create Payment Plan'
            closeOnClickOutside={true}
            hideModal={this.handleModalCloseBackToPaymentPlans}
          >
            {isLoading ? (
              <AppCanvasLoader />
            ) : (
              <>
                <form
                  className='admin-form'
                  onClick={(event) => {
                    this.handleClickOutside(event);
                  }}
                >
                  <div className='app-sidebar-layout-canvas'>
                    <div
                      className='app-sidebar app-sidebar-left'
                      style={styels}
                    >
                      <div>
                        <div className='admin-form-row'>
                          <div
                            className={`input-modal-container`}
                            style={{
                              width: "100%",
                            }}
                          >
                            <label htmlFor='text'># of Invoices In Plan:</label>
                            <input
                              name='name'
                              guide={"true"}
                              placeholder='Number of invoices'
                              type='text'
                              value={
                                paymentPlanInformation.numberOfInvoices.value
                              }
                            />
                            {paymentPlanInformation.numberOfInvoices.error
                              ? this.renderErrorMessage("numberOfInvoices")
                              : null}
                          </div>
                        </div>
                        <div className='admin-form-row'>
                          <div
                            className={`input-modal-container`}
                            // ${input.email.hasError ? " error" : ""}
                            style={{
                              width: "100%",
                            }}
                          >
                            <label htmlFor='text'>Payment Plan Amount:</label>
                            <MaskedTextInput
                              style={{
                                color: "black",
                                borderBottomColor: "black",
                                fontSize: "1.2em",
                              }}
                              disabled
                              mask={amountMask}
                              placeholder='Payment Plan Amount ($)'
                              value={
                                paymentPlanInformation.paymentPlanAmount.value
                              }
                            />
                            {paymentPlanInformation.paymentPlanAmount.error
                              ? this.renderErrorMessage("paymentPlanAmount")
                              : null}
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: "12px",
                          }}
                        >
                          <div className='admin-form-row'>
                            <div
                              className={`input-modal-container ${
                                false ? " error" : ""
                              }`}
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <input
                                  type='checkbox'
                                  style={{
                                    appearance: "auto",
                                    height: "20px",
                                    width: "15px",
                                    marginRight: "12px",
                                  }}
                                  checked={this.state.radioApplyDiscount}
                                  onClick={(event) =>
                                    this.checkradioApplyDiscount(event)
                                  }
                                />
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    color: "#252525",
                                    fontFamily: "sofia_pro_bold",
                                    fontSize: "1em",
                                  }}
                                >
                                  Apply Discount?
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='admin-form-row'>
                            {this.state.radioApplyDiscount ? (
                              <div
                                style={{
                                  float: "left",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "40%",
                                  }}
                                >
                                  <input
                                    type='radio'
                                    style={{
                                      height: "10px",
                                      width: "15px",
                                      appearance: "auto",
                                      marginRight: "12px",
                                    }}
                                    name='addDiscount'
                                    id='addDiscountPer'
                                    checked={this.state.radioDiscountPercentage}
                                    onClick={() => {
                                      this.checkIspercent("is_percent");
                                    }}
                                  />
                                  <span
                                    htmlFor='addDiscountPer'
                                    style={{
                                      color: "#252525",
                                      fontFamily: "sofia_pro_bold",
                                      fontSize: "1em",
                                    }}
                                  >
                                    Discount Percent (%)
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "230px",
                                  }}
                                >
                                  &nbsp;Or&nbsp;&nbsp;&nbsp;&nbsp;
                                  <input
                                    type='radio'
                                    style={{
                                      height: "10px",
                                      width: "15px",
                                      appearance: "auto",
                                      marginRight: "12px",
                                    }}
                                    id='addDiscountAmt'
                                    name='addDiscount'
                                    checked={this.state.radioDiscountAmount}
                                    onClick={() => {
                                      this.checkIspercent("is_amount");
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#252525",
                                      fontFamily: "sofia_pro_bold",
                                      fontSize: "1em",
                                    }}
                                    htmlFor='addDiscountAmt'
                                  >
                                    Discount Amount ($)
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className='admin-form-row'>
                            {this.state.radioApplyDiscount &&
                            this.state.radioDiscountPercentage ? (
                              <MaskedTextInput
                                style={{
                                  color: this.state.radioApplyDiscount
                                    ? "black"
                                    : "grey",
                                  borderBottomColor: this.state
                                    .radioApplyDiscount
                                    ? "black"
                                    : "gray",
                                  fontSize: "1em",
                                }}
                                mask={
                                  this.state.radioApplyDiscount &&
                                  this.state.radioDiscountPercentage
                                    ? percentMask
                                    : amountMask
                                }
                                name='paymentPlanDiscount'
                                disabled={!this.state.radioApplyDiscount}
                                placeholder='Discount Percent (%)'
                                // placeholderChar={"\u2000"}
                                type='text'
                                checked={this.state.radioDiscountPercentage}
                                value={
                                  paymentPlanInformation.discountPercent.value
                                }
                                onChange={(event) => {
                                  if (
                                    parseFloat(
                                      event.target.value
                                        .trim()
                                        .replace("%", "")
                                        .replace(",", "")
                                    ) > 0 &&
                                    parseFloat(
                                      event.target.value
                                        .trim()
                                        .replace("%", "")
                                        .replace(",", "")
                                    ) <= 100
                                  ) {
                                    this.handleInputChange({
                                      discountPercent: {
                                        value: event.target.value,
                                        error: false,
                                      },
                                    });
                                    this.formulatePaymentPlan(
                                      event.target.value,
                                      "discountPercent"
                                    );
                                  } else {
                                    this.handleInputChange({
                                      discountPercent: {
                                        value: "",
                                        error: true,
                                      },
                                      paymentPlanAmount: {
                                        value: parseFloat(
                                          multipleInvoiceData.invoiceBalanceAmt.toFixed(
                                            2
                                          )
                                        ).toFixed(2),
                                        error: false,
                                      },
                                    });
                                  }
                                }}
                              />
                            ) : this.state.radioApplyDiscount &&
                              this.state.radioDiscountAmount ? (
                              <MaskedTextInput
                                style={{
                                  color: this.state.radioApplyDiscount
                                    ? "black"
                                    : "grey",
                                  borderBottomColor: this.state
                                    .radioApplyDiscount
                                    ? "black"
                                    : "gray",
                                  fontSize: "1em",
                                }}
                                mask={
                                  this.state.radioApplyDiscount &&
                                  this.state.radioDiscountAmount
                                    ? amountMask
                                    : percentMask
                                }
                                disabled={!this.state.radioApplyDiscount}
                                checked={this.state.radioDiscountAmount}
                                name='paymentPlanDiscount'
                                placeholder='Discount Amount ($)'
                                placeholderChar={"\u2000"}
                                type='text'
                                value={
                                  paymentPlanInformation.discountAmount.value
                                }
                                onChange={(event) => {
                                  if (
                                    parseFloat(
                                      event.target.value
                                        .trim()
                                        .replace("$", "")
                                        .replace(",", "")
                                    ) > 0 &&
                                    parseFloat(
                                      event.target.value
                                        .trim()
                                        .replace("$", "")
                                        .replace(",", "")
                                    ) <=
                                      parseFloat(
                                        paymentPlanInformation.paymentPlanAmount.value
                                          .trim()
                                          .replace("$", "")
                                          .replace(",", "")
                                      ).toFixed(2)
                                  ) {
                                    this.setState((state) => ({
                                      ...state,
                                      isDiscountAmtUpdated: true,
                                    }));
                                    this.handleInputChange({
                                      discountAmount: {
                                        value: event.target.value,
                                        error: false,
                                      },
                                    });
                                    this.formulatePaymentPlan(
                                      event.target.value,
                                      "discountAmount"
                                    );
                                  } else {
                                    this.handleInputChange({
                                      discountAmount: {
                                        value: "",
                                        error: true,
                                      },
                                      paymentPlanAmount: {
                                        value: parseFloat(
                                          multipleInvoiceData.invoiceBalanceAmt.toFixed(
                                            2
                                          )
                                        ).toFixed(2),
                                        error: false,
                                      },
                                    });
                                  }
                                }}
                                onBlur={() => {
                                  if (this.state.isDiscountAmtUpdated) {
                                    this.handleInputChange({
                                      discountAmount: {
                                        value: parseFloat(
                                          `${paymentPlanInformation.discountAmount.value}`
                                            .trim()
                                            .replace("$", "")
                                            .replace(",", "")
                                        ).toFixed(2),
                                        error: false,
                                      },
                                    });
                                    this.setState((state) => ({
                                      ...state,
                                      isDiscountAmtUpdated: false,
                                    }));
                                  }
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          {this.state.radioApplyDiscount &&
                          this.state.radioDiscountAmount &&
                          paymentPlanInformation.discountAmount.error
                            ? this.renderErrorMessage("discountAmount")
                            : null}
                          {this.state.radioApplyDiscount &&
                          this.state.radioDiscountPercentage &&
                          paymentPlanInformation.discountPercent.error
                            ? this.renderErrorMessage("discountPercent")
                            : null}
                        </div>
                        <div
                          style={{
                            marginBottom: "12px",
                          }}
                        >
                          <div className='admin-form-row'>
                            <div
                              className={`input-modal-container ${
                                false ? " error" : ""
                              }`}
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    color: "#252525",
                                    fontFamily: "sofia_pro_bold",
                                    fontSize: "1em",
                                  }}
                                >
                                  # of Months / Weeks:
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='admin-form-row'>
                            <div
                              style={{
                                float: "left",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "40%",
                                }}
                              >
                                <input
                                  type='radio'
                                  style={{
                                    height: "10px",
                                    width: "15px",
                                    appearance: "auto",
                                    marginRight: "12px",
                                  }}
                                  name='addMonth'
                                  id='addMonth'
                                  checked={this.state.radioMonthSelected}
                                  onClick={() => {
                                    this.checkIspercent("is_month");
                                  }}
                                />
                                <span
                                  htmlFor='addMonth'
                                  style={{
                                    color: "#252525",
                                    fontFamily: "sofia_pro_bold",
                                    fontSize: "1em",
                                  }}
                                >
                                  # Of Months
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "230px",
                                }}
                              >
                                &nbsp;Or&nbsp;&nbsp;&nbsp;&nbsp;
                                <input
                                  type='radio'
                                  style={{
                                    height: "10px",
                                    width: "15px",
                                    appearance: "auto",
                                    marginRight: "12px",
                                  }}
                                  id='addWeeks'
                                  name='addWeeks'
                                  checked={this.state.radioWeekSelected}
                                  onClick={() => {
                                    this.checkIspercent("is_week");
                                  }}
                                />
                                <span
                                  style={{
                                    color: "#252525",
                                    fontFamily: "sofia_pro_bold",
                                    fontSize: "1em",
                                  }}
                                  htmlFor='addMonth'
                                >
                                  # Of Weeks
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='admin-form-row'>
                            {this.state.radioMonthSelected ? (
                              <MaskedTextInput
                                style={{
                                  color: "black",
                                  borderBottomColor: "black",
                                  fontSize: "1em",
                                }}
                                mask={quantityMask}
                                name='paymentPlanMonths'
                                placeholder='# of Months'
                                // placeholderChar={"\u2000"}
                                type='text'
                                checked={this.state.radioMonthSelected}
                                value={
                                  paymentPlanInformation.installmentPeriod.value
                                }
                                onChange={(event) => {
                                  if (parseInt(event.target.value) > 0) {
                                    this.handleInputChange({
                                      installmentPeriod: {
                                        value: parseInt(event.target.value),
                                        error: false,
                                      },
                                    });
                                    this.formulatePaymentPlan(
                                      event.target.value,
                                      "installmentPeriod"
                                    );
                                  } else {
                                    this.handleInputChange({
                                      installmentPeriod: {
                                        value: "",
                                        error: true,
                                      },
                                      paymentAmount: {
                                        value: "",
                                        error: false,
                                      },
                                    });
                                  }
                                }}
                              />
                            ) : this.state.radioWeekSelected ? (
                              <MaskedTextInput
                                style={{
                                  color: "black",
                                  borderBottomColor: "black",
                                  fontSize: "1em",
                                }}
                                mask={quantityMask}
                                checked={this.state.radioWeekSelected}
                                name='paymentPlanWeeks'
                                placeholder='# of Weeks'
                                placeholderChar={"\u2000"}
                                type='text'
                                value={
                                  paymentPlanInformation.installmentPeriod.value
                                }
                                onChange={(event) => {
                                  if (parseInt(event.target.value) > 0) {
                                    this.handleInputChange({
                                      installmentPeriod: {
                                        value: parseInt(event.target.value),
                                        error: false,
                                      },
                                    });
                                    this.formulatePaymentPlan(
                                      event.target.value,
                                      "installmentPeriod"
                                    );
                                  } else {
                                    this.handleInputChange({
                                      installmentPeriod: {
                                        value: "",
                                        error: true,
                                      },
                                      paymentAmount: {
                                        value: "",
                                        error: false,
                                      },
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          {paymentPlanInformation.installmentPeriod.error
                            ? this.renderErrorMessage("installmentPeriod")
                            : null}
                          {paymentPeriodError
                            ? this.renderErrorMessage("paymentPeriodError")
                            : null}
                        </div>
                        <div className='admin-form-row'>
                          <div
                            className={`input-modal-container`}
                            style={{
                              width: "100%",
                            }}
                          >
                            <label htmlFor='text'>Payment Amount:</label>
                            <MaskedTextInput
                              style={{
                                color: "black",
                                borderBottomColor: "black",
                                fontSize: "1.2em",
                              }}
                              type='text'
                              mask={amountMask}
                              placeholder='Payment Amount ($)'
                              value={paymentPlanInformation.paymentAmount.value}
                              onChange={(event) => {
                                if (
                                  parseInt(
                                    event.target.value
                                      .trim()
                                      .replace("$", "")
                                      .replace(",", "")
                                  ) > 0
                                ) {
                                  this.setState((state) => ({
                                    ...state,
                                    isAmtValueUpdated: true,
                                  }));
                                  this.handleInputChange({
                                    paymentAmount: {
                                      value: event.target.value
                                        .trim()
                                        .replace("$", "")
                                        .replace(",", ""),
                                      error: false,
                                    },
                                  });
                                  this.formulatePaymentPlan(
                                    event.target.value
                                      .trim()
                                      .replace("$", "")
                                      .replace(",", ""),
                                    "paymentAmount"
                                  );
                                } else {
                                  this.handleInputChange({
                                    installmentPeriod: {
                                      value: "",
                                      error: false,
                                    },
                                    paymentAmount: {
                                      value: "",
                                      error: false,
                                    },
                                  });
                                }
                              }}
                              onBlur={() => {
                                if (this.state.isAmtValueUpdated) {
                                  this.handleInputChange({
                                    paymentAmount: {
                                      value: parseFloat(
                                        paymentPlanInformation.paymentAmount
                                          .value
                                      ).toFixed(2),
                                      error: false,
                                    },
                                  });
                                  this.setState((state) => ({
                                    ...state,
                                    isAmtValueUpdated: false,
                                  }));
                                }
                              }}
                            />
                            {paymentPlanInformation.paymentAmount.error
                              ? this.renderErrorMessage("paymentAmount")
                              : null}
                            {paymentAmountError
                              ? this.renderErrorMessage("paymentAmountError")
                              : null}
                            {paymentPeriodMathError
                              ? this.renderErrorMessage(
                                  "paymentPeriodMathError"
                                )
                              : null}
                          </div>
                        </div>
                        <div className='admin-form-row'>
                          <div
                            className={`input-modal-container`}
                            style={{
                              width: "100%",
                            }}
                          >
                            <label htmlFor='text'>First Payment Due:</label>
                            <MaskedTextInput
                              guide={true}
                              mask={[
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              name='date'
                              placeholder='mm/dd/yyyy'
                              placeholderChar={"\u2000"}
                              type='text'
                              value={
                                paymentPlanInformation.paymentDueDate.value
                              }
                              onChange={(event) => {
                                this.handleInputChange({
                                  paymentDueDate: {
                                    value: event.target.value,
                                    error: false,
                                  },
                                });
                              }}
                            />
                            {paymentDueDateError
                              ? this.renderErrorMessage("paymentDueDateError")
                              : null}
                            {paymentPlanInformation.paymentDueDate.error
                              ? this.renderErrorMessage("paymentDueDate")
                              : null}
                          </div>
                        </div>
                        <div className='admin-form-row'>
                          <div
                            className={`input-modal-container ${
                              false ? " error" : ""
                            }`}
                            style={{
                              marginBottom: "0px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <input
                                type='checkbox'
                                style={{
                                  appearance: "auto",
                                  height: "20px",
                                  width: "15px",
                                  marginRight: "12px",
                                }}
                                checked={this.state.markAsAccepted}
                                onClick={(event) =>
                                  this.checkradioMarkAsAccepted(event)
                                }
                              />
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  color: "#252525",
                                  fontFamily: "sofia_pro_bold",
                                  fontSize: "1em",
                                }}
                              >
                                Mark payment plan as accepted.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className='modal-actions double-btns'>
                  <div className='modal-buttons-mobile-view-alignment'>
                    <button
                      className={`modal-buttons mg-cancel-button margin-top`}
                      onClick={this.handleModalCloseBackToPaymentPlans}
                      disabled={this.state.bool}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className='modal-buttons-mobile-view-alignment'>
                    <button
                      className={`modal-buttons mg-brand2-color margin-right`}
                      onClick={() => {
                        this.handleSubmit(false);
                      }}
                      disabled={this.state.bool}
                    >
                      Save
                    </button>

                    <button
                      className='modal-buttons mg-brand2-color'
                      disabled={this.state.bool}
                      onClick={() => {
                        this.handleSubmit(true);
                      }}
                    >
                      Save & Send
                    </button>
                  </div>
                </div>
              </>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default withRouter(CreatePaymentPlanModal);
