import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AdminAddAdminForm from "../../components/Admin/Forms/AdminAddAdminForm";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import Panel from "../../components/App/Panel";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import SuperAdminSidebar from "../../components/SuperAdmin/SuperAdminSidebar";
import SuperAdminTemplateSidebar from "../../components/SuperAdmin/SuperAdminTemplateSidebar";
import Tabs from "../../components/Tabs";
import ToggleSwitch from "../../components/ToggleSwitch";
// API
import * as API from "../../utils/api";
// Utils
import { showToast } from "../../utils/helpers";
import SuperAdminAssignOrg from "./SuperAdminAssignOrg";
import SuperAdminOnboardingView from "./superAdminOnboardingView";
import AdminAdmins from "../AdminPortal/AdminAdmins"

class SuperAdminAccountsView extends Component {
  constructor(props) {

    super(props);
    this.state = {
      user: {},
      organization: {
        config_list: [],
        admins: [],
      },
      activeTab: 1,
      debts: [],
      offers: [],
      isLoading: true,
      addAdminFormIsVisible: false,
      selectedOfferForCounter: {},
      showCounterOfferModal: false,
      currentModal: null,
      optionalModalProps: null,
      paymentEnable: false,
      mergeVarData: [],
      error: {
        hasAnyError: false,
        statusCode: 200,
      }
    };
    this.reloadAdminsChildRef = React.createRef();
  }

  componentDidMount() {
    this.getOrganizationData();
    this.getMergeVariablesList();
  }

  getOrganizationData = () => {
    const accountId = this.props.match.params.id;
    API.fetchOrganizationDetails(this.props.user, accountId).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.message) {
        this.setState((state) => ({
          ...state,
          user: this.props.user,
          organization: data.data,
          paymentEnable: data.data.admin_approved_for_payment,
          isLoading: false,
        }));
      } else {
        this.handleError(data);
      }
    });
  };

  getMergeVariablesList = () => {
    const accountId = this.props.match.params.id;
    API.getMergeVariablesList(this.props.user, accountId).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.message) {
        this.setState((state) => ({
          ...state,
          mergeVarData: data.data,
        }));
      } else {
        this.handleError(data);
      }
    });
  };

  updateMergeVariables = (payload) => {
    const accountId = this.props.match.params.id;
    payload = { ...payload, organization_id: accountId };
    API.updateMergeVariables(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.getMergeVariablesList();
      } else {
        this.handleError(data);
      }
    });
  };

  handleError = (data) => {
    this.setState((state) => ({
      ...state,
      isLoading: false,
      error: {
        hasAnyError: true,
        statusCode: data
          ? data.status ||
            (data.message && data.message == "Access denied." ? 401 : 500) ||
            500
          : 500,
      },
    }));
  };

  getAccountFromData = (id, users) => {
    let currentId = parseInt(id, 10);
    let user = users.filter(function (user) {
      return user.id === currentId;
    });

    return user[0];
  };

  handleAddAdminClick = () => {
    this.setState((state) => ({
      ...state,
      addAdminFormIsVisible: true,
    }));
  };

  handleAddAdminFormCancelation = () => {
    this.setState((state) => ({
      ...state,
      addAdminFormIsVisible: false,
    }));
  };

  addAdmin = (adminUser) => {
    API.createAdmin(this.props.user, adminUser).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        !data.errors &&
        data.status_code != 400
      ) {
        this.props.updateModalState(true, "SUCCESS", { message: data.message });
        if(this.state.activeTab == 1){
          this.reloadAdminsChildRef.current.reloadAdmins(this.props.match.params.id)
        }
        //this.getOrganizationData();
      } else if (data && data.status_code === 400) {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  updatePaymentOption = () => {
    let payload = {
      organization_id: this.state.organization.id,
      admin_approved_for_payment: this.state.paymentEnable,
    };
    API.updatePaymentOption(this.props.user, payload).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        !data.errors &&
        data.status_code != 400
      ) {
        this.props.updateModalState(true, "SUCCESS", { message: data.message });
        this.getOrganizationData();
      } else if (data && data.status_code === 400) {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = ["First Name", "Last Name", "Email", "Password"];

    columns = data.map((admin) => {
      return {
        secondaryColumns: [
          {
            title: admin.first_name,
            key: "First Name",
          },
          {
            title: admin.last_name,
            key: "Last Name",
          },
          {
            title: admin.email,
            key: "Email",
          },
          {
            title: admin.registration_token ? admin.registration_token : "n/a",
            key: "Password",
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  handleTabChange = (tabIndex) => {
    this.setState({
      activeTab: tabIndex,
    });
  };

  renderTable = (data) => {
    let emptyStateMessage = "List Not Available.";
    let tableData = data;

    return tableData.length > 0 ? (
      <div>
        {/* cardview for mobile device */}
        <ClientsCardView data={this.getObjectListData(tableData)} />
        {/* list view for big devices */}
        <InvoiceObjectList
          data={this.getObjectListData(tableData)}
          hideForMobile={true}
          optionalTableClasses="client-admin-details"
        />
      </div>
    ) : (
      <div className="object-list-empty-state">
        <span className="object-list-empty-state-message">
          {emptyStateMessage}
        </span>
      </div>
    );
  };


  configDetails = (configData) => {
    return configData.length > 0 ? (
      <Panel>
        {configData.map((key, value) => {
          return (
            <div>
              {(key.app_id || key.secret_key || key.token) && (
                <h3 className="panel-heading">{key.name}</h3>
              )}
              <div className="org-config-details">
                {key.app_id && (
                  <div style={{ marginRight: "auto" }}>
                    <h4 className="panel-heading-secondary">App Id</h4>
                    <p>{key.app_id}</p>
                  </div>
                )}
                {key.token && (
                  <div style={{ marginRight: "auto" }}>
                    <h4 className="panel-heading-secondary">Token</h4>
                    <p>{key.token}</p>
                  </div>
                )}
                {key.secret_key && (
                  <div style={{ marginRight: "auto" }}>
                    <h4 className="panel-heading-secondary">Secret Key</h4>
                    <p>{key.secret_key}</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Panel>
    ) : (
      <div className="object-list-empty-state">
        <span className="object-list-empty-state-message">
          {"Configuration Details Not Available."}
        </span>
      </div>
    );
  };

  toggleButton = () => {
    this.setState({ paymentEnable: !this.state.paymentEnable }, () => {
      this.updatePaymentOption();
    });
  };

  renderEnablePayment = () => {
    return (
      <div
        style={{
          display: "flex",
          marginBottom: "1em",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginRight: 10 }}>Enable Payment</div>
        <ToggleSwitch
          isOn={this.state.paymentEnable}
          toggleButton={() => this.toggleButton()}
        />
      </div>
    );
  };

  updateLogo = (data) => {
    const accountId = this.props.match.params.id;
    API.updateMergeVarLogo(this.props.user, accountId, data).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", { message: data.message });
        this.getMergeVariablesList();
      } else {
        this.handleError(data);
      }
    });
  };

  hanldeInputChange = (value, merge, isColor) => {
    let { mergeVarData } = this.state;
    Object.entries(mergeVarData).map(([key, subject], index) => {
      if (key && key != "logo_url") {
        Object.entries(subject).map(([key1, rowData], i) => {
          if (rowData && rowData.length) {
            rowData.map((item) => {
              if (item.merge_variable_id == merge.merge_variable_id) {
                item.value = isColor ? value.hex : value;
              }
            })
          }
        });
      }
    });
    this.setState({ mergeVarData });
  };

  render() {
    const { appName, user } = this.props;
    const {
      organization,
      addAdminFormIsVisible,
      isLoading,
      error,
      activeTab,
      mergeVarData,
    } = this.state;
    const tabLabels = ["Users","Details", "Configure Templates", "Onboarding"];
    let props = this.props;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    let sectionHeaderUtilities = (
      <div className="app-section-header-utilities">
        <Tabs
          labels={tabLabels}
          activeTarget={activeTab}
          handleTabChange={this.handleTabChange}
          optionalInTabClasses="mg-tabs-tab"
          optionalClasses="con-fit super-admin-account-view-tabs"
        />
        <button
          className={`cta mg-brand2-color ${
            addAdminFormIsVisible ? " hide" : ""
          }`}
          onClick={this.handleAddAdminClick}
        >
          + Add Admin
        </button>
      </div>
    );

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="accounts-view app-sidebar-layout scrollbar super-admin-account-view">
        <Helmet>
          <title>
            {appName} | {organization.name}
          </title>
        </Helmet>
        <AppSectionHeader
          title={organization.name}
          optionalClassNames="secondary admin-secondary"
          utilities={sectionHeaderUtilities}
          backLink={{
            text: "Back to All Accounts",
            path: "/super-admin/accounts",
          }}
        />
        <div className="super-admin-account-view-inner scrollbar">
          {addAdminFormIsVisible && (
            <AdminAddAdminForm
              organization={organization}
              closeForm={() => this.handleAddAdminFormCancelation()}
              handleFormSubmission={(adminUser) => this.addAdmin(adminUser)}
            />
          )}
          {activeTab == 1 ? (
            <div >
              <AdminAdmins
                  user={this.props.user}
                  ref={this.reloadAdminsChildRef}
                  organization_id={this.props.match.params.id}
              />
            </div>
          ) : activeTab == 2 ? (
            <div className="app-sidebar-layout-canvas">
              <SuperAdminSidebar
                account={organization}
                name={organization.id}
                renderEnablePayment={this.renderEnablePayment}
              />
              <div className="app-sidebar-layout-canvas-content">
                <h2 className="accounts-view-heading">Configuration Details</h2>
                {this.configDetails(organization.config_list)}
                {/*<h2 className="accounts-view-heading">Admin Details</h2>
                {this.renderTable(organization.admins)}*/}
              </div>
            </div>
          ) : activeTab == 3 ? (
            <div className="app-sidebar-layout-canvas">
              <SuperAdminTemplateSidebar
                account={organization}
                name={organization.id}
                mergeVarData={mergeVarData}
                updateLogo={this.updateLogo}
                renderEnablePayment={this.renderEnablePayment}
                hanldeInputChange={this.hanldeInputChange}
                updateMergeVariables={this.updateMergeVariables}
              />
              <div className="app-sidebar-layout-canvas-content">
                <SuperAdminAssignOrg
                  accountId={organization.id}
                  user={this.props.user}
                  updateModalState={this.props.updateModalState}
                />
              </div>
            </div>
          ) : (
            <div>
              <SuperAdminOnboardingView
                accountId={organization.id}
                user={this.props.user}
                updateModalState={this.props.updateModalState} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SuperAdminAccountsView;
