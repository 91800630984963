import React, { Component } from 'react';

// Components
import Logo from '../../components/Logo';
import ArrowLink from '../../components/ArrowLink';

class OnboardingHeader extends Component {

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label="Back"
        arrow="left"
        path={prevLink}
        optionalClassName="onboarding-header-prev-link"
      />
    )
  }

  render() {
    let { optionalClassName, prevLink } = this.props;

    return (
      <header className={`onboarding-header${optionalClassName ? ' ' + optionalClassName : ''}`}>
        {prevLink && this.renderPrevLink(prevLink)}
        <Logo
          optionalClass="onboarding-header-logo"
        />
      </header>
    );
  }
}

export default OnboardingHeader;
