import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Packages
import shortid from 'shortid';

class ExpanderTable extends Component {
  static propTypes = {
    isExpanded: PropTypes.bool.isRequired
  }

  render() {
    let { options, isExpanded } = this.props;

    let notes = (
      <div>
        <div className="expander-table-header">
          {options.notesHeadings.map((heading) => {
            return (
              <h4 key={shortid.generate()} className="expander-table-header-heading" style={{textAlign:`left`}}>
                {options.notesRows? heading : ''}
              </h4>
            )
          })}
        </div>
        <div className="object-debt-row-data expander-table-row-padding object-row-data-column">
          <div>{options.notesRows}</div>
        </div>
      </div>
    )

    return options.rows 
    ? (
      <div className={`expander-table${isExpanded ? ' visible' : ''}`}>
          { options.notesRows? notes : null}
          {
            options.notesRows? (<div><span><br/><hr/></span></div>): ''
          }
          <div className="expander-table-header">
            {options.headings.map((heading) => {
              return (
                <h4 key={shortid.generate()} className="expander-table-header-heading">
                  {heading}
                </h4>
              )
            })}
          </div>
          {options.rows.map((row) => {
            return (
              <div key={shortid.generate()} className="object-debt-row-data expander-table-row-padding">
                <div className="object-row-data-column">
                  <div>{row.admin_first_name} {row.admin_last_name}</div>
                </div>
                <div className="object-row-data-column">
                  {/* {new Date(row.date).toLocaleDateString()}  */}
                  {moment.utc(row.date).format("MM/DD/YYYY")}
                </div>
                <div className="object-row-data-column">
                  {`$${row.amount}`}
                </div>
                <div className="object-row-data-column">
                  {row.payment_type}
                </div>
                <div className="object-row-data-column">
                  {row.stripe_id}
                </div>
                <div className="object-row-data-column expander-table-transaction-id">
                  {row.transaction_id ? row.transaction_id : 'n/a'}
                </div>
              </div>
            );
          })}
      </div>
    )
    : (
      <div className={`expander-table${isExpanded ? ' visible' : ''}`}>
        {
          options.notesRows? notes : 
            <div className="expander-table-empty-state">
              No Additional Details
            </div>
          }
      </div>
    )
  }
}

export default ExpanderTable;
