import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// Components
import Modal from '../Modal';


class UserAccountPaymentOfferSubmittedModal extends Component {

  handleModalClose = () => {
    const { debtId } = this.props.optionalProps;
    this.props.hideModal();
    this.props.history.push(`/dashboard/accounts/${debtId}`);
  }

  render() {

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="user-account-offer-submitted"
        modalExit={ false }
      >
        <div className="modal-spotlight-illustration"></div>
        <h1 className="modal-heading">
          Thank you for submitting your offer!
        </h1>
        <p>
          Your offer is currently being reviewed by the {this.props.appName} team. You will be notified when a decision has been made!
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.handleModalClose}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default withRouter(UserAccountPaymentOfferSubmittedModal);
