import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import MaskedTextInput from "react-text-mask";

// Utils
import * as API from "../utils/api";
import {
  validateCompanyEmail,
  validateEmail,
  networkMessage,
  showToast,
} from "../utils/helpers";
import deb_logo from "../assets/icons/deb_logo.png";

class AppThirdPartyConfigForm extends Component {
  constructor(props) {
    super(props);
    this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);
  }

  state = {
    input: {
      isHeap: {
        value: false,
        hasError: false,
      },
      isTwilio: {
        value: false,
        hasError: false,
      },
      isIntercom: {
        value: false,
        hasError: false,
      },
      isStripe: {
        value: false,
        hasError: false,
      },
      isMailChimp: {
        value: false,
        hasError: false,
      },
      isLob: {
        value: false,
        hasError: false,
      },
      heapEmail: {
        value: "",
        hasError: false,
      },
      heapAppId: {
        value: "",
        hasError: false,
      },
      twilioPhone: {
        value: "",
        hasError: false,
      },
      twilioAccountSid: {
        value: "",
        hasError: false,
      },
      twilioAuthToken: {
        value: "",
        hasError: false,
      },
      intercomAppId: {
        value: "",
        hasError: false,
      },
      stripeToken: {
        value: "",
        hasError: false,
      },
      stripeSecretKey: {
        value: "",
        hasError: false,
      },
      mailChimpAppId: {
        value: "",
        hasError: false,
      },
      mailChimpSecretKey: {
        value: "",
        hasError: false,
      },
      lobKey: {
        value: "",
        hasError: false,
      },
    },
    accessError: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    isAppConfigure: false,
    isAppConfig: false,
  };

  componentWillMount() {
    var isConf = localStorage.getItem("isAppConfig") || false;
    if (isConf == false || isConf == "false") {
      isConf = this.getAppThirdPartyConfiguredStatus();
      this.setState((state) => ({
        ...state,
        loading: false,
        isAppConfig: isConf,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        loading: false,
        isAppConfig: true,
      }));
    }
  }

  async getAppThirdPartyConfiguredStatus() {
    var me = this;
    localStorage.setItem("isAppConfig", false);
    // await API.isThirdPartyAppConfigured().then(data => {
    //   if ((typeof data != 'undefined' || data != null) && (!data.errors)) {
    //     if (data.isAppConfig) {
    //       me.setState(state => ({
    //         ...state,
    //         loading: false,
    //         isAppConfig: true
    //       }))
    //       localStorage.setItem("isAppConfig", true);
    //       return true;
    //     } else {
    //       me.setState(state => ({
    //         ...state,
    //         loading: false,
    //         isAppConfig: false
    //       }))
    //       localStorage.setItem("isAppConfig", false);
    //       return false;
    //     }
    //   } else {
    //     me.setState(state => ({
    //       ...state,
    //       loading: false,
    //       isAppConfig: false
    //     }))
    //     localStorage.setItem("isAppConfig", false);
    //     return false;
    //   }
    // });
  }

  handleCheckboxInputChange(event, newPartialInput) {
    let target = event.target;
    let newInputState = { ...this.state.input };
    let value = target.type === "checkbox" ? target.checked : target.value;
    let preference = target.name;
    if (preference === "isHeap") {
      newInputState.isHeap.value = value;
    } else if (preference === "isTwilio") {
      newInputState.isTwilio.value = value;
    } else if (preference === "isIntercom") {
      newInputState.isIntercom.value = value;
    } else if (preference === "isStripe") {
      newInputState.isStripe.value = value;
      newInputState.isStripe.hasError = false;
    } else if (preference === "isMailChimp") {
      newInputState.isMailChimp.value = value;
      newInputState.isMailChimp.hasError = false;
    } else if (preference === "isLob") {
      newInputState.isLob.value = value;
      newInputState.isLob.hasError = false;
    }

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "isTwilio") {
        if (value.value) {
          newInputState.isTwilio.hasError = false;
        }
      } else if (key === "isStripe") {
        if (value.value) {
          newInputState.isStripe.hasError = false;
        }
      } else if (key === "isMailChimp") {
        if (value.value) {
          newInputState.isMailChimp.hasError = false;
        }
      } else if (key === "isLob") {
        if (value.value) {
          newInputState.isLob.hasError = false;
        }
      }
    });

    this.setState((state) => ({
      ...state,
      input: newInputState,
    }));
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      let config_data = [];

      let heapObj = {
        name: "HEAP",
        email: this.state.input.heapEmail.value,
        app_id: this.state.input.heapAppId.value,
      };
      config_data.push(heapObj);

      let phoneNumber = this.state.input.twilioPhone.value;
      if (phoneNumber) {
        phoneNumber = phoneNumber
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", "");
      }

      let twilioObj = {
        name: "TWILIO",
        email: phoneNumber,
        app_id: this.state.input.twilioAccountSid.value,
        secret_key: this.state.input.twilioAuthToken.value,
      };

      config_data.push(twilioObj);

      let intercomObj = {
        name: "INTERCOM",
        app_id: this.state.input.intercomAppId.value,
      };

      config_data.push(intercomObj);

      let stripeObj = {
        name: "STRIPE",
        token: this.state.input.stripeToken.value,
        secret_key: this.state.input.stripeSecretKey.value,
      };

      config_data.push(stripeObj);

      let mailChimpObj = {
        name: "MAILCHIMP",
        app_id: this.state.input.mailChimpAppId.value,
        secret_key: this.state.input.mailChimpSecretKey.value,
      };

      config_data.push(mailChimpObj);

      let lobObj = {
        name: "LOB",
        secret_key: this.state.input.lobKey.value,
      };

      config_data.push(lobObj);

      API.appThirdPartyConfig(config_data).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.isAppConfig) {
            this.setState((state) => ({
              ...state,
              input: {
                ...state.input,
              },
              isAppConfig: true,
            }));
          } else {
            this.setState((state) => ({
              ...state,
              input: {
                ...state.input,
              },
              isAppConfig: false,
            }));
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    } else {
      console.log("in handleSubmit ::: false");
    }
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "isTwilio") {
        if (!value.value) {
          newInputState[key].hasError = true;
        }
      } else if (key === "isStripe") {
        if (!value.value) {
          newInputState[key].hasError = true;
        }
      } else if (key === "isMailChimp") {
        if (!value.value) {
          newInputState[key].hasError = true;
        }
      } else if (key === "stripeToken") {
        if (value.value === "") {
          newInputState[key].hasError = true;
        }
      } else if (key === "stripeSecretKey") {
        if (value.value === "") {
          newInputState[key].hasError = true;
        }
      } else if (key === "mailChimpAppId") {
        if (value.value == "") {
          newInputState[key].hasError = true;
        }
      } else if (key === "mailChimpSecretKey") {
        if (value.value == "") {
          newInputState[key].hasError = true;
        }
      } else if (key === "twilioPhone") {
        if (value.value === "") {
          newInputState[key].hasError = true;
        }
      } else if (key === "twilioAccountSid") {
        if (value.value === "") {
          newInputState[key].hasError = true;
        }
      } else if (key === "twilioAuthToken") {
        if (value.value === "") {
          newInputState[key].hasError = true;
        }
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "Please make sure to complete this field.";
    if (
      input === "isTwilio" ||
      input === "isStripe" ||
      input === "isMailChimp"
    ) {
      message = "Mandatory field is required.";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  render() {
    const { input, accessError, location, error } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (this.state.isAppConfig == true || this.state.isAppConfig == "true") {
      return <Redirect to={{ pathname: "/" }} />;
    }

    let newInputState = { ...this.state.input };

    return (
      <div>
        {accessError && (
          <div className='input-container error'>
            {this.renderErrorMessage("account")}
          </div>
        )}
        <form
          className='sessions-form configure-form'
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <div style={{ width: `100%`, display: `flex` }}>
            <div style={{ width: `50%`, padding: `0 20px` }}>
              <h3> Mandatory</h3>
              <div
                className={`input-container${
                  input.isTwilio.hasError ? " error " : " "
                } third-party-config-divide-cell`}
              >
                <label htmlFor='phone' style={{ display: `flex` }}>
                  <input
                    name='isTwilio'
                    type='checkbox'
                    style={{
                      WebkitAppearance: `checkbox`,
                      width: `20px`,
                      marginRight: `10px`,
                      marginTop: `-5px`,
                    }}
                    checked={input.isTwilio.value}
                    onChange={this.handleCheckboxInputChange}
                  />
                  Twilio Integration (For sending OTP)
                </label>
                {input.isTwilio.hasError
                  ? this.renderErrorMessage("isTwilio")
                  : null}
              </div>
              <div
                className={`${input.twilioAccountSid.hasError ? " error" : ""}`}
              ></div>
              {newInputState.isTwilio.value ? (
                <div className={`input-container`}>
                  <div
                    className={`input-container${
                      input.twilioAccountSid.hasError ? " error" : ""
                    }`}
                  >
                    <label>Twilio Account Sid </label>
                    <input
                      name='twilioAccountSid'
                      type='text'
                      value={input.twilioAccountSid.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          twilioAccountSid: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.twilioAccountSid.hasError
                      ? this.renderErrorMessage("twilioAccountSid")
                      : null}
                  </div>
                  <div
                    className={`input-container${
                      input.twilioAuthToken.hasError ? " error" : ""
                    }`}
                  >
                    <label>Twilio Auth Token</label>
                    <input
                      name='twilioAuthToken'
                      type='text'
                      value={input.twilioAuthToken.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          twilioAuthToken: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.twilioAuthToken.hasError
                      ? this.renderErrorMessage("twilioAuthToken")
                      : null}
                  </div>
                  <div
                    className={`input-container${
                      input.twilioPhone.hasError ? " error" : ""
                    }`}
                  >
                    <label>Twilio Phone Number</label>
                    <MaskedTextInput
                      name='twilioPhone'
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder='(123) 456-7890'
                      placeholderChar={"\u2000"}
                      guide={true}
                      type='text'
                      value={input.twilioPhone.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          twilioPhone: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.twilioPhone.hasError
                      ? this.renderErrorMessage("twilioPhone")
                      : null}
                  </div>
                </div>
              ) : null}

              <div
                className={`input-container${
                  input.isStripe.hasError ? " error " : " "
                } third-party-config-divide-cell`}
              >
                <label htmlFor='phone' style={{ display: `flex` }}>
                  <input
                    name='isStripe'
                    type='checkbox'
                    style={{
                      WebkitAppearance: `checkbox`,
                      width: `20px`,
                      marginRight: `10px`,
                      marginTop: `-5px`,
                    }}
                    checked={input.isStripe.value}
                    onChange={this.handleCheckboxInputChange}
                  />
                  Stripe Integration (For Payment)
                </label>
                {input.isStripe.hasError
                  ? this.renderErrorMessage("isStripe")
                  : null}
              </div>
              {newInputState.isStripe.value ? (
                <div className={`input-container`}>
                  <div
                    className={`input-container${
                      input.stripeToken.hasError ? " error" : ""
                    }`}
                  >
                    <label>Stripe Publisable Key</label>
                    <input
                      name='stripeToken'
                      type='text'
                      value={input.stripeToken.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          stripeToken: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.stripeToken.hasError
                      ? this.renderErrorMessage("stripeToken")
                      : null}
                  </div>
                  <div
                    className={`input-container${
                      input.stripeSecretKey.hasError ? " error" : ""
                    }`}
                  >
                    <label>Stripe Secret key(For Backend)</label>
                    <input
                      name='stripeSecretKey'
                      type='text'
                      value={input.stripeSecretKey.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          stripeSecretKey: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.stripeSecretKey.hasError
                      ? this.renderErrorMessage("stripeSecretKey")
                      : null}
                  </div>
                </div>
              ) : null}

              <div
                className={`input-container${
                  input.isMailChimp.hasError ? " error " : " "
                } third-party-config-divide-cell`}
              >
                <label htmlFor='phone' style={{ display: `flex` }}>
                  <input
                    name='isMailChimp'
                    type='checkbox'
                    style={{
                      WebkitAppearance: `checkbox`,
                      width: `20px`,
                      marginRight: `10px`,
                      marginTop: `-5px`,
                    }}
                    checked={input.isMailChimp.value}
                    onChange={this.handleCheckboxInputChange}
                  />
                  MailChimp Integration (For sending email)
                </label>
                {input.isMailChimp.hasError
                  ? this.renderErrorMessage("isMailChimp")
                  : null}
              </div>
              {newInputState.isMailChimp.value ? (
                <div className={`input-container`}>
                  <div
                    className={`input-container${
                      input.mailChimpAppId.hasError ? " error" : ""
                    }`}
                  >
                    <label>MailChimp App Id</label>
                    <input
                      name='mailChimpAppId'
                      type='text'
                      value={input.mailChimpAppId.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          mailChimpAppId: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.mailChimpAppId.hasError
                      ? this.renderErrorMessage("mailChimpAppId")
                      : null}
                  </div>
                  <div
                    className={`input-container${
                      input.mailChimpSecretKey.hasError ? " error" : ""
                    }`}
                  >
                    <label>MailChimp Api Key</label>
                    <input
                      name='mailChimpSecretKey'
                      type='text'
                      value={input.mailChimpSecretKey.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          mailChimpSecretKey: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.mailChimpSecretKey.hasError
                      ? this.renderErrorMessage("mailChimpSecretKey")
                      : null}
                  </div>
                </div>
              ) : null}
              <div
                className={`input-container${
                  input.isLob.hasError ? " error " : " "
                } third-party-config-divide-cell`}
              >
                <label htmlFor='phone' style={{ display: `flex` }}>
                  <input
                    name='isLob'
                    type='checkbox'
                    style={{
                      WebkitAppearance: `checkbox`,
                      width: `20px`,
                      marginRight: `10px`,
                      marginTop: `-5px`,
                    }}
                    checked={input.isLob.value}
                    onChange={this.handleCheckboxInputChange}
                  />
                  Lob Integration (For sending email)
                </label>
                {input.isLob.hasError ? this.renderErrorMessage("isLob") : null}
              </div>
              {newInputState.isLob.value ? (
                <div className={`input-container`}>
                  <div
                    className={`input-container${
                      input.lobKey.hasError ? " error" : ""
                    }`}
                  >
                    <label>Lob App Key</label>
                    <input
                      name='lobKey'
                      type='text'
                      value={input.lobKey.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          lobKey: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.lobKey.hasError
                      ? this.renderErrorMessage("lobKey")
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
            <div style={{ width: `50%`, padding: `0 20px` }}>
              <h3> Optional </h3>
              <div className={`input-container third-party-config-divide-cell`}>
                <label htmlFor='phone' style={{ display: `flex` }}>
                  <input
                    name='isHeap'
                    type='checkbox'
                    style={{
                      WebkitAppearance: `checkbox`,
                      width: `20px`,
                      marginRight: `10px`,
                      marginTop: `-5px`,
                    }}
                    checked={input.isHeap.value}
                    onChange={this.handleCheckboxInputChange}
                  />
                  Heap Integration (For app analytics)
                </label>
              </div>
              {newInputState.isHeap.value ? (
                <div className={`input-container`}>
                  <div
                    className={`input-container${
                      input.heapEmail.hasError ? " error" : ""
                    }`}
                  >
                    <label>Email</label>
                    <input
                      name='heapEmail'
                      type='email'
                      value={input.heapEmail.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          heapEmail: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.heapEmail.hasError
                      ? this.renderErrorMessage("heapEmail")
                      : null}
                  </div>
                  <div
                    className={`input-container${
                      input.heapAppId.hasError ? " error" : ""
                    }`}
                  >
                    <label>App Id</label>
                    <input
                      name='heapAppId'
                      type='text'
                      value={input.heapAppId.value}
                      onChange={(event) =>
                        this.handleInputChange({
                          heapAppId: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                    {input.heapAppId.hasError
                      ? this.renderErrorMessage("heapAppId")
                      : null}
                  </div>
                </div>
              ) : null}

              <div className={`input-container third-party-config-divide-cell`}>
                <label htmlFor='phone' style={{ display: `flex` }}>
                  <input
                    name='isIntercom'
                    type='checkbox'
                    style={{
                      WebkitAppearance: `checkbox`,
                      width: `20px`,
                      marginRight: `10px`,
                      marginTop: `-5px`,
                    }}
                    checked={input.isIntercom.value}
                    onChange={this.handleCheckboxInputChange}
                  />
                  Intercom Integration (For chat)
                </label>
              </div>
              {newInputState.isIntercom.value ? (
                <div
                  className={`input-container${
                    input.intercomAppId.hasError ? " error" : ""
                  }`}
                >
                  <label>Intercom App Id</label>
                  <input
                    name='intercomAppId'
                    type='text'
                    value={input.intercomAppId.value}
                    onChange={(event) =>
                      this.handleInputChange({
                        intercomAppId: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {input.intercomAppId.hasError
                    ? this.renderErrorMessage("intercomAppId")
                    : null}
                </div>
              ) : null}
            </div>
          </div>
          <input
            className='cta'
            type='submit'
            value='Submit'
            style={{ width: `50%`, margin: `20px 25%` }}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(AppThirdPartyConfigForm);
