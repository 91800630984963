import React, { Component } from "react";
import Modal from "./Modal";
import "react-select/dist/react-select.css";
import "../../../assets/styles/style.css";
import * as API from "../../../utils/api";
import { objToQueryString } from "fetch-mw-oauth2/dist/util";
import { getDollarAmount } from "../../../utils/helpers";
import Emitter from "../../../utils/event-emitter";
class CancelCreditMemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      showLoader: false,
    };
  }

  componentDidMount() {
    let { optionalProps } = this.props;
    let { invoiceDetails } = optionalProps;
    let creditMemoList = [];
    if (invoiceDetails.payment_history.length > 0) {
      invoiceDetails.payment_history.map((payemnt_item) => {
        if (
          payemnt_item.payment_type == "Credit Memo" &&
          !payemnt_item.is_cancelled
        ) {
          let amount = payemnt_item.amount
            ? parseFloat(payemnt_item.amount)
            : "0.00";
          if (creditMemoList.length == 0) {
            creditMemoList.push({
              payment_transaction_id: payemnt_item.payment_transaction_id,
              id: payemnt_item.credit_memo_number,
              amount: parseFloat(amount),
            });
          } else {
            let checkFlag = true;
            creditMemoList.map((item, index) => {
              if (item.id == payemnt_item.credit_memo_number) {
                checkFlag = false;
                creditMemoList[index].amount =
                  parseFloat(creditMemoList[index].amount) + parseFloat(amount);
                creditMemoList[index].payment_transaction_id =
                  payemnt_item.payment_transaction_id;
              }
            });
            if (checkFlag) {
              creditMemoList.push({
                payment_transaction_id: payemnt_item.payment_transaction_id,
                id: payemnt_item.credit_memo_number,
                amount: parseFloat(amount),
              });
            }
          }
        }
      });
    }
    this.setState({ list: creditMemoList });
  }

  reverseCreditMemo = (item) => {
    let { optionalProps } = this.props;
    let { invoiceDetails, user } = optionalProps;
    let invoice_id = invoiceDetails.id;
    let payload = {
      txn_id: item.payment_transaction_id,
      invoice_id: invoice_id,
      credit_memo_number: item.id,
    };
    if (invoiceDetails.payment_history.length) {
      invoiceDetails.payment_history.map((item) => {
        if (item.payment_type != "Credit Memo") {
          let modal = "NO_Credit_Memo";
        }
      });
    }
    this.setState({ showLoader: true });
    API.reverseCreditMemo(user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.setState({ showLoader: false });
        if (!data.errors && data.status_code == 200) {
          this.props.hideModal();
          this.props.optionalProps.getCreditMemoDetails();
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message
              ? data.message
              : "Credit Memo reversed successfully.",
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
         else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message
              ? data.message
              : "Credit Memo reverse failed.",
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  handleModalClose = () => {
    this.props.hideModal();
  };

  //render table headers
  getHeaders = (list) => {
    return (
      <div>
        {list.length > 0 ? (
          <div style={StyleSheet.headerView}>
            <h4 style={StyleSheet.headerLabel}>Credit Memo #</h4>
            <h4 style={StyleSheet.headerLabel1}>Amount</h4>
            <h4 style={StyleSheet.headerLabel1}>Action</h4>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}> No Credit Memo available.</div>
        )}
      </div>
    );
  };
  render() {
    //   let { message, title, isOKCallback } = this.props.optionalProps;
    let { list } = this.state;
    let { optionalProps } = this.props;
    return (
      <Modal
        title={"Cancel Credit Memo"}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses="scroll"
      >
        <div style={{ marginTop: "0.5em" }}>
          {this.getHeaders(list)}
          {list && list.length
            ? list.map((item, i) => {
                return (
                  <div key={"_" + i} style={StyleSheet.tableBody}>
                    <div style={StyleSheet.col1}>{`#${item.id}`}</div>
                    <div style={StyleSheet.col2}>
                      {item.amount ? getDollarAmount(item.amount) : "0.00"}
                    </div>
                    <div className="in-new-btn" style={StyleSheet.col3}>
                      <button
                        id={`xyz` + i}
                        onClick={(event) => this.reverseCreditMemo(item)}
                      >
                        Cancel Credit Memo
                      </button>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </Modal>
    );
  }
}

let StyleSheet = {
  headerView: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  headerLabel: {
    margin: "0px 10px 0px 0px",
    fontSize: "1em",
    color: "#9b9b9b",
  },
  headerLabel1: {
    margin: 0,
    fontSize: "1em",
    color: "#9b9b9b",
  },
  tableBody: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e1e1e1",
    borderRadius: 4,
    marginBottom: "1em",
    padding: "0.5em",
    justifyContent: "space-between",
  },

  col1: {
    width: "33.333%",
  },
  col2: {
    width: "23.333%",
    marginLeft: "1rem",
  },
  col3: {
    width: "43.333%",
  },
};

export default CancelCreditMemo;
