import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import ReactGA from 'react-ga';

// API
import * as API from "../../../utils/api";

import { getZohoPagesense } from "../../../utils/helpers";

// Components
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../../components/App/AppSectionHeader";
import ObjectChatList from "../../../components/ObjectList/ObjectChatList";

class EmployeeViewConsumerChat extends Component {
  state = {
    consumerAccountId: null,
    consumer: {},
    savedData: [],
    isLoading: true,
    hasAnyError: false,
  };

  componentDidMount() {
    const consumerAccountId = this.props.match.params.id;
    API.fetchConsumerChatHistory(this.props.user, consumerAccountId).then(
      (data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          let conversation = data.data.reduce(function (r, a) {
            r[new Date(a.created_at).toDateString()] =
              r[new Date(a.created_at).toDateString()] || [];
            r[new Date(a.created_at).toDateString()].push(a);
            return r;
          }, Object.create(null));

          this.setState((state) => ({
            ...state,
            savedData: conversation,
            isLoading: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
          }));
        }
      }
    );
    getZohoPagesense();
    // ReactGA.pageview("/ViewConsumerChat");
  }

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    for (var i in data) {
      columns = data.map((conversation_data) => {
        return {
          chatData: {
            userRole: conversation_data.sender_name,
            userText: conversation_data.body,
            //chatId: conversation_data.author.id,
            createdAt: conversation_data.created_at,
          },
        };
      });
    }

    return {
      headings,
      columns,
    };
  };

  render() {
    const { consumer, conversation, isLoading, savedData } = this.state;
    if (savedData !== undefined || savedData !== "") {
      var conversationList = [];
      let index = 0;
      Object.entries(savedData).forEach(([key, value]) => {
        let tableData = value;
        conversationList.push(
          <div className='object-list-empty-state chat-margin' key={index++}>
            <ObjectChatList
              data={this.getObjectListData(tableData)}
              consumer={consumer}
            />
          </div>
        );
      });
    }

    if (this.state.hasAnyError) {
      this.props.updateModalState(true, "OTHER_ERROR", this.state.hasAnyError);
      return <Redirect to={{ pathname: "/sign-out" }} />;
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <AppSectionHeader
          title='Chat History'
          optionalClassNames='secondary'
          backLink={{
            text: "Back to all message",
            path: "/employee/messageHistory/2",
          }}
        />
        <div className='object-list-empty-state'>
          {conversationList ? (
            <div
              className='scrollbar'
              style={{ height: window.innerHeight * 0.65, paddingBottom: 50 }}
            >
              {conversationList}
            </div>
          ) : (
            <div className='object-list-empty-state chat-margin'>
              No chat conversation.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default EmployeeViewConsumerChat;
