import React, { Component } from "react";
import PropTypes from "prop-types";

// Packages
import shortid from "shortid";

// Components
import ObjectDebtRow from "./ObjectDebtRow";
import ObjectDebtListHeader from "./ObjectDebtListHeader";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

class ObjectDebtList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    appName: PropTypes.string,
  };

  renderOptionalColumnHeadings(headings, adjustHeader, setObjListStyle) {
    if (headings && headings.length > 0) {
      return (
        <ObjectDebtListHeader
          headings={headings}
          adjustHeader={adjustHeader}
          setObjListStyle={setObjListStyle}
        />
      );
    }
  }

  render() {
    let {
      data,
      rowActionClick,
      optionalClasses,
      adjustHeader,
      hideScrollClass,
      hideForMobile,
      setObjListStyle,
      removePadding,
      showLoader,
      showScroll,
    } = this.props;
    let style = this.props.hideScrollbar
      ? {}
      : { height: "auto", overflow: "auto", paddingBottom: 50 };
    style = removePadding
      ? { height: "auto", overflow: "auto", paddingBottom: 0 }
      : style;
    let newMailStyle = { height: "400px", overflow: "auto", paddingBottom: 20 };
    let fixht = { height: "auto" };
    return (
      <div
        className={`table-wrap-cls${hideForMobile ? " mobile-hide-list" : ""}`}
        style={fixht}
      >
        <div
          className={`object-debt-list${
            optionalClasses ? " " + optionalClasses : ""
          }`}
          style={fixht}
        >
          {this.renderOptionalColumnHeadings(
            data.headings,
            adjustHeader,
            setObjListStyle
          )}
          {
            <div
              ref={this.props.scrollRef}
              className={hideScrollClass ? "" : "scrollbar"}
              style={this.props.showScroll ? newMailStyle : style}
            >
              {data.columns.map((row) => {
                return (
                  <ObjectDebtRow
                    key={shortid.generate()}
                    rowData={row}
                    appName={this.props.appName}
                    handleRowExpanderClick={(event) =>
                      this.props.handleRowExpanderClick(event)
                    }
                    handleRowActionClick={(event) => rowActionClick(event)}
                  />
                );
              })}
              {showLoader ? (
                <div>
                  <AppCanvasLoader />
                </div>
              ) : null}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ObjectDebtList;
