import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import { Link } from "react-router-dom";
import * as API from "../../utils/api";
import { errorMessage, validateEmail } from "../../utils/helpers";

class SSOSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization_key: "",
      openid_id: "",
      sub_id: "",
      input: {
        email: {
          value: "",
          hasError: false,
        },
        password: {
          value: "",
          hasError: false,
        },
      },
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      accessError: "",
      showFlash: false,
      errorMsg: "",
      id_token: "",
      refreshToken: "",
    };
  }

  componentDidMount() {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    let email = searchParams.get("email");
    let organization_key = searchParams.get("organization_key");
    let sub_id = searchParams.get("sub_id");
    let id_token = searchParams.get("id_token");
    let newPartialInput = { email: { value: email, hasError: false } };
    this.setState({
      organization_key: organization_key,
      sub_id: sub_id,
      id_token: id_token,
    });
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
    if (email != "" && email != null && sub_id != "" && sub_id != null) {
      const newOrgData = localStorage.getItem("themeData");
      const userCandidate = {
        email: email,
        organization_key: organization_key
          ? organization_key
          : newOrgData
          ? newOrgData.organization_key
          : "",
        openid_id: sub_id,
        id_token: id_token,
      };
      console.log("usercandidate 69==>>", userCandidate);

      this.handleLogin(userCandidate, true);
    }
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    if (!validateEmail(newInputState.email.value)) {
      newInputState.email.hasError = true;
      errorsArePresent = true;
    }

    if (newInputState.password.value === "") {
      newInputState.password.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage = (input) => {
    let message = "";
    let { errorMsg } = this.state;
    if (input == "email") {
      message = "Email is not valid";
    } else if (input === "password") {
      message = "Please enter a password";
    } else {
      if (errorMsg) {
        let errorMessage = "";
        let splitErrorMsg = errorMsg.split(".");
        splitErrorMsg.forEach(function (msg, i) {
          if (i != 0) {
            errorMessage = errorMessage + msg;
            if (i > 0 && i < splitErrorMsg.length - 1) {
              errorMessage = errorMessage + ".";
            }
          }
        });
        message = errorMessage;
      } else {
        message = "Invalid password";
      }
    }
    return <div className='input-error-message'>{message}</div>;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let { organization_key, sub_id, input, id_token } = this.state;
    const userCandidate = {
      email: input.email.value,
      password: input.password.value,
      organization_key: organization_key,
      openid_id: sub_id,
      id_token: id_token,
    };
    if (this.validateForm()) {
      //$('#login-btn-submit').replaceWith('<div class="spinner"></div>');
      this.handleLogin(userCandidate, false);
    }
  };

  handleLogin = (userCandidate, openIdFlag) => {
    let { history } = this.props;
    if (openIdFlag) {
      try {
        API.setOauthSSOObj(userCandidate);
      } catch (error) {
        console.log("error catched at line 94==>", error);
      }
    } else {
      API.setOauthObj(userCandidate);
    }

    API.ssoLogin(userCandidate).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (!data.errors) {
          const user = {
            first_name: data.first_name,
            qb_connect:
              data.is_qb_connected === null ? false : data.is_qb_connected,
            last_name: data.last_name,
            email: data.email,
            user_id: data.id,
            user_auth_token: data.auth_token,
            admin: data.admin,
            is_onboarding_completed: data.is_onboarding_completed,
            is_employee: data.is_employee,
            super_admin: data.super_admin,
            onboarding_complete: data.accepted_on ? true : false,
            client_admin: data.client_admin,
            verified: data.verified,
            verified_at: data.verified_at,
            user_hash: data.user_hash,
            intercom_id: data.intercom_id,
          };
          this.props.updateUserLogin(user);
          history.push(`/client/dashboard`);
        } else {
          this.setState((state) => ({
            ...state,
            showFlash: true,
            accessError: errorMessage,
            errorMsg: data.errors,
          }));
          history.push(`/login`);
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        history.push(`/login`);
      }
    });
  };

  render() {
    const { appName } = this.props;
    let { input } = this.state;

    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    let email = searchParams.get("email");
    let sub_id = searchParams.get("sub_id");

    return (
      <div className='sessions fill-screen mg-session'>
        <Helmet>
          <title>{appName} | Success</title>
        </Helmet>
        {sub_id != null &&
        sub_id != "" &&
        email != null &&
        email != "" ? null : (
          <div className={`sessions-modal modal-space`}>
            <div className='sessions-modal-logo-container centerd-content'>
              <Logo circular={true} />
            </div>

            <form
              className='sessions-form'
              onSubmit={(event) => this.handleSubmit(event)}
            >
              <div
                className={`input-container ${
                  input.email.hasError ? " error" : ""
                }`}
                style={{ textAlign: "left", marginTop: "2rem" }}
              >
                <label>Email Address: {input.email.value}</label>
                {input.email.hasError ? this.renderErrorMessage("email") : null}
              </div>

              <div
                className={`input-container${
                  input.password.hasError ? " error" : ""
                }`}
                style={{ textAlign: "left" }}
              >
                <label>Password</label>
                <div className='password-input-container'>
                  <input
                    className={`${input.password.hasError ? "error" : ""}`}
                    name='password'
                    type='password'
                    placeholder='password'
                    value={input.password.value}
                    onChange={(event) =>
                      this.handleInputChange({
                        password: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                </div>
                {input.password.hasError
                  ? this.renderErrorMessage("password")
                  : null}
              </div>
              <input
                id='login-btn-submit'
                className='cta mg-button'
                type='submit'
                value='Login'
              />
              <label style={{ marginTop: "1rem" }}>
                Note: If you are first time sign in with intuit and you are
                already app user. You have to put app password for first time.{" "}
              </label>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default SSOSuccess;
