import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
// import ReactGA from 'react-ga';

// API
import * as API from "../../../utils/api";

import { getZohoPagesense } from "../../../utils/helpers";

// Components
import ConsumerAccountsViewSidebar from "../../../components/Consumer/ConsumerAccountsViewSidebar";
import AppSectionHeader from "../../../components/App/AppSectionHeader";
import Tabs from "../../../components/Tabs";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import FlashMessage from "../../../components/FlashMessage";
import ObjectList from "../../../components/ObjectList/ObjectList";
import CardView from "../../../components/CardView/CardView";
import downloadImg from "../../../assets/icons/download.png";

// Packages
import shortid from "shortid";

class ConsumerAccountsPaymentValidate extends Component {
  state = {
    account: {
      debtId: "",
      originalCollectionAmount: "",
      creditorName: "",
      numberOfOffers: "",
      validationDocuments: null,
    },
    isLoading: true,
    showFlash: false,
    hasAnyError: false,
  };

  componentDidMount() {
    const debtId = parseInt(this.props.match.params.id, 10);

    this.props.updatePrevPath(`/dashboard/accounts/${debtId}`);

    API.getDebt(this.props.user, debtId).then((data) => {
      if (typeof data != "undefined" || data != null) {
        let { debt, offers } = data;

        API.getDebtValidationDocuments(this.props.user, debtId).then((data) => {
          if (typeof data != "undefined" || data != null) {
            this.setState((state) => ({
              account: {
                debtId: debt.id,
                originalCollectionAmount: debt.balance_in_dollars_as_currency,
                creditorName: debt.creditor_name,
                numberOfOffers: offers.length,
                offers: offers,
                validationDocuments: data.data,
              },
              debt: debt,
              isLoading: false,
            }));
          } else {
            this.setState((state) => ({
              ...state,
              hasAnyError: true,
            }));
          }
        });
      } else {
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
        }));
      }
    });

    getZohoPagesense();
    // ReactGA.pageview("/ConsumerAccountsPaymentValidate");
  }

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  handleValidationMailLinkClick = () => {
    const { debtId } = this.state.account;

    API.requestValidationDocumentsMailed(this.props.user, debtId).then(
      (data) => {
        if (typeof data != "undefined" || data != null) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.setState((state) => ({
            ...state,
            hasAnyError: true,
          }));
        }
      }
    );
  };

  renderValidationMailLink() {
    return (
      <a
        className='text-link text-link-quat'
        onClick={() => this.handleValidationMailLinkClick()}
      >
        Request Validation Media via Mail
      </a>
    );
  }

  handleTabClick = (tabIndex) => {
    const { account } = this.state;

    switch (parseInt(tabIndex, 10)) {
      case 1:
        break;
      case 2:
        this.props.history.push(
          `/dashboard/accounts/${account.debtId}/negotiate`
        );
        break;
      case 3:
        this.props.history.push(
          `/dashboard/accounts/${account.debtId}/settle-account`
        );
        break;
      default:
        console.log("error");
        return;
    }
  };

  renderTabsOrHeading = () => {
    const { account } = this.state;

    if (account.numberOfOffers === 0) {
      const tabLabels = ["Validate", "Negotiate", "Pay"];

      return (
        <Tabs
          labels={tabLabels}
          activeTarget={1}
          optionalClasses='consumer-pay-tab'
          handleTabChange={this.handleTabClick}
        />
      );
    } else {
      return <h2 className='consumer-accounts-payment-heading'>Validate</h2>;
    }
  };

  getCardOptions = (data) => {
    return data.map((document) => {
      return {
        emptyPrimary: true,
        secondaryRows: [
          {
            key: "Name",
            value: document.name,
          },
          {
            key: "Description",
            value: document.description,
          },
          {
            key: "Download",
            value: (
              <a
                className='make-payment-button'
                download={document.name}
                href={document.document}
              >
                <img src={downloadImg} width='21' height='21' />
              </a>
            ),
          },
        ],
      };
    });
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = ["Name", "Description", "Download"];

    columns = data.map((document) => {
      return {
        primaryColumn: {
          title: document.name,
        },
        secondaryColumns: [
          {
            title: document.description,
          },
          {
            title: (
              <a
                className='make-payment-button'
                download={document.name}
                href={document.document}
              >
                <img src={downloadImg} width='21' height='21' />
              </a>
            ),
            isDownloadBtn: true,
            optionalClasses: "mobile-hide rmv-right-space",
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  renderValidationDocuments = (document) => {
    return document && document.length ? (
      // <div className="scrollbar tab-spacing-validation-doc" style={{ height: window.innerHeight * 0.65, overflow: 'auto' }}>
      <div className='app-sidebar-layout-canvas'>
        <div className='app-sidebar-layout-canvas-content'>
          <CardView data={this.getCardOptions(document)} />
          <ObjectList
            data={this.getObjectListData(document)}
            optionalClasses={"no-row-actions"}
            hideForMobile={true}
          />
        </div>
      </div>
    ) : (
      // </div>
      <div className='consumer-accounts-empty-state'>
        <h3 className='consumer-accounts-empty-state-heading'>
          No Validation Documents
        </h3>
        <p>There are currently no validation documents for this debt.</p>
        {this.renderValidationMailLink()}
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const { account, isLoading, debt } = this.state;

    if (this.state.hasAnyError) {
      this.props.updateModalState(true, "OTHER_ERROR", this.state.hasAnyError);
      return <Redirect to={{ pathname: "/sign-out" }} />;
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='accounts-view consumer-accounts-view app-sidebar-layout'>
        <Helmet>
          <title>{appName} | Validate</title>
        </Helmet>
        <div className='wrap user-portal-collapse-wrap'>
          <AppSectionHeader
            title={account.creditorName}
            subtitle={`$${account.originalCollectionAmount}`}
            optionalClassNames='secondary reversed-title consumer-portal-app-header'
            backLink={{
              text:
                account.numberOfOffers > 0
                  ? "Back to Account"
                  : "Back to All Accounts",
              path:
                account.numberOfOffers > 0
                  ? `/dashboard/accounts/${account.debtId}`
                  : `/dashboard/accounts/`,
            }}
          />
          <div
            className='scrollbar tab-spacing-negotiate'
            style={{ height: window.innerHeight * 0.65, overflow: "auto" }}
          >
            <div className='app-sidebar-layout-canvas'>
              <ConsumerAccountsViewSidebar account={account} debt={debt} />
              <div className='app-sidebar-layout-canvas-content'>
                {this.renderTabsOrHeading()}
                {this.state.showFlash ? (
                  <FlashMessage
                    message={this.state.flashMessage}
                    handleFlashDismiss={this.dismissFlash}
                  />
                ) : null}
                {this.renderValidationDocuments(account.validationDocuments)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerAccountsPaymentValidate;
