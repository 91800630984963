import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import 'react-select/dist/react-select.css';
import MaskedTextInput from 'react-text-mask';
// API
import * as API from '../../../../utils/api';
import { convertCreditorsToArrayForSelectComponent, isValidDate, showToast, validateEmail } from '../../../../utils/helpers';
// Components
import Modal from '../Modal';

class AddAdminAccountModal extends Component {
  state = {
    input: {
      adminInformation: {
        first_name: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        last_name: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        phone_number: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      }
    },
    slide: 1,
    allow_to_make_offer: false,
    view_all_accounts: false,
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    API.fetchAllCreditors(this.props.user).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        const creditorsForSelectField = [...data.creditors];

        let creditorsArray = convertCreditorsToArrayForSelectComponent(creditorsForSelectField);
        this.setState(state => ({
          creditors: creditorsArray,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    });
  }

  handleCheckboxInputChange(data) {
    if(data === 'view') {
      this.setState({
        view_all_accounts : !this.state.view_all_accounts
      })
    } else {
      this.setState({
        allow_to_make_offer : !this.state.allow_to_make_offer
      })
    }
  }

  handleModalClose = () => {
    this.props.hideModal();
    this.props.history.push("/admin/accounts");
  }

  handleAdminInfoInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        adminInformation: {
          ...state.input.adminInformation,
          ...newPartialInput,
        }
      }
    }))
  }

  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, '');
    return strippedNumber;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { input } = this.state;

    if (this.validateForm()) {
      let adminForSubmission = {
        first_name: input.adminInformation.first_name.value,
        last_name: input.adminInformation.last_name.value,
        email: input.adminInformation.email.value,
        phone_number: this.getNumberFromPhoneString(input.adminInformation.phone_number.value),
        allow_make_offer: this.state.allow_to_make_offer,
        view_all_accounts: this.state.view_all_accounts
      }

      API.createEmployee(this.props.user, adminForSubmission).then(data => {
        this.scrollToTop();
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          if (data.status_code === 400) {
            this.props.updateModalState(true, 'ERROR', { message: data.message });
          } else {
            let optionalProps = {
              account: adminForSubmission
            }
            this.props.updateModalState(true, 'ACCOUNT_CREATED', optionalProps);   
            (this.props.optionalProps && this.props.optionalProps.reloadData() && this.props.optionalProps.reloadData())
          }
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      });
    } else {
      this.setFormErrorState();
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  hasOnlyDigits = (string) => {
    return /^\d+$/.test(string);
  }

  validateForm = () => {
    let errorsArePresent = false;
    let inputs = { ...this.state.input.adminInformation }

    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === 'phone_number') {
        if (!value.value || (value.value && this.getNumberFromPhoneString(value.value).length != 10)) {
          errorsArePresent = true;
        }
      } else if (key === 'date_of_birth') {
        if (!isValidDate(value.value) || value.value === '' || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === 'email') {
        if (!value.value || !validateEmail(value.value) && value.value !== '') {
          errorsArePresent = true;
        }
      } else if (key === 'ssn') {
        if (!this.hasOnlyDigits(value.value)) {
          errorsArePresent = true;
        }
      } else if (key === 'creditor_id') {
        if (value.value === '') {
          errorsArePresent = true;
        }
      } else if (value.isRequired && value.value === '') {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  }

  setFormErrorState = () => {
    let errorsArePresent = false;
    let newInputState = { ...this.state.input.adminInformation }

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'phone_number') {
        if (!value.value || (value.value && this.getNumberFromPhoneString(value.value).length != 10)) {
          newInputState[key].hasError = true;
        }
      } else if (key === 'date_of_birth') {
        if (!isValidDate(value.value) || value.value === '' || value.value === null) {
          newInputState[key].hasError = true;
        }
      } else if (key === 'email') {
        if (!value.value || !validateEmail(value.value) && value.value !== '') {
          newInputState[key].hasError = true;
        }
      } else if (key === 'ssn') {
        if (!this.hasOnlyDigits(value.value)) {
          newInputState[key].hasError = true;
        }
      } else if (key === 'creditor_id') {
        if (value.value === '') {
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === '') {
        newInputState[key].hasError = true;
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: {
          ...state.input,
          adminInformation: newInputState
        }
      }))
    }
  }

  renderErrorMessage(input) {
    let message = '';

    if (input === 'name') {
      message = "Please enter a full name";
    } else if (input === 'date_of_birth') {
      message = 'Please enter a valid date';
    } else if (input === 'email') {
      message = 'Please enter a valid email address';
    } else if (input === 'phone_number') {
      message = 'Please enter a valid phone number';
    } else {
      message = 'Please complete this field';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { input, slide, creditors, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <Modal
        optionalClasses="add-account-modal scroll add-admin-form-width add-admin-modal"
        title="Add Employee Account"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        <form
          className="admin-form"
        >
          <header className="modal-slide-header">
            <div className={`modal-slide-header-tab${slide === 1 ? ' active' : ''}`}>
              Employee's Information
            </div>
          </header>
          <div className={`modal-slide${slide === 1 ? '' : ' hide'}`}>
            <div className="form-container add-admin-form-container">
              <div className="form-column add-admin-from-column">
                <div
                  className={`input-container${input.adminInformation.first_name.hasError || input.adminInformation.last_name.hasError ? ' error' : ''}`}
                >
                  {/* <label htmlFor="name">Admin's Name</label> */}
                  <label htmlFor="name">Employee's Name</label>
                  <div
                    className="input-container multi-input-field"
                  >
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="First Name"
                      value={input.adminInformation.first_name.value}
                      onChange={event => this.handleAdminInfoInputChange({ first_name: { value: event.target.value, hasError: false } })}
                    />
                    <input
                      className="multi-input-field-input"
                      name="name"
                      type="text"
                      placeholder="Last Name"
                      value={input.adminInformation.last_name.value}
                      onChange={event => this.handleAdminInfoInputChange({ last_name: { value: event.target.value, hasError: false } })}
                    />
                  </div>
                  {input.adminInformation.first_name.hasError || input.adminInformation.last_name.hasError ? this.renderErrorMessage('name') : null}
                </div>

                <div
                  className={`input-container${input.adminInformation.email.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="email">Employee's Email Address</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="address@email.com"
                    value={input.adminInformation.email.value}
                    onChange={event => this.handleAdminInfoInputChange({ email: { value: event.target.value, hasError: false } })}
                  />
                  {input.adminInformation.email.hasError ? this.renderErrorMessage('email') : null}
                </div>

                <div
                  className={`input-container${input.adminInformation.phone_number.hasError ? ' error' : ''}`}
                >
                  <label htmlFor="phone">Employee's Phone Number</label>
                  <MaskedTextInput
                    name="phone"
                    mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="(555) 555-5555"
                    placeholderChar={'\u2000'}
                    guide={true}
                    type="text"
                    value={input.adminInformation.phone_number.value}
                    onChange={event => this.handleAdminInfoInputChange({ phone_number: { value: event.target.value, hasError: false } })}
                  />
                  {input.adminInformation.phone_number.hasError ? this.renderErrorMessage('phone_number') : null}
                </div>
                {/* <div className={`input-container`} style={{ width: `50%` }} >
                  <label htmlFor="make-offer" style={{ display: `flex` }}>
                    <input
                      name='make-offer'
                      type="checkbox"
                      style={{ WebkitAppearance: `checkbox`, width: `20px`, marginRight: `10px`, marginTop: `-5px` }}
                      checked={this.state.allow_to_make_offer}
                      onChange={event => this.handleCheckboxInputChange('allow')} />Allow To Make Offer
                      </label>
                </div> */}
                <div className={`input-container`} style={{ width: `50%` }} >
                  <label htmlFor="view-account" style={{ display: `flex` }}>
                    <input
                      name='view-account'
                      type="checkbox"
                      style={{ WebkitAppearance: `checkbox`, width: `20px`, marginRight: `10px`, marginTop: `-5px` }}
                      checked={this.state.view_all_accounts}
                      onChange={event => this.handleCheckboxInputChange('view')} />View All Accounts
                      </label>
                </div>
              </div>
            </div>
            <div className="modal-actions">
              <a
                className="cta mg-brand2-color"
                onClick={(event) => this.handleSubmit(event)}
              >
                Add Account
              </a>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default withRouter(AddAdminAccountModal);
