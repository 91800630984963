import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

class ObjectListHeader extends Component {
  static propTypes = {
    headings: PropTypes.array.isRequired
  }

  render() {
    let { headings, fromConsumerAccount, hideHeader } = this.props;
    let flag = fromConsumerAccount ? fromConsumerAccount : false
    return (
      <div className="object-list-header">
        <div className={`object-list-header-primary-heading${flag ? ' primary-override-header' : ''}`}> 
          <h4 className="object-list-header-heading">
            {headings[0]}
          </h4>
        </div>
        <div className={`object-list-header-secondary-headings${flag ? ' secondary-override-header-spacing' : ''}`}>
          {headings.map((heading, index) => {
            if (index > 0) {
              return (
                <h4 key={shortid.generate()} className={`object-list-header-heading${hideHeader && (heading === 'Discount' || heading === 'Description') ? ' hide-header' : ''}${flag ? ' secondary-override-header' : ''}${heading === 'Download' && headings.length -1 === index ? ' adjustDownloadHeader' : ''}${heading === 'Action' ? ' ation-btn-last-align' : ''}`}>
                  {heading}
                </h4>
              );
            }
            return null;
          })}
        </div>
      </div>
    )
  }
}

export default ObjectListHeader;
