import React from "react";
import styles from "./button.module.scss";
import { ReactComponent as NextIcon } from "../../assets/icons/NextIcon.svg";

function PrimaryButton({ text, onClick, title, isNextButton }) {
  return (
    <div
      title={title}
      className={`${styles.primaryButton} ${styles.flex}`}
      onClick={onClick}
    >
      <div style={{ whiteSpace: "nowrap" }}>{text}</div>
      {isNextButton && <NextIcon width={15} height={15} />}
    </div>
  );
}

export default PrimaryButton;
