
import React, {  useCallback } from "react";
import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";


const ACHPlaidLink = (props) => {
  const [publicKey, setPublicKey] = useState("");
  const {
    user,
    isCheck,
    amount,
    invoice_id,
    plaidStatus,
    auth,
    primary_color,
  } = props;
  const [orgKey, setOrgKey] = useState("");

  

  const onSuccess = useCallback(
    (token, metadata) => {
      // send token to server
      props.sendDataBackToServer(token, metadata);
      props.toggleLoading(true);
    },
    [amount]
  );

  const config = {
    env:
      process.env.REACT_APP_STAGE === "production" ? "production" : "sandbox",
    token: publicKey,
    onSuccess,
  };

  const openPlaidModal = () => {
    
  };

  const { open, ready, error } = usePlaidLink(config);

  return plaidStatus ? (
    <button
      className="cta mg-brand2-common-color"
      onClick={props.createPlaidPayment}
      disabled={!ready || !isCheck}
      style={{ backgroundColor: primary_color,borderColor:"#9B9B9B", }}
    >
      Submit Payment
    </button>
  ) : (
    <button
      className="cta mg-brand2-common-color"
      onClick={() => {
        openPlaidModal();
        props.toggleLoading(true);
      }}
      disabled={!ready || !isCheck}
      style={{ backgroundColor: primary_color, borderColor:"#9B9B9B"}}
    >
      Connect using Plaid
    </button>
  );
};
export default ACHPlaidLink;
