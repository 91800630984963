import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import Logo from "../Logo";
import ArrowLink from "../../components/ArrowLink";
import OfferCount from "./OfferCount";

class AppHeader extends Component {
  static propTypes = {
    path: PropTypes.string,
    user: PropTypes.object,
  };

  renderPrevLink(prevLink) {
    return (
      <ArrowLink
        label="Back"
        arrow="left"
        path={prevLink}
        optionalClassName="onboarding-header-prev-link"
      />
    );
  }

  // User Info
  renderUserInitials = (user) => {
    let initials = "";
    if (user.first_name && user.last_name) {
      initials = user.first_name.charAt(0) + user.last_name.charAt(0);
    }
    return initials;
  };

  renderUserName = (user) => {
    let userName = user.first_name + " " + user.last_name;

    return userName;
  };

  render() {
    let {
      user,
      prevLink,
      logoPath,
      appName,
      hideNav,
      headerTab,
      accountHolder,
    } = this.props;
    return (
      <header
        className={`app-header${hideNav ? " consumer-mobile-header" : ""}`}
      >
        {prevLink && this.renderPrevLink(prevLink)}
        <Logo
          linkPath={logoPath}
          linkTitle={appName}
          hideNav={hideNav}
          accountHolder={accountHolder}
        />
        <div className="app-header-label">{headerTab ? headerTab : ""}</div>
        <div className={`app-header-content${hideNav ? " avtar-space" : ""}`}>
          <div className="app-header-user">
            <div
              className={`app-header-user-avatar${
                hideNav ? " avtar-space" : ""
              }`}
            >
              <span>{this.renderUserInitials(user)}</span>
            </div>
            <div
              className={`app-header-user-nav${
                hideNav ? " user-nav-hide" : ""
              } `}
            >
              <div className="user-name">{this.renderUserName(user)}</div>
              <Link className="app-header-user-nav-link" to="/sign-out">
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default AppHeader;
