import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { withRouter, Redirect } from "react-router-dom"
import moment from 'moment';

// API
import * as API from '../../utils/api'

import { showConfirmAlert } from '../../utils/helpers'

// Components
import AppSectionHeader from '../../components/App/AppSectionHeader'
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import DropdownButton from '../../components/DropdownButton'
import MenuItem from '../../components/MenuItem'
import ObjectList from '../../components/ObjectList/ObjectList'
import FlashMessage from '../../components/FlashMessage'

class ViewPendingAddressUser extends Component {

  state = {
    consumers: [],
    isLoading: true,
    isUploading: false,
    showFlash: false,
    totalPendingOffers: 0,
    input: {
      search: {
        value: ""
      }
    },
    hasAnyError: false
  }

  componentDidMount() {
    this.fetchPendingAddressConsumers()
  }

  fetchPendingAddressConsumers = () => {
    API.fetchAllPendingAddressConsumers(this.props.user).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        this.setState(state => ({
          ...state,
          consumers: data.consumers,
          defaultConsumers: data.consumers,
          totalPendingOffers: data.total_pending_offers,
          isLoading: false,
          isUploading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          hasAnyError: true
        }))
      }
    })
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...newPartialInput
      }
    }),
      () => { this.handleSearch() }
    )
  }

  setConsumersData = (dataSet = []) => {
    this.setState(state => ({
      ...state,
      consumers: dataSet
    }))
  }

  handleSearch = () => {
    let queryString = this.state.input.search.value
    if (queryString) {
      API.adminSearch(this.props.user, 'consumers', queryString).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && data.results) {
          this.setConsumersData(data.results)
        } else {
          this.setConsumersData()
        }
      })
    } else {
      this.setConsumersData(this.state.defaultConsumers)
    }
  }

  handleUploadClick = () => {
    this.refs.fileUploader.click()
  }

  uploadFile = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)

    this.setState(state => ({
      ...state,
      isUploading: true
    }),
      () => {
        API.uploadCSV(this.props.user, formData).then(data => {
          if (((typeof data != 'undefined') || (data != null)) && data.results) {
            if (data.message) {
              this.setState(state => ({
                ...state,
                showFlash: true,
                flashMessage: data.message
              }))
            }
            this.fetchConsumers()
          } else {
            this.setState(state => ({
              ...state,
              hasAnyError: true
            }))
          }
        })
      }
    )
  }

  handleRowActionClick = (event) => {
    let { history } = this.props
    const actionBehavior = event.target.getAttribute('data-behavior')
    const consumerId = event.target.getAttribute('data-target')

    switch (actionBehavior) {
      case "account-view":
        history.push(`/admin/accounts/${consumerId}`)
        break
      case "account-edit":
        history.push(`/admin/accounts/${consumerId}/edit`)
        break
      case "account-delete":
        showConfirmAlert('Are you sure want to delete account?', () => {
          this.deactivateConsumer(consumerId)
        })
        break
      case "view-conversation":
        history.push(`/admin/view/conversation/${consumerId}`)
        break
      case "approve-address":
        history.push(`/admin/accounts/${consumerId}/addressDocument`)
        break
      default:
        alert('Error: Something went wrong')
    }
  }

  deactivateConsumer = (id) => {
    let consumers = [...this.state.consumers]
    let targetId = parseInt(id, 10)
    let newConsumers = consumers.filter((consumer) => {
      return consumer.id !== targetId
    })

    if (newConsumers.length < consumers.length) {
      API.deactivateConsumer(this.props.user, id)
      this.setState(state => ({
        ...state,
        consumers: newConsumers
      }))
    }
  }

  dismissFlash = () => {
    this.setState(state => ({
      ...state,
      showFlash: false
    }))
  }

  getAccountsObjectListData = () => {
    const { consumers } = this.state

    return {
      headings: [
        'Name',
        'Offer(s)',
        'Total Debts',
        'Total Owed',
        'Last Payment'
      ],
      columns: consumers.map((consumer) => {
        return {
          primaryColumn: {
            title: consumer.user_address_document_status ? consumer.first_name + ' ' + consumer.last_name + ' (' + consumer.user_address_document_status[0] + ')' : consumer.first_name + ' ' + consumer.last_name,
            titleLinkPath: `/admin/accounts/${consumer.id}`,
            secondaryInfo: consumer.email
          },
          secondaryColumns: [
            {
              title: `${consumer.total_pending_offers_count}`,
            },
            {
              title: `${consumer.total_debt_count} Debts`,
            },
            {
              title: `$${consumer.total_debt_owed}`,
            },
            {
              title: `${consumer.last_payment_date ? this.renderFormatedDate(consumer.last_payment_date) : 'n/a'}`
            }
          ],
          rowLinkPath: `/admin/accounts/${consumer.id}`,
          rowType: 'overflowMenu',
          rowActions: [
            {
              title: 'Account Details',
              dataBehavior: 'account-view',
              dataTarget: consumer.id
            },
            {
              title: 'Edit Account',
              dataBehavior: 'account-edit',
              dataTarget: consumer.id
            },
            {
              title: 'Delete Account',
              optionalClasses: 'alert',
              dataBehavior: 'account-delete',
              dataTarget: consumer.id
            },
            {
              title: 'View Conversations',
              optionalClasses: 'alert',
              dataBehavior: 'view-conversation',
              dataTarget: consumer.id
            },
            {
              title: 'Approve Address',
              optionalClasses: 'alert',
              dataBehavior: 'approve-address',
              dataTarget: consumer.id
            }
          ]
        }
      })
    }
  }

  renderFormatedDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  }

  render() {
    const { appName } = this.props
    const { isLoading, isUploading } = this.state

    if (this.state.hasAnyError) {
      this.props.updateModalState(true, 'OTHER_ERROR', this.state.hasAnyError);
      return <Redirect to={{ pathname: '/sign-out' }} />
    }

    return (isLoading || isUploading)
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <Helmet>
            <title>{appName} | Accounts </title>
          </Helmet>
          {this.state.showFlash ? (
            <FlashMessage
              message={this.state.flashMessage}
              handleFlashDismiss={this.dismissFlash}
            />
          ) : null}

          <AppSectionHeader
            title="Pending Address Verification Accounts"
          />

          <ObjectList
            optionalClasses={"accounts"}
            data={this.getAccountsObjectListData()}
            rowActionClick={(event) => this.handleRowActionClick(event)}
          />
        </div>
      )
  }
}

export default withRouter(ViewPendingAddressUser)
