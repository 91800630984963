import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components
import AppHeader from '../../components/App/AppHeader';
import EmployeeNav from '../../components/Employee/EmployeeNav';

class EmployeePortal extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired
  }

  render() {
    if (!this.props.isLoggedIn || !this.props.user.is_employee) {
      return <Redirect to={{
        pathname: '/login',
        state: { from: this.props.location }
      }}/>
    }

    return (
      <div className="app-portal">
        <AppHeader
          user={ this.props.user }
          appName={ this.props.appName}
          logoPath="/employee/accounts/"
      />
        <div className="app-portal-canvas">
          <EmployeeNav
            path={ this.props.location }
          />
            <div className="wrap">
              { this.props.screen }
            </div>
        </div>
      </div>
    );
  }
}

export default EmployeePortal;
