import React, { Component } from "react";
import { default as ReactApexCharts } from "react-apexcharts";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

class ClientsDashboardChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: `ACP`,
          data: props.ACP, //ACP array
        },
        {
          name: `ADD`,
          data: props.ADD, //ADD array
        },
      ],
      options: {
        chart: {
          height: 300,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        colors: ["#5fd0b9", "#032b6e"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          borderColor: "#979797",
          row: {
            colors: ["transparent"], // takes an array which will be repeated on columns
            opacity: 0.1,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: props.months, //month values for x-axis
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Days",
          },
          min: props.minRange, //min range value
          max: props.maxRange, //max range value
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          floating: true,
          offsetY: -5,
          offsetX: 0,
        },
      },
      percent: props.cei > 0 ? props.cei : 0,
      isLoading: true,
    };
  }

  //re-render the graph when value changes
  componentWillReceiveProps = (props) => {
    let { minRange, maxRange, months, year } = props;
    this.state.options.yaxis.min = parseInt(minRange) - 2;
    this.state.options.yaxis.max = parseInt(maxRange) + 2;
    this.state.options.xaxis.categories = months;
    this.state.series[0].name = `ACP - ${
      year.length > 1 ? year[0] + "-" + year[1] : year[0]
    }`;
    this.state.series[1].name = `ADD - ${
      year.length > 1 ? year[0] + "-" + year[1] : year[0]
    }`;

    setTimeout(() => {
      this.setState({
        isLoading: false,
        percent: props.cei > 0 ? props.cei : 0,
        options: this.state.options,
        series: this.state.series,
      });
    }, 500);
  };

  //circular bar color based on percentage value
  getColor = () => {
    let { percent } = this.state;
    if (percent > 80) {
      return "#7adb6b";
    } else if (percent > 60) {
      return "#5bd176";
    } else if (percent > 40) {
      return "#42c97f";
    } else if (percent > 20) {
      return "#2ac288";
    } else {
      return "#0ab894";
    }
  };

  render() {
    const { appName } = this.props;
    const { series, options, isLoading, percent } = this.state;
    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className="dashboard-content">
        <div className="dashboard-content-chart">
          <div className="panel-heading-secondary report-heading-space">
            Avg Collection period / Avg Delinquent Days
          </div>
          <ReactApexCharts
            options={options}
            series={series}
            type="line"
            height={300}
          />
        </div>
        <div className="dashboard-content-radial">
          <div className="panel-heading-secondary radial-down-space">
            Collection Efficiency Index
          </div>
          <div className="start-from-6">
            <div className="circular-shadow">
              <CircularProgressbarWithChildren
                value={percent}
                styles={{
                  // Customize the root svg element
                  root: {
                    height: 160,
                    width: 160,
                  },
                  // Customize the path, i.e. the "completed progress"
                  path: {
                    // Path color
                    // stroke: this.getColor(),
                    stroke: this.getColor(),
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Customize transition animation
                    transition: "stroke-dashoffset 0.5s ease 0s",
                    // Rotate the path
                    transform: `rotate(180deg)`,
                    transformOrigin: "center center",
                  },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: "#E0E0E0",
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    // Rotate the trail
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center",
                  },
                }}
              >
                <div
                  className="circular-progress-inner"
                  style={{ height: 130, width: 130 }}
                >
                  <p>Percent</p>
                  <div className="circular-progress-percent">{percent}</div>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
          <div className="radial-up-space">
            <div className="radial-bottom-content">
              <div className="radial-percent">0-20</div>
              <div
                className="radial-box"
                style={{ backgroundColor: "#0ab894" }}
              />
            </div>
            <div className="radial-bottom-content">
              <div className="radial-percent">21-40</div>
              <div
                className="radial-box"
                style={{ backgroundColor: "#2ac288" }}
              />
            </div>
            <div className="radial-bottom-content">
              <div className="radial-percent">41-60</div>
              <div
                className="radial-box"
                style={{ backgroundColor: "#42c97f" }}
              />
            </div>
            <div className="radial-bottom-content">
              <div className="radial-percent">61-80</div>
              <div
                className="radial-box"
                style={{ backgroundColor: "#5bd176" }}
              />
            </div>
            <div className="radial-bottom-content">
              <div className="radial-percent">81-100</div>
              <div
                className="radial-box"
                style={{ backgroundColor: "#7adb6b" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsDashboardChart;
