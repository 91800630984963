import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom"

// Utils
import * as API from '../../utils/api'
import { validateEmail, networkMessage, showToast } from '../../utils/helpers'

class OnboardingCreateAccountForm extends Component {

  state = {
    input: {
      firstName: {
        value: "",
        hasError: false
      },
      lastName: {
        value: "",
        hasError: false
      },
      email: {
        value: "",
        hasError: false
      },
      password: {
        value: "",
        hasError: false
      },
      confirmPassword: {
        value: "",
        hasError: false
      },
      userAgreementConfirmed: {
        value: false,
        hasError: false
      },
    },
    accessError: false,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.validateForm()) {
      const userCanidate = {
        'first_name': this.state.input.firstName.value,
        'last_name': this.state.input.lastName.value,
        'email': this.state.input.email.value,
        'password': this.state.input.password.value
      }

      API.signup(userCanidate).then(data => {
       if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          if (data.user) {
            const user = {
              first_name: data.user.first_name,
              last_name: data.user.last_name,
              user_id: data.user.id,
              user_auth_token: data.user.auth_token,
              admin: false,
              super_admin: false
            }
            this.props.updateUserLogin(user).then(
              this.props.history.push("/confirm-account/phone")
            )
          } else {
            this.setState(state => ({
              ...state,
              input: {
                ...state.input,
                email: {
                  value: "",
                  hasError: true,
                }
              },
              accessError: true
            }))
          }
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      })
    }
  }

  validateForm = () => {
    let newInputState = { ...this.state.input }
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'password' || key === 'confirmPassword') {
        if (newInputState.password.value !== newInputState.confirmPassword.value) {
          newInputState[key].hasError = true
        } else if (value.value === '') {
          newInputState[key].hasError = true
        }
      } else if (key === 'email') {
        if (!validateEmail(value.value)) {
          newInputState[key].hasError = true
        }
      } else if (value.value === '') {
        newInputState[key].hasError = true
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true
    }
  }

  renderErrorMessage(input) {
    let message = ''
    if (input === 'email') {
      message = 'Please enter a valid email'
    } else if (input === 'firstName') {
      message = 'Please enter your first name'
    } else if (input === 'lastName') {
      message = 'Please enter your last name'
    } else if (input === 'password' || input === 'confirmPassword') {
      message = 'Please make sure passwords match'
    } else if (input === 'account') {
      message = 'Email Already Exists'
    } else {
      message = 'Please make sure to complete this field'
    }
    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    const { input, accessError, error } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <div>
        {accessError &&
          <div className='input-container error'>
            {this.renderErrorMessage('account')}
          </div>
        }
        <form className="sessions-form" onSubmit={(event) => this.handleSubmit(event)}>

          <div
            className={`input-container${input.firstName.hasError ? ' error' : ''}`}
          >
            <label>First Name</label>
            <input
              name="firstName"
              type="text"
              value={input.firstName.value}
              onChange={event => this.handleInputChange(
                { firstName: { value: event.target.value, hasError: false } }
              )}
            />
            {input.firstName.hasError ? this.renderErrorMessage('firstName') : null}
          </div>
          <div
            className={`input-container${input.lastName.hasError ? ' error' : ''}`}
          >
            <label>Last Name</label>
            <input
              name="lastName"
              type="text"
              value={input.lastName.value}
              onChange={event => this.handleInputChange(
                { lastName: { value: event.target.value, hasError: false } }
              )}
            />
            {input.lastName.hasError ? this.renderErrorMessage('lastName') : null}
          </div>
          <div
            className={`input-container${input.email.hasError ? ' error' : ''}`}
          >
            <label>Email Address</label>
            <input
              name="email"
              type="email"
              autoComplete="new-password"
              value={input.email.value}
              onChange={event => this.handleInputChange(
                { email: { value: event.target.value, hasError: false } }
              )}
            />
            {input.email.hasError ? this.renderErrorMessage('email') : null}
          </div>
          <div
            className={`input-container${input.password.hasError ? ' error' : ''}`}
          >
            <label>Password</label>
            <input
              name="password"
              type="password"
              autoComplete="new-password"
              value={input.password.value}
              onChange={event => this.handleInputChange(
                { password: { value: event.target.value, hasError: false } }
              )}
            />
            {input.password.hasError ? this.renderErrorMessage('password') : null}
          </div>
          <div
            className={`input-container${input.confirmPassword.hasError ? ' error' : ''}`}
          >
            <label>Confirm Password</label>
            <input
              name="confirmPassword"
              type="password"
              value={input.confirmPassword.value}
              onChange={event => this.handleInputChange(
                { confirmPassword: { value: event.target.value, hasError: false } }
              )}
            />
            {input.confirmPassword.hasError ? this.renderErrorMessage('confirmPassword') : null}
          </div>
          <input className="cta" type="submit" value="Next »" />
        </form>
      </div>
    )
  }
}

export default withRouter(OnboardingCreateAccountForm)
