
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class EmployeeNav extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
  }

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return 'active';
    }
  }

  render() {
    return (
      <nav className="admin-nav scrollbar">
        <Link
          className={`admin-nav-link ${this.isActive('/employee/accounts')}`}
          to="/employee/accounts"
        >
          <svg className="icon"><use xlinkHref="#currency"/></svg>
          Accounts
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/employee/profile')}`}
          to="/employee/profile"
        >
          <svg className="icon">
            <use xlinkHref="#admin"/>
          </svg>
          Profile
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/employee/offers')}`}
          to="/employee/offers"
        >
          <svg className="icon"><use xlinkHref="#currency"/></svg>
          Offers
        </Link>
        { /*<Link
          className={`admin-nav-link ${this.isActive('/employee/skipPayment')}`}
          to="/employee/skipPayment"
        >
          <svg className="icon"><use xlinkHref="#setting"/></svg>
          Settings
        </Link> */}
        <Link
          className={`admin-nav-link ${this.isActive('/employee/paymentHistory')}`}
          to="/employee/paymentHistory"
        >
          <svg className="icon"><use xlinkHref="#setting"/></svg>
          Payment History
        </Link>
        {/*<Link
          className={`admin-nav-link ${this.isActive('/employee/lobHistory')}`}
          to="/employee/lobHistory"
        >
          <svg className="icon"><use xlinkHref="#lob"/></svg>
          Mail
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/employee/messageHistory')}`}
          to="/employee/messageHistory/1"
        >
          <svg className="icon"><use xlinkHref="#lob"/></svg>
          Message
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/employee/skipTrace')}`}
          to="/employee/skipTrace"
        >
          <svg className="icon"><use xlinkHref="#setting"/></svg>
          Skip Trace
        </Link> */}
      </nav>
    );
  }
}

export default EmployeeNav;
