import React, { Component } from "react";
import { getTimeToDisplay } from "../../utils/helpers";
import Panel from "../../components/App/Panel";
import lowStockGray from "../../assets/images/goods/low-stock-gray.png";
import noStockGray from "../../assets/images/goods/no-stock-gray.png";
import { getTermsListInSettings } from "../../utils/api";
import doneIcon from "../../assets/icons/checked_icon.svg";
import tooltip from "../../assets/icons/tooltip.png";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import editGreenIcon from "../../assets/icons/edit_green.svg";
import * as API from "../../utils/api";
import { number } from "prop-types";
class ClientsSettingSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daysPastDue: null,
      daysPastDueOld: null,
      isDaysPastMode: false,
      isDaysPastError: false,
      daysPastErrorText: "Days past due field should not be empty.",
      isDaysPastSubmitted: false,
      isDaysPastLoading: false,
      minAmount: "",
      minAmountOld: "",
      isMinAmountMode: false,
      isMinAmountError: false,
      minAmountErrorText: "Minimum Amount can not be 0",
      isMinAmountSubmitted: false,
      isMinAmountLoading: false,
      termsList: [],
    };
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }
  focusTextInput(editMode) {
    this.setState({ ...editMode }, () => {
      this.textInput.current.focus();
    });
  }

  onInputDaysChange = (e) => {
    e.preventDefault();
    let input = e.target.value.trim();
    input = input.replace(/\D/g, "");
    if (input != "") {
      this.setState({ isDaysPastError: false, daysPastDue: input });
    } else {
      this.setState({ isDaysPastError: true, daysPastDue: input });
    }
  };

  onInputAmountChange = (e) => {
    e.preventDefault();
    let input = e.target.value;
    input = input.replace(/[^0-9.]/g, "");

    this.setState({ isMinAmountError: false, minAmount: input });

    // this.validateMinAmount(e.target.value.trim());
  };

  decimal = (value) => {
    let decimalValue = parseFloat(value);
    decimalValue.toFixed(2);
    return decimalValue.toFixed(2);
  };

  getClientTermsList = (message = undefined) => {
    API.getTermsListInSettings(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let newData = data.data;

          this.setState(
            {
              isDaysPastSubmitted: false,
              isDaysPastMode: false,
              isDaysPastLoading: false,
              isMinAmountSubmitted: false,
              isMinAmountMode: false,
              isMinAmountLoading: false,
              minAmount: data.account_min_amount
                ? this.decimal(data.account_min_amount)
                : "",
              minAmountOld: data.account_min_amount
                ? this.decimal(data.account_min_amount)
                : "",
              daysPastDue:
                data.days_past_due >= 0 && data.days_past_due != null
                  ? data.days_past_due
                  : null,
              daysPastDueOld:
                data.days_past_due >= 0 && data.days_past_due != null
                  ? data.days_past_due
                  : null,
            },
            () => {
              if (message) {
                this.props.updateModalState(true, "SUCCESS", {
                  message: message,
                });
              }
              this.setState((state) => ({
                ...state,
                termsList: newData,
              }));
            }
          );
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  componentDidMount() {
    this.getClientTermsList();
  }

  // validateDaysPastDue = (data) => {
  //   let status = true;
  //   let errorMsg = "";

  //   if (data && !data.match(numbers) ) {
  //     status = false;
  //     this.setState({
  //       isDaysPastError: true,
  //       daysPastErrorText: "Please enter valid days count.",
  //     });
  //   }else if (data == null || data === ''){
  //     status = true
  //   }
  //   if (status === true) {
  //     this.setState({ isDaysPastError: false, daysPastErrorText: "" });
  //   }
  //   return status;
  // };

  validateMinAmount = (data) => {
    let status = true;
    let errorMsg = "";
    let Regex = /^([0-9]+([.][0-9])?|[.][0-9])*$/;
    let RegexToCheckDecimal = /^([0-9]+([.][0-9]{0,2})?|[.][0-9]{0,2})$/;

    if (data && !Regex.test(data)) {
      status = false;
      this.setState({
        isMinAmountError: true,
        minAmountErrorText: "Please enter valid amount.",
      });
    } else if (data && !RegexToCheckDecimal.test(data)) {
      status = false;
      this.setState({
        isMinAmountError: true,
        minAmountErrorText: "Allowed amount of 2 decimal places only.",
      });
    }
    if (status === true) {
      this.setState({ isMinAmountError: false, minAmountErrorText: "" });
    }
    return status;
  };

  onSubmitDaysPastDue = () => {
    let dueDaysFlag = "DAYS";
    if (!this.state.isDaysPastError) {
      this.setState({
        isDaysPastSubmitted: true,
        isDaysPastLoading: true,
      });

      let payload = {
        days_past_due: parseInt(this.state.daysPastDue),
      };
      this.updateAccountPlacementEligibility(payload, dueDaysFlag);
    }
    //   this.setState({ isDaysPastLoading: false });
    // }
  };

  onSubmitMinAmount = () => {
    let minAmountFlag = "AMT";
    if (!this.state.isMinAmountError) {
      this.setState({
        isMinAmountSubmitted: true,
        isMinAmountLoading: true,
      });
      // if (this.validateMinAmount(this.state.minAmount)) {

      let payload = {
        account_min_amount:
          this.state.minAmount.length > 0 ? this.state.minAmount : null,
      };
      this.updateAccountPlacementEligibility(payload, minAmountFlag);
    }
    //   this.setState({ isMinAmountLoading: false });
    // }
  };

  updateAccountPlacementEligibility = (payload, flag) => {
    API.updateAccountPlacementEligibility(this.props.user, payload).then(
      (data) => this.handleCommonResponseDaysPastDue(data)
    );
  };

  handleCommonResponseDaysPastDue = (data) => {
    if (data && data.status_code) {
      if (data.status_code == 200) {
        this.getClientTermsList(data.message);
      } else {
        this.setState({ isMinAmountLoading: false, isDaysPastLoading: false });
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      }
    } else {
      this.setState({ isMinAmountLoading: false, isDaysPastLoading: false });
    }
  };

  renderPaymentContent = () => {
    let { connectStripeData } = this.props;
    let status = "";
    let color = "red";
    if (connectStripeData && connectStripeData.stripe_connect_status) {
      status = connectStripeData.stripe_connect_status;
      if (connectStripeData.stripe_connect_status == "ACCEPTING PAYMENTS") {
        color = "green";
      } else if (
        connectStripeData.stripe_connect_status == "PENDING APPROVAL"
      ) {
        color = "";
      } else {
        color = "red";
      }
    }
    return (
      <div className='side-wd'>
        <Panel>
          <div className='set-col-con'>
            <h4
              className='panel-heading-secondary'
              style={{
                width: "auto",
                marginRight: 5,
                marginBottom: "0.075rem",
              }}
            >
              Status:
            </h4>
            <p
              style={
                color == "green"
                  ? {
                      fontSize: 14,
                      color: "#409D57",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }
                  : color == "red"
                  ? {
                      fontSize: 14,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      color: "#fb5c82",
                      lineHeight: "120%",
                    }
                  : {
                      fontSize: 14,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      color: "#000000",
                    }
              }
            >
              {status}
            </p>
          </div>
        </Panel>
        {color != "green" && (
          <Panel>
            <p className='qb-p'>
              Enable your customized payment portal and start accepting payments
              from your customers today. Click the Enable Payments button to get
              started. Contact us with any questions.
            </p>
          </Panel>
        )}
        {color == "green" ? (
          <div className='history-btn'>
            <button
              onClick={this.props.paymentDashboardClick}
              style={{ marginLeft: 0, maxWidth: "14rem" }}
            >
              Payments Dashboard
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  renderQBContent = () => {
    const { isQBConnected, lastSyncDate, isEditPref } = this.props;
    return (
      <div className='side-wd'>
        <Panel>
          <p className='qb-p'>
            Import your QuickBooks Online account data, including Customer data,
            Invoice data and Payment data. Apply payments to your invoices made
            via your custom branded MakeGood payment processing account.
          </p>
        </Panel>
        <Panel>
          <div className='set-col-con'>
            <h4 className='panel-heading-secondary set-col-con-key'>Status:</h4>
            <p
              style={
                isQBConnected
                  ? {
                      color: "#409D57",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }
                  : {
                      color: "#fb5c82",
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }
              }
            >
              {isQBConnected ? "CONNECTED" : "NOT CONNECTED"}
            </p>
          </div>
          <div className='set-col-con'>
            <h4 className='panel-heading-secondary set-col-con-key'>
              Last Sync:
            </h4>
            <p className='side-bar-value'>
              {lastSyncDate ? getTimeToDisplay(lastSyncDate) : "NEVER"}
            </p>
          </div>
          <div className='set-col-con'>
            <h4 className='panel-heading-secondary set-col-con-key'>
              Next Sync:
            </h4>
            <p className='side-bar-value'>{"01:00 AM EST"}</p>
          </div>
        </Panel>
        {!isEditPref && isQBConnected && (
          <div className='history-btn'>
            <button
              onClick={(event) => {
                this.props.viewpreferences(true);
              }}
              style={{ marginLeft: 0 }}
            >
              Edit Preferences
            </button>
          </div>
        )}
        {isEditPref && isQBConnected && (
          <div className='history-btn qb-side-btns'>
            <button
              onClick={(event) => {
                this.props.viewpreferences(false);
              }}
            >
              Sync
            </button>
            <button onClick={(event) => this.props.disconnectQB(event)}>
              Disconnect
            </button>
          </div>
        )}
      </div>
    );
  };

  renderGeneralContent = () => {
    return (
      <div className='side-wd'>
        <Panel>
          <p className='qb-p'>
            Use this page to configure your task settings, including whether to
            automatically or manually send the communication, when each task
            should trigger, how the communication will be sent, which clients
            the tasks apply to and an option to send the communications to an
            internal contact, such as an account manager.
          </p>
        </Panel>
      </div>
    );
  };

  renderContentForCreditTerms = () => {
    return (
      <div className='side-wd'>
        <Panel>
          <p className='qb-p'>
            Use this page to manage credit terms for your customer accounts.
            Select one term to be the default term applied to your customer
            accounts and, if needed, override the default term at the account
            level. Below, you also define the amount of past due days an Account
            ages before being marked as eligible for placement with a
            collections agency.
          </p>
        </Panel>
      </div>
    );
  };

  renderGoodsAndServicesContent = () => {
    let { goodsStockProps } = this.props;
    return (
      <div className='side-wd'>
        <div>
          <img style={{ height: "10rem" }} src={lowStockGray} />
          <div style={{ fontWeight: "bold" }}>
            Low Stock Items = {goodsStockProps.lowStock}
          </div>
        </div>
        <div>
          <img style={{ height: "10rem" }} src={noStockGray} />
          <div style={{ fontWeight: "bold" }}>
            Out of Stock Items = {goodsStockProps.outStock}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { title, activeTab, utilities, optionalClassNames, defaultValue } =
      this.props;

    return (
      <div className='app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar'>
        <div
          className={`app-section-header combine-header client-section-header${
            optionalClassNames ? " " + optionalClassNames : ""
          }`}
        >
          <div
            className={`app-section-header-content`}
            style={{ marginRight: "2rem" }}
          >
            <p className='app-section-header-heading side-hed-col'>{title}</p>
          </div>
          <div className='tab-utility' style={{ margin: "25px" }}>
            {utilities}
          </div>
        </div>
        {activeTab === 3
          ? this.renderPaymentContent()
          : activeTab === 2
          ? this.renderQBContent()
          : activeTab === 1
          ? this.renderGeneralContent()
          : activeTab === 6
          ? this.renderContentForCreditTerms()
          : activeTab === 4 && this.props.goodsStockProps.goodsTab == 1
          ? this.renderGoodsAndServicesContent()
          : ""}
        {activeTab === 6 && (
          <>
            <div className='mobile-view-default-term'>
              <label htmlFor='accountNo' className='mobile-view-default-term'>
                Default Term
              </label>
              <div className='invoice-amt-owed' style={{ fontSize: "18px" }}>
                {defaultValue == "" ||
                defaultValue == null ||
                defaultValue == undefined
                  ? "No Default Term is selected"
                  : defaultValue}
              </div>
            </div>
            <div className='account-placement-eligibility-div1'>
              <div className='heading-wrapper'>
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: "0.975em",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  Account Placement Eligibility
                </p>
              </div>
              <div
                className='action-icon-wrapper'
                style={{ height: "auto", float: "right" }}
              >
                <img
                  src={tooltip}
                  className='tooltip-icon'
                  title={
                    "Use Account Placement Eligibility to track Customer Accounts that are severely delinquent and at risk of defaulting. Accounts eligible for placement with a professional collection agency will have placement eligibility icon indicators within the Accounts screen."
                  }
                />
              </div>
            </div>

            <div>
              <div className='account-placement-eligibility'>
                <div className='account-number-field-div'>
                  <label htmlFor='daysPastDue'>Days Past Due</label>
                  <input
                    type='text'
                    placeholder='Enter # of Days'
                    ref={this.textInput}
                    value={
                      this.state.isDaysPastMode
                        ? this.state.daysPastDue != null
                          ? this.state.daysPastDue
                          : ""
                        : this.state.daysPastDueOld != null
                        ? `${this.state.daysPastDueOld}` + " Days Past Due"
                        : ""
                    }
                    name='daysPastDue'
                    id='daysPastDue'
                    maxLength={4}
                    onChange={(e) => this.onInputDaysChange(e)}
                    disabled={this.state.isDaysPastMode ? false : true}
                    className={`account-input ${
                      this.state.isDaysPastMode &&
                      this.state.isDaysPastSubmitted &&
                      this.state.isDaysPastError
                        ? "error-mode"
                        : this.state.isDaysPastMode
                        ? "edit-mode"
                        : ""
                    }`}
                  />
                </div>
                <div className='action-wrapper'>
                  {this.state.isDaysPastMode &&
                  !this.state.isDaysPastLoading ? (
                    <img
                      src={doneIcon}
                      className='save-icon'
                      title={"Save Days Past Due"}
                      onClick={this.onSubmitDaysPastDue}
                    />
                  ) : this.state.isDaysPastMode &&
                    this.state.isDaysPastLoading ? (
                    <AppCanvasLoader />
                  ) : (
                    <img
                      src={editGreenIcon}
                      className='edit-icon'
                      title={"Edit Days Past Due"}
                      onClick={() => {
                        this.focusTextInput({
                          isDaysPastMode: true,
                          isMinAmountMode: false,
                          daysPastDue: this.state.daysPastDueOld,
                        });
                      }}
                    />
                  )}
                </div>
                <div className='action-icon-wrapper'>
                  <img
                    src={tooltip}
                    className='tooltip-icon'
                    title={
                      " Entering “0” in the Days Past Due field will disable the Account Placement Eligibility feature.Enter the days past due an account will reach to be eligible for placement with a professional collection agency."
                    }
                  />
                </div>
              </div>
              {this.state.isDaysPastMode && this.state.isDaysPastError ? (
                <div className='error-text'>{this.state.daysPastErrorText}</div>
              ) : null}
              <br />
              <div className='account-placement-eligibility'>
                <div className='account-number-field-div'>
                  <label htmlFor='minAmt'>Minimum Amt (Optional)</label>
                  <input
                    type='text'
                    placeholder='Enter Amounts'
                    ref={this.textInput}
                    value={
                      this.state.isMinAmountMode
                        ? this.state.minAmount
                          ? this.state.minAmount
                          : ""
                        : this.state.minAmountOld
                        ? "$" + `${this.decimal(this.state.minAmountOld)}`
                        : ""
                    }
                    name='minAmt'
                    id='minAmt'
                    maxLength={20}
                    onChange={(e) => this.onInputAmountChange(e)}
                    disabled={this.state.isMinAmountMode ? false : true}
                    className={`account-input ${
                      this.state.isMinAmountMode &&
                      this.state.isMinAmountSubmitted &&
                      this.state.isMinAmountError
                        ? "error-mode"
                        : this.state.isMinAmountMode
                        ? "edit-mode"
                        : ""
                    }`}
                  />
                </div>
                <div className='action-wrapper'>
                  {this.state.isMinAmountMode &&
                  !this.state.isMinAmountLoading ? (
                    <img
                      src={doneIcon}
                      className='save-icon'
                      title={"Save Minimum Amount"}
                      onClick={this.onSubmitMinAmount}
                    />
                  ) : this.state.isMinAmountMode &&
                    this.state.isMinAmountLoading ? (
                    <AppCanvasLoader />
                  ) : (
                    <img
                      src={editGreenIcon}
                      className='edit-icon'
                      title={"Edit Minimum Amount"}
                      onClick={() => {
                        this.focusTextInput({
                          isDaysPastMode: false,
                          isMinAmountMode: true,
                          minAmount: this.state.minAmountOld,
                        });
                      }}
                    />
                  )}
                </div>
                <div className='action-icon-wrapper'>
                  <img
                    src={tooltip}
                    className='tooltip-icon'
                    title={
                      " Enter a minimum amount to exclude low balances from being eligible for placement with a professional collection agency. This field is optional. Entering $0.00 in the Minimum Amount field will disable the Minimum Amount criteria. If $0.00 is entered, only the Days Past Due field will be used to mark accounts Eligible for Placement."
                    }
                  />
                </div>
              </div>
              {this.state.isMinAmountMode && this.state.isMinAmountError ? (
                <div className='error-text'>
                  {this.state.minAmountErrorText}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ClientsSettingSidebar;
