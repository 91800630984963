import React, { Component } from 'react';

class FlashMessage extends Component {

  render() {
    return (
      <div className="flash mg-flash">
        {this.props.message}
        <a className="close" onClick={this.props.handleFlashDismiss} title="Dismiss Message">&times;</a>
      </div>
    );
  }
}

export default FlashMessage;
