import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import settings from "../../assets/images/header/settings.png";
import sync_icon_static from "../../assets/images/header/sync-blue.png";
import manageUser from "../../assets/images/header/manage-user.png";
import { Settings } from "@material-ui/icons";


class ClientFlyoutMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      isFlyOut: false,
    }
  }
  static propTypes = {
    path: PropTypes.string.isRequired,
  };

  renderElements = (item,index) =>{
let {MenuOptions ,path} = this.props;
    return (
        
        <Link to={`${item.path}`} style={{textDecoration: 'none'}}>
            <span className="Flyout-menu" onClick={() =>{
              if(path === '/welcome-screen' && index == 2){
                this.props.handleBack();
              }
                this.props.closeFlyout();
            }} style={{borderBottom: index == MenuOptions.length-1 ? '' :'0px'}}>
                <img src={item.icon}  width='25px' height='25px' />
                <span style={{marginLeft: '5px',fontFamily: 'Open Sans',fontWeight: 'bold'}}>{item.label}</span>
            </span>
        </Link>
        
    );
  }

  render() {
      let {MenuOptions} = this.props;
    return (
        <>
        {MenuOptions.map((item,index) =>{
            return this.renderElements(item,index)
        })}
        
        </>
    );
  }
}

export default ClientFlyoutMenu;
