import React, { Component } from 'react'

// Partials
import ConsumerAccountsPaymentPlanForm from './Partials/ConsumerAccountsPaymentPlanForm'
import ConsumerAccountsOneTimePaymentForm from './Partials/ConsumerAccountsOneTimePaymentForm'
import ConsumerAccountsPaymentPayForm from './Partials/ConsumerAccountsPaymentPayForm'

// Components
import AppCanvasLoader from '../../../components/App/AppCanvasLoader'

// Utils
import {
  getStrippedDollarAmount,
} from '../../../utils/helpers'

class ConsumerAccountsPaymentForm extends Component {
  state = {
    input: {
      paymentPlan: {},
      oneTimePayment: {},
      creditCardInformation: {}
    },
    slideNumber: 1,
    isLoading: false
  }

  handleFormSubmit = (stripeToken, amount) => {
    const { input } = this.state
    this.setState(state => ({
      ...state,
      isLoading: true
    }))

    switch(this.props.formType) {
      case 'payment-plan':
        this.props.handleFormSubmit(
          input.paymentPlan.numberOfPayments.value,
          input.paymentPlan.firstPaymentDate.value,
          stripeToken
        )
        // eslint-disable-next-line no-unreachable
        break
      case 'one-time-payment':
        this.props.handleFormSubmit(
          getStrippedDollarAmount(input.oneTimePayment.paymentAmount.value),
          input.oneTimePayment.paymentDate.value,
          stripeToken,
          amount
        )
        // eslint-disable-next-line no-unreachable
        break
      case 'settle-account':
        this.props.handleFormSubmit(stripeToken, amount)
        // eslint-disable-next-line no-unreachable
        break
      default:
        return null
        // eslint-disable-next-line no-unreachable
        break
    }
  }

  showFormSlide = (event, slideNumber) => {
    event.preventDefault()

    window.scrollTo(0, 0)

    this.setState(state => ({
      ...state,
      slideNumber: slideNumber
    }))
  }

  handleSlideChange = (event, slideNumber, formInput, formType) => {
    event.preventDefault()

    if (formType === 'payment-plan') {
      this.setState(state => ({
        ...state,
        input: {
          ...state.input,
          paymentPlan: formInput
        }
      }))
    } else if (formType === 'one-time-payment') {
      this.setState(state => ({
        ...state,
        input: {
          ...state.input,
          oneTimePayment: formInput
        }
      }))
    }
    this.showFormSlide(event, slideNumber)
  }

  renderFormSlide() {
    const { slideNumber } = this.state
    const { formType, account } = this.props

    if( slideNumber === 1 ) {
      if (formType === 'one-time-payment') {
        return (
          <div className="consumer-accounts-payment-form-slide">
            <ConsumerAccountsOneTimePaymentForm
              handleFormSubmit={this.handleFormSubmit}
              handleContinue={this.handleSlideChange}
              user={this.props.user}
              stripePubKey={this.props.stripePubKey}
              formType={formType}
              originalCollectionAmount={getStrippedDollarAmount(account.originalCollectionAmount)}
            />
          </div>
        )
      } else if (formType === 'payment-plan') {
        return (
          <div className="consumer-accounts-payment-form-slide">
            <ConsumerAccountsPaymentPlanForm
              originalDebtAmount={getStrippedDollarAmount(account.originalCollectionAmount)}
              handleFormSubmit={this.handleFormSubmit}
              handleContinue={this.handleSlideChange}
              user={this.props.user}
              stripePubKey={this.props.stripePubKey}
              formType={formType}
            />
          </div>
        )
      } else if (formType === 'settle-account') {
        return (
          <div className="consumer-accounts-payment-form-slide">
            <ConsumerAccountsPaymentPayForm
              originalCollectionAmount={getStrippedDollarAmount(account.originalCollectionAmount)}
              handleFormSubmit={this.handleFormSubmit}
              user={this.props.user}
              stripePubKey={this.props.stripePubKey}
              formType={formType}
            />
          </div>
        )
      }
    } else if (slideNumber === 2) {
      return (
        <div className="consumer-accounts-payment-form-slide">
          <ConsumerAccountsPaymentPayForm
            handleFormSubmit={this.handleFormSubmit}
            user={this.props.user}
            stripePubKey={this.props.stripePubKey}
          />
          <a
            className="text-link"
            onClick={(event) => this.showFormSlide(event, 1)}
          >
            Edit offer
          </a>
        </div>
      )
    }
  }

  render() {
    const { isLoading } = this.state;
    const {hideLoader} = this.props

    return (isLoading && !hideLoader)
    ? (
      <AppCanvasLoader />
    ) : (
      <div
        className="consumer-accounts-payment-form"
        style={{marginBottom: 100}}
      >
        {this.renderFormSlide()}
      </div>
    )
  }
}

export default ConsumerAccountsPaymentForm
