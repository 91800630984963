import React, { Component } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { Link } from "react-router-dom";
import MenuItem from "../../components/MenuItem";
import DropdownOverflowMenu from "../../components/ObjectList/DropdownOverflowMenu";

class AppSectionHeader extends Component {
  constructor() {
    super();
    this.state = {
      consumer: [{ id: 1 }],
      rowAction: [],
    };
  }

  static propTypes = {
    backLink: PropTypes.object,
    optionalClassNames: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    offerFilter: PropTypes.object,
    utilities: PropTypes.object,
    classFlag: PropTypes.bool,
  };

  componentDidMount() {
    if (this.props && this.props.account) {
      this.setState({ consumer: this.props.account }, () => {
        this.setRowActionData();
      });
    }
  }

  setRowActionData() {
    this.setState({
      rowAction: [
        {
          title: "Add New Debt",
          dataBehavior: "account-debt",
          dataTarget: this.state.consumer.id,
        },
        {
          title: "Approve Address",
          optionalClasses: "alert",
          dataBehavior: "approve-address",
          dataTarget: this.state.consumer.id,
        },
        {
          title: "Set Threshold",
          optionalClasses: "alert",
          dataBehavior: "automatic-negotiation",
          dataTarget: this.state.consumer.id,
          dataThreshold: this.state.consumer.threshold
            ? this.state.consumer.threshold[0]
            : "",
        },
        {
          title: "Assign Employee",
          optionalClasses: "alert",
          dataBehavior: "assign-employee",
          dataTarget: this.state.consumer.id,
        },
        {
          title: "Send New Letter",
          optionalClasses: "alert",
          dataBehavior: "send-letter",
          dataTarget: this.state.consumer.id,
        },
        {
          title: "Send New Email",
          optionalClasses: "alert",
          dataBehavior: "send-new-mail",
          dataTarget: this.state.consumer.id,
        },
        {
          title: "Send Automated Mails",
          optionalClasses: "alert",
          dataBehavior: "send-mail",
          dataTarget: this.state.consumer.id,
          verified: this.state.consumer.verified
            ? this.state.consumer.verified
            : false,
          startMails: this.state.consumer.start_automated_mail_at
            ? this.state.consumer.start_automated_mail_at
            : "",
        },
      ],
    });
  }

  renderBackLink(link) {
    if (link !== undefined && link.arrangeLink) {
      return (
        <Link
          className="app-section-header-back-link"
          to={link.path}
          onClick={this.props.handleBackLink}
        >
          « {link.text}
        </Link>
      );
    } else if (link !== undefined) {
      return (
        <Link className="app-section-header-back-link" to={link.path}>
          « {link.text}
        </Link>
      );
    }
  }

  render() {
    let {
      title,
      subtitle,
      utilities,
      optionalClass,
      optionalClassNames,
      Names,
      backLink,
      offerFilter,
      showHeaderThreeDots,
      titleClasses,
      classFlag,
    } = this.props;
    let style = showHeaderThreeDots ? { justifyContent: "space-between" } : {};
    return (
      <div
        className={`${"app-section-header"}${
          optionalClassNames ? " " + optionalClassNames : ""
        }`}
        style={style}
      >
        {classFlag ? (
          <div>
            {this.renderBackLink(backLink)}
            <h1
              style={{
                color: "black",
                marginBottom: "10px",
                fontFamily: "Open Sans",
                fontSize: "1.875em",
                fontWeight: 700,
              }}
            >
              {this.props.title}
            </h1>
            {subtitle && (
              <h3 className="app-section-header-subheading">{subtitle}</h3>
            )}
          </div>
        ) : (
          <div className={`app-section-header-content`}>
            {this.renderBackLink(backLink)}
            <h1
              className={`app-section-header-heading app-section-header-mobile${
                titleClasses ? " " + titleClasses : ""
              }`}
            >
              {this.props.title}
            </h1>
            {subtitle && (
              <h3 className="app-section-header-subheading">{subtitle}</h3>
            )}
          </div>
        )}
        {showHeaderThreeDots && (
          <div className="consumer-three-dots">
            <DropdownOverflowMenu showThreeDots={true}>
              {this.state.rowAction.map((rowAction) => {
                return (
                  <MenuItem
                    key={shortid.generate()}
                    dataBehavior={rowAction.dataBehavior}
                    dataTarget={rowAction.dataTarget}
                    dataThreshold={
                      rowAction.dataThreshold ? rowAction.dataThreshold : ""
                    }
                    optionalClasses={rowAction.optionalClasses}
                    handleClick={(event) => this.props.rowActionClick(event)}
                    verified={rowAction.verified ? rowAction.verified : ""}
                    startMails={
                      rowAction.startMails ? rowAction.startMails : ""
                    }
                  >
                    {rowAction.title}
                  </MenuItem>
                );
              })}
            </DropdownOverflowMenu>
          </div>
        )}
        {offerFilter}
        {utilities}
      </div>
    );
  }
}

export default AppSectionHeader;
