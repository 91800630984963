import { data } from "jquery";
import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ClientsTaskSidebar from "../../components/Clients/ClientsTaskSidebar";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";

class ClientsTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 1,
      task_list: [],
      tasks: [],
      taskName: "",
      isLoading: true,
      showLoader: false,
      leftLoader: false,
      hasMoreData: false,
      sortHeader: "",
      sortType: false,
      offset: 0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    let sort_by = this.state.sortType ? "asc" : "desc";
    API.getSettingsTasks(this.props.user).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        typeof data != "undefined" ||
        (data != null && data.status_code && data.status_code === 200)
      ) {
        if (data.data) {
          let { task_list, tasks } = data.data;
          task_list = task_list.filter(
            (task) => task.name !== "Send Multiple Invoices To Client"
          );
          this.setState(
            {
              task_list,
              tasks,
              hasMoreData: tasks.length < 10 ? false : true,
            },
            () => this.setTaskName()
          );
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  getInvoiceTask = (reload) => {
    let { id, offset, tasks } = this.state;
    let sort_key = this.getSortKey();
    let sort_by = this.state.sortType ? "asc" : "desc";
    API.getInvoiceTasks(this.props.user, id, offset, sort_key, sort_by).then(
      (data) => {
        if (
          typeof data != "undefined" ||
          (data != null && data.status_code && data.status_code === 200)
        ) {
          if (data.data) {
            let check = reload ? tasks : false;
            let newData = check ? tasks.concat(data.data) : data.data;

            this.setState((state) => ({
              ...state,
              tasks: newData,
              isLoading: false,
              hasMoreData: data.data.length < 10 ? false : true,
            }));
          }
          this.setState({
            isLoading: false,
            showLoader: false,
            leftLoader: false,
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            leftLoader: false,
            showLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  sortInvoiceData = (header, type) => {
    this.setState(
      {
        sortHeader: header,
        sortType: type,
        offset: 0,
      },
      () => {
        this.getInvoiceTask(false);
      }
    );
  };

  getSortKey = () => {
    let { sortHeader } = this.state;
    if (sortHeader === "Invoice #") {
      return "invoice_number";
    } else if (sortHeader === "Account Name") {
      return "display_name";
    } else if (sortHeader === "Auto Scheduled") {
      return "auto_scheduled";
    } else if (sortHeader === "Status") {
      return "status";
    } else if (sortHeader === "Complete Date") {
      return "complete_date";
    } else {
      return "due_date";
    }
  };

  compare = (a, b) => {
    const statusA = a.status.toUpperCase();
    const statusB = b.status.toUpperCase();

    let comparison = 0;
    if (statusA > statusB) {
      comparison = -1;
    } else if (statusA < statusB) {
      comparison = 1;
    }
    return comparison;
  };

  getSortedArray = (tasks) => {
    if (tasks && tasks.length > 1) {
      tasks.sort(this.compare);
    }
    return tasks;
  };

  setTaskName = () => {
    const { task_list } = this.state;
    if (task_list && task_list.length) {
      this.setState({ taskName: task_list[0].name, id: task_list[0].id });
    }
  };

  handleTabChange = (data) => {
    this.setState(
      { taskName: data.name, id: data.id, offset: 0, leftLoader: true },
      () => {
        this.getInvoiceTask(false);
      }
    );
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.getInvoiceTask(true);
        }
      );
    }
  };

  rowExpanderClick = (event) => {
    let { history } = this.props;
    if (event.row && event.row.invoice_id) {
      history.push({
        pathname: `/client/invoices/${event.row.invoice_id}`,
        state: { backPath: `/client/tasks` },
      });
    }
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "--/--/----";
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Account Name",
        style: {
          width: "25%",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Invoice #",
        style: {
          width: "15%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Status",
        style: {
          width: "13%",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Auto Scheduled",
        style: {
          width: "15%",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Scheduled Date",
        style: {
          width: "15%",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      {
        name: "Assigned To",
        style: {
          width: "15%",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      {
        name: "Complete Date",
        style: {
          width: "15%",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "flex-end",
        },
      },
      { name: "expander", style: {} },
    ];

    columns = data.map((item, index) => {
      return {
        primaryRow: {
          key: "Account Name",
          title: item.display_name ? item.display_name : "",
          style: { width: "10%", display: "flex" },
        },
        secondaryColumns: [
          {
            title: item.display_name ? item.display_name : "",
            hideForMobile: true,
          },
          {
            key: "Invoice #",
            title: item.invoice_number,
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Status",
            title: item.status === "Not_Started" ? "Pending" : item.status,
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Auto Scheduled",
            title:
              (item.status && item.status == "Completed") ||
              item.status == "Failed" ? (
                <div className='status-point col3 task-auto-status' />
              ) : item.assigned_to && item.assigned_to == "System" ? (
                <div className='status-point col1 task-auto-status' />
              ) : item.status &&
                item.status == "Pending" &&
                item.assigned_to &&
                item.assigned_to != "System" ? (
                <div className='status-point col2 task-auto-status' />
              ) : (
                ""
              ),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Scheduled Date",
            title: this.getDate(item.due_date),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Assigned to",
            title:
              item.assigned_to && item.assigned_to == "System"
                ? "System"
                : "User",
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Complete Date",
            title: this.getDate(item.complete_date),
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
            style: { width: 0 },
          },
        ],
        row: item,
      };
    });

    return {
      headings,
      columns,
    };
  };

  render() {
    const { appName } = this.props;
    const { task_list, tasks, isLoading, leftLoader, error, taskName } =
      this.state;

    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>{appName} | Tasks </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsTaskSidebar
            user={this.props.user}
            title={"Tasks"}
            task_list={task_list}
            handleTabChange={this.handleTabChange}
          />
          <div className='app-sidebar-layout-canvas-content setting-side-canvas'>
            <div className='big-device-grad '>
              <div className='he-hed'>{taskName}</div>
              {leftLoader ? (
                <div className='mobile-loader'>
                  <AppCanvasLoader />
                </div>
              ) : tasks && tasks.length ? (
                <BottomScrollListener
                  offset={50}
                  onBottom={this.handleContainerOnBottom}
                  debounce={0}
                >
                  {(scrollRef) => (
                    <div>
                      {isSmallDevice ? (
                        <div className='big-device-grad invoice-line'>
                          <div
                            ref={scrollRef}
                            className='scrollbar task-scroll'
                          >
                            <ClientsCardView
                              data={this.getObjectListData(
                                this.getSortedArray(
                                  JSON.parse(JSON.stringify(tasks))
                                )
                              )}
                              rowActionClick={(event) =>
                                this.handleRowActionClick(event)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginBottom: "1em" }}>
                          <InvoiceObjectList
                            data={this.getObjectListData(
                              JSON.parse(JSON.stringify(tasks))
                            )}
                            hideForMobile={true}
                            rowActionClick={(event) =>
                              this.rowExpanderClick(event)
                            }
                            scrollRef={scrollRef}
                            optionalTableClasses='send-invoice-list'
                            optionalClasses='scrollbar in-sc'
                            sortHeaders={[
                              this.state.sortHeader,
                              this.state.sortType,
                            ]}
                            sortInvoiceData={this.sortInvoiceData}
                          />
                        </div>
                      )}
                      {this.state.showLoader && (
                        <div style={{ marginTop: "-3em" }}>
                          <AppCanvasLoader />
                        </div>
                      )}
                    </div>
                  )}
                </BottomScrollListener>
              ) : (
                <div className='admin-admins-empty-state'>
                  <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                    No Tasks Available
                  </h3>
                  <p>There are currently no tasks available.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsTasks;
