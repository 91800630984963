import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import deleteImg from "../../assets/icons/delete.png";
import editImg from "../../assets/icons/edit_blue.svg";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import CardView from "../../components/CardView/CardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";

class SuperAdminProfile extends Component {
  constructor() {
    super();
    this.state = {
      profileData: [],
      isLoading: true,
      offset: 0,
      hasMoreData: false,
      ViewMoreType: false,
      ViewMoreId: "",
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    this.callApi();
  }

  callApi = (reload, resetOffset) => {
    let { offset } = this.state;
    resetOffset && this.setState({ offset: 0 });
    offset = resetOffset ? 0 : offset;
    API.getAdminProfileList(this.props.user, offset).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let tasks = reload ? this.state.profileData : false;
          let newData = tasks ? tasks.concat(data.data) : data.data;
          this.setState((state) => ({
            ...state,
            profileData: newData,
            isLoading: false,
            hasMoreData: data.data.length < 2 ? false : true,
          }));
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleDeleteProfile = (row) => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to delete profile?",
      confirmClick: this.callDeleteApi,
      closeOnClickOutside: true,
      methodData: row,
    });
  };

  callDeleteApi = (row) => {
    API.deleteAdminProfile(this.props.user, row.id).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.setState({ isLoading: false, offset: 0 }, () => {
          this.callApi(false, true);
        });
      } else {
        if (data && data.status_code && data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    });
  };

  handleEditProfile = (data) => {
    let props = {
      reload: this.callApi,
      editProfileData: data,
      isEdit: true,
    };
    this.props.updateModalState(true, "ADD_TEMPLATE_PROFILE", props);
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        }),
        () => {
          this.callApi(true);
        }
      );
    }
  };

  documentAction = (row) => {
    return (
      <div>
        <button
          title='Edit'
          style={{
            background: "transparent",
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => this.handleEditProfile(row)}
        >
          <img src={editImg} width='16' height='16' />
        </button>
        <button
          title='Delete'
          style={{
            background: "transparent",
            cursor: "pointer",
          }}
          onClick={() => this.handleDeleteProfile(row)}
        >
          <img src={deleteImg} width='28' height='28' />
        </button>
      </div>
    );
  };

  handleViewMore = (data, type) => {
    if (type == "TEMP") {
      this.setState({ ViewMoreType: type, ViewMoreId: data.id });
    } else if (type == "ORG") {
      this.setState({ ViewMoreType: type, ViewMoreId: data.id });
    }
  };

  renderRoundedCard = (data, type, rowData) => {
    let { ViewMoreType, ViewMoreId } = this.state;
    if (data && data.length) {
      let checkLength = data.length > 2 ? true : false;
      data =
        checkLength && ViewMoreType == type && ViewMoreId == rowData.id
          ? data
          : data.slice(0, 2);
      return (
        <div className='template-cols'>
          {data.map((item) => {
            let newName = item.name;
            if (item.name && item.name.length > 25) {
              newName = item.name.substr(0, 22);
              newName = newName + "...";
            }
            return (
              <span title={item.name} className='template-col-name'>
                {newName}
              </span>
            );
          })}
          {data.length == 2 && checkLength && (
            <a
              className='profile-view-more'
              onClick={() => this.handleViewMore(rowData, type)}
            >
              View More
            </a>
          )}
        </div>
      );
    } else {
      return "";
    }
  };

  getPreferences = (data) => {
    let dataLength = data && data.length ? data.length : 0;
    let prefString = "";
    if (dataLength) {
      data.map((item, index) => {
        if (dataLength - 1 == index) {
          prefString = prefString + item.name + " ";
        } else {
          prefString = prefString + item.name + ", ";
        }
      });
    }
    return prefString;
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Profile Name",
        style: { width: "30%", display: "flex", justifyCotent: "center" },
      },
      {
        name: "Preferences",
        style: { width: "14%", display: "flex", justifyCotent: "center" },
      },
      {
        name: "Default",
        style: { width: "14%", display: "flex", justifyCotent: "center" },
      },
      {
        name: "Templates",
        style: { width: "14%", display: "flex", justifyCotent: "center" },
      },
      {
        name: "Assigned Organizations",
        style: { width: "14%", display: "flex", justifyCotent: "center" },
      },
      {
        name: "Action",
        style: { width: "14%", display: "flex", justifyCotent: "center" },
      },
    ];

    columns = data.map((row) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Profile Name",
            title: row.name,
            style: { width: "30%", display: "flex", justifyCotent: "center" },
          },
          {
            key: "Preferences",
            title: this.getPreferences(row.methods),
            style: { width: "14%", display: "flex", justifyCotent: "center" },
          },
          {
            key: "Default",
            title: <ToggleSwitch isOn={row.is_default} />,
            style: { width: "14%", display: "flex", justifyCotent: "center" },
          },
          {
            key: "Templates",
            title: this.renderRoundedCard(row.templates, "TEMP", row),
            style: { width: "14%", display: "flex", justifyCotent: "center" },
          },
          {
            key: "Assigned Organizations",
            title: this.renderRoundedCard(row.organizations, "ORG", row),
            style: { width: "14%", display: "flex", justifyCotent: "center" },
          },
          {
            key: "Action",
            title: this.documentAction(row),
            style: { width: "14%", display: "flex", justifyCotent: "center" },
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  handleAddTempProfile = () => {
    let props = {
      reload: this.callApi,
    };
    this.props.updateModalState(true, "ADD_TEMPLATE_PROFILE", props);
  };

  getSectionHeaderUtilities() {
    return (
      <div className='app-section-header-utilities'>
        <a
          className='cta mg-brand2-color'
          onClick={(event) => this.handleAddTempProfile(event)}
        >
          Add New
        </a>
      </div>
    );
  }

  render() {
    const { appName } = this.props;
    const { profileData, isLoading, error } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;

    return (
      <div>
        <Helmet>
          <title>{appName ? appName : ""} | Profiles </title>
        </Helmet>
        <AppSectionHeader
          title='Profiles'
          utilities={this.getSectionHeaderUtilities()}
        />
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <BottomScrollListener
            offset={50}
            onBottom={this.handleContainerOnBottom}
            debounce={0}
          >
            {(scrollRef) => (
              <div>
                {isSmallDevice ? (
                  <div className='big-device-grad invoice-line'>
                    <div ref={scrollRef} className='scrollbar in-sc'>
                      <CardView data={this.getObjectListData(profileData)} />
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: "1em" }}>
                    <InvoiceObjectList
                      data={this.getObjectListData(profileData)}
                      hideForMobile={true}
                      optionalTableClasses='template-profile-list'
                      optionalClasses='scrollbar in-sc'
                      scrollRef={scrollRef}
                    />
                  </div>
                )}
              </div>
            )}
          </BottomScrollListener>
        )}
      </div>
    );
  }
}

export default withRouter(SuperAdminProfile);
