import React from 'react';

const ConsumerPaymentCalculator = ({ message, date, dollarAmount }) => {

  return (
    <div className="consumer-payment-calculator">
      <p>
        {message} {date}
      </p>
      <span className="dollar-total">
        ${parseFloat(dollarAmount).toFixed(2)}
      </span>
    </div>
  )
}

export default ConsumerPaymentCalculator;
