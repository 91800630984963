import React, { Component } from "react";
// Packages
import onClickOutside from "react-onclickoutside";

class Modal extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClickOutside = () => {
    if (this.props.closeOnClickOutside) {
      this.props.hideModal();
    }
  };

  closeModal = (event) => {
    event.preventDefault();
    this.props.hideModal();
    if (this.props.getDocumentType) {
      this.props.getDocumentType();
    }
  };

  renderModalHeader(title, titleClasses) {
    return title ? (
      <header>
        <h1
          className={`modal-heading${titleClasses ? " " + titleClasses : ""}`}
        >
          {title}
        </h1>
      </header>
    ) : (
      ""
    );
  }

  renderOptionalModalExit = () => {
    const { modalExit, handleModalClose } = this.props;
    let modalExitStatus =
      modalExit === true || modalExit === false ? modalExit : true;

    if (this.props.hideCloseBtn === true) {
      modalExitStatus = false;
    }

    return modalExitStatus ? (
      <a
        className='modal-close'
        onClick={(event) =>
          handleModalClose ? handleModalClose() : this.closeModal(event)
        }
      >
        <svg className='icon'>
          <use xlinkHref='#close' />
        </svg>
      </a>
    ) : (
      ""
    );
  };

  render() {
    let { children, title, optionalClasses, titleClasses } = this.props;

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
          }}
          className={`modal ${optionalClasses ? " " + optionalClasses : ""}`}
        >
          {this.renderOptionalModalExit()}
          {this.renderModalHeader(title, titleClasses)}
          {children}
        </div>
      </div>
    );
  }
}

export default onClickOutside(Modal);
