// Packages
import React, { Component } from "react";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import * as API from "../../utils/api";
import { getZohoPagesense, validateEmail } from "../../utils/helpers";
import ExpressHeader from "./ExpressHeader";

class ExpressPaymentFailed extends Component {
  state = {
    isLoading: false,
    showDetails: false,
  };

  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/payment-failed");
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.history.push("/payment");
  };

  render() {
    const { appName } = this.props;
    const { isLoading, email } = this.state;

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className='app-portal'>
          <Helmet>
            <title>{appName}</title>
          </Helmet>
          <ExpressHeader appName={appName} />
          <div className='app-sidebar-layout-canvas'>
            <div className='app-sidebar-layout-canvas-content express-session-fill'>
              <ClientsLogo color={"#000"} />
              <form className='express-pay-form'>
                <div className='sessions-modal-content'>
                  <h1 className='sessions-modal-heading ex-payment-failed'>
                    Payment Failed!
                  </h1>
                  <p className='qb-msg-text'>
                    The payment has failed. Please click the Retry Payment
                    button below.
                  </p>
                </div>
                <input
                  style={{ marginTop: "1em" }}
                  className='cta submit-btn-align mg-brand2-color'
                  type='submit'
                  value='Retry Payment'
                  onClick={(e) => this.handleClick(e)}
                />
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ExpressPaymentFailed;
