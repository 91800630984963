import React, { Component } from "react";

import MakeGoodLogo from "../../../../assets/icons/MG-logo.png";

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkAdminPortal() {
    if (this.porps.hasOwnProperty("adminPortal")) {
      if (this.porps.adminPortal) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkWidthAsMediaSize() {
    let x = window.matchMedia("(max-width: 600px)");
    if (x.matches) {
      return "60px";
    } else {
      return "75px";
    }
  }

  render() {
    return (
      <div>
        <div
          className="companyLogoDiv"
          style={{
            margin: "0 0 0 0",
            height: "35px",
            float: "left",
          }}
        >
          <img style={{ height: "35px" }} src={MakeGoodLogo} />
        </div>
      </div>
    );
  }
}

export default Logo;
