import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import ReactGA from 'react-ga';

// Components
import Logo from "../components/Logo";
import LandingHero from "../components/Landing/LandingHero/LandingHero";
import StepIconList from "../components/Landing/StepIconList/StepIconList";
import TabGroup from "../components/Landing/TabGroup";
import ExpanderSet from "../components/Landing/ExpanderSet/ExpanderSet";
import SocialLinks from "../components/SocialLinks";

// Image Imports
import ControlIcon from "../assets/images/landing/icons/control@2x.png";
import FlexibilityIcon from "../assets/images/landing/icons/flexibility@2x.png";
import TransparencyIcon from "../assets/images/landing/icons/transparency@2x.png";

import { getZohoPagesense } from "../utils/helpers";

class Landing extends Component {
  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/Landing");
  }

  render() {
    const { appName, appEmail } = this.props;

    return (
      <div className='landing'>
        <Helmet>
          <title>Welcome to {appName}</title>
        </Helmet>
        <LandingHero appName={appName} />
        <section className='intro-section'>
          <div className='inner-wrap'>
            <div className='section-content-left'>
              <div className='section-header section-header-left'>
                <h3>Welcome to {appName}</h3>
              </div>
              <p>
                {appName} has taken a unique approach to the client bill-pay
                experience. Each of our patient’s experience is important from
                service to payment. We understand that sometimes its not always
                easy to pay bills, and for that reason we offer our patients a
                flexible, custom payment option that works for them – at every
                step of the way.
              </p>
              <p>
                <strong>
                  Join {appName} for free today and take control of your
                  financial future.
                </strong>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className='inner-wrap'>
            <div className='section-header landing-section-header'>
              <h3 className='landing-section-header-heading'>
                <span>Getting started. As simple as one, two, three.</span>
              </h3>
              <div className='landing-section-header-surheading'>
                Signing up to the {appName} platform gives you the freedom to:
              </div>
            </div>
            <StepIconList />
          </div>
        </section>
        <section>
          <div className='inner-wrap'>
            <div className='section-header landing-section-header'>
              <h3 className='landing-section-header-heading'>
                <span>Payments that work for you.</span>
              </h3>
              <div className='landing-section-header-surheading'>
                Our payment options give you the flexibility to manage how, and
                when you pay.
              </div>
            </div>
          </div>
          <TabGroup />
        </section>
        <section className='benefits-section'>
          <div className='inner-wrap'>
            <div className='section-header landing-section-header'>
              <h3 className='landing-section-header-heading'>
                <span>Countless benefits</span>
              </h3>
              <div className='landing-section-header-surheading'>
                {appName} gives you the freedom to take charge of your financial
                future through:
              </div>
            </div>
            <ul className='benefits-list'>
              <li className='benefits-list-item'>
                <div
                  className='benefits-list-item-icon'
                  style={{ backgroundImage: `url(${ControlIcon})` }}
                ></div>
                <h4 className='benefits-list-item-heading'>Complete Control</h4>
                <div className='benefits-list-item-body'>
                  You determine how you want to manage your delinquent debt.
                </div>
              </li>
              <li className='benefits-list-item'>
                <div
                  className='benefits-list-item-icon'
                  style={{ backgroundImage: `url(${FlexibilityIcon})` }}
                ></div>
                <h4 className='benefits-list-item-heading'>
                  Financial Flexibility
                </h4>
                <div className='benefits-list-item-body'>
                  Negotiate and set up payments that work for you, either
                  through regular monthly amounts, or by making us a one-time
                  offer to settle your debt once and for all.
                </div>
              </li>
              <li className='benefits-list-item'>
                <div
                  className='benefits-list-item-icon'
                  style={{ backgroundImage: `url(${TransparencyIcon})` }}
                ></div>
                <h4 className='benefits-list-item-heading'>
                  Total Transparency
                </h4>
                <div className='benefits-list-item-body'>
                  {appName} provides a history of all offers, decisions and
                  payments for your reference, available through a single,
                  centralized platform.
                </div>
              </li>
            </ul>
            <a href='/' className='cta cta-secondary'>
              Take control today
            </a>
          </div>
        </section>
        <section className='product-highlight-section pull-up'>
          <div className='inner-wrap'>
            <h4>{appName} is available on desktop, iOS and Android devices.</h4>
          </div>
          <div className='devices'>
            <div className='inner-wrap'>
              <div className='devices-image'></div>
            </div>
          </div>
        </section>
        <section className='faq-section'>
          <div className='inner-wrap'>
            <div className='section-header landing-section-header'>
              <h3 className='landing-section-header-heading'>
                <span>
                  You’ve got questions,
                  <br />
                  we’ve got answers.
                </span>
              </h3>
            </div>
            <ExpanderSet appName={appName} />
          </div>
        </section>
        <section className='cta-section'>
          <div className='inner-wrap'>
            <h2>See for yourself.</h2>
            <p>
              Joining {appName} is completely free and takes just a few minutes.
              Sign up and start controlling your financial future today.
            </p>
            <p>No fees. No hidden costs. No surprises.</p>
            <a href='' className='cta'>
              Join {appName}
            </a>
          </div>
        </section>
        <footer>
          <div className='inner-wrap footer-content'>
            <div className='footer-content-primary'>
              <Logo circular={true} />
              <div className='footer-content-links'>
                <Link
                  className='footer-content-links-site-link'
                  title='{appName}'
                  to='/'
                >
                  {appEmail}
                </Link>
                <SocialLinks />
              </div>
            </div>
            <div className='footer-content-secondary'>
              <div className='footer-copyright'>
                Copyright © 2018 {appName}, LLC - All Rights Reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Landing;
