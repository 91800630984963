import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fBuildVersion } from "../../utils/helpers";
import * as API from "../../utils/api";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#ffffff",
    opacity: 0.95,
  },
};
class ConsumerSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "",
      loading: false,
      appThemeData: {},
    };
  }

  checkPrimaryColor = (props) => {
    return props.hasOwnProperty("primary_color")
      ? props.primary_color
      : "#0ab894";
  };

  getLogoColorsForPayment() {
    this.setState({ isLoading: true });
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
        });
      })
      .catch(() => {});
    this.setState({ isLoading: false });
  }

  componentDidMount() {
    const { user } = this.props;
    this.getLogoColorsForPayment();
    if (user) {
      API.getUserbackend(user).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.errors) {
          this.setState((state) => ({
            ...state,
            version: data.version,
            isLoading: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  }

  render() {
    const {
      title,
      subTitle,
      sidebarContent,
      optionalClassNames,
      BackLink,
      exPay,
    } = this.props;
    const { isLoading } = this.state;
    return isLoading ? (
      <div style={styles.loader}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div
        className={`app-sidebar app-sidebar-left accounts-view-sidebar clients-side-bar col1-scroll scrollbar ${
          exPay ? " payment-side-bar" : " consumer-side-bar"
        }`}
        style={{
          backgroundColor: this.checkPrimaryColor(this.props),
        }}
      >
        {title || subTitle || BackLink ? (
          <div
            className={`app-section-header client-section-header${
              optionalClassNames ? " " + optionalClassNames : ""
            }`}
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              maxHeight: "9rem",
            }}
          >
            <div className='app-section-header-content consumer-sidebar'>
              {BackLink ? (
                <Link
                  to={BackLink}
                  className='app-section-header-back-link'
                  style={{ margin: "7px 0px 0px 0px", color: "#FFFFFF" }}
                  onClick={() => this.props.showHistory(false)}
                >
                  « Back
                </Link>
              ) : null}
              {title && (
                <h1
                  className='app-section-header-heading side-hed-col'
                  style={{ fontSize: "2em" }}
                >
                  {title}
                </h1>
              )}
              {subTitle && <h4 style={StyleSheet.textColor}>{subTitle}</h4>}
            </div>
          </div>
        ) : null}
        <div className='consumer-sidebar'>{sidebarContent}</div>
        {this.props.user ? (
          <div className='footer-version'>
            <div>
              <p>Client Version: {fBuildVersion}</p>
              <p>API Version: {this.state.version}</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ConsumerSidebar;

let StyleSheet = {
  textColor: {
    color: "#FFFFFF",
  },
};
