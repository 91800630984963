// Packages
import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// API
import * as API from "../../../../utils/api";
// Utils
import { getZohoPagesense } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import CardView from "../../../../components/CardView/CardView";
import ClientsLogo from "../../../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "./ConsumerSliderStatic";
import InvoiceObjectList from "../../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import Tabs from "./Tabs";

class ConsumerAccountsInvoices extends Component {
  state = {
    activeTab: 1,
    accountType: "current",
    consumerData: [],
    invoiceData: [],
    consumerInvoice: [],
    hasMoreData: false,
    isLoading: false,
    showLoader: false,
    leftLoader: false,
    offset: 0,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    input: {
      search: {
        value: "",
      },
    },
  };

  componentDidMount() {
    // getZohoPagesense();
    // ReactGA.pageview("/ConsumerAccounts");
    // this.getInvoiceData();
  }

  componentWillUnmount() {
    // localStorage.setItem("static", false);
  }

  getInvoiceData = () => {
    let { offset, activeTab } = this.state;
    let is_open = activeTab === 1 ? true : false;
    API.fetchAllConsumerInvoices(this.props.user, offset, is_open).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          this.setState((state) => ({
            ...state,
            consumerInvoice: data.data,
            invoiceData: data.data.invoices,
            hasMoreData: data.data.invoices.length < 15 ? false : true,
            isLoading: false,
            leftLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            leftLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  consumerInvoicesPagination = (reload) => {
    let { offset, activeTab } = this.state;
    let is_open = activeTab === 1 ? true : false;
    API.consumerInvoicesPagination(this.props.user, offset, is_open).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          let invoice = reload ? this.state.invoiceData : false;
          let newData = invoice ? invoice.concat(data.data) : data.data;
          this.setState((state) => ({
            ...state,
            invoiceData: newData,
            hasMoreData: data.data.length < 15 ? false : true,
            showLoader: false,
            leftLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            showLoader: false,
            leftLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          showLoader: true,
        })
        // () => {
        //   this.consumerInvoicesPagination(true);
        // }
      );
    }
  };

  handleRowActionClick = (event) => {
    let { history } = this.props;

    history.push(`/welcome-screen/accounts/000000`);
  };

  handleInputChange(newPartialInput) {
    const re = /[0-9]+/g;
    if (
      (newPartialInput.search.value === "" ||
        re.test(newPartialInput.search.value)) &&
      newPartialInput.search.value !== this.state.input.search.value
    ) {
      this.setState(
        (state) => ({
          ...state,
          input: {
            ...newPartialInput,
          },
        }),
        () => {
          if (this.state.input.search.value === "") {
            // this.getInvoiceData();
          } else {
            this.handleKeyPress({ key: "Enter" });
          }
        }
      );
    }
  }

  handleKeyPress(event) {
    if (event.key === "Enter" && this.state.input.search.value !== "") {
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        })
        // () => {
        //   this.handleSearch(); //TO_DO
        // }
      );
    }
  }

  //search api call
  handleSearch = (reload, isSort) => {
    let { activeTab } = this.state;
    let is_open = activeTab === 1 ? true : false;
    if (!isSort) {
      if (reload) {
        this.setState({ showLoader: true });
      } else {
        this.setState({ leftLoader: true });
      }
    }
    // let sort_key = this.getSortKey();
    // let order = this.state.sortType ? "asc" : "desc";
    let queryString = this.state.input.search.value;
    // let filterBy = this.state.consumer_type;
    let user_id = this.props.user.id
      ? this.props.user.id
      : this.props.user.user_id
      ? this.props.user.user_id
      : "";
    API.consumerSearch(this.props.user, queryString, user_id, is_open).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          this.setState((state) => ({
            ...state,
            consumerInvoice: data.data,
            invoiceData: data.data.invoices,
            hasMoreData: data.data.invoices.length < 15 ? false : true,
            isLoading: false,
            leftLoader: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            leftLoader: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };

  getPaymentStatus = (invoice) => {
    if (invoice === "Paid") {
      return <div style={StyleSheet.paymentStatusCss}>Paid</div>;
    }
    let is_past_due = this.checkInvoicePastDue(invoice.due_date);
    let statusStyle = is_past_due
      ? { color: "#fb5c82", fontFamily: "sofia_pro_bold" }
      : { color: "#409D57", fontFamily: "sofia_pro_bold" };
    if (
      parseFloat(invoice.balance).toFixed(2) ==
      (
        parseFloat(invoice.total_amt) +
        parseFloat(invoice.tax_amount) +
        parseFloat(invoice.slow_payer_amount)
      ).toFixed(2)
    ) {
      return <div style={statusStyle}>Unpaid</div>;
    } else {
      return <div style={statusStyle}>Partially Paid</div>;
    }
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      let total_amount = parseFloat(invoice.total_amt);
      let tax_amount = parseFloat(invoice.tax_amount);
      let slow_payer = parseFloat(invoice.slow_payer_amount);
      let balance = parseFloat(invoice.balance);
      amount = invoice.total_amt
        ? invoice.balance
          ? (total_amount + tax_amount + slow_payer).toFixed(2) -
            balance.toFixed(2)
          : invoice.total_amt
        : invoice.total_amt;
    }
    return amount.toFixed(2);
  };

  getInvoiceListData = (data, activeTab) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Inv. #",
        style: {
          width: "13%",
          display: "flex",
          justifyContent: "flex-start",
          padding: 0,
        },
      },
      {
        name: "Inv. Date",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Due Date",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Inv. Total",
        style: {
          width: "14%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Amount Paid",
        style: { width: "14%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Balance",
        style: {
          width: "12%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Status",
        style: { width: "12%", display: "flex", justifyContent: "center" },
      },
      {
        name: "expander",
        style: { width: 0, display: "flex", justifyContent: "center" },
      },
    ];

    columns = [
      {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Inv. #",
            title: "000000",
            style: {
              width: "13%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Inv. Date",
            title: "09/23/2021",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Due Date",
            title: "10/08/2021",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Inv. Total",
            title: "$100",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Amt Paid",
            title: `$0.00`,
            style: {
              width: "12%",
              display: "flex",
              justifyContent: "center",
            },
          },
          // {
          //   key: "Discount",
          //   title: "$0.00",
          // },
          {
            key: "Balance",
            title: "$100",
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Status",
            title: "Unpaid",
            style: {
              width: "9%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
        status: "Unpaid",
        rowData: "static",
      },
    ];

    return {
      headings,
      columns,
    };
  };

  renderInvoiceTable = () => {
    const { activeTab, invoiceData, leftLoader } = this.state;
    if (leftLoader) {
      return (
        <div className='mobile-loader'>
          <AppCanvasLoader />
        </div>
      );
    }
    return (
      <BottomScrollListener
        offset={50}
        onBottom={this.handleContainerOnBottom}
        debounce={0}
      >
        {(scrollRef) => (
          <div
            ref={scrollRef}
            className='consumer-scroll-accounts-list scrollbar'
          >
            <div className='big-device-grad invoice-line'>
              <CardView
                data={this.getInvoiceListData(invoiceData, activeTab)}
                isStatus={activeTab === 1 ? true : false}
                rowActionClick={(event) => this.handleRowActionClick(event)}
              />
            </div>
            <InvoiceObjectList
              data={this.getInvoiceListData(invoiceData, activeTab)}
              rowActionClick={(event) => this.handleRowActionClick(event)}
              isStatus={activeTab === 1 ? true : false}
              hideForMobile={true}
              fromConsumer={true}
            />
            {this.state.showLoader && (
              <div>
                <AppCanvasLoader />
              </div>
            )}
          </div>
        )}
      </BottomScrollListener>
    );
  };

  getAccountStatus = (rowData) => {
    let flag = this.checkInvoicePastDue(rowData.due_date);
    if (flag) {
      if (this.state.accountType != "past due") {
        this.setState({ accountType: "past due" });
      }
    }
    // let adjustment = rowData.adjustment ? rowData.adjustment : 0;
    // if (
    //   (rowData.balance > 0 && adjustment == 0) ||
    //   (adjustment > 0 && rowData.balance != adjustment)
    // ) {
    //   flag = true;
    // } else if (
    //   (rowData.balance == 0 && adjustment == 0) ||
    //   (adjustment > 0 && adjustment == rowData.balance)
    // ) {
    //   flag = false;
    //   this.setState({ accountType: "past due" });
    // }
    return !flag;
  };

  sidebarContent = () => {
    const { consumerInvoice, accountType } = this.state;

    return (
      <div className='consumer-sidebar'>
        <div
          className='sidebar-primary-text consumer-account-type'
          style={StyleSheet.head1}
        >
          Your account is due
        </div>

        <div className='consumer-sidebar-panel-content'>
          <div className='consumer-amount-details'>Account Balance</div>
          <div className='consumer-amount-details'>${"100"}</div>
        </div>
        <div className='consumer-sidebar-panel-content'>
          <div className='consumer-amount-details'>Account Past Due</div>
          <div className='consumer-amount-details'>${"100"}</div>
        </div>
        <div className='consumer-sidebar-panel-content'>
          <div className='consumer-amount-details'>No. of Open Invoices</div>
          <div className='consumer-amount-details'>{"1"}</div>
        </div>
      </div>
    );
  };

  getSubTitle = () => {
    return "Welcome, " + "Test User";
  };

  getSectionHeaderUtilities() {
    const { activeTab, input } = this.state;
    const tabLabels = ["Open", "Closed"];

    return (
      <div className='consumer-section-header-wrapper'>
        <Tabs
          labels={tabLabels}
          activeTarget={activeTab}
          handleTabChange={() => {}}
          optionalClasses='consumer-pay-tab tp-sp con-fit'
          optionalInTabClasses='mg-consumer-tabs-tab'
        />
        <div className='consumer-search-input-container client-search-c'>
          <input
            type='text'
            name='search'
            placeholder='Search By Invoice No.'
            className='consumer-search-input'
            disabled={true}
          />
          <span className='search-icon' />
        </div>
      </div>
    );
  }

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className='scrollbar consumer-scroll-accounts'>
          <Helmet>
            <title>{appName} | Invoices</title>
          </Helmet>
          <div className='app-sidebar-layout-canvas'>
            <ConsumerSidebar
              primary_color={this.props.appThemeData.primary_color}
              user={this.props.user}
              subTitle={this.getSubTitle(this.props.user)}
              sidebarContent={this.sidebarContent()}
            />
            <div className='app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad'>
              <ClientsLogo />
              <h1 className='app-section-consumer-heading consumer-brand'>
                Invoices
              </h1>
              <div className='section-header1'>
                {this.getSectionHeaderUtilities()}
              </div>
              {this.renderInvoiceTable()}
              <br />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ConsumerAccountsInvoices;

let StyleSheet = {
  head1: { marginBottom: "2em", display: "flex", color: "#FFFFFF" },
  paymentStatusCss: { color: "#409D57", fontFamily: "sofia_pro_bold" },
  invoiceStatusCss: { height: "auto", padding: "6rem 2rem" },
};
