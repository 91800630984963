import React, { Component } from 'react';

class ErrorFlashMessage extends Component {

  render() {
    return (
      <div className="error-flash-message">
        {this.props.message}
        <a className="close" onClick={this.props.handleFlashDismiss} title="Dismiss Message">&times;</a>
      </div>
    );
  }
}

export default ErrorFlashMessage;
