import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ColorPicker, createColor } from "material-ui-color";

const theme = createTheme ({
  palette: {}
});

export default function ColorPickerComponent({ updateMergeVariablesColor, colorPicker, defaultColor }) {
  const [color, setColor] = useState(createColor(defaultColor));
  const [hexValue, setHexValue] = useState(defaultColor.slice(1,7));
  const handleChange = (value) => {
    setColor(value);
    setHexValue(value.hex);
    if(colorPicker === 'primaryColor' )
      updateMergeVariablesColor('primaryColor', `#${value.hex}`)
    
      if(colorPicker === 'secondaryColor' )
      updateMergeVariablesColor('secondaryColor', `#${value.hex}`)
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Grid container>
          <Grid item className="color-picker">
            <ColorPicker
              hideTextfield
              disableAlpha
              borderWidth={0}
              inputFormats={["hex"]}
              value={color} onChange={handleChange}
            />
          </Grid>
          <Grid item style={{ marginTop: "10px", fontWeight: '500',fontFamily: 'Open Sans'}}>{`#${hexValue}`}</Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
