// Packages
import React, { Component } from "react";
// import ReactGA from "react-ga";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import Panel from "../../components/App/Panel";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerAddAccountForm from "../../components/Consumer/ConsumerAddAccountForm";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import ConsumerExpressPayment from "./Payment/ConsumerExpressPayment";
// API
import * as API from "../../utils/api";
// Utils
import { getZohoPagesense } from "../../utils/helpers";

class ConsumerPayment extends Component {
  state = {
    amount: "",
    user: "",
    cardList: [],
    paymentMethod: "CC",
    amountError: false,
    isLoading: false,
    isSubmit: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  checkPrimaryColor = (props) => {
    return props.hasOwnProperty("primary_color")
      ? props.primary_color
      : "#0ab894";
  };

  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/ConsumerAccounts");
    API.getUser(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        this.setState((state) => ({
          user: data.user,
          isLoading: false,
        }));
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  }

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amount
        ? invoice.balance
          ? parseFloat(invoice.total_amount) - parseFloat(invoice.balance)
          : invoice.total_amount
        : invoice.total_amount;
    }
    return amount ? amount.toFixed(2) : "0.00";
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "amount") {
      message = "Please enter a valid amount";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  checkDiscount = () => {
    let { invoiceDetails } = this.props;
    let finalEarlyPayerDisc = 0.0;
    let sCT = 0;
    let targetDate;
    let earlyPayerInfoFlag = false;
    if (
      invoiceDetails &&
      invoiceDetails.invoice_lines &&
      invoiceDetails.invoice_lines.length
    ) {
      invoiceDetails.invoice_lines.map((line) => {
        if (line.item_name && line.item_name.includes("Early Payer Dis")) {
          finalEarlyPayerDisc = parseFloat(line.amount);
          sCT++;
        }
      });

      if (sCT === 0) {
        if (
          invoiceDetails &&
          invoiceDetails.criteria_discount_days &&
          invoiceDetails.criteria_discount_days > 0
        ) {
          let startdate = invoiceDetails.date;
          // var new_date = moment(startdate, "YYYY-MM-DD").add(5, "days");
          let nn = moment(
            moment(startdate)
              .add(invoiceDetails.criteria_discount_days, "d")
              .format("YYYY/MM/DD")
          );
          let today = new Date();
          targetDate = moment(nn);
          var month = today.getUTCMonth() + 1; //months from 1-12
          var day = today.getUTCDate();
          var year = today.getUTCFullYear();

          let newdate = year + "/" + month + "/" + day;
          let momentToday = moment(newdate);
          let diffNumber = targetDate.diff(momentToday, "days");
          if (diffNumber >= 0) {
            let value = 0.0;
            if (
              invoiceDetails &&
              invoiceDetails.criteria_discount_percent_based === true
            ) {
              value = parseFloat(invoiceDetails.total_amount) / 100;
              value = value * parseFloat(invoiceDetails.criteria_discount);
            } else if (
              invoiceDetails &&
              invoiceDetails.criteria_discount_percent_based === false
            ) {
              value = invoiceDetails.criteria_discount;
            }
            earlyPayerInfoFlag = true;

            let discAmount = 0.0;
            if (
              invoiceDetails &&
              invoiceDetails.criteria_discount_percent_based === true
            ) {
              finalEarlyPayerDisc =
                parseFloat(invoiceDetails.total_amount) *
                (parseFloat(invoiceDetails.criteria_discount) / 100);
            } else {
              finalEarlyPayerDisc = invoiceDetails.criteria_discount;
              // this.setState({ isDiscountApplied: true, discountAmount: discAmount })
            }
          }
        }
      }
    }

    return {
      isApplied: earlyPayerInfoFlag,
      amount: finalEarlyPayerDisc,
      discountDate: targetDate,
    };
  };

  sidebarContent = () => {
    const { invoiceDetails } = this.props;
    let discObj = this.checkDiscount();
    let isDiscountApplied = discObj.isApplied;
    let discountDate = discObj.discountDate;
    return (
      <div className='consumer-sidebar'>
        <label
          style={{ marginBottom: "0.3em", fontSize: 16, color: "#ffffff" }}
        >
          Balance Due
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "0.6em", color: "#000000" }}
        >
          {invoiceDetails.balance
            ? currencyFormatter.format(invoiceDetails.balance, { code: "USD" })
            : "0.00"}
        </div>
        <label
          style={{ marginBottom: "0.3em", fontSize: 16, color: "#ffffff" }}
        >
          Due Date
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {invoiceDetails.balance
            ? moment(invoiceDetails.due_date).format("MM/DD/YYYY")
            : ""}
        </div>

        {isDiscountApplied ? (
          <div>
            <label style={{ marginBottom: "0.3em", fontSize: 16 }}>
              Discount till
            </label>
            <div
              className='invoice-amt-owed payment-balance'
              style={{ marginBottom: "1em", color: "#000000" }}
            >
              {invoiceDetails.criteria_discount_days
                ? moment(discountDate).format("MM/DD/YYYY")
                : "NA"}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const {
      appName,
      stripePubKey,
      invoiceDetails,
      BackLink,
      user,
      primary_color,
    } = this.props;
    const { isLoading, error, cardList, isSubmit } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className='scrollbar consumer-scroll-accounts'>
          <Helmet>
            <title>{appName} | Payment</title>
          </Helmet>
          <div className='app-sidebar-layout-canvas'>
            <ConsumerSidebar
              primary_color={primary_color}
              user={this.props.user}
              title={"Payments"}
              sidebarContent={this.sidebarContent(invoiceDetails)}
              BackLink={BackLink}
            />
            <div className='app-sidebar-layout-canvas-content consumer-right-canvas big-device-grad'>
              <ClientsLogo />
              <h1
                className='app-section-consumer-heading consumer-brand'
                style={{ color: this.checkPrimaryColor(this.props) }}
              >
                Invoice# {invoiceDetails.invoice_number}
              </h1>
              <div className='consumer-payment-form consumer-scroll-payment scrollbar'>
                <ConsumerExpressPayment
                  primary_color={primary_color}
                  stripePubKey={stripePubKey}
                  user={user}
                  invoiceDetails={invoiceDetails}
                  updateModalState={this.props.updateModalState}
                  getInvoiceDetails={this.props.getInvoiceDetails}
                  isAutoPopulateBalanceDue={this.props.isAutoPopulateBalanceDue}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ConsumerPayment;
