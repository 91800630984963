import React, { Component } from 'react';

// Components
import Modal from '../Modal';

class AccountUpdatedModal extends Component {

  render() {
    let { account } = this.props.optionalProps;

    return (
      <Modal
        hideModal={this.props.hideModal}
        closeOnClickOutside={true}
        title="Account Updated!"
      >
        <p>
          The account for {account.first_name + ' ' + account.last_name} has been successfully Updated.
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            onClick={this.props.hideModal}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default AccountUpdatedModal;