import React, { Component } from "react";
import PropTypes from "prop-types";
import { isThirdPartyAppConfigured } from "../../utils/api";

class ObjectEmailHistorySubDetails extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      Delivered: 0,
    };
  }

  render() {
    return (
      <div>
        <div>
          <div className="subdetails-heading">Last 30 Days</div>
          <div className="subdetailed-delivered-style">
            Delivered: {this.props.deliver}
          </div>
        </div>

        <div className="object-row-link object-row subdetails-main-div">
          <div className="col-md-4 subdetails-style subdetails-column-width">
            <div className="panel-heading-secondary">Sent:</div>
            <div className="subdetails-data-div">{this.props.data.sent}</div>
          </div>
          <div className="col-md-4 subdetails-style subdetails-column-width">
            <div className="panel-heading-secondary">Hard Bounces:</div>
            <div className="subdetails-data-div">
              {this.props.data.hard_bounces}
            </div>
          </div>

          <div className="col-md-4 subdetails-style subdetails-column-width">
            <div className="panel-heading-secondary">Complaints:</div>
            <div className="subdetails-data-div">
              {this.props.data.complaints}
            </div>
          </div>
          <div className="col-md-4 subdetails-style subdetails-column-width">
            <div className="panel-heading-secondary">Rejects:</div>
            <div className="subdetails-data-div">{this.props.data.rejects}</div>
          </div>
          <div className="col-md-4 subdetails-style subdetails-column-width">
            <div className="panel-heading-secondary">Soft Bounces:</div>
            <div className="subdetails-data-div">
              {this.props.data.soft_bounces}
            </div>
          </div>

          <div className="col-md-4 subdetails-style subdetails-column-width">
            <div className="panel-heading-secondary">Unsubs:</div>
            <div className="subdetails-data-div">{this.props.data.unsubs}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ObjectEmailHistorySubDetails;
