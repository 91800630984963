import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// import ReactGA from 'react-ga';

// API
import * as API from "../../../utils/api";

import { networkMessage, getZohoPagesense } from "../../../utils/helpers";

// Packages
import moment from "moment";

// Components
import AppSectionHeader from "../../../components/App/AppSectionHeader";
import ConsumerAccountsPaymentForm from "../../../components/Consumer/Forms/ConsumerAccountsPaymentForm";

class ConsumerAccountsNegotiateOneTimePayment extends Component {
  state = {
    account: {
      debtId: "",
      originalCollectionAmount: "",
      creditorName: "",
      hasAnyError: false,
    },
  };

  componentDidMount() {
    const debtId = parseInt(this.props.match.params.id, 10);

    // this.props.updatePrevPath(`/dashboard/accounts/${debtId}`);

    API.getDebt(this.props.user, debtId).then((data) => {
      if (typeof data != "undefined" || data != null) {
        let { debt } = data;

        this.setState((state) => ({
          account: {
            debtId: debt.id,
            originalCollectionAmount: debt.balance_in_dollars_as_currency,
            creditorName: debt.creditor_name,
          },
        }));
      } else {
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
        }));
      }
    });

    getZohoPagesense();
    // ReactGA.pageview("/ConsumerAccountsNegotiateOneTimePayment");
  }

  handleFormSubmit = (amount, paymentDate, stripeToken, paymentAmount) => {
    let paymentPlanOffer = {
      total_in_dollars: amount,
      start_date: moment(paymentDate, "MM-DD-YYYY").format("YYYY-DD-MM"),
      number_of_payments: 1,
      amount: paymentAmount,
    };

    const debtId = parseInt(this.props.match.params.id, 10);

    API.consumerCreateOffer(
      this.props.user,
      debtId,
      paymentPlanOffer,
      stripeToken
    ).then((data) => {
      let checkStatus =
        data && data.status_code && data.status_code === 400 ? true : false;

      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        !checkStatus
      ) {
        const { debtId } = this.state.account;

        if (data.offer.status == "rejected") {
          let optionalModalProps = {
            user: this.props.user,
            debtId: debtId,
            history: this.props.history,
          };
          this.props.updateModalState(
            true,
            "AUTO_OFFER_REJECTED",
            optionalModalProps
          );
        } else {
          let optionalModalProps = {
            debtId: debtId,
            modalExitButton: false,
            appName: this.props.appName,
          };

          this.props.updateModalState(
            true,
            "USER_OFFER_SUBMITTED",
            optionalModalProps
          );
        }
      } else {
        this.state.hasAnyError = true;
        if (data && data.message) {
          this.props.updateModalState(true, "ERROR", { message: data.message });
        } else {
          this.props.updateModalState(true, "OTHER_ERROR", true);
        }
      }
    });
  };

  render() {
    const { appName } = this.props;
    const { account } = this.state;

    if (this.state.hasAnyError) {
      //this.props.updateModalState(true, 'OTHER_ERROR', this.state.hasAnyError);
      // return <Redirect to={{ pathname: '/sign-out' }} />
    }

    return (
      <div>
        <Helmet>
          <title>{appName} | One Time Payment</title>
        </Helmet>
        <div className='wrap user-portal-collapse-wrap'>
          <AppSectionHeader
            title={account.creditorName}
            subtitle={`$${account.originalCollectionAmount}`}
            optionalClassNames='secondary reversed-title consumer-portal-app-header'
            backLink={{
              text: "Back",
              path: `/dashboard/accounts/${account.debtId}/negotiate`,
            }}
          />
          <div
            className='scrollbar'
            style={{ height: window.innerHeight * 0.65, paddingBottom: 100 }}
          >
            <div className='collapse-wrap-padded-content'>
              <div className='mini-wrap'>
                <h2 className='consumer-accounts-payment-heading'>
                  One-Time Fixed Amount
                </h2>
                <ConsumerAccountsPaymentForm
                  handleFormSubmit={this.handleFormSubmit}
                  account={account}
                  formType='one-time-payment'
                  user={this.props.user}
                  stripePubKey={this.props.stripePubKey}
                  originalCollectionAmount={account.originalCollectionAmount}
                  hideLoader={this.state.hasAnyError}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerAccountsNegotiateOneTimePayment;
