import React, { Component } from "react";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import * as API from "../../../utils/api";
import { getBaseUrl } from "../../../utils/api";
import Cookies from "universal-cookie";
import styles from "../../../components/Buttons/button.module.scss";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

class CustomizeWorkFlowStep3 extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    mergeVarData: "",
    own_payment_url: {
      value: "",
      hasError: false,
    },
    merge_variable_id: "",
    use_mg_portal: false,
    OwnPaymentPortalRadio: true,
    isCheckedRadioBtn: false,
    isCheckedRadioBtnNoPayment: false,
    isLoading: true,
    organizationDetails: "",
    portal_value: null,
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.getOrganizationalDetails();
  }
  componentWillReceiveProps = (props) => {
    this.getOrganizationalDetails();
  };

  checkUpdatedData = () => {
    let isData1Present,
      isData2Present = false;
    Object.entries(this.state.mergeVarData).forEach(([key, value]) => {
      if (key === "Sign In and Payment URLs") {
        value[""].map((item) => {
          if (item.purpose === "Payment Portal URL") {
            if (item.value !== "" || item.value === null) {
              isData1Present = true;
            }
          }
        });
      }
      if (key === "Sign In and Payment URLs") {
        value["Bring Your Own Payment Portal URL"].map((item) => {
          if (item.name === "Express") {
            if (item.value !== "" && item.value !== null) {
              isData2Present = true;
              this.setState((state) => ({
                ...state,
                own_payment_url: {
                  value: item.value,
                },
                OwnPaymentPortalRadio: false,
              }));
            }
          }
        });
      }
    });
    if (!isData1Present && !isData2Present) {
      this.setState({ isCheckedRadioBtnNoPayment: true });
    } else if (isData2Present) {
      this.setState({ isCheckedRadioBtn: true });
    }
  };
  getOrganizationalDetails = () => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    // API.getOrgDetails(user).then((data) => {
    //   if ((typeof data != "undefined" || data != null) && data.data) {
    this.setState({ organizationDetails: this.props.orgData }, () =>
      this.getMergeVariablesList()
    );
    let { input, organizationDetails } = this.state;
    // } else {
    //   this.setState((state) => ({
    //     ...state,
    //     error: {
    //       hasAnyError: true,
    //       statusCode: data
    //         ? data.status ||
    //           (data.status_code && data.status_code == 401 ? 401 : 500) ||
    //           500
    //         : 500,
    //     },
    //   }));
    // }
    // });
  };
  getMergeVariablesList = () => {
    // let { mergeVarData, organizationDetails } = this.state;
    // const accountId = organizationDetails.organization.id;
    // const accountId = location.state.companyData.organization.id
    // API.getMergeVariablesListClient(this.props.user, accountId).then((data) => {
    //   if ((typeof data != "undefined" || data != null) && !data.message) {
    this.setState(
      (state) => ({
        ...state,
        mergeVarData: this.props.mergeVarData,
        isLoading: false,
      }),
      () => this.checkUpdatedData()
    );
    //   } else {
    //     this.setState((state) => ({
    //       ...state,
    //       error: {
    //         hasAnyError: true,
    //         statusCode: data
    //           ? data.status ||
    //             (data.status_code && data.status_code == 401 ? 401 : 500) ||
    //             500
    //           : 500,
    //       },
    //     }));
    //   }
    // });
  };
  handleSubmitNext = () => {
    let { isCheckedRadioBtn, isCheckedRadioBtnNoPayment } = this.state;
    let isValuePresent = false;
    Object.entries(this.state.mergeVarData).forEach(([key, value]) => {
      if (key === "Sign In and Payment URLs") {
        value[""].map((item) => {
          if (item.purpose === "Payment Portal URL") {
            if (item.value !== "" && item.value !== null) {
              isValuePresent = true;
            }
          }
        });
      }
      if (key === "Sign In and Payment URLs") {
        value["Bring Your Own Payment Portal URL"].map((item) => {
          if (item.name === "Express") {
            if (item.value !== "" && item.value !== null) {
              isValuePresent = true;
            }
          }
        });
      }
    });
    const regEx =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    if (isCheckedRadioBtn) {
      if (this.state.own_payment_url.value.match(regEx)) {
        if (!this.state.own_payment_url.hasError) {
          Object.entries(this.state.mergeVarData).forEach(([key, value]) => {
            if (key === "Sign In and Payment URLs") {
              value["Bring Your Own Payment Portal URL"].map((item) => {
                if (
                  item.purpose ===
                  "Bring your own payment portal URL for Express Payments"
                ) {
                  this.setState(
                    {
                      merge_variable_id: item.merge_variable_id,
                      use_mg_portal: false,
                    },
                    () => this.useMakegoodPorta()
                  );
                }
              });
            }
          });
        }
      } else {
        this.setState((state) => ({
          ...state,
          own_payment_url: {
            hasError: true,
          },
        }));
      }
    } else if (isCheckedRadioBtnNoPayment) {
      this.setState(
        {
          merge_variable_id: "",
          use_mg_portal: false,
        },
        () => this.useMakegoodPorta()
      );
    } else if (isValuePresent === true) {
      this.props.handleNext("step4");
    } else {
      this.props.updateModalState(true, "ERROR", {
        message: "Please select any one option of the following Payment Portal",
      });
    }
  };
  handleSubmitRadiobtn = () => {
    let { isCheckedRadioBtn } = this.state;
    isCheckedRadioBtn = !isCheckedRadioBtn;
    this.setState({ isCheckedRadioBtn, isCheckedRadioBtnNoPayment: false });
    if (this.state.isCheckedRadioBtn) {
      this.setState({
        OwnPaymentPortalRadio: true,
      });
    } else if (!this.state.isCheckedRadioBtn) {
      this.setState({
        OwnPaymentPortalRadio: false,
      });
    }
  };
  noPaymentPortalRadioBtn = () => {
    let { isCheckedRadioBtnNoPayment } = this.state;
    isCheckedRadioBtnNoPayment = !isCheckedRadioBtnNoPayment;
    this.setState({ isCheckedRadioBtnNoPayment });
    if (isCheckedRadioBtnNoPayment) {
      this.setState((state) => ({
        ...state,
        own_payment_url: {
          hasError: false,
          value: "",
        },
        isCheckedRadioBtn: false,
        OwnPaymentPortalRadio: true,
      }));
    }
  };
  handleSubmit = (res) => {
    Object.entries(this.state.mergeVarData).forEach(([key, value]) => {
      if (key === "Sign In and Payment URLs") {
        value[""].map((item) => {
          if (item.purpose === "Payment Portal URL") {
            this.setState(
              (state) => ({
                ...state,
                own_payment_url: {
                  hasError: false,
                  value: "",
                },
                merge_variable_id: item.merge_variable_id,
                portal_value: item.value,
                use_mg_portal: true,
              }),
              () => this.useMakegoodPorta()
            );
          }
        });
      }
    });
  };

  useMakegoodPorta = () => {
    let { merge_variable_id, use_mg_portal, own_payment_url } = this.state;
    let payload = {
      use_mg_portal: use_mg_portal,
      merge_variable_id: merge_variable_id,
      value: own_payment_url.value,
    };
    API.paymentPortalUrl(this.props.user, payload).then((data) => {
      if (data.status_code == 200) {
        if (use_mg_portal || data.modify_count != 0) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
        this.props.getOrganizationalDetails();
        this.props.handleNext("step4");
      } else if (data.status_code !== 200) {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
    //  this.props.handleNextstep("step4")
  };
  handleConsumerInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      ...state.own_payment_url,
      ...newPartialInput,
    }));
  }
  render() {
    let { isLoading } = this.state;

    return isLoading ? (
      <div className='loader_alignment'>
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <div className='app-sidebar-layout-canvas-content  col1-scroll mg-col1-scroll scrollbar margin-around-main-div'>
        <div className='step3-app-sidebar-main-div'>
          <div className='app-sidebar-main-div'>
            <div className='payment-portal-div'>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <h3
                  style={{ fontFamily: "Open Sans", width: "75%" }}
                  className='mg-head-bold'
                >
                  Select Your Payment Portal
                </h3>
                <div style={{ width: "25%" }} className={styles.btnBox}>
                  <SecondaryButton
                    isBackButton={true}
                    text={"Back"}
                    title={`Go Back To \n Add Banking Information`}
                    onClick={() => this.props.handleback("step2")}
                  />
                  <PrimaryButton
                    isNextButton={true}
                    text={"Next"}
                    title={`Continue To \n Upload Your W9 Form`}
                    onClick={() => this.handleSubmitNext("OwnPaymentPortal")}
                  />
                </div>
              </div>
              <h7
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
              >
                MakeGood provides you with a branded payment portal for your
                customers to easily make online payments. Your email
                communications will provide a link to this payment portal.
              </h7>

              <div style={{}} className='makegood-btn-div'>
                <input
                  type='submit'
                  id='select_payment_portal_use_makegood_btton'
                  style={{ borderRadius: "12px" }}
                  value={"Use the MakeGood Payment Portal"}
                  className='cta mg-brand2-color mg-usepaymentbtn btns-font'
                  onClick={() => this.handleSubmit(" MakeGoodPaymentPortal")}
                />
              </div>
              <div
                className='makegood-btn-div'
                style={{
                  fontWeight: "bold",
                  paddingTop: "10px",
                  paddingLeft: "105px",
                }}
              >
                Recommended
              </div>
            </div>
            <div className='payment-portal-radio mg-aic'>
              <input
                type='radio'
                id='select_payment_portal_use_my_own_radio_button'
                value={"Next"}
                checked={this.state.isCheckedRadioBtn}
                style={{ WebkitAppearance: `radio`, width: `20px` }}
                className='payment-portal-radio-btn '
                onClick={() => this.handleSubmitRadiobtn()}
              />
              <div className='first_radio_option_step3'>
                <div style={{ height: "55px" }}>
                  <p>Use My Own Payment Portal </p>
                </div>
                <div>
                  <fieldset disabled={this.state.OwnPaymentPortalRadio}>
                    <input
                      placeholder='Enter your payment portal URL'
                      id='select_payment_portal_enter_url'
                      value={this.state.own_payment_url.value}
                      onChange={(event) =>
                        this.handleConsumerInfoInputChange({
                          own_payment_url: {
                            value: event.target.value,
                            hasError: false,
                          },
                        })
                      }
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            {this.state.own_payment_url.hasError ? (
              <p
                style={{
                  color: "#cc0606",
                  marginLeft: "31px",
                  fontWeight: "bold",
                }}
              >
                Please Enter Valid URL
              </p>
            ) : (
              ""
            )}
            <div className='payment-portal-radio mg-aic'>
              <input
                type='radio'
                value={"Next"}
                id='select_payment_portal_use_no_payment_btton'
                checked={this.state.isCheckedRadioBtnNoPayment}
                style={{
                  WebkitAppearance: `radio`,
                  width: `20px`,
                  marginLeft: "0px",
                  bottom: "0px",
                }}
                className='payment-portal-radio-btn'
                onClick={() => this.noPaymentPortalRadioBtn()}
              />
              <p>Use No Payment Portal </p>
            </div>
          </div>
        </div>
        {/* <div
          className='bottum-btns '
          style={{
            marginBottom: "70px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          <div className='button-left'>
            <input
              type='submit'
              id='select_payment_portal_go_back'
              value='Go Back'
              className='btns-font'
              style={{
                borderRadius: "0.5em",
                width: "200px",
                height: "50px",
                marginTop: "20px",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "500",
                border: "2px solid",
                float: "left",
              }}
              onClick={() => this.props.handleback("step2")}
            />
            <span className='go-back-text'>
              <p style={this.style.pText}>Go back to</p>
              <p style={this.style.pText}>"Add Banking Information"</p>
            </span>
          </div>
          <div className='button-right'>
            <input
              type='submit'
              id='select_payment_portal_next'
              value={"Next"}
              className='mg-brand2-color  mg-ml-0 btns-font'
              style={{
                width: "200px",
                height: "50px",
                borderRadius: "0.5em",
                marginTop: "20px",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: "500",
                color: "white",
                float: "right",
              }}
              onClick={() => this.handleSubmitNext("OwnPaymentPortal")}
            />
            <span className='next-text'>
              <p style={this.style.pText}>Continue to</p>
              <p style={this.style.pText}>"Upload Your W9 Form"</p>
            </span>
          </div>
        </div> */}
      </div>
    );
  }
}

export default CustomizeWorkFlowStep3;
