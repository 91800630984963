
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class AdminNav extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
  }

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return 'active';
    }
  }

  render() {
    return (
      <nav className="admin-nav scrollbar">
        <Link
          className={`admin-nav-link ${this.isActive('/admin/accounts')}`}
          to="/admin/accounts"
        >
          <svg className="icon"><use xlinkHref="#currency"/></svg>
          Accounts
        </Link>
          <Link
          className={`admin-nav-link ${this.isActive('/admin/companyDashboard')}`}
          to="/admin/companyDashboard"
        >
          <svg className="icon"><use xlinkHref="#admin"/></svg>
          Clients Dashboard
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/admin/offers')}`}
          to="/admin/offers"
        >
          <svg className="icon"><use xlinkHref="#currency"/></svg>
          Offers
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/admin/admins')}`}
          to="/admin/admins"
        >
          <svg className="icon"><use xlinkHref="#admin"/></svg>
          Admins
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/admin/pendingActions')}`}
          to="/admin/pendingActions"
        >
          <svg className="icon"><use xlinkHref="#admin"/></svg>
          Pending Action
        </Link>
      </nav>
    );
  }
}

export default AdminNav;
