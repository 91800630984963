import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

class ObjectListHeaderAdminAccount extends Component {
  static propTypes = {
    headings: PropTypes.array.isRequired
  }
  state = {
    isChecked: false
  }

  render() {
    let { headings, isAllSelected, hideCheckBox } = this.props;

    return (
      <div className="admin-form-row-with-checkbox">
        {!hideCheckBox && <div>
            <input
               name = 'preferenceEmail'
               type="checkbox"
               checked = {isAllSelected}
               style={{WebkitAppearance: `checkbox`, width: `20px`, marginRight: `10px`, marginTop: `-5px` }}
               onChange={(event) => this.props.handleCheckboxClick(event)}/>
        </div>}
      <div className="object-list-header">
        <div className="object-list-header-primary-heading">
          <h4 className="object-list-header-heading">
            {headings[0]}
          </h4>
        </div>
        <div className="object-list-header-secondary-headings">
          {headings.map((heading, index) => {
            if (index > 0) {
              return (
                <h4 key={shortid.generate()} className="object-list-header-heading">
                  {heading}
                </h4>
              );
            }
            return null;
          })}
        </div>
      </div>
      </div>
    )
  }
}

export default ObjectListHeaderAdminAccount;
