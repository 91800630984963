import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ClientsReportSidebar from "../../components/Clients/ClientsReportSidebar";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import * as API from "../../utils/api";
import ClientsSubscription from "./ClientsSubscription";
import Emitter from "../../utils/event-emitter";

class ClientsReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isOn: false,
      searchValue: "",
      showSubscription: false,
      data: [],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handleSearch = () => {
    let { user } = this.props;
    let { searchValue } = this.state;
    API.getSubscriptionReport(user, searchValue).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (typeof data != "undefined" || data != null) {
        if (data.data) {
          this.setState({ data: data.data });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleExpanderClick = (event, reportId) => {
    let { history } = this.props;
    history.push(`/client/reports/${reportId}`);
  };

  toggleButton = () => {
    this.setState({ isOn: !this.state.isOn });
  };

  showSubscriptionScreen = () => {
    this.setState({ showSubscription: !this.state.showSubscription });
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "--/--/----";
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      "Report Name",
      "Last Scheduled Run",
      "Next Scheduled Run",
      "Run Report",
    ];

    columns = data.map((item, index) => {
      return {
        primaryRow: {
          key: "Report Name",
          title: item.name,
        },
        secondaryColumns: [
          {
            title: item.name,
            hideForMobile: true,
          },
          {
            key: "Last Scheduled Run",
            title: this.getDate(item.last_scheduled_run),
          },
          {
            key: "Next Scheduled Run",
            title: this.getDate(item.next_schedule_run),
          },
          {
            key: "Run Report",
            title: (
              <div
                style={{
                  height: "1em",
                  width: "1em",
                  // marginLeft: "3em",
                  display: "flex",
                }}
              >
                <a
                  className={`object-row-expander`}
                  onClick={(event) => this.handleExpanderClick(event, item.id)}
                >
                  <div className={`client-caret expander-caret`} />
                </a>
              </div>
            ),
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  headerUtilities = () => {
    return (
      <div
        className='app-section-header-utilities'
        style={{ marginBottom: "2em" }}
      >
        <div className='admin-search-input-container main-header-search'>
          <input
            type='text'
            name='search'
            placeholder='Search'
            value={this.state.searchValue}
            className='admin-search-input'
            onChange={(event) =>
              this.setState({ searchValue: event.target.value }, () =>
                this.handleSearch()
              )
            }
          />
          <span className='search-icon' onClick={() => this.handleSearch()} />
        </div>
      </div>
    );
  };

  renderReportScreen = () => {
    let { data } = this.state;
    return (
      <div>
        <div className='main-header'>
          <div className='he-hed'>All Reports</div>
          {this.headerUtilities()}
        </div>
        {data && data.length ? (
          <div>
            {/* cardview for mobile device */}
            <ClientsCardView data={this.getObjectListData(data)} />
            {/* list view for big devices */}
            <HistoryObjectList
              data={this.getObjectListData(data)}
              optionalClasses='scrollbar in-sc'
              hideForMobile={true}
            />
          </div>
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
              No Reports Available
            </h3>
            <p>There are currently no reports available</p>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error, showSubscription } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>{appName} | Reports </title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ClientsReportSidebar
            user={this.props.user}
            title={"Reports"}
            showSubscription={this.state.showSubscription}
            showSubscriptionScreen={this.showSubscriptionScreen}
          />
          <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
            {showSubscription ? (
              <ClientsSubscription
                user={this.props.user}
                reportsList={this.state.data}
                updateModalState={this.props.updateModalState}
              />
            ) : (
              this.renderReportScreen()
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsReports;
