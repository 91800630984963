import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Redirect } from "react-router-dom";
// import ReactGA from 'react-ga';

// Packages
import Select from "react-select";

// API
import * as API from "../../utils/api";
import {
  networkMessage,
  showToast,
  getZohoPagesense,
} from "../../utils/helpers";

// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ObjectList from "../../components/ObjectList/ObjectList";
import CounterofferModal from "../../components/App/Modals/Client/CounterofferModal";

class EmployeeOffers extends Component {
  constructor() {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      offers: [],
      isLoading: true,
      input: {
        search: {
          value: "",
        },
        offer_type: {
          value: "pending",
        },
      },
      selectedOfferForCounter: "",
      showCounterOfferModal: "",
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    let newPartialInput = { ...this.state.input };
    let offer_type = "pending";
    if (
      newPartialInput.offer_type.value === undefined ||
      newPartialInput.offer_type.value === null ||
      newPartialInput.offer_type.value === ""
    ) {
      offer_type = "pending";
    } else {
      offer_type = newPartialInput.offer_type.value;
    }
    API.getClientsOfferList(this.props.user, "employee", offer_type).then(
      (data) => {
        let isAccessDenied =
          data && data.message && data.message == "Access denied."
            ? true
            : false;

        if (
          (typeof data != "undefined" || data != null) &&
          !data.error &&
          !isAccessDenied
        ) {
          this.setState((state) => ({
            ...state,
            offers: data.offers,
            isLoading: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            isLoading: false,
          }));
        }
      }
    );
    getZohoPagesense();
    // ReactGA.pageview("/employeeOffers");
  }

  handleInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        input: {
          ...state.input,
          ...newPartialInput,
        },
      }),
      () => {
        if (newPartialInput.search.value.length === 0) {
          this.handleKeyPress({ key: "Enter" });
        }
      }
    );
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState(
        {
          input: this.state.input,
          isLoading: true,
        },
        () => {
          this.handleSearch();
        }
      );
    }
  }

  setOfferData = (dataSet) => {
    this.setState((state) => ({
      ...state,
      offers: dataSet,
      isLoading: false,
    }));
  };

  handleSearch = () => {
    let queryString = this.state.input.search.value;
    let offer_type = this.state.input.offer_type.value;
    if (offer_type === undefined || offer_type === null || offer_type === "") {
      offer_type = "pending";
    }
    if (queryString) {
      API.clientsOfferSearch(
        this.props.user,
        "employee",
        "offers",
        offer_type,
        queryString
      ).then((data) => {
        if ((typeof data != "undefined" || data != null) && data.results) {
          this.setOfferData(data.results);
        } else {
          this.setOfferData([]);
        }
      });
    } else {
      this.handleSelectChange(this.state.input);
    }
  };

  handleRowActionClick = (event) => {
    const actionBehavior = event.target.getAttribute("data-behavior");
    const offerId = event.target.getAttribute("data-target");
    const { user } = this.props;
    let amount = 0;
    let message = "";
    for (var i in this.state.offers) {
      let offerIdState = this.state.offers[i].id;
      if (offerId == offerIdState) {
        amount = this.state.offers[i].total_in_dollars_as_currency;
        break;
      }
    }

    message =
      "Are you sure you want to accept the offer of $" +
      amount +
      " for this account.";

    switch (actionBehavior) {
      case "offer-accept":
        confirmAlert({
          //title: 'Confirm to submit',
          message: message,
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                let optionalProps = {
                  offerId: offerId,
                  action: "accept",
                  handleAcceptOffer: this.handleAcceptOffer,
                };
                this.props.updateModalState(
                  true,
                  "ACCEPT_REJECT_NOTES",
                  optionalProps
                );
              },
            },
            {
              label: "Go Back",
              onClick: () => "",
            },
          ],
        });
        break;
      case "offer-counter":
        this.handleCounterOffer(offerId);
        break;
      case "offer-reject":
        let optionalProps = {
          offerId: offerId,
          action: "reject",
          handleRejectAction: this.handleRejectAction,
        };
        this.props.updateModalState(true, "ACCEPT_REJECT_NOTES", optionalProps);
        break;
      default:
        alert("Error: Something went wrong");
    }
  };

  handleAcceptOffer = (offerId, note) => {
    const { user } = this.props;
    this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
    API.clientsAcceptOffer(user, "employee", offerId, note).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.handleOfferActionResponse(data, offerId, "offer-accept");
      } else {
        if (data.error) {
          this.props.updateModalState(true, "ERROR", { message: data.error });
        }
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleCounterOffer = (offerId) => {
    let selectedOffer = this.getOffer(offerId);
    let modalProps = {};

    if (selectedOffer.number_of_payments === 1) {
      this.setState((state) => ({
        ...state,
        showCounterOfferModal: true,
        selectedOfferForCounter: selectedOffer,
      }));
    } else {
      modalProps = {
        message:
          "This offer cannot be countered because the consumer wants to make multiple payments. Please accept or reject this offer.",
      };
      this.props.updateModalState(true, "ERROR", modalProps);
    }
  };

  handleRejectAction = (offerId, note) => {
    const { user } = this.props;
    this.props.updateModalState(false, "ACCEPT_REJECT_NOTES");
    API.clientsRejectOffer(user, "employee", offerId, note).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        this.handleOfferActionResponse(data, offerId, "offer-reject");
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleOfferActionResponse = (data, offerId, actionBehavior) => {
    if (data.message) {
      this.hideCounterOfferModal();
      if (data.paymentComplete) {
        this.props.updateModalState(true, "ADMIN_OFFER_ACCEPTED_AND_SETTLED");
        this.componentDidMount();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    } else {
      let optionalProps = {};
      let { offers } = this.state;
      let selectedOffer = this.getOffer(offerId);

      switch (actionBehavior) {
        case "offer-accept":
          optionalProps = {
            user: selectedOffer.user,
            offer: selectedOffer,
          };
          this.props.updateModalState(true, "OFFER_ACCEPTED", optionalProps);
          break;
        case "offer-counter":
          optionalProps = {
            user: selectedOffer.user,
            appEmail: this.props.appEmail,
          };
          this.props.updateModalState(true, "COUNTEROFFER_SENT", optionalProps);
          this.hideCounterOfferModal();
          break;
        case "offer-reject":
          optionalProps = {
            user: selectedOffer.user,
          };
          this.props.updateModalState(true, "OFFER_REJECTED", optionalProps);
          break;
        default:
          alert("Error: Something went wrong");
      }

      let newOffers = offers.filter((offer) => {
        return offer.id !== parseInt(offerId, 10);
      });

      this.setState((state) => ({
        ...state,
        offers: newOffers,
      }));
    }
  };

  hideCounterOfferModal = () => {
    this.setState((state) => ({
      ...state,
      showCounterOfferModal: false,
    }));
  };

  getOffer = (offerId) => {
    let { offers } = this.state;

    let selectedOffer = offers.filter((offer) => {
      return offer.id === parseInt(offerId, 10);
    });

    return selectedOffer[0];
  };

  getOffersObjectListData = () => {
    const { offers } = this.state;
    let disableMenuClass = "overflowMenu";
    if (this.state.input.offer_type.value !== "pending") {
      disableMenuClass = "inactive remove-inactive-menu";
    }

    if (this.state.input.offer_type.value == "pending") {
      return {
        headings: [
          "Consumer / Creditor",
          "Debt Amount",
          "Offer Amount",
          "Payment Type",
        ],
        columns: offers.map((offer) => {
          var paymentTypeTitle = "";

          if (offer.number_of_payments === 1) {
            paymentTypeTitle = "One Time";
          } else {
            paymentTypeTitle = `${offer.number_of_payments} Month`;
          }

          return {
            primaryColumn: {
              title: offer.user.first_name + " " + offer.user.last_name,
              secondaryInfo: offer.creditor_name,
            },
            secondaryColumns: [
              {
                title: `$${offer.original_debt_in_dollars}`,
              },
              {
                title: `$${offer.total_in_dollars_as_currency}`,
              },
              {
                title: paymentTypeTitle,
              },
            ],
            rowType: disableMenuClass,
            rowActions:
              offer.number_of_payments === 1
                ? [
                    {
                      title: "Accept Offer",
                      optionalClasses: "alert-positive",
                      dataBehavior: "offer-accept",
                      dataTarget: offer.id,
                    },
                    {
                      title: "Counter Offer",
                      dataBehavior: "offer-counter",
                      dataTarget: offer.id,
                    },
                    {
                      title: "Reject Offer",
                      optionalClasses: "alert",
                      dataBehavior: "offer-reject",
                      dataTarget: offer.id,
                    },
                  ]
                : [
                    {
                      title: "Accept Offer",
                      optionalClasses: "alert-positive",
                      dataBehavior: "offer-accept",
                      dataTarget: offer.id,
                    },
                    {
                      title: "Reject Offer",
                      optionalClasses: "alert",
                      dataBehavior: "offer-reject",
                      dataTarget: offer.id,
                    },
                  ],
          };
        }),
      };
    } else {
      return {
        headings: [
          "Consumer / Creditor",
          "Debt Amount",
          "Offer Amount",
          "Payment Type",
          "Description",
        ],
        columns: offers.map((offer) => {
          var paymentTypeTitle = "";

          if (offer.number_of_payments === 1) {
            paymentTypeTitle = "One Time";
          } else {
            paymentTypeTitle = `${offer.number_of_payments} Month`;
          }

          return {
            primaryColumn: {
              title: offer.user.first_name + " " + offer.user.last_name,
              secondaryInfo: offer.creditor_name,
            },
            secondaryColumns: [
              {
                title: `$${offer.original_debt_in_dollars}`,
                optionalClasses: "secondary-cell-utility offer-rows",
              },
              {
                title: `$${offer.total_in_dollars_as_currency}`,
                optionalClasses: "secondary-cell-utility offer-rows",
              },
              {
                title: paymentTypeTitle,
                optionalClasses: "secondary-cell-utility offer-rows",
              },
              {
                title: offer.description ? offer.description : "n/a",
                optionalClasses: "secondary-cell-utility description",
              },
            ],
            rowType: disableMenuClass,
            rowActions: [],
          };
        }),
      };
    }
  };

  handleSelectChange = (newPartialInput) => {
    if (
      newPartialInput.offer_type.value !== undefined ||
      newPartialInput.offer_type.value !== null ||
      newPartialInput.offer_type.value !== ""
    ) {
      this.setState((state) => ({
        ...state,
        input: {
          ...state.input,
          ...newPartialInput,
        },
        isLoading: true,
      }));
      API.getClientsOfferList(
        this.props.user,
        "employee",
        newPartialInput.offer_type.value
      ).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          this.setOfferData(data.offers);
        } else {
          if (data && data.error) {
            this.props.updateModalState(true, "ERROR", { message: data.error });
          }
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { appName, user } = this.props;
    const {
      input,
      isLoading,
      showCounterOfferModal,
      selectedOfferForCounter,
      error,
    } = this.state;

    const modal = showCounterOfferModal ? (
      <CounterofferModal
        user={user}
        handleOfferActionResponse={this.handleOfferActionResponse}
        offerRecipient={selectedOfferForCounter.user}
        offer={selectedOfferForCounter}
        hideModal={this.hideCounterOfferModal}
        updateModalState={this.props.updateModalState}
      />
    ) : null;

    let sectionHeaderUtilities = (
      <div className='app-section-header-utilities'>
        <div className='admin-search-input-container'>
          <input
            type='text'
            name='search'
            placeholder='Search'
            value={input.search.value}
            onKeyDown={this.handleKeyPress}
            className='admin-search-input'
            onChange={(event) =>
              this.handleInputChange({ search: { value: event.target.value } })
            }
          />
          <span
            className='search-icon'
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
      </div>
    );

    let offerFilterAction = (
      <div className='app-section-header-offer-filter'>
        <label htmlFor='name offer-select-label'>Filter By: </label>
        <Select
          inputProps={{
            autoComplete: "none",
            autoCorrect: "off",
            spellCheck: "off",
          }}
          className='form-select'
          name='offer_type'
          value={input.offer_type.value}
          placeholder='Select Offer'
          options={[
            { value: "pending", label: "Pending" },
            { value: "accepted", label: "Accepted" },
            { value: "rejected", label: "Rejected" },
            { value: "countered", label: "Countered" },
            { value: "closed", label: "Closed" },
          ]}
          resetValue=''
          onChange={(event) =>
            this.handleSelectChange({
              offer_type: { value: event ? event.value : "", hasError: false },
              search: { value: "" },
            })
          }
        />
      </div>
    );

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div>
        <Helmet>
          <title>{appName} | Offers</title>
        </Helmet>
        <AppSectionHeader
          title='Offers'
          offerFilter={offerFilterAction}
          utilities={sectionHeaderUtilities}
        />
        {isLoading ? (
          <div style={{ marginTop: "10%" }}>
            <AppCanvasLoader />
          </div>
        ) : this.state.offers ? (
          this.state.offers.length > 0 ? (
            <ObjectList
              data={this.getOffersObjectListData()}
              rowActionClick={(event) => this.handleRowActionClick(event)}
            />
          ) : this.state.input.search.value.length ? (
            <div className='admin-admins-empty-state'>
              <p>No results found for:</p>
              <h3 className='admin-admins-empty-state-heading'>
                {this.state.input.search.value}
              </h3>
            </div>
          ) : (
            <div className='admin-admins-empty-state'>
              <h3 className='admin-admins-empty-state-heading'>
                Currently no offers!
              </h3>
              <p>There are currently no submitted offers for you to review.</p>
            </div>
          )
        ) : (
          ""
        )}
        {showCounterOfferModal ? modal : ""}
        {showCounterOfferModal ? <div className='curtain'></div> : ""}
      </div>
    );
  }
}

export default EmployeeOffers;
