import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import editIcon from "../../assets/icons/edit_blue.svg";
import editIcon2 from "../../assets/icons/edit_user_icon.png";
import downloadImg from "../../assets/icons/info_teal.png";
import sendResendIcon from "../../assets/images/quickbooks/ic_comm_gray.svg";
// import
class ClientsCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
    };
  }
  goToEditSetting = (e, path) => {
    e.preventDefault();
    let finalPath = path + "/edit-setting";
    this.props.history.push(finalPath);
  };

  handleOnTooltipClick() {
    if (!this.state.showInfo) {
      this.setState({ showInfo: true });
    } else {
      this.setState({ showInfo: false });
    }
  }

  renderPrimaryRow = (data, status) => {
    let { isStatus } = this.props;
    return (
      <div className='card-view'>
        <div className='card-view-content'>
          <h4 className='card-view-content-header mg-text-color'>{data.key}</h4>
        </div>
        <div className='card-view-content'>
          <div className='card-view-content-primary-data point-space pad-left-half'>
            {data.value ? data.value : data.title}
            {data.editIcon ? (
              <img
                className='edit-icon'
                src={editIcon}
                onClick={(e) => this.goToEditSetting(e, data.rowPath)}
              />
            ) : null}
          </div>
          {isStatus && (
            <div
              className={`card-view-content-status-point ${
                status ? "col1" : "col2"
              }`}
            />
          )}
          {data.secondaryInfo ? (
            <div className='card-view-content-secondary-info pad-left-half'>
              {data.secondaryInfo}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderSecondaryRows = (data, emptyPrimary, rowData) => {
    let { isStatus, expanderLabel, test } = this.props;

    let editUserEvent = {
      title: "Edit User",
    };
    let sendInvtEvent = {
      title: "Send/Re-send Invite",
    };
    let resetPassEvent = {
      title: "Reset Password",
    };
    // let disableUserEvent = {
    //   title: "Disable User",
    // };
    return data.map((row, index) => {
      if (row.hideForMobile) return;

      if (row.rowType && row.rowType === "arrow-click") {
        return (
          <div
            key={shortid.generate()}
            className={`card-view${
              emptyPrimary && index === 0 ? "" : " content-space"
            }`}
          >
            {!!rowData.rowData && rowData.rowData.openid_id === null && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                    color: "#008bb2",
                  }}
                >
                  <span>Actions:</span>
                  <span
                    className='mg-cp'
                    title='Edit User'
                    onClick={() =>
                      this.props.rowActionClick(
                        editUserEvent,
                        rowData.rowData.id
                      )
                    }
                  >
                    <img className='edit-icon' src={editIcon2} />
                  </span>
                  <span
                    className='mg-cp'
                    title='Send/Re-send Invite'
                    onClick={() =>
                      this.props.rowActionClick(
                        sendInvtEvent,
                        rowData.rowData.id
                      )
                    }
                  >
                    <img className='edit-icon' src={sendResendIcon} />
                  </span>
                  <span
                    className='mg-cp'
                    title='Reset Password'
                    onClick={() =>
                      this.props.rowActionClick(
                        resetPassEvent,
                        rowData.rowData.id
                      )
                    }
                  >
                    <img className='edit-icon' src={editIcon} />
                  </span>
                </div>
              </>
            )}

            {!test ? (
              <>
                {row.key && (
                  <div className='card-view-content'>
                    <h4 className='card-view-content-header mg-text-color'>
                      {row.key}
                    </h4>
                  </div>
                )}
                <div className='card-view-content'>
                  <a
                    onClick={() => this.props.rowActionClick(rowData)}
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "125%",
                    }}
                  >
                    <div
                      className={`card-view-content expander-link${
                        row.optionalClasses ? " " + row.optionalClasses : ""
                      }`}
                      style={{ whiteSpace: !expanderLabel && "nowrap" }}
                    >
                      {expanderLabel ? expanderLabel : "View Details"}
                    </div>
                    <div
                      className='object-row-arrow-consumer'
                      style={{ marginTop: 3 }}
                    >
                      <div className={`client-caret expander-caret`} />
                    </div>
                  </a>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        return (
          <div
            key={index + row.key}
            className={`card-view${
              emptyPrimary && index === 0 ? "" : " content-space"
            }`}
          >
            <div className='card-view-content'>
              <h4 className='card-view-content-header mg-text-color'>
                {row.key}
              </h4>
            </div>
            <div className='card-view-content'>
              <div
                className={`card-view-content-secondary-data point-space pad-left-half mobile-size-alignment ${
                  row.optionalClasses ? " " + row.optionalClasses : ""
                } mg-text-color`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {row.title}
                </span>
              </div>
              {isStatus && (
                <div>
                  <div
                    className={`card-view-content-status-point ${
                      rowData.taskStatus
                        ? "col3"
                        : rowData.status
                        ? "col1"
                        : "col2"
                    }`}
                  />
                </div>
              )}
              {row.secondaryInfo ? (
                <div className='card-view-content-secondary-info pad-left-half'>
                  {row.secondaryInfo}
                </div>
              ) : null}
            </div>
          </div>
        );
      }
    });
  };

  render() {
    let { data, showEditPopup } = this.props;
    return data && data.columns && data.columns.length ? (
      data.columns.map((rowData, index) => {
        return (
          <div className='client-side-card' key={"_" + index.toString()}>
            {rowData.clickable && rowData.rowData ? (
              <a
                className='card'
                key={index.toString()}
                onClick={(event) => {
                  showEditPopup(event, rowData.rowData);
                }}
              >
                {rowData &&
                  rowData.primaryRow &&
                  this.renderPrimaryRow(rowData.primaryRow, rowData.status)}
                {this.renderSecondaryRows(
                  rowData.secondaryColumns,
                  rowData.emptyPrimary
                )}
              </a>
            ) : (
              <div className='card' key={index.toString()}>
                {rowData &&
                  rowData.primaryRow &&
                  this.renderPrimaryRow(rowData.primaryRow, rowData.status)}
                {this.renderSecondaryRows(
                  rowData.secondaryColumns,
                  rowData.emptyPrimary,
                  rowData
                )}
              </div>
            )}
          </div>
        );
      })
    ) : (
      <div />
    );
  }
}

export default withRouter(ClientsCardView);
