import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";

class PreviewInvoiceModal extends Component {
  state = {
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    let { payload, user } = this.props.optionalProps;

    API.clientPreviewInvoice(user, payload).then((data) => {
      if (data && data.status_code) {
        if (data.data && data.status_code == 200) {
          let getView = document.getElementById("preview-invoice");
          if (getView) {
            getView.innerHTML = data.data;
          }
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        optionalClasses="add-account-modal preview-modal scroll"
        title="Preview Invoice"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {isLoading && <AppCanvasLoader />}
        <div id="preview-invoice"></div>
      </Modal>
    );
  }
}

export default withRouter(PreviewInvoiceModal);
