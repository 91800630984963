import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
// API
import * as API from "../../../../utils/api";
import { showToast } from "../../../../utils/helpers";
import Modal from "../Modal";
import BottomScrollListener from "react-bottom-scroll-listener";
import ObjectDebtList from "../../../ObjectList/ObjectDebtList";
import ToggleSwitch from "../../../ToggleSwitch";

class AssignEmployee extends Component {
  state = {
    employees: [],
    input: {
      employee_name: {
        value: "",
        id: "",
        hasError: false,
      },
      employee_email: {
        value: "",
        hasError: false,
      },
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    showFlash: false,
    flashMessage: "",
    disable: false,
    selectedCheckBox: [],
    buttonVisibility: false,
  };

  componentDidMount() {
    let employee_id = this.props.optionalProps.consumerId;
    if (this.props.optionalProps.assign === "consumer") {
      API.fetchAllUnassignedConsumers(this.props.user, employee_id).then(
        (data) => {
          if (
            (typeof data != "undefined" || data != null) &&
            data.data &&
            !data.error
          ) {
            let employeeArray = this.convertEmployeeToArray(data.data);
            this.setState((state) => ({
              employees: employeeArray,
              isLoading: false,
            }));
          } else {
            this.setState({ isLoading: false });
          }
        }
      );
    } else {
      API.fetchAllClientsEmployee(this.props.user).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.employees &&
          !data.error
        ) {
          let employeeArray = this.convertEmployeeToArray(data.employees);
          this.setState((state) => ({
            employees: employeeArray,
            isLoading: false,
          }));
        } else {
          this.setState({ isLoading: false });
        }
      });
    }
  }

  convertEmployeeToArray(creditorsArray) {
    let newEmployees = [...creditorsArray];

    for (var i = 0; i < newEmployees.length; i++) {
      newEmployees[i].value = newEmployees[i]["id"];
      delete newEmployees[i].id;
      newEmployees[i].label = newEmployees[i]["display_name"];
      delete newEmployees[i].name;
      if (newEmployees[i].is_assigned) {
        this.state.selectedCheckBox.push(newEmployees[i].user_id);
      }
    }

    return newEmployees;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { user } = this.props;
    //if (this.validateForm()) {
    let payload = {};
    if (this.props.optionalProps.assign === "consumer") {
      payload = {
        user_ids: this.state.selectedCheckBox,
        employee_id: this.props.optionalProps.consumerId,
      };
    } else {
      payload = {
        user_ids: this.props.optionalProps.consumerId,
        employee_id: this.state.input.employee_name.id,
      };
    }
    API.assignEmployeeToConsumer(user, payload).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (this.props.optionalProps.assign === "consumer") {
          this.props.updateModalState(true, "SUCCESS", {
            message: "Account(s) assigned successfully.",
          });
        } else {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
        this.props.optionalProps.releoadTableData();
      } else {
        this.setState((state) => ({
          ...state,
          hasAnyError: true,
          disable: false,
        }));
      }
    });
    //}
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  validateForm = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "employee_name") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "employee_name") {
      message = "Please select a employee";
    } else if (input === "employee_email") {
      message = "Please enter a valid email";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  dismissFlash = () => {
    this.handleModalClose();
    this.setState((state) => ({
      ...state,
      showFlash: false,
      flashMessage: "",
    }));
  };

  multiSelect = () => {
    if (this.state.selectedCheckBox.length > 1) {
      let first =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 2];
      let last =
        this.state.selectedCheckBox[this.state.selectedCheckBox.length - 1];
      if (first > last) {
        let temp = first;
        first = last;
        last = temp;
      }
      this.state.employees.map((consumer) => {
        if (consumer.user_id >= first && consumer.user_id <= last) {
          consumer.is_assigned = true;
        }
      });
    }
    this.setState({ consumers: this.state.consumers });
  };

  onCheckboxClick = (event, item) => {
    const consumerId = event.target.name;
    let isShiftKey = event.shiftKey;

    this.state.employees.map((consumer) => {
      if (consumer.user_id == consumerId) {
        if (event.target.checked) {
          consumer.is_assigned = true;
          this.state.selectedCheckBox.push(item.user_id);
        } else {
          consumer.is_assigned = false;
          let Index = this.state.selectedCheckBox.indexOf(item.user_id);
          this.state.selectedCheckBox.splice(Index, 1); // c
        }
      }
    });
    this.setState({ selectedCheckBox: this.state.selectedCheckBox }, () => {
      isShiftKey && this.multiSelect();
      this.buttonVisibility();
    });
  };

  buttonVisibility = () => {
    let { employees, buttonVisibility } = this.state;
    let flag = false;
    employees.map((item) => {
      if (item.is_assigned) {
        flag = true;
      }
      this.setState({ buttonVisibility: flag });
    });
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [];

    columns = data.map((item, index) => {
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: (
              <input
                name={item.user_id}
                value={item.user_id}
                key={item.user_id}
                type="checkbox"
                checked={item.is_assigned}
                style={{
                  WebkitAppearance: `checkbox`,
                  width: `20px`,
                }}
                onChange={() => {}}
                onClick={(event) => this.onCheckboxClick(event, item)}
              />
            ),
            opClassesPar: "task-checkbox",
          },
          {
            title: item.display_name ? item.display_name : "",
          },
        ],
        fullRowIsClickable: false,
        rowType: "",
        optionalRowClasses: "object-row-inner-padding",
      };
    });

    return {
      headings,
      columns,
    };
  };

  render() {
    let { employees, input, error, isLoading } = this.state;
    let { optionalProps } = this.props;
    let isConsumer = optionalProps.assign === "consumer" ? true : false;
    const style = {
      container: { display: "flex" },
      content: { width: "60%", paddingTop: `10px` },
      label: { width: "40%", paddingTop: `10px` },
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title={isConsumer ? "Assign Accounts" : "Assign Employee"}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses="scroll task-popup-sp"
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            {employees && employees.length > 0 && (
              <BottomScrollListener
                offset={50}
                onBottom={this.handleContainerOnBottom}
                debounce={0}
              >
                {(scrollRef) => (
                  <div>
                    <ObjectDebtList
                      data={this.getObjectListData(employees)}
                      scrollRef={scrollRef}
                      removePadding={true}
                      showLoader={this.state.showLoader}
                    />
                  </div>
                )}
              </BottomScrollListener>
            )}
            {error.hasAnyError ? (
              <div
                className="input-container error"
                style={{
                  marginBottom: "1.5em",
                  marginTop: "-1em",
                  display: "flex",
                }}
              >
                <div className="input-error-message" style={{ marginTop: 0 }}>
                  Please Select contact
                </div>
              </div>
            ) : null}
            <div className="modal-actions" style={{ marginBottom: "2rem" }}>
              <a
                className="cta mg-brand2-color"
                style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                onClick={(event) => this.handleSubmit(event)}
              >
                Done
              </a>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(AssignEmployee);
