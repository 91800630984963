import React, { Component } from 'react';

class TabGroup extends Component {
  state = {
    activeSlide: "slide-1"
  }

  renderActiveClass(slide) {
    if (slide === this.state.activeSlide) {
      return 'active'
    }
  }

  handleTabClick = (event) => {
    let targetSlide = event.target.getAttribute('data-target');

    this.setState(state => ({
      activeSlide: targetSlide
    }))
  }

  render() {
    return (
      <div className="tab-group">
        <div className="tab-group-control">
          <a
            className={`tab-group-control-tab ${this.renderActiveClass("slide-1")}`}
            data-target="slide-1"
            onClick={(event) => this.handleTabClick(event)}
          >
            Quick Settlement
          </a>
          <a
            className={`tab-group-control-tab ${this.renderActiveClass("slide-2")}`}
            data-target="slide-2"
            onClick={(event) => this.handleTabClick(event)}
          >
            Monthly Agreement
          </a>
        </div>
        <div className="tab-group-canvas">
          <div
            className={`tab-group-canvas-slide ${this.renderActiveClass("slide-1")}`}
          >
            <div className="tab-group-canvas-slide-content">
              <h3 className="tab-group-canvas-slide-heading">
                Settle quickly and easily on your terms
              </h3>
              <p className="tab-group-canvas-slide-copy">
                Make us an offer to settle your account today. You will only pay that amount there and then and we will settle the remaining balance.
              </p>
              <h4>
                Who is this for?
              </h4>
              <p className="tab-group-canvas-slide-copy">
                This payment option is ideal for those who want to clear their delinquent debt in one go and prefer to make one lump-sum payment.
              </p>
            </div>
          </div>
          <div
            className={`tab-group-canvas-slide ${this.renderActiveClass("slide-2")}`}
          >
            <div className="tab-group-canvas-slide-content">
              <h3 className="tab-group-canvas-slide-heading">
                Regular payments through our monthly payment plan
              </h3>
              <p className="tab-group-canvas-slide-copy">
                Determine what you can afford to pay each month and make us an offer. If accepted, you then make regular payments at a pace that works for you.
              </p>

              <h4>
                Who is this for?
              </h4>
              <p className="tab-group-canvas-slide-copy">
                This payment option is ideal for those who can’t afford a one-off lump sum, but want to control how much they pay each month.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabGroup;
