import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom"

// API
import * as API from '../../utils/api';

import { networkMessage, showToast } from '../../utils/helpers'


// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import AppSectionHeader from '../../components/App/AppSectionHeader';

class AdminNotifications extends Component {

  state = {
    notificationData: [],
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {

    API.getPendingOffersCount(this.props.user).then(data => {
     if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        this.setState(state => ({
          ...state,
          notificationData: data,
          isLoading: false
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  render() {
    const { appName } = this.props;
    let { notificationData } = this.state;
    const {
      isLoading, error
    } = this.state;

    let marginLeft = {
      marginLeft: 0
    }

    let pendingActionFlag = false;

    let content = [];
    if (notificationData.offers_count > 0) {
      content.push(<a href="/admin/offers" key={1}><div className="card" >
        <div className="card-box-shadow">
          <h2 className="card-h2">{notificationData.offers_count}</h2>
          <h5 className="card-h5"> Offers</h5>
        </div>
      </div></a>)
      marginLeft = {
        marginLeft: 20
      }
      pendingActionFlag = true;
    }
    if (notificationData.address_pending_count > 0) {
      content.push(<a href="/admin/pendingAddressUser" key={2}>
        <div className="card" style={marginLeft} >
          <div className="card-box-shadow">
            <h2 className="card-h2">{notificationData.address_pending_count}</h2>
            <h5 className="card-h5"> Address Verification</h5>
          </div>
        </div></a>)
      pendingActionFlag = true;
    }

    if (!pendingActionFlag) {
      content.push(<a href="/admin/pendingAddressUser" key={3}>
        <div className="card" >
          <div className="card-box-shadow">
            <h2 className="card-h2">0</h2>
            <h5 className="card-h5"> No Pending Action(s)</h5>
          </div>
        </div></a>)
    }

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <Helmet>
            <title>{appName} | Offers</title>
          </Helmet>
          <AppSectionHeader
            title="Pending Action(s)"
          />
          <div>{content}</div>
        </div>
      );
  }
}

export default AdminNotifications;
