import React, { Component } from 'react';


// Components
import Modal from './Modal';



class PaymentPreferencesModal extends Component {


  hideErrorModal = () => {
      this.props.hideModal();
  }


  render() {
    let errorCls = 'error-card-cls'
    return (
      <Modal
        title=""
        hideModal={this.props.hideModal}
        closeOnClickOutside={true}
        optionalClasses = {errorCls}
      >
          <h4 style={{ marginTop: 70, fontFamily: 'Open Sans ,sans-serif'}}>No payment information available for this preference. You can fill the required details <a href='/welcome-screen/add-banking-info' onClick={()=>{localStorage.setItem("hereClick", true)}}>here</a>.</h4>

        <div className="modal-actions">
          <a
            className="cta mg-brand2-color"
            style={{marginTop: 20,padding:"0.45rem 2rem"}}
            onClick={this.hideErrorModal}
          >
            Okay
          </a>
        </div>
      </Modal>
    )
  }
}

export default PaymentPreferencesModal;