import $ from "jquery";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ToggleSwitch from "../../../components/ToggleSwitch";
import * as API from "../../../utils/api";
import { showToast, validateEmail } from "../../../utils/helpers";
import stateDataShortNames from "../../../utils/stateDataShortNames.json";
import { getBaseUrl } from "../../../utils/api";
import Cookies from "universal-cookie";
import styles from "../../../components/Buttons/button.module.scss";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
// import Modal from "../../components/App/Modals/Modal";

class CustomizeWorkFlowStep2 extends Component {
  state = {
    useAddress: false,
    organizationDetails: "",
    mergeVarData: "",
    toggleACH: false,
    toggleDomasticWire: false,
    bankingInfo: false,
    communication: false,
    isDisable: true,
    isDisableDomastic: true,
    input: {
      consumerInformation: {
        bankABA: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        accountNoACH: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        bankDomasticWire: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        accountNoDomasticWire: {
          value: "",
          hasError: false,
          isRequired: true,
        },

        companyName: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        companyNameWire: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        first_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        last_name: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        email: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        street_address_primary: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        street_address_ACH: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        city2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        state2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode: {
          value: "",
          hasError: false,
          isRequired: false,
        },
        zipcode2: {
          value: "",
          hasError: false,
          isRequired: false,
        },
      },
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.props.getOrganizationalDetails();
    this.getOrganizationalDetails("Event 1");
  }

  handleConsumerInfoInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: {
          ...state.input.consumerInformation,
          ...newPartialInput,
        },
      },
    }));
  }
  getNumberFromPhoneString = (phoneString) => {
    let strippedNumber = phoneString.replace(/\(|\)|\s|-/g, "");
    return strippedNumber;
  };

  getOrganizationalDetails = async (event) => {
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    this.setState(
      (state) => ({
        ...state,
        organizationDetails: this.props.orgData,
        mergeVarData: this.props.mergeVarData,
      }),
      () => {
        this.bankingInformationData();
      }
    );
  };

  bankingInformationData = () => {
    let { mergeVarData } = this.state;
    Object.entries(mergeVarData).forEach(([key, value]) => {
      let { input, toggleDomasticWire } = this.state;
      let Ach = true,
        wire = true;
      if (key === "Payment Information") {
        value["Domestic ACH"].map((item) => {
          if (item.name === "Bank Name") {
            input.consumerInformation.companyName.value = item.value;
            if (item.value !== "") {
              this.setState((state) => ({
                ...state,
                toggleACH: true,
                isDisable: false,
                bankingInfo: true,
              }));
            }
            if (item.value === "" || item.value === null) {
              Ach = false;
              this.setState((state) => ({
                ...state,
                toggleACH: false,
                isDisable: true,
              }));
            }
          } else if (item.name === "ABA") {
            input.consumerInformation.bankABA.value = item.value;
          } else if (item.name === "Account #") {
            input.consumerInformation.accountNoACH.value = item.value;
          } else if (item.name === "Line1") {
            input.consumerInformation.street_address_ACH.value = item.value;
          } else if (item.name === "Line2") {
            input.consumerInformation.street_address_secondary.value =
              item.value;
          } else if (item.name === "City") {
            input.consumerInformation.city.value = item.value;
          } else if (item.name === "State") {
            input.consumerInformation.state.value = item.value;
          } else if (item.name === "Zip") {
            input.consumerInformation.zipcode.value = item.value;
          }
          if (item.value !== "" && item.value !== null) {
            this.setState((state) => ({
              ...state,
              toggleACH: true,
              isDisable: false,
            }));
          }
        });
        value["Domestic Wire"].map((item) => {
          if (item.name === "Bank Name") {
            input.consumerInformation.companyNameWire.value = item.value;
            if (item.value !== "") {
              this.setState((state) => ({
                ...state,
                toggleDomasticWire: true,
                isDisableDomastic: false,
                bankingInfo: true,
              }));
            }
            if (item.value === "" || item.value === null) {
              wire = false;
              this.setState((state) => ({
                ...state,
                toggleDomasticWire: false,
                isDisableDomastic: true,
              }));
            }
          } else if (item.name === "ABA") {
            input.consumerInformation.bankDomasticWire.value = item.value;
          } else if (item.name === "Account #") {
            input.consumerInformation.accountNoDomasticWire.value = item.value;
          } else if (item.name === "Line1") {
            input.consumerInformation.street_address_primary.value = item.value;
          } else if (item.name === "Line2") {
            input.consumerInformation.street_address_secondary.value =
              item.value;
          } else if (item.name === "City") {
            input.consumerInformation.city2.value = item.value;
          } else if (item.name === "State") {
            input.consumerInformation.state2.value = item.value;
          } else if (item.name === "Zip") {
            input.consumerInformation.zipcode2.value = item.value;
          }
          if (item.value !== "" && item.value !== null) {
            this.setState((state) => ({
              ...state,
              toggleDomasticWire: true,
              isDisableDomastic: false,
            }));
          }
        });
      }
      if (!Ach && !wire) {
        this.setState({
          ...this.state,
          bankingInfo: false,
        });
      }
      this.setState((state) => ({ ...state, input }));
    });
    this.setState({ isLoading: false });
    // setTimeout(() => {}, 5000);
  };
  removeBankingInfo = () => {
    let { input } = this.state;
    input.consumerInformation.companyName.value = "";
    input.consumerInformation.bankABA.value = "";
    input.consumerInformation.accountNoACH.value = "";
    input.consumerInformation.street_address_ACH.value = "";
    input.consumerInformation.city.value = "";
    input.consumerInformation.state.value = "";
    input.consumerInformation.zipcode.value = "";
    input.consumerInformation.companyNameWire.value = "";
    input.consumerInformation.bankDomasticWire.value = "";
    input.consumerInformation.accountNoDomasticWire.value = "";
    input.consumerInformation.street_address_primary.value = "";
    input.consumerInformation.city2.value = "";
    input.consumerInformation.state2.value = "";
    input.consumerInformation.zipcode2.value = "";
    this.setState({ input });
  };

  handleSubmit = (event) => {
    let { organizationDetails, input } = this.state;
    let { location } = this.props;
    let domesticACHAdress =
      this.state.mergeVarData["Payment Information"]["Domestic ACH"];
    let DomesticWireAdress =
      this.state.mergeVarData["Payment Information"]["Domestic Wire"];

    event.preventDefault();
    let payload = {
      merge_vars: [
        {
          merge_variable_id: domesticACHAdress[0].merge_variable_id,
          value: input.consumerInformation.companyName.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: domesticACHAdress[1].merge_variable_id,
          value: input.consumerInformation.accountNoACH.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: domesticACHAdress[2].merge_variable_id,
          value: input.consumerInformation.bankABA.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: domesticACHAdress[3].merge_variable_id,
          value: input.consumerInformation.street_address_ACH.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: domesticACHAdress[4].merge_variable_id,
          value: input.consumerInformation.city.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: domesticACHAdress[5].merge_variable_id,
          value: input.consumerInformation.state.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: domesticACHAdress[6].merge_variable_id,
          value: input.consumerInformation.zipcode.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[0].merge_variable_id,
          value: input.consumerInformation.companyNameWire.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[1].merge_variable_id,
          value: input.consumerInformation.accountNoDomasticWire.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[2].merge_variable_id,
          value: input.consumerInformation.bankDomasticWire.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[3].merge_variable_id,
          value: input.consumerInformation.street_address_primary.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[4].merge_variable_id,
          value: input.consumerInformation.city2.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[5].merge_variable_id,
          value: input.consumerInformation.state2.value,
          organization_id: organizationDetails.organization.id,
        },
        {
          merge_variable_id: DomesticWireAdress[6].merge_variable_id,
          value: input.consumerInformation.zipcode2.value,
          organization_id: organizationDetails.organization.id,
        },
      ],
    };
    if (this.validateForm()) {
      API.updateMergeVariablesClient(this.props.user, payload).then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          if (data.status_code == 200) {
            //show toast only if any field update
            if (data.modify_count != 0) {
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
            }
            this.props.getOrganizationalDetails();
            this.props.handleNext("step3");
          } else if (data.status_code !== 200) {
            this.setState((state) => ({
              ...state,
              hasAnyError: true,
            }));
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
        }
      });
    } else {
      this.props.updateModalState(true, "Default_TERM", {
        message:
          "Please enter banking information before proceeding to the next step. If you don’t have any banking information to enter then select “No” instead of “Yes”.",
        handleSubmit: false,
      });
      this.setFormErrorState();
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }
  toggleButtons = (event, res) => {
    event.preventDefault();
    let { toggleACH, toggleDomasticWire, bankingInfo } = this.state;
    if (bankingInfo) {
      if (res === "ACH Payments") {
        toggleACH = !toggleACH;
        this.removeBankingInformation("ACH");
        this.setState({ toggleACH });
        if (toggleACH === true) {
          this.AddBankingInformation("ACH");
          this.setState({ isDisable: false });
        } else if (toggleACH === false) {
          this.setState({ isDisable: true });
          if (toggleDomasticWire === false) {
            this.setState({ bankingInfo: false });
          }
        }
      } else if (res === "Domastic Wire") {
        toggleDomasticWire = !toggleDomasticWire;
        this.removeBankingInformation("Domestic");
        this.setState({ toggleDomasticWire });
        if (toggleDomasticWire === true) {
          this.AddBankingInformation("Domestic");
          this.setState({ isDisableDomastic: false });
        } else if (toggleDomasticWire === false) {
          this.setState({ isDisableDomastic: true });
          if (toggleACH === false) {
            this.setState({ bankingInfo: false });
          }
        }
      }
    }
  };

  removeBankingInformation = (res) => {
    let { input } = this.state;
    let temp = { ...input };
    if (res === "ACH") {
      temp.consumerInformation.companyName.value = "";
      temp.consumerInformation.bankABA.value = "";
      temp.consumerInformation.accountNoACH.value = "";
      temp.consumerInformation.street_address_ACH.value = "";
      temp.consumerInformation.city.value = "";
      temp.consumerInformation.state.value = "";
      temp.consumerInformation.zipcode.value = "";
      this.setState((state) => ({ ...state, temp }));
    } else if (res === "Domestic") {
      temp.consumerInformation.companyNameWire.value = "";
      temp.consumerInformation.bankDomasticWire.value = "";
      temp.consumerInformation.accountNoDomasticWire.value = "";
      temp.consumerInformation.street_address_primary.value = "";
      temp.consumerInformation.city2.value = "";
      temp.consumerInformation.state2.value = "";
      temp.consumerInformation.zipcode2.value = "";
      this.setState((state) => ({ ...state, temp }));
    }
  };
  AddBankingInformation = (res) => {
    Object.entries(this.state.mergeVarData).forEach(([key, value]) => {
      let { input, toggleDomasticWire } = this.state;
      if (key === "Payment Information") {
        if (res === "ACH") {
          value["Domestic ACH"].map((item) => {
            item.name === "Bank Name"
              ? (input.consumerInformation.companyName.value = item.value)
              : item.name === "ABA"
              ? (input.consumerInformation.bankABA.value = item.value)
              : item.name === "Account #"
              ? (input.consumerInformation.accountNoACH.value = item.value)
              : item.name === "Line1"
              ? (input.consumerInformation.street_address_ACH.value =
                  item.value)
              : item.name === "Line2"
              ? (input.consumerInformation.street_address_secondary.value =
                  item.value)
              : item.name === "City"
              ? (input.consumerInformation.city.value = item.value)
              : item.name === "State"
              ? (input.consumerInformation.state.value = item.value)
              : item.name === "Zip" &&
                (input.consumerInformation.zipcode.value = item.value);
            if (item.value !== "" && item.value !== null) {
              this.setState((state) => ({
                ...state,
                toggleACH: true,
                isDisable: false,
              }));
            }
          });
        }
        if (res === "Domestic") {
          value["Domestic Wire"].map((item) => {
            item.name === "Bank Name"
              ? (input.consumerInformation.companyNameWire.value = item.value)
              : item.name === "ABA"
              ? (input.consumerInformation.bankDomasticWire.value = item.value)
              : item.name === "Account #"
              ? (input.consumerInformation.accountNoDomasticWire.value =
                  item.value)
              : item.name === "Line1"
              ? (input.consumerInformation.street_address_primary.value =
                  item.value)
              : item.name === "Line2"
              ? (input.consumerInformation.street_address_secondary.value =
                  item.value)
              : item.name === "City"
              ? (input.consumerInformation.city2.value = item.value)
              : item.name === "State"
              ? (input.consumerInformation.state2.value = item.value)
              : item.name === "Zip" &&
                (input.consumerInformation.zipcode2.value = item.value);
            if (item.value !== "" && item.value !== null) {
              this.setState((state) => ({
                ...state,
                toggleDomasticWire: true,
                isDisableDomastic: false,
              }));
            }
          });
        }
      }
      this.setState((state) => ({ ...state, input }));
    });
  };
  validateForm = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let inputs = { ...this.state.input.consumerInformation };

    // Check inputs for errors
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "zipcode" && this.state.toggleACH) {
        if (
          value.value === "" ||
          value.value === null ||
          value.value.trim().length !== 5
        ) {
          errorsArePresent = true;
        }
      } else if (key === "zipcode2" && this.state.toggleDomasticWire) {
        if (
          value.value === "" ||
          value.value === null ||
          value.value.trim().length !== 5
        ) {
          errorsArePresent = true;
        }
      } else if (key === "bankABA" && this.state.toggleACH) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "accountNoACH" && this.state.toggleACH) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "bankDomasticWire" && this.state.toggleDomasticWire) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (
        key === "accountNoDomasticWire" &&
        this.state.toggleDomasticWire
      ) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "companyName" && this.state.toggleACH) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "companyNameWire" && this.state.toggleDomasticWire) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (
        key === "street_address_primary" &&
        this.state.toggleDomasticWire
      ) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "street_address_ACH" && this.state.toggleACH) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "city" && this.state.toggleACH) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "city2" && this.state.toggleDomasticWire) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "state" && this.state.toggleACH) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      } else if (key === "state2" && this.state.toggleDomasticWire) {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
        }
      }
    });
    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = (formType) => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.consumerInformation };

    // Set error state on necessary fields
    Object.entries(newInputState).forEach(([key, value]) => {
      if (this.state.toggleACH || this.state.toggleDomasticWire) {
        if (key === "zipcode") {
          if (
            value.value === "" ||
            value.value === null ||
            value.value.trim().length !== 5
          ) {
            newInputState[key].hasError = true;
          }
        } else if (key === "zipcode2") {
          if (
            value.value === "" ||
            value.value === null ||
            value.value.trim().length !== 5
          ) {
            newInputState[key].hasError = true;
          }
        } else if (key === "bankABA") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "accountNoACH") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "bankDomasticWire") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "accountNoDomasticWire") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "companyName") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "companyNameWire") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "street_address_primary") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "street_address_ACH") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "city") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "city2") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "state") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        } else if (key === "state2") {
          if (value.value === "" || value.value === null) {
            newInputState[key].hasError = true;
          }
        }

        if (newInputState[key].hasError) {
          errorsArePresent = true;
        }
      }
    });

    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerInformation: newInputState,
      },
    }));
  };
  renderErrorMessage(input) {
    let message = "";
    if (this.state.toggleACH || this.state.toggleDomasticWire) {
      if (input === "zipcode") {
        message = "Please enter a zip code with 5 digits";
      } else if (input === "zipcode2") {
        message = "Please enter a zip code with 5 digits";
      } else if (input === "bankABA") {
        message = "Please enter Bank ABA (Routing number)";
      } else if (input === "accountNoACH") {
        message = "Please enter Bank Account Number";
      } else if (input === "bankDomasticWire") {
        message = "Please enter Bank ABA (Routing number)";
      } else if (input === "accountNoDomasticWire") {
        message = "Please enter Bank Account Number";
      } else if (input === "companyName") {
        message = "Please enter a Bank name";
      } else if (input === "companyNameWire") {
        message = "Please enter a Bank name";
      } else if (input === "street_address_primary") {
        message = "Please enter the address";
      } else if (input === "street_address_ACH") {
        message = "Please enter the address";
      } else if (input === "city") {
        message = "Please enter the city";
      } else if (input === "city2") {
        message = "Please enter the city";
      } else if (input === "state") {
        message = "Please enter the state";
      } else if (input === "state2") {
        message = "Please enter the state";
      } else {
        message = "Please complete this field";
      }
    }

    return <div className='input-error-message'>{message}</div>;
  }
  handleCheckboxInputChange = (event, res) => {
    if (res === "No") {
      this.setState((state) => ({
        ...state,
        bankingInfo: false,
        communication: true,
        toggleACH: false,
        toggleDomasticWire: false,
        isDisable: true,
        isDisableDomastic: true,
      }));
      this.removeBankingInfo();
    }
    if (res === "yes") {
      this.setState((state) => ({
        ...state,
        bankingInfo: true,
        communication: false,
        isDisable: false,
        isDisableDomastic: false,
        toggleACH: true,
        toggleDomasticWire: true,
      }));
    }
  };

  render() {
    let {
      input,
      error,
      isLoading,
      bankingInfo,
      toggleDomasticWire,
      toggleACH,
      isDisable,
      isDisableDomastic,
    } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    return isLoading ? (
      <div className='loader_alignment'>
        <div style={{ marginTop: "20%" }}>
          <AppCanvasLoader />
        </div>
      </div>
    ) : (
      <div
        className='tp-sps app-sidebar-layout-canvas-content  col1-scroll mg-col1-scroll scrollbar'
        style={{ paddingTop: "30px", paddingBottom: "60px" }}
      >
        <div
          // className="app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-ml-0 mg-wt-100"
          style={{
            // height: "auto",
            // width: "90%",
            marginLeft: "2rem",
            marginRight: "2rem",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <h3
              className=' mg-head-bold '
              style={{ color: "#000000", fontSize: "30px", width: "75%" }}
            >
              Add Banking Information
            </h3>
            <div style={{ width: "25%" }} className={styles.btnBox}>
              <SecondaryButton
                isBackButton={true}
                text={"Back"}
                title={`Go Back To \n Add Billing Details`}
                onClick={() => this.props.handleback("step1")}
              />
              <PrimaryButton
                isNextButton={true}
                text={"Next"}
                title={`Continue To \n Select Your Payment Portal`}
                onClick={this.handleSubmit}
              />
            </div>
          </div>
          <h7 style={{ lineHeight: "20px" }}>
            Paying customers appreciate flexible payment options that match
            their desired method of payment and displaying your organization's
            banking information is another way to reduce friction points in the
            payment process. If your organization is setup to receive ACH and /
            or wire payments then enter the banking information below and you
            will be able to display bank information for receiving ACH and / or
            wire payments on your invoice communications and your payment
            portal.
          </h7>

          {/* </div> */}
          <div
            style={{ display: "flex", alignItems: "center" }}
            className='mg-ffw'
          >
            <span>
              <h4
                style={{
                  fontSize: "1.5em",
                  marginTop: "10px",
                  fontWeight: "bold",
                  fontFamily: "Open Sans,sans-serif",
                }}
              >
                Include your banking information with your invoice
                communications?{" "}
              </h4>
            </span>
            <input
              type='radio'
              id='add_bank_info_radio_yes'
              name='Yes'
              style={{
                WebkitAppearance: `radio`,
                width: `20px`,
                marginRight: `10px`,
                marginLeft: `40px`,
              }}
              checked={bankingInfo}
              onClick={(e) => this.handleCheckboxInputChange(e, "yes")}
            />
            <span style={{ fontSize: "1.3em", fontWeight: "bold" }}>Yes</span>
            <input
              type='radio'
              id='add_bank_info_radio_no'
              name='No'
              style={{
                WebkitAppearance: `radio`,
                width: `20px`,
                marginRight: `10px`,
                marginLeft: `30px`,
              }}
              checked={!bankingInfo}
              onClick={(e) => this.handleCheckboxInputChange(e, "No")}
            />
            <span style={{ fontSize: "1.3em", fontWeight: "bold" }}>No</span>
          </div>

          <div style={{ display: "flex", paddingTop: "10px" }}>
            <div
              optionalClasses='add-account-modal scroll'
              closeOnClickOutside={true}
              hideModal={this.props.hideModal}
              style={{ width: "50%", height: "50%" }}
            >
              <header
                className='modal-slide-header'
                style={{ textAlign: "left", marginBottom: "0.5em" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className={`modal-slide-header-tab`}
                    style={{
                      margin: 0,
                      color: bankingInfo && toggleACH ? "black" : "",
                      fontWeight: "bold",
                      fontFamily: "unset",
                    }}
                  >
                    ACH Payments
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <ToggleSwitch
                      id='add_bank_info_ach_payment_toggle'
                      isOn={this.state.toggleACH}
                      isDisable={this.state.communication}
                      toggleButton={(event) => {
                        this.toggleButtons(event, "ACH Payments");
                      }}
                    />
                  </div>
                </div>
              </header>
              <form className='admin-form'>
                <fieldset disabled={this.state.toggleACH ? false : true}>
                  <div className={`modal-slide`}>
                    <div className='form-container'>
                      <div className='form-column'>
                        <div
                          className={`input-container ${
                            this.state.toggleACH &&
                            input.consumerInformation.companyName.hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor='name'
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          ></label>
                          <input
                            name='name'
                            type='text'
                            id='add_bank_info_ach_bank_name'
                            placeholder='Bank Name'
                            value={input.consumerInformation.companyName.value}
                            style={{
                              fontFamily: "unset",
                              color: isDisable ? "#9B9B9B" : "",
                            }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                companyName: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.companyName.hasError &&
                          this.state.toggleACH
                            ? this.renderErrorMessage("companyName")
                            : null}
                        </div>
                        <div
                          className={`input-container ${
                            this.state.toggleACH &&
                            input.consumerInformation.street_address_ACH
                              .hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor='streetAddress'
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          ></label>
                          <input
                            name='streetAddress'
                            type='text'
                            placeholder='Bank Address Line 1'
                            id='add_bank_info_ach_address_line_1'
                            value={
                              input.consumerInformation.street_address_ACH.value
                            }
                            style={{
                              fontFamily: "unset",
                              color: isDisable ? "#9B9B9B" : "",
                            }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                street_address_ACH: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.street_address_ACH
                            .hasError && this.state.toggleACH
                            ? this.renderErrorMessage("street_address_ACH")
                            : null}
                        </div>
                        <div
                          style={{ display: "flex", marginBottom: "0px" }}
                          className={`input-container mg-dir-col`}
                        >
                          <div
                            className={`input-container ${
                              this.state.toggleACH &&
                              input.consumerInformation.city.hasError
                                ? "error"
                                : ""
                            }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='city'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            ></label>
                            <input
                              name='city'
                              type='text'
                              id='add_bank_info_ach_city_name'
                              placeholder='City'
                              value={input.consumerInformation.city.value}
                              style={{
                                fontFamily: "unset",
                                color: isDisable ? "#9B9B9B" : "",
                              }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  city: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.city.hasError &&
                            this.state.toggleACH
                              ? this.renderErrorMessage("city")
                              : null}
                          </div>
                          <div
                            className={`input-container ${
                              this.state.toggleACH &&
                              input.consumerInformation.state.hasError
                                ? "error"
                                : ""
                            }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='state'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            ></label>
                            <Select
                              inputProps={{
                                autoComplete: "none",
                                autoCorrect: "off",
                                spellCheck: "off",
                              }}
                              className='form-select'
                              id='add_bank_info_ach_state'
                              name='state'
                              value={input.consumerInformation.state.value}
                              placeholder='State'
                              options={stateDataShortNames}
                              resetValue=''
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  state: {
                                    value: event ? event.value : "",
                                    hasError: false,
                                  },
                                })
                              }
                              style={{
                                fontFamily: "unset",
                                bottom: "0.8px",
                                color: isDisable ? "#9B9B9B" : "",
                              }}
                            />
                            {input.consumerInformation.state.hasError &&
                            this.state.toggleACH
                              ? this.renderErrorMessage("state")
                              : null}
                          </div>
                          <div
                            className={`input-container ${
                              this.state.toggleACH &&
                              input.consumerInformation.zipcode.hasError
                                ? "error"
                                : ""
                            }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='zipcode'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            ></label>
                            <MaskedTextInput
                              guide={true}
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                              name='zipcode'
                              id='add_bank_info_ach_zipcode'
                              placeholder='Zip'
                              placeholderChar={"\u2000"}
                              type='text'
                              value={input.consumerInformation.zipcode.value}
                              style={{ color: isDisable ? "#9B9B9B" : "" }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  zipcode: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.zipcode.hasError &&
                            this.state.toggleACH
                              ? this.renderErrorMessage("zipcode")
                              : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`input-container ${
                          this.state.toggleACH &&
                          input.consumerInformation.bankABA.hasError
                            ? "error"
                            : ""
                        }`}
                        style={{ height: "70px" }}
                      >
                        <label
                          htmlFor='name'
                          style={{ fontWeight: "bold", fontFamily: "unset" }}
                        ></label>
                        <input
                          name='ABA'
                          type='text'
                          placeholder='Bank ABA (Routing number)'
                          id='add_bank_info_ach_routing_number'
                          value={input.consumerInformation.bankABA.value}
                          style={{
                            fontFamily: "unset",
                            color: isDisable ? "#9B9B9B" : "",
                          }}
                          onChange={(event) =>
                            this.handleConsumerInfoInputChange({
                              bankABA: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.consumerInformation.bankABA.hasError &&
                        this.state.toggleACH
                          ? this.renderErrorMessage("bankABA")
                          : null}
                      </div>
                    </div>
                    <div
                      className={`input-container ${
                        this.state.toggleACH &&
                        input.consumerInformation.accountNoACH.hasError
                          ? "error"
                          : ""
                      }`}
                      style={{ height: "70px" }}
                    >
                      <label
                        htmlFor='name'
                        style={{ fontWeight: "bold", fontFamily: "unset" }}
                      ></label>
                      <input
                        name='Account No'
                        type='text'
                        placeholder='Bank Account number'
                        id='add_bank_info_ach_account_number'
                        value={input.consumerInformation.accountNoACH.value}
                        style={{
                          fontFamily: "unset",
                          color: isDisable ? "#9B9B9B" : "",
                        }}
                        onChange={(event) =>
                          this.handleConsumerInfoInputChange({
                            accountNoACH: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.consumerInformation.accountNoACH.hasError &&
                      this.state.toggleACH
                        ? this.renderErrorMessage("accountNoACH")
                        : null}
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div style={{ width: "50px" }}></div>
            <div
              optionalClasses='add-account-modal scroll'
              closeOnClickOutside={true}
              hideModal={this.props.hideModal}
              style={{ width: "50%", height: "50%" }}
            >
              <form className='admin-form'>
                <div className={`modal-slide`}>
                  <div className='form-container'>
                    <div className='form-column'>
                      <header
                        className='modal-slide-header'
                        style={{ textAlign: "left", marginBottom: "0.5em" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            className={`modal-slide-header-tab`}
                            style={{
                              margin: 0,
                              color:
                                bankingInfo && toggleDomasticWire
                                  ? "black"
                                  : "",
                              fontWeight: "bold",
                              fontFamily: "unset",
                            }}
                          >
                            Domestic Wire
                          </div>
                          <div style={{ marginLeft: "20px" }}>
                            <ToggleSwitch
                              isOn={this.state.toggleDomasticWire}
                              id='add_bbanking_info_domestic_wire'
                              isDisable={this.state.communication}
                              toggleButton={(event) =>
                                this.toggleButtons(event, "Domastic Wire")
                              }
                            />
                          </div>
                        </div>
                      </header>
                      <fieldset
                        disabled={this.state.toggleDomasticWire ? false : true}
                      >
                        <div
                          className={`input-container ${
                            this.state.toggleDomasticWire &&
                            input.consumerInformation.companyNameWire.hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor='name'
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          ></label>
                          <input
                            name='name'
                            type='text'
                            id='add_bbanking_info_bank_name'
                            placeholder='Bank Name'
                            value={
                              input.consumerInformation.companyNameWire.value
                            }
                            style={{
                              fontFamily: "unset",
                              color: isDisableDomastic ? "#9B9B9B" : "",
                            }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                companyNameWire: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.companyNameWire.hasError &&
                          this.state.toggleDomasticWire
                            ? this.renderErrorMessage("companyNameWire")
                            : null}
                        </div>
                        <div
                          className={`input-container ${
                            this.state.toggleDomasticWire &&
                            input.consumerInformation.street_address_primary
                              .hasError
                              ? "error"
                              : ""
                          }`}
                          style={{ height: "70px" }}
                        >
                          <label
                            htmlFor='streetAddress'
                            style={{ fontWeight: "bold", fontFamily: "unset" }}
                          ></label>
                          <input
                            name='streetAddress'
                            id='add_bbanking_info_bank_address_line_1'
                            type='text'
                            placeholder='Bank Address Line 1'
                            value={
                              input.consumerInformation.street_address_primary
                                .value
                            }
                            style={{
                              fontFamily: "unset",
                              color: isDisableDomastic ? "#9B9B9B" : "",
                            }}
                            onChange={(event) =>
                              this.handleConsumerInfoInputChange({
                                street_address_primary: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              })
                            }
                          />
                          {input.consumerInformation.street_address_primary
                            .hasError && this.state.toggleDomasticWire
                            ? this.renderErrorMessage("street_address_primary")
                            : null}
                        </div>
                        <div
                          className='input-container mg-dir-col'
                          style={{ display: "flex", marginBottom: "0px" }}
                        >
                          <div
                            className={`input-container ${
                              this.state.toggleDomasticWire &&
                              input.consumerInformation.city2.hasError
                                ? "error"
                                : ""
                            }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='city'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            ></label>
                            <input
                              name='city'
                              type='text'
                              id='add_bbanking_info_bank_city_name'
                              placeholder='City'
                              value={input.consumerInformation.city2.value}
                              style={{
                                fontFamily: "unset",
                                color: isDisableDomastic ? "#9B9B9B" : "",
                              }}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  city2: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.city2.hasError &&
                            this.state.toggleDomasticWire
                              ? this.renderErrorMessage("city2")
                              : null}
                          </div>
                          <div
                            className={`input-container ${
                              this.state.toggleDomasticWire &&
                              input.consumerInformation.state2.hasError
                                ? "error"
                                : ""
                            }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='state'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            ></label>
                            <Select
                              inputProps={{
                                autoComplete: "none",
                                autoCorrect: "off",
                                spellCheck: "off",
                              }}
                              className='form-select'
                              id='add_bbanking_info_bank_state'
                              name='state'
                              value={input.consumerInformation.state2.value}
                              placeholder='State'
                              options={stateDataShortNames}
                              resetValue=''
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  state2: {
                                    value: event ? event.value : "",
                                    hasError: false,
                                  },
                                })
                              }
                              style={{
                                fontFamily: "unset",
                                bottom: "0.8px",
                                color: isDisableDomastic ? "#9B9B9B" : "",
                              }}
                            />
                            {input.consumerInformation.state2.hasError &&
                            this.state.toggleDomasticWire
                              ? this.renderErrorMessage("state2")
                              : null}
                          </div>
                          <div
                            className={`input-container ${
                              this.state.toggleDomasticWire &&
                              input.consumerInformation.zipcode2.hasError
                                ? "error"
                                : ""
                            }`}
                            style={{ height: "70px" }}
                          >
                            <label
                              htmlFor='zipcode'
                              style={{
                                fontWeight: "bold",
                                fontFamily: "unset",
                              }}
                            ></label>
                            <MaskedTextInput
                              guide={true}
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                              name='zipcode'
                              placeholder='Zip'
                              id='add_bbanking_info_bank_zip_code'
                              placeholderChar={"\u2000"}
                              type='text'
                              style={{
                                color: isDisableDomastic ? "#9B9B9B" : "",
                              }}
                              value={input.consumerInformation.zipcode2.value}
                              onChange={(event) =>
                                this.handleConsumerInfoInputChange({
                                  zipcode2: {
                                    value: event.target.value,
                                    hasError: false,
                                  },
                                })
                              }
                            />
                            {input.consumerInformation.zipcode2.hasError &&
                            this.state.toggleDomasticWire
                              ? this.renderErrorMessage("zipcode2")
                              : null}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div className={`modal-slide`}>
                  <div className='form-container'>
                    <fieldset
                      disabled={this.state.toggleDomasticWire ? false : true}
                    >
                      <div
                        className={`input-container ${
                          this.state.toggleDomasticWire &&
                          input.consumerInformation.bankDomasticWire.hasError
                            ? "error"
                            : ""
                        }`}
                        style={{ height: "70px" }}
                      >
                        <label
                          htmlFor='ABA'
                          style={{ fontWeight: "bold", fontFamily: "unset" }}
                        ></label>
                        <input
                          name='ABA'
                          type='text'
                          id='add_bbanking_info_routing_number'
                          placeholder='Bank ABA (Routing number)'
                          value={
                            input.consumerInformation.bankDomasticWire.value
                          }
                          style={{
                            fontFamily: "unset",
                            color: isDisableDomastic ? "#9B9B9B" : "",
                          }}
                          onChange={(event) =>
                            this.handleConsumerInfoInputChange({
                              bankDomasticWire: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.consumerInformation.bankDomasticWire.hasError &&
                        this.state.toggleDomasticWire
                          ? this.renderErrorMessage("bankDomasticWire")
                          : null}
                      </div>
                      <div
                        className={`input-container ${
                          this.state.toggleDomasticWire &&
                          input.consumerInformation.accountNoDomasticWire
                            .hasError
                            ? "error"
                            : ""
                        }`}
                        style={{ height: "70px" }}
                      >
                        <label
                          htmlFor='Account'
                          style={{ fontWeight: "bold", fontFamily: "unset" }}
                        ></label>
                        <input
                          name='Account'
                          id='add_bbanking_info_bank_account_number'
                          type='text'
                          placeholder='Bank Account number'
                          value={
                            input.consumerInformation.accountNoDomasticWire
                              .value
                          }
                          style={{
                            fontFamily: "unset",
                            color: isDisableDomastic ? "#9B9B9B" : "",
                          }}
                          onChange={(event) =>
                            this.handleConsumerInfoInputChange({
                              accountNoDomasticWire: {
                                value: event.target.value,
                                hasError: false,
                              },
                            })
                          }
                        />
                        {input.consumerInformation.accountNoDomasticWire
                          .hasError && this.state.toggleDomasticWire
                          ? this.renderErrorMessage("accountNoDomasticWire")
                          : null}
                      </div>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className='bottum-btns' style={{ paddingTop: "15px" }}>
            <div className='button-left'>
              <input
                type='submit'
                id='add_bbanking_info_go_back'
                value='Go Back'
                style={{
                  borderRadius: "0.5em",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  border: "2px solid",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "left",
                }}
                onClick={() => this.props.handleback("step1")}
              />
              <span className='go-back-text'>
                <p style={this.style.pText}>Go back to</p>
                <p style={this.style.pText}>"Add Billing Details"</p>
              </span>
            </div>
            <div className='button-right'>
              <input
                type='submit'
                value={"Next"}
                id='add_bbanking_info_next'
                className='cta mg-brand2-color mg-nextbtn'
                style={{
                  width: "200px",
                  height: "50px",
                  borderRadius: "0.5em",
                  marginTop: "20px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "right",
                  // left: "110px",
                }}
                onClick={this.handleSubmit}
              />
              <span className='next-text'>
                <p style={this.style.pText}>Continue to</p>
                <p style={this.style.pText}>"Select Your Payment Portal"</p>
              </span>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withRouter(CustomizeWorkFlowStep2);
