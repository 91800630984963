import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Components
import Logo from '../Logo';
import SocialLinks from '../SocialLinks';

class MarketingFooter extends Component {

  render() {
    return (
      <footer>
        <div className="wrap">
          {/* <Logo
            linkPath="/"
            linkTitle="appName"
            optionalClass=""
            footerLogo={true}
          /> */}
          <div style={{marginTop: 32}}>
          <p>(844) DBT-HAPN</p>
          </div>
          <nav>
            <Link
              title="Contact"
              to="/contact"
            >
              Contact
            </Link>
            <Link
              title="Privacy"
              to="/privacy"
            >
              Privacy
            </Link>
            <Link
              title="Terms"
              to="/terms"
            >
              Terms
            </Link>
            <a href="https://medium.com/@DebHelps" title="Blog" target="_blank" rel="noopener noreferrer">
              Blog
            </a>
          </nav>
          <div className="social-callout">
            <h4>Follow Us</h4>
            <SocialLinks />
          </div>
        </div>
        <div className="wrap">
          <p className="fine-print">
          Copyright © 2018 {this.props.appName}, LLC - All Rights Reserved.            
            <br />
            {this.props.companyAddress}
          </p>
        </div>
      </footer>
    );
  }
}

export default MarketingFooter;
