import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StepIconListItem extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }

  render() {
    let { icon, number, heading, body } = this.props;

    return (
      <li className="step-icon-list-item">
        <div className="step-icon-list-item-header">
          <div className="header-number">
            { number }
          </div>
          <div className="header-icon" style={{backgroundImage: `url(${icon})`}}>
          </div>
        </div>
        <h4>
          { heading }
        </h4>
        <div className="list-item-body">
          <span>
            { body }
          </span>
        </div>
      </li>
    );
  }
}

export default StepIconListItem;
