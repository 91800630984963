import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import downloadImg from "../../././../assets/icons/info_teal.png";
import DropdownOverflowMenu from "../DropdownOverflowMenu";
import MenuItem from "../../MenuItem";
import { Grid } from "@material-ui/core";
import styles from "../../CustomTable/DynamicTable.module.scss";
class InviteNewTeamObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleExpanderClick = (rowData) => {
    this.props.rowActionClick(rowData);
  };

  showOnlyHeader = (header) => {
    return (
      header === "Paid Amount" ||
      header === "Discount" ||
      header === "Auto Scheduled"
    );
  };

  renderRowExpander = (rowData, col) => {
    let key;
    rowData.secondaryColumns.map((items) => {
      if (items.key == "Role") {
        key = items;
      }
    });
    let { fromConsumer } = this.props;
    let type = col.icon;
    if (type && type === "up-down") {
      return (
        <a
          key={shortid.generate()}
          className={`client-caret expander-caret${
            col.isExpanded ? " down-caret" : ""
          }${fromConsumer ? " consumer-brand" : ""}`}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        ></a>
      );
    } else if (type && type === "eye") {
      return (
        <a
          key={shortid.generate()}
          className={`object-row-expander`}
          style={{ marginLeft: -16, marginTop: 5 }}
          onClick={(event) => this.handleExpanderClick(event, rowData)}
        >
          <svg className='icon object-row-view'>
            <use xlinkHref='#view' />
          </svg>
        </a>
      );
    } else if (rowData.rowType === "overflowMenu") {
      return (
        <div style={{ display: "flex" }}>
          <DropdownOverflowMenu rowData={rowData} forTeam={true}>
            {rowData.rowActions.map((rowAction) => {
              return rowData.rowData.openid_id != null ? null : (
                <MenuItem
                  key={shortid.generate()}
                  dataBehavior={rowAction.dataBehavior}
                  dataTarget={rowAction.dataTarget}
                  optionalClasses={rowAction.optionalClasses}
                  handleClick={(event) =>
                    this.props.rowActionClick(rowAction, rowData.rowData.id)
                  }
                >
                  {rowAction.title}
                </MenuItem>
              );
            })}
          </DropdownOverflowMenu>
          {rowData.rowData.openid_id != null ? this.infotitle("Tooltip") : null}
        </div>
      );
    } else
      return (
        <>
          <a
            // className="expander-caret-hover"
            key={shortid.generate()}
            className={`client-caret expander-caret`}
            onClick={(event) => this.handleExpanderClick(event, rowData)}
          ></a>
        </>
      );
  };

  renderPrimaryColumn = (primaryColumn) => {
    let primaryContent = (
      <div className='object-row-header-name primary-column-title'>
        {primaryColumn.title}
      </div>
    );

    let secondaryContent = primaryColumn.secondaryInfo ? (
      <div className='object-row-header-secondary-info primary-column-info'>
        {primaryColumn.secondaryInfo}
      </div>
    ) : null;

    return (
      <div>
        {primaryContent}
        {secondaryContent}
      </div>
    );
  };

  showSortContent = (header) => {
    const { sortHeaders } = this.props;
    if (header === sortHeaders[0]) {
      return (
        <div
          className='caret-header-title'
          onClick={() => this.props.sortInvoiceData(header, !sortHeaders[1])}
        >
          <div className={styles.rowData}>{header}</div>
          <div
            id='table-header-caret'
            className={`client-caret${
              sortHeaders[1] ? " client-caret-up" : ""
            }`}
            style={{ marginLeft: "0.4em" }}
          />
        </div>
      );
    } else {
      return (
        <div
          className='caret-header-title'
          onClick={() => this.props.sortInvoiceData(header, true)}
        >
          <div className={styles.rowData}>{header}</div>
          {header !== "Action" && (
            <div
              id='table-header-caret'
              className={`client-caret`}
              style={{ marginLeft: "0.4em" }}
            />
          )}
        </div>
      );
    }
  };
  infotitle = (header) => {
    let title = null;
    header === "Role"
      ? (title =
          "Tip: Standard Users have access to everything but your company’s configuration settings. Admins have access to everything.")
      : header === "Tooltip"
      ? (title =
          "The account designated with Owner role cannot be edited except for enabling as an “Internal Contact”. Contact support@makegoodapp.com if you need assistance. ")
      : (title =
          "Tip: Internal Contacts will receive communications sent to All Accounts or Selected Accounts.");
    return (
      <>
        {header === "Tooltip" ? null : (
          <span className={styles.headingStyle}>{header}</span>
        )}
        <span
          title={title}
          style={{
            marginLeft: "5px",
            position: header === "Tooltip" ? "" : null,
            right: header === "Tooltip" ? "0px" : null,
          }}
        >
          <img src={downloadImg} width='25' height='25' />
        </span>
      </>
    );
  };

  render() {
    let {
      data,
      isStatus,
      isBeforeIcon,
      hideForMobile,
      optionalClasses,
      optionalTableClasses,
      scrollRef,
      sortHeaders,
      isPrimaryColumn,
      utilities,
      searchedValue,
    } = this.props;
    let status_style = isStatus ? " header-status-space" : "";
    let primary_col_style = isPrimaryColumn ? " primary-column" : "";
    return (
      <div ref={scrollRef} className={"scrollbar in-sc"}>
        <Table striped hover className={`mobile-hide-list`}>
          {/* <thead style={{ marginBottom: "30px", marginTop: "20px" }}>
            <tr className="list-header">
              <Grid container justifyContent="space-between">
                <Grid item>
                  <h1
                    className={`app-section-header-heading app-section-header-mobile`}
                    style={{
                      width: '300px',
                      marginLeft: '-13px',
                      fontSize: "30px",
                      fontWeight: "bold",
                      width:"auto",
                    }}
                  >
                    {"Team Management"}
                  </h1>
                </Grid>
                <Grid style={{ marginRight: "-10px" }} item>
                  {utilities}
                </Grid>
              </Grid>
            </tr>
          </thead> */}
          {data.columns.length !== 0 && (
            <thead
              style={{ backgroundColor: "#DADADA" }}
              className={styles.headerFix}
            >
              <tr className={`${styles.headingStyle} `}>
                {isBeforeIcon && (
                  <th style={{ flex: 0, marginRight: "20px" }} />
                )}
                {data.headings &&
                  data.headings.map((header, index) => {
                    return (
                      <th
                        key={shortid.generate()}
                        className={
                          header === "expander"
                            ? " row-expander"
                            : styles.headingStyle
                        }
                        style={{ borderTop: "none" }}
                      >
                        {header === "expander"
                          ? ""
                          : sortHeaders
                          ? header === "expander"
                            ? null
                            : this.showOnlyHeader(header)
                            ? header
                            : this.showSortContent(header)
                          : header == "Role" || header == "Internal Contact"
                          ? this.infotitle(header)
                          : header}
                      </th>
                    );
                  })}
              </tr>
            </thead>
          )}
          {data.columns.length !== 0 ? (
            <tbody
              ref={scrollRef}
              // className={`${optionalClasses ? optionalClasses : ""}`}
            >
              {data.columns.length !== 0 &&
                data.columns.map((item, index) => {
                  return (
                    <tr key={shortid.generate()}>
                      {isStatus ? (
                        <td
                          style={{ fontSize: "16px" }}
                          className={`status-point${
                            item.taskStatus
                              ? "col3"
                              : item.status
                              ? "col1"
                              : "col2"
                          }`}
                        />
                      ) : isBeforeIcon ? (
                        item.icon
                      ) : (
                        ""
                      )}
                      {item.primaryColumn && (
                        <td
                          style={{ fontSize: "16px" }}
                          key={shortid.generate()}
                          className={styles.rowData}
                        >
                          {this.renderPrimaryColumn(item.primaryColumn)}
                        </td>
                      )}
                      {item.secondaryColumns &&
                        item.secondaryColumns.map((col, index) => {
                          if (col.rowType && col.rowType === "arrow-click") {
                            return this.renderRowExpander(item, col);
                          } else if (
                            col.rowType &&
                            col.rowType === "hide-task"
                          ) {
                            return <div style={{ width: 12 }} />;
                          }
                          return (
                            <td
                              style={{ fontSize: "16px" }}
                              key={shortid.generate()}
                              className={styles.rowData}
                            >
                              {col.title}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          ) : (
            <div className='admin-admins-empty-state'>
              <p>No results found for:</p>
              <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                {searchedValue}
              </h3>
            </div>
          )}
        </Table>
      </div>
    );
  }
}

export default InviteNewTeamObjectList;
