import React, { Component } from "react";

// Components
import Modal from './Modal';
import { toast } from "react-toastify";

class ErrorModal extends Component {
  hideToast = () => {
    this.props.hideModal();
  };

  render() {
    let { message, title } = this.props.optionalProps;

    return (
      <>
        {this.props.optionalProps.modalErr &&
        this.props.optionalProps.modalErr == "modalerr" ? (
          <Modal
            title={"Failed!"}
            hideModal={this.props.hideModal}
            closeOnClickOutside={true}
          >
            <p style={{ textAlign: "center" }}>{message}</p>
            <div className="modal-actions">
              <a className="cta mg-brand2-color" style={{padding:""}} onClick={this.props.hideModal}>
                Okay
              </a>
            </div>
          </Modal>
        ) : (
          <>
            {toast.error(message)}
            {this.hideToast()}
          </>
        )}
      </>
    );
  }
}

export default ErrorModal;
