import currencyFormatter from "currency-formatter";
import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import Panel from "../../../components/App/Panel";
import ACHPlaidLink from "../../../components/Consumer/ACHPlaidLink";
import ConsumerAddAccountForm from "../../../components/Consumer/ConsumerAddAccountForm";
import * as API from "../../../utils/api";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import CardView from "../../../components/CardView/CardView";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";

const styles = {
  tabPanel: {
    display: "flex",
  },
  checkboxDiv: {
    display: "flex",
    margin: "0.5em 0px",
  },
  checkbox: {
    WebkitAppearance: `checkbox`,
    width: `16px`,
    marginRight: `10px`,
  },
  acceptAgr: {
    width: "100%",
    lineHeight: "120%",
    marginTop: 8,
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  italicStyle: {
    width: "100%",
    lineHeight: "120%",
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  secText: {
    lineHeight: "120%",
  },
  primText: {
    fontWeight: 600,
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#000",
    opacity: 0.3,
  },
  error: {
    fontSize: "1em",
    color: "#fb5c82",
    fontFamily: "Open Sans",
    fontWeight: 600,
    marginTop: "0.4em",
  },
};

class MultipleInvoicesMakePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: {
        value: "",
        hasError: false,
      },
      isPlaidVerified: null,
      plaidStatus: "",
      plaidStatusMessage: "",
      paymentMethod: "CC",
      paymentDetails: [],
      invoiceList: {},
      errorMessage: "Please enter payment amount",
      showBankDetails: false,
      isACH: false,
      isCC: false,
      isPaymentPortal: false,
      isCheck: false,
      totalAmount: 0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      companyData: {},
    };
  }

  checkActiveButtonTab = (check) => {
    if (check) {
      return this.props.primary_color;
    } else {
      return "#a1a1a1";
    }
  };
  componentDidMount() {
    this.setAmountValue();
    this.getOrganizationData();
    this.reloadData();
  }

  setAmountValue = () => {
    let { amount } = this.state;
    let { earlyPayerDiscount, isAutoPopulateBalanceDue } = this.props;

    if (isAutoPopulateBalanceDue && earlyPayerDiscount !== 0) {
      amount.value =
        parseFloat(this.props.totalAmount).toFixed(2) -
        earlyPayerDiscount.toFixed(2);
    } else {
      amount.value =
        this.props.totalAmount > 0
          ? parseFloat(this.props.totalAmount).toFixed(2)
          : "";
    }
    if (this.props.invoiceList) {
      this.setState({
        totalAmount: this.props.totalAmount,
        invoiceList: this.props.invoiceList,
        amount,
      });
    }
  };

  async getOrganizationData() {
    await API.getOrganizationData().then((data) => {
      this.setState({
        companyData: data,
      });
    });
  }

  reloadData = () => {
    this.getPlaidStatus();
    this.getConsumerPaymentDetails();
  };
  getPlaidStatus = () => {
    API.getPlaidStatus(this.props.user).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code &&
        data.status_code == 200
      ) {
        if (data.data) {
          this.setState({
            isPlaidVerified: data.data.plaid_verified,
            plaidStatusMessage: data.data.plaid_status_message,
          });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getConsumerPaymentDetails = () => {
    API.getConsumerPaymentDetails(this.props.user).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.getBankStatus(data.data);
        this.setState({ paymentDetails: data.data }, () => {
          let details = this.state.paymentDetails;
          if (
            details &&
            details.payment_preferences &&
            details.payment_preferences.payment_portal
          ) {
            this.setState({ isPaymentPortal: true });
            let flag = true;
            if (details.payment_preferences.ach) {
              this.setState({ isACH: true, paymentMethod: "ACH" });
              flag = false;
            }
            if (details.payment_preferences.credit_card) {
              this.setState({ isCC: true, paymentMethod: "CC" });
              flag = false;
            }
            if (flag) {
              this.setState({ paymentMethod: "", showBankDetails: true });
            }
          }
        });
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
    });
  };

  handleFilterChange = (type) => {
    this.setState({ paymentMethod: type });
  };

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    if (!newPartialInput.amount.value) {
      newPartialInput.amount.hasError = true;
      this.setState((state) => ({
        ...state,
        ...newPartialInput,
        errorMessage: "Please enter payment amount",
      }));
    } else {
      if (this.props.totalAmount > 0) {
        if (
          parseFloat(newPartialInput.amount.value) <=
            parseFloat(this.state.totalAmount) &&
          parseFloat(newPartialInput.amount.value) >= 1.0
        ) {
          newPartialInput.amount.hasError = false;

          this.setState((state) => ({
            ...state,
            ...newPartialInput,
          }));
        } else {
          newPartialInput.amount.hasError = true;
          this.setState((state) => ({
            ...state,
            ...newPartialInput,
            errorMessage:
              "You can not enter amount greater than total selected payment(s) amount",
          }));
        }
      } else if (parseFloat(newPartialInput.amount.value) >= 1.0) {
        this.setState((state) => ({
          ...state,
          ...newPartialInput,
        }));
      }
    }
  }

  acceptPayment = (amount, card, isCheck) => {
    let { invoiceList, history } = this.props;
    let { paymentDetails, totalAmount } = this.state;
    let temp = invoiceList.filter((item) => item.checkForPay);
    let ids = [];
    temp.map((item) => {
      ids.push(item.id);
    });
    let currentDate = new Date();
    let ind = this.getCardId("card");
    let payload = {
      payment: {
        invoice_ids: ids,
        amount: parseFloat(amount),
        payment_type: "CreditCard",
        payment_date: moment.utc(currentDate).format("YYYY-MM-DD"),
        source_id: isCheck ? card : "",
        card_token: !isCheck ? card : "",
        return_url: `${window.location.origin}/dashboard/accounts/invoices/`,
      },
    };
    this.setState({ isSubmit: true, isLoading: true });
    API.payMultipleInvoices(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 302) {
        window.location.href = data.redirect_url;
      } else if (data && data.status_code && data.status_code == 200) {
        this.setState({ amount: { value: "" }, isSubmit: false });
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        history.push("/dashboard/accounts/invoices");
        this.getConsumerPaymentDetails();
      } else {
        this.setState({ isSubmit: false });
        this.props.updateModalState(true, "ERROR", {
          message: data.message ? data.message : data.error,
        });
      }
      this.setState({ isLoading: false });
    });
  };

  getCardId = (type) => {
    let { paymentDetails } = this.state;
    let ind =
      paymentDetails && paymentDetails.payment_sources
        ? paymentDetails.payment_sources.findIndex((i) => i["object"] == type)
        : -1;
    return ind;
  };

  handleDisconnect = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to disconnect your bank?",
      confirmClick: this.callDisconnectBank,
      closeOnClickOutside: true,
    });
  };

  callDisconnectBank = () => {
    let { invoiceList } = this.props;
    let { paymentDetails } = this.state;
    let ind = this.getCardId("bank_account");
    let payload = {
      invoice_id: invoiceList.id,
      card_id: ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };

    this.setState({ isLoading: true });
    API.disconnectBank(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getConsumerPaymentDetails();
        this.reloadData();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
      this.setState({ isLoading: false });
    });
  };

  removeCard = () => {
    this.props.updateModalState(true, "CONFIRM_ALERT", {
      message: "Are you sure you want to remove your card?",
      confirmClick: this.callRemoveCard,
      closeOnClickOutside: true,
    });
  };

  callRemoveCard = () => {
    let { paymentDetails } = this.state;
    let ind = this.getCardId("card");
    let payload = {
      card_id: ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
    };
    this.setState({ isLoading: true });
    API.removeCard(this.props.user, payload).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.getConsumerPaymentDetails();
      } else {
        this.props.updateModalState(true, "ERROR", { message: data.message });
      }
      this.setState({ isLoading: false });
    });
  };

  createOrUpdateStripeAccount = (stripeToken, card, isCheck) => {
    this.setState({ isSubmit: true, isLoading: true });

    if (stripeToken) {
      if (isCheck) {
        API.createOrUpdateStripeCustomerId(this.props.user, stripeToken)
          .then((data) => {
            if (data && data.cardAdded) {
              this.acceptPayment(this.state.amount.value, card, isCheck);
              this.props.updateModalState(true, "SUCCESS", {
                message: data.message,
              });
              this.getConsumerPaymentDetails();
            } else {
              this.setState({ isSubmit: false, isLoading: false });
              this.props.updateModalState(true, "ERROR", {
                message: data.error
                  ? data.error
                  : data.message || "An unknown error occurred",
              });
            }
          })
          .catch((error) => {
            this.props.updateModalState(true, "ERROR", {
              message: "An error occurred while processing your request.",
            });
          });
      } else {
        this.acceptPayment(this.state.amount.value, stripeToken, isCheck);
      }
    }
  };

  getTaxAmount = (invoice) => {
    let amount = 0.0;
    if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
      invoice.tax_and_discount_line.map((item) => {
        if (item.detail_type === "TaxLineDetail") {
          amount = parseFloat(item.amount);
        }
      });
    }
    return amount;
  };

  getSubTotalAmount = (invoiceList) => {
    let subTotal = 0.0;
    invoiceList.map((invoice) => {
      if (invoice.invoice_lines && invoice.invoice_lines.length) {
        invoice.invoice_lines.map((item) => {
          if (
            item.detail_type === "SalesItemLineDetail" &&
            item.item_name &&
            !item.item_name.includes("Early Payer Dis") &&
            !item.item_name.includes("Slow Payer Penalty") &&
            !item.item_name.includes("Invoice Credit")
          ) {
            item.amount = parseFloat(item.amount);
            subTotal = subTotal + item.amount;
          }
        });
      }
    });
    return subTotal && subTotal > 0 ? subTotal.toFixed(2) : "0.00";
  };

  getInvoiceTotalAmount = (invoice) => {
    let amount = 0.0;
    let tax = this.getTaxAmount(invoice);
    let slowPayer = this.getSlowPayerAmount(invoice);
    let subtotal = this.getSubTotalAmount(invoice);
    let invoiceCred = this.getInvoiceCreditAmount(invoice);

    amount =
      parseFloat(subtotal) +
      parseFloat(tax) +
      parseFloat(slowPayer) -
      parseFloat(invoiceCred);
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getPaidAmount = (value) => {
    let amount = "0.00";
    if (value && value !== null && value !== undefined) {
      if (typeof value === "string") {
        amount = parseFloat(value.replace(",", "")).toFixed(2);
      } else if (typeof value === "number") {
        amount = parseFloat(value).toFixed(2);
      }
    }

    return amount;
  };

  getPaidEarlyPayerDiscount = (invoice) => {
    let amount = 0;
    if (invoice.tax_and_discount_line && invoice.tax_and_discount_line.length) {
      invoice.tax_and_discount_line.map((line) => {
        if (line.item_name && line.item_name.includes("Discounts given")) {
          amount = parseFloat(line.amount);
        }
      });
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getSlowPayerAmount = (invoice) => {
    let amount = 0;
    if (invoice.invoice_lines && invoice.invoice_lines.length) {
      invoice.invoice_lines.map((lineItem) => {
        if (lineItem.item_name === "Slow Payer Penalty") {
          amount = amount + parseFloat(lineItem.amount);
        }
      });
    }
    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  getInvoiceCreditAmount = (invoiceList) => {
    let amount = 0;
    invoiceList.map((invoice) => {
      if (invoice.payment_history.payment_type === "Credit Memo") {
        amount = amount + parseFloat(invoice.payment_history.amount);
      }
    });

    return amount && amount > 0 ? amount.toFixed(2) : "0.00";
  };

  validateForm = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.hasError = false;
    } else {
      amount.hasError = true;
    }
    this.setState({ amount });
    return !amount.hasError;
  };

  createPlaidPayment = () => {
    let { history } = this.props;
    if (this.validateForm()) {
      let { paymentDetails, invoiceList } = this.state;
      let temp = invoiceList.filter((item) => item.checkForPay);
      let ids = [];
      temp.map((item) => {
        ids.push(item.id);
      });

      let ind = this.getCardId("bank_account");
      let currentDate = new Date();
      let payload = {
        payment: {
          amount: parseFloat(this.state.amount.value),
          invoice_ids: ids,
          payment_type: "ach_debit",
          payment_date: moment.utc(currentDate).format("YYYY-MM-DD"),
          return_url: `${window.location.origin}/dashboard/accounts/invoices/`,
          source_id:
            ind > -1 ? paymentDetails.payment_sources[ind].source_id : null,
        },
      };
      this.setState({ isLoading: true });
      API.payMultipleInvoices(this.props.user, payload).then((data) => {
        if (data && data.status_code) {
          if (data.status_code == 200) {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
            history.push("/dashboard/accounts/invoices");
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: data.message,
            });
          }
          this.setState({
            isCheck: false,
            isLoading: false,
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };
  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  sendDataBackToServer = (token, metadata) => {
    let payload = {
      public_token: token,
      metadata: metadata,
      payment_amount: this.state.amount.value,
    };
    API.sendPlaidData(this.props.user, payload).then((data) => {
      if (data && data.status_code) {
        if (data.status_code == 200) {
          if (data.message === "Your account verification is pending.") {
            this.props.updateModalState(true, "SUCCESS", {
              title: "Info",
              message: data.message,
            });
          } else {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
          }
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.reloadData();
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  handlePlansSelection = (list) => {
    let { amount } = this.state;
    let { isAutoPopulateBalanceDue, earlyPayerDiscount } = this.props;
    let total = 0.0;
    let earlyPayDisc = 0.0;
    this.setState({ invoiceList: list }, () => {
      list.map((item) => {
        if (item && item.hasOwnProperty("early_payer_discount")) {
          earlyPayDisc = earlyPayDisc + parseFloat(item.early_payer_discount);
        }
        total = total + parseFloat(item.balance);
      });
      if (isAutoPopulateBalanceDue && earlyPayerDiscount !== 0) {
        earlyPayerDiscount = earlyPayDisc;
        amount.value = total - earlyPayDisc;
        amount.hasError = false;
      } else {
        amount.value = total;
        amount.hasError = false;
      }

      this.setState({ amount, totalAmount: total }, () => {
        this.handleInputBlur();
        this.props.updateSideBar(list, total);
      });
    });
  };
  handleEditPayment = () => {
    let { invoiceList } = this.state;
    let props = {
      plan_list: invoiceList,
      primary_color: this.props.primary_color,
      from: "invoice_tab",
      handleSubmit: this.handlePlansSelection,
    };
    this.props.updateModalState(true, "PAY_INVOICES_LIST", props);
  };

  toggleLoading = (flag) => {
    this.setState({ isLoading: flag });
  };

  getInputField = (payments, index) => {
    return (
      <div
        key={"key" + index}
        className={`input-container amount-input merge-inputs`}
        style={{ marginRight: 10 }}
      >
        <tr>
          <td
            style={{
              fontFamily: "Open Sans",
              fontWeight: 600,
              fontSize: 14,
              paddingRight: 10,
            }}
          >
            {payments.name}:
          </td>
          <td>{payments.value}</td>
        </tr>
      </div>
    );
  };

  creditCardView = () => {
    const { stripePubKey, isSubmit, primary_color } = this.props;
    return (
      <StripeProvider apiKey={stripePubKey}>
        <Elements>
          <ConsumerAddAccountForm
            amount={this.state.amount.value}
            italicStyle={styles.acceptAgr}
            checkboxDivStyle={styles.checkboxDiv}
            checkboxStyle={styles.checkbox}
            getConsumerPaymentDetails={this.getConsumerPaymentDetails}
            acceptPayment={this.acceptPayment}
            updatePaymentInfoState={this.updatePaymentInfoState}
            paymentAmount={this.state.amount.value}
            primary_color={primary_color}
            handleFormSubmit={this.createOrUpdateStripeAccount}
            validateAmount={true}
            isSubmit={isSubmit}
          />
        </Elements>
      </StripeProvider>
    );
  };

  getBankStatus = (paymentDetails) => {
    let connected = false;
    if (paymentDetails && paymentDetails.payment_sources) {
      paymentDetails.payment_sources.map((item) => {
        if (item.object && item.object == "bank_account") connected = true;
      });
    }
    if (this.state.isPlaidVerified) {
      if (this.state.plaidStatusMessage === null) {
        this.setState({ plaidStatus: "Instant" });
      } else {
        this.setState({ plaidStatus: "Verified" });
      }
      connected = true;
    } else {
      if (this.state.plaidStatusMessage === "pending_manual_verification") {
        this.setState({ plaidStatus: "Pending" });
      } else {
        this.setState({ plaidStatus: "Failed" });
      }
      connected = false;
    }
    return connected;
  };

  showBankStatus = (paymentDetails) => {
    let style =
      this.state.plaidStatus === "Instant" ||
      this.state.plaidStatus === "Verified"
        ? { color: "#409D57", fontFamily: "Open Sans", fontWeight: 600 }
        : this.state.plaidStatus === "Pending"
        ? { color: "#c2c20e", fontFamily: "Open Sans", fontWeight: 600 }
        : { color: "#fb5c82", fontFamily: "Open Sans", fontWeight: 600 };

    return (
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "0.5em",
        }}
      >
        <div style={{ ...styles.primText, lineHeight: "150%", marginRight: 5 }}>
          Status:
        </div>
        <div style={style}>
          {this.state.plaidStatus === "Instant" ||
          this.state.plaidStatus === "Verified"
            ? "CONNECTED"
            : this.state.plaidStatus === "Pending"
            ? "PENDING"
            : "NOT CONNECTED"}
        </div>
        <br />
      </div>
    );
  };

  MakePayment = () => {
    const { stripePubKey, invoiceList, isSubmit, totalAmount } = this.props;
    const {
      isCC,
      isACH,
      amount,
      paymentMethod,
      isPlaidVerified,
      plaidStatus,
      isCheck,
      isPaymentPortal,
      showBankDetails,
      paymentDetails,
    } = this.state;
    let detailsCheck = isPaymentPortal
      ? showBankDetails
        ? false
        : true
      : false;

    let subTotal = 0.0;
    let taxData = 0.0;
    let invoiceCredit = 0.0;
    let slow_payer_amount = 0.0;
    let amount_paid = 0.0;
    let early_payer_balance = 0.0;
    invoiceList.map((item) => {
      item.total_amt = parseFloat(item.total_amt);
      subTotal = subTotal + item.total_amt;
      slow_payer_amount =
        slow_payer_amount + parseFloat(item.slow_payer_amount);
      taxData = taxData + parseFloat(item.tax_amount);
      item.payment_history.length &&
        item.payment_history.map((i) => {
          if (i.payment_type === "Credit Memo") {
            invoiceCredit = invoiceCredit + parseFloat(i.amount);
          }
          if (
            i.payment_type === "Cash" ||
            i.payment_type === "CreditCard" ||
            i.payment_type === "ach_debit" ||
            i.payment_type === "Check"
          ) {
            amount_paid = amount_paid + parseFloat(i.amount);
          }
        });
    });

    invoiceList.map((list) => {
      let amtPaid = 0.0;
      if (list.hasOwnProperty("early_payer_discount")) {
        list.payment_history.length &&
          list.payment_history.map((i) => {
            if (i.payment_type === "Credit Memo") {
              amtPaid = parseFloat(i.amount);
            }
          });

        early_payer_balance =
          early_payer_balance +
          (parseFloat(list.total_amt) +
            parseFloat(list.tax_amount) -
            parseFloat(amtPaid) -
            parseFloat(list.early_payer_discount));
        amtPaid = 0.0;
      } else {
        early_payer_balance = early_payer_balance + parseFloat(list.balance);
      }
    });

    let isDiscount = invoiceList.filter((item) => item.early_payer_discount);
    const Data = [
      {
        title: "Subtotal",
        value: subTotal > 0 ? subTotal.toFixed(2) : "0.00",
      },
      { title: "Taxes", value: taxData > 0 ? taxData.toFixed(2) : "0.00" },
      {
        title: "Invoice Credit",
        value: invoiceCredit > 0 ? invoiceCredit.toFixed(2) : "0.00",
        isSubtract: true,
      },
      {
        title: "Slow Payer Penalty",
        value: slow_payer_amount > 0 ? slow_payer_amount.toFixed(2) : "0.00",
      },
      {
        title: "Invoice Total",
        value: subTotal > 0 ? subTotal.toFixed(2) : "0.00",
      },
      {
        title: "Amount Paid",
        value: amount_paid > 0 ? `${amount_paid.toFixed(2)}` : "0.00",
        isSubtract: true,
      },
      {
        title: "Balance Due",
        value: totalAmount.toFixed(2),
      },
    ];

    if (isDiscount.length > 0) {
      let epBalance = {
        title: "Early Payer Balance Due",
        value: early_payer_balance ? early_payer_balance.toFixed(2) : "0.00",
      };

      Data.push(epBalance);
    }
    let cardInd =
      paymentDetails &&
      paymentDetails.payment_sources &&
      paymentDetails.payment_sources.length
        ? paymentDetails.payment_sources.findIndex(
            (item) => item["object"] == "card"
          )
        : -1;

    return (
      <div className='make-payment-panel' style={{ marginTop: 0 }}>
        <Panel>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label style={{ fontSize: "18px" }}>Payment Amount</label>
            </div>
            <div>
              <button
                id='submit-consumer-paymant'
                className='cta'
                style={{
                  backgroundColor: `${this.props.primary_color}`,
                  border: "1px solid #a1a1a1",
                }}
                onClick={(e) => this.handleEditPayment(e)}
              >
                Edit Invoice(s)
              </button>
            </div>
          </div>
          <div className='payment-container-mg'>
            <div
              className={`input-container consumer-input-payment  pay-input-value`}
            >
              {amount.value ? <span className='span-doller'>$</span> : null}
              <input
                placeholder='Enter Payment Amount'
                type='number'
                pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                value={amount.value}
                onBlur={this.handleInputBlur}
                onChange={(event) =>
                  this.handleInputChange({
                    amount: {
                      value: event.target.value,
                      hasError: false,
                    },
                  })
                }
              />
            </div>
            <div>
              {" "}
              {amount.hasError && (
                <div style={styles.error}>{this.state.errorMessage}</div>
              )}
            </div>
          </div>
          {Data.map((item, i) => {
            return (
              <div
                key={"panel" + i}
                style={{
                  display: "flex",
                  padding: 10,
                  backgroundColor: i % 2 == 0 ? "#dbe9f2" : "#FFF",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    fontWeight:
                      item.title == "Balance Due" ||
                      item.title == "Early Payer Balance Due"
                        ? 600
                        : "auto",
                  }}
                >
                  {item.title}
                  <div style={{ fontSize: "11px" }}>
                    {item.subTitle ? `(${item.subTitle})` : ""}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight:
                      item.title == "Balance Due" ||
                      item.title == "Early Payer Balance Due"
                        ? 600
                        : "auto",
                  }}
                >
                  {<span>&nbsp;&nbsp;</span>}${item.value ? item.value : "0.00"}
                </div>
              </div>
            );
          })}
        </Panel>
        {detailsCheck ? (
          <Panel>
            <div
              className={`pay-amount-input amount-input`}
              style={{ marginTop: 0 }}
            >
              <label>Select Payment Method</label>
            </div>
            <div style={styles.tabPanel}>
              {isCC ? (
                <div style={{ position: "relative" }}>
                  <button
                    title='All'
                    className={`option-tabs-btns all bt-sp-l${
                      paymentMethod == "CC" ? " active" : ""
                    }`}
                    style={{
                      width: "110px",
                      margin: 0,
                      padding: "1px",
                      color: this.checkActiveButtonTab(paymentMethod == "CC"),
                    }}
                    onClick={() => this.handleFilterChange("CC")}
                  >
                    Credit Card
                  </button>
                  {paymentMethod == "CC" && (
                    <span
                      style={{
                        width: "108px",
                        height: "4px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        bottom: "-2px",
                        left: "2px",
                      }}
                    ></span>
                  )}
                </div>
              ) : null}
              {isACH ? (
                <div style={{ position: "relative" }}>
                  <button
                    title='All'
                    className={`option-tabs-btns all bt-sp-l${
                      paymentMethod == "ACH" ? " active" : ""
                    }`}
                    style={{
                      width: "120px",
                      padding: "1px",
                      margin: 0,
                      color: this.checkActiveButtonTab(paymentMethod == "ACH"),
                    }}
                    onClick={() => this.handleFilterChange("ACH")}
                  >
                    Bank Transfer
                  </button>
                  {paymentMethod == "ACH" && (
                    <span
                      style={{
                        width: "118px",
                        height: "4px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        bottom: "-2px",
                        left: "1px",
                      }}
                    ></span>
                  )}
                </div>
              ) : null}
            </div>
            <div className={`panal-content-switch`}>
              {paymentMethod == "ACH" ? (
                <div>
                  {this.showBankStatus(paymentDetails)}
                  {this.state.plaidStatus === "Instant" ||
                  this.state.plaidStatus === "Verified" ? (
                    <div>
                      <div style={styles.primText}>Your bank is connected:</div>
                      <br />
                      {paymentDetails && paymentDetails.payment_sources
                        ? paymentDetails.payment_sources.map((item) => {
                            if (item.object && item.object == "bank_account") {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        ...styles.primText,
                                        lineHeight: "120%",
                                        fontSize: 14,
                                      }}
                                    >
                                      {item.bank_name}
                                    </div>
                                    <span>xxxxxxx{item.last4}</span>
                                  </div>
                                  <button
                                    onClick={this.handleDisconnect}
                                    className='cta plaid-disconnect-btn'
                                  >
                                    Disconnect
                                  </button>
                                </div>
                              );
                            }
                          })
                        : null}
                    </div>
                  ) : (
                    <div>
                      <div style={styles.primText}>
                        Connect your bank:
                        <br />
                        <br />
                        <span style={styles.secText}>
                          Easily make payments using ACH bank transfers by
                          connecting to your bank using one of the verification
                          methods below
                        </span>
                      </div>
                      <br />
                      <div style={styles.secText}>
                        Connect immediately with Plaid (immediate) (Recommended)
                        (i) Plaid provides the quickest way to collect and
                        verify your bank information and allows you to make
                        payments immediately.
                      </div>
                    </div>
                  )}
                  <br />
                  <div style={styles.checkboxDiv}>
                    <input
                      name='ach'
                      type='checkbox'
                      checked={isCheck}
                      style={styles.checkbox}
                      onChange={(event) =>
                        this.setState({ isCheck: event.target.checked })
                      }
                    />
                    <div style={styles.acceptAgr}>
                      I authorize {this.state.companyData.clientName} to
                      electronically debit my account and, if necessary,
                      electronically credit my account to correct erroneous
                      debits.
                    </div>
                  </div>
                  <br />
                  <ACHPlaidLink
                    primary_color={this.props.primary_color}
                    auth={true}
                    user={this.props.user}
                    amount={amount.value}
                    plaidStatus={isPlaidVerified}
                    plaidLevel={plaidStatus}
                    isCheck={isCheck && amount.value && !amount.hasError}
                    toggleLoading={this.toggleLoading}
                    updateModalState={this.props.updateModalState}
                    sendDataBackToServer={this.sendDataBackToServer}
                    createPlaidPayment={this.createPlaidPayment}
                    disconnectBank={this.callDisconnectBank}
                  />
                </div>
              ) : stripePubKey && paymentMethod == "CC" ? (
                <div>
                  {cardInd > -1 ? (
                    <div>
                      <h4>Credit Card Details</h4>
                      <label style={{ marginBottom: "1em" }}>
                        Card Number: xxxx-xxxx-xxxx-
                        {paymentDetails.payment_sources[cardInd].last4}
                      </label>
                      <a
                        className='text-link'
                        onClick={(e) => this.removeCard(e)}
                      >
                        Remove Card
                      </a>
                      {isSubmit ? (
                        <div style={{ marginTop: "2em" }}>
                          <div className='spinner' />
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <button
                            id='submit-consumer-paymant'
                            className='cta'
                            disabled={
                              amount.value && !amount.hasError ? false : true
                            }
                            style={{
                              backgroundColor: `${this.props.primary_color}`,
                              border: "1px solid #a1a1a1",
                              padding: "7px",
                            }}
                            onClick={(e) => {
                              let cardID =
                                this.state.paymentDetails.payment_sources
                                  .filter((card) => card.object === "card")
                                  .map((card) => card.source_id);
                              return this.acceptPayment(
                                this.state.amount.value,
                                cardID[0],
                                true
                              );
                            }}
                          >
                            Submit Payment
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    this.creditCardView()
                  )}
                </div>
              ) : null}
            </div>
          </Panel>
        ) : (
          <Panel>
            {paymentDetails &&
              paymentDetails.payment_data &&
              Object.entries(paymentDetails.payment_data).map(
                ([key1, rowData], i) => {
                  if (rowData && rowData.length) {
                    return (
                      <div className='bank-details' key={key1}>
                        <label style={{ marginBottom: "0.3em" }}>{key1}</label>
                        {rowData && rowData.length
                          ? rowData.map((item, index) => {
                              return this.getInputField(item, index);
                            })
                          : null}
                      </div>
                    );
                  }
                }
              )}
          </Panel>
        )}
      </div>
    );
  };

  render() {
    const { error, isLoading } = this.state;
    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div>
        {this.MakePayment()}
        {isLoading ? (
          <div style={styles.loader}>
            <AppCanvasLoader />
          </div>
        ) : null}
      </div>
    );
  }
}

export default MultipleInvoicesMakePayment;
