import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Redirect } from "react-router-dom"

// API
import * as API from '../../utils/api'

import { networkMessage, showToast } from '../../utils/helpers'

// Components
import AppCanvasLoader from '../../components/App/AppCanvasLoader'
import AdminAddAdminForm from '../../components/Admin/Forms/AdminAddAdminForm'
import AppSectionHeader from '../../components/App/AppSectionHeader'
import ObjectList from '../../components/ObjectList/ObjectList'
import ConfirmAdminDeletionModal from '../../components/App/Modals/Admin/ConfirmAdminDeletionModal'
import FlashMessage from '../../components/FlashMessage'
import settings from "../../assets/images/header/settings.png";
import BellIcon from "../../assets/images/header/ic_bell.svg";
import manageUser from "../../assets/images/header/manage-user.png";

class AdminAdmins extends Component {
  state = {
    addAdminFormIsVisible: false,
    admins: [],
    isLoading: true,
    input: {
      search: {
        value: ""
      }
    },
    showAdminDeletionModal: false,
    adminToDelete: '',
    showFlash: false,
    error: {
      hasAnyError: false,
      statusCode: 200
    }

  }

  componentDidMount() {
    this.getAdmins(this.props.organization_id)
  }

  reloadAdmins = (organization_id) => {
    this.getAdmins(organization_id);
  }

  getAdmins = (organization_id) => {
    let me=this;
    API.fetchAllAdmins(this.props.user, organization_id).then(data => {
      //if (((typeof data != 'undefined') || (data != null)) && (!data.message)) {
      if ((typeof data != 'undefined') || (data != null)) {
        me.setState({
          admins: data.users,
          defaultAdmins: data.users,
          isLoading: false
        })
      } else {
        me.setState(state => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...newPartialInput
      }
    }),
      () => { this.handleSearch() }
    )
  }

  setAdminData = (dataSet = []) => {
    this.setState(state => ({
      ...state,
      admins: dataSet
    }))
  }

  handleSearch = () => {
    let queryString = this.state.input.search.value
    if (queryString) {
      API.adminSearch(this.props.user, 'admins', queryString).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && (data.results)) {
          this.setAdminData(data.results)
        } else {
          this.setAdminData()
        }
      })
    } else {
      this.setAdminData(this.state.defaultAdmins)
    }
  }

  handleAddAdminClick = () => {
    this.setState(state => ({
      ...state,
      addAdminFormIsVisible: true
    }))
  }

  handleAddAdminFormCancelation = () => {
    this.setState(state => ({
      ...state,
      addAdminFormIsVisible: false
    }))
  }

  handleRowActionClick = (event) => {
    const actionBehavior = event.target.getAttribute('data-behavior')
    const adminId = event.target.getAttribute('data-target')
    switch (actionBehavior) {
      case "admin-delete":
        this.setAdminToDelete(adminId)
        this.toggleAdminDeletionModal()
        break
      case "admin-reset-password":
          this.resetPassword(adminId);
          break
      default:
        alert('Error: Something went wrong')
    }
  }

  setAdminToDelete = (adminId) => {
    let selectedAdmin = this.getAdmin(adminId)

    this.setState(state => ({
      ...state,
      adminToDelete: selectedAdmin
    }))
  }

  resetPassword = (adminId) => {
    API.resetAdminPassword(this.props.user, adminId).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && data.status_code == 200) {
        this.componentDidMount()
        this.setState(state => ({
          ...state,
          showFlash: true,
          flashMessage: `${data.message}`
        }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          }
        }))
      }
    })
  }

  resetAdminPassword = (adminId) => {
    let selectedAdmin = this.getAdmin(adminId)

    this.setState(state => ({
      ...state,
      adminToDelete: selectedAdmin
    }))
  }

  toggleAdminDeletionModal = () => {
    const { showAdminDeletionModal } = this.state

    this.setState(state => ({
      ...state,
      showAdminDeletionModal: !showAdminDeletionModal,
    }))
  }

  handleModalSubmission = (adminId) => {
    this.toggleAdminDeletionModal()
    this.deactivateAdmin(adminId)
  }

  deactivateAdmin = (id) => {
    let admins = [...this.state.admins]
    let targetId = parseInt(id, 10)
    let newAdminsState = admins.filter((admin) => {
      return admin.id !== targetId
    })

    if (newAdminsState.length < admins.length) {
      API.deactivateAdmin(this.props.user, id).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.componentDidMount()
          this.setState(state => ({
            ...state,
            showFlash: true,
            flashMessage: `${data.message}`
          }))
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      })
    }
  }

  getAdmin = (adminId) => {
    let { admins } = this.state

    let selectedAdmin = admins.filter((admin) => {
      return admin.id === parseInt(adminId, 10)
    })

    return selectedAdmin[0]
  }

  dismissFlash = () => {
    this.setState(state => ({
      ...state,
      showFlash: false
    }))
  }

  addAdminUser = (adminUser) => {
    API.createAdmin(this.props.user, adminUser).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && (!data.errors)) {
        this.getAdmins()
        this.setState(state => ({
          ...state,
          showFlash: true,
          flashMessage: `${adminUser.first_name} has been successfully added.`
        }))
      } else {
        this.setState(state => ({
          ...state,
          showFlash: true,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          flashMessage: data.errors[0]
        }))
      }
    })
  }

  DeleteOwner = () => {
    API.DeleteOwner(this.props.user, this.props.organization_id).then(data => {
      if (((typeof data != 'undefined') || (data != null)) && (!data.errors)) {
        this.setState(state => ({
          ...state,
          showFlash: true,
          flashMessage: `Owner Account has been deleted successfully.`
        }))
      } else {
        this.setState(state => ({
          ...state,
          showFlash: true,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          flashMessage: `Something Went Wrong.`
        }))
      }
    })
  }

  getAdminsListData = () => {
    let { admins } = this.state
    return {
      headings: [
        'Name',
        'Role',
        'Password',
        'Status'
      ],
      columns: admins.map((admin) => {
        let statusPillClass = null
        let deleteMenuClass = 'alert'
        if (!admin.active) {
          statusPillClass = 'inactive'
          deleteMenuClass = 'alert remove-inactive-menu'
        }

        if (admin.deactivate_admin_id === this.props.user.user_id) {
          statusPillClass = 'inactive'
        }

        if (admin.deactivate_admin_id !== null) {
          statusPillClass = 'inactive'
        }


        return {
          primaryColumn: {
            title: admin.first_name + ' ' + admin.last_name,
            secondaryInfo: admin.email
          },
          secondaryColumns: [
            {
              title: admin.role
            },
            {
              title: admin.registration_token ? admin.registration_token : "n/a"
            },
            {
              title: admin.active ? admin.deactivate_admin_id ? 'Deletion Pending' : 'Active' : 'Inactive',
              optionalClasses: `status-pill ${statusPillClass ? statusPillClass : ''}`
            }
          ],
          rowType: 'overflowMenu',
          rowActions: [
            {
              title: (admin.deactivate_admin_id != null) ? 'Confirm Delete' : 'Delete Admin',
              optionalClasses: deleteMenuClass,
              dataBehavior: 'admin-delete',
              dataTarget: admin.id,
            },
            {
              title: 'Reset Password',
              optionalClasses: deleteMenuClass,
              dataBehavior: 'admin-reset-password',
              dataTarget: admin.id,
            }
          ]
        }
      })
    }
  }

  render() {
    const { appName, user } = this.props
    const {
      addAdminFormIsVisible,
      showAdminDeletionModal,
      isLoading,
      adminToDelete,
      input, 
      error
    } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    let sectionHeaderUtilities = (
      <div className="app-section-header-utilities">
        <div className="admin-search-input-container">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={input.search.value}
            className="admin-search-input"
            onChange={event => this.handleInputChange(
              { search: { value: event.target.value } }
            )}
          />
          <span className="search-icon"/>
        </div>
        <button
          className={`cta mg-brand2-color ${addAdminFormIsVisible ? ' hide' : ''}`}
          onClick={this.handleAddAdminClick}
        >
          + Add Admin
        </button>
      </div>
    )

    const modal = showAdminDeletionModal ? (
      <ConfirmAdminDeletionModal
        handleOfferActionResponse={this.handleOfferActionResponse}
        user={user}
        admin={adminToDelete}
        submitAndRemoveAdmin={this.handleModalSubmission}
        hideModal={() => this.toggleAdminDeletionModal()}
      />
    ) : null

    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div className="admin-admins">
          <button
          className={`cta mg-brand2-color ${
            addAdminFormIsVisible ? " hide" : ""
          }`}
          onClick={this.DeleteOwner}
        >
          Delete Owner Account
        </button>
          {this.state.showFlash ? (
            <FlashMessage
              message={this.state.flashMessage}
              handleFlashDismiss={this.dismissFlash}
            />
          ) : null}
          <AppSectionHeader
            title="Admins"
            /*utilities={sectionHeaderUtilities}*/
          />
          {addAdminFormIsVisible &&
            <AdminAddAdminForm
              closeForm={() => this.handleAddAdminFormCancelation()}
              handleFormSubmission={(adminUser) => this.addAdminUser(adminUser)}
            />
          }
          {this.state.admins.length > 0 ? (
            <ObjectList
              optionalClasses={"admins"}
              data={this.getAdminsListData()}
              rowActionClick={(event) => this.handleRowActionClick(event)}
            />
          ) : (
              <div className="admin-admins-empty-state">
                <p>
                  No results found for:
                  </p>
                <h3 className="admin-admins-empty-state-heading">
                  {this.state.input.search.value}
                </h3>
              </div>
            )}
          {showAdminDeletionModal ? modal : ''}
          {showAdminDeletionModal ? (
            <div className="curtain" ></div>
          ) : ''}
        </div>
      )
  }
}

export default AdminAdmins
