import moment from "moment";
import React, { Component } from "react";
// import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import deleteIcon from "../../assets/icons/delete.png";
import viewIcon from "../../assets/icons/view.png";
import ObjectDebtList from "../../components/ObjectList/ObjectDebtList";
import ObjectList from "../../components/ObjectList/ObjectList";
// API
import * as API from "../../utils/api";
import {
  getStrippedDollarAmount,
  getStrippedNumberFromString,
  getZohoPagesense,
  showToast,
} from "../../utils/helpers";

class EmployeeUploadValidationDoc extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    consumer: {},
    debts: [],
    debtDetails: [],
    documents: [],
    offers: [],
    isLoading: true,
    selectedOfferForCounter: {},
    showCounterOfferModal: false,
    currentModal: null,
    optionalModalProps: null,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    open_additional_details: false,
  };

  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/ClientsAccountsView");
    let { debtData } = this.props;
    this.setState(
      {
        debtDetails: debtData,
      },
      () => {
        this.getDocumentData();
      }
    );
    document.getElementById("scroll-emp-val-doc").scrollIntoView();
  }

  getDocumentData() {
    const accountId = this.state.debtDetails.id;
    API.getDebtValidationDocuments(this.props.user, accountId).then((data) => {
      let isAccessDenied =
        data && data.message && data.message == "Access denied." ? true : false;
      if (
        (typeof data != "undefined" || data != null) &&
        data.data &&
        !isAccessDenied
      ) {
        this.setState((state) => ({
          ...state,
          documents: data.data,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  deleteDocument = (doc_id) => {
    confirmAlert({
      message: "Do you want to delete document?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.deleteVerificationDocument(this.props.user, doc_id).then(
              (data) => {
                this.props.updateModalState(true, "SUCCESS", {
                  message: data.message,
                });
                this.getDocumentData();
              }
            );
          },
        },
        {
          label: "Cancel",
          onClick: () => "",
        },
      ],
    });
  };

  viewValidationDoc = (base64Data) => {
    let props = {
      image: base64Data,
      arrange_pdf: true,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  getAdjustmentDebt = (debt) => {
    let adjustment = 0;
    if (debt.adjustment) {
      adjustment =
        parseFloat(
          getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
        ) + parseFloat(debt.adjustment);
    } else {
      adjustment = parseFloat(
        getStrippedDollarAmount(debt.balance_in_dollars_as_currency)
      );
    }
    adjustment = parseFloat(adjustment).toFixed(2);
    return adjustment;
  };

  getObjectListData = (data, tableType) => {
    let headings = [],
      columns = [];
    if (tableType === "debt") {
      headings = [
        "Creditor",
        "Date",
        "Total",
        "Amount Paid",
        "Discount",
        "Balance",
      ];
    } else if (tableType === "payment") {
      headings = [
        "Date",
        "Amount",
        "Pymt Method",
        "Customer ID",
        "Transaction ID",
      ];
    }

    if (tableType === "debt") {
      columns = [
        {
          primaryColumn: {},
          secondaryColumns: [
            {
              title: data.creditor_name,
            },
            {
              title: data.created_at
                ? this.renderLatestPaymentDate(data.created_at)
                : "--",
            },
            {
              title: data.total_in_dollars_as_currency
                ? `$${data.total_in_dollars_as_currency}`
                : "n/a",
            },
            {
              title: data.initial_payment ? `$${data.initial_payment}` : "n/a",
            },
            {
              title: data.adjustment ? `$${data.adjustment}` : "n/a",
            },
            {
              title: data.balance_in_dollars_as_currency
                ? `$${data.balance_in_dollars_as_currency}`
                : "n/a",
            },
          ],
        },
      ];
    } else if (tableType === "payment") {
      columns = data.map((row) => {
        return {
          primaryColumn: {},
          secondaryColumns: [
            {
              title: moment(row.created_at).format("MM/DD/YYYY"),
              optionalClasses: "mobile-hide",
            },
            {
              title: `$${getStrippedNumberFromString(row.amount).toFixed(2)}`,
              optionalClasses: "mobile-hide",
            },
            {
              title:
                row.payment_type && row.payment_type === "Stripe"
                  ? "Credit Card"
                  : row.payment_type,
              optionalClasses: "mobile-hide",
            },
            {
              title: row.stripe_id ? row.stripe_id : "n/a",
              optionalClasses: "mobile-hide word-wrap right-space",
            },
            {
              title: row.transaction_id ? row.transaction_id : "n/a",
              optionalClasses: "mobile-hide word-wrap right-space",
            },
          ],
          fullRowIsClickable: false,
          rowType: "",
        };
      });
    }
    return {
      headings,
      columns,
    };
  };

  getDocumentObjectList = (data) => {
    let headings = [],
      columns = [];

    headings = ["Name", "Description", "Action"];

    columns = data.map((document) => {
      return {
        primaryColumn: {
          title: document.name,
        },
        secondaryColumns: [
          {
            title: document.description,
          },
          {
            title: (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  title='View'
                  style={{
                    background: "transparent",
                    marginLeft: "2em",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.viewValidationDoc(document.document)}
                >
                  <img src={viewIcon} width='24' height='24' />
                </button>
                <button
                  title='View'
                  style={{
                    background: "transparent",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.deleteDocument(document.document_id)}
                >
                  <img src={deleteIcon} width='35' height='35' />
                </button>
              </div>
            ),
            isAction: true,
            optionalClasses: "mobile-hide rmv-right-space",
          },
        ],
      };
    });
    return {
      headings,
      columns,
    };
  };

  reload = () => {
    this.getDocumentData();
  };

  showValidationModal() {
    let props = {
      rowData: this.state.debtDetails,
      consumer: this.props.consumer,
      reloadData: this.reload,
    };
    this.props.updateModalState(true, "VALIDATION_DOCUMENT", props);
  }

  renderNotes = (notes) => {
    return notes ? (
      <div
        className='object-list-empty-state'
        style={{ textAlign: "left", padding: "1.4rem", background: "white" }}
      >
        <span>{notes}</span>
      </div>
    ) : (
      <div className='object-list-empty-state' style={{ padding: "1.4rem" }}>
        <span className='object-list-empty-state-message'>
          No notes for review.
        </span>
      </div>
    );
  };

  renderValidationDoc = (document) => {
    return (
      <div>
        {document && document.length ? (
          <ObjectList
            data={this.getDocumentObjectList(document)}
            optionalClasses={"no-row-actions"}
            removeExtraSpace={true}
          />
        ) : (
          <div
            className='consumer-accounts-empty-state'
            style={{ padding: "2rem", height: "auto" }}
          >
            <h3 className='consumer-accounts-empty-state-heading'>
              No Validation Documents
            </h3>
            <p>There are currently no validation documents for this debt.</p>
          </div>
        )}
        <button
          className='cta'
          onClick={() => {
            this.showValidationModal();
          }}
          style={{ marginBottom: "2rem", marginTop: "2rem" }}
        >
          Upload Validation Document
        </button>
      </div>
    );
  };

  renderTable = (data, tableType) => {
    let emptyStateMessage = "";
    let tableData = null;

    if (tableType === "debt") {
      emptyStateMessage = "No debt details available.";
      tableData = data;
    } else if (tableType === "payment") {
      emptyStateMessage = "No payment details available";
      tableData = data;
    }

    return tableData && tableType === "debt" ? (
      <ObjectDebtList
        data={this.getObjectListData(tableData, tableType)}
        hideScrollbar={true}
      />
    ) : tableData && tableData.length > 0 ? (
      <ObjectDebtList
        data={this.getObjectListData(tableData, tableType)}
        hideScrollbar={true}
      />
    ) : (
      <div className='object-list-empty-state'>
        <span className='object-list-empty-state-message'>
          {emptyStateMessage}
        </span>
      </div>
    );
  };

  render() {
    const {
      debtDetails,
      documents,
      isLoading,
      offers,
      selectedOfferForCounter,
      showCounterOfferModal,
      error,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div
        className='app-sidebar-layout-canvas-content'
        id='scroll-emp-val-doc'
      >
        <h2 className='accounts-view-heading'>Debt Details</h2>
        {this.renderTable(debtDetails, "debt")}
        <h2 className='accounts-view-heading'>Notes</h2>
        {this.renderNotes(debtDetails.additional_details)}
        <h2 className='accounts-view-heading'>Payment Details</h2>
        {this.renderTable(debtDetails.payment_details, "payment")}
        <h2 className='accounts-view-heading'>Documents</h2>
        {this.renderValidationDoc(documents)}
      </div>
    );
  }
}

export default EmployeeUploadValidationDoc;
