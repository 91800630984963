import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Packages
import moment from 'moment';

// Components
import Panel from '../App/Panel';

// Utils
import {
  getStrippedDollarAmount, getNumberWithCommas
} from '../../utils/helpers';

class ConsumerAccountsViewSidebar extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired
  }

  getPaidDebt = (debt) => {
    let adjustment = debt.adjustment? debt.adjustment : 0
    let amount = (getStrippedDollarAmount(debt.total_in_dollars_as_currency) - getStrippedDollarAmount(debt.balance_in_dollars_as_currency) - adjustment).toFixed(2);
    return getNumberWithCommas(amount)
  }

  renderMakePaymentLink() {
    const { account } = this.props;

    let acceptedOffers = account.offers.filter((offer) => {
      return offer.status === 'accepted';
    });

    return acceptedOffers.length === 0
    ? (
      <Link
        to={`/dashboard/accounts/${account.id}/settle-account`}
        className="text-link accounts-view-sidebar-debt-text-link"
      >
        Make a Payment »
      </Link>
    ) : '';
  }

  render() {
    const { debt } = this.props;
    const styles = {fontSize: `1.5em`, padding: `0.7rem`}
    
    return (
      <div className="app-sidebar app-sidebar-left accounts-view-sidebar mobile-view">
        <Panel
          // heading="Outstanding Debt"
          optionalClasses="accounts-view-sidebar-debt-panel"
        >
          <h4 className="panel-heading-secondary"> Total</h4>
          <div className="accounts-view-sidebar-debt" style={styles}>
            ${debt.total_in_dollars_as_currency}
          </div><br/>
          <h4 className="panel-heading-secondary">Amount Paid</h4>
          <div className="accounts-view-sidebar-debt" style={styles}>
            ${this.getPaidDebt(debt)}
          </div><br/>
          <h4 className="panel-heading-secondary">Adjustment</h4>
          <div className="accounts-view-sidebar-debt" style={styles}>
            ${debt.adjustment? parseFloat(debt.adjustment).toFixed(2) : parseFloat(0).toFixed(2)}
          </div><br/>
          <h4 className="panel-heading-secondary">Balance</h4>
          <div className="accounts-view-sidebar-debt" style={styles}>
            ${debt.balance_in_dollars_as_currency}
          </div>
          {debt.status === 'open' ? null : this.renderMakePaymentLink()}
        </Panel>
        <Panel
          heading="Debt Information"
        >
          <h4 className="panel-heading-secondary">
            Total
          </h4>
          <p>
            ${debt.total_in_dollars_as_currency}
          </p>
          <h4 className="panel-heading-secondary">
            Date of Origination
          </h4>
          <p>
            {moment(debt.created_at).format('MM/DD/YYYY')}
          </p>
          <h4 className="panel-heading-secondary">
            Amount Paid
          </h4>
          <p>
            ${this.getPaidDebt(debt)}
          </p>
          <h4 className="panel-heading-secondary">
            Description
          </h4>
          <p>
            {debt.description ? debt.description : 'n/a'}
          </p>
        </Panel>
      </div>
    );
  }
}

export default ConsumerAccountsViewSidebar;
