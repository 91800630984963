import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// import Intercom from "react-intercom";
import * as API from "../../utils/api";

import { getZohoPagesense } from "../../utils/helpers";

// Components
import AppConsumerHeader from "../../components/App/AppConsumerHeader";
import ConsumerNav from "../../components/Consumer/ConsumerNav";
import AppConsumerHeaderStatic from "../../components/App/AppConsumerHeaderStatic";

class ConsumerPortalStatic extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    screen: PropTypes.object.isRequired,
    prevPath: PropTypes.string,
  };

  state = {
    intercomUser: "",
    intercomAppId: "",
  };

  componentDidMount() {
    //this.setIntercomUser(this.props.user);
    // getZohoPagesense();
  }

  componentWillReceiveProps(nextProps) {
    // this.setIntercomUser(nextProps.user);
  }
  componentWillUnmount() {
    
  }

  

  render() {
    
    
    
    //mpig6e59

    const { prevPath, intercomAppId } = this.props;
    return (
      <div className="app-portal">
          <AppConsumerHeaderStatic
            user={this.props.user}
            appThemeData={this.props.appThemeData}
            appName={this.props.appName}
            logoPath="/dashboard"
            hideNav={true}
            accountHolder="consumer"
          />
        
        
        <div
          className={`app-portal-canvas consumer-portal${
            this.props.removePadding ? " canvas-back-consumer" : ""
          }`}
        >
          <div
            className="wrap"
            style={this.props.removePadding ? { padding: 0 } : {}}
          >
            {this.props.screen}
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerPortalStatic;
