import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import * as API from "../../utils/api";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";

class QbFailure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organization_key: "",
      sub_id: "",
      email: "",
      id_token: "",
      isSavedSubdomain: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      user_auth_token: null,
      user_id: null,
      eula_accepted_date: false,
    };
  }
  styles = {
    loader: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundColor: "#ffffff",
      opacity: 0.95,
    },
  };

  componentWillMount() {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    const userCandidate = {
      email: searchParams.get("email"),
      organization_key: searchParams.get("organization_key"),
      openid_id: searchParams.get("sub_id"),
      id_token: searchParams.get("id_token"),
      sub_id: searchParams.get("sub_id"),
    };
    this.setState({ isLoading: true });
    try {
      API.setOauthSSOObj(userCandidate);
    } catch (error) {
      console.log("error catched QB failure=>", error);
    }
    API.ssoLoginForQB(userCandidate).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (!data.errors) {
          const user = {
            first_name: data.first_name,
            last_name: data.last_name,
            qb_connect:
              data.is_qb_connected === null ? false : data.is_qb_connected,
            user_id: data.id,
            user_auth_token: data.auth_token,
            admin: data.admin,
            is_employee: data.is_employee,
            super_admin: data.super_admin,
            onboarding_complete: data.accepted_on ? true : false,
            is_onboarding_completed: data.accepted_on ? true : false,
            client_admin: data.client_admin,
            verified: data.verified,
            verified_at: data.verified_at,
            email: data.email,
            user_hash: data.user_hash,
            intercom_id: data.intercom_id,
            organization_key: searchParams.get("organization_key"),
          };
          this.props.updateUserLogin(user);
          if (searchParams.get("is_save_subdomain")) {
            let { history } = this.props;
            history.push(`/client/settings`, { openQB: true });
          }
        }
      }
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { heapEmail, heapAppId, appName } = this.props;
    const { isLoading } = this.state;

    return isLoading ? (
      <div style={this.styles.loader}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div className='sessions fill-screen mg-session'>
        <Helmet>
          <title>{appName} | Failed</title>
        </Helmet>
        <div className={`sessions-modal modal-space`}>
          <div className='sessions-modal-logo-container centerd-content'>
            <Logo circular={true} />
          </div>
          <div className='sessions-modal-content'>
            <h1 className='sessions-modal-heading qb-heading-error'>
              QuickBooks Online Account Authentication Failed
            </h1>
            <p className='qb-msg-text'>
              The authentication process with QuickBooks Online has failed,
              Please try again.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default QbFailure;
