import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import TextareaAutosize from "react-autosize-textarea";
import FileBase64 from "react-file-base64";
import { Redirect } from "react-router-dom";
import MaskedTextInput from "react-text-mask";
import InfoIcon from "../../../../assets/images/quickbooks/ic_info_gray.png";
import * as API from "../../../../utils/api";
import {
  amountMask,
  getStrippedDollarAmount,
  isValidDate,
} from "../../../../utils/helpers";
import AppCanvasLoader from "../../AppCanvasLoader";
import Modal from "../Modal";
import Emitter from "../../../../utils/event-emitter";

class CancelRefundAmountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {
        paymentAmount: {
          value: "",
          hasError: false,
        },
        cancellationFee: {
          value: "",
          hasError: false,
        },
        feeToInvoice: {
          value: "",
          hasError: false,
        },
        cancellationDate: {
          value: moment(new Date()).format("MM/DD/YYYY"),
          hasError: false,
        },
        cancellationNote: {
          value: "",
          hasError: false,
        },
        document: {
          value: "",
          hasError: false,
        },
      },
      isLoading: true,
      error: {
        hasAnyError: false,
        statusCode: 200,
        message: "",
      },
    };
  }

  componentDidMount() {
    let { currentRow } = this.props;
    let { input } = this.state;
    //set payment value which is getting from previous list selected row
    input.paymentAmount.value = parseFloat(currentRow.amount).toFixed(2);
    this.setState({ input, isLoading: false });
  }

  //make amount value to fix to 2 decimal points
  getFormattedAmt = (value) => {
    if (value) return parseFloat(value).toFixed(2);
    return "0.00";
  };

  //on input blur add 2 decimal values
  handleInputBlur = () => {
    let { input } = this.state;
    if (input.cancellationFee.value) {
      if (!input.cancellationFee.value.includes(".")) {
        input.cancellationFee.value = input.cancellationFee.value + ".00";
      }
    }
    this.setState({ input });
  };

  //close modal
  handleModalClose = () => {
    if (!this.state.error.hasAnyError) {
      this.props.hideModal();
    }
  };

  //calls on form text input value change
  handleCancelInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  // get selected file check its type and save filename, extension, size in mb and other details
  getFiles(files) {
    let newInputState = { ...this.state.input.document };
    let imgExtension = files[0].type;
    let fileName = moment(new Date()).format("MMDDYYYY");
    let extensionFlag = false;
    if (!imgExtension) {
      imgExtension = files[0].name.split(".").pop();
    }

    if (
      imgExtension.toLowerCase() === "image/png" ||
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg" ||
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf"
    ) {
      extensionFlag = true;
    }
    if (files[0].file && files[0].file.size > 10000000) {
      extensionFlag = false;
    } else {
      let file_size =
        files[0].file && files[0].file.size
          ? files[0].file.size / (1024 * 1024)
          : 0;
      newInputState.fileSizeInMB = parseFloat(file_size).toFixed(2);
    }
    if (imgExtension.toLowerCase() === "image/png") {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".png"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/jpg" ||
      imgExtension.toLowerCase() === "image/jpeg"
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".jpeg"
      }`;
    } else if (
      imgExtension.toLowerCase() === "image/pdf" ||
      imgExtension.toLowerCase() === "application/pdf" ||
      imgExtension.toLowerCase() === "application/jpeg"
    ) {
      newInputState.fileExtension = `${
        files[0].name ? files[0].name : fileName + ".pdf"
      }`;
    }

    if (extensionFlag) {
      newInputState.value = files[0].base64;
      newInputState.hasError = false;
    } else {
      newInputState.hasError = true;
    }

    this.setState({
      ...this.state,
      input: {
        ...this.state.input,
        document: newInputState,
      },
    });
  }

  //submit cancel payment form
  cancelRefundPayment = (paymentInfo) => {
    $("#form-1").replaceWith('<div class="spinner"></div>');
    API.cancelRefundPayment(this.props.user, paymentInfo).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code && data.status_code === 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        //reloads the invoice details screen after api success
        this.props.reloadPage();
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data.error,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  handleCancelPaymentClick = (event) => {
    event.preventDefault();
    const { input } = this.state;
    let { invoiceDetails, currentRow } = this.props;
    if (this.validateForm()) {
      let paymentInfo = {
        invoice_id: invoiceDetails.id,
        payment_transaction_id: currentRow.payment_transaction_id,
        // cancellation_fee: getStrippedDollarAmount(input.cancellationFee.value),
        date: input.cancellationDate.value,
        note: input.cancellationNote.value,
        document: {
          document: input.document.value,
          document_name: input.document.fileExtension,
          file_size_in_mb: input.document.fileSizeInMB,
          description: "",
          name: "CancelRefund" + invoiceDetails.invoice_number,
          document_type_id: "",
        },
      };
      this.cancelRefundPayment(paymentInfo);
    }
  };

  //validate the form with required fields
  validateForm = () => {
    let errorsArePresent = false;
    // Determine which input group to check for errors
    let newInputState = { ...this.state.input };
    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      // if (key === "cancellationFee") {
      //   if (value.value === "" || getStrippedDollarAmount(value.value) < 1) {
      //     errorsArePresent = true;
      //     newInputState[key].hasError = true;
      //   }
      // } else
      if (key === "cancellationDate") {
        if (!isValidDate(value.value) || value.value === "") {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cancellationNote") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "document") {
        if (value.value && value.hasError) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
      return false;
    } else {
      return true;
    }
  };

  //show render messages based on error
  renderErrorMessage(input) {
    let message = "";
    if (input === "paymentAmount") {
      message = "Please enter amount.";
    } else if (input === "cancellationFee") {
      message = "Please enter fee.";
    } else if (input === "cancellationDate") {
      message = "Please enter valid date.";
    } else if (input === "invoiceLine") {
      message = "Please select invoice line.";
    } else if (input === "cancellationNote") {
      message = "Please enter note.";
    } else {
      message = "Please complete this field.";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  render() {
    let { input, error, isLoading } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        //showToast(error.message);
        this.props.updateModalState(true, "ERROR", { message: error.message });
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title='Cancel Payment'
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses='scroll'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <form>
            <div style={style.container}>
              <div style={style.content}>Payment Amount : </div>
              <div
                className={`input-container${
                  input.paymentAmount.hasError ? " error" : ""
                }`}
              >
                <MaskedTextInput
                  guide={true}
                  mask={amountMask}
                  readOnly={true}
                  name='paymentAmount'
                  placeholder='$0'
                  placeholderChar={"\u2000"}
                  pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                  value={"$" + input.paymentAmount.value}
                  onChange={(event) =>
                    this.handleCancelInputChange({
                      paymentAmount: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.paymentAmount.hasError
                  ? this.renderErrorMessage("paymentAmount")
                  : null}
              </div>
            </div>
            {/* <div style={style.container}>
              <div style={style.content}>Cancellation Fee : </div>
              <div
                className={`input-container${
                  input.cancellationFee.hasError ? " error" : ""
                }`}
              >
                <MaskedTextInput
                  guide={true}
                  mask={amountMask}
                  name="cancellationFee"
                  placeholder="$0"
                  placeholderChar={"\u2000"}
                  pattern="^(\d+)(,\d{1,2}|.\d{1,2})?$"
                  value={input.cancellationFee.value}
                  onBlur={() => this.handleInputBlur()}
                  onChange={(event) =>
                    this.handleCancelInputChange({
                      cancellationFee: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.cancellationFee.hasError
                  ? this.renderErrorMessage("cancellationFee")
                  : null}
              </div>
            </div> */}
            {/* <div className={`input-container third-party-config-divide-cell`}>
              <div style={{ display: `flex` }}>
                <input
                  name="fee-to-invoice"
                  type="checkbox"
                  style={{
                    WebkitAppearance: `checkbox`,
                    width: `20px`,
                    marginRight: `10px`,
                    marginTop: `-8px`,
                  }}
                  checked={input.feeToInvoice.value}
                  onChange={(event) =>
                    this.handleCancelInputChange({
                      feeToInvoice: {
                        value: event.target.checked,
                        hasError: false,
                      },
                    })
                  }
                />
                Apply Fee To Invoice{" "}
                <img
                  src={InfoIcon}
                  style={{marginTop: '-1px'}}
                  className="info-tooltip"
                  title="Checking the Apply Fee To Invoice checkbox will add the fee to this Customer’s invoice."
                />
              </div>
            </div> */}
            <div style={style.container}>
              <div style={style.content}>Cancellation Date : </div>
              <div
                className={`input-container${
                  input.cancellationDate.hasError ? " error" : ""
                }`}
              >
                <MaskedTextInput
                  guide={true}
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  name='cancellationDate'
                  placeholder='mm/dd/yyyy'
                  placeholderChar={"\u2000"}
                  type='text'
                  value={input.cancellationDate.value}
                  onChange={(event) =>
                    this.handleCancelInputChange({
                      cancellationDate: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.cancellationDate.hasError
                  ? this.renderErrorMessage("cancellationDate")
                  : null}
              </div>
            </div>
            <div style={style.container}>
              <div style={style.content}>Cancellation Note : </div>
              <div
                className={`input-container${
                  input.cancellationNote.hasError ? " error" : ""
                }`}
              >
                <div className='refund-method-select'>
                  <TextareaAutosize
                    name='cancellationNote'
                    type='textarea'
                    placeholder='Your cancellation note here...'
                    value={input.cancellationNote.value}
                    onChange={(event) =>
                      this.handleCancelInputChange({
                        cancellationNote: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  <img
                    src={InfoIcon}
                    className='info-tooltip'
                    title='The cancellation note entered here will be added to the notes section of this invoice for record keeping. The note will not be sent to your customer.'
                  />
                </div>
                {input.cancellationNote.hasError
                  ? this.renderErrorMessage("cancellationNote")
                  : null}
              </div>
            </div>
            <div className='admin-form-row address-document'>
              <div className={`input-container error`}>
                <div className='refund-method-select file-text'>
                  <FileBase64
                    multiple={true}
                    onDone={this.getFiles.bind(this)}
                  />
                  <img
                    src={InfoIcon}
                    className='info-tooltip'
                    title='Documents uploaded here will be added to the Documents section of this invoice. The document will not be sent to your customer.'
                  />
                </div>
                <div
                  className=' input-error-message'
                  style={{ color: "darkgrey" }}
                >
                  Image upload format (.png, .jpg, .jpeg, .bmp, .pdf) <br />{" "}
                  Size must be less than 1 MB <br />
                  <br />
                </div>
              </div>
            </div>
            <div className='modal-actions'>
              <a
                id='form-1'
                className='cta mg-brand2-color'
                onClick={(event) => this.handleCancelPaymentClick(event)}
              >
                Submit Cancellation of Payment
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

const style = {
  container: { display: "flex" },
  content: { width: "60%", paddingTop: `10px` },
};

export default CancelRefundAmountModal;
