import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as API from "../../../utils/api"
//import companyData from '../../../assets/styles/theme.json';

class LandingHero extends Component {
   constructor(props){
	  super(props);
       this.state = {
           companyData: {}
       };
   }

    componentDidMount() {
        this.getOrganizationData();
    }

    async getOrganizationData() {
        await API.getOrganizationData().then((data) => {
            this.setState({
                companyData: data
            });
        })
    }
	
   render() {
    return (
      <section className="hero landing-hero false-bottom">
        <div className="inner-wrap">
          <div className="hero-content">
            <h1>
              Debt Happens.
            </h1>
            <p>
            But with {this.props.appName}, you can manage it. {this.props.appName} offers an easy solution for
            identifying, negotiating and paying outstanding debts. So you can
            move on, and make life happen.
            </p>
            <p>
            Received a registration code in the mail? Click the link below to
            finish setting up your account.
            </p>
          </div>
          <div className="hero-content hero-cta-container">
            <Link
              className="cta"
              title={"Register with " + this.state.companyData.name + " Today"}
              to="/register"
            >
              Register with {this.state.companyData.name} Today
            </Link>
            <div className="landing-hero-device">
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LandingHero;
