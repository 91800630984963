import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// Components
import Modal from '../Modal';


class UserAccountPaymentFailedModal extends Component {

  handleModalClose = () => {
    this.props.hideModal();
    this.props.history.push("/dashboard/accounts");
  }

  render() {

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="offer-rejected"
        closeOnClickOutside={false}
        modalExit={false}
      >
        <div className="modal-spotlight-illustration"></div>
        <h1 className="modal-heading">
          Payment Failed!
        </h1>
        <p>
          Your payment is not processed. Please try again.
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.handleModalClose}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default withRouter(UserAccountPaymentFailedModal);