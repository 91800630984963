import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import shortid from "shortid";
import * as API from "../../../../utils/api";
import viewIcon from "../../../../assets/icons/view.png";
import downloadImg from "../../../../assets/icons/download.png";
import CardView from "../../../../components/CardView/CardView";
import InvoiceObjectList from "../../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import InvoiceAndLineDetails from "./InvoiceAndLineDetails";

class ConsumerInvoiceDetails extends Component {
  state = {
    expandPayment: false,
    expandDoc: false,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      amount = invoice.total_amount
        ? invoice.balance
          ? parseFloat(invoice.total_amount) - parseFloat(invoice.balance)
          : invoice.total_amount
        : invoice.total_amount;
    }
    return amount ? amount.toFixed(2) : "0.00";
  };

  viewValidationDoc = (row) => {
    const { user } = this.props;
    let props = {
      user: user,
      image: row.document,
      type: row.document_name,
      arrange_pdf: true,
      doc_name: row.name,
      doc_id: row.document_id,
      invoiceId: this.props.invoiceDetails.invoice_number,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  downloadAttachedDocuement = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuementForConsumer(user, doc_id, "debts").then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  documentAction = (row) => {
    let imgExtension = row.document_name && row.document_name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension.toLowerCase() === "xls" ||
      imgExtension.toLowerCase() === "xlsx" ||
      imgExtension.toLowerCase() === "doc" ||
      imgExtension.toLowerCase() === "docx"
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;

    return (
      <div>
        <a
          className='make-payment-button'
          style={{ cursor: "pointer", marginRight: 30 }}
          title='Download'
          onClick={() =>
            this.downloadAttachedDocuement(row.document_id, row.document_name)
          }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>
        {flag && (
          <button
            title='View'
            style={{
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width='20' height='20' />
          </button>
        )}
      </div>
    );
  };

  getDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY");
    }
    return "--/--/----";
  };

  handleHeaderExpander = (event, type) => {
    if (type === "Payment") {
      this.setState({ expandPayment: !this.state.expandPayment });
    } else {
      this.setState({ expandDoc: !this.state.expandDoc });
    }
  };

  getClickableHeader = (header, type, data) => {
    let { expandDoc, expandPayment } = this.state;
    let count = data && data.length ? data.length : 0;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='ic-wt-fields'>
        <div className='he-hed he-sec-hed'>
          {header} ({count})
        </div>
        <a
          key={shortid.generate()}
          className={`client-caret expander-caret consumer-brand ${
            flag ? " down-caret" : ""
          }`}
          style={{ margin: "12px 0px 0px 12px" }}
          onClick={(event) => this.handleHeaderExpander(event, type)}
        />
      </div>
    );
  };

  renderPaymentAndDoc = (data, header, type, emptyMsg) => {
    let { expandDoc, expandPayment } = this.state;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div style={type === "Payment" ? { marginBottom: "2em" } : {}}>
        {this.getClickableHeader(header, type, data)}
      </div>
    );
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  render() {
    const { appName, user, invoiceDetails } = this.props;
    const { expandLine, isLoading, error } = this.state;
    const { notes } = invoiceDetails;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <div className='consumer-scroll-account-details scrollbar'>
        <InvoiceAndLineDetails
          invoiceDetails={invoiceDetails}
          fromClient={false}
        />
        <div style={{ marginTop: "2em" }}>
          <div className='he-hed he-sec-hed'>Notes</div>
          {notes && notes.length ? (
            <div></div>
          ) : (
            this.renderEmpty(`There are currently no notes to review.`)
          )}
        </div>
        {this.renderPaymentAndDoc(
          invoiceDetails.document,
          "Documents",
          "Document",
          "No documents available"
        )}
        {this.renderPaymentAndDoc(
          invoiceDetails.payment_history,
          "Payment Details",
          "Payment",
          "No payment details available"
        )}
      </div>
    );
  }
}

export default ConsumerInvoiceDetails;
