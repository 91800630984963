import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import ReactGA from 'react-ga';

// Components
import OnboardingHeader from "../../components/Onboarding/OnboardingHeader";
import SessionsModal from "../../components/Sessions/SessionsModal";
import OnboardingResidenceForm from "../../components/Onboarding/OnboardingResidenceForm";

import { getZohoPagesense } from "../../utils/helpers";

class OnboardingResidence extends Component {
  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/OnboardingResidence");
  }

  render() {
    let { appName } = this.props;

    return (
      <div className='sessions fill-screen'>
        <Helmet>
          <title>{appName} | Where Do You Live?</title>
        </Helmet>
        <SessionsModal>
          <OnboardingHeader optionalClassName='nav-bar' />
          <div className='sessions-modal-content'>
            <h1 className='sessions-modal-heading'>Where do you live?</h1>
            <p>We need this information to ensure we verify your identity.</p>
            <OnboardingResidenceForm
              user={this.props.user}
              updateModalState={this.props.updateModalState}
            />
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default OnboardingResidence;
