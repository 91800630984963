import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

// API
import * as API from "../../../utils/api";

// Packages
import TextareaAutosize from "react-autosize-textarea";
import Select from "react-select";
import "react-select/dist/react-select.css";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import Panel from "../../App/Panel";
import AppCanvasLoader from "../../App/AppCanvasLoader";

// Utils
import stateDataAbbreviations from "../../../utils/stateDataAbbreviations.json";
import {
  isValidDate,
  numberMask,
  getStrippedDollarAmount,
  convertCreditorsToArrayForSelectComponent,
  showToast,
  validateEmail,
} from "../../../utils/helpers";

class ClientsUpdateConsumerForm extends Component {
  state = {
    consumer: {},
    creditors: [],
    input: {
      consumer: {},
      consumerDebts: [],
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
  };

  componentDidMount() {
    API.fetchAllCreditors(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        let { consumer } = this.props;
        let creditorsArray = convertCreditorsToArrayForSelectComponent(
          data.creditors
        );

        this.setState((state) => ({
          consumer,
          creditors: creditorsArray,
          input: {
            consumer: {
              first_name: {
                value: consumer.first_name,
                hasError: false,
              },
              middle_name: {
                value: consumer.middle_name,
                hasError: false,
              },
              last_name: {
                value: consumer.last_name,
                hasError: false,
              },
              email: {
                value: consumer.email,
                hasError: false,
              },
              phone_number: {
                value: consumer.phone_number,
                hasError: false,
              },
              date_of_birth: {
                value: consumer.date_of_birth,
                hasError: false,
              },
              ssn: {
                value: consumer.ssn,
                hasError: false,
              },
              street_address_primary: {
                value: consumer.street_address_primary,
                hasError: false,
              },
              street_address_secondary: {
                value: consumer.street_address_secondary,
                hasError: false,
              },
              city: {
                value: consumer.city,
                hasError: false,
              },
              state: {
                value: consumer.state,
                hasError: false,
              },
              zipcode: {
                value: consumer.zipcode,
                hasError: false,
              },
              company_name: {
                value: consumer.company_name,
                hasError: false,
              },
              title: {
                value: consumer.title,
                hasError: false,
              },
              suffix: {
                value: consumer.suffix,
                hasError: false,
              },
              display_name: {
                value: consumer.display_name,
                hasError: false,
              },
              secondary_email: {
                value: consumer.secondary_email,
                hasError: false,
              },
              mobile: {
                value: consumer.mobile_no,
                hasError: false,
              },
              fax_number: {
                value: consumer.fax,
                hasError: false,
              },
              alternate_phone_number: {
                value: consumer.alternate_phone_number,
                hasError: false,
              },
              website: {
                value: consumer.website,
                hasError: false,
              },
            },
            consumerDebts: consumer.debts_attributes.map((debt) => {
              let debtCreditor = creditorsArray.filter((creditor) => {
                return creditor.label === debt.creditor_name;
              });

              let dateCreated = debt.created_at
                ? moment(debt.created_at).format("MM/DD/YYYY")
                : "";

              return {
                additional_details: {
                  value: debt.additional_details,
                  hasError: false,
                },
                balance_in_dollars_as_currency: {
                  value: debt.balance_in_dollars_as_currency,
                  hasError: false,
                },
                close_out_date: {
                  value: debt.close_out_date,
                  hasError: false,
                },
                created_at: {
                  value: dateCreated,
                  hasError: false,
                },
                creditor_id: {
                  value:
                    debtCreditor && debtCreditor.length
                      ? debtCreditor[0].value
                      : "",
                  hasError: false,
                },
                date_sent: {
                  value: debt.date_sent,
                  hasError: false,
                },
                id: {
                  value: debt.id,
                  hasError: false,
                },
                last_payment_date: {
                  value: debt.last_payment_date,
                  hasError: false,
                },
                loan_open_date: {
                  value: debt.loan_open_date,
                  hasError: false,
                },
                status: {
                  value: debt.status,
                  hasError: false,
                },
                total_in_dollars_as_currency: {
                  value: debt.total_in_dollars_as_currency,
                  hasError: false,
                  previousTotal: debt.total_in_dollars_as_currency,
                },
                user_id: {
                  value: debt.user_id,
                  hasError: false,
                },
                initial_payment: {
                  value: debt.initial_payment,
                  hasError: false,
                  previousPayment: debt.initial_payment,
                },
                adjustment: {
                  value: debt.adjustment,
                  hasError: false,
                  previousAdjustment: debt.adjustment,
                },
              };
            }),
          },
          isLoading: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }

  handleConsumerInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumer: {
          ...state.input.consumer,
          ...newPartialInput,
        },
      },
    }));
  }

  handleDebtInputChange(newPartialInput, index) {
    let newConsumerDebts = [...this.state.input.consumerDebts];
    newConsumerDebts[index] = {
      ...newConsumerDebts[index],
      ...newPartialInput,
    };
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerDebts: newConsumerDebts,
      },
    }));
  }

  addCreditorInputSet = () => {
    let newDebts = [...this.state.input.consumerDebts];
    let userId = this.state.consumer.id;

    newDebts.push({
      additional_details: {
        value: null,
        hasError: false,
      },
      balance_in_dollars_as_currency: {
        value: "",
        hasError: false,
      },
      close_out_date: {
        value: "",
        hasError: false,
      },
      created_at: {
        value: "",
        hasError: false,
      },
      creditor_id: {
        value: "",
        hasError: false,
      },
      creditor_name: {
        value: "",
        hasError: false,
      },
      date_sent: {
        value: null,
        hasError: false,
      },
      id: {
        value: "",
        hasError: false,
      },
      last_payment_date: {
        value: "",
        hasError: false,
      },
      loan_open_date: {
        value: "",
        hasError: false,
      },
      status: {
        value: null,
        hasError: false,
      },
      total_in_dollars_as_currency: {
        value: "",
        hasError: false,
        previousTotal: "",
      },
      user_id: {
        value: userId,
        hasError: false,
      },
      initial_payment: {
        value: "",
        hasError: false,
      },
      adjustment: {
        value: "",
        hasError: false,
      },
    });

    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        consumerDebts: newDebts,
      },
    }));
  };

  addNewCreditor = () => {
    this.props.updateModalState(true, "Add_NEW_CREDITOR", this.props);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let { history, user } = this.props;
    const consumerId = this.state.consumer.id;

    if (this.validateForm()) {
      confirmAlert({
        // title: 'Confirm to save profile',
        message: "Are you sure to save changes?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.showLoader(true);
              let consumerForSubmission = this.getConsumerForSubmission(
                consumerId
              );
              API.updateClientsConsumer(
                user,
                consumerId,
                consumerForSubmission
              ).then((data) => {
                this.props.showLoader(false);
                if (
                  (typeof data != "undefined" || data != null) &&
                  !data.error
                ) {
                  history.push(`/client/accounts/${consumerId}`);
                } else {
                  this.setState((state) => ({
                    ...state,
                    hasAnyError: true,
                  }));
                }
              });
            },
          },
          {
            label: "Cancel",
            onClick: () => "",
          },
        ],
      });
    } else {
      this.setFormErrorState();
    }
  };

  getConsumerForSubmission = (consumerId) => {
    let debtCreditor = "";
    const { input, creditors } = this.state;
    const consumerInput = this.state.input.consumer;

    let debtAttributes = input.consumerDebts.map((debt) => {
      debtCreditor = creditors.filter((creditor) => {
        return creditor.value === debt.creditor_id.value;
      });

      let initial_payment = debt.initial_payment.value
        ? getStrippedDollarAmount(debt.initial_payment.value)
        : 0;
      let adjustment = debt.adjustment.value
        ? getStrippedDollarAmount(debt.adjustment.value)
        : 0;

      return {
        additional_details: debt.additional_details.value,
        //balance_in_dollars: this.getNewDebtBalance(debt) - debt.id.value? 0 :initial_payment - debt.id.value? 0 :adjustment,
        balance_in_dollars: this.getNewDebtBalance(debt),
        close_out_date: debt.close_out_date.value,
        created_at: moment(debt.created_at.value, "MM/DD/YYYY"),
        creditor_id: debtCreditor[0].value,
        creditor_name: debtCreditor[0].label,
        date_sent: debt.date_sent.value,
        id: debt.id.value,
        last_payment_date: debt.last_payment_date.value,
        loan_open_date: debt.loan_open_date.value,
        status: debt.status.value,
        total_in_dollars: getStrippedDollarAmount(
          debt.total_in_dollars_as_currency.value
        ),
        initial_payment: initial_payment,
        adjustment: adjustment,
        user_id: consumerId,
      };
    });

    let consumerForSubmission = {
      city: consumerInput.city.value,
      date_of_birth: consumerInput.date_of_birth.value,
      debts_attributes: debtAttributes,
      email: consumerInput.email.value,
      first_name: consumerInput.first_name.value,
      id: consumerId,
      middle_name: consumerInput.middle_name.value,
      last_name: consumerInput.last_name.value,
      phone_number: consumerInput.phone_number.value,
      ssn: consumerInput.ssn.value,
      state: consumerInput.state.value,
      street_address_primary: consumerInput.street_address_primary.value,
      street_address_secondary: consumerInput.street_address_secondary.value,
      zipcode: consumerInput.zipcode.value,
      title: consumerInput.title.value,
      suffix: consumerInput.suffix.value,
      display_name: consumerInput.display_name.value,
      secondary_email: consumerInput.secondary_email.value,
      mobile_no: consumerInput.mobile.value,
      fax: consumerInput.fax_number.value,
      alternate_phone_number: consumerInput.alternate_phone_number.value,
      website: consumerInput.website.value,
    };

    return consumerForSubmission;
  };

  getNewDebtBalance = (debt) => {
    let newTotalDebt = getStrippedDollarAmount(
      debt.total_in_dollars_as_currency.value
    );
    let previousTotalDebt = getStrippedDollarAmount(
      debt.total_in_dollars_as_currency.previousTotal
    );
    let oldBalance = getStrippedDollarAmount(
      debt.balance_in_dollars_as_currency.value
    );

    let totalDebtDifference = newTotalDebt - previousTotalDebt;
    let newBalance = oldBalance + totalDebtDifference;

    if (!debt.id.value) {
      let initialPayment = getStrippedDollarAmount(debt.initial_payment.value);
      let adjustment = getStrippedDollarAmount(debt.adjustment.value);
      newBalance = newBalance - initialPayment - adjustment;
    } else {
      let initialPayment = getStrippedDollarAmount(debt.initial_payment.value);
      let adjustment = getStrippedDollarAmount(debt.adjustment.value);
      let previousPayment = getStrippedDollarAmount(
        debt.initial_payment.previousPayment
      );
      let previousAdjustment = getStrippedDollarAmount(
        debt.adjustment.previousAdjustment
      );

      if (initialPayment < previousPayment) {
        let payment = previousPayment - initialPayment;
        newBalance = newBalance + payment;
      } else {
        let payment = initialPayment - previousPayment;
        newBalance = newBalance - payment;
      }
      if (adjustment < previousAdjustment) {
        let actual_adjustment = previousAdjustment - adjustment;
        newBalance = newBalance + actual_adjustment;
      } else {
        let actual_adjustment = adjustment - previousAdjustment;
        newBalance = newBalance - actual_adjustment;
      }
    }

    if (newBalance < 0) {
      newBalance = 0;
    }

    return newBalance;
  };

  validateForm = () => {
    let errorsArePresent = false;

    // Check Consumer Info Inputs for Errors
    let newConsumerInputState = { ...this.state.input.consumer };

    Object.entries(newConsumerInputState).forEach(([key, value]) => {
      if (key === "date_of_birth") {
        if (
          !isValidDate(value.value) ||
          value.value === "" ||
          value.value === null
        ) {
          newConsumerInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (value.value && !validateEmail(value.value)) {
          newConsumerInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "display_name") {
        if (!value.value) {
          newConsumerInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "phone_number") {
        let phoneNumber = value.value;
        if (phoneNumber === "" || phoneNumber === null) {
          newConsumerInputState.phone_number.hasError = true;
          errorsArePresent = true;
        } else {
          let phoneNumber = value.value
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
          if (phoneNumber === "") {
            newConsumerInputState.phone_number.hasError = true;
            errorsArePresent = true;
          } else if (phoneNumber.trim().length < 10) {
            newConsumerInputState.phone_number.hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (key === "mobile") {
        if (value.value) {
          let mobile = value.value
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
          if (mobile.trim().length < 10) {
            newConsumerInputState.mobile.hasError = true;
            errorsArePresent = true;
          }
        }
      } else if (key === "alternate_phone_number") {
        if (value.value) {
          let alternate_phone_number = value.value
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");
          if (alternate_phone_number.trim().length < 10) {
            newConsumerInputState.alternate_phone_number.hasError = true;
            errorsArePresent = true;
          }
        }
      }
    });

    // Check Debts' Inputs for Errors
    let newConsumerDebtsInputState = [...this.state.input.consumerDebts];

    for (let debt of newConsumerDebtsInputState) {
      if (this.checkFormFieldObjectForErrors(debt, errorsArePresent)) {
        errorsArePresent = true;
      }
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  };

  setFormErrorState = () => {
    let errorsArePresent = false;

    // Set errors on consumer account info fields
    let newConsumerInputState = { ...this.state.input.consumer };

    Object.entries(newConsumerInputState).forEach(([key, value]) => {
      if (key === "date_of_birth") {
        if (!isValidDate(value.value)) {
          newConsumerInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "email") {
        if (!validateEmail(value.value)) {
          newConsumerInputState[key].hasError = true;
          errorsArePresent = true;
        }
      } else if (key === "display_name") {
        if (!value.value) {
          newConsumerInputState[key].hasError = true;
          errorsArePresent = true;
        }
      }

      if (newConsumerInputState[key].hasError) {
        errorsArePresent = true;
      }
    });

    // Set errors on debt object input fields
    let newConsumerDebtsInputState = [...this.state.input.consumerDebts];

    for (var index = 0; index < newConsumerDebtsInputState.length; index++) {
      if (
        this.checkFormFieldObjectForErrors(
          newConsumerDebtsInputState[index],
          errorsArePresent
        )
      ) {
        newConsumerDebtsInputState[index] = this.getUpdatedDebtsInputObject(
          newConsumerDebtsInputState[index]
        );
        errorsArePresent = true;
      }
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: {
          ...state.input,
          consumer: newConsumerInputState,
          consumerDebts: newConsumerDebtsInputState,
        },
      }));
    }
  };

  checkFormFieldObjectForErrors = (debtObject, errorsArePresent) => {
    Object.entries(debtObject).forEach(([key, value]) => {
      if (key === "creditor_id") {
        if (!value.value) {
          errorsArePresent = true;
        }
      }
      if (key === "created_at" && debtObject.id.value === "") {
        if (!isValidDate(debtObject.created_at.value)) {
          errorsArePresent = true;
        } else if (new Date(debtObject.created_at.value) >= new Date()) {
          errorsArePresent = true;
        }
      }

      if (debtObject.id.value === "" || debtObject.status.value === "open") {
        if (
          getStrippedDollarAmount(
            debtObject.total_in_dollars_as_currency.value
          ) <
          getStrippedDollarAmount(debtObject.initial_payment.value) +
            getStrippedDollarAmount(debtObject.adjustment.value)
        ) {
          debtObject["total_in_dollars_as_currency"].hasError = true;
          errorsArePresent = true;
        }
      }
    });

    return errorsArePresent;
  };

  getUpdatedDebtsInputObject = (inputFieldObject) => {
    Object.entries(inputFieldObject).forEach(([key, value]) => {
      if (key === "creditor_id") {
        if (!value.value) {
          inputFieldObject[key].hasError = true;
        }
      }

      if (key === "created_at") {
        if (!value.value) {
          inputFieldObject[key].hasError = true;
        }
      }

      if (key === "total_in_dollars_as_currency") {
        if (!value.value) {
          inputFieldObject[key].hasError = true;
        }
      }
    });

    return inputFieldObject;
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "date_of_birth") {
      message = "Please enter a valid date";
    } else if (input === "email") {
      message = "Please enter a valid email";
    } else if (input === "first_name") {
      message = "Please enter first name";
    } else if (input === "last_name") {
      message = "Please enter last name";
    } else if (input === "phone_number") {
      message = "Please enter valid phone number";
    } else if (input === "mobile") {
      message = "Please enter valid mobile number";
    } else if (input === "creditor_id") {
      message = "Please select a creditor";
    } else if (input === "total_in_dollars_as_currency") {
      message = "Please enter valid debt amount";
    } else if (input === "created_at") {
      message = "Please enter a open date";
    } else if (input === "display_name") {
      message = "Please enter display name";
    } else {
      message = "Please complete this field";
    }

    return <div className="input-error-message">{message}</div>;
  }

  render() {
    let { isLoading, creditors, input, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div
        className="scrollbar"
        style={{
          height: window.innerHeight * 0.65,
          overflow: "auto",
          paddingBottom: 100,
        }}
      >
        <form
          className="admin-form add-edit-account-form"
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <Panel heading="Customer's Information">
            <div className="admin-form-row">
              <div className="input-container one-third">
                <label htmlFor="name">First Name</label>
                <input
                  name="name"
                  type="text"
                  placeholder="First Name"
                  value={input.consumer.first_name.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      first_name: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div className="input-container one-third">
                <label htmlFor="name">Middle Name</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Middle Name"
                  value={input.consumer.middle_name.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      middle_name: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div className="input-container one-third">
                <label htmlFor="name">Last Name</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Last Name"
                  value={input.consumer.last_name.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      last_name: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
            </div>
            <div className="admin-form-row">
              <div className={`input-container one-third`}>
                <label htmlFor="birthdate">Company Name</label>
                <input
                  name="Company Name"
                  type="text"
                  placeholder="Company Name"
                  value={input.consumer.company_name.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      company_name: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.email.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="email">Primary Email Address</label>
                <input
                  name="email"
                  type="email"
                  placeholder="address@email.com"
                  value={input.consumer.email.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      email: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.consumer.email.hasError
                  ? this.renderErrorMessage("email")
                  : null}
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.phone_number.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="phone">Primary Phone Number</label>
                <MaskedTextInput
                  name="phone"
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="(555) 555-5555"
                  placeholderChar={"\u2000"}
                  guide={true}
                  type="text"
                  value={input.consumer.phone_number.value}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      phone_number: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.consumer.phone_number.hasError
                  ? this.renderErrorMessage("phone_number")
                  : null}
              </div>
            </div>
            <div className="admin-form-row">
              <div className="input-container one-third">
                <label htmlFor="name">Title</label>
                <input
                  name="title"
                  type="text"
                  placeholder="Title"
                  value={input.consumer.title.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      title: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.display_name.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="email">Display Name</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Display Name"
                  value={input.consumer.display_name.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      display_name: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.consumer.display_name.hasError
                  ? this.renderErrorMessage("display_name")
                  : null}
              </div>
              <div className={`input-container one-third`}>
                <label htmlFor="phone">Suffix</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Suffix"
                  value={input.consumer.suffix.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      suffix: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
            </div>
            <div className="admin-form-row">
              <div className="input-container one-third">
                <label htmlFor="name">Fax Number</label>
                <input
                  name="name"
                  type="text"
                  placeholder=""
                  value={input.consumer.fax_number.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      fax_number: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.secondary_email.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="email">Secondary Email Address</label>
                <input
                  name="email"
                  type="email"
                  placeholder="address@email.com"
                  value={input.consumer.secondary_email.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      secondary_email: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.consumer.secondary_email.hasError
                  ? this.renderErrorMessage("secondary_email")
                  : null}
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.mobile.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="phone">Mobile</label>
                <MaskedTextInput
                  name="phone"
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="(555) 555-5555"
                  placeholderChar={"\u2000"}
                  guide={true}
                  type="text"
                  value={input.consumer.mobile.value}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      mobile: { value: event.target.value, hasError: false },
                    })
                  }
                />
                {input.consumer.mobile.hasError
                  ? this.renderErrorMessage("mobile")
                  : null}
              </div>
            </div>
            <div className="admin-form-row">
              <div
                className={`input-container half${
                  input.consumer.alternate_phone_number.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="birthdate">Alternative Phone Number</label>
                <MaskedTextInput
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="(555) 555-5555"
                  placeholderChar={"\u2000"}
                  guide={true}
                  type="text"
                  value={input.consumer.alternate_phone_number.value}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      alternate_phone_number: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.consumer.alternate_phone_number.hasError
                  ? this.renderErrorMessage("phone_number")
                  : null}
              </div>
              <div
                className={`input-container half${
                  input.consumer.website.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="ssn">Website</label>
                <input
                  name="website"
                  type="url"
                  placeholder="website"
                  value={input.consumer.website.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      website: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
            </div>
            <div className="admin-form-row">
              <div
                className={`input-container half${
                  input.consumer.date_of_birth.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="birthdate">Consumer's Date Of Birth</label>
                <MaskedTextInput
                  guide={true}
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  name="birthday"
                  placeholder="mm/dd/yyyy"
                  placeholderChar={"\u2000"}
                  type="text"
                  value={input.consumer.date_of_birth.value}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      date_of_birth: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
                {input.consumer.date_of_birth.hasError
                  ? this.renderErrorMessage("date_of_birth")
                  : null}
              </div>
              <div
                className={`input-container half${
                  input.consumer.ssn.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="ssn">Consumer's Last Four of SSN</label>
                <MaskedTextInput
                  guide={true}
                  mask={[/\d/, /\d/, /\d/, /\d/]}
                  name="ssn"
                  placeholder="####"
                  placeholderChar={"\u2000"}
                  type="text"
                  value={input.consumer.ssn.value}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      ssn: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
            </div>
            <div className="admin-form-row">
              <div
                className={`input-container two-thirds${
                  input.consumer.street_address_primary.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="streetAddress">Street Address</label>
                <input
                  name="streetAddress"
                  type="text"
                  placeholder="Street Address"
                  value={input.consumer.street_address_primary.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      street_address_primary: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.street_address_secondary.hasError
                    ? " error"
                    : ""
                }`}
              >
                <label htmlFor="unit">Unit/Apt #</label>
                <input
                  name="unit"
                  type="text"
                  placeholder="Apt. #"
                  value={input.consumer.street_address_secondary.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      street_address_secondary: {
                        value: event.target.value,
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="admin-form-row">
              <div
                className={`input-container one-third${
                  input.consumer.city.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="city">City</label>
                <input
                  name="city"
                  type="text"
                  placeholder="Anywhere"
                  value={input.consumer.city.value || ""}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      city: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.state.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="state">State</label>
                <Select
                  inputProps={{
                    autoComplete: "none",
                    autoCorrect: "off",
                    spellCheck: "off",
                  }}
                  className="form-select"
                  name="state"
                  value={input.consumer.state.value}
                  placeholder="Select a State"
                  options={stateDataAbbreviations}
                  resetValue=""
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      state: {
                        value: event ? event.value : "",
                        hasError: false,
                      },
                    })
                  }
                />
              </div>
              <div
                className={`input-container one-third${
                  input.consumer.zipcode.hasError ? " error" : ""
                }`}
              >
                <label htmlFor="zip">Zip Code</label>
                <MaskedTextInput
                  guide={true}
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                  name="zip"
                  placeholder="90210"
                  placeholderChar={"\u2000"}
                  type="text"
                  value={input.consumer.zipcode.value}
                  onChange={(event) =>
                    this.handleConsumerInputChange({
                      zipcode: { value: event.target.value, hasError: false },
                    })
                  }
                />
              </div>
            </div>
          </Panel>

          <Panel heading="Add New Or Modifiy Pending Debt">
            {input.consumerDebts.map((debt, index) => {
              let isDisabled =
                debt.status.value != "open" &&
                debt.status.value != null &&
                debt.id.value != ""
                  ? true
                  : false;
              return (
                <div className="add-edit-account-form-creditor" key={index}>
                  <fieldset disabled={isDisabled}>
                    <div className="admin-form-row">
                      <div
                        className={`input-container one-third${
                          debt.creditor_id.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="creditorName" style={{ width: `50%` }}>
                          Creditor's Name
                        </label>
                        <a
                          className="text-link"
                          onClick={() => this.addNewCreditor()}
                        >
                          {isDisabled ? null : "+Add Creditor"}
                        </a>
                        <Select
                          inputProps={{
                            autoComplete: "none",
                            autoCorrect: "off",
                            spellCheck: "off",
                          }}
                          className="form-select"
                          name="creditorName"
                          value={debt.creditor_id.value}
                          placeholder="Select a Creditor"
                          options={creditors}
                          resetValue=""
                          onChange={(event) =>
                            this.handleDebtInputChange(
                              {
                                creditor_id: {
                                  value: event ? event.value : "",
                                  hasError: false,
                                },
                              },
                              index
                            )
                          }
                          disabled={isDisabled}
                        />
                        {debt.creditor_id.hasError
                          ? this.renderErrorMessage("creditor_id")
                          : null}
                      </div>
                      <div
                        className={`input-container one-third${
                          debt.total_in_dollars_as_currency.hasError
                            ? " error"
                            : ""
                        }`}
                      >
                        <label htmlFor="creditorTotalDebt">Charges</label>
                        <MaskedTextInput
                          guide={true}
                          mask={numberMask}
                          name="creditorTotalDebt"
                          placeholder="$0"
                          placeholderChar={"\u2000"}
                          type="text"
                          value={debt.total_in_dollars_as_currency.value}
                          onChange={(event) =>
                            this.handleDebtInputChange(
                              {
                                total_in_dollars_as_currency: {
                                  value: event.target.value,
                                  hasError: false,
                                  previousTotal:
                                    debt.total_in_dollars_as_currency
                                      .previousTotal,
                                },
                              },
                              index
                            )
                          }
                        />
                        {debt.total_in_dollars_as_currency.hasError
                          ? this.renderErrorMessage(
                              "total_in_dollars_as_currency"
                            )
                          : null}
                      </div>
                      <div
                        className={`input-container one-third${
                          debt.created_at.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="openDate">Date of Origination</label>
                        <DatePicker
                          selected={
                            debt.created_at.value
                              ? moment(debt.created_at.value)
                              : ""
                          }
                          onChange={(date) =>
                            this.handleDebtInputChange(
                              {
                                created_at: {
                                  value: moment(date).format("MM/DD/YYYY"),
                                  hasError: false,
                                },
                              },
                              index
                            )
                          }
                          placeholderText="mm/dd/yyyy"
                          maxDate={moment()}
                          disabled={debt.created_at.value ? isDisabled : false}
                        />
                        {debt.created_at.hasError
                          ? this.renderErrorMessage("created_at")
                          : null}
                      </div>
                    </div>
                    <div className="admin-form-row">
                      <div
                        className={`input-container one-third${
                          debt.initial_payment.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="initial_payment">Payments</label>
                        <MaskedTextInput
                          guide={true}
                          mask={numberMask}
                          name="initial_payment"
                          placeholder="$0"
                          placeholderChar={"\u2000"}
                          type="text"
                          value={debt.initial_payment.value}
                          onChange={(event) =>
                            this.handleDebtInputChange(
                              {
                                initial_payment: {
                                  value: event.target.value,
                                  hasError: false,
                                  previousPayment:
                                    debt.initial_payment.previousPayment,
                                },
                              },
                              index
                            )
                          }
                        />
                        {debt.initial_payment.hasError
                          ? this.renderErrorMessage("initial_payment")
                          : null}
                      </div>
                      <div
                        className={`input-container one-third${
                          debt.adjustment.hasError ? " error" : ""
                        }`}
                      >
                        <label htmlFor="adjustment">Discount</label>
                        <MaskedTextInput
                          guide={true}
                          mask={numberMask}
                          name="adjustment"
                          placeholder="$0"
                          placeholderChar={"\u2000"}
                          type="text"
                          value={debt.adjustment.value}
                          onChange={(event) =>
                            this.handleDebtInputChange(
                              {
                                adjustment: {
                                  value: event.target.value,
                                  hasError: false,
                                  previousAdjustment:
                                    debt.adjustment.previousAdjustment,
                                },
                              },
                              index
                            )
                          }
                        />
                        {debt.adjustment.hasError
                          ? this.renderErrorMessage("adjustment")
                          : null}
                      </div>
                      <div className="input-container one-third">
                        <label htmlFor="creditorAdditionalDetails">
                          Additional Details
                        </label>
                        <TextareaAutosize
                          name="creditorAdditionalDetails"
                          type="textarea"
                          placeholder="Your message here..."
                          value={
                            debt.additional_details.value
                              ? debt.additional_details.value
                              : ""
                          }
                          onChange={(event) =>
                            this.handleDebtInputChange(
                              {
                                additional_details: {
                                  value: event.target.value,
                                  hasError: false,
                                },
                              },
                              index
                            )
                          }
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              );
            })}
            <a className="text-link" onClick={() => this.addCreditorInputSet()}>
              +Add Additional Debt
            </a>
          </Panel>
          <input type="submit" value="Submit" className="cta" />
        </form>
      </div>
    );
  }
}

export default withRouter(ClientsUpdateConsumerForm);
