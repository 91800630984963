import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// Components
import Modal from '../Modal';


class AdminOfferAcceptedAndSettledModal extends Component {

  render() {

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="user-account-payment-settled"
        closeOnClickOutside={false}
        modalExit={false}
      >
        <div className="modal-spotlight-illustration"></div>
        <h1 className="modal-heading">
          User account is now settled!
        </h1>
        <p>
          You have received his payment, and his account is now settled!
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.props.hideModal}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default withRouter(AdminOfferAcceptedAndSettledModal);
