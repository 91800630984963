import React, { Component } from 'react'

// Packages
import MaskedTextInput from 'react-text-mask'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// Components
import Panel from '../../../App/Panel'
import ConsumerPaymentCalculator from '../../ConsumerPaymentCalculator'

// Utils
import { isValidDate, getStrippedDollarAmount, numberMask } from '../../../../utils/helpers'

class ConsumerAccountsOneTimePaymentForm extends Component {
  state = {
    input: {
      paymentAmount: {
        value: '',
        hasError: false
      },
      paymentDate: {
        value: moment(),
        hasError: false
      },
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      }
    }))
  }

  handlePaymentDateChange = (date) => {
    let newPaymentDate = {
      paymentDate: {
        value: date,
        hasError: false
      }
    }

    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPaymentDate,
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.validateForm()) {
      let { input } = this.state

      this.props.handleContinue(event, 2, input, 'one-time-payment')
    } else {
      this.setFormErrorState()
    }
  }

  validateForm = () => {
    let newInputState = { ...this.state.input }
    let originalAmount = this.props.originalCollectionAmount;
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'paymentDate') {
        if (!isValidDate(value.value.format('MM/DD/YYYY'))) {
          errorsArePresent = true
        }
      } else if (key === 'paymentAmount') {
        if (value.value === '' || (getStrippedDollarAmount(value.value) < 1) ||
          originalAmount < getStrippedDollarAmount(value.value)) {
          errorsArePresent = true
        }
      } else if (value.value === '') {
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      return false
    } else {
      return true
    }
  }

  setFormErrorState = () => {
    let newInputState = { ...this.state.input }
    let originalAmount = this.props.originalCollectionAmount;
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'paymentDate') {
        if (!isValidDate(value.value.format('MM/DD/YYYY'))) {
          newInputState[key].hasError = true
        }
      } else if (key === 'paymentAmount') {
        if (value.value === '' || (getStrippedDollarAmount(value.value) < 1) ||
          originalAmount < getStrippedDollarAmount(value.value)) {
          newInputState[key].hasError = true
        }
      } else if (value.value === '') {
        newInputState[key].hasError = true
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    }
  }

  renderErrorMessage(input) {
    let message = ''

    if (input === 'paymentAmount') {
      message = 'Please enter valid amount'
    } else {
      message = 'Please enter a valid date'
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { input } = this.state

    return (
      <div
        className="consumer-accounts-payment-form"      >
        <p className="consumer-accounts-payment-form-description">
          Submit an amount you’re willing to pay at a one-time fixed amount. If accepted, you will be required to enter payment information immediately.
        </p>
        <Panel>
          <div className="admin-form-row">
            <div
              className={`input-container${input.paymentAmount.hasError ? ' error' : ''}`}
            >
              <label htmlFor="name">Total Amount</label>
              <MaskedTextInput
                guide={true}
                mask={numberMask}
                name="paymentAmount"
                placeholder="$0"
                placeholderChar={'\u2000'}
                type="text"
                value={input.paymentAmount.value}
                onChange={event => this.handleInputChange(
                  { paymentAmount: { value: event.target.value, hasError: false } }
                )}
              />
              {input.paymentAmount.hasError ? this.renderErrorMessage('paymentAmount') : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container${input.paymentDate.hasError ? ' error' : ''}`}
            >
              <label>Date of Payment (must be within 30 days)</label>
              <DatePicker
                selected={input.paymentDate.value}
                readOnly={true}
                onChange={this.handlePaymentDateChange}
                minDate={moment()}
                maxDate={moment().add(30, "days")}
                placeholderText="Select a date"
              />
              {input.paymentDate.hasError ? this.renderErrorMessage('paymentDate') : null}
            </div>
          </div>
        </Panel>
        <ConsumerPaymentCalculator
          message="Total to be paid on"
          date={input.paymentDate.value.format('MM/DD/YYYY')}
          dollarAmount={getStrippedDollarAmount(input.paymentAmount.value)}
        />
        <a
          className="cta payment-continue-btn"
          onClick={(event) => this.handleSubmit(event)}
        >
          Continue to payment information »
        </a>
      </div>
    )
  }
}

export default ConsumerAccountsOneTimePaymentForm
