import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';

// API
import * as API from '../../utils/api';

import { networkMessage, showToast } from '../../utils/helpers'

// Components
import AppSectionHeader from '../../components/App/AppSectionHeader';
import AppCanvasLoader from '../../components/App/AppCanvasLoader';
import SuperAdminUpdateOrgForm from '../../components/Admin/Forms/SuperAdminUpdateOrgForm';

class SuperAdminAccountAddEditView extends Component {
  state = {
    organization: {},
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    const orgAccountId = this.props.match.params.id;
    API.fetchOrganizationDetails(this.props.user, orgAccountId).then(data => {
     if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        this.setState(state => ({
            ...state,
            user: this.props.user,
            organization: data.data ? data.data : '',
            isLoading: false
          }))
      } else {
        this.setState(state => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
          },
          isLoading: false
        }))
      }
    });
  }

  render() {
    const { appName } = this.props;
    const { organization, isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }


    return isLoading
      ? (
        <AppCanvasLoader />
      )
      : (
        <div>
          <Helmet>
            <title>{appName} | {organization.name}</title>
          </Helmet>
          <AppSectionHeader
            title="Edit Account"
            optionalClassNames="secondary"
            backLink={{
              text: `Cancel and Return to Account`,
              path: `/super-admin/accounts/${organization.id}`
            }}
          />
          <SuperAdminUpdateOrgForm
            organization={organization}
            user={this.props.user}
            hideModal={this.props.hideModal}
            updateModalState={this.props.updateModalState}
          />
        </div>
      );
  }
}

export default SuperAdminAccountAddEditView;
