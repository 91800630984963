import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import Logo from "../../components/Logo";

class StripePaymentSuccess extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
      setTimeout(() => {
         this.onContinueClick(); 
      }, 1000);
  }

  onContinueClick = () => {
    let { history } = this.props;
    history.push(`/client/settings`, { openPayment: true });
  };

  render() {
    const { appName } = this.props;

    if (!this.props.isLoggedIn || !this.props.user.client_admin) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName} | Success</title>
        </Helmet>
        <div className={`sessions-modal modal-space`}>
          <div className="sessions-modal-logo-container centerd-content">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <h1 className="sessions-modal-heading qb-heading-success">
              Payment account created successfully
            </h1>
            <p className="qb-msg-text">
              {/* The authentication process with QuickBooks Online has completed
              successfully. */}
            </p>
          </div>
          <div className="modal-actions qb-btns">
            <a
              className="cta"
              onClick={() => {
                this.onContinueClick();
              }}
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default StripePaymentSuccess;
