import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import styles from "./reports.module.scss";

class ClientsInventoryConsumptionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalItems: 0,
    };
    this.overAllTotal = [];
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.totalItems != this.state.totalItems) {
      this.setState({ data: this.getReportData(nextProps.data) });
    }
  }

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };

  getReportData = (data) => {
    return {
      headings: ["Item Name", "Invoice Number", "Invoice Date", "Quantity"],
      columns: data,
    };
  };

  getTableRows = (item) => {
    return [
      {
        title: item.item_name,
      },
      {
        title: item.invoice_number,
      },
      {
        title: this.getDate(item.invoice_date),
      },
      {
        title: item.quantity,
      },
    ];
  };

  getTotal = (title, e, total) => {
    total[0] = "Total " + title + " Usage";
    total[1] = "";
    total[2] = "";
    total[3] = Number(total[3])
      ? Number(total[3]) + Number(e.quantity)
      : Number(e.quantity);
    return total;
  };

  renderTableBody = (data) => {
    return (
      data &&
      data.map((item) => {
        let total = [];
        return (
          <tbody key={shortid.generate()}>
            {item.list && item.list.length ? (
              item.list.map((d, i) => {
                let rows = this.getTableRows(d);
                total = this.getTotal(item.item_name, d, total);
                return (
                  <tr
                    key={shortid.generate()}
                    className={`row-total ${styles.rowData}`}
                  >
                    {rows &&
                      rows.map((col) => {
                        return (
                          <td
                            key={shortid.generate()}
                            className={`${styles.rowData}`}
                          >
                            {col.title}
                          </td>
                        );
                      })}
                  </tr>
                );
              })
            ) : (
              <tr className='empty-row' />
            )}
            {total && total.length ? (
              <tr
                className={`row-total ${styles.rowData}`}
                style={{ backgroundColor: "#008BB2", color: "#FFF" }}
              >
                {total.map((col, i) => {
                  return (
                    <td
                      key={shortid.generate()}
                      className={`${styles.totalData} total-sub-header`}
                    >
                      {i === 0 ? col : col}
                    </td>
                  );
                })}
              </tr>
            ) : (
              <tr className='empty-row' />
            )}
          </tbody>
        );
      })
    );
  };

  render() {
    let { data } = this.state;
    return (
      <Table hover className='inventory-consumption-report' striped>
        <thead className={styles.headerFix}>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return (
                  <th
                    key={shortid.generate()}
                    className={styles.headingStyle}
                    style={{ borderTop: "none" }}
                  >
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        <div className='inventory-consumption-report'>
          {this.renderTableBody(data.columns)}
        </div>
      </Table>
    );
  }
}

export default ClientsInventoryConsumptionReport;
