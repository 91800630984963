import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

class ConfirmActionModal extends Component {
  handleClickOutside = () => {
    if (this.props.optionalProps.closeOnClickOutside) {
      this.props.hideModal();
    }
  };

  closeModal = (event) => {
        event.preventDefault();
        this.props.hideModal();
  };

  render() {
    let { message, title, methodData, isCancelCallback } = this.props.optionalProps;
    return (
      <div className="confirm-modal" >
          <div className="confirm-action-heading" style={{display: "inline-block"}}>Select Action <div className="confirm-action-close-btns"><a className="modal-close" style={{height: "1rem"}} onClick={(event) => this.closeModal(event)}>
              <svg className="icon">
                  <use xlinkHref="#close" />
              </svg>
          </a></div></div>

          <div className="confirm-action-btns">
            <div className="btn-wrapper">
                    <button
                        className="yes-btn action-btn"
                        onClick={(event) => {
                            this.props.optionalProps.confirmClick(methodData);
                            this.props.hideModal();
                        }}
                    >
                        {this.props.optionalProps.positiveBTN}
                    </button>
            </div>
            <div className="btn-wrapper">
                    <button className="cancel-btn action-btn" onClick={() => {
                        this.props.optionalProps.cancelClick(methodData);
                        this.props.hideModal();
                    }}>
                        {this.props.optionalProps.negativeBTN}
                    </button>
            </div>
          </div>
        {/* <div className="modal-msg">{message}</div>
        <div className="confirm-action-btns">
          <button
            className="yes-btn"
            onClick={(event) => {
              this.props.optionalProps.confirmClick(methodData);
              this.props.hideModal();
            }}
          >
            {this.props.optionalProps.positiveBTN}
          </button>
          {isCancelCallback
            ? <button className="cancel-btn" onClick={() => {
              this.props.hideModal
              this.props.optionalProps.cancelClick(methodData);
              this.props.hideModal();
            }}>
              {this.props.optionalProps.negativeBTN}
          </button> 
          : <button className="cancel-btn" onClick={this.props.hideModal}>
            NO
        </button> }
        </div> */}
      </div>
    );
  }
}

export default onClickOutside(ConfirmActionModal);
