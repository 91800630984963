import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Packages
import shortid from 'shortid';

// Components
import ObjectRowAdminAccount from './ObjectRowAdminAccount';
import ObjectListHeaderAdminAccount from './ObjectListHeaderAdminAccount';
import AppCanvasLoader from '../App/AppCanvasLoader';

class ObjectListAdminAccount extends Component {
  constructor() {
    super();
    this.state = {
      fromChild: '',
      checkeObjList: []
    };
  }
  static propTypes = {
    data: PropTypes.object.isRequired,
    appName: PropTypes.string
  }

  renderOptionalColumnHeadings(headings, isAllSelected, hideCheckBox) {
    if (headings && headings.length > 0) {
      return (
        <ObjectListHeaderAdminAccount
          headings={headings}
          hideCheckBox={hideCheckBox}
          isAllSelected={isAllSelected}
          handleCheckboxClick={(event) => this.props.handleHeaderCheckboxClick(event)}
        />
      )
    }
  }

  render() {
    let { data, rowActionClick, optionalClasses, rowCheckboxActionClick, hideCheckBox } = this.props;
    return (
      <div className={`object-list${optionalClasses ? ' ' + optionalClasses : ''}`}>
        {this.renderOptionalColumnHeadings(data.headings, data.isAllSelected, hideCheckBox)}
        {
          <div ref={this.props.scrollRef} className="scrollbar" style={{ height: window.innerHeight * 0.65, paddingBottom: 50 }}> {
            data.columns.map((row) => {
              return (
                <ObjectRowAdminAccount
                  key={shortid.generate()}
                  hideCheckBox={hideCheckBox}
                  rowData={row}
                  appName={this.props.appName}
                  checkeObjList={this.state.checkeObjList}
                  handleRowActionClick={(event) => rowActionClick(event)}
                  action={(event) => this.props.rowCheckListHandler(event)}
                />
              )
            })
          }
          </div>
        }
      </div>
    );
  }
}

export default ObjectListAdminAccount;
