import moment from "moment";
import React, { Component } from "react";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import viewIcon from "../../../assets/icons/view.png";
import downloadImg from "../../../assets/icons/download.png";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";

import ConsumerSidebar from "../../../components/Consumer/ConsumerSidebar";
import * as API from "../../../utils/api";
import shortid from "shortid";
import {
  displayPlanStatus,
  getZohoPagesense,
  getDollarAmount,
  getCalculatedPlanAmount,
} from "../../../utils/helpers";
import CardView from "../../../components/CardView/CardView";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import HistoryObjectList from "../../../components/ObjectList/ClientsAccounts/HistoryObjectList";

class ConsumerPaymentPlanDetails extends Component {
  state = {
    paymentPlanDetails: [],
    showPaymentScreen: false,
    planInvoicesData: [],
    isLoading: true,
    textColor: "",
    expandDoc: false,
    showLoader: false,
    paymentApplied: {},
    paymentSummary: "",
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    appThemeData: {},
    planList: {},
  };

  getLogoColorsForPayment() {
    this.setState({ isLoading: true });
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
        });
      })
      .catch(() => {});
    // this.setState({ isLoading: false });
  }

  handleHeaderExpander = (event, type) => {
    this.setState({ expandDoc: !this.state.expandDoc });
  };
  getPaidAmount = (value) => {
    let temp = value;
    let amount = 0.0;
    if (value) {
      if (typeof value === "string") {
        amount = parseFloat(temp.replace(",", ""));
      } else if (typeof value === "string") {
        amount = parseFloat(temp);
      }
    }
    return amount.toFixed(2);
  };

  componentDidMount() {
    this.getLogoColorsForPayment();
    // Set Previous Path for use with App Header's "Back" Link
    this.props.updatePrevPath("/dashboard/accounts/payment-plans");
    getZohoPagesense();
    // ReactGA.pageview("/ConsumerAccountsView");
    this.getPlanDetails();
  }
  renderLatestPaymentDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  getPlanInvoiceList = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Invoice #",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Invoice Date",
        style: {
          width: "15%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Due Date",
        style: {
          width: "15%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Total",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        name: "Balance",
        style: {
          width: "20%",
          display: "flex",
          justifyContent: "center",
        },
      },
    ];

    columns = data.map((row, index) => {
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Invoice #",
            title: row.invoice_number ? row.invoice_number : "n/a",
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Invoice Date",
            title: row.txn_date && moment(row.txn_date).format("MM/DD/YYYY"),
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Due Date",
            title: row.due_date && moment(row.due_date).format("MM/DD/YYYY"),
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Total",
            title: row.total_amount && row.total_amount,
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Balance",
            title: getDollarAmount(row.balance),
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "center",
            },
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderPlanInvoices = () => {
    const { planInvoicesData } = this.state;
    if (planInvoicesData && planInvoicesData.length > 0) {
      return (
        <div>
          <div className='invoice-line-header'>{"Invoice Details"}</div>
          {
            <div>
              <CardView data={this.getPlanInvoiceList(planInvoicesData)} />
              <InvoiceObjectList
                data={this.getPlanInvoiceList(planInvoicesData)}
                hideForMobile={true}
                optionalTableClasses={"client-inv-line"}
              />
            </div>
          }
        </div>
      );
    } else {
      return <></>;
    }
  };

  getPlanDetails = () => {
    const invoiceId = this.props.match.params.id;
    API.getConsumerPaymentPlanDetails(this.props.user, invoiceId).then(
      (data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.data != null &&
          data.status_code == 200
        ) {
          let dummyData = data.data;

          if (data.data.get_payment_plan_invoices.length) {
            this.setState({
              planInvoicesData: data.data.get_payment_plan_invoices,
            });
          }
          this.setState({ paymentPlanDetails: dummyData }, () => {
            let payeeAmt = 0;
            let list = dummyData.payment_plans_payments.map((item, index) => {
              item.paymentNumber = index + 1;

              item.dueDate = item.due_date;
              item.paidDate = item.paid_date;
              item.paymentAmt = item.payment_amount;
              item.paid_amt = item.paid_amount ? item.paid_amount : 0;
              payeeAmt = item.paid_amt
                ? parseFloat(item.paid_amt.replace(",", "")) + payeeAmt
                : payeeAmt;
              return item;
            });
            let temp = [
              {
                planAmt: getCalculatedPlanAmount(
                  dummyData.total_in_dollars_as_currency,
                  dummyData.adjustment_amount,
                  dummyData.is_credit_memo_applied,
                  dummyData.status,
                  "Plan_amt"
                ),
                noOfMonths: dummyData.number_of_payments,
                paymentAmt: dummyData.monthly_payment_amount_as_currency,
                firstPaymentDate: dummyData.start_date,
                finalPaymentDate:
                  dummyData.payment_plans_payments[
                    dummyData.payment_plans_payments.length - 1
                  ].due_date,
              },
            ];

            this.setState({
              paymentApplied: payeeAmt,
              paymentSummary: temp,
              planList: list,
              isLoading: false,
            });
          });
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      }
    );
  };
  downloadAttachedDocuement = (doc_id, fileName) => {
    const { user } = this.props;
    API.getDownloadConsumerPaymentPlan(
      user,
      doc_id,
      "payment_plan_document"
    ).then((data) => {
      if (data && data.status_code && data.status_code == 200) {
        if (data.data) {
          let flag = data.data.includes("base64");
          if (!flag) {
            data.data = "data:application/pdf;base64," + data.data;
          }
          let url = data.data;
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  };

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    return "Welcome, " + fullname + "!";
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };
  viewValidationDoc = (row) => {
    let props = {
      // image: row.document,
      type: row.document_name,
      doc_name: row.document_name,
      doc_id: row.id,
      arrange_pdf: true,
      user: this.props.user,

      isConsumerPayment: true,
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };
  getAccountType = () => {
    const { invoiceDetails } = this.state;
    let flag = this.checkInvoicePastDue(invoiceDetails.due_date);
    if (flag) {
      return "past due";
    } else {
      return "current";
    }
  };

  handlePayNow = () => {
    let { history } = this.props;
    const { paymentPlanDetails } = this.state;
    return history.push(
      `/dashboard/accounts/pay-payment-plans/${paymentPlanDetails.id}`
    );
  };
  displayPlanStatusText(status) {
    if (status === "offer") {
      return 'in "Offer" status.';
    } else if (status === "active") {
      return "Active.";
    } else if (status === "inactive") {
      return "Inactive.";
    } else if (status === "offer_sent") {
      return 'in "Offer Sent" status.';
    } else if (status === "closed") {
      return "Closed.";
    } else if (status === "paid") {
      return "paid.";
    } else if (status === "due") {
      return "due.";
    } else if (status === "not_due") {
      return "not due.";
    } else if (status === "past_due") {
      return "past due.";
    } else if (status === "on_time") {
      return "On Time.";
    } else if (status === "partially_paid") {
      return "partially paid.";
    } else {
      return "Unknown";
    }
  }
  sidebarContent = () => {
    const { paymentPlanDetails } = this.state;
    return (
      <div className='consumer-sidebar'>
        <div
          className='sidebar-primary-text'
          style={{ marginBottom: "2em", display: "flex", color: "#FFFFFF" }}
        >
          This payment plan is{" "}
          {this.displayPlanStatusText(paymentPlanDetails.status)}
        </div>
        <label style={{ marginBottom: "0.3em", color: "#FFFFFF" }}>
          Balance Due
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {paymentPlanDetails.total_in_dollars_as_currency
            ? currencyFormatter.format(paymentPlanDetails.balance_as_currency, {
                code: "USD",
              })
            : "0.00"}
        </div>
      </div>
    );
  };
  getClickableHeader = (header, type, data) => {
    let { expandDoc, expandPayment } = this.state;
    let count = data && data.length ? data.length : 0;
    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='in-new-btn'>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>{header}</div>
          {/* <a
            key={shortid.generate()}
            className={`client-caret expander-caret${
              flag ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.handleHeaderExpander(event, type)}
          /> */}
        </div>
      </div>
    );
  };

  getHeaderUtilsForDocument = (header, type, data, count) => {
    let { expandDoc, expandPayment } = this.state;

    let flag =
      expandDoc && type === "Document"
        ? true
        : expandPayment && type === "Payment"
        ? true
        : false;
    return (
      <div className='in-new-btn'>
        <div className='ic-wt-fields'>
          <div className='he-hed he-sec-hed'>
            {header} {` ( ${count} )`}
          </div>
          <a
            key={shortid.generate()}
            className={`client-caret expander-caret${
              flag ? " down-caret" : ""
            }`}
            style={{ margin: "12px 0px 0px 12px" }}
            onClick={(event) => this.handleHeaderExpander(event, type)}
          />
        </div>
      </div>
    );
  };

  renderPaymentAndDoc = (data, header, type, emptyMsg) => {
    let newData = [];
    newData = data;
    return (
      <div>
        {type === "Document"
          ? this.getHeaderUtilsForDocument(header, type, newData)
          : this.getClickableHeader(header, type, newData)}
        <div style={{ maxHeight: "30em", overflowY: "auto" }}>
          {data && data.length ? (
            <div>
              <CardView data={this.getObjectListData(newData, type)} />
              <HistoryObjectList
                data={this.getObjectListData(newData, type)}
                hideForMobile={true}
                optionalTableClasses={type === "Document" ? "invoice-doc" : ""}
                optionalClasses='mg-row-pl-15'
              />
            </div>
          ) : (
            this.renderEmpty(emptyMsg)
          )}
        </div>
      </div>
    );
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem", backgroundColor: "#FFF" }}
      >
        <p>{msg}</p>
      </div>
    );
  };
  getObjectListDataForAdditionalInformation = (data, type) => {
    let { paymentPlanDetails } = this.state;
    let headings = [],
      columns = [];
    headings = ["Plan Create Date", "Plan Status"];
    columns = [
      {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Plan Create Date",
            title: this.renderLatestPaymentDate(paymentPlanDetails.created_at),
          },
          {
            key: "Plan Status",
            title: displayPlanStatus(paymentPlanDetails.status),
          },
        ],
      },
    ];

    return {
      headings,
      columns,
    };
  };
  showTotalsDetails = (data) => {
    let filterdData = [];
    let { paymentPlanDetails, paymentApplied } = this.state;
    filterdData.push({
      key: "Original Balance",
      value: `$ ${getCalculatedPlanAmount(
        paymentPlanDetails.total_in_dollars_as_currency,
        paymentPlanDetails.adjustment_amount,
        paymentPlanDetails.is_credit_memo_applied,
        paymentPlanDetails.status,
        "org_balance"
      )}`,
      flag: "",
    });
    filterdData.push({
      key: "Discounts Applied",
      value: `$ ${this.getPaidAmount(paymentPlanDetails.adjustment_amount)}`,
      flag: "",
    });

    filterdData.push({
      key: "Payment Applied",
      value: `$ ${parseFloat(paymentApplied).toFixed(2)}`,
      flag: "",
    });
    filterdData.push({
      key: "Outstanding Balance",
      value: `$ ${this.getPaidAmount(paymentPlanDetails.balance_as_currency)}`,
      flag: "",
    });

    return filterdData && filterdData.length ? (
      <div className={`panel tax-discount-content`}>
        {filterdData.map((item, index) => {
          return (
            <div
              key={index.toString()}
              className={`tax-details ${
                index + 1 === filterdData.length ? "no-border" : ""
              }`}
            >
              <div
                className={`tax-details-key tax-details-total highlighted-key"
              `}
              >
                {item.key}
              </div>
              <div className={`tax-details-value`}>
                <div>
                  {item.flag ? item.flag : ""}
                  {item.value}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  };
  documentAction = (row) => {
    let imgExtension = row.document_name && row.document_name.split(".").pop();
    let fileSizeCheck =
      row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    let flag =
      imgExtension &&
      (imgExtension.toLowerCase() === "xls" ||
        imgExtension.toLowerCase() === "xlsx" ||
        imgExtension.toLowerCase() === "doc" ||
        imgExtension.toLowerCase() === "docx")
        ? false
        : true;
    flag = fileSizeCheck ? false : flag;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a
          className='make-payment-button'
          style={{ cursor: "pointer" }}
          title='Download'
          onClick={() =>
            this.downloadAttachedDocuement(row.id, row.document_name)
          }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>

        {flag && (
          <button
            title='View'
            className='view-button'
            style={{
              background: "transparent",
              cursor: "pointer",
              marginLeft: "1rem",
            }}
            onClick={() => this.viewValidationDoc(row)}
          >
            <img src={viewIcon} width='16' height='16' />
          </button>
        )}
      </div>
    );
  };

  getObjectListData = (data, type) => {
    let headings = [],
      columns = [];
    headings =
      type === "Payment"
        ? [
            "Plan Amt",
            "# Wks/Months",
            "Pymt Amt",
            "First Pymt Due",
            "Final Pymt Date",
          ]
        : type === "Document"
        ? ["Name", "Description", "Type", "Action"]
        : type === "PaymentDetails"
        ? [
            "Payment Number",
            "Status",
            "Due Date",
            "Paid Date",
            "Payment Amt",
            "Paid Amt",
            "Balance",
          ]
        : type === "note"
        ? ["Name", "Date", "Note"]
        : [];

    columns =
      type === "Document"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.document_name,
                },
                {
                  key: "Description",
                  title: row.description,
                },
                {
                  key: "Type",
                  title: row.document_type ? row.document_type.name : "",
                },
                {
                  key: "Action",
                  title: this.documentAction(row),
                },
              ],
            };
          })
        : type === "Payment"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Plan Amt",
                  title: `$  ${this.getPaidAmount(row.planAmt)}`,
                },
                {
                  key: "# of Months / Weeks",
                  title: row.noOfMonths,
                },
                {
                  key: "Payment Amount",
                  title: `$ ${this.getPaidAmount(row.paymentAmt)}`,
                },
                {
                  key: "First Payment Due",
                  title: row.firstPaymentDate
                    ? this.renderLatestPaymentDate(row.firstPaymentDate)
                    : "--/--/----",
                },
                {
                  key: "Final Payment Date",
                  title: row.finalPaymentDate
                    ? this.renderLatestPaymentDate(row.finalPaymentDate)
                    : "--/--/----",
                },
              ],
            };
          })
        : type === "PaymentDetails"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Payment Number",
                  title: row.paymentNumber,
                },
                {
                  key: "Status",
                  title: displayPlanStatus(row.status),
                },
                {
                  key: "Due Date",
                  title: row.dueDate
                    ? this.renderLatestPaymentDate(row.dueDate)
                    : "--/--/----",
                },
                {
                  key: "Paid Date",
                  title: row.paidDate
                    ? this.renderLatestPaymentDate(row.paidDate)
                    : "--/--/----",
                },
                {
                  key: "Payment Amt",
                  title: `$ ${this.getPaidAmount(row.paymentAmt)}`,
                },
                {
                  key: "Paid Amt",
                  title: `$ ${this.getPaidAmount(row.paid_amt)}`,
                },
                {
                  key: "Balance",
                  title: `$ ${this.getPaidAmount(row.balance)}`,
                },
              ],
            };
          })
        : type === "note"
        ? data.map((row) => {
            return {
              emptyPrimary: true,
              secondaryColumns: [
                {
                  key: "Name",
                  title: row.display_name,
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-start",
                  },
                },
                {
                  key: "Date",
                  title: row.created_at
                    ? this.renderLatestPaymentDate(row.created_at)
                    : "--/--/----",
                  style: {
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
                {
                  key: "Note",
                  title: row.note,
                  style: {
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                  },
                },
              ],
            };
          })
        : [];

    return {
      headings,
      columns,
    };
  };

  renderDocuments = (data, header, type, emptyMsg) => {
    let newData = [];
    newData = data;
    let { expandDoc } = this.state;
    let flag = expandDoc && type === "Document" ? true : false;
    let count = data && data.length ? data.length : 0;
    return (
      <div>
        {type === "Document"
          ? this.getHeaderUtilsForDocument(header, type, newData, count)
          : this.getClickableHeader(header, type, newData)}
        {flag ? (
          <div>
            {data && data.length ? (
              <div>
                <CardView data={this.getObjectListData(newData, type)} />
                <HistoryObjectList
                  data={this.getObjectListData(newData, type)}
                  hideForMobile={true}
                  optionalTableClasses={
                    type === "Document" ? "invoice-doc" : ""
                  }
                  optionalClasses='mg-row-pl-15'
                />
              </div>
            ) : (
              this.renderEmpty(emptyMsg)
            )}
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    const { appName, user, stripePubKey } = this.props;
    const {
      isLoading,
      error,
      invoiceDetails,
      showLoader,
      paymentPlanDetails,
      planList,
      paymentSummary,
      textColor,
    } = this.state;
    const props = this.props;
    const invoiceId = this.props.match.params.id;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>{`${appName} | Payment Plan Details`}</title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ConsumerSidebar
            primary_color={this.props.appThemeData.primary_color}
            user={this.props.user}
            subTitle={this.getSubTitle(this.props.user)}
            sidebarContent={this.sidebarContent()}
            BackLink={"/dashboard/accounts/payment-plans"}
          />

          {showLoader ? (
            <div className='app-sidebar-layout-canvas-content'>
              <AppCanvasLoader />
            </div>
          ) : (
            <div className='app-sidebar-layout-canvas-content setting-side-canvas big-device-grad col1-scroll scrollbar'>
              <div style={{ display: "flex" }}>
                <div className='main-header'>
                  <div className='he-hed edit-invoice'>
                    Payment Plan Details
                  </div>
                </div>
                {(paymentPlanDetails.status === "active" ||
                  paymentPlanDetails.status === "on_time" ||
                  paymentPlanDetails.status === "past_due") && (
                  <button
                    className='cta make-payment-btn mg-brand2-color-common'
                    onClick={() => this.handlePayNow()}
                    style={{
                      fontFamily: "Open Sans",
                      backgroundColor: this.props.appThemeData.primary_color,
                      border: "1px solid #a1a1a1",
                    }}
                  >
                    Make Payment
                  </button>
                )}
              </div>

              <div className='main-invoice-wrapper'>
                <div
                  className='sub-invoice-wrapper1'
                  style={{ marginTop: "15px" }}
                >
                  <CardView
                    data={this.getObjectListDataForAdditionalInformation(
                      "newData",
                      "type"
                    )}
                  />
                  <HistoryObjectList
                    data={this.getObjectListDataForAdditionalInformation(
                      "newData",
                      "type"
                    )}
                    hideForMobile={true}
                    // optionalTableClasses={
                    //   type === "Document" ? "invoice-doc" : ""
                    // }
                    optionalClasses='mg-row-pl-15'
                  />
                  {
                    <div style={{ marginTop: "1rem" }}>
                      {this.renderPlanInvoices()}
                    </div>
                  }

                  {this.renderPaymentAndDoc(paymentSummary, "", "Payment", "")}
                </div>

                <div className='sub-invoice-wrapper2'>
                  {this.showTotalsDetails()}
                </div>
              </div>
              <div>
                {this.renderPaymentAndDoc(
                  planList,
                  "Payment Details",
                  "PaymentDetails",
                  ""
                )}
              </div>
              <div className='in-new-btn'>
                <div className='he-hed he-sec-hed'>
                  Notes {`(${paymentPlanDetails.notes.length})`}
                </div>
              </div>
              {paymentPlanDetails.notes.length > 0 ? (
                <div>
                  <CardView
                    data={this.getObjectListData(
                      paymentPlanDetails.notes,
                      "note"
                    )}
                  />
                  <HistoryObjectList
                    data={this.getObjectListData(
                      paymentPlanDetails.notes,
                      "note"
                    )}
                    hideForMobile={true}
                    optionalTableClasses='in-task'
                  />
                </div>
              ) : (
                this.renderEmpty(`There are currently no notes to review.`)
              )}
              {this.renderDocuments(
                paymentPlanDetails.documents,
                "Documents",
                "Document",
                "No documents available"
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConsumerPaymentPlanDetails;
