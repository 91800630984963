import React, { Component } from 'react';

// Components
import Modal from '../Modal';


class ConfirmAdminDeletionModal extends Component {

  render() {
    let { admin } = this.props;

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="confirm-admin-deletion"
        closeOnClickOutside={true}
      >
        <h1 className="modal-heading">
          Are you sure?
        </h1>
        <p>
          Are you sure you want to delete the admin, <strong>{admin.first_name} {admin.last_name}?</strong>
        </p>
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={() => this.props.submitAndRemoveAdmin(admin.id)}
          >
            Yes, Delete this Admin
          </a>
          <a
            className="modal-link"
            onClick={this.props.hideModal}
          >
            Cancel
          </a>
        </div>
      </Modal>
    )
  }
}

export default ConfirmAdminDeletionModal;