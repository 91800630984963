import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import shortid from "shortid";
import * as API from "../../../utils/api";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ClientsCardView from "../../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../../components/ObjectList/ClientsAccounts/InvoiceObjectList";

class InvoiceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getInvoiceHistory();
  }

  getInvoiceHistory = () => {
    const { user } = this.props;
    API.getInvoiceHistory(user, this.props.invoiceDetails.id).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.data) {
          // this.setState({ historyData: data.data }, () => {
          //   this.SortData();
          // });
          this.SortData(data.data);
        }
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  SortData = (data) => {
    let sortedData;
    sortedData = _.orderBy(
      data,
      // function (o) {
      //   return new moment(o.date).format("YYYYMMDD");
      // },
      ["date"],
      ["desc"]
    );
    // console.log("sorted data ==> ", sortedData);
    this.setState({ historyData: sortedData });
  };

  getDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY HH:mm:ss A");
    }
    return "";
  };

  getTaskColumns = (data) => {
    if (!data) return [];
    return data.map((row) => {
      return {
        secondaryColumns: [
          // {
          //   title: this.getDate(row.date) + `, ${row.type}`,
          // },
          {
            title: this.getDate(row.date),
          },
          {
            title: row.email ? row.email : "System",
          },
          {
            title: row.desc,
          },
        ],
      };
    });
  };

  //returns formatted table and card data
  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Date/Time",
        style: { width: "20%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Username",
        style: { width: "20%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Description",
        style: { width: "60%", display: "flex", justifyContent: "flex-start" },
      },
    ];

    columns = data.map((item, index) => {
      return {
        // primaryRow: {
        //   key: "Date/Time",
        //   title: item.date ? this.getDate(item.date) : "",
        // },
        secondaryColumns: [
          {
            key: "Date/Time",
            title: item.date ? this.getDate(item.date) : "",
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Username",
            title: item.email ? item.email : "System",
            style: {
              width: "20%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Description",
            title: item.desc ? item.desc : "",
            style: {
              width: "60%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
        ],
        rowData: item,
      };
    });

    return {
      headings,
      columns,
    };
  };

  renderTable = (data) => {
    return data && data.length
      ? data.map((column) => {
          return (
            <div key={shortid.generate()} className='in-history'>
              {column.secondaryColumns.map((item) => {
                return (
                  <div
                    key={shortid.generate()}
                    className='row'
                    title={item.title}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          );
        })
      : this.renderEmpty(`Invoice history not available.`);
  };

  renderEmpty = (msg) => {
    return (
      <div
        className='admin-admins-empty-state'
        style={{ padding: "2rem 2rem 1rem 2rem" }}
      >
        <p>{msg}</p>
      </div>
    );
  };

  render() {
    let { historyData, isLoading } = this.state;
    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <div
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={() => this.props.goBack()}
        >
          « Back to invoice
        </div>
        <div className='he-hed' style={{ marginBottom: "2em" }}>
          Invoice History
        </div>
        {<ClientsCardView data={this.getObjectListData(historyData)} />}
        <InvoiceObjectList
          data={this.getObjectListData(historyData)}
          hideForMobile={true}
          // rowActionClick={this.rowExpanderClick}
          optionalTableClasses='invoice-history-list'
        />
        {/* <div className="in-history">
            <div className="row" style={{fontWeight: "bold"}}> Date/Time</div>
            <div className="row" style={{fontWeight: "bold"}}> Username</div>
            <div className="row" style={{fontWeight: "bold"}}> Description</div>
          </div>
        {this.renderTable(this.getTaskColumns(historyData))} */}
      </div>
    );
  }
}

export default InvoiceHistory;
