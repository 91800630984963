import moment from "moment";
import React, { Component } from "react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Redirect, withRouter } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
// Components
import AppSectionHeader from "../../components/App/AppSectionHeader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import DropdownButton from "../../components/DropdownButton";
import FlashMessage from "../../components/FlashMessage";
import MenuItem from "../../components/MenuItem";
import AccountsObjectList from "../../components/ObjectList/ClientsAccounts/AccountsObjectList";
// API
import * as API from "../../utils/api";
import {
  getZohoPagesense,
  showToast,
  getDollarAmount,
} from "../../utils/helpers";
import Emitter from "../../utils/event-emitter";
import { Widgets } from "@material-ui/icons";

class ClientsAccounts extends Component {
  constructor() {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      consumers: [],
      consumer_type: "ALL",
      fromChild: "",
      isLoading: true,
      isUploading: false,
      listLoader: false,
      showFlash: false,
      teamMembersData: [],
      totalPendingOffers: 0,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      input: {
        search: {
          value: "",
        },
      },
      isAllSelected: false,
      hasMoreData: false,
      offset: 0,
      showLoader: false,
      sortHeader: "invoice_number",
      sortType: false,
    };
  }

  style = {
    eligbleForPayment: {
      padding: "5px 7px",
      borderRadius: "7px",
      backgroundColor: "#0ab894",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "10px",
      fontWeight: 400,
      marginBottom: "10px",
      float: "right",
    },
    activePayment: {
      padding: "5px 7px",
      borderRadius: "7px",
      backgroundColor: "#118BB3",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "10px",
      fontWeight: 400,
      marginBottom: "10px",
      float: "right",
    },
    inCollection: {
      padding: "5px 7px",
      borderRadius: "7px",
      backgroundColor: "#db2424",
      color: "#ffffff",
      fontFamily: "Open Sans",
      fontSize: "10px",
      float: "right",
      fontWeight: 400,
    },
  };
  componentDidMount() {
    this.getTeamMembersData();
    this.handleAccountListRefresh = this.handleAccountListRefresh.bind(this);
    Emitter.on("ACCOUNT_LIST_REFRESH", this.handleAccountListRefresh);

    this.handleSearch(false);
    getZohoPagesense();
    // ReactGA.pageview("/ClientsAccounts");
  }

  componentWillUnmount() {
    Emitter.off("ACCOUNT_LIST_REFRESH", this.handleAccountListRefresh);
  }

  handleAccountListRefresh() {
    this.handleSearch(false, true);
  }

  //calls when page scroll reaches the bottom, used for pagination
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData && this.state.consumer_type === "ALL") {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    } else if (
      this.state.hasMoreData &&
      (this.state.consumer_type === "PAST_DUE" ||
        this.state.consumer_type === "NON_QB")
    ) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.handleSearch(true);
        }
      );
    }
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.setState(
        (state) => ({
          ...state,
          offset: 0,
        }),
        () => {
          this.handleSearch();
        }
      );
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(
      (state) => ({
        ...state,
        input: {
          ...newPartialInput,
        },
      }),
      () => {
        this.handleKeyPress({ key: "Enter" });
      }
    );
  }

  setConsumersData = (dataSet = []) => {
    this.setState((state) => ({
      ...state,
      consumers: dataSet,
      listLoader: false,
    }));
  };

  //search api call
  handleSearch = (reload, isSort) => {
    if (!isSort) {
      if (reload) {
        this.setState({ showLoader: true });
      } else {
        this.setState({ listLoader: true });
      }
    }
    let sort_key = this.getSortKey();
    let order = this.state.sortType ? "asc" : "desc";
    let queryString = this.state.input.search.value.toLowerCase();
    let filterBy = this.state.consumer_type;
    API.clientSearch(
      this.props.user,
      "consumers",
      queryString,
      this.state.offset,
      false,
      filterBy,
      sort_key,
      order
    ).then((data) => {
      Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
      if (
        (typeof data != "undefined" || data != null) &&
        data &&
        data.status_code == 200
      ) {
        if (!data.data) {
          this.setConsumersData();
        } else {
          let consumers = reload ? this.state.consumers : false;
          let newData = consumers ? consumers.concat(data.data) : data.data;
          this.setState({
            hasMoreData: data.data.length < 10 ? false : true,
          });
          this.setConsumersData(newData);
        }
        setTimeout(() => {
          this.setState({
            listLoader: false,
            showLoader: false,
            isLoading: false,
          });
        }, 1000);
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
          listLoader: false,
          showLoader: false,
          isLoading: false,
        }));
      }
    });
  };

  //returns the sort arrow click header
  getSortKey = () => {
    let { sortHeader } = this.state;
    if (sortHeader === "Name") {
      return "display_name";
    } else if (sortHeader === "# Open Inv.") {
      return "open_invoices";
    } else if (sortHeader === "Amt Due") {
      return "amount_due";
    } else if (sortHeader === "Past Due Amt") {
      return "past_due_amount";
    } else {
      return "invoice_number";
    }
  };

  //sort table based on header sort arrow click
  sortAccountData = (header, type) => {
    this.setState(
      {
        sortHeader: header,
        sortType: type,
        offset: 0,
      },
      () => {
        this.handleSearch(false, true);
      }
    );
  };

  //calls when add invoice button click to open add invoice screen
  handleAddInvoiceClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/add/invoices`,
      state: { backPath: "/client/accounts" },
    });
  };

  handleAddAccountsClick = (e) => {
    let { history } = this.props;
    history.push({
      pathname: `/client/import`,
      state: { backPath: "/client/accounts" },
    });
  };

  getTeamMembersData = () => {
    this.setState({ showLoader: true, listLoader: true });
    API.getTeamMembers(this.props.user, true)
      .then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          this.setState({
            teamMembersData: data.employees,
          });
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ showLoader: false, listLoader: false });
      });
  };

  //calls when add account button click to open add account popup modal
  handleAddAccountClick = (event) => {
    event.preventDefault();
    let teamData = this.state.teamMembersData.map((item) => ({
      Id: item.id,
      name: item.display_name,
    }));
    let props = {
      teamData: teamData,
      releoadTableData: this.releoadTableData,
    };
    this.props.updateModalState(true, "ADD_ORGANIZATION_CLIENT_ACCOUNT", props);
  };

  //reloads table data
  releoadTableData = () => {
    this.handleSearch(false);
  };

  //opens clients account screen
  handleRowActionClick = (event) => {
    let { history } = this.props;
    const consumerId = event.consumerId;
    if (consumerId) {
      history.push(`/client/accounts/${consumerId}`);
    }
  };

  dismissFlash = () => {
    this.setState((state) => ({
      ...state,
      showFlash: false,
    }));
  };

  //hanlde filter value change
  handleFilter = (consumer_type) => {
    this.setState({ consumer_type, offset: 0 }, () => {
      this.handleSearch(false);
    });
  };

  //show header options
  getSectionHeaderUtilities() {
    const { input } = this.state;
    let app_api_url = process.env.REACT_APP_API_URL;

    return (
      <div className='app-section-header-utilities client-account-utility-inner'>
        <div className='admin-search-input-container client-search-c'>
          <form autoComplete='off'>
            <input
              type='text'
              name='search_by_account'
              placeholder='Search By Account Name'
              value={input.search.value}
              className='admin-search-input'
              onKeyDown={this.handleKeyPress}
              onChange={(event) =>
                this.handleInputChange({
                  search: { value: event.target.value },
                })
              }
            />
          </form>
          <span
            className='search-icon'
            onClick={() => this.handleKeyPress({ key: "Enter" })}
          />
        </div>
        <div className='utility-btn-col'>
          <DropdownButton
            title='Filter By'
            primaryLink='/client/accounts'
            overrideStyle={true}
            forFilter={true}
            clientMobile={true}
          >
            <MenuItem
              handleClick={(event) => this.handleFilter("ALL")}
              optionalClasses={
                this.state.consumer_type === "ALL" ? " filter-select" : " "
              }
            >
              All
            </MenuItem>
            <MenuItem
              handleClick={(event) => this.handleFilter("PAST_DUE")}
              optionalClasses={
                this.state.consumer_type === "PAST_DUE" ? " filter-select" : " "
              }
            >
              Past Due
            </MenuItem>
            {/* <MenuItem
              handleClick={(event) => this.handleFilter("NON_QB")}
              optionalClasses={
                this.state.consumer_type === "NON_QB" ? " filter-select" : " "
              }
            >
              Local
            </MenuItem>*/}
          </DropdownButton>
          <div className='add-account-invoice'>
            <DropdownButton
              title='+ Add'
              primaryLink='/client/accounts'
              overrideStyle={true}
              forFilter={true}
              clientMobile={true}
            >
              <MenuItem handleClick={this.handleAddAccountClick}>
                Add Account
              </MenuItem>
              <MenuItem handleClick={this.handleAddInvoiceClick}>
                Add Invoice
              </MenuItem>
              <MenuItem handleClick={this.handleAddAccountsClick}>
                Import Accounts
              </MenuItem>
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  }

  //returns formatted account table data
  getAccountsObjectListData = () => {
    const { consumers, isAllSelected } = this.state;

    return {
      headings: [
        "Name",
        "Status", // Account placement status
        "# Open Inv.",
        "Amt Due",
        "Past Due Amt",
        "Last Pymt",
        "Next Pymt",
        "expander",
      ],
      columns: consumers.map((consumer) => {
        return {
          primaryColumn: {
            key: "Name",
            title: consumer.display_name,
            secondaryInfo: consumer.email,
            editIcon: true,
            rowPath: `/client/accounts/${consumer.id}`,
          },
          primaryRow: {
            key: "Name",
            title: consumer.display_name,
            secondaryInfo: consumer.email,
            editIcon: true,
            rowPath: `/client/accounts/${consumer.id}`,
          },
          secondaryColumns: [
            {
              key: "Status", // Account placement status
              title: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "max-content",
                  }}
                >
                  {consumer.has_active_payment_plan && (
                    <button style={this.style.activePayment}>
                      Active Payment Plan
                    </button>
                  )}
                  {consumer.has_eligible_invoices && (
                    <button style={this.style.eligbleForPayment}>
                      Eligible for Placement
                    </button>
                  )}
                  {consumer.has_placed_invoices && (
                    <button style={this.style.inCollection}>
                      In Collection
                    </button>
                  )}
                </div>
              ),
            },
            {
              key: "# Open Inv.",
              title: `${consumer.open_invoices}`,
            },
            {
              key: "Amt Due",
              title: `${
                consumer.amount_due
                  ? getDollarAmount(Number(consumer.amount_due).toFixed(2))
                  : "$0.00"
              }`,
            },
            {
              key: "Past Due Amt",
              title: `${
                consumer.past_due_amount
                  ? getDollarAmount(Number(consumer.past_due_amount).toFixed(2))
                  : "$0.00"
              }`,
            },
            {
              key: "Last Pymt",
              title: `${
                consumer.last_payment_date
                  ? this.renderLatestPaymentDate(consumer.last_payment_date)
                  : "--/--/----"
              }`,
            },
            {
              key: "Next Pymt",
              title: `${
                consumer.next_payment_due_date
                  ? this.renderLatestPaymentDate(consumer.next_payment_due_date)
                  : "--/--/----"
              }`,
            },
            {
              rowType: "arrow-click",
              optionalClasses: "expander",
            },
          ],
          status: consumer.past_due_amount > 0 ? false : true,
          consumerId: consumer.id,
        };
      }),
    };
  };

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  render() {
    const { appName } = this.props;
    const { isLoading, isUploading, error } = this.state;
    let isSmallDevice = window.innerWidth <= 680 ? true : false;
    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading || isUploading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <Helmet>
          <title>{appName ? appName : ""} | Accounts </title>
        </Helmet>
        {this.state.showFlash ? (
          <FlashMessage
            message={this.state.flashMessage}
            handleFlashDismiss={this.dismissFlash}
          />
        ) : null}

        <AppSectionHeader
          title='Accounts'
          utilities={this.getSectionHeaderUtilities()}
          optionalClassNames='client-account-utility'
        />
        {this.state.consumers ? (
          this.state.consumers.length ? (
            <div>
              {this.state.listLoader ? (
                <div className='object-list-loader'>
                  <AppCanvasLoader />
                </div>
              ) : (
                <BottomScrollListener
                  offset={50}
                  onBottom={this.handleContainerOnBottom}
                  debounce={0}
                >
                  {(scrollRef) => (
                    <div>
                      {isSmallDevice ? (
                        <div
                          ref={scrollRef}
                          className='big-device-grad scrollbar ac-sc'
                        >
                          <ClientsCardView
                            data={this.getAccountsObjectListData()}
                            isStatus={true}
                            rowActionClick={(event) =>
                              this.handleRowActionClick(event)
                            }
                          />
                        </div>
                      ) : (
                        <AccountsObjectList
                          data={this.getAccountsObjectListData()}
                          rowActionClick={(event) =>
                            this.handleRowActionClick(event)
                          }
                          scrollRef={scrollRef}
                          isPrimaryColumn={true}
                          isStatus={true}
                          hideForMobile={true}
                          optionalClasses={"scrollbar ac-sc"}
                          sortAccountData={this.sortAccountData}
                          sortHeaders={[
                            this.state.sortHeader,
                            this.state.sortType,
                          ]}
                        />
                      )}
                      {this.state.showLoader && (
                        <div>
                          <AppCanvasLoader />
                        </div>
                      )}
                    </div>
                  )}
                </BottomScrollListener>
              )}
            </div>
          ) : this.state.input.search.value.length ? (
            <div className='admin-admins-empty-state'>
              <p>No results found for:</p>
              <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                {this.state.input.search.value}
              </h3>
            </div>
          ) : (
            <div className='admin-admins-empty-state'>
              <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
                No Accounts
              </h3>
              <p>
                There are currently no accounts. Select Add Account to create an
                Account.
              </p>
            </div>
          )
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
              No Accounts
            </h3>
            <p>
              There are currently no consumer accounts. Select Add Consumer to
              create an Consumer account.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClientsAccounts);
