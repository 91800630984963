import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Heap from "react-heap";
// import ReactGA from 'react-ga';

// Components
import SessionsModal from "../components/Sessions/SessionsModal";
import ConfigureForm from "../components/ConfigureForm";
import Logo from "../components/Logo";

import * as API from "../utils/api";

import { getZohoPagesense } from "../utils/helpers";

class AppConfiguration extends Component {
  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/AppConfiguration");
  }

  render() {
    const { isLoggedIn, user, location, isAppConfigure } = this.props;

    let { appName, appData } = this.props;

    if (
      appData.company_name &&
      appData.company_email &&
      appData.admin_email &&
      appData.sender_email &&
      appData.company_address
    ) {
      if (isLoggedIn) {
        return <Redirect to={{ pathname: "/login" }} />;
      } else {
        return <Redirect to={{ pathname: "/" }} />;
      }
    }

    return (
      <div className='sessions fill-screen'>
        <Helmet>
          <title>{appName} | Configure</title>
        </Helmet>
        <SessionsModal title='Configure the app'>
          <div className='sessions-modal-logo-container'>
            <Logo circular={true} />
          </div>
          <div className='sessions-modal-content'>
            <h1 className='sessions-modal-heading'>Configure the app</h1>
            <h5>
              Welcome to {appName} installer. This configuration screen will
              help you tailor {appName} product for your own use. Please enter
              your organization name and upload your company logo to be used.
              Same name and logo will be printed in all letters generated by the
              system
            </h5>
            <ConfigureForm
              appName={appName}
              appData={this.props.appData}
              location={this.props.location}
              updateModalState={this.props.updateModalState}
              isAppConfigure={this.props.isAppConfigure}
            />
          </div>
        </SessionsModal>
      </div>
    );
  }
}

export default AppConfiguration;
