import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import * as API from "../../../../utils/api";

import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import viewIcon from "../../../../assets/icons/view.png";
import downloadImg from "../../../../assets/icons/download.png";
import Modal from "../Modal";
import { displayPlanStatus } from "../../../../utils/helpers";
import moment from "moment";
import BottomScrollListener from "react-bottom-scroll-listener";

class PaymentInvoicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isPlansSelected: true,
      offset: 0,
      hasMoreData: false,
      isLoading: false,
      leftLoader: false,
    };
  }

  componentDidMount() {
    let { optionalProps } = this.props;
    let { plan_list, from } = optionalProps;
    this.setState({ list: plan_list }, () => {
      if (from === "invoice_tab") {
        this.setState({ isLoading: true, offset: 0, hasMoreData: false });
        this.getInvoiceData();
      }
    });
  }
  getDollerAmount = (amount) => {
    let value = 0.0;
    if (amount) {
      let temp = amount.replace(",", "");
      value = parseFloat(temp);
    }
    return value.toFixed(2);
  };
  getInvoiceData = () => {
    let { offset, list } = this.state;

    API.fetchAllConsumerInvoices(this.props.user, offset, true).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        let temp = data.data.invoices.map((item) => {
          item.checkForPay = false;
          list.map((li) => {
            if (li.id === item.id) {
              item.checkForPay = true;
            }
          });
          return item;
        });
        let uniqueInvoicesSet = new Set(
          [...temp, ...list].map((invoice) => invoice.id)
        );
        let uniqueIds = Array.from(uniqueInvoicesSet);
        let resultArray = uniqueIds.map((id) => {
          return [...temp, ...list].find((invoice) => invoice.id === id);
        });
        this.setState((state) => ({
          ...state,
          hasMoreData: data.data.invoices.length < 15 ? false : true,
          // consumerInvoice: data.data,
          list: resultArray.sort((a, b) => {
            return a.checkForPay === b.checkForPay ? 0 : a.checkForPay ? -1 : 1;
          }),
          isLoading: false,
          leftLoader: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          leftLoader: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  handleEdit = (event) => {
    let { list } = this.state;
    // history.push(`/client/invoices/${item.id}`);
    let { optionalProps } = this.props;
    let temp = list.filter((item) => item.checkForPay);
    optionalProps.handleSubmit(temp);
    this.props.hideModal();
  };
  handleSubmit = (event) => {
    let { list } = this.state;
    // history.push(`/client/invoices/${item.id}`);
    let { optionalProps } = this.props;
    // optionalProps.handleSubmit(list)
    this.props.hideModal();
  };

  handleModalClose = () => {
    let { optionalProps } = this.props;
    let { plan_list } = optionalProps;
    plan_list.map((item) => {
      item.checkForPay = true;
    });
    this.setState({ list: plan_list });
    this.props.hideModal();
  };
  viewValidationDoc = (row) => {
    let props = {
      // image: row.document,
      type: row.document_type.name,
      doc_name: row.document_name,
      doc_id: row.id,
      arrange_pdf: true,
      user: this.props.user,
      from: "payment_plan",
    };
    this.props.updateModalState(true, "SHOW_IMAGE", props);
  };

  //render table headers
  getHeaders = (from) => {
    return (
      <div style={StyleSheet.headerView}>
        <h4 style={StyleSheet.headerLabel}>
          {from === "invoice_tab" ? "Invoice No" : "Payment #"}
        </h4>
        <h4 style={StyleSheet.headerLabel}>Rem. Balance</h4>
        <h4 style={StyleSheet.headerLabel}>Status</h4>
        {/* {from === 'invoice_tab' &&  <h4 style={StyleSheet.headerLabel}>Action</h4>} */}
      </div>
    );
  };
  downloadAttachedDocuement = (doc_id, fileName) => {
    const { user } = this.props;
    API.getDownloadPaymentDocument(user, doc_id, "payment_plan_document").then(
      (data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            let flag = data.data.includes("base64");
            if (!flag) {
              data.data = "data:application/pdf;base64," + data.data;
            }
            let url = data.data;
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  };

  documentAction = (row) => {
    // let imgExtension = row.document_name && row.document_name.split(".").pop();
    // let fileSizeCheck =
    //   row.file_size_in_mb && row.file_size_in_mb > 3 ? true : false;
    // let flag =
    //   imgExtension &&
    //   (imgExtension.toLowerCase() === "xls" ||
    //     imgExtension.toLowerCase() === "xlsx" ||
    //     imgExtension.toLowerCase() === "doc" ||
    //     imgExtension.toLowerCase() === "docx")
    //     ? false
    //     : true;
    // flag = fileSizeCheck ? false : flag;
    return (
      <>
        <a
          className='make-payment-button'
          style={{ cursor: "pointer", marginRight: 15 }}
          title='Download'
          // onClick={() =>
          //       this.downloadAttachedDocuement(row.id, row.document_name)
          //     }
        >
          <img src={downloadImg} width='16' height='16' />
        </a>
        {/* <button
              title="Edit"
              style={{
                background: "transparent",
                cursor: "pointer",
                marginRight: 15,
              }}
              onClick={() => this.attchDocument(row, true)}
            >
              <img src={editImg} width="16" height="16" />
            </button>
            <button
              title="Delete"
              style={{
                background: "transparent",
                cursor: "pointer",
                marginRight: 15,
              }}
              onClick={() => this.deleteAttachment(row.id)}
            >
              <img src={deleteImg} width="16" height="16" />
            </button> */}
        {/* {flag && ( */}
        <button
          title='View'
          className='view-button'
          style={{
            background: "transparent",
            cursor: "pointer",
          }}
          // onClick={() => this.viewValidationDoc(row)}
        >
          <img src={viewIcon} width='16' height='16' />
        </button>
        {/* )} */}
      </>
    );
  };
  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };
  getPaymentStatus = (invoice) => {
    let is_past_due = this.checkInvoicePastDue(invoice.due_date);
    let statusStyle = is_past_due
      ? { color: "#fb5c82", fontFamily: "Open Sans", fontWeight: 600 }
      : { color: "#409D57", fontFamily: "Open Sans", fontWeight: 600 };
    if (
      parseFloat(invoice.balance).toFixed(2) ==
      (
        parseFloat(invoice.total_amt) +
        parseFloat(invoice.tax_amount) +
        parseFloat(invoice.slow_payer_amount)
      ).toFixed(2)
    ) {
      return "Unpaid";
    } else {
      return "Partially Paid";
    }
  };
  handlePlansSelection = (e, item, index) => {
    let { list } = this.state;
    list[index].checkForPay = e.target.checked;
    this.setState({ list }, () => {
      // this.checkSendEmailValid();
      let len = list.filter((item) => item.checkForPay);
      this.setState({ isPlansSelected: len.length > 0 });
    });
  };
  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
          leftLoader: true,
          hasMoreData: false,
        }),
        () => {
          this.getInvoiceData();
        }
      );
    }
  };

  render() {
    let { list, isPlansSelected, isLoading } = this.state;
    let { optionalProps } = this.props;
    let { from } = optionalProps;
    return (
      <Modal
        title={from === "invoice_tab" ? "Edit Invoice(s)" : "View Payment(s)"}
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        handleModalClose={this.handleModalClose}
        optionalClasses='scroll'
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <div>
            <BottomScrollListener
              onBottom={this.handleContainerOnBottom}
              debounce={0}
              offset={50}
            >
              {(scrollRef) => (
                <div
                  ref={scrollRef}
                  className='consumer-scroll-accounts-list scrollbar margin-bottom'
                >
                  {this.getHeaders(from)}
                  {list && list.length
                    ? list.map((item, i) => {
                        item.plan_number = i + 1;
                        return (
                          <div key={"_" + i} style={StyleSheet.tableBody}>
                            {from === "invoice_tab" && (
                              <div className='email-value-wrapper'>
                                <input
                                  className='email-checkbox'
                                  type='checkbox'
                                  id={`tax00${i}`}
                                  name={"tax"}
                                  value={"tax"}
                                  checked={item.checkForPay}
                                  onChange={(e) =>
                                    this.handlePlansSelection(e, item, i)
                                  }
                                />
                              </div>
                            )}
                            <div style={StyleSheet.invoiceID}>
                              {from === "invoice_tab"
                                ? item.invoice_number
                                : `#${item.plan_number}`}
                            </div>
                            <div style={StyleSheet.invoiceID}>
                              {`$${this.getDollerAmount(item.balance)}`}
                            </div>
                            <div style={StyleSheet.invoiceID}>
                              {`${
                                from === "invoice_tab"
                                  ? this.getPaymentStatus(item)
                                  : displayPlanStatus(item.status)
                              }`}
                            </div>
                            {/* {from === 'invoice_tab' && <div style={StyleSheet.invoiceID}>
                      {this.documentAction(item)}
                    </div>}
             */}
                          </div>
                        );
                      })
                    : null}
                </div>
              )}
            </BottomScrollListener>

            <div className='main_doneButton' style={StyleSheet.buttonWrapper}>
              {from === "invoice_tab" ? (
                isPlansSelected ? (
                  <button
                    style={{
                      ...StyleSheet.refundBtn,
                      backgroundColor: optionalProps.primary_color,
                    }}
                    onClick={(event) => this.handleEdit(event)}
                  >
                    Done
                  </button>
                ) : (
                  <></>
                )
              ) : (
                <button
                  style={{
                    ...StyleSheet.refundBtn,
                    backgroundColor: optionalProps.primary_color,
                  }}
                  onClick={(event) => this.handleSubmit(event)}
                >
                  Done
                </button>
              )}
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

let StyleSheet = {
  headerView: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
  },
  tableBody: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    border: "1px solid #e1e1e1",
    borderRadius: 4,
    marginBottom: "1em",
    padding: "0.5em",
  },
  headerLabel: {
    margin: "0px 10px 0px 0px",
    width: "100%",
    fontSize: "1em",
    color: "#9b9b9b",
    flex: 1,
  },
  headerLabel1: {
    margin: 0,
    width: "100%",
    fontSize: "1em",
    color: "#9b9b9b",
    flex: 3,
  },
  refundBtn: { padding: "1em 2em 0.8em", margin: 0, fontWeight: 600 },
  fullWidth: { margin: 0, width: "100%" },
  dateValue: { margin: "0px 10px 0px 0px", width: "100%" },
  invoiceID: {
    margin: 0,
    width: "100%",
    // flex: 5,
    textAlign: "left",
  },
  buttonWrapper: {
    width: "100%",
  },
};

export default withRouter(PaymentInvoicesList);
