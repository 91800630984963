import React, { Component } from 'react';

// Components
import Modal from './Modal';


class CounterofferSentModal extends Component {

  renderMessage = () => {
    let { optionalProps } = this.props;

    if (optionalProps && optionalProps.user) {
      let { user } = this.props.optionalProps;
      return (
        <p>
          {user.first_name} {user.last_name} will receive a message stating that their offer was countered.
        </p>
      )
    } else {
      return (
        (
          <p>
          	{optionalProps.appName} will review your counteroffer shortly.
          </p>
        )
      )
    }

  }
  render() {

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="simple"
        closeOnClickOutside={true}
      >
        <h1 className="modal-heading" style={{marginTop: '22px'}}>
          Counteroffer has been sent!
        </h1>
        {this.renderMessage()}
        <div className="modal-actions">
          <a
            className="cta"
            data-behavior="offer-accept"
            onClick={this.props.hideModal}
          >
            Done
          </a>
        </div>
      </Modal>
    )
  }
}

export default CounterofferSentModal;