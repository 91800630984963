import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


class ConsumerNav extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
  }

  isActive(linkUrl) {
    if (this.props.path.includes(linkUrl)) {
      return 'active';
    }
  }

  
  

  render() {
    return (
      <nav className="admin-nav" style={{ overflow: `inherit` }}>
        
        <Link
          className={`admin-nav-link ${this.isActive('/dashboard/accounts')}`}
          to="/dashboard/accounts/invoices"
        >
          <svg className="icon">
            <use xlinkHref="#currency" />
          </svg>
          Accounts
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/dashboard/profile')}`}
          to="/dashboard/profile"
        >
          <svg className="icon">
            <use xlinkHref="#admin" />
          </svg>
          Profile
        </Link>
        <Link
          className={`admin-nav-link ${this.isActive('/dashboard/help')}`}
          to="/dashboard/help"
        >
          <svg className="icon">
            <use xlinkHref="#help" />
          </svg>
          Get Help
        </Link>
      </nav>
    );
  }
}

export default ConsumerNav;
