import React, { Component } from 'react';
import StepIconListItem from './StepIconListItem/StepIconListItem';

// Image Imports
import ValidateIcon from '../../../assets/images/landing/icons/validate@2x.png';
import NegotiateIcon from '../../../assets/images/landing/icons/negotiate@2x.png';
import SettleIcon from '../../../assets/images/landing/icons/settle@2x.png';

class StepIconList extends Component {

  render() {

    return (
      <ol className="step-icon-list">
        <StepIconListItem
          number="1"
          icon={ValidateIcon}
          heading="Validate that the debt is yours "
          body="So you can be sure you’re only paying what you actually owe."
        />
        <StepIconListItem
          number="2"
          icon={NegotiateIcon}
          heading="Negotiate on the amount"
          body="To get the best deal for you, whether that’s setting up regular, manageable payments, or making a one-time offer to pay off your delinquent debt in one go."
        />
        <StepIconListItem
          number="3"
          icon={SettleIcon}
          heading="Settle your account"
          body="So you can clear your bad debt, and go back to focusing on the things that matter in life."
        />
      </ol>
    );
  }
}

export default StepIconList;
