import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

// Packages
import Select from 'react-select';
import MaskedTextInput from 'react-text-mask';

// API
import * as API from '../../../../utils/api';

// Components
import Modal from '../Modal';
import FlashMessage from '../../../../components/FlashMessage'

import { numberMask, numberMaskForPercent, getStrippedDollarOrPercentAmount, showToast } from '../../../../utils/helpers';
import { log } from 'util';


class userLevelSkipPayment extends Component {
  state = {
    flashMessage: "",
    get_payment_setting:{},
    input: {
        skipPayment: {
            value: "",
            hasError: false,
        },
        frequency: {
            value: "",
            hasError: false,
        }
    }
}

  componentDidMount() {
    const { consumerId } = this.props.optionalProps;
    API.fetchAllCompanyClients(this.props.user).then(data => {
      let consumerData = data.consumers;   
      if (((typeof data != 'undefined') || (data != null)) && !data.error) {
        consumerData.map((data) =>{
          if(data.id === consumerId[0] && data.get_payment_setting && consumerId.length === 1){
            this.setState({
              input: {
                skipPayment: {
                    value: data.get_payment_setting[0].value,
                    hasError: false,
                },
                frequency: {
                    value: data.get_payment_setting[0].frequency,
                    hasError: false,
                }
            }
          })
        }
        })
        
       } else {
         this.setState(state => ({
           ...state,
           error: {
             hasAnyError: true,
             statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
           }
         }))
       }
     })
  }

  handleModalClose = () => {
    this.props.hideModal();
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
        ...state,
        input: {
            ...state.input,
            ...newPartialInput
        }
    }))
}

resetInputFields = () => {
  this.setState(state => ({
      input: {
          skipPayment: {
              value: "",
              hasError: false,
          },
          frequency: {
              value: "",
              hasError: false,
          }
      }
  }))
}

handleModalClose = () => {
  this.props.hideModal();
}

handleSubmit = (event) => {
  event.preventDefault();

  if (this.validateFormAndSetErrorsIfNecessary()) {
      let { input } = this.state;

      let inputValues = {
          skip_payment_value: input.skipPayment.value,
          skip_payment_frequency: input.frequency.value,
          consumerId: this.props.optionalProps
      }

      this.handleHelpFormSubmit(inputValues);
      // this.resetInputFields();
  }
}

handleHelpFormSubmit = (inputs) => {
  const { user } = this.props;

  API.skipPaymetSetting(user, inputs).then(data => {
   if (((typeof data != 'undefined') || (data != null)) && !data.error) {
    this.handleModalClose();
      this.setState(state => ({
        ...state,
        showFlash: true,
        flashMessage: 'Skip payment option saved successfully!'
      }))  
      //this.props.closeForm();
    } else {
      this.setState(state => ({
        ...state,
        error: {
          hasAnyError: true,
          statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
        }
      }))
    }
  })
}

validateFormAndSetErrorsIfNecessary = () => {
  let errorsArePresent = false;
  let newInputState = this.state.input;
  const { skipPayment, frequency } = this.state.input;

  Object.entries(newInputState).forEach(([key, value]) => {
      if (value.value === '') {
          newInputState[key].hasError = true;
          errorsArePresent = true;
      }
  });

  if (skipPayment.value == 'quarter' && frequency.value > 3) {
      errorsArePresent = true;
      frequency.hasError = true;
  }

  if (skipPayment.value == 'half_year' && frequency.value > 6) {
      frequency.hasError = true;
      errorsArePresent = true;
  }

  if (skipPayment.value == 'year' && frequency.value > 12) {
      frequency.hasError = true;
      errorsArePresent = true;
  }

  if (errorsArePresent) {
      this.setState(state => ({
          ...state,
          input: newInputState
      }))
  } else {
      return true;
  }
}

renderErrorMessage(input) {
  let message = '';

  if (input === 'skipPayment') {
      message = "Please select an option.";
  } else if (input === 'frequency') {
      message = "Enter valid frequency.";
  } else {
      message = 'Please complete this field';
  }

  return (
      <div className="input-error-message">
          {message}
      </div>
  )
}

  scrollToTop() {
    window.scrollTo(0, 0);
  }


  renderErrorMessage(input) {
    let message = '';
    if (input === 'type') {
      message = "Please select negotiation type.";
    } else if (input === 'capabilities') {
      message = "Please select capabilities.";
    } else if (input === 'amount') {
      message = "Please enter amount.";
    } else if (input === 'percentage') {
      message = "Please enter percentage.";
    } else {
      message = 'Please complete this field.';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { input, slide, error } = this.state;

    var selectOptions = [
      { value: 'quarter', label: 'Once a quarter' },
      { value: 'half_year', label: 'Once in half year' },
      { value: 'year', label: 'Once a year' }
  ];

    // if (error.hasAnyError) {
    //   if (error.statusCode == 500) {
    //     showToast();
    //   } else if (error.statusCode == 401) {
    //     return <Redirect to={{ pathname: '/sign-out' }} />
    //   } else {
    //     this.props.updateModalState(true, 'OTHER_ERROR', true)
    //     return <Redirect to={{ pathname: '/sign-out' }} />
    //   }
    // }

    return (
      <Modal
        title="Skip Payment"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
      >
        
        <form
                style={{ margin: `5% auto`, maxWidth: `20em` }}
                onSubmit={(event) => this.handleSubmit(event)}
            >
                <div className="admin-form-row">
                    <div className={`input-container${input.skipPayment.hasError ? ' error' : ''}`} style={{ width: `70%` }}>
                        <label htmlFor="name">Skip Payment</label>
                        <Select
                            inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                            className="form-select"
                            name="form-field-name"
                            value={input.skipPayment.value}
                            placeholder="Select an option..."
                            options={selectOptions}
                            resetValue=""
                            onChange={event => this.handleInputChange({ skipPayment: { value: event ? event.value : '', hasError: false } })}
                        />
                        {input.skipPayment.hasError ? this.renderErrorMessage('skipPayment') : null}
                    </div>
                    <div className={`input-container${input.frequency.hasError ? ' error' : ''}`} style={{ width: `25%` }}>
                        <label htmlFor="name">Frequency</label>
                        <MaskedTextInput
                            style={{ paddingLeft: `20px` }}
                            guide={true}
                            mask={[/\d/, /\d/]}
                            name="frequency"
                            placeholder="0"
                            placeholderChar={'\u2000'}
                            type="text"
                            value={input.frequency.value}
                            onChange={event => this.handleInputChange({ frequency: { value: event ? event.target.value : '', hasError: false } })}
                        />
                        {input.frequency.hasError ? this.renderErrorMessage('frequency') : null}
                    </div>
                </div>
                <input type="submit" value="Submit" className="cta" style={{ marginTop: `5%` }} />
            </form>
      </Modal>
    )
  }
}

const style = {
  container: { display: 'flex' },
  content: { width: '80%', paddingTop: `10px` }
}

export default userLevelSkipPayment;
