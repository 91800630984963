import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import ObjectDebtList from "../../components/ObjectList/ObjectDebtList";
import HistoryObjectList from "../../components/ObjectList/ClientsAccounts/HistoryObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";
import moment from "moment";

class ClientsSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      teamMembersData: [],
      data: [],
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.getSubscriptionList();
    this.getTeamMembersData();
  };

  getSubscriptionList = () => {
    let { user } = this.props;
    API.getSubscriptionList(user).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        if (data.message) {
          this.setState({ data: data.message });
        }
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  toggleButton = (event, item) => {
    if (item && item.subscription && item.subscription.id) {
      this.callUpdateApi(item.subscription.id, !item.subscription.status);
    }
  };

  callUpdateApi = (id, status) => {
    let { user } = this.props;
    let payload = {
      subscription_id: id,
      status: status,
    };
    API.updateSubscriptionStatus(user, payload).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        this.reload();
        this.setState({ isLoading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  getTeamMembersData = () => {
    this.setState({ isLoading: true });
    API.getTeamMembers(this.props.user, true)
      .then((data) => {
        if ((typeof data != "undefined" || data != null) && !data.error) {
          this.setState({
            teamMembersData: data.employees,
          });
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleCreateClick = (event, item) => {
    let teamData = this.state.teamMembersData.map((item) => ({
      Id: item.id,
      name: item.display_name,
    }));
    let props = {
      user: this.props.user,
      item: item,
      reportsList: this.props.reportsList,
      teamData: teamData,
      reload: this.reload,
    };
    this.props.updateModalState(true, "ADD_EDIT_SUBSCRIPTION", props);
  };

  getScheduleDays = (scheduleList) => {
    let daysString = "";
    if (scheduleList) {
      scheduleList.map((item, index) => {
        if (item.day === "mon") {
          daysString = daysString + "Mon";
        } else if (item.day === "tue") {
          daysString = daysString ? daysString + "-Tue" : "Tue";
        } else if (item.day === "wed") {
          daysString = daysString ? daysString + "-Wed" : "Wed";
        } else if (item.day === "thu") {
          daysString = daysString ? daysString + "-Thu" : "Thu";
        } else if (item.day === "fri") {
          daysString = daysString ? daysString + "-Fri" : "Fri";
        } else if (item.day === "sat") {
          daysString = daysString ? daysString + "-Sat" : "Sat";
        } else if (item.day === "sun") {
          daysString = daysString ? daysString + "-Sun" : "Sun";
        }
      });
    }
    return daysString;
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      "Report Subscription Name",
      "Scheduled",
      "Next Send",
      "Status",
      "Edit",
    ];

    columns = data.map((item, index) => {
      return {
        primaryRow: {
          key: "Report Subscription Name",
          title: item.subscription.name,
        },
        secondaryColumns: [
          {
            title: item.subscription.name,
            hideForMobile: true,
          },
          {
            key: "Scheduled",
            title: item.subscription_schedule_list
              ? this.getScheduleDays(item.subscription_schedule_list)
              : "n/a",
          },
          {
            key: "Next Send",
            title: item.subscription.created_at
              ? moment(item.subscription.created_at).format("MM/DD/YYYY")
              : "n/a",
          },
          {
            key: "Status",
            title: (
              <ToggleSwitch
                isOn={item.subscription.status}
                toggleButton={(event) => this.toggleButton(event, item)}
              />
            ),
          },
          {
            key: "Edit",
            title: (
              <div
                style={{
                  height: "1em",
                  width: "1em",
                  display: "grid",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <a
                  className={`object-row-expander`}
                  onClick={(event) => this.handleCreateClick(event, item)}
                >
                  <div className={`client-caret expander-caret`} />
                </a>
              </div>
            ),
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };

  headerUtilities = () => {
    return (
      <div
        className='app-section-header-utilities'
        style={{ marginBottom: "2em" }}
      >
        <input
          type='submit'
          value='Create New Subscriptions'
          className='cta cr-sub-btn mg-brand2-color'
          id='new-sub'
          onClick={(event) => this.handleCreateClick(event)}
        />
      </div>
    );
  };

  render() {
    const { appName } = this.props;
    const { isLoading, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className='he-hed'>Subscriptions</div>
          {this.headerUtilities()}
        </div>
        {this.state.data && this.state.data.length ? (
          <div style={{ marginBottom: "100px" }}>
            {/* cardview for mobile device */}
            <ClientsCardView data={this.getObjectListData(this.state.data)} />
            {/* list view for big devices */}
            <HistoryObjectList
              data={this.getObjectListData(this.state.data)}
              hideForMobile={true}
              optionalTableClasses='sub-report'
            />
          </div>
        ) : (
          <div className='admin-admins-empty-state'>
            <h3 className='admin-admins-empty-state-heading empty-mesg-mg'>
              No Subscriptions Available
            </h3>
            <p>There are currently no subscriptions available</p>
          </div>
        )}
      </div>
    );
  }
}

export default ClientsSubscription;
