import React, { Component } from "react";
import PropTypes from "prop-types";
// import ReactGA from "react-ga";

import { getZohoPagesense } from "../utils/helpers";

class MakeGoodPrivacy extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    appName: PropTypes.string,
  };

  componentDidMount() {
    getZohoPagesense();
    // ReactGA.pageview("/MakeGoodPrivacy");
  }

  render() {
    let sectionStyle = {
      overflow: `auto`,
      height: `100vh`,
      paddingTop: "3em",
      paddingBottom: "3em",
    };
    let headerStyle = {
      display: "flex",
      justifyContent: "center",
      marginBottom: "2rem",
    };
    return (
      <div style={sectionStyle}>
        <div className='inner-wrap content'>
          <h1 style={headerStyle}>Privacy Policy</h1>

          <h3>Purpose of This Notice</h3>
          <p>
            At MakeGood we are committed to providing our clients with products
            and services to meet their needs. Our commitment includes protecting
            personally identifiable information we obtain about you. This
            Privacy Notice (“Notice”) describes how we collect, use, disclose
            and protect Personal Information (as defined below) that MakeGood
            and its subsidiaries, affiliates and divisions (“MakeGood,” “we,”
            “us,” or “our”) collect about you. This Notice applies to any party
            whom we receive Personal Information from or about, including, but
            not limited to our clients, our clients’ consumers, vendors and
            users of this website and our software applications (collectively,
            “Sites”) that display or link to this Notice. By using our Sites,
            you consent to the collection, use and disclosure of your Personal
            Information in compliance with this Notice and subject to the
            applicable state and federal laws of the United States.
          </p>

          <p>
            We want to transparently explain how and why we gather, store, share
            and use your personal information and outline the controls and
            choices you have around when and how you choose to share this
            information.
          </p>

          <p>
            This Privacy Policy relates to our use of any personal information
            you give us through our Sites. To make sure you enjoy our full range
            of services, we need to collect information about you. We want you
            to feel comfortable and confident when using our website. Here’s how
            we do that:
          </p>
          <ul>
            <li>We provide you notice of our website information practices.</li>
            <li>We tell you how and why we use web technologies.</li>
            <li>
              We outline how the personally identifiable information that you
              give us may be used.
            </li>
            <li>
              We give you the ability to update or correct your personally
              identifiable information.
            </li>
            <li>We outline the ways we protect your information.</li>
          </ul>

          <p>
            We’ll also offer you ways to contact us about our information
            practices and other aspects of privacy.
          </p>

          <p>
            We’re ridiculously committed to safeguarding your personal
            information. So, whenever you provide such information, we’ll only
            ever use it in line with the laws concerning the protection of
            personal information (these laws are referred to collectively in
            this Privacy Policy as the "data protection laws"). We’ve worked
            hard on this Privacy Policy to make sure it’s clear, transparent and
            at the leading-edge of privacy guidelines in our industry.
          </p>

          <h3>We Provide You Notice of Our Website Information Practices.</h3>
          <p>
            To maximize the value of our services, we may request information
            from you when you visit our website. We won’t share your personal
            information with outside companies for their promotional use,
            however we do use third party services who may access your
            information for the purpose of collecting a debt.
          </p>

          <p>
            Here’s a description of the information we collect and how it’s
            used/disclosed:
          </p>
          <ul>
            <li>
              Electronic communication: We provide you the opportunity to
              subscribe to email communications. You can unsubscribe by
              following the instructions provided in each communication.
            </li>
            <li>
              Mandatory and optional information: We identify what information
              is required to fulfill your request. If you choose not to provide
              us with this information, we will not be able to provide you the
              service you are requesting. As part of your use of our website, we
              may collect information that may include your name, address,
              social security number, financial information about you and other
              information.
            </li>
            <li>
              Within the MakeGood family: Within the MakeGood family, we may
              exchange customer information to fulfill your request(s) or to
              provide you with information about other products or services, if
              you have chosen to receive such communications.
            </li>
            <li>
              Clients: We share information with our clients who have elected to
              use our Sites and services.
            </li>
            <li>
              Service providers: We employ or use service providers such
              consultants, temporary workers, and third party software
              developers, etc. to complete a business process or provide a
              service on our behalf. For example, we may use service providers
              to enhance our website technology, deliver products, or to send
              emails. When we employ service providers, we may need to share
              your personally identifiable information. Service providers are
              strictly prohibited from using your personally identifiable
              information for purposes other than to act on our behalf.{" "}
            </li>
            <li>
              Lien holders, judgment creditors or other parties whose claim or
              interest must be determined, settled, paid or released;
            </li>
            <li>
              Potential third-party investors in MakeGood or any of its
              affiliates for ongoing transparency and due diligence purposes;
            </li>
            <li>
              Other parties for whom you have given us written authorization to
              disclose your Personal Information in connection with our Sites or
              services;
            </li>
            <li>
              Data providers: We use a number of third party data providers.
              Their respective privacy policies can be found on their websites.
              You accept that we may share your information with these data
              partners so they can give us the insight we need to collect a
              debt. In the event you are directed to or use a third party
              company’s website, these third party websites have their own
              privacy policies, including cookies, and we urge you to review
              them. They will govern the use of personal information you submit
              or are collected by cookies whilst visiting these websites. We do
              not accept any responsibility or liability for the privacy
              practices of such third party websites and your use of such
              websites is at your own risk.
            </li>
            <li>
              Service alerts and critical notices: Although we respect and honor
              the privacy preferences you have expressed, we may need to contact
              you to inform you of specific changes that may impact your ability
              to use this service or for other critical non-marketing purposes,
              such as bug alerts. We may also contact you to respond to your
              specific requests, to clarify the order of information you gave
              us, or to let you know about upcoming subscription expiration
              dates.
            </li>
            <li>
              Change of control: Your personally identifiable information may be
              transferred in connection with a sale, merger, transfer, exchange
              or other disposition (whether of assets, stock or otherwise) of
              all or a portion of a business of MakeGood. You will have the
              opportunity to opt out of further secondary use of your
              information following any change of control.
            </li>
            <li>
              Legal disclosures: In some cases we may disclose certain
              information to comply with a legal process such as a court order,
              subpoena, search warrant, or law enforcement request.
            </li>
            <li>
              Hosting: Our website, portal and mobile applications are hosted by
              a third party service provider on servers located in the United
              States. If you are in a non-US jurisdiction, you may be sending
              your personal information to the United States, which may or may
              not have laws that provide an equivalent level of data protection
              to the laws of your home country.
            </li>
          </ul>

          <h3>Your Security and Privacy</h3>
          <p>
            By using our website, you are consenting to the use of information
            and agreeing to these guidelines and the other MakeGood policies
            described on our website.
          </p>
          <p>
            MakeGood does not share your personal information with outside
            companies (non-affiliated companies) for their promotional use
            without your consent.
          </p>
          <p>
            Because we’re in the business of finance, our websites aren’t for
            anyone under 18. So, we don’t knowingly attempt to solicit or
            receive any information from children. If we learn we have collected
            or received Personal Information from anyone under the age of 18
            without parental consent, we will promptly delete that information.
            If you believe we might have any Personal Information from or about
            anyone under the age of 18, please contact us at:
            <br />
            MakeGood
            <br />
            42 Spring Street
            <br />
            Suite 11
            <br />
            Newport, RI 02840
            <br />
            Attention: Compliance Department
            <br />
            Email: privacy@makegoodapp.com
            <br />
          </p>

          <p>
            We don’t make our advertising decisions based on political
            affiliations or in support of, or against, any political or
            religious group. We firmly believe in the right of free speech,
            however, we also believe we have an obligation to ensure our
            advertising does not appear in content that is detrimental to our
            brand values. We won’t advertise within programs that consistently
            exploit excessive or gratuitous violence or sexuality, which demean
            or denigrate any religion, race, sex or which are otherwise lacking
            in good taste.
          </p>

          <p>
            Should our advertising appear within programming content that
            violates these guidelines we’ll review the content in question and
            make decisions about the appropriateness of that program for future
            consideration.
          </p>

          <h3>Information We Collect</h3>
          <p>
            We collect information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked to a particular person or device (also known as “Personal
            Information”). In particular, we may receive and/or obtain the
            following types of Personal Information: <br />
            (i) contact information such as name, address, phone number, email
            address;
            <br />
            (ii) demographic information such as date of birth, gender, marital
            status;
            <br />
            (iii) government ID numbers such as social security number (SSN),
            driver’s license, passport;
            <br />
            (iv) financial account information;
            <br />
            (v) responses to any surveys you complete in relation to or
            regarding your experience with our products or services; and/or{" "}
            <br />
            (vi) any other information collected in the ordinary course of
            business in order to provide our products and/or services. We also
            collect browsing information from your Internet browser, computer,
            and/or mobile device if you visit or use our Sites, including, but
            not limited to, Internet Protocol (IP) address or device ID/UDID,
            protocol and sequence information, browser language and type, domain
            name system requests, browsing history (such as time spent at a
            domain, time and date of your visit and number of clicks), http
            headers, application client and service banners and operating system
            data (collectively, “Browsing Information”).
          </p>

          <h3>Use of Personal Information</h3>
          <p>
            We may use the Personal Information we collect for one or more of
            the following business purposes:
          </p>
          <ul>
            <li>To collect a debt;</li>
            <li>
              To fulfill or meet the reason you provided the information,
              including responding to your inquiries, questions and/or other
              requests or providing our services and products;
            </li>
            <li>
              To support, develop and improve our Sites, products and services;
            </li>
            <li>
              To create, maintain, customize and secure your account with us;
            </li>
            <li>To process your requests and payments and prevent fraud;</li>
            <li>
              To help maintain the safety, security and integrity of our Sites,
              products and services, databases and other technology assets;
            </li>
            <li>Marketing purposes;</li>
            <li>Enforcing our Terms of Use; and/or</li>
            <li>
              For such other purposes as may be described to you when collecting
              your Personal Information or as otherwise set forth in this
              Notice.
            </li>
          </ul>
          <p>
            We will not collect additional types of Personal Information or use
            the Personal Information we collected for materially different,
            unrelated or incompatible purposes without providing you prior
            notice. See “Changes to our Privacy Notice” for further details.
          </p>

          <h3>Disclosing Personal Information</h3>
          <p>
            We implement and maintain physical, electronic and procedural
            safeguards reasonably designed to: (i) protect the security,
            confidentiality and integrity of your Personal Information; (ii)
            prevent unauthorized use, access to, disclosure, loss or theft of
            your Personal Information; and (iii) prevent and detect unauthorized
            intrusions into our systems. We do not disclose, make available or
            provide access to any of your Personal Information to any of our
            subsidiaries, affiliates, employees, contractors, service providers
            or third parties, other than those who require such Personal
            Information in order for us to provide our products and services and
            who are bound by confidentiality obligations or as otherwise
            provided for in this Notice. We carefully select and monitor any
            contractors, service providers, third parties, potential investors
            or potential third-party acquirers who have access to your Personal
            Information and we require them to keep it confidential. However, we
            are not able to guarantee the security of our Sites.
          </p>

          <h3>Your Rights and Choices</h3>
          <p>
            It is your choice to submit Personal Information to us, regardless
            of whether such information is provided to us directly or
            indirectly. If you choose to opt out of submitting Personal
            Information to us we may not be able to provide certain of our
            services or products.
          </p>
          <p>
            As permitted by law, you may opt-out of disclosure or use of your
            Personal Information by notifying us by one of the methods at the
            end of this Notice.
          </p>

          <h3>We Tell You How and Why We Use Web Technologies.</h3>
          <p>
            Here is how and why we use some common web technologies to help
            manage our websites:
          </p>
          <ul>
            <li>
              Cookies: A "cookie" is a small piece of information that our
              website may provide to your browser while you are at our sites. We
              may use web-based tools, like cookies to see which areas of our
              site users prefer (e.g., based on the number of visits to those
              areas) which will help us better market. This is done through a
              tracking utility that allows us to reconstruct activity from a
              session or by a user, for troubleshooting and issue resolution
              purposes. MakeGood may also employ service providers to help us
              collect and understand our website usage data. These service
              providers may also use cookies. We do not process information
              through cookies to identify a particular individual. Cookies are
              stored on your device and you have full access and control over
              their use. You may deactivate or restrict the transmission of
              cookies by changing the settings of your web browser. Cookies that
              are already stored may be deleted at any time. If you visit our
              website with cookies deactivated, you may not be able to use all
              the functions of our site fully. When periodic surveys are
              presented to website visitors, cookies are used to prevent issuing
              multiple invitations to the same individual.
            </li>
            <li>
              If you are a registered member on the MakeGood website, you will
              receive a cookie when you log in. This cookie is maintained by
              your browser and contains your identifier. This cookie is also
              used to authenticate your identity and provide you with access to
              areas of our website that are limited to registered members, such
              as those that allow you to view and manage your personal profile.
            </li>
            <li>
              Additionally, MakeGood service providers that serve ads on
              affiliate and/or advertiser websites may assign different cookies
              and small graphical images called single-pixel GIFs or web
              beacons, to your browser to track the effectiveness of our
              advertising on other websites and your involvement with MakeGood.
              We may also employ service providers who may assign cookies or web
              beacons to your browser to assist us in collecting website usage
              data such as your IP address, session ID, URL and demographic
              information such as your zip code. The collection of data may
              include personally identifiable information. We do not track URLs
              that you type into your browser, nor do we track you across the
              internet once you leave our site.
            </li>
            <li>
              Your web browser may be set to accept cookies automatically, but
              can be changed to decline them. If you wish to remove cookies
              provided by MakeGood, please check your browser's settings and its
              Help section for instructions for removing cookies from your
              browser. If you decline to accept cookies, then you may be
              required to re-register each time you download or request
              materials from the website.
            </li>
            <li>
              We may use internal or third party advertising companies to
              collect non-personally identifiable information for online
              behavioral advertising to provide targeted display advertisements,
              through participating publishers and advertisers, that are likely
              to be of greater interest to you. Such information may include
              non-personally identifiable information (e.g., click stream
              information, browser type, time and date, subject of
              advertisements clicked or scrolled over) through the use of
              cookies and other technology.
            </li>
            <li>
              Website usage data: Our website tracks usage data, including, for
              example, your IP address, your browser type and version, which
              pages you view, which page, if any, linked you to our site, and
              which link, if any, you follow off of our site. We use this data
              in the aggregate and on an individual level to better understand
              website activity to improve our site offerings, to reconstruct
              activity from a session or by a user, for troubleshooting and
              issue resolution purposes. We may also use this data to provide
              you a more personalized website experience, assistance with
              technical support questions, to send you special offers, product
              and service updates, or other promotional materials that are
              relevant and tailored to your interests. If you do not want to
              receive these offers or promotions, simply indicate your contact
              preferences during the registration process, within any future
              communications to us or by sending an email to
              optout@makegoodapp.com. We do not share your information with
              outside companies for their promotional use, however we do use
              certain functionality from Google and others for data analytics
              and advertising us and our products on the internet. We have
              implemented the following features with Google: Tag Manager,
              Analytics, Optimize & Ads.
            </li>
            <li>
              Social media sites and Marketing security practices: We use social
              media to connect with and market to the public, our customers, and
              potential customers of the MakeGood family. Your activity on third
              party websites is governed by the security and privacy policies of
              each third party website. Users of third party websites often
              share information with the general public, user community, and/or
              the third party vendors operating these websites, which may use
              this information in a variety of ways. Consequently, you should
              review the privacy policies of the third party website before
              using it and ensure that you understand how this information may
              be used. You may be able to adjust privacy settings on your
              accounts on any third party website or application to match your
              preferences. The information posted on or directed at us through
              social media is generally available to the public. To protect your
              privacy, do not include information you want to keep private or
              any other sensitive personal information in your social media
              activity, comments or responses. This information may be archived
              independently on, and retention of such information is governed by
              the third party website. Additionally, we may use third party
              marketing platforms and other data analytics tools to analyze your
              preferences and certain details. In order to maximize these
              capabilities, we may store some data about you on those platforms.
            </li>
          </ul>

          <h3>Use of Personal Information</h3>
          <p>
            We give you the opportunity to control the use of your personal
            information for purposes outside of debt collection practices relied
            on by our clients. For example, occasionally, we may use your
            contact information to send you promotional communications about
            MakeGood products, including those of our affiliates. These
            communications may include email, telephone, texts and direct mail.
            If you don’t wish to receive these, you can tell us your contact
            preferences during the information collection process, or you can
            call us at (844) 328-4276 or send an email to
            optout@makegoodapp.com.
          </p>
          <p>
            We use Google for certain marketing purposes. If you wish to opt out
            of Google’s use of your information for marketing purposes, you may
            opt out through this link{" "}
            <a target='_blank' href='https://tools.google.com/dlpage/gaoptout/'>
              https://tools.google.com/dlpage/gaoptout/
            </a>{" "}
            and follow the instructions on the website.
          </p>
          <p>
            Additionally, you can manage your ad preferences with Google through
            the following link{" "}
            <a target='_blank' href='https://adssettings.google.com/'>
              https://adssettings.google.com/
            </a>
          </p>

          <p>
            Special Notice For California Residents:
            <br />
            Effective January 1, 2020, the California Consumer Privacy Act
            (CCPA) allows California residents, upon a verifiable consumer
            request, to request that a business that collects consumers’
            personal information to give consumers access, in a portable and (if
            technically feasible) readily usable form, to the specific pieces
            and categories of personal information that the business has
            collected about the consumer, the categories of sources for that
            information, the business or commercial purposes for collecting the
            information, and the categories of third parties with which the
            information was shared. California residents also have the right to
            submit a request for deletion of information under certain
            circumstances. Consistent with California law, if you choose to
            exercise your rights, we won’t charge you different prices or
            provide different quality of services unless those differences are
            related to your information.
            <br />
            We do not sell your personal information.
            <br />
            (1) The categories of personal information that we collected about
            you; and
            <br />
            (2) The categories of personal information that we disclosed about
            you for a business purpose.
            <br />
            The right to request us to delete the personal information it has
            collected from you, subject to certain legal exceptions, for
            example, when the personal information is necessary to complete a
            transaction for which we collected it or to comply with a legal
            obligation.
            <br />
            The right to be protected from discrimination for exercising your
            CCPA rights. We cannot and will not discriminate against you for
            exercising your rights under the statute, including by:
            <br />
            (A) denying you goods or services;
            <br />
            (B) charging you different prices or rates for goods or services,
            including through the use of discounts or other benefits or imposing
            penalties;
            <br />
            (C) providing you with a different level or quality of goods or
            services; or
            <br />
            (D) suggesting that you will receive a different price, rate, level,
            or quality of goods or services. Nothing prohibits a business from
            charging a different price or providing a different level or quality
            of service if the difference is reasonably related to the value
            provided to the consumer by the consumer’s data. For additional
            information on any of the above, or to submit a request for personal
            information under the CCPA, please contact us{" "}
            <a target='_blank' href='https://makegoodapp.com/contact/'>
              here
            </a>
            . Please note that we may claim legal exemptions for certain types
            of personal information from all or certain parts of the CCPA
            pursuant to various CCPA exemptions.
          </p>

          <h3>
            Ability to Update or Correct Your Personally Identifiable
            Information.
          </h3>
          <p>
            You can update or correct the contact information that you’ve given
            us, at any time. To change the information, contact our team at
            (844) 328-4276 or email us at changeinfo@makegoodapp.com.
          </p>

          <p>
            MakeGood recommends that you do not use email communications to send
            us sensitive personal information. If you need to change that
            personal information, you should call us directly.
          </p>

          <p>
            You may have rights in non-U.S. countries, such as objecting to, and
            blocking the processing of your personal information. For security
            reasons, MakeGood will take steps to authenticate your identity
            before providing you with access to personal information.
          </p>

          <h3>How We Protect Your Information</h3>
          <p>
            We maintain physical, electronic and procedural safeguards to
            protect your nonpublic personal information. Encryption technology
            is used when nonpublic personal information is transmitted over the
            internet. We restrict access to nonpublic personal information to
            those employees who need to know that information to provide our
            services to you. Our employees are bound by a Confidentiality
            Agreement requiring confidential treatment of all such information.
            We train our employees in security procedures and we conduct audits
            to verify compliance with these procedures.
          </p>

          <p>
            Additionally, we use industry-recognized security safeguards to
            protect your information from loss, misuse or unauthorization
            alteration. We use SSL transaction protocol for all email
            communication as well as strong encryption products, and use AWS
            hosting services.
          </p>

          <h3>Ways to Contact Us About Security and Privacy.</h3>
          <p>
            Questions regarding our website's privacy statement, our online
            Privacy Policy, our information practices or other aspects of
            privacy on our websites, should be directed to us at
            infosec@makegoodapp.com or in writing to MakeGood, Attn: Privacy
            Officer, 42 Spring Street, Suite 11, Newport, RI 02840. You may also
            call us at (844) 328-4276.
          </p>
        </div>
      </div>
    );
  }
}

export default MakeGoodPrivacy;
