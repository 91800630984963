import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import BottomScrollListener from "react-bottom-scroll-listener";
// import ReactGA from 'react-ga';

// API
import * as API from "../../../utils/api";

import { getZohoPagesense } from "../../../utils/helpers";

// Components
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import ObjectHistoryList from "../../../components/ObjectList/ObjectHistoryList";

class EmployeeChatHistory extends Component {
  constructor() {
    super();
    this.state = {
      savedData: [],
      fromChild: "",
      isLoading: true,
      hasMoreData: false,
      showFlash: false,
      offset: 1,
      search: {
        value: "",
      },
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getChatHistoryData();
    getZohoPagesense();
    // ReactGA.pageview("/ChatHistory");
  }

  getChatHistoryData() {
    const { user } = this.props;
    let offset = this.state.offset;
    let searchText = this.state.search.value;

    this.setState((state) => ({ ...state, showLoader: true }));

    API.fetchChatHistory(user, offset).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        let savedData = this.state.savedData;
        let newData = savedData.concat(data.data);
        this.setState((state) => ({
          savedData: newData,
          isLoading: false,
          hasMoreData: data.data.length < 10 ? false : true,
          showLoader: false,
        }));

        this.setState((state) => ({
          ...state,
          offset: data.offset,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
          showLoader: false,
        }));
      }
    });
  }

  handleContainerOnBottom = () => {
    if (this.state.hasMoreData) {
      let offsetCount = parseInt(this.state.offset) + 1;
      this.setState(
        (state) => ({
          ...state,
          offset: offsetCount,
        }),
        () => {
          this.getChatHistoryData();
        }
      );
    }
  };

  renderLatestPaymentDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      "Last Chat Date",
      "Admin Name",
      "Consumer Name",
      "Message Body",
    ];

    columns = data.map((row) => {
      let lastChatDate = new Date(row.created_at * 1000);
      return {
        primaryColumn: {},
        secondaryColumns: [
          {
            title: row.created_at
              ? this.renderLatestPaymentDate(lastChatDate)
              : "n/a",
          },
          {
            title: `${row.admin_name ? row.admin_name : "n/a"}`,
          },
          {
            title: `${row.consumer_name ? row.consumer_name : "n/a"}`,
          },
          {
            title: `${row.body ? row.body : "n/a"}`,
            optionalClasses: `${row.body ? ` message-ellipsis` : ""}`,
          },
        ],
        rowLinkPath: `/employee/view/chat/${row.consumer_id}`,
        fullRowIsClickable: true,
      };
    });
    return {
      headings,
      columns,
    };
  };

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <div style={{ marginTop: `10rem` }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div>
        {this.state.savedData.length > 0 ? (
          <BottomScrollListener
            offset={50}
            onBottom={this.handleContainerOnBottom}
            debounce={0}
          >
            {(scrollRef) => (
              <div>
                <ObjectHistoryList
                  optionalClasses={"object-debt-list object-list-color"}
                  data={this.getObjectListData(this.state.savedData)}
                  scrollRef={scrollRef}
                />
                {this.state.showLoader && (
                  <div>
                    <AppCanvasLoader />
                  </div>
                )}
              </div>
            )}
          </BottomScrollListener>
        ) : (
          <div className='admin-admins-empty-state'>
            <h2> No chat history available. </h2>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(EmployeeChatHistory);
