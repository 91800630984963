import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
// Components
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";
import Emitter from "../../utils/event-emitter";
import Tabs from "../../components/Tabs";

class GeneralSetting extends Component {
  state = {
    error: {
      hasAnyError: false,
      statusCode: 200,
    },
    activeTab: 1,
    invoiceData: [],
    preventionList: [],
    pursuitList: {},
    mergeVairablesData: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getAllMergeVariables();
  }

  reload = () => {
    this.getAllMergeVariables();
  };

  getAllMergeVariables = () => {
    this.setState({ isLoading: true });
    this.getTasksList();
    API.getOrgDetails(this.props.user).then((data) => {
      if ((typeof data != "undefined" || data != null) && data.data) {
        this.setState({ isLoading: true });
        API.getMergeVariablesListClient(
          this.props.user,
          data.data.organization.id
        ).then((data) => {
          let mergeVaribalesValuesObj = {};
          Object.keys(data.data).forEach(function (key) {
            if (
              key === "Colors" ||
              key === "Payment Address" ||
              key === "Payment Information" ||
              key === "Sign In and Payment URLs" ||
              key === "Sign In and Payment URLs"
            ) {
              if (key === "Colors") {
                Object.keys(data.data[key]).forEach(function (key2) {
                  data.data[key][key2].map((item, index) => {
                    mergeVaribalesValuesObj[item.variable_key] =
                      item.value === null ||
                      item.value === "" ||
                      item.value === undefined
                        ? item.default_value
                        : item.value;
                  });
                });
              } else {
                Object.keys(data.data[key]).forEach(function (key2) {
                  data.data[key][key2].map((item, index) => {
                    mergeVaribalesValuesObj[item.variable_key] =
                      item.value === null ||
                      item.value === "" ||
                      item.value === undefined
                        ? item.default_value
                        : "";
                  });
                });
              }
            } else if (key === "logo_url") {
              mergeVaribalesValuesObj["ORG_LOGO"] = data.data[key];
            }
          });
          this.setState((state) => ({
            ...state,
            mergeVairablesData: mergeVaribalesValuesObj,
          }));
          this.setState({ isLoading: false });
        });
      } else {
        this.setState({ isLoading: false });
        this.setState((state) => ({
          ...state,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };
  getTasksList = () => {
    API.getPaymentPlPlanTaskList(this.props.user).then((response) => {
      API.getTasksList(this.props.user).then((data) => {
        Emitter.emit("IS_QUICKBOOK_CONNECTED", data.qb_connected);
        if (
          typeof data != "undefined" ||
          (data != null && data.status_code && data.status_code === 200)
        ) {
          if (data.data) {
            this.setState({ invoiceData: data.data }, () => {
              this.formatData(response.data);
            });
          }
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      });
    });
  };

  formatData = (response) => {
    if (this.state.invoiceData && this.state.invoiceData.length) {
      this.state.invoiceData.map((dataItem, index) => {
        dataItem.delivery_method =
          dataItem.delivery_method === "Email"
            ? "Email Only"
            : dataItem.delivery_method === "Letter"
            ? "Letter Only"
            : "Email & Letter";
        if (!dataItem.state) {
          dataItem.is_all_client = true;
          dataItem.state = false;
          dataItem.auto_send = false;
          dataItem.auto_send_on_import = false;
          dataItem.internal = false;
          dataItem.delivery_method = dataItem.delivery_method;
        }
      });
      response.map((dataItem, index) => {
        dataItem.isPaymentPlanTask = true;
        dataItem.delivery_method = "Email Only";
        if (!dataItem.state) {
          dataItem.is_all_client = true;
          dataItem.state = false;
          dataItem.auto_send = false;
          dataItem.auto_send_on_import = false;
          dataItem.internal = false;
          dataItem.delivery_method = dataItem.delivery_method;
        }
      });
      const newList = [];
      let existingList = [];
      this.state.invoiceData.forEach((item, index) => {
        if (item.is_prevention) {
          newList.push(item);
        } else {
          existingList.push(item);
        }
      });
      existingList = [...existingList, ...response];
      this.setState({
        invoiceData: this.state.invoiceData,
        preventionList: newList,
        pursuitList: existingList,
      });
    }
  };

  //calls on row arrow expander click
  rowExpanderClick = (item) => {
    this.setState({ currentItem: item.row });
    let props = {
      item: item.row,
      reload: this.getTasksList,
      user: this.props.user,
    };
    // open Edit Task popup modal
    this.props.updateModalState(true, "EDIT_TASK_CONFIG", props);
  };

  //handle preview eye icon click
  handleViewClick = (e, data) => {
    let props = {
      selectedTemp: data,
      mergeVairablesData: this.state.mergeVairablesData,
    };
    //open preview Task popup modal
    this.props.updateModalState(true, "PREVIEW_TASK_CONFIG", props);
  };

  getSelectedConsumers = (data) => {
    let payload = {};
    let { currentItem } = this.state;
    payload.task_id = this.state.currentItem.id;
    payload.is_all_client = false;
    payload.contact_preferences = data;
    this.updateTaskSetting(payload);
  };

  //returns days based on "send_logic" flag
  getDays = (days, logic) => {
    let prefix = "";
    if (logic === "before") {
      prefix = "-";
    }
    if (logic === "after") {
      prefix = "+";
    }
    if (days && days > 0) {
      if (days === 1) {
        return prefix + days + " Day";
      } else {
        return prefix + days + " Days";
      }
    } else {
      return "0 Days";
    }
  };

  getObjectListData = (data) => {
    let headings = [],
      columns = [];

    headings = [
      {
        name: "Communication Tasks",
        style: { width: "15%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Task State",
        style: { width: "15%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Auto Send",
        style: { width: "10%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Delivery Schedule",
        style: {
          width: "12.5%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Delivery Method",
        style: {
          width: "12.5%",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        name: "Client Contact Preferences",
        style: { width: "15%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Preview",
        style: { width: "19%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "expander",
        style: { width: "1%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((item, index) => {
      return {
        row: item,
        primaryRow: {
          key: "Communication Tasks",
          title: item.name,
        },
        secondaryColumns: [
          {
            title: item.name,
            hideForMobile: true,
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Task State",
            title: <ToggleSwitch isOn={item.state} />,
            style: { width: "15%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Auto Send",
            title: <ToggleSwitch isOn={item.auto_send} />,
            style: { width: "10%", display: "flex", justifyContent: "center" },
          },
          {
            key: "Delivery Schedule",
            title:
              item.name === "Send Monthly Statements"
                ? "Monthly"
                : this.getDays(item.reminder_schedule, item.send_logic),
            style: { width: "12.5%", display: "flex" },
          },
          {
            key: "Delivery Method",
            title: item.state ? item.delivery_method : "-",
            style: { width: "12.5%", display: "flex" },
          },
          {
            key: "Client Contact Preferences",
            title: item.isPaymentPlanTask
              ? "-"
              : item.is_all_client
              ? "All Clients"
              : "Selected",
            style: { width: "15%", display: "flex" },
          },
          {
            key: "Preview",
            title: (
              <a
                className={`object-row-expander`}
                onClick={(event) => this.handleViewClick(event, item)}
                // style={{ position: "absolute", marginTop: "-8px" }}
              >
                <svg
                  className='icon object-row-view'
                  // style={{ position: "absolute", top: "6px", left: "-7px" }}
                >
                  <use xlinkHref='#view' />
                </svg>
              </a>
            ),
            style: {
              width: "15%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            rowType: "arrow-click",
            optionalClasses: "expander",
          },
        ],
      };
    });

    return {
      headings,
      columns,
    };
  };
  handleTabChange = (tabIndex) => {
    this.setState({
      activeTab: tabIndex,
    });
  };

  render() {
    let { appName } = this.props;
    let { isLoading, error, preventionList, pursuitList, activeTab } =
      this.state;
    let tabLabels = ["Prevention", "Pursuit"];

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return isLoading ? (
      <div style={{ marginTop: "20%" }}>
        <AppCanvasLoader />
      </div>
    ) : (
      <div className='consumer-help'>
        <Helmet>
          <title>{appName} | Tasks</title>
        </Helmet>
        <div className='big-device-grad '>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className='he-hed'>Task Configurations</div>
            <div style={{ marginBottom: "2rem" }}>
              <Tabs
                labels={tabLabels}
                activeTarget={activeTab}
                isTaskTab={true}
                handleTabChange={this.handleTabChange}
                optionalStyle={{
                  justifyContent: "flex-start",
                  display: "flex",
                  margin: "0",
                }}
                optionalInTabClasses='mg-tabs-tab'
                optionalClasses='consumer-pay-tab tp-sp con-fit services-tab'
              />
            </div>
          </div>
          {/* cardview for mobile device */}
          <ClientsCardView
            data={this.getObjectListData(
              activeTab === 1 ? preventionList : pursuitList
            )}
            expanderLabel={"Edit"}
            rowActionClick={(event) => this.rowExpanderClick(event)}
          />
          {/* list view for big devices */}
          <InvoiceObjectList
            data={this.getObjectListData(
              activeTab === 1 ? preventionList : pursuitList
            )}
            // optionalTableClasses='ts-con-tb'
            optionalClasses='scrollbar in-sc'
            rowActionClick={(event) => this.rowExpanderClick(event)}
            hideForMobile={true}
          />
        </div>
      </div>
    );
  }
}

export default GeneralSetting;
