import React, { Component } from 'react';
import { withRouter } from "react-router-dom";


// Packages
import 'react-select/dist/react-select.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'

// Components
import Modal from '../Modal';

// Utils
import { isValidDate } from '../../../../utils/helpers';

class LobFilterModal extends Component {
    state = {
        input: {
            from: {
                value: "",
                hasError: false,
            },
            to: {
                value: "",
                hasError: false,
            },
            filterText: {
                value: "",
                hasError: false,
            }
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const { from, to, filterText } = this.state.input;
        const { action } = this.props.optionalProps;
        const input = {
            action: action,
            from: from.value ? from.value.format('YYYY-MM-DD') : '',
            to: to.value ? to.value.format('YYYY-MM-DD') : '',
            filterText: filterText.value
        };

        if (action === 'date' && this.validateFormForDate()) {
            this.props.optionalProps.handlefilterInput(input)
            this.handleModalClose();
        }

        if (this.validateForm()) {
            this.props.optionalProps.handlefilterInput(input)
            this.handleModalClose();
        }
    }


    handleInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            input: {
                ...state.input,
                ...newPartialInput
            }
        }))
    }

    validateFormForDate = () => {
        let errorsArePresent = false;

        // Check Debts' Inputs for Errors
        let filterInputText = this.state.input;
        Object.entries(filterInputText).forEach(([key, value]) => {
            if (key === 'from') {
                if (!isValidDate(value.value.format('MM/DD/YYYY'))) {
                    errorsArePresent = true;
                    filterInputText[key].hasError = true;
                }
            } else if (key === 'to') {
                if (!isValidDate(value.value.format('MM/DD/YYYY'))) {
                    errorsArePresent = true;
                    filterInputText[key].hasError = true;
                }
            } else if (value.isRequired && value.value === '') {
                errorsArePresent = true;
            }
        })

        if (this.compareDate(filterInputText.from.value.format('DD/MM/YYYY')) >
            this.compareDate(filterInputText.to.value.format('DD/MM/YYYY'))) {
            errorsArePresent = true;
            filterInputText.to.hasError = true;
        }

        if (errorsArePresent) {
            this.setState(state => ({
                ...state,
                input: filterInputText
            }))
        } else {
            return true;
        }
    }

    compareDate(date) {
        var parts = date.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    validateForm = () => {
        let errorsArePresent = false;

        // Check Debts' Inputs for Errors
        let filterInputText = this.state.input;
        Object.entries(filterInputText).forEach(([key, value]) => {
            if (key === 'filterText') {
                if (value.value === '' || value.value === null) {
                    errorsArePresent = true;
                    filterInputText[key].hasError = true;
                }
            } else if (value.isRequired && value.value === '') {
                errorsArePresent = true;
            }
        })

        if (errorsArePresent) {
            this.setState(state => ({
                ...state,
                input: filterInputText
            }))
        } else {
            return true;
        }

    }

    renderErrorMessage(input) {
        let message = '';
        if (input === 'from') {
            message = 'Please enter a valid date';
        } else if (input === 'to') {
            message = 'Please enter a valid date';
        } else if (input === 'filterText') {
            message = 'Please enter a text for filter';
        } else {
            message = 'Please complete this field';
        }

        return (
            <div className="input-error-message">
                {message}
            </div>
        )
    }

    handleModalClose = () => {
        this.props.hideModal();
    }


    dismissFlash = () => {
        this.handleModalClose();
        this.setState(state => ({
            ...state,
            showFlash: false,
            flashMessage: ""
        }))
    }

    render() {
        let { input } = this.state;
        const { action, title } = this.props.optionalProps;

        return (
            <Modal
                title={"Filter by " + title}
                closeOnClickOutside={true}
                hideModal={this.props.hideModal}
                style={{ maxWidth: `35em` }}
            >
                <form
                    style={{ margin: `0% auto`, maxWidth: `20em` }}
                    onSubmit={(event) => this.handleSubmit(event)}
                >
                    {action === 'date' ?
                        <div>
                            <div className="admin-form-row">
                                <div className={`input-container${input.from.hasError ? ' error' : ''}`} style={{ width: `45%` }}>
                                    <label htmlFor="from" style={{ display: `flex` }}>From</label>
                                    <DatePicker
                                        selected={input.from.value}
                                        onChange={date => this.handleInputChange({ from: { value: date ? date : '', hasError: false } })}
                                        placeholderText="mm/dd/yyyy"
                                        maxDate={moment()}
                                    />
                                    {input.from.hasError ? this.renderErrorMessage('from') : null}
                                </div>
                                <div className={`input-container${input.to.hasError ? ' error' : ''}`} style={{ width: `45%` }}>
                                    <label htmlFor="to" style={{ display: `flex` }}>To</label>
                                    <DatePicker
                                        selected={input.to.value}
                                        onChange={date => this.handleInputChange({ to: { value: date ? date : '', hasError: false } })}
                                        placeholderText="mm/dd/yyyy"
                                        maxDate={moment()}
                                    />
                                    {input.to.hasError ? this.renderErrorMessage('to') : null}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`input-container${input.filterText.hasError ? ' error' : ''}`} style={{ width: `100%` }}>
                            <input
                                name="filterText"
                                placeholder=""
                                type="text"
                                value={input.filterText.value}
                                onChange={event => this.handleInputChange({ filterText: { value: event.target.value, hasError: false } })}
                            />
                            {input.filterText.hasError ? this.renderErrorMessage('filterText') : null}
                        </div>
                    }
                    <input type="submit" value="Submit" className="cta mg-brand2-color" style={{ marginTop: `5%` }} />
                </form>
            </Modal>
        )
    }
}

export default withRouter(LobFilterModal);
