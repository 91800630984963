import React, { Component } from 'react';

// Packages
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import MaskedTextInput from 'react-text-mask';


class ClientsSkipPaymentForm extends Component {
    state = {
        input: {
            skipPayment: {
                value: "",
                hasError: false,
            },
            frequency: {
                value: "",
                hasError: false,
            }
        }
    }

    componentDidMount() {
        const { savedData } = this.props;

        this.setState({
            input: {
                skipPayment: {
                    value: savedData ? savedData.value : "",
                    hasError: false,
                },
                frequency: {
                    value: savedData ? savedData.frequency : "",
                    hasError: false,
                }
            }
        })
    }

    handleInputChange(newPartialInput) {
        this.setState(state => ({
            ...state,
            input: {
                ...state.input,
                ...newPartialInput
            }
        }))
    }

    resetInputFields = () => {
        this.setState(state => ({
            input: {
                skipPayment: {
                    value: "",
                    hasError: false,
                },
                frequency: {
                    value: "",
                    hasError: false,
                }
            }
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.validateFormAndSetErrorsIfNecessary()) {
            let { input } = this.state;

            let inputValues = {
                skip_payment_value: input.skipPayment.value,
                skip_payment_frequency: input.frequency.value
            }

            this.props.handleSubmit(inputValues);
            // this.resetInputFields();
        }
    }

    validateFormAndSetErrorsIfNecessary = () => {
        let errorsArePresent = false;
        let newInputState = this.state.input;
        const { skipPayment, frequency } = this.state.input;

        Object.entries(newInputState).forEach(([key, value]) => {
            if (value.value === '') {
                newInputState[key].hasError = true;
                errorsArePresent = true;
            }
        });

        if (skipPayment.value == 'quarter' && frequency.value > 3) {
            errorsArePresent = true;
            frequency.hasError = true;
        }

        if (skipPayment.value == 'half_year' && frequency.value > 6) {
            frequency.hasError = true;
            errorsArePresent = true;
        }

        if (skipPayment.value == 'year' && frequency.value > 12) {
            frequency.hasError = true;
            errorsArePresent = true;
        }

        if (errorsArePresent) {
            this.setState(state => ({
                ...state,
                input: newInputState
            }))
        } else {
            return true;
        }
    }

    renderErrorMessage(input) {
        let message = '';

        if (input === 'skipPayment') {
            message = "Please select an option.";
        } else if (input === 'frequency') {
            message = "Enter valid frequency.";
        } else {
            message = 'Please complete this field';
        }

        return (
            <div className="input-error-message">
                {message}
            </div>
        )
    }

    render() {
        const { input } = this.state;

        var selectOptions = [
            { value: 'quarter', label: 'Once a quarter' },
            { value: 'half_year', label: 'Once in half year' },
            { value: 'year', label: 'Once a year' }
        ];

        return (
            <form
                style={{ margin: `5% auto`, maxWidth: `20em` }}
                onSubmit={(event) => this.handleSubmit(event)}
            >
                <div className="admin-form-row">
                    <div className={`input-container${input.skipPayment.hasError ? ' error' : ''}`} style={{ width: `70%` }}>
                        <label htmlFor="name">Skip Payment</label>
                        <Select
                            inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                            className="form-select"
                            name="form-field-name"
                            value={input.skipPayment.value}
                            placeholder="Select an option..."
                            options={selectOptions}
                            resetValue=""
                            onChange={event => this.handleInputChange({ skipPayment: { value: event ? event.value : '', hasError: false } })}
                        />
                        {input.skipPayment.hasError ? this.renderErrorMessage('skipPayment') : null}
                    </div>
                    <div className={`input-container${input.frequency.hasError ? ' error' : ''}`} style={{ width: `25%` }}>
                        <label htmlFor="name">Frequency</label>
                        <MaskedTextInput
                            style={{ paddingLeft: `20px` }}
                            guide={true}
                            mask={[/\d/, /\d/]}
                            name="frequency"
                            placeholder="0"
                            placeholderChar={'\u2000'}
                            type="text"
                            value={input.frequency.value}
                            onChange={event => this.handleInputChange({ frequency: { value: event ? event.target.value : '', hasError: false } })}
                        />
                        {input.frequency.hasError ? this.renderErrorMessage('frequency') : null}
                    </div>
                </div>
                <input type="submit" value="Submit" className="cta" style={{ marginTop: `5%` }} />
            </form>
        );
    }
}

export default ClientsSkipPaymentForm;
