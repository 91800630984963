import React, { Component } from "react";
import { Table } from "reactstrap";
import shortid from "shortid";
import moment from "moment";
import {
  getDollarAmount,
  getInvoiceDownload,
  getInvoiceNumber,
} from "../../../utils/helpers";
import downloadImg from "../../././../assets/icons/download.png";
import * as API from "../../../utils/api";
import styles from "./reports.module.scss";

class ClientsAgingDetailReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalItems: 0,
    };
  }

  componentDidMount() {
    let { data } = this.props;
    this.setState({ data: this.getReportData(data) });
    // this.getReportData(data);
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.totalItems != this.state.totalItems) {
    this.setState({ data: this.getReportData(nextProps.data) });
    // }
  }

  downloadInvoice = (doc_id, fileName) => {
    const { user } = this.props;
    API.getAttachedDocuement(this.props.props.user, doc_id, "debts").then(
      (data) => {
        if (data && data.status_code && data.status_code == 200) {
          if (data.data) {
            let flag = data.data.includes("base64");
            if (!flag) {
              data.data = "data:application/pdf;base64," + data.data;
            }
            let url = data.data;
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        } else {
          this.setState((state) => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
            showLoader: false,
          }));
        }
      }
    );
  };
  getRowTotal = (item) => {
    let total =
      Number(item.current_sum) +
      Number(item.sum_1_to_30) +
      Number(item.sum_30_to_60) +
      Number(item.sum_60_to_90) +
      Number(item.sum_90_to_120) +
      Number(item.sum_121_plus);
    return Number(total).toFixed(2);
  };

  getLastColumn = (title, list, data) => {
    data &&
      data.map((e, i) => {
        list[0] = title;
        list[1] = list[1]
          ? Number(list[1]) + Number(e.total_amt)
          : Number(e.total_amt);
        list[2] = list[2]
          ? Number(list[2]) + Number(e.balance)
          : Number(e.balance);
      });
    return list;
  };
  handleClick = (data) => {
    let { history } = this.props.props;
    // const id = this.props.match.params.id;
    history.push({
      pathname: `/client/invoices/${data.id}`,
      state: {
        invoiceDetails: data,
        backPath: `/client/reports/1`,
      },
    });
  };

  getOverAllTotal = () => {
    let { data } = this.props;
    let list = [];
    let total = [];
    data.total_current && list.push(data.total_current);
    data.total_1_30 && list.push(data.total_1_30);
    data.total_30_60 && list.push(data.total_30_60);
    data.total_60_90 && list.push(data.total_60_90);
    data.total_90_120 && list.push(data.total_90_120);
    data.total_121_plus && list.push(data.total_121_plus);

    list &&
      list.map((item, i) => {
        total[0] = "Total";
        total[1] = total[1]
          ? Number(total[1]) + Number(item[1])
          : Number(item[1]);
        total[2] = total[2]
          ? Number(total[2]) + Number(item[2])
          : Number(item[2]);
      });
    return total;
  };

  getDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    }
    return "n/a";
  };
  getReportData = (data) => {
    let downloadInvoice = this.downloadInvoice;
    let handleClick = this.handleClick;
    let total_current = [];
    let total_1_30 = [];
    let total_30_60 = [];
    let total_60_90 = [];
    let total_90_120 = [];
    let total_121_plus = [];

    return {
      headings: [
        "Date",
        "Transaction Type",
        "Num",
        "Customer",
        "Due Date",
        "Amount",
        "Open Balance",
        "Action",
      ],

      current_list_data:
        data.current_list_data &&
        data.current_list_data.map((item, index) => {
          let downloadInvoice = this.downloadInvoice;
          let handleClick = this.handleClick;
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: getInvoiceNumber(item, handleClick),
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(
                  Number(item.total_amt) + (parseFloat(item.tax_amount) || 0)
                ),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
              {
                title: getInvoiceDownload(item, downloadInvoice),
              },
            ],
          };
        }),
      data_1_to_30:
        data.data_1_to_30 &&
        data.data_1_to_30.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: getInvoiceNumber(item, handleClick),
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
              {
                title: getInvoiceDownload(item, downloadInvoice),
              },
            ],
          };
        }),
      data_30_to_60:
        data.data_30_to_60 &&
        data.data_30_to_60.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: getInvoiceNumber(item, handleClick),
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
              {
                title: getInvoiceDownload(item, downloadInvoice),
              },
            ],
          };
        }),
      data_60_to_90:
        data.data_60_to_90 &&
        data.data_60_to_90.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: getInvoiceNumber(item, handleClick),
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
              {
                title: getInvoiceDownload(item, downloadInvoice),
              },
            ],
          };
        }),
      data_90_to_120:
        data.data_90_to_120 &&
        data.data_90_to_120.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: getInvoiceNumber(item, handleClick),
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
              {
                title: getInvoiceDownload(item, downloadInvoice),
              },
            ],
          };
        }),
      data_121_plus:
        data.data_121_plus &&
        data.data_121_plus.map((item, index) => {
          return {
            secondaryColumns: [
              {
                title: this.getDate(item.txn_date),
              },
              {
                title: "Invoice",
              },
              {
                title: getInvoiceNumber(item, handleClick),
              },
              {
                title: item.display_name,
              },
              {
                title: this.getDate(item.due_date),
              },
              {
                title: getDollarAmount(Number(item.total_amt)),
              },
              {
                title: getDollarAmount(Number(item.balance)),
              },
              {
                title: getInvoiceDownload(item, downloadInvoice),
              },
            ],
          };
        }),
      total_current: this.getLastColumn(
        "Total for Current",
        total_current,
        data.current_list_data
      ),
      total_1_30: this.getLastColumn(
        "Total for 1 - 30 days past due",
        total_1_30,
        data.data_1_to_30
      ),
      total_30_60: this.getLastColumn(
        "Total for 31 - 60 days past due",
        total_30_60,
        data.data_30_to_60
      ),
      total_60_90: this.getLastColumn(
        "Total for 61 - 90 days past due",
        total_60_90,
        data.data_60_to_90
      ),
      total_90_120: this.getLastColumn(
        "Total for 91 - 120 days past due",
        total_90_120,
        data.data_90_to_120
      ),
      total_121_plus: this.getLastColumn(
        "Total for 121+ days past due",
        total_121_plus,
        data.data_121_plus
      ),
    };
  };

  renderTableBody = (headerTitle, data, lastData) => {
    return (
      <tbody>
        <tr>
          <td className={`${styles.rowData} inner-title`}>{headerTitle}</td>
        </tr>
        {data &&
          data.map((item, index) => {
            return (
              <tr
                key={shortid.generate()}
                className={`${styles.rowData} row-total`}
              >
                {item.secondaryColumns &&
                  item.secondaryColumns.map((col) => {
                    return (
                      <td key={shortid.generate()} className={styles.rowData}>
                        {col.title}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        <tr className='row-total'>
          {lastData &&
            lastData.map((col, i) => {
              return (
                <td
                  key={shortid.generate()}
                  className={`${styles.rowData} total-sub-header`}
                >
                  {i === 0 ? col : getDollarAmount(Number(col))}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  renderOverAllTotal = () => {
    let total = this.getOverAllTotal();
    return (
      <tbody>
        <tr className='row-total over-all-total'>
          {total &&
            total.map((col, i) => {
              return (
                <td key={shortid.generate()} className={"total-sub-header"}>
                  {i === 0 ? col : getDollarAmount(Number(col))}
                </td>
              );
            })}
        </tr>
      </tbody>
    );
  };

  render() {
    let { data } = this.state;
    return (
      <Table striped hover className='aging-detail-report'>
        <thead className={styles.headerFix}>
          <tr>
            {data.headings &&
              data.headings.map((header) => {
                return (
                  <th
                    key={shortid.generate()}
                    className={styles.headingStyle}
                    style={{ borderTop: "none" }}
                  >
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        {/* <div responsive hover className="aging-detail-report scrollbar" > */}
        {data.current_list_data && data.current_list_data.length ? (
          this.renderTableBody(
            "Current",
            data.current_list_data,
            data.total_current
          )
        ) : (
          <tbody />
        )}
        {data.data_1_to_30 && data.data_1_to_30.length ? (
          this.renderTableBody(
            "1 - 30 days past due",
            data.data_1_to_30,
            data.total_1_30
          )
        ) : (
          <tbody />
        )}
        {data.data_30_to_60 && data.data_30_to_60.length ? (
          this.renderTableBody(
            "31 - 60 days past due",
            data.data_30_to_60,
            data.total_30_60
          )
        ) : (
          <tbody />
        )}
        {data.data_60_to_90 && data.data_60_to_90.length ? (
          this.renderTableBody(
            "61 - 90 days past due",
            data.data_60_to_90,
            data.total_60_90
          )
        ) : (
          <tbody />
        )}
        {data.data_90_to_120 && data.data_90_to_120.length ? (
          this.renderTableBody(
            "91 - 120 days past due",
            data.data_90_to_120,
            data.total_90_120
          )
        ) : (
          <tbody />
        )}
        {data.data_121_plus && data.data_121_plus.length ? (
          this.renderTableBody(
            "121+ days past due",
            data.data_121_plus,
            data.total_121_plus
          )
        ) : (
          <tbody />
        )}
        {this.renderOverAllTotal()}
        {/* </div> */}
      </Table>
    );
  }
}

export default ClientsAgingDetailReport;
