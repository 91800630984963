import React, { Component } from "react";

class Tabs extends Component {
  renderActiveClass(activeTarget, tabIndex) {
    return tabIndex === activeTarget ? " active" : "";
  }

  checkPrimaryColor = (props) => {
    return this.props.hasOwnProperty("appThemeData")
      ? this.props.appThemeData.hasOwnProperty("primary_color")
        ? this.props.appThemeData.primary_color
        : "#0ab894"
      : "#0ab894";
  };
  checkActiveTab(activeTarget, tabIndex) {
    return tabIndex === activeTarget
      ? { backgroundColor: this.checkPrimaryColor(this.props),border: '1px solid #a1a1a1', width:"10em" }
      : { display: "block" , width:"10em"};
  }
  handleTabClick = (event) => {
    let targetElement = event.target.getAttribute("data-target");
    this.props.handleTabChange(parseInt(targetElement, 10) + 1);
  };

  render() {
    const {
      labels,
      activeTarget,
      optionalStyle,
      optionalClasses,
      optionalInTabClasses,
    } = this.props;
    return (
      <div
        className={`tabs${
          optionalClasses ? " " + optionalClasses : ""
        }  mg-mt-10`}
        style={optionalStyle}
      >
        {labels.map((label, index) => {
          return (
            <a
              key={index}
              className={`tabs-tab${this.renderActiveClass(
                activeTarget,
                index + 1
              )}${optionalInTabClasses ? " " + optionalInTabClasses : ""}`}
              style={this.checkActiveTab(activeTarget, index + 1)}
              data-target={index}
              onClick={(event) => this.handleTabClick(event)}
            >
              {label}
            </a>
          );
        })}
      </div>
    );
  }
}

export default Tabs;
