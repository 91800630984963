import React, { Component } from "react";
import Modal from "../Modal";

import * as API from "../../../../utils/api";

class PlaceInCollectionConfirmModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTime: false,
      showLoader: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isFirstTime") === "true") {
      this.setState({ isFirstTime: false });
    } else {
      this.setState({
        isFirstTime: this.props.optionalProps.isFirstTimeInCollection,
      });
    }
  }

  reverseCreditMemo = (item) => {};

  handleModalClose = () => {
    // this.setState({isFirstTime: true})
    this.props.hideModal();
  };

  invoicesToCollection = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    let { isFirstTime } = this.state;
    if (isFirstTime) {
      API.clientPlacementTerm(this.props.optionalProps.user).then((res) => {
        if (res.message === "success" && res.status_code === 200) {
          localStorage.setItem("isFirstTime", true);
          this.setState({ isFirstTime: false });
        }
        if (res.status_code === 400) {
          this.props.updateModalState(true, "ERROR", {
            message: res.message,
            title: "Alert",
          });
          this.handleModalClose();
        }
      });
    } else {
      let {
        user,
        multipleInvoiceData,
        invoiceDetails,
        closeInvoiceListForCollection,
      } = this.props.optionalProps;
      API.sendInvoicesToCollection(
        user,
        invoiceDetails.user.user_id,
        multipleInvoiceData.invoiceListArray
      )
        .then((res) => {
          if (res && res.status_code === 200) {
            this.props.updateModalState(true, "SUCCESS", {
              message: res.message,
              title: "Alert",
            });
            localStorage.removeItem("isFirstTime");
            closeInvoiceListForCollection();
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: res.message,
              title: "Alert",
            });
          }
        })
        .catch(() => {});
    }
  };

  render() {
    let { isFirstTime } = this.state;
    let { multipleInvoiceData } = this.props.optionalProps;

    return (
      <Modal
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        optionalClasses='scroll'
      >
        <div
          style={{
            marginTop: "1.5em",
          }}
        >
          <div>
            <label
              style={{
                color: "#808080",
                fontFamily: "Open sans",
                fontSize: "24px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {isFirstTime
                ? "Account Placement Disclaimer"
                : "Place In Collections"}
            </label>
          </div>
          {isFirstTime ? (
            <div
              style={{
                color: "#000000",
                fontFamily: "Open sans",
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  color: "#000000",
                  fontFamily: "Open sans",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                {`Upon clicking “Agree”, the information required to place an invoice(s) in collections, including
information about your organization and data from your customer accounts and invoices will be sent to Eastern Account Systems, Inc. (“EAS”), a provider of debt collection services. MakeGood does receive a fee equal to a percentage of revenue received by EAS from MakeGood’s clients who use the services of EAS. Any transactions that you enter into with EAS are solely between you and EAS. MakeGood is not an agent for EAS and MakeGood does not endorse, guarantee or assume any liability for any EAS product and/or service. If you wish to continue, click “Agree”. If you do not wish to continue, click Cancel.`}
              </p>
            </div>
          ) : (
            <div
              style={{
                color: "#000000",
                fontFamily: "Open sans",
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  margin: "0px",
                  color: "#000000",
                  fontFamily: "Open sans",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                {` By clicking “Submit” you agree to place ${
                  multipleInvoiceData.invoiceListArray.length
                }  ${
                  multipleInvoiceData.invoiceListArray.length >= 2
                    ? "invoices"
                    : "invoice"
                } in collections with a professional collections agency. For invoices placed in collections, automated communications from your MakeGood account for these speciﬁc invoices will be paused. There is no upfront cost for this service. The collections agency may contact you for additional information. If you do not wish to proceed, click Cancel.`}
              </p>
              <p
                style={{
                  margin: "0px",
                  color: "#000000",
                  fontFamily: "Open sans",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Use the chat button below to contact us or email
                <a href='mailto:support@makegoodapp.com'>
                  {" "}
                  support@makegoodapp.com
                </a>{" "}
                for more information.
              </p>
            </div>
          )}
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <input
              className=''
              type='submit'
              value={"Cancel"}
              style={{
                float: "left",
                width: "fit-content",
                borderRadius: "0.5em",
                border: "2px solid",
                padding: "7px 10px",
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: "600",
              }}
              onClick={() => this.handleModalClose()}
            />
            <input
              type='submit'
              value={isFirstTime ? "Agree" : "Submit"}
              className='cta mg-brand2-color'
              disabled={this.state.showLoader}
              style={{
                float: "right",
                width: "fit-content",
                borderRadius: "0.5em",
                border: "2px solid",
                padding: "7px 10px",
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: "600",
              }}
              onClick={(e) => this.invoicesToCollection(e)}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default PlaceInCollectionConfirmModel;
