import React, { useEffect, useState } from "react";
import {
  getTestCreds,
  updateOnboardingPercentage,
  getBaseUrl,
  getOrgDetails,
} from "../../../utils/api";
import Emitter from "../../../utils/event-emitter";
import AppCanvasLoader from "../../../components/App/AppCanvasLoader";
import Cookies from "universal-cookie";
import styles from "../../../components/Buttons/button.module.scss";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

export default function PaymentPortalPreview({
  handleGoBackAction,
  organizationDetails,
  handleback,
  history,
  user,
  updateOnboardingComplete,
}) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    handleback();
    getTestCreds(user, true).then((res) => {
      setEmail(res.data.email);
      setPass(res.data.password);
    });
    return () => {
      localStorage.removeItem("type");
    };
  }, []);

  let style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  const getOnboardingPercentage = () => {
    setIsLoader(true);
    const cookies = new Cookies();
    let authuser = cookies.get("accessToken", { path: "/", secure: true });
    let user = {
      user_auth_token: authuser,
    };
    let percentage = 0;
    Object.entries(organizationDetails.onboarding_percentage).forEach(
      ([key, value]) => {
        if (key !== "style_and_preview") {
          if (value) {
            percentage = percentage + 11;
          }
        } else {
          if (value) {
            percentage = percentage + 12;
          }
        }
      }
    );
    if (percentage >= 88) {
      updateOnboardingPercentage(user, 88).then((res) => {
        setIsLoader(false);
        if (res.status_code == 200) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          handleback();
          history.push({
            pathname: "/client/dashboard",
            state: { progressPercentage: true },
          });
          updateOnboardingComplete();
        }
      });
    } else {
      handleback();
      history.push({
        pathname: "/welcome-screen",
        state: { progressPercentage: true },
      });
    }
  };

  const paymentPortal = (pathVariable) => {
    if (pathVariable) {
      // getTestCreds(user, pathVariable).then((res) => {
      //   if (res && res.status_code == 200) {
      var windowReferance = window.open();
      let url = `${getBaseUrl()}/welcome-screen/login?email=${email}&pass=${pass}`;
      // let url = `http://localhost:3000/welcome-screen/login?email=${email}&pass=${pass}`;
      windowReferance.location = url;
      //   }
      // });
    } else {
      var windowReferance = window.open();

      let url = `${getBaseUrl()}/welcome-screen/payment`;
      // let url = `http://localhost:3000/welcome-screen/payment?}`;

      windowReferance.location = url;
    }
  };
  return (
    <div className='tp-sps app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar payment-portal-margin'>
      {isLoader ? (
        <AppCanvasLoader />
      ) : (
        <div className='app-sidebar-layout-canvas-content setting-side-canvas col1-scroll payment-portal'>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <div style={{ width: "75%" }}>
              <h3
                style={{
                  flex: "2",
                  fontFamily: "Open Sans ,sans-serif",
                  color: "black",
                  fontWeight: "700",
                }}
                className='mg-head-bold'
              >
                Payment Portal Preview
              </h3>
            </div>
            <div style={{ width: "25%" }} className={styles.btnBox}>
              <SecondaryButton
                isBackButton={true}
                text={"Back"}
                title={`Go Back To \n Payments Preferences`}
                onClick={() => {
                  handleGoBackAction();
                }}
              />
              <PrimaryButton
                isNextButton={false}
                text={"Finish"}
                title={`Continue To \n Payment Portal Preview`}
                onClick={() => {
                  getOnboardingPercentage();
                }}
              />
            </div>
          </div>
          <p
            style={{
              padding: "0px",
              width: "auto",
              marginBottom: 30,
              flex: "2",
              fontFamily: "Open Sans ,sans-serif",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            Congrats! You are ready to accept payments from your customers with
            your company-branded, easy to use, Payment Portal.
          </p>
          <div
            className='pref-selection'
            style={{ marginBottom: 30, width: "100%" }}
          >
            <div className='pref-selection-header'>Full Payment Portal</div>
            <div className='pref-selection-sep' />
            <div className='pref-selection-inner-left2'>
              <p
                className='pref-selection-rdtx'
                style={{ fontWeight: 500, width: "60%" }}
              >
                With your Payment Portal, your customers can login to see all
                invoices, make payments, view billing history, update billing
                information and contact your accounts receivable department for
                help.
              </p>
              <button
                style={{
                  height: "50px",
                  width: "125px",
                  backgroundColor: "#0ab894",
                  borderRadius: "10px",
                  color: "white",
                  fontWeight: 600,
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                id='payment_portal_full_payment_tour'
                onClick={() => paymentPortal(true)}
              >
                Tour the Full Payment Portal
              </button>
            </div>
          </div>
          <div className='pref-selection' style={{ width: "100%" }}>
            <div className='pref-selection-header'>Express Payment Portal</div>
            <div className='pref-selection-sep' />
            <div className='pref-selection-inner-left2'>
              <p
                className='pref-selection-rdtx'
                style={{ fontWeight: 500, width: "60%" }}
              >
                Customers can also opt to use Express Pay, allowing them to make
                payments on a specific invoice without logging in.
              </p>
              <button
                style={{
                  height: "50px",
                  width: "125px",
                  backgroundColor: "#0ab894",
                  borderRadius: "10px",
                  color: "white",
                  fontWeight: 600,
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                id='payment_portal_exprs_payment_tour'
                onClick={() => paymentPortal(false)}
              >
                Tour the Express Payment Portal
              </button>
            </div>
          </div>
          {/* <div className='bottum-btns'>
            <div className='button-left'>
              <input
                type='submit'
                value='Go Back'
                id='payment_portal_go_back'
                style={{
                  borderRadius: "10px",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                  border: "2px solid",
                }}
                onClick={() => {
                  handleGoBackAction();
                }}
              />
              <span className='go-back-text'>
                <p style={style.pText}>Go back to</p>
                <p style={style.pText}>"Payments Preferences"</p>
              </span>
            </div>
            <div className='button-right'>
              <input
                type='submit'
                value='Finish'
                id='payment_portal_final_step'
                onClick={() => {
                  getOnboardingPercentage();
                }}
                className='cta mg-brand2-color emp-add-btn-mob'
                style={{
                  borderRadius: "10px",
                  width: "200px",
                  height: "50px",
                  marginTop: "20px",
                }}
              />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
}
