import { fromPairs } from "lodash";
import React, { Component } from "react";
import "react-select/dist/react-select.css";
import * as API from "../../../../utils/api";
import AppCanvasLoader from "../../../../components/App/AppCanvasLoader";
import { Redirect } from "react-router-dom";
import styles from "../../../../components/Buttons/button.module.scss";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
class SetUpPaymentAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripePaymentsDetails: {},
      Interval: "",
      isLoader: true,
      stripe_connect_status: "",
      isLoading: false,
    };
  }
  style = {
    pText: {
      fontSize: "14px",
      color: "#2b2828",
      margin: "0px",
    },
  };

  componentDidMount() {
    this.props.handleback();
    let Interval = setInterval(this.getStripeConnectDetailsCall, 2500);
    this.setState({ Interval });
  }
  componentWillUnmount() {
    clearInterval(this.state.Interval);
  }

  getStripeConnectDetailsCall = () => {
    API.getStripeConnectDetails(this.props.user)
      .then((data) => {
        if (data.data && data.status_code == 200) {
          if (
            data.data.stripe_connect_status === "ACCEPTING PAYMENTS" ||
            data.data.is_mg_portal === false
          ) {
            clearInterval(this.state.Interval);
          }
          if (
            this.state.stripe_connect_status !== data.data.stripe_connect_status
          ) {
            this.setState({ isLoader: true });
          }
          this.setState((state) => ({
            ...state,
            stripePaymentsDetails: data.data,
            stripe_connect_status: data.data.stripe_connect_status,
            isLoader: false,
          }));
        }
        this.setState({ isLoader: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoader: false });
      });
  };

  handleProceedPayment = () => {
    if (this.state.stripePaymentsDetails.details_submitted) {
      this.setState({ isLoader: true });
      API.getStripeDashboardLink(this.props.user)
        .then((data) => {
          if (data && data.data && data.status_code == 200) {
            //open redirection link in new tab
            window.open(data.data, "_blank");
          } else {
            if (data && data.data && data.status_code == 401) {
              this.props.updateModalState(true, "ERROR", {
                modalErr: "Toast",
                message: "Access denied, try to Re-login.",
              });
            } else if (
              data &&
              data.data &&
              data.status_code == 400 &&
              data.message
            ) {
              this.props.updateModalState(true, "ERROR", {
                modalErr: "Toast",
                message: data.message,
              });
            } else if (data && data.data && data.status_code == 500) {
              this.props.updateModalState(true, "ERROR", {
                modalErr: "Toast",
                message: "Internal Server Error",
              });
            } else {
              this.props.updateModalState(true, "ERROR", {
                modalErr: "Toast",
                message: "Something went wrong. Please try again.",
              });
            }
          }
          this.setState({ isLoader: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoader: false });
        });
    } else {
      this.props.updateModalState(true, "PROCEED_ENABLE_PAYMENTS", {
        // confirmClick: this.getQBData,
        closeOnClickOutside: true,
      });
    }
  };

  handleLearnMore = () => {
    this.props.updateModalState(true, "LEARN_MORE", {
      // confirmClick: this.getQBData,
      closeOnClickOutside: true,
    });
  };

  handleGoBackAction = () => {
    this.props.handleAction("GO_LIVE_DATE");
  };

  handleActionNext = () => {
    let { user } = this.props;
    let { stripePaymentsDetails } = this.state;
    this.setState({ isLoading: true });
    API.getOrgDetails(this.props.user).then((data) => {
      if (data && data.status_code == 200) {
        let percent = data.data.organization.onboarding_percentage;
        if (
          !stripePaymentsDetails.is_mg_portal ||
          (stripePaymentsDetails.is_mg_portal &&
            stripePaymentsDetails.details_submitted)
        ) {
          if (percent == 66.66) {
            API.updateOnboardingPercentage(user, percent);
          }
        }

        this.props.handleAction("PAYMENT_PREFERENCES");
      }
    });
  };
  render() {
    return (
      <>
        {this.state.isLoader || this.state.isLoading ? (
          <div className='loader_alignment'>
            <div style={{ marginTop: "20%" }}>
              <AppCanvasLoader />
            </div>
          </div>
        ) : (
          <div
            className='tp-sps app-sidebar-layout-canvas-content setting-side-canvas col1-scroll mg-col1-scroll scrollbar'
            style={{ marginRight: "20px" }}
          >
            <div className='main-setup-payment scrollbar'>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className='mg-head-bold'
                  style={{
                    fontFamily: "Open Sans",
                    maxWidth: "75%",
                    color: "black",
                  }}
                >
                  Set Up Your Payment Account
                </p>
                <div style={{ width: "25%" }} className={styles.btnBox}>
                  <SecondaryButton
                    isBackButton={true}
                    text={"Back"}
                    title={`Go Back To \n Schedule Your Go Live Date`}
                    onClick={() => this.handleGoBackAction()}
                  />
                  <PrimaryButton
                    isNextButton={true}
                    text={"Next"}
                    title={`Continue To \n Payments Preferences`}
                    onClick={() => this.handleActionNext()}
                  />
                </div>
              </div>
              <p
                style={{
                  margin: "0px",
                }}
              >
                We use Stripe to make sure you get paid on time and to keep your
                bank and details secure.
              </p>
              <p>Stripe offers these benefits:</p>
              <ul>
                <li>
                  Seamless payment acceptance & payment reconcilation experience
                  between your MakeGood & QuickBooks Online account
                </li>
                <li>Start Accepting Payments Within Minutes</li>
                <li>ACH Payments & Credit Card Accepted</li>
                <li>Apple Pay & Gpay for easy mobile payments</li>
                <li>
                  Full Billing History, Payment History, Billing Contact
                  Updates, Billing Inquiries
                </li>
                <li>
                  Guest Pay Option with no login required for Easy Payments
                </li>
              </ul>
              <p
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                Click the Enable Payments button below to get started.
              </p>
              <div style={{ width: "100%" }}>
                <input
                  type='submit'
                  id='setup_payment_account_stripe_or_enable_payment'
                  value={
                    this.state.stripePaymentsDetails.details_submitted
                      ? "Stripe Dashboard"
                      : "Enable Payments"
                  }
                  style={{
                    marginRight: "50px",
                  }}
                  className='cta mg-brand2-color proceed-payments dynamic-width'
                  onClick={() => this.handleProceedPayment()}
                />
                <input
                  className='learm-more'
                  id='setup_payment_account_info_button'
                  type='submit'
                  value='Learn More'
                  onClick={() => this.handleLearnMore()}
                />
              </div>
              <div style={{ width: "100%", float: "left" }}>
                <p
                  style={{
                    color:
                      this.state.stripePaymentsDetails.stripe_connect_status ==
                      "ACCEPTING PAYMENTS"
                        ? "#0ab894"
                        : this.state.stripePaymentsDetails
                            .stripe_connect_status == "PAYMENTS NOT ENABLED" ||
                          this.state.stripePaymentsDetails
                            .stripe_connect_status == "REJECTED"
                        ? "#ff0000"
                        : this.state.stripePaymentsDetails
                            .stripe_connect_status == "PENDING APPROVAL"
                        ? "#b3b3b3"
                        : this.state.stripePaymentsDetails
                            .stripe_connect_status == "INFORMATION NEEDED"
                        ? "#0d51ff"
                        : "#000000",
                    marginTop: "50px",
                    fontFamily: "Open Sans",
                    fontSize: "1.54rem",
                    fontWeight: "bold",
                  }}
                >
                  Status:{" "}
                  <span
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "1.7rem",
                      fontWeight: "bold",
                      color:
                        this.state.stripePaymentsDetails
                          .stripe_connect_status == "ACCEPTING PAYMENTS"
                          ? "#0ab894"
                          : this.state.stripePaymentsDetails
                              .stripe_connect_status ==
                              "PAYMENTS NOT ENABLED" ||
                            this.state.stripePaymentsDetails
                              .stripe_connect_status == "REJECTED"
                          ? "#ff0000"
                          : this.state.stripePaymentsDetails
                              .stripe_connect_status == "PENDING APPROVAL"
                          ? "#b3b3b3"
                          : "#000000",
                    }}
                  >
                    {this.state.stripePaymentsDetails.stripe_connect_status}
                  </span>
                </p>
              </div>
              {/* <div
                className='bottum-btns'
                style={{ width: "100%", float: "left" }}
              >
                <div className='button-left'>
                  <input
                    id='setup_payment_account_go_back'
                    className='go-back'
                    type='submit'
                    value='Go Back'
                    onClick={() => this.handleGoBackAction()}
                    style={{ marginTop: "15px" }}
                  />
                  <span className='go-back-text'>
                    <p style={this.style.pText}>Go back to</p>
                    <p style={this.style.pText}>"Schedule Your Go Live Date"</p>
                  </span>
                </div>
                <div className='button-right'>
                  <input
                    type='submit'
                    id='setup_payment_account_next'
                    value={"Next"}
                    className='cta mg-brand2-color submit'
                    onClick={() => this.handleActionNext()}
                    style={{ marginTop: "15px" }}
                  />
                  <span className='next-text'>
                    <p style={this.style.pText}>Continue to</p>
                    <p style={this.style.pText}>"Payments Preferences"</p>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SetUpPaymentAccount;
