import React, { Component } from 'react';

class SessionsModal extends Component {

  render() {
    let { children, optionalClassName } = this.props;

    return (
      <div className={`sessions-modal${optionalClassName ? ' ' + optionalClassName : ''}`}>
        {children}
      </div>
    );
  }
}

export default SessionsModal;
