import moment from "moment";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DropdownButton from "../../components/DropdownButton";
import MenuItem from "../../components/MenuItem";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import Panel from "../../components/App/Panel";
import ClientsAgingDetailReport from "../../components/Clients/Reports/ClientsAgingDetailReport";
import ClientsAgingSummaryReport from "../../components/Clients/Reports/ClientsAgingSummaryReport";
import ClientsInventoryStatusReport from "../../components/Clients/Reports/ClientsInventoryStatusReport";
import ClientsInventoryConsumptionReport from "../../components/Clients/Reports/ClientsInventoryConsumptionReport";
import ClientsCollectionsReport from "../../components/Clients/Reports/ClientsCollectionsReport";
import ClientsCustomerBalanceDetail from "../../components/Clients/Reports/ClientsCustomerBalanceDetail";
import * as API from "../../utils/api";
//import companyData from "../../assets/styles/theme.json";
import MaskedTextInput from "react-text-mask";
import InfoIcon from "../../assets/images/quickbooks/ic_info_gray.png";
import { isValidDate } from "../../utils/helpers";
import Select from "react-select";
import InventoryItemFilter from "../../components/App/Modals/Client/InventoryItemFilterModal";
import ClientReportFilterModal from "../../components/App/Modals/Client/ClientReportFilterModal";
class ClientsReportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportPeriod: "Today",
      reportSlug: "aging_detail",
      fromDate: "",
      toDate: "",
      data: [],
      AgingSummaryData: [],
      isLoading: true,
      isDownloading: false,
      error: {
        hasAnyError: false,
        statusCode: 200,
      },
      searchValue: "",
      input: {
        fromDate: {
          value: "",
          hasError: false,
        },
        toDate: {
          value: "",
          hasError: false,
        },
        filterPref: "All Items",
        filterPref2: "All Clients",
        is_all_items: true,
        item_count: 0,
        item_preferences: [],
        selected_items: [],
      },
      filterOptions: [
        {
          label: "All Items",
          value: "All Items",
          flag: true,
        },
        {
          label: "Selected",
          value: "Selected",
          flag: false,
        },
      ],
      filterOptions2: [
        {
          label: "All Clients",
          value: "All Clients",
          flag: true,
        },
        {
          label: "Selected",
          value: "Selected",
          flag: false,
        },
      ],
      showPrefPopUp: false,
      showPrefPopUp2: false,
      showPrefPopUp3: false,
      totalItems: 0,
      companyData: {},
    };
  }

  componentDidMount() {
    this.getOrganizationData();
    this.getReportData();
  }

  async getOrganizationData() {
    await API.getOrganizationData().then((data) => {
      this.setState({
        companyData: data,
      });
    });
  }

  getFormattedDate = (date) => {
    if (date) return moment(new Date(date)).format("YYYY-MM-DD");
    return date;
  };

  getReportData = (type = "") => {
    this.setState({
      isLoading: true,
    });
    let { totalItems, is_all_items } = this.state;
    const reportId = this.props.match.params.id;
    let fromDate = "";
    let toDate = "";
    if (this.state.input.fromDate.value && this.state.input.toDate.value) {
      fromDate = this.getFormattedDate(this.state.input.fromDate.value);
      toDate = this.getFormattedDate(this.state.input.toDate.value);
    }
    if (is_all_items) {
      this.setState({ totalItems: 0 });
    }

    if (type == "aging_detail") {
      // API.fetchAllClientsForTask().then((data) => {
      //   if (typeof data != "undefined" || data != null) {
      //     if (data.consumers) {
      //       totalItems = totalItems + data.consumers.length;
      //       this.setState({
      //         AgingSummaryData: [...data.consumers],
      //         reportName: "A/R AGING DETAIL REPORT",
      //         reportSlug: "aging_detail",
      //         totalItems: totalItems,
      //         isLoading: false,
      //       });
      //     }
      //     // this.setState({});
      //   } else {
      //     this.setState((state) => ({
      //       ...state,
      //       isLoading: false,
      //       error: {
      //         hasAnyError: true,
      //         statusCode: data
      //           ? data.status ||
      //             (data.status_code && data.status_code == 401 ? 401 : 500) ||
      //             500
      //           : 500,
      //       },
      //     }));
      //   }
      // });
    } else {
      API.arAgingSummaryList(
        this.props.user,
        reportId,
        fromDate,
        toDate,
        // ["Jack"]
        this.state.input.selected_items
      ).then((data) => {
        if (typeof data != "undefined" || data != null) {
          if (data.data) {
            if (data.report_slug == "inventory_consumption") {
              data.data.map((item) => {
                totalItems = totalItems + item.list.length;
              });
            } else if (data.report_slug == "aging_summary") {
              totalItems = totalItems + data.data.length;
            }

            this.setState((prevState) => ({
              ...prevState,
              AgingSummaryData: data.data,
              reportName: data.name,
              reportSlug: data.report_slug,
              totalItems,
              isLoading: false,
            }));
          }
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              statusCode: data
                ? data.status ||
                  (data.status_code && data.status_code == 401 ? 401 : 500) ||
                  500
                : 500,
            },
          }));
        }
      });
    }
  };

  validateForm = () => {
    let errorsArePresent = false;
    let newInputState = this.state.input;
    if (newInputState.fromDate.value === "") {
      errorsArePresent = true;
      newInputState["fromDate"].hasError = true;
    } else if (
      newInputState.fromDate.value &&
      !isValidDate(newInputState.fromDate.value)
    ) {
      errorsArePresent = true;
      newInputState["fromDate"].hasError = true;
    }

    if (newInputState.toDate.value === "") {
      errorsArePresent = true;
      newInputState["toDate"].hasError = true;
    } else {
      if (
        newInputState.toDate.value &&
        !isValidDate(newInputState.toDate.value)
      ) {
        errorsArePresent = true;
        newInputState["toDate"].hasError = true;
      }
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "fromDate" || input === "toDate") {
      message = "Please enter valid date";
    }
    return <div className='input-error-message'>{message}</div>;
  }

  downloadReport = (type) => {
    const reportId = this.props.match.params.id;
    const { reportName } = this.state;
    // $('#download-report').replaceWith('<div class="spinner"></div>');
    this.setState({ isDownloading: true });
    let fromDate = "";
    let toDate = "";

    if (this.state.input.fromDate.value && this.state.input.toDate.value) {
      fromDate = this.getFormattedDate(this.state.input.fromDate.value);
      toDate = this.getFormattedDate(this.state.input.toDate.value);
    }

    API.downloadReport(
      this.props.user,
      reportId,
      type.toUpperCase(),
      fromDate,
      toDate,
      this.state.input.selected_items
    ).then((data) => {
      if (typeof data != "undefined" || (data != null && !data.error)) {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${reportName ? reportName : "report"}.${type}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({ isDownloading: false });
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  handleInputChange = (value, type) => {
    if (type === "to") {
      this.setState({ toDate: value });
    } else {
      this.setState({ fromDate: value });
    }
  };

  handleInputFilterChange = (newPartialInput) => {
    if (newPartialInput.is_all_items) {
      newPartialInput.selected_items = [];
      newPartialInput.item_count = 0;
      this.setState(
        (state) => ({
          ...state,
          input: {
            ...state.input,
            ...newPartialInput,
          },
        }),
        () => {
          this.getReportData();
        }
      );
    } else {
      this.setState((state) => ({
        ...state,
        input: {
          ...state.input,
          ...newPartialInput,
        },
      }));
    }
  };

  renderDownloadBtn = (isMobile) => {
    let { isDownloading } = this.state;
    const reportId = this.props.match.params.id;

    return (
      <DropdownButton
        title={isDownloading ? "Downloading..." : "Download"}
        // primaryLink={`/client/reports/${reportId}`}
        parentOptClasses={isMobile ? "re-down-mob" : ""}
        buttonInnerClasses={isMobile ? "down-inner" : ""}
        overrideStyle={true}
        forFilter={true}
        onPrimaryClick={(event) => ""}
        extraPropsForIndex={true}
      >
        <MenuItem handleClick={() => this.downloadReport("pdf")}>
          Download PDF
        </MenuItem>
        <MenuItem handleClick={() => this.downloadReport("csv")}>
          Download CSV
        </MenuItem>
        {/*<MenuItem handleClick={() => this.downloadReport("xls")}>
          Download Excel
        </MenuItem>*/}
      </DropdownButton>
    );
  };

  utilityHeader = () => {
    return (
      <div
        className='report-utility mobile-hide-list'
        style={{ marginLeft: "auto", marginTop: "auto" }}
      >
        {this.renderDownloadBtn()}
      </div>
    );
  };

  handleSearchInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }
  handleSearchClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.getReportData();
    }
  };

  openItemPref = (checklist) => {
    if (checklist) {
      this.state.input.is_all_items = false;
      this.state.input.selected_items = checklist;
      this.state.input.item_count = checklist.length;
    }
    let reportType = this.state.reportSlug;
    if (reportType === "inventory_consumption") {
      this.setState({
        showPrefPopUp: !this.state.showPrefPopUp,
        input: this.state.input,
      });
      this.getReportData();
    } else if (reportType === "aging_detail") {
      this.setState({
        showPrefPopUp2: !this.state.showPrefPopUp2,
        input: this.state.input,
      });
      this.getReportData("aging_detail");
    } else if (reportType === "aging_summary") {
      this.setState({
        showPrefPopUp3: !this.state.showPrefPopUp3,
        input: this.state.input,
      });
      this.getReportData();
    }
    // this.getReportData();
  };

  // function for aragingdetail & aragingsummary
  openItemPrefForARAging = (checklist) => {
    if (checklist) {
      this.state.input.is_all_items = false;
      this.state.input.selected_items = checklist;
      this.state.input.item_count = checklist.length;
    }
    this.setState({
      showPrefPopUp2: !this.state.showPrefPopUp2,
      input: this.state.input,
    });

    this.getReportData();
  };

  render() {
    const { appName } = this.props;
    const {
      reportName,
      isLoading,
      error,
      AgingSummaryData,
      reportSlug,
      input,
      showPrefPopUp,
      showPrefPopUp2,
      showPrefPopUp3,
      totalItems,
    } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }
    const style = {
      container: { display: "flex" },
      content: { width: "100%", paddingTop: `10px` },
    };
    if (showPrefPopUp) {
      return (
        <InventoryItemFilter
          item={input}
          hideModal={this.openItemPref}
          user={this.props.user}
          selected_items={this.state.input.selected_items}
        />
      );
    } else if (showPrefPopUp2) {
      return (
        <ClientReportFilterModal
          item={input}
          hideModal={this.openItemPrefForARAging}
          user={this.props.user}
          selected_items={this.state.input.selected_items}
          reportSlug={this.state.reportSlug}
        />
      );
    } else if (showPrefPopUp3) {
      return (
        <ClientReportFilterModal
          item={input}
          hideModal={this.openItemPref}
          user={this.props.user}
          selected_items={this.state.input.selected_items}
          reportSlug={this.state.reportSlug}
        />
      );
    }

    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div>
        <AppSectionHeader
          title={reportName}
          titleClasses='report-title'
          backLink={{
            text: `Back to all Reports`,
            path: `/client/reports`,
          }}
          utilities={this.utilityHeader()}
        />
        <div className='mobile-re-btn'>{this.renderDownloadBtn(true)}</div>
        {reportSlug == "inventory_consumption" ? (
          <div className='mg-p-10'>
            <div style={style.container}>
              <div style={style.content}>Inventory Search Preferences : </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  className={`input-container select-view`}
                  style={{ marginBottom: "1rem" }}
                >
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    resetValue=''
                    className='form-select'
                    name='All Items'
                    value={input.filterPref}
                    placeholder='All Items'
                    options={this.state.filterOptions}
                    onChange={(event) =>
                      this.handleInputFilterChange({
                        is_all_items: event && event.flag ? event.flag : false,
                        filterPref: event ? event.value : "",
                      })
                    }
                  />
                  <button
                    disabled={input.is_all_items ? true : false}
                    className={`select-inner-btn mg-brand2-color`}
                    onClick={() => this.openItemPref()}
                  >
                    Select
                  </button>
                </div>
                {input.is_all_items ? (
                  <div style={{ marginBottom: "1rem" }} />
                ) : (
                  <div className={`input-container contact-count`}>
                    {input.item_count && input.item_count == 1
                      ? "Selected Item: "
                      : "Selected Items: "}
                    {input.item_count ? input.item_count : "0"}
                  </div>
                )}
              </div>
            </div>
            <form>
              <div style={{ display: "flex" }}>
                <div>
                  <div
                    className={`input-container ${
                      input.fromDate.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='name'>From Date</label>
                    <div>
                      <MaskedTextInput
                        guide={true}
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        name='fromDate'
                        placeholder='mm/dd/yyyy'
                        placeholderChar={"\u2000"}
                        style={{ width: "95%" }}
                        type='text'
                        value={this.state.input.fromDate.value}
                        onChange={(event) =>
                          this.handleSearchInputChange({
                            fromDate: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.fromDate.hasError
                        ? this.renderErrorMessage("fromDate")
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`input-container ${
                      input.toDate.hasError ? " error" : ""
                    }`}
                  >
                    <label htmlFor='name'>To Date</label>
                    <div>
                      <MaskedTextInput
                        guide={true}
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        name='toDate'
                        placeholder='mm/dd/yyyy'
                        placeholderChar={"\u2000"}
                        style={{ width: "95%" }}
                        type='text'
                        value={this.state.input.toDate.value}
                        onChange={(event) =>
                          this.handleSearchInputChange({
                            toDate: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.toDate.hasError
                        ? this.renderErrorMessage("toDate")
                        : null}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: ".9rem" }}>
                  <button
                    onClick={(e) => this.handleSearchClick(e)}
                    className={`inner-btn mg-brand2-color add-client-btn reportSearchBtn`}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>{" "}
          </div>
        ) : reportSlug == "aging_detail" || reportSlug == "aging_summary" ? (
          <div>
            <div style={style.container}>
              <div style={style.content}>Client Search Preferences : </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  className={`input-container select-view`}
                  style={{ marginBottom: "1rem" }}
                >
                  <Select
                    inputProps={{
                      autoComplete: "none",
                      autoCorrect: "off",
                      spellCheck: "off",
                    }}
                    resetValue=''
                    className='form-select'
                    name='All Clients'
                    value={input.filterPref2}
                    placeholder='All Clients'
                    options={this.state.filterOptions2}
                    onChange={(event) =>
                      this.handleInputFilterChange({
                        is_all_items: event && event.flag ? event.flag : false,
                        filterPref2: event ? event.value : "",
                        reportType: reportSlug,
                      })
                    }
                  />
                  <button
                    disabled={input.is_all_items ? true : false}
                    className={`select-inner-btn mg-brand2-color`}
                    onClick={() => this.openItemPref()}
                  >
                    Select
                  </button>
                </div>
                {input.is_all_items ? (
                  <div style={{ marginBottom: "1rem" }} />
                ) : (
                  <div className={`input-container contact-count`}>
                    {input.item_count && input.item_count == 1
                      ? "Selected Item: "
                      : "Selected Items: "}
                    {input.item_count ? input.item_count : "0"}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className='app-sidebar-layout-canvas-content scrollbar '
          style={{ height: window.innerHeight * 0.7, paddingBottom: 150 }}
        >
          {AgingSummaryData ? (
            <Panel optionalClasses='mg-mwt'>
              <div className='cl-re'>
                <div className='re-com'>
                  {this.state.companyData.clientName}
                </div>
                <div className='re-name'>{reportName}</div>
                <div className='re-date'>
                  As of {moment().format("MMM DD, YYYY")}
                </div>
              </div>
              {reportSlug == "aging_summary" ? (
                <ClientsAgingSummaryReport
                  data={AgingSummaryData}
                  props={this.props}
                  totalItems={totalItems}
                />
              ) : reportSlug == "aging_detail" ? (
                <ClientsAgingDetailReport
                  data={AgingSummaryData}
                  props={this.props}
                  totalItems={totalItems}
                />
              ) : reportSlug == "collection" ? (
                <ClientsCollectionsReport
                  data={AgingSummaryData}
                  props={this.props}
                />
              ) : reportSlug == "invoice_list" ? (
                <ClientsAgingSummaryReport
                  data={AgingSummaryData}
                  invoiceReport={true}
                  props={this.props}
                />
              ) : reportSlug == "customer_balance_detail" ? (
                <ClientsCustomerBalanceDetail
                  data={AgingSummaryData}
                  props={this.props}
                />
              ) : reportSlug == "customer_balance_summary" ? (
                <ClientsAgingSummaryReport
                  data={AgingSummaryData}
                  customerSummary={true}
                  extraProps='mg-wt-50'
                />
              ) : reportSlug == "inventory_status" ? (
                <ClientsInventoryStatusReport
                  data={AgingSummaryData}
                  customerSummary={true}
                />
              ) : reportSlug == "inventory_consumption" ? (
                <ClientsInventoryConsumptionReport
                  data={AgingSummaryData}
                  totalItems={totalItems}
                />
              ) : (
                <div />
              )}
            </Panel>
          ) : (
            <div
              className='admin-admins-empty-state'
              style={{ marginTop: "2em" }}
            >
              <p>Report appears here?</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ClientsReportView;
