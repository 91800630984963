import $ from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
// API
import * as API from "../../../../utils/api";
import { showToast, validateEmail } from "../../../../utils/helpers";
import AppCanvasLoader from "../../../App/AppCanvasLoader";
import Modal from "../Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";

class SentMessageToBillingAdmin extends Component {
  state = {
    input: {
      inquiry: {
        value: "",
        error: false,
      },
      message: {
        value: "",
        error: false,
      },
    },
  };

  componentDidMount() {}

  handleInputChange(newPartialInput, flag, value) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
    if (this.props.optionalProps.isPaymentPlanModel) {
      if (flag) {
        this.autoFillMergeVariablies(true, value);
      }
    }
  }

  handleInputChangeForMsg(msg, data) {
    this.setState(
      {
        ...this.state.message.value,
        ...msg,
      },
      () => {
        this.getMergeVariables();
      }
    );
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  validateInput = () => {
    let { inquiry, message } = this.state.input;
    if (inquiry.value === "" || message.value === "") {
      if (inquiry.value === "") {
        this.handleInputChange({
          inquiry: {
            value: "",
            error: true,
          },
        });
      }
      if (message.value === "") {
        this.handleInputChange({
          message: {
            value: "",
            error: true,
          },
        });
      }
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = () => {
    let { inquiry, message } = this.state.input;
    let { user, optionalProps } = this.props;
    let { planDetails } = optionalProps;
    if (this.validateInput()) {
      let payload = {
        contact_form: {
          email: planDetails.user.email,
          message: message.value,
          name: planDetails.user.first_name + " " + planDetails.user.last_name,
          page: "consumer",
          phone_number: planDetails.user.phone_number,
          topic: inquiry.value,
        },
      };
      API.contactUs(user, payload).then((data) => {
        if (
          (typeof data != "undefined" || data != null) &&
          data.status_code == 200
        ) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      });
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "name") {
      message = "Please enter name.";
    } else if (input === "email") {
      message = "Please enter a valid email.";
    } else if (input === "cc_email") {
      message = "Please enter a valid email.";
    } else if (input === "message") {
      message = "Please enter message.";
    } else {
      message = "Please complete this field.";
    }

    return <div className="input-error-message">{message}</div>;
  }

  handleModalClose = () => {
    this.props.hideModal();
  };

  render() {
    let { isPaymentPlanModel } = this.props.optionalProps;
    let {
      input,
      isLoading,
    } = this.state;
    let selectOptions = [
      { value: "Issue", label: " I have an issue (account or payment)" },
      { value: "Something Else", label: "Question about something else" },
    ];

    return (
      <Modal
        optionalClasses="add-account-modal send-message-to-admin scroll"
        title="Send us a message"
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
      >
        {isLoading ? (
          <AppCanvasLoader />
        ) : (
          <>
            <form className="admin-form">
              <div className="app-sidebar-layout-canvas">
                <div
                  className=" app-sidebar-left"
                  style={{ padding: "0px 20px", width: "100%" }}
                >
                  <div>
                    <div className="admin-form-row">
                      <div
                        className={`input-container ${
                          input.inquiry.error ? " error" : ""
                        }`}
                      >
                        <label htmlFor="inquiry">Select Category:</label>
                        <Select
                          inputProps={{
                            autoComplete: "none",
                            autoCorrect: "off",
                            spellCheck: "off",
                          }}
                          className="form-select"
                          name="form-field-name"
                          value={input.inquiry.value}
                          placeholder="Select an option"
                          options={selectOptions}
                          resetValue=""
                          onChange={(event) =>
                            this.handleInputChange({
                              inquiry: {
                                value: event ? event.value : "",
                                error: false,
                              },
                            })
                          }
                        />
                        {input.inquiry.error
                          ? this.renderErrorMessage("inquiry")
                          : null}
                      </div>
                    </div>
                    <div className="admin-form-row">
                      <div
                        className={`input-container ${
                          input.message.error ? " error" : ""
                        }`}
                      >
                        <label htmlFor="message">Your Message:</label>
                        <input
                          name="message"
                          guide={"true"}
                          placeholder="Enter message"
                          type="text"
                          value={input.message.value}
                          onChange={(event) =>
                            this.handleInputChange({
                              message: {
                                value: event.target.value,
                                error: false,
                              },
                            })
                          }
                        />
                        {input.message.error
                          ? this.renderErrorMessage("message")
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="modal-actions double-btns2">
              <button
                className="cta mg-brand2-color"
                disabled={this.state.bool}
                onClick={this.handleSubmit}
                style={{ marginBottom: "15px" }}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default withRouter(SentMessageToBillingAdmin);
