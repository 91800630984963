import React, { Component } from 'react';

// Components
import MarketingSubscribeForm from './MarketingSubscribeForm';

class MarketingEmailCallout extends Component {

  render() {
    return (
      <section className="marketing-email-callout">
        <div className="inner-wrap">

          <div className="h1">Now is the time to take back control.</div>
          <div className="email-form-container">
            <p>Get notified when we launch.</p>
            <MarketingSubscribeForm appName={this.props.appName}/>
          </div>

        </div>
      </section>
    );
  }

}

export default MarketingEmailCallout;
