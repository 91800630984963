import React, { Component } from 'react'
import { withRouter, Redirect } from "react-router-dom";

// Packages
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import MaskedTextInput from 'react-text-mask'

// Data
import stateDataShortNames from '../../utils/stateDataShortNames.json'

// Utils
import * as API from '../../utils/api'
import { showToast } from '../../utils/helpers';

class OnboardingCreateAccountForm extends Component {

  state = {
    input: {
      address: {
        value: "",
        hasError: false
      },
      addressLineTwo: {
        value: "",
        hasError: false
      },
      city: {
        value: "",
        hasError: false
      },
      state: {
        value: "",
        hasError: false
      },
      zip: {
        value: "",
        hasError: false
      },
    },
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.validateForm()) {
      const userUpdate = {
        "street_address_primary": this.state.input.address.value,
        "street_address_secondary": this.state.input.addressLineTwo.value,
        "city": this.state.input.city.value,
        "state": this.state.input.state.value,
        "zipcode": this.state.input.zip.value
      }
      API.updateUser(this.props.user, userUpdate).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && (!data.errors)) {
          this.props.history.push("/onboarding/verify-identity")
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      })
    }
  }

  validateForm = () => {
    let newInputState = { ...this.state.input }
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.value === '' && key !== 'addressLineTwo') {
        newInputState[key].hasError = true
      }
      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    } else {
      return true
    }
  }

  renderErrorMessage(input) {
    return (
      <div className="input-error-message">
        Please make sure to complete this field
      </div>
    )
  }

  render() {
    const { input, error } = this.state

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <form className="sessions-form" onSubmit={(event) => this.handleSubmit(event)}>
        <div
          className={`input-container${input.address.hasError ? ' error' : ''}`}
        >
          <label>Street Address</label>
          <input
            name="address"
            type="text"
            value={input.address.value}
            onChange={event => this.handleInputChange(
              { address: { value: event.target.value, hasError: false } }
            )}
          />
          {input.address.hasError ? this.renderErrorMessage('address') : null}
        </div>
        <div
          className={`input-container${input.addressLineTwo.hasError ? ' error' : ''}`}
        >
          <label>Address Line 2</label>
          <input
            name="addressLineTwo"
            type="text"
            value={input.addressLineTwo.value}
            onChange={event => this.handleInputChange(
              { addressLineTwo: { value: event.target.value, hasError: false } }
            )}
          />
          {input.addressLineTwo.hasError ? this.renderErrorMessage('addressLineTwo') : null}
        </div>
        <div
          className={`input-container${input.city.hasError ? ' error' : ''}`}
        >
          <label>City</label>
          <input
            name="city"
            type="text"
            value={input.city.value}
            onChange={event => this.handleInputChange(
              { city: { value: event.target.value, hasError: false } }
            )}
          />
          {input.city.hasError ? this.renderErrorMessage('city') : null}
        </div>
        <div
          className={`input-container${input.state.hasError ? ' error' : ''}`}
        >
          <label>State</label>
          <Select
            inputProps={{ autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
            className="form-select"
            name="state"
            value={input.state.value}
            placeholder="Select State"
            options={stateDataShortNames}
            resetValue=""
            onChange={event => this.handleInputChange({ state: { value: event ? event.value : '', hasError: false } })}
          />
          {input.state.hasError ? this.renderErrorMessage('state') : null}
        </div>
        <div
          className={`input-container${input.zip.hasError ? ' error' : ''}`}
        >
          <label>Zip Code</label>
          <MaskedTextInput
            guide={true}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            name="zip"
            placeholder="90210"
            placeholderChar={'\u2000'}
            type="text"
            value={input.zip.value}
            onChange={event => this.handleInputChange(
              { zip: { value: event.target.value, hasError: false } }
            )}
          />
          {input.zip.hasError ? this.renderErrorMessage('zip') : null}
        </div>
        <input className="cta" type="submit" value="Next »" />
      </form>
    )
  }
}

export default withRouter(OnboardingCreateAccountForm)
