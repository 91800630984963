import React, { Component } from 'react';
import { withRouter, Redirect } from "react-router-dom";

// API
import * as API  from '../../../utils/api';

// Packages
import TextareaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import MaskedTextInput from 'react-text-mask';
import moment from 'moment';

// Components
import Panel from '../../App/Panel';
import AppCanvasLoader from '../../App/AppCanvasLoader';

// Utils
import stateDataAbbreviations from '../../../utils/stateDataAbbreviations.json';
import {
  isValidDate,
  numberMask,
  getStrippedDollarAmount,
  convertCreditorsToArrayForSelectComponent,
  showToast
} from '../../../utils/helpers';

class AdminUpdateConsumerForm extends Component {
  state = {
    consumer: {},
    creditors: [],
    input: {
      consumer: {},
      consumerDebts: []
    },
    isLoading: true,
    error: {
      hasAnyError: false,
      statusCode: 200
    },
  }

  componentDidMount () {
    API.fetchAllCreditors(this.props.user).then(data => {

      let isAccessDenied =  (data && data.message && data.message == "Access denied.") ? true : false

      if (((typeof data != 'undefined') || (data != null)) && !data.error && !isAccessDenied) {
          let { consumer } = this.props;
          let creditorsArray = convertCreditorsToArrayForSelectComponent(data.creditors);

          this.setState(state => ({
            consumer,
            creditors: creditorsArray,
            input: {
              consumer: {
                first_name: {
                  value: consumer.first_name,
                  hasError: false
                },
                last_name: {
                  value: consumer.last_name,
                  hasError: false
                },
                email: {
                  value: consumer.email,
                  hasError: false
                },
                phone_number: {
                  value: consumer.phone_number,
                  hasError: false
                },
                date_of_birth: {
                  value: consumer.date_of_birth,
                  hasError: false
                },
                ssn: {
                  value: consumer.ssn,
                  hasError: false
                },
                street_address_primary: {
                  value: consumer.street_address_primary,
                  hasError: false
                },
                street_address_secondary: {
                  value: consumer.street_address_secondary,
                  hasError: false
                },
                city: {
                  value: consumer.city,
                  hasError: false
                },
                state: {
                  value: consumer.state,
                  hasError: false
                },
                zipcode: {
                  value: consumer.zipcode,
                  hasError: false
                },
              },
              consumerDebts: consumer.debts_attributes.map((debt) => {
                let debtCreditor = creditorsArray.filter((creditor) => {
                  return creditor.label === debt.creditor_name;
                });

                let dateCreated = moment(debt.created_at).format('MM/DD/YYYY');

                return {
                  additional_details: {
                    value: debt.additional_details,
                    hasError: false
                  },
                  balance_in_dollars_as_currency: {
                    value: debt.balance_in_dollars_as_currency,
                    hasError: false
                  },
                  close_out_date: {
                    value: debt.close_out_date,
                    hasError: false
                  },
                  created_at: {
                    value: dateCreated,
                    hasError: false
                  },
                  creditor_id: {
                    value: debtCreditor[0].value,
                    hasError: false
                  },
                  date_sent: {
                    value: debt.date_sent,
                    hasError: false
                  },
                  id: {
                    value: debt.id,
                    hasError: false
                  },
                  last_payment_date: {
                    value: debt.last_payment_date,
                    hasError: false
                  },
                  loan_open_date: {
                    value: debt.loan_open_date,
                    hasError: false
                  },
                  status: {
                    value: debt.status,
                    hasError: false
                  },
                  total_in_dollars_as_currency: {
                    value: debt.total_in_dollars_as_currency,
                    hasError: false,
                    previousTotal: debt.total_in_dollars_as_currency
                  },
                  user_id: {
                    value: debt.user_id,
                    hasError: false
                  },
                };
              })
            },
            isLoading: false
          }))
      }else{
          this.setState(state => ({
               ...state,
               error: {
                hasAnyError: true,
                statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
              }
          }))
      }
    });
  }

  handleConsumerInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        consumer: {
          ...state.input.consumer,
          ...newPartialInput,
        }
      }
    }))
  }

  handleDebtInputChange(newPartialInput, index) {
    let newConsumerDebts = [...this.state.input.consumerDebts];

    newConsumerDebts[index] = {
      ...newConsumerDebts[index],
      ...newPartialInput
    }

    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        consumerDebts: newConsumerDebts
      }
    }))
  }

  addCreditorInputSet = () => {
    let newDebts = [...this.state.input.consumerDebts];
    let userId = this.state.consumer.id;

    newDebts.push(
      {
        additional_details: {
          value: null,
          hasError: false
        },
        balance_in_dollars_as_currency: {
          value: '',
          hasError: false
        },
        close_out_date: {
          value: '',
          hasError: false
        },
        created_at: {
          value: '',
          hasError: false
        },
        creditor_id: {
          value: '',
          hasError: false
        },
        creditor_name: {
          value: '',
          hasError: false
        },
        date_sent: {
          value: null,
          hasError: false
        },
        id: {
          value: '',
          hasError: false
        },
        last_payment_date: {
          value: '',
          hasError: false
        },
        loan_open_date: {
          value: '',
          hasError: false
        },
        status: {
          value: null,
          hasError: false
        },
        total_in_dollars_as_currency: {
          value: '',
          hasError: false,
          previousTotal: ''
        },
        user_id: {
          value: userId,
          hasError: false
        },
      }
    );

    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        consumerDebts: newDebts
      }
    }))
  }

  addNewCreditor = () => {
    this.props.updateModalState(true, 'Add_NEW_CREDITOR', this.props)
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { history, user } = this.props;
    const consumerId = this.state.consumer.id;

    if (this.validateForm()) {
      let consumerForSubmission = this.getConsumerForSubmission(consumerId);

      API.updateConsumer(user, consumerId, consumerForSubmission).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
            history.push(`/admin/accounts/${consumerId}`);
        }else{
            this.setState(state => ({
                   ...state,
                   error: {
                    hasAnyError: true,
                    statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
                  }
            }))
        }
      });
    } else {
      this.setFormErrorState();
    }
  }

  getConsumerForSubmission = (consumerId) => {
    let debtCreditor = '';
    const { input, creditors } = this.state;
    const consumerInput = this.state.input.consumer;

    let debtAttributes = input.consumerDebts.map((debt) => {
      debtCreditor = creditors.filter((creditor) => {
        return creditor.value === debt.creditor_id.value;
      });

      return {
        additional_details: debt.additional_details.value,
        balance_in_dollars: this.getNewDebtBalance(debt),
        close_out_date: debt.close_out_date.value,
        created_at: moment(debt.created_at.value, "MM/DD/YYYY"),
        creditor_id: debtCreditor[0].value,
        creditor_name: debtCreditor[0].label,
        date_sent: debt.date_sent.value,
        id: debt.id.value,
        last_payment_date: debt.last_payment_date.value,
        loan_open_date: debt.loan_open_date.value,
        status: debt.status.value,
        total_in_dollars: getStrippedDollarAmount(debt.total_in_dollars_as_currency.value),
        user_id: consumerId
      };
    })

    let consumerForSubmission = {
      city: consumerInput.city.value,
      date_of_birth: consumerInput.date_of_birth.value,
      debts_attributes: debtAttributes,
      email: consumerInput.email.value,
      first_name: consumerInput.first_name.value,
      id: consumerId,
      last_name: consumerInput.last_name.value,
      phone_number: consumerInput.phone_number.value,
      ssn: consumerInput.ssn.value,
      state: consumerInput.state.value,
      street_address_primary: consumerInput.street_address_primary.value,
      street_address_secondary: consumerInput.street_address_secondary.value,
      zipcode: consumerInput.zipcode.value
    }

    return consumerForSubmission;
  }

  getNewDebtBalance = (debt) => {
    let newTotalDebt = getStrippedDollarAmount(debt.total_in_dollars_as_currency.value);
    let previousTotalDebt = getStrippedDollarAmount(debt.total_in_dollars_as_currency.previousTotal);
    let oldBalance = getStrippedDollarAmount(debt.balance_in_dollars_as_currency.value);

    let totalDebtDifference = newTotalDebt - previousTotalDebt;

    let newBalance = oldBalance + totalDebtDifference;

    if (newBalance < 0) {
      newBalance = 0
    }

    return newBalance;
  }

  validateForm = () => {
    let errorsArePresent = false;

    // Check Consumer Info Inputs for Errors
    let newConsumerInputState = {...this.state.input.consumer};

    Object.entries(newConsumerInputState).forEach(([key, value]) => {
      if (key === 'date_of_birth') {
        if (!isValidDate(value.value) && value.value !== '' && value.value !== null) {
          errorsArePresent = true;
        }
      }
    });

    // Check Debts' Inputs for Errors
    let newConsumerDebtsInputState = [...this.state.input.consumerDebts];

    for (let debt of newConsumerDebtsInputState) {
      if(this.checkFormFieldObjectForErrors(debt, errorsArePresent)) {
        errorsArePresent = true;
      }
    }

    if (errorsArePresent) {
      return false;
    } else {
      return true;
    }
  }

  setFormErrorState = () => {
    let errorsArePresent = false;

    // Set errors on consumer account info fields
    let newConsumerInputState = {...this.state.input.consumer};

    Object.entries(newConsumerInputState).forEach(([key, value]) => {
      if (key === 'date_of_birth' && value.value !== '' && value.value !== null) {
        if (!isValidDate(value.value)) {
          newConsumerInputState[key].hasError = true;
        }
      } else if (value.value === ''){
        newConsumerInputState[key].hasError = true;
      }

      if (newConsumerInputState[key].hasError) {
        errorsArePresent = true
      }
    });

    // Set errors on debt object input fields
    let newConsumerDebtsInputState = [...this.state.input.consumerDebts];

    for (var index = 0; index < newConsumerDebtsInputState.length; index++) {
      if(this.checkFormFieldObjectForErrors(newConsumerDebtsInputState[index], errorsArePresent)) {
        newConsumerDebtsInputState[index] = this.getUpdatedDebtsInputObject(newConsumerDebtsInputState[index])
        errorsArePresent = true;
      }
    }

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: {
          ...state.input,
          consumer: newConsumerInputState,
          consumerDebts: newConsumerDebtsInputState
        }
      }))
    }
  }

  checkFormFieldObjectForErrors = (debtObject, errorsArePresent) => {
    Object.entries(debtObject).forEach(([key, value]) => {
      if (key === 'creditor_id') {
        if (!value.value) {
          errorsArePresent = true
        }
      }
      if(key === 'created_at' && (debtObject.id.value === '')){
        if(!isValidDate(debtObject.created_at.value)){
            errorsArePresent = true
        }else if (new Date(debtObject.created_at.value) >= new Date()){
            errorsArePresent = true
        }
      }
    })

    return errorsArePresent;
  }

  getUpdatedDebtsInputObject = (inputFieldObject) => {
    Object.entries(inputFieldObject).forEach(([key, value]) => {
      if (key === 'creditor_id') {
        if (!value.value) {
          inputFieldObject[key].hasError = true;
        }
      }

      if(key === 'created_at'){
        inputFieldObject[key].hasError = true;
      }
    })

    return inputFieldObject;
  }

  renderErrorMessage(input) {
    let message = '';

    if (input === 'date_of_birth') {
      message = 'Please enter a valid date';
    } else if (input === 'creditor_id') {
      message = 'Please select a creditor';
    } else if (input === 'created_at'){
      message = 'Please enter a valid date';
    }else{
      message = 'Please complete this field';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  render() {
    let { isLoading, creditors, input, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        })
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return isLoading
    ? (
      <AppCanvasLoader />
    )
    : (
      <form
        className="admin-form add-edit-account-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <Panel
          heading="Customer's Information"
        >
          <div className="admin-form-row">
            <div className="input-container one-third">
              <label htmlFor="name">Consumer's Name</label>
              <div
                className={`input-container multi-input-field${input.consumer.first_name.hasError ? ' error' : ''}`}
              >
                <input
                  className="multi-input-field-input"
                  name="name"
                  type="text"
                  placeholder="First Name"
                  value={input.consumer.first_name.value}
                  onChange={event => this.handleConsumerInputChange({first_name: {value: event.target.value, hasError: false}})}
                />
                <input
                  className="multi-input-field-input"
                  name="name"
                  type="text"
                  placeholder="Last Name"
                  value={input.consumer.last_name.value}
                  onChange={event => this.handleConsumerInputChange({last_name: {value: event.target.value, hasError: false}})}
                />
              </div>
            </div>
            <div
              className={`input-container one-third${input.consumer.email.hasError ? ' error' : ''}`}
            >
              <label htmlFor="email">Consumer's Email Address</label>
              <input
                name="email"
                type="email"
                placeholder="address@email.com"
                value={input.consumer.email.value}
                onChange={event => this.handleConsumerInputChange({email: {value: event.target.value, hasError: false}})}
              />
            </div>
            <div
              className={`input-container one-third${input.consumer.phone_number.hasError ? ' error' : ''}`}
            >
              <label htmlFor="phone">Consumer's Phone Number</label>
              <MaskedTextInput
                name="phone"
                mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(555) 555-5555"
                placeholderChar={'\u2000'}
                guide={true}
                type="text"
                value={input.consumer.phone_number.value}
                onChange={event => this.handleConsumerInputChange({phone_number: {value: event.target.value, hasError: false}})}
              />
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container half${input.consumer.date_of_birth.hasError ? ' error' : ''}`}
            >
              <label htmlFor="birthdate">Consumer's Date Of Birth</label>
              <MaskedTextInput
                guide={true}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                name="birthday"
                placeholder="mm/dd/yyyy"
                placeholderChar={'\u2000'}
                type="text"
                value={input.consumer.date_of_birth.value}
                onChange={event => this.handleConsumerInputChange({
                  date_of_birth: {value: event.target.value, hasError: false}}
                )}
              />
              {input.consumer.date_of_birth.hasError ? this.renderErrorMessage('date_of_birth') : null}
            </div>
            <div
              className={`input-container half${input.consumer.ssn.hasError ? ' error' : ''}`}
            >
                <label htmlFor="ssn">Consumer's Last Four of SSN</label>
                <MaskedTextInput
                  guide={true}
                  mask={[/\d/, /\d/, /\d/, /\d/ ]}
                  name="ssn"
                  placeholder="####"
                  placeholderChar={'\u2000'}
                  type="text"
                  value={input.consumer.ssn.value}
                  onChange={event => this.handleConsumerInputChange({
                    ssn: {value: event.target.value, hasError: false}}
                  )}
                />
              </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container two-thirds${input.consumer.street_address_primary.hasError ? ' error' : ''}`}
            >
              <label htmlFor="streetAddress">Street Address</label>
              <input
                name="streetAddress"
                type="text"
                placeholder="Your Name"
                value={input.consumer.street_address_primary.value}
                onChange={event => this.handleConsumerInputChange({
                  street_address_primary: {value: event.target.value, hasError: false}}
                )}
              />
            </div>
            <div
              className={`input-container one-third${input.consumer.street_address_secondary.hasError ? ' error' : ''}`}
            >
              <label htmlFor="unit">Unit/Apt #</label>
              <input
                name="unit"
                type="text"
                placeholder="Apt. #"
                value={input.consumer.street_address_secondary.value ? input.consumer.street_address_secondary.value : ''}
                onChange={event => this.handleConsumerInputChange({
                  street_address_secondary: {value: event.target.value, hasError: false}}
                )}
              />
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container one-third${input.consumer.city.hasError ? ' error' : ''}`}
            >
              <label htmlFor="city">City</label>
              <input
                name="city"
                type="text"
                placeholder="Anywhere"
                value={input.consumer.city.value}
                onChange={event => this.handleConsumerInputChange({
                  city: {value: event.target.value, hasError: false}}
                )}
              />
            </div>
            <div
              className={`input-container one-third${input.consumer.state.hasError ? ' error' : ''}`}
            >
              <label htmlFor="state">State</label>
              <Select
                inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                className="form-select"
                name="state"
                value={input.consumer.state.value}
                placeholder="Select a State"
                options={stateDataAbbreviations}
                resetValue=""
                onChange={event => this.handleConsumerInputChange(
                  { state: {value: event ? event.value : '', hasError: false} }
                )}
              />
            </div>
            <div
              className={`input-container one-third${input.consumer.zipcode.hasError ? ' error' : ''}`}
            >
              <label htmlFor="zip">Zip Code</label>
              <MaskedTextInput
                guide={true}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                name="zip"
                placeholder="90210"
                placeholderChar={'\u2000'}
                type="text"
                value={input.consumer.zipcode.value}
                onChange={event => this.handleConsumerInputChange({
                  zipcode: {value: event.target.value, hasError: false}}
                )}
              />
            </div>
          </div>
        </Panel>

        <Panel
          heading="Add New Or Modifiy Pending Debt"
        >
          {

            input.consumerDebts.map((debt, index) => {
              let isDisabled = (debt.status.value != 'open' && debt.status.value != null && debt.id.value != '') ? true : false;
              return (
                <div
                  className="add-edit-account-form-creditor"
                  key={index}
                >
                  <fieldset disabled={isDisabled}>
                    <div className="admin-form-row">
                      <div
                        className={`input-container one-third${debt.creditor_id.hasError ? ' error' : ''}`}
                      >
                        <label htmlFor="creditorName" style={{width:`50%`}}>Creditor's Name</label><a
                          className="text-link"
                          onClick={() => this.addNewCreditor()}
                        >
                          {isDisabled ? null : '+Add Creditor'}
                        </a>
                        <Select
                          inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                          className="form-select"
                          name="creditorName"
                          value={debt.creditor_id.value}
                          placeholder="Select a Creditor"
                          options={creditors}
                          resetValue=""
                          disabled={isDisabled}
                          onChange={event => this.handleDebtInputChange(
                            {creditor_id: {value: event ? event.value : '', hasError: false}}
                            , index
                          )}
                        />
                        {debt.creditor_id.hasError ? this.renderErrorMessage('creditor_id') : null}
                      </div>
                      <div className="input-container one-third">
                        <label htmlFor="creditorTotalDebt">Total Debt</label>
                        <MaskedTextInput
                          guide={true}
                          mask={numberMask}
                          name="creditorTotalDebt"
                          placeholder="$0"
                          placeholderChar={'\u2000'}
                          type="text"
                          value={debt.total_in_dollars_as_currency.value}
                          onChange={event => this.handleDebtInputChange(
                            {total_in_dollars_as_currency: {
                              value: event.target.value,
                              hasError: false,
                              previousTotal: debt.total_in_dollars_as_currency.previousTotal
                            }}
                            , index
                          )}
                        />
                      </div>
                      <div className={`input-container one-third${debt.created_at.hasError ? ' error' : ''}`}>
                        <label htmlFor="openDate">Open Date</label>
                        <MaskedTextInput
                          guide={true}
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          name="openDate"
                          placeholder="mm/dd/yyyy"
                          placeholderChar={'\u2000'}
                          type="text"
                          value={debt.created_at.value ? debt.created_at.value : ''}
                          onChange={event => this.handleDebtInputChange(
                            {created_at: {value: event.target.value, hasError: false}}
                            , index
                          )}
                        />
                        {debt.created_at.hasError ? this.renderErrorMessage('created_at') : null}
                      </div>
                    </div>
                    <div className="admin-form-row">
                      <div className="input-container">
                        <label htmlFor="creditorAdditionalDetails">
                          Additional Details
                        </label>
                        <TextareaAutosize
                          name="creditorAdditionalDetails"
                          type="textarea"
                          placeholder="Your message here..."
                          value={debt.additional_details.value ? debt.additional_details.value : ''}
                          onChange={event => this.handleDebtInputChange(
                            {additional_details: {value: event.target.value, hasError: false}}
                            , index
                          )}
                        />
                      </div>
                  </div>
                  </fieldset>
                </div>
              );
            })
          }
          <a
            className="text-link"
            onClick={() => this.addCreditorInputSet()}
          >
            +Add Additional Debt
          </a>
        </Panel>
        <input type="submit" value="Submit" className="cta" />
      </form>
    );
  }
}

export default withRouter(AdminUpdateConsumerForm);
