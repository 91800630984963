import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import MaskedTextInput from "react-text-mask";
import moment from 'moment';
import Panel from "../../components/App/Panel";
import CardView from "../../components/CardView/CardView";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ToggleSwitch from "../../components/ToggleSwitch";
import * as API from "../../utils/api";
import { showToast } from "../../utils/helpers";

class SuperAdminOnboardingView extends Component {
    state = {
        isLoading: true,
        cGoLiveDate: '',
        isDateError: false,
        isEditDate: false,
        dateErrorType: '',
        details: null,
        error: {
          hasAnyError: false,
          statusCode: 200,
        },
    };

    componentDidMount() {
        this.getSettingsDetails(); 
    }

    getSettingsDetails = () => {
        const { accountId, user } = this.props;
        API.getSuperGoLiveDate(user, accountId).then((data) => {
          if (data && data.status_code && data.status_code == 200) {
            if (data.data) {
              this.setState({ details: data.data }, () => {
                this.getDateString()
              });
            }
            this.setState({ isLoading: false });
          } else {
            this.setState((state) => ({
              ...state,
              isLoading: false,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.status_code && data.status_code == 401 ? 401 : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
    };

    getDateString = () => {
      let myDate = "2014-06-01T12:00:00Z";
      const { details } = this.state;
      // let dateString = moment(details.go_live_date).format('L');
      if (details && details.go_live_date) {
        let dateString = moment(details.go_live_date).format('L');
        this.setState({cGoLiveDate: dateString})
      }
    }

    callUpdateDateApi() {
        let { accountId, user } = this.props;
        let payload = {
            organization_id: accountId,
            go_live_date: this.state.cGoLiveDate
        };
        API.updateSuperGoLiveDate(user, payload).then((data) => {
          if (data && data.status_code && data.status_code == 200) {
            this.props.updateModalState(true, "SUCCESS", {
              message: data.message,
            });
            this.getSettingsDetails();
            this.setState({ isLoading: false, isEditDate: false });
          } else {
            this.setState((state) => ({
              ...state,
              isLoading: false,
              error: {
                hasAnyError: true,
                statusCode: data
                  ? data.status ||
                    (data.status_code && data.status_code == 401 ? 401 : 500) ||
                    500
                  : 500,
              },
            }));
          }
        });
    }

    handleDateChange = (value) => {
        this.setState({cGoLiveDate: value});
        this.validateSubmit(value.trim())
    }

    handleEdit = () => {
        this.setState({isEditDate: true});
    }

    handleSubmit = () => {
        if (this.validateSubmit()){
            this.callUpdateDateApi();
        } else {
            //Submit API _ error
        }
    }

    validateSubmit = (value) => {
        const { cGoLiveDate, isDateError } = this.state
        let error = false;
        let isValid = true;
        let errorType = ''
        let checkValue;
        if(value){
            checkValue = value;
        } else {
            checkValue = cGoLiveDate
        }
        if(checkValue === '' ) {
            error = true;
            isValid = false;
            errorType = 'req';
        }else if (checkValue && checkValue.length && checkValue.length < 10) {
            error = true;
            isValid = false;
            errorType = 'valid';
        }
        this.setState({dateErrorType: errorType, isDateError: error})
        return isValid;
    }

    renderErrorMessage(input) {
        let message = "";
        if (input === "communicationDate") {
          message = "Please select payment type.";
        } else {
          message = "Please enter valid date.";
        }
    
        return <div className="input-error-message">{message}</div>;
      }

    render() {
        const {
          isLoading,
          cGoLiveDate,
          isDateError,
          isEditDate,
          error,
        } = this.state;

        return isLoading ? (
            <AppCanvasLoader />
        ) : (
            <Panel>
                <h3 className="panel-heading">Communication Go Live Date</h3>
                <form>
                    <div style={style.container} className={`onboading-container`}>
                        <div style={style.content} className={`${this.state.isEditDate === false ? 'disabled-container' : ''}`}>Date : </div>
                        <div
                        className={`input-container${
                            isDateError
                            ? " error"
                            : ""
                        } ${this.state.isEditDate === false ? 'disabled-container' : ''}`}
                        style={style.inputStyle}
                        >
                        <MaskedTextInput
                            guide={true}
                            mask={[
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            ]}
                            name="date"
                            placeholder="mm/dd/yyyy"
                            placeholderChar={"\u2000"}
                            type="text"
                            value={cGoLiveDate}
                            disabled={isEditDate === false}
                            onChange={(event) =>
                            this.handleDateChange(event.target.value)
                            }
                        />
                        {isDateError
                            ? this.renderErrorMessage(this.state.dateErrorType === 'req' ? 'reqError' : 'validError')
                            : null}
                        </div>
                        {isEditDate ? <div id="submit-form" className="modal-actions" style={style.submitContent}>
                            <a
                                className="cta mg-brand2-color"
                                onClick={(event) => this.handleSubmit(event)}
                            >
                                Submit
                            </a>
                        </div>
                        :   <div id="submit-form" className="modal-actions" style={style.submitContent}>
                            <a
                                className="cta mg-brand2-color"
                                onClick={(event) => this.handleEdit()}
                            >
                                Edit
                            </a>
                        </div>}
                    </div>   
                </form>
            </Panel>
        )  
    }
}

const style = {
    settingTitle: { fontSize: "16px", fontWeight: "bold" },
    // container: { display: 'flex' },
    inputStyle: { maxWidth: "300px", paddingTop: '10px', flex: '4' },
    content: { paddingTop: `20px`, fontWeight: "600", flex: '2', maxWidth: '110px' },
    submitContent: { flex: '2' }

};

export default SuperAdminOnboardingView;