import React from "react";
import Modal from "../../components/App/Modals/Modal";
import MGLogoIcon from "../../assets/icons/MG-logo.png";
import * as API from "../../utils/api";
import signInWithIntuit from "../../assets/images/landing/Sign_in_transparent_btn_tall_default.png";
import signInWithIntuitHover from "../../assets/images/landing/Sign_in_transparent_btn_tall_hover.png";
import MaskedTextInput from "react-text-mask";

class QbLaunch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mgdomain: {
        value: "",
        domain: "",
        error: false,
      },
      show: false,

      firstname: {
        value: "",
        errorMsg: "",
        error: false,
      },
      lastname: {
        value: "",
        errorMsg: "",
        error: false,
      },
      email: {
        value: "",
        errorMsg: "",
        error: false,
      },
      phone: {
        value: "",
        errorMsg: "",
        error: false,
      },
    };
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.getQbLaunchData();
    this.setState({ signInWithIntuitImg: signInWithIntuit });
  }

  signInWithIntuit = () => {
    API.signInWithIntuit().then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.errors) {
        window.open(data.data, "_self");
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let payload = {
      email: this.state.email.value,
      first_name: this.state.firstname.value,
      last_name: this.state.lastname.value,
      phone_number: this.state.phone.value,
    };
    if (this.validate()) {
      API.clientMandrillRequest(payload).then((data) => {
        if (data && data.status_code == 200) {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
      });
    } else {
      this.setState({ isMinAmountLoading: false });
    }
  };
tinue = () => {
    window.open(
      `https://${this.state.mgdomain.value}.${this.state.mgdomain.domain}/login`
    );
  };

  validate = () => {
    if (
      this.validateFirstnameText(this.state.firstname.value) &&
      this.validateLastnameText(this.state.lastname.value) &&
      this.validateEmail(this.state.email.value)
    ) {
      return true;
    } else {
      this.validateFirstnameText(this.state.firstname.value);
      this.validateLastnameText(this.state.lastname.value);
      this.validateEmail(this.state.email.value);
      return false;
    }
  };

  validateFirstnameText = (data) => {
    let isError = true;
    let Regex = /^[a-zA-Z]{0,}$/;
    if (data != "") {
      if (!Regex.test(data)) {
        isError = false;
        this.setState({
          firstname: {
            value: data,
            errorMsg: "Please enter valid first name.",
            error: true,
          },
        });
      }
    } else {
      isError = false;
      this.setState({
        firstname: {
          value: data,
          errorMsg: "Please enter first name.",
          error: true,
        },
      });
    }
    if (isError === true) {
      this.setState({
        firstname: {
          value: data,
          errorMsg: "",
          error: false,
        },
      });
    }
    return isError;
  };

  validateLastnameText = (data) => {
    let isError = true;
    let Regex = /^[a-zA-Z]{0,}$/;
    if (data != "") {
      if (!Regex.test(data)) {
        isError = false;
        this.setState({
          lastname: {
            value: data,
            errorMsg: "Please enter valid last name.",
            error: true,
          },
        });
      }
    } else {
      isError = false;
      this.setState({
        lastname: {
          value: data,
          errorMsg: "Please enter last name.",
          error: true,
        },
      });
    }
    if (isError === true) {
      this.setState({
        lastname: {
          value: data,
          errorMsg: "",
          error: false,
        },
      });
    }
    return isError;
  };

  validateEmail = (data) => {
    let isError = true;
    let Regex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/;
    if (data != "") {
      if (!Regex.test(data)) {
        isError = false;
        this.setState({
          email: {
            value: data,
            errorMsg: "Please enter valid email address.",
            error: true,
          },
        });
      }
    } else {
      isError = false;
      this.setState({
        email: {
          value: data,
          errorMsg: "Please enter email address.",
          error: true,
        },
      });
    }
    if (isError === true) {
      this.setState({
        email: {
          value: data,
          errorMsg: "",
          error: true,
        },
      });
    }
    return isError;
  };

  validatePhone = (data) => {
    let isError = true;
    let Regex = /^[1-9]{10,15}$/;
    if (!Regex.test(data)) {
      isError = false;
      this.setState({
        phone: {
          value: data,
          errorMsg: "Please enter valid phone number.",
          error: true,
        },
      });
    }
    if (isError === true) {
      this.setState({
        phone: {
          value: data,
          errorMsg: "",
          error: true,
        },
      });
    }
    return isError;
  };

  async getQbLaunchData() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let realmid = urlParams.get("realmid");

    // realmid = "4620816365179202380";

    await API.getRealmIdFromQB(realmid).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        data.status_code == 200
      ) {
        let result = data.data.split(".")[0].slice(8);
        let result2 = data.data.split(".")[1] + "." + data.data.split(".")[2];
        this.setState((state) => ({
          ...state,
          mgdomain: { value: result, domain: result2 },
          show: true,
        }));
      } else {
        this.setState({ show: false });
        //open error modal
        this.props.updateModalState(true, "ERROR", {
          message: data.message
            ? data.message
            : "Current Quickbook Company is not assosciated with MakeGood.",
        });
      }
    });
  }

  inputChangeFirstName = (e) => {
    e.preventDefault();
    this.setState({
      firstname: {
        value: e.target.value.trim(),
        errorMsg: "",
        error: false,
      },
    });
    this.validateFirstnameText(e.target.value);
  };

  inputChangeLastName = (e) => {
    e.preventDefault();
    this.setState({
      lastname: {
        value: e.target.value.trim(),
        errorMsg: "",
        error: false,
      },
    });
    this.validateLastnameText(e.target.value);
  };

  inputChangeEmail = (e) => {
    e.preventDefault();
    this.setState({
      email: {
        value: e.target.value.trim(),
        errorMsg: "",
        error: false,
      },
    });
    this.validateEmail(e.target.value);
  };

  inputChangePhone = (e) => {
    e.preventDefault();
    this.setState({
      phone: {
        value: e.target.value.trim(),
        errorMsg: "",
        error: false,
      },
    });
    // this.validatePhone(e.target.value);
  };

  handleChangeMGdomain = (value) => {
    let obj = { ...this.state.mgdomain };
    this.setState({
      mgdomain: {
        value: value,
        domain: obj.domain,
      },
    });
  };
  
  render() {
    return (
      <div className="qbLaunch-page">
        <div className="container-launch">
          <div className="content-launch">
            <div className="header-launch">
              <span>Welcome to</span>
              &nbsp; &nbsp;
              <span>
                <img src={MGLogoIcon} />
              </span>
            </div>
            <div className="qbLaunch-content">
              <div className="qbLaunch-signup-section">
                <p style={{ fontWeight: 500, fontSize: "18px" }}>Sign Up</p>
                <p style={{ margin: "0px" }}>Sign up now with Intuit</p>
                <div style={{ marginTop: "0.6rem", marginBottom: "0.9rem" }}>
                  <button
                    className="qbLaunch-intuit"
                    onClick={(event) => {
                      this.signInWithIntuit();
                    }}
                  >
                    Sign in with Intuit
                  </button>
                </div>
                <p style={{ margin: "0px 0px 0px 30px", fontWeight: 500 }}>
                  - OR -
                </p>
                <p style={{ margin: "0px 0px 0px 7px", fontWeight: 500 }}>
                  Fill out this form and we'll contact you.
                </p>
                <div className="form">
                  <div className="user-name">
                    <div>
                      <label>First Name*</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        ref={this.textInput}
                        value={this.state.firstname.value}
                        onChange={(e) => this.inputChangeFirstName(e)}
                      />
                      {this.state.firstname.error && (
                        <p className="error">{this.state.firstname.errorMsg}</p>
                      )}
                    </div>
                    <div>
                      <label>Last Name*</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        ref={this.textInput}
                        value={this.state.lastname.value}
                        onChange={(e) => this.inputChangeLastName(e)}
                      />
                      {this.state.lastname.error && (
                        <p className="error">{this.state.lastname.errorMsg}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <label>Email*</label>
                    <input
                      type="text"
                      placeholder="Email"
                      ref={this.textInput}
                      value={this.state.email.value}
                      onChange={(e) => this.inputChangeEmail(e)}
                    />
                    {this.state.email.error && (
                      <p className="error">{this.state.email.errorMsg}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="phone">Phone (optional)</label>
                    <MaskedTextInput
                      name="phone"
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder="(###) ###-####"
                      placeholderChar={"\u2000"}
                      guide={true}
                      type="text"
                      value={this.state.phone.value}
                      onChange={(e) => this.inputChangePhone(e)}
                    />
                    {this.state.phone.error && (
                      <p className="error">{this.state.phone.errorMsg}</p>
                    )}
                  </div>
                  <button onClick={(e) => this.onSubmit(e)}>Submit</button>
                </div>
              </div>
              <div className="qbLaunch-login-section">
                <p style={{ fontWeight: 500, fontSize: "18px" }}>Log In</p>
                <p style={{ margin: "0px" }}>Log in now with Intuit</p>
                <div style={{ marginTop: "0.6rem", marginBottom: "0.9rem" }}>
                  <button
                    className="qbLaunch-intuit"
                    onClick={(event) => {
                      this.signInWithIntuit();
                    }}
                  >
                    Sign in with Intuit
                  </button>
                </div>
                <p style={{ margin: "0px 0px 0px 30px", fontWeight: 500 }}>
                  - OR -
                </p>
                <p style={{ margin: "0px 0px 10px" }}>
                  Enter your MakeGood domain to log in.
                </p>
                <div className="form">
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      placeholder="Enter domain"
                      value={this.state.mgdomain.value}
                      onChange={(e) =>
                        this.handleChangeMGdomain(e.target.value)
                      }
                    />
                    {"  "}
                    <span>.makegoodapp.com</span>
                  </div>
                  <button onClick={() => this.handleContinue()}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QbLaunch;
