import React, { Component } from "react";

// API
import * as API from "../../utils/api";

// Utils
import { validateEmail, networkMessage } from "../../utils/helpers";

class ResetPasswordForm extends Component {
  state = {
    orgkey: "",
    input: {
      email: {
        value: "",
        hasError: false,
      },
    },
  };

  componentDidMount = () => {
    this.getOrganizationData();
  };

  getOrganizationData() {
    API.getOrganizationData().then((data) => {
      if (typeof data != "undefined" || data != null) {
        this.setState({ orgkey: data.organization_key });
      }
    });
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      },
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const email = this.state.input.email.value;
      API.passwordResetRequest(email, this.state.orgkey).then(() => {
        this.clearFormInput();
        this.props.handleSubmit();
      });
    }
  };

  clearFormInput = () => {
    this.setState((state) => ({
      ...state,
      input: {
        email: {
          value: "",
          hasError: false,
        },
      },
    }));
  };

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    if (!validateEmail(newInputState.email.value)) {
      newInputState.email.hasError = true;
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        input: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage() {
    return (
      <div className="input-error-message">
        Please enter a valid email address
      </div>
    );
  }

  render() {
    const { input } = this.state;
    return (
      <form
        className="sessions-form"
        onSubmit={(event) => this.handleSubmit(event)}
      >
        <div
          className={`input-container${input.email.hasError ? " error" : ""}`}
        >
          <label>Email Address</label>
          <input
            name="email"
            type="email"
            placeholder="you@youremail.com"
            value={input.email.value}
            onChange={(event) =>
              this.handleInputChange({
                email: { value: event.target.value, hasError: false },
              })
            }
          />
          {input.email.hasError ? this.renderErrorMessage() : null}
        </div>
        <input className="cta mg-button" type="submit" value="Reset Password" />
      </form>
    );
  }
}

export default ResetPasswordForm;
