import React, { Component } from "react";
import PropTypes from "prop-types";

// Packages
import onClickOutside from "react-onclickoutside";

class DropdownOverflowMenu extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
  };

  state = {
    dropdownIsActive: false,
  };

  toggleDropdown = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      ...state,
      dropdownIsActive: !state.dropdownIsActive,
    }));
  };

  handleClickOutside = (event) => {
    if (this.state.dropdownIsActive) {
      this.toggleDropdown(event);
    }
  };

  render() {
    const { children, showThreeDots, rowData, forTeam } = this.props;
    let { dropdownIsActive } = this.state;
    let style = showThreeDots ? { color: "#000000" } : {};
    let menuStyle = showThreeDots
      ? { marginTop: "-1em", right: 0 }
      : // ? { marginTop: "-1em", marginRight: "-0.5em" }
        {};
    return (
      <a
        className='dropdown-overflow-menu'
        style={style}
        onClick={(event) => this.toggleDropdown(event)}
      >
        <svg className='dropdown-overflow-menu-icon'>
          <use xlinkHref='#overflow-menu' />
        </svg>
        <ul
          //  className={`dropdown-menu${dropdownIsActive ? ' active': '' }`}
          className={`${
            forTeam ? "dropdown-menu-team" : "dropdown-menu"
          } mbleft ${
            dropdownIsActive && rowData && rowData.rowData.openid_id == null
              ? " active"
              : ""
          } end-0`}
          style={menuStyle}
        >
          {children}
        </ul>
      </a>
    );
  }
}

export default onClickOutside(DropdownOverflowMenu);
