import moment from "moment";
import React, { Component } from "react";
// import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import currencyFormatter from "currency-formatter";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import ConsumerSidebar from "../../components/Consumer/ConsumerSidebar";
import * as API from "../../utils/api";
import {
  getZohoPagesense,
  getDollarAmount,
  isSameStartDateAndDueDate,
  displayPlanStatus,
  getCalculatedPlanAmount,
} from "../../utils/helpers";
import ConsumerPayment from "./ConsumerPayment";
import Panel from "../../components/App/Panel";
import MenuItem from "../../components/MenuItem";
import AppSectionHeader from "../../components/App/AppSectionHeader";
import InvoiceObjectList from "../../components/ObjectList/ClientsAccounts/InvoiceObjectList";
import ClientsCardView from "../../components/CardView/ClientsCardView";
import BottomScrollListener from "react-bottom-scroll-listener";

class ConsumerAccountsPaymentplansReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appThemeData: {},
      isLoading: false,
      planDetails: {},
    };
    this.previewDDRef = React.createRef();
    this.saveDDRef = React.createRef();
  }
  componentDidMount() {
    this.getPaymentPlanDetails();
    this.getLogoColorsForPayment();
    // Set Previous Path for use with App Header's "Back" Link
    this.props.updatePrevPath("/dashboard/accounts/payment-plans");
    // getZohoPagesense();
    // // ReactGA.pageview("/ConsumerAccountsView");
  }
  getPaymentPlanDetails() {
    const invoiceId = this.props.match.params.id;
    let { user } = this.props;
    API.getConsumerPaymentPlanDetails(user, invoiceId).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.data != null &&
        data.status_code == 200
      ) {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          planDetails: data.data,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  }
  sidebarContent = () => {
    const { planDetails } = this.state;
    return (
      <div className='consumer-sidebar'>
        <label style={{ marginBottom: "0.3em", color: "#FFFFFF" }}>
          Payment Plan Total
        </label>
        <div
          className='invoice-amt-owed payment-balance'
          style={{ marginBottom: "1em", color: "#000000" }}
        >
          {"$ " + planDetails.total_in_dollars_as_currency}
        </div>
      </div>
    );
  };

  getSubTitle = (userData) => {
    let fullname = "";
    if (userData.first_name) {
      fullname = userData.first_name;
    }
    if (userData.last_name && fullname) {
      fullname = fullname + " " + userData.last_name;
    } else {
      fullname = userData.last_name;
    }
    return "Welcome, " + fullname + "!";
  };

  getInvoiceDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY");
    } else {
      return "--/--/----";
    }
  };

  getPaidAmount = (invoice) => {
    let amount = 0;
    if (invoice) {
      invoice.total_amt = invoice.total_amt ? invoice.total_amt : 0;
      amount = invoice.total_amt
        ? invoice.balance
          ? parseFloat(invoice.total_amt) -
            parseFloat(invoice.balance) +
            parseFloat(invoice.tax_amount) +
            parseFloat(invoice.slow_payer_amount)
          : parseFloat(invoice.total_amt)
        : parseFloat(invoice.total_amt);
    }
    return amount.toFixed(2);
  };

  getAmountPaidInPlans = (obj) => {
    let amount = 0;
    obj.map((item, idx) => {
      amount = item.paid_amount
        ? parseFloat(item.paid_amount.replace(",", "")) + amount
        : 0 + amount;
    });
    return amount.toFixed(2);
  };

  checkInvoicePastDue = (date) => {
    return moment(date).isBefore(new Date());
  };

  getLogoColorsForPayment() {
    this.setState({ isLoading: true });
    API.getLogoColorsForPayment()
      .then((response) => {
        this.setState({
          appThemeData: response.data,
        });
      })
      .catch(() => {});
    this.setState({ isLoading: false });
  }

  getInvoiceListData = (data) => {
    let headings = [],
      columns = [];
    headings = [
      {
        name: "Invoice #",
        style: { width: "25%", display: "flex", justifyContent: "flex-start" },
      },
      {
        name: "Invoice Date",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Invoice Amt.",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
      {
        name: "Balance",
        style: { width: "25%", display: "flex", justifyContent: "center" },
      },
    ];

    columns = data.map((row, index) => {
      const total_amount =
        parseFloat(row.total_amt) + parseFloat(row.tax_amount);
      return {
        emptyPrimary: true,
        secondaryColumns: [
          {
            key: "Invoice #",
            title: row.invoice_number ? row.invoice_number : "n/a",
            style: {
              width: "25%",
              display: "flex",
              justifyContent: "flex-start",
            },
          },
          {
            key: "Invoice Date",
            title: this.getInvoiceDate(row.txn_date),
            style: {
              width: "25%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Invoice Amt.",
            title: getDollarAmount(row.total_amount),
            style: {
              width: "25%",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            key: "Balance",
            title: getDollarAmount(row.balance),
            style: {
              width: "25%",
              display: "flex",
              justifyContent: "center",
            },
          },
        ],
        rowData: row,
      };
    });
    return {
      headings,
      columns,
    };
  };

  renderInvoice = (tableData) => {
    let isSmallDevice = window.innerWidth <= 680 ? true : false;
    return (
      <div>
        {isSmallDevice ? (
          <div className='big-device-grad invoice-line'>
            <div className='scrollbar in-sc'>
              <ClientsCardView
                data={this.getInvoiceListData(tableData)}
                isStatus={false}
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "1em" }}>
            <InvoiceObjectList
              data={this.getInvoiceListData(tableData)}
              isPaymentPlansData={true}
              isStatus={false}
              hideForMobile={true}
              optionalClasses=''
            />
          </div>
        )}
      </div>
    );
  };
  handleModalClose = () => {
    this.props.hideModal();
  };
  sentMessageToBillingAdmin = () => {
    let { user } = this.props;
    let props = {
      user: user,
      handleModalClose: this.handleModalClose,
      planDetails: this.state.planDetails,
    };
    this.props.updateModalState(true, "SEND_MESSAGE_TO_ADMIN", props);
  };

  // closeReviewOption = () => {
  //   let { history } = this.props;
  //   history.push(``);
  // };

  acceptConsumerPaymentPlanCall = () => {
    let { user, history } = this.props;
    let payload = {
      is_accepted: true,
      payment_plan_id: this.state.planDetails.id,
    };
    API.acceptConsumerPaymentPlan(user, payload).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code == 200
      ) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
        history.push(`/dashboard/accounts/payment-plans`);
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data.message,
        });
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            statusCode: data
              ? data.status ||
                (data.status_code && data.status_code == 401 ? 401 : 500) ||
                500
              : 500,
          },
        }));
      }
    });
  };

  render() {
    const { appName, user, stripePubKey } = this.props;
    const { isLoading, planDetails } = this.state;
    return isLoading ? (
      <AppCanvasLoader />
    ) : (
      <div className='client-scroll-dashboard scrollbar'>
        <Helmet>
          <title>{`${appName} | Payment Plan# 12345`}</title>
        </Helmet>
        <div className='app-sidebar-layout-canvas'>
          <ConsumerSidebar
            primary_color={this.props.appThemeData.primary_color}
            user={this.props.user}
            subTitle={this.getSubTitle(this.props.user)}
            sidebarContent={this.sidebarContent()}
            BackLink={"/dashboard/accounts/payment-plans"}
          />
          <div
            className='app-sidebar-layout-canvas-content add-invoice scrollbar'
            style={{ padding: "30px", height: "100%" }}
          >
            <AppSectionHeader
              title={"Review Payment Plan Offer"}
              optionalClassNames='create-invoice-utility'
            />
            <div className='add-inv-inner-scroll scrollbar'>
              <div className='main-container'>
                <div
                  id='invoice'
                  className={`input-container invoice-fields half `}
                >
                  <label htmlFor='invoice#'>Payment Plan #</label>
                  <input
                    name='paymentPlan#'
                    guide={"true"}
                    placeholder='Payment Plan #'
                    type='number'
                    readOnly={true}
                    value={planDetails.payment_plan_number}
                    style={{
                      cursor: "default",
                      backgroundColor: "#f2f2f2",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </div>
              </div>
              <Panel>
                <div className='main-container'>
                  <div className='half'>
                    <label htmlFor='consumer-contact'>Plan Details</label>
                    <div className={`panel consumer-contact`}>
                      <div className='row alt-row'>
                        <div className='key'>Payment Plan Total:</div>
                        <div className='value'>
                          $ {planDetails.total_in_dollars_as_currency}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='key'>Weekly / Monthly Payment Amt</div>
                        <div className='value'>
                          $ {planDetails.monthly_payment_amount_as_currency}
                        </div>
                      </div>
                      <div className='row alt-row'>
                        <div className='key'>Plan Status:</div>
                        <div className='value'>
                          {displayPlanStatus(planDetails.status)}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='key'>Number of Weeks/Months:</div>
                        <div className='value'>
                          {planDetails.number_of_payments}{" "}
                          {planDetails.hasOwnProperty("is_months_plan")
                            ? planDetails.is_months_plan
                              ? "Months"
                              : "Weeks"
                            : "-- --"}
                        </div>
                      </div>
                      <div className='row alt-row'>
                        <div className='key'>First Payment Due:</div>
                        <div className='value'>
                          {this.getInvoiceDate(planDetails.start_date)}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='key'>Final Payment Date:</div>
                        <div className='value'>
                          {planDetails.hasOwnProperty("payment_plans_payments")
                            ? this.getInvoiceDate(
                                planDetails.payment_plans_payments[
                                  planDetails.payment_plans_payments.length - 1
                                ].due_date
                              )
                            : "0.00"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='half'>
                    <label htmlFor='consumer-contact'>Plan Totals</label>
                    <div className={`panel consumer-contact`}>
                      <div className={`row alt-row`}>
                        <div className='key'>Original Balance:</div>
                        <div className='value'>
                          ${" "}
                          {getCalculatedPlanAmount(
                            planDetails.total_in_dollars_as_currency
                              ? planDetails.total_in_dollars_as_currency
                              : "0.00",
                            planDetails.adjustment_amount
                              ? planDetails.adjustment_amount
                              : "0.00",
                            planDetails.is_credit_memo_applied,
                            planDetails.status,
                            "org_balance"
                          )}
                        </div>
                      </div>
                      <div className={`row`}>
                        <div className='key'>Discounts Applied:</div>
                        <div className='value'>
                          ${" "}
                          {parseFloat(planDetails.adjustment_amount).toFixed(2)}
                        </div>
                      </div>
                      <div className={`row alt-row`}>
                        <div className='key'>Payments Applied:</div>
                        <div className='value'>
                          ${" "}
                          {planDetails.hasOwnProperty(
                            "payment_plans_payments"
                          ) &&
                            this.getAmountPaidInPlans(
                              planDetails.payment_plans_payments
                            )}
                        </div>
                      </div>
                      <div className={`row`}>
                        <div className='key'>Outstanding Balance:</div>
                        <div className='value'>
                          $ {planDetails.balance_as_currency}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel>
                <label htmlFor='invoice#' style={{ marginBottom: "20px" }}>
                  Invoices Included In Payment Plan
                </label>
                {planDetails.hasOwnProperty("get_payment_plan_invoices") &&
                  this.renderInvoice(planDetails.get_payment_plan_invoices)}
              </Panel>
            </div>
            <div className='form-buttons'>
              <div className='dropdown-button' ref={this.saveDDRef}>
                <button
                  className={`drop-down-button`}
                  onClick={this.acceptConsumerPaymentPlanCall}
                >
                  <span>Accept Plan</span>
                </button>
              </div>
              <div className='dropdown-button'>
                <button
                  className={`drop-down-button cancel`}
                  // onClick={this.closeReviewOption}
                >
                  <span>
                    <Link to={"/dashboard/accounts/payment-plans"}>Cancel</Link>
                  </span>
                </button>
              </div>
              <div className='dropdown-button'>
                <button
                  className={`drop-down-button`}
                  onClick={this.sentMessageToBillingAdmin}
                >
                  <span>Contact Billing Admin</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsumerAccountsPaymentplansReview;
