import React, { createContext, useContext, useState } from "react";

export const ImportContext = createContext();

export const useImport = () => useContext(ImportContext);

export const ImportProvider = ({ children }) => {
  const [importStarted, setImportStarted] = useState(false);
  const [status, setStatus] = useState("");
  const [isPolling, setIsPolling] = useState(false);
  const [importType, setImportType] = useState(null);

  return (
    <ImportContext.Provider
      value={{
        importStarted,
        setImportStarted,
        status,
        setStatus,
        isPolling,
        setIsPolling,
        setImportType,
        importType,
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};
