import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class LoginForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      locationData: {
        email: '',
        password: '',
      },
    };
  }
  componentDidMount(){
    let {locationData} = this.state;
    const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let email = urlParams.get('email');
        let pass = urlParams.get('pass');
        locationData.email = email;
        locationData.password = pass;
        this.setState({locationData});
  }
  
  render() {
    let { history } = this.props;
    let {locationData} = this.state;

    return (
      <div className="login-box">
        <form className="sessions-form">
          <div style={{ marginBottom: "20px" }}>
            <label>Email Address</label>
            <input
              name="email"
              type="email"
              placeholder="you@youremail.com"
              value={locationData.email}
            />
          </div>
          <div className={`input-container${""}`}>
            <label>Password</label>
            <div className="password-input-container">
              <input
                name="password"
                type="password"
                placeholder="password"
                value={locationData.password}
              />
              <button disabled={true} className="forgot-password-link-static">
                Forgot?
              </button>
            </div>
          </div>
          <button
            className="cta mg-brand4-color-static"
            style={{
              backgroundColor: "rgb(0, 139, 178)",
              borderWidth: "0.3em",
              borderColor: "#172ada",
              opacity: 1,
              borderWidth: "0.2em",
              width: "100%",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Open Sans",
              paddingTop: "5px",
              paddingBottom: "5px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            onClick={(e) => {
              e.preventDefault()
              // localStorage.setItem("static", true);
              history.push("/welcome-screen/accounts");
              // window.location.href = '/welcome-screen/accounts'
            }}
          >
            Login
          </button>
        </form>
      </div>
    );
  }
}

export default withRouter(LoginForm);
