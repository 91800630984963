import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

// API
import * as API from '../../../../utils/api';

// Components
import Modal from '../Modal';

// Packages
import MaskedTextInput from 'react-text-mask';
import moment from 'moment';
import TextareaAutosize from 'react-autosize-textarea';

// Helpers
import { numberMask, getStrippedDollarAmount, showToast } from '../../../../utils/helpers';

class Counteroffer extends Component {
  state = {
    user: '',
    offerRecipient: '',
    offer: '',
    input: {
      paymentAmount: {
        value: '',
        hasError: false,
      },
      note: {
        value: '',
        hasError: false,
      }
    },
    slide: 1,
    error: {
      hasAnyError: false,
      statusCode: 200
    }
  }

  componentDidMount() {
    let { user, offer, offerRecipient } = this.props;

    this.setState(state => ({
      ...state,
      user,
      offer,
      offerRecipient,
    }))
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      }
    }))
  }

  toNextSlide = () => {
    if (this.validateForm()) {
      this.setState(state => ({
        ...state,
        slide: this.state.slide + 1
      }))
    } else {
      this.setFormErrorState();
    }
  }

  toPreviousSlide = () => {
    this.setState(state => ({
      ...state,
      slide: this.state.slide - 1
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let { input, user, offer } = this.state;

    let offerForSubmission = {
      offer: {
        total_in_dollars: getStrippedDollarAmount(input.paymentAmount.value),
        description: this.state.input.note.value
      }
    }

    if (offer.from_admin) {
      API.consumerCounterOffer(user, offer.id, offerForSubmission).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.props.handleOfferActionResponse(data, offer.id, "offer-counter");
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      });
    } else {
      API.adminCounterOffer(user, offer.id, offerForSubmission).then(data => {
        if (((typeof data != 'undefined') || (data != null)) && !data.error) {
          this.props.handleOfferActionResponse(data, offer.id, "offer-counter");
        } else {
          this.setState(state => ({
            ...state,
            error: {
              hasAnyError: true,
              statusCode: data ? (data.status || ((data.message && data.message == "Access denied.") ? 401 : 500) || 500) : 500
            }
          }))
        }
      });
    }
  }

  validateForm = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.value === '') {
        errorsArePresent = true
      }
    });

    return errorsArePresent ? false : true;
  }

  setFormErrorState = () => {
    let newInputState = { ...this.state.input };
    let errorsArePresent = false;

    Object.entries(newInputState).forEach(([key, value]) => {
      if (value.value === '') {
        newInputState[key].hasError = true;
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    });

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    }
  }

  renderErrorMessage(input) {
    let message = '';
    if (input === 'paymentAmount') {
      message = 'Please enter a counteroffer';
    } else if (input === 'note') {
      message = 'Please enter a note.';
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  renderSlideBody = (slideNumber) => {
    const { offerRecipient, offer, input } = this.state;

    if (offer.from_admin) {
      if (slideNumber === 1) {
        return (
          <div className="modal-body">
            <div className="original-offer-description">
              <p>
                You are countering {this.props.appName}'s offer of:
              </p>
              <p className="original-offer-description-amount">
                ${offer.total_in_dollars_as_currency} (one-time payment).
              </p>
            </div>
            <p>
              Please enter the amount you'd like to offer.
            </p>
          </div>
        )
      } else {
        return (
          <div className="modal-body">
            <p>
              You've proposed a counter offer of {input.paymentAmount.value} to be paid on {moment(offer.start_date).format('MM/DD/YYYY')}
            </p>
          </div>
        )
      }
    } else {
      if (slideNumber === 1) {
        return (
          <div className="modal-body">
            <p>
              {offerRecipient.first_name} {offerRecipient.last_name} has offered to pay a one time payment of ${offer.total_in_dollars_as_currency}. Please set your counter offer below.
            </p>
          </div>
        )
      } else {
        return (
          <div className="modal-body">
            <p>
              You are about to send a counteroffer for a one-time payment of {input.paymentAmount.value} instead of {offerRecipient.first_name}’s offer of ${offer.total_in_dollars_as_currency}.
            </p>
            <p>
              Are you sure you want to send this counteroffer?
            </p>
          </div>
        )
      }
    }
  }

  render() {
    let { input, slide, error } = this.state;

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        showToast();
        this.setState({
          error:{
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200
          }
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: '/sign-out' }} />
      } else {
        this.props.updateModalState(true, 'OTHER_ERROR', true)
        return <Redirect to={{ pathname: '/sign-out' }} />
      }
    }

    return (
      <Modal
        hideModal={this.props.hideModal}
        optionalClasses="counteroffer"
      >
        <div className={`modal-slide${slide === 1 ? '' : ' hide'}`}>
          <header>
            <h1 className="modal-heading">Create Counteroffer</h1>
          </header>
          {this.renderSlideBody(slide)}
          <form
            className="admin-form"
            onSubmit={(event) => this.handleSubmit(event)}
          >
            <div
              className={`input-container${input.paymentAmount.hasError ? ' error' : ''}`}
            >
              <MaskedTextInput
                guide={true}
                mask={numberMask}
                name="paymentAmount"
                placeholder="$0"
                placeholderChar={'\u2000'}
                type="text"
                value={input.paymentAmount.value}
                onChange={event => this.handleInputChange(
                  { paymentAmount: { value: event.target.value, hasError: false } }
                )}
              />
              {input.paymentAmount.hasError ? this.renderErrorMessage('paymentAmount') : null}
            </div>
            <div
              className={`input-container${input.note.hasError ? ' error' : ''}`}
            >
              <TextareaAutosize
                name="note"
                type="textarea"
                placeholder="Your note here..."
                value={input.note.value}
                onChange={event => this.handleInputChange({ note: { value: event.target.value, hasError: false } })}
              />
              {input.note.hasError ? this.renderErrorMessage('note') : null}
            </div>
            <div className="modal-actions">
              <a
                className="cta"
                onClick={this.toNextSlide}
              >
                Submit Counteroffer
              </a>
              <a
                className="modal-link"
                onClick={this.props.hideModal}
              >
                Cancel
              </a>
            </div>
          </form>
        </div>
        <div className={`modal-slide${slide === 2 ? '' : ' hide'}`}>
          <header>
            <h1 className="modal-heading">
              Your Counteroffer
            </h1>
          </header>
          <div className="modal-body">
            {this.renderSlideBody(slide)}
          </div>
          <div className="modal-actions">
            <a
              className="cta"
              onClick={this.handleSubmit}
            >
              Confirm and Send
            </a>
            <a
              className="modal-link"
              onClick={this.toPreviousSlide}
            >
              Edit Counteroffer
            </a>
          </div>
        </div>
      </Modal>
    )
  }
}

export default Counteroffer;
