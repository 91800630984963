// Packages
import React, { Component } from "react";
// import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import AppCanvasLoader from "../../components/App/AppCanvasLoader";
import ClientsLogo from "../../components/Consumer/ClientsLogo";
import * as API from "../../utils/api";
import { getZohoPagesense } from "../../utils/helpers";
import ExpressHeader from "./ExpressHeader";
import InvoiceDetails from "./InvoiceDetails";

class InvoiceAmountForm extends Component {
  state = {
    orgkey: "",
    invoiceDetails: [],
    invoiceNumber: {
      value: "",
      hasError: false,
    },
    amount: {
      value: "0.00",
      hasError: false,
    },
    searchInvoice_no: "",
    searchInvoice_amount: "0.00",
    isLoading: false,
    showDetails: false,
  };

  checkPrimaryColor = (props) => {
    return props.hasOwnProperty("appThemeData")
      ? props.appThemeData.hasOwnProperty("primary_color")
        ? props.appThemeData.primary_color
        : "#0ab894"
      : "#0ab894";
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const paymentIntent = params.get("payment_intent");
    getZohoPagesense();
    // ReactGA.pageview("/payInvoiceForm");
    if (paymentIntent) {
      this.attemptCount = 0;
      this.get3DCardPaymentConfirmation(paymentIntent);
    } else {
      this.getOrganizationData();
    }
  }

  get3DCardPaymentConfirmation = (intentID) => {
    this.setState({ isLoading: true });
    const MAX_ATTEMPTS = 10;
    if (this.attemptCount < MAX_ATTEMPTS) {
      this.attemptCount++;
      API.getCardPaymentConfirmation(intentID).then((res) => {
        if (res && res.status_code === 200 && res.is_updated) {
          if (res.status === "SUCCESS") {
            this.props.updateModalState(true, "SUCCESS", {
              message: "Payments has been made successfully",
            });
          } else {
            this.props.updateModalState(true, "ERROR", {
              message: "Payments has failed",
            });
          }
          setTimeout(() => {
            this.getOrganizationData();
          }, 2000);
        } else {
          setTimeout(() => {
            this.get3DCardPaymentConfirmation(intentID);
          }, 2000);
        }
      });
    } else {
      this.getOrganizationData();
    }
  };

  getInvoiceDetailsForURL = (invoiceNumber, amount) => {
    API.getInvoiceForExpressPayment(
      this.state.orgkey,
      invoiceNumber,
      amount
    ).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code &&
        data.status_code == 200
      ) {
        this.setState({
          invoiceDetails: data.data,
          showDetails: true,
          isLoading: false,
        });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data && data.message ? data.message : "Please try again.",
        });
      }
    });
  };

  async getOrganizationData() {
    this.setState({ isLoading: true });
    await API.getOrganizationData().then((data) => {
      this.setState({ orgkey: data.organization_key, isLoading: false }, () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let invoice_no = urlParams.get("invoice_no");
        let invoice_amount = urlParams.get("amt");
        if (invoice_amount !== null && invoice_no !== null) {
          this.setState(
            {
              searchInvoice_no: invoice_no,
              searchInvoice_amount: invoice_amount,
              isLoading: true,
            },
            () => {
              this.getInvoiceDetailsForURL(invoice_no, invoice_amount);
            }
          );
        }
      });
    });
  }

  handleInputBlur = () => {
    let { amount } = this.state;
    if (amount.value) {
      amount.value = parseFloat(amount.value).toFixed(2);
      this.setState({ amount: this.state.amount });
    }
  };

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      ...newPartialInput,
    }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.getInvoiceDetails(
        this.state.invoiceNumber.value,
        this.state.amount.value
      );
    }
  };

  getInvoiceDetails = (invoiceNumber, amount) => {
    API.getInvoiceForExpressPayment(
      this.state.orgkey,
      invoiceNumber,
      amount
    ).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code &&
        data.status_code == 200
      ) {
        this.setState({ invoiceDetails: data.data, showDetails: true });
      } else {
        this.props.updateModalState(true, "ERROR", {
          message: data && data.message ? data.message : "Please try again.",
        });
      }
    });
  };

  validateForm = () => {
    let errorsArePresent = false;
    const { invoiceNumber, amount } = this.state;
    if (invoiceNumber.value === "" || invoiceNumber.value === null) {
      invoiceNumber.hasError = true;
      errorsArePresent = true;
    }
    if (amount.value < 0) {
      amount.hasError = true;
      errorsArePresent = true;
    }
    if (errorsArePresent) {
      this.setState({
        invoiceNumber: this.state.invoiceNumber,
        amount: this.state.amount,
      });
      return false;
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";

    if (input === "number") {
      message = "Please enter an invoice number";
    } else if (input === "amount") {
      message = "Please enter a valid amount";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  render() {
    const { appName, clientName, stripePubKey } = this.props;
    const {
      isLoading,
      invoiceNumber,
      amount,
      invoiceDetails,
      searchInvoice_amount,
      searchInvoice_no,
      showDetails,
    } = this.state;
    if (showDetails) {
      return (
        <InvoiceDetails
          stripePubKey={stripePubKey}
          appName={appName}
          invoiceNumber={
            searchInvoice_no ? searchInvoice_no : invoiceNumber.value
          }
          amount={searchInvoice_amount ? searchInvoice_amount : amount.value}
          invoiceDetails={invoiceDetails}
          clientName={clientName}
          updateAmount={this.getInvoiceDetails}
          updateModalState={this.props.updateModalState}
          getInvoiceDetailsForURL={this.getInvoiceDetailsForURL}
        />
      );
    }
    if (isLoading) {
      return <AppCanvasLoader />;
    } else {
      return (
        <div className='app-portal'>
          <Helmet>
            <title>{`${appName} | Payment`}</title>
          </Helmet>
          <ExpressHeader
            appName={appName}
            logoUrl={this.props.appThemeData.logo_url}
          />
          <div className='app-sidebar-layout-canvas'>
            <div className='app-sidebar-layout-canvas-content express-session-fill'>
              <ClientsLogo color={"#000"} />
              <form className='express-pay-form'>
                <div
                  className={`input-container consumer-input${
                    invoiceNumber.hasError ? " error" : ""
                  }`}
                >
                  <label>
                    Invoice Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name='number'
                    placeholder='Enter Invoice Number (required)'
                    type='text'
                    value={invoiceNumber.value || ""}
                    onChange={(event) =>
                      this.handleInputChange({
                        invoiceNumber: {
                          value: event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {invoiceNumber.hasError
                    ? this.renderErrorMessage("number")
                    : null}
                </div>
                <div
                  className={`input-container consumer-input amount-input ov-amt-in ${
                    amount.hasError ? " error" : ""
                  }${amount.value ? " amount-input-value" : ""}`}
                  style={{ marginTop: 0 }}
                >
                  <label>Invoice Amount (Optional)</label>
                  {amount.value ? (
                    <span
                      style={{
                        top:
                          amount.hasError && invoiceNumber.hasError
                            ? "46%"
                            : amount.hasError && !invoiceNumber.hasError
                            ? "45%"
                            : amount.hasError || invoiceNumber.hasError
                            ? "55%"
                            : "",
                      }}
                    >
                      $
                    </span>
                  ) : null}
                  <input
                    name='amount'
                    placeholder='Enter Invoice Amount'
                    type='number'
                    // pattern="[0-9]*"
                    pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                    inputmode='numeric'
                    value={amount.value || ""}
                    onBlur={() => this.handleInputBlur()}
                    onChange={(event) =>
                      this.handleInputChange({
                        amount: {
                          value: event.target.value
                            ? event.target.value.slice(0, 10)
                            : event.target.value,
                          hasError: false,
                        },
                      })
                    }
                  />
                  {amount.hasError ? this.renderErrorMessage("amount") : null}
                </div>
                <input
                  style={{
                    marginTop: "1em",
                    backgroundColor: this.checkPrimaryColor(this.props),
                    border: "1px solid #a1a1a1",
                  }}
                  className='cta submit-btn-align mg-brand2-common-color'
                  type='submit'
                  value='Submit'
                  onClick={(event) => this.handleSubmit(event)}
                />
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default InvoiceAmountForm;
