import React, { Component } from 'react'

// Packages
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

// Components
import Panel from '../../../App/Panel'
import ConsumerPaymentCalculator from '../../ConsumerPaymentCalculator'

// Utils
import { isValidDate } from '../../../../utils/helpers'

class ConsumerAccountsPaymentPlanForm extends Component {
  state = {
    input: {
      numberOfPayments: {
        value: "",
        hasError: false
      },
      firstPaymentDate: {
        value: moment(),
        hasError: false
      },
    }
  }

  handleInputChange(newPartialInput) {
    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newPartialInput,
      }
    }))
  }

  handleFirstPaymentDateChange = (date) => {
    let newFirstPaymentDate = {
      firstPaymentDate: {
        value: date,
        hasError: false
      }
    }

    this.setState(state => ({
      ...state,
      input: {
        ...state.input,
        ...newFirstPaymentDate,
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.validateForm()) {
      let { input } = this.state

      this.props.handleContinue(event, 2, input, 'payment-plan')
    } else {
      this.setFormErrorState()
    }
  }

  validateForm = () => {
    let newInputState = {...this.state.input}
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'firstPaymentDate') {
        if (!isValidDate(value.value.format('MM/DD/YYYY'))) {
          errorsArePresent = true
        }
      } else if (value.value === ''){
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      return false
    } else {
      return true
    }
  }

  setFormErrorState = () => {
    let newInputState = {...this.state.input}
    let errorsArePresent = false

    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === 'firstPaymentDate') {
        if (!isValidDate(value.value.format('MM/DD/YYYY'))) {
          newInputState[key].hasError = true
        }
      } else if (value.value === ''){
        newInputState[key].hasError = true
      }

      if (newInputState[key].hasError) {
        errorsArePresent = true
      }
    })

    if (errorsArePresent) {
      this.setState(state => ({
        ...state,
        input: newInputState
      }))
    }
  }

  renderErrorMessage(input) {
    let message = ''

    if (input === 'numberOfPayments') {
      message = 'Please select a number of payments'
    } else {
      message = 'Please enter a valid date'
    }

    return (
      <div className="input-error-message">
        {message}
      </div>
    )
  }

  getPaymentNumberOptions() {
    let options = [];
    for (let index = 1; index <= 3; index++) {
      const element = { "value": index, "label": index };
      options.push(element);
    }
    for (let index = 6; index <= 36; ) {
      const element = { "value": index, "label": index };
      options.push(element);
      index = index + 6;
    }
    return options;
  }

  render() {
    let { originalDebtAmount } = this.props
    let { input } = this.state
    let formIsCompletedAndValid = false

    if (this.validateForm()) {
      formIsCompletedAndValid = true
    }

    let paymentNumberOptions = this.getPaymentNumberOptions()
    let costPerMonth = parseFloat(originalDebtAmount) / parseFloat(input.numberOfPayments.value)

    return (
      <div
        className="consumer-accounts-payment-form"
      >
        <p className="consumer-accounts-payment-form-description">
          For a payment plan, you must pay off the full amount. Please select the number of payments you would like to make.
        </p>
        <Panel>
          <div className="admin-form-row">
            <div
              className={`input-container${input.numberOfPayments.hasError ? ' error' : ''}`}
            >
              <label htmlFor="name">Number of Payments</label>
              <Select
                inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
                className="form-select"
                name="numberOfPayments"
                value={input.numberOfPayments.value}
                placeholder="Select a number of payments"
                options={paymentNumberOptions}
                resetValue=""
                onChange={event => this.handleInputChange(
                  {numberOfPayments: {value: event ? event.value : '', hasError: false}}
                )}
              />
              {input.numberOfPayments.hasError ? this.renderErrorMessage('numberOfPayments') : null}
            </div>
          </div>
          <div className="admin-form-row">
            <div
              className={`input-container${input.firstPaymentDate.hasError ? ' error' : ''}`}
            >
              <label>Date of First Payment (Must be within the next 30 days)</label>
              <DatePicker
                selected={input.firstPaymentDate.value}
                onChange={this.handleFirstPaymentDateChange}
                readOnly={true}
                minDate={moment()}
                maxDate={moment().add(30, "days")}
                placeholderText="Select a date"
              />
              {input.firstPaymentDate.hasError ? this.renderErrorMessage('firstPaymentDate') : null}
            </div>
          </div>
        </Panel>
        {formIsCompletedAndValid &&
          <ConsumerPaymentCalculator
            message="Your monthly cost starting"
            date={input.firstPaymentDate.value.format('MM/DD/YYYY')}
            dollarAmount={costPerMonth}
          />
        }
        <a
          className="cta payment-continue-btn"
          onClick={(event) => this.handleSubmit(event)}
        >
          Continue to payment information »
        </a>
      </div>
    )
  }
}

export default ConsumerAccountsPaymentPlanForm
