import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SeeForYourself extends Component {
  constructor(props){
	super(props);
  }
  render() {
    return (
      <section className="cta-section">
        <div className="inner-wrap">
          <h2>
            See for yourself.
          </h2>
          <p>
            Joining {this.props.appName} is completely free and takes just a few minutes. Sign up and start controlling your financial future today.
          </p>
          <p>
            No fees. No hidden costs. No surprises.
          </p>
          <Link
            className="cta"
            title="Register"
            to="/register"
          >
            Register with {this.props.appName} Today
          </Link>
        </div>
      </section>
    );
  }
}

export default SeeForYourself;
